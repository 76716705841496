import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Table = styled.div`
  color: black;
  width: 100%;
  text-align: left;

  & tr {
    border-bottom: 1px solid #ccc;
  }

  & th,
  & td {
    padding: 1rem;
    font-size: 1.3rem;
  }

  @media (max-width: 768px) {
    Table,
    & tr,
    & th,
    & td {
      padding: 1rem 2rem;
    }
  }
`;
