import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

import Cards from '../../../assets/icon_cards.svg';
import Pix from '../../../assets/icon_pix.svg';
import CodeBar from '../../../assets/icon_code_bar.svg';
import {
  Container,
  ContainerProfile,
  ContainerTitle,
  ImageProfile,
  ContainerTextList,
  Text,
  ContainerPayment,
  ContainerButtons,
  Button,
  Select,
  ContainerVideo,
  ContainerTitleVideo,
  WrapperVideos,
  ContainerContentVideo,
} from './styles';
import { api } from '../../../services/api';
import { ModalCheckoutClassPackage } from '../../Modals/ModalCheckoutClassPackage';
import { formatPrice } from '../../../utils/format';
import { useAuth } from '../../../hooks/AuthProvider';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Video from '../../../pages/Dashboard/Guideline/components/Video';
import ModalVideo from '../../Modals/ModalVideo';
export function LeftColumnProfile() {
  const { t } = useTranslation();

  const history = useHistory();

  const { user } = useAuth();

  const [classPackages, setClassPackages] = useState([]);
  const [showModal, setShowModal] = useState(null);
  const [classPackageId, setClassPackageId] = useState('none');
  const [guidelines, setGuidelines] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState();
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);

  function handleChangePaymentMethod(value) {
    setPaymentMethod(value);
  }

  function handleCheckoutModalOpen() {
    if (classPackageId === 'none' || !classPackageId) {
      toast.error(t('selectPlan'));
      return false;
    }
    if (paymentMethod === 'none' || !paymentMethod) {
      toast.error(t('selectPaymentMethod'));
      return false;
    }
    if (user.address) {
      setIsCheckoutModalOpen(
        Boolean(classPackageId && paymentMethod !== 'none'),
      );
    } else {
      history.push('/dashboard/profile/editprofile/profile');
      toast.info(
        'Precisa ter endereço cadastrado para realizar a compra de créditos',
      );
    }
  }

  function handleCheckoutModalClose() {
    setIsCheckoutModalOpen(false);
    setClassPackageId('none');
    setPaymentMethod(undefined);
  }

  useEffect(() => {
    async function fetchClassPackages() {
      await api.get('/class-packages').then(response => {
        setClassPackages(
          response.data.map(classPackage => ({
            ...classPackage,
            priceFormatted: formatPrice(classPackage.value),
          })),
        );
      });
    }

    async function fetchGuidelines() {
      await api
        .get('/guidelines', { params: { type: 'video' } })
        .then(response => {
          setGuidelines(response.data);
        });
    }

    fetchClassPackages();
    fetchGuidelines();
  }, []);

  function closeModalVideo() {
    setShowModal(null);
  }

  return (
    <>
      <Container>
        <ContainerProfile>
          <ContainerTitle>
            <span className="badge" />
            <ImageProfile src={user.avatarURL} />
            <ContainerTitle>
              <Text>{user.fullName ?? user.name}</Text>
              <Text>@{t(user.type)}</Text>
            </ContainerTitle>
          </ContainerTitle>
          <ContainerTextList gridArea="c1">
            <Text>{t('yourLevel')}</Text>
            <div>
              <Text>{user.level?.method?.title?.split(' -')[0]}</Text>
            </div>
          </ContainerTextList>
          <ContainerTextList gridArea="f1">
            <Text>{t('classesAvailable')}</Text>
            <div>
              <Text>{user.credits}</Text>
            </div>
          </ContainerTextList>
          <ContainerTextList gridArea="c2">
            <Text>{t('completedLessons')}</Text>
            <div>
              <Text>{user.completedLessons}</Text>
            </div>
          </ContainerTextList>
          <ContainerTextList gridArea="f2">
            <Text>{t('canceledLessons')}</Text>
            <div>
              <Text>{user.canceledLessons}</Text>
            </div>
          </ContainerTextList>
          <ContainerTextList gridArea="c3">
            <Text>{t('completedAssignments')}</Text>
            <div>
              <Text>{user.completedAssignments}</Text>
            </div>
          </ContainerTextList>
          <ContainerTextList gridArea="f3">
            <Text>{t('yourLevel')}</Text>
            <div className="book">
              <Text>{user.level?.mission?.title}</Text>
            </div>
          </ContainerTextList>
        </ContainerProfile>

        <ContainerPayment>
          <Text>{t('buyAdditionalLessons')}</Text>
          <Select
            value={classPackageId}
            onChange={event => {
              setClassPackageId(event.target.value);
            }}
          >
            <option value="none">{t('selectPlan')}</option>
            {classPackages.map(classPackage => (
              <option value={classPackage._id} style={{ color: '#083059' }}>
                {classPackage.quantity} {t('aulas')} -{' '}
                {classPackage.priceFormatted}
              </option>
            ))}
          </Select>
          <Text>{t('paymentMethods')}</Text>
          <ContainerButtons>
            <Button
              checked={paymentMethod === 'CREDIT_CARD'}
              onClick={() => {
                handleChangePaymentMethod('CREDIT_CARD');
              }}
            >
              <img src={Cards} alt="" className="cards" />
              {t('creditCard')}
            </Button>
            <Button
              checked={paymentMethod === 'PIX'}
              onClick={() => {
                handleChangePaymentMethod('PIX');
              }}
            >
              <img src={Pix} alt="" className="pix-image" />
              PIX
            </Button>
            <Button
              checked={paymentMethod === 'BOLETO'}
              onClick={() => {
                handleChangePaymentMethod('BOLETO');
              }}
            >
              <img src={CodeBar} alt="" className="code-bar" />
              {t('boleto')}
            </Button>
          </ContainerButtons>
          <Button submitPay onClick={handleCheckoutModalOpen}>
            {t('buyNow')}
          </Button>
        </ContainerPayment>

        <ContainerVideo>
          <ContainerTitleVideo>
            <Text>{t('videos')}</Text>
          </ContainerTitleVideo>
          <WrapperVideos>
            {guidelines?.map((guideline, i) => (
              <ContainerContentVideo>
                <React.Fragment key={i}>
                  <Video
                    index={i}
                    title={guideline.title}
                    url={guideline.fileURL}
                    visibleSlide={0}
                    viewModal={showModal}
                    onChangeModal={() => {
                      setShowModal(i+1);
                    }}
                    desc={guideline.description}
                    key={i}
                    customWidth={'23rem'}
                    img={guideline.thumbnailURL}
                  />

                  {showModal != null && showModal == i + 1 && (
                    <ModalVideo
                      open={true}
                      closed={closeModalVideo}
                      urlVideo={guideline.fileURL}
                    />
                  )}
                </React.Fragment>
              </ContainerContentVideo>
            ))}
          </WrapperVideos>
        </ContainerVideo>
      </Container>
      {isCheckoutModalOpen && classPackageId && paymentMethod && (
        <ModalCheckoutClassPackage
          isOpen={isCheckoutModalOpen}
          paymentMethod={paymentMethod}
          classPackageId={classPackageId}
          onRequestClose={handleCheckoutModalClose}
        />
      )}
    </>
  );
}
