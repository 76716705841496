import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 12rem;
  border: 1px solid #dbad00;
  background: #f9f8fe;
  border-radius: 0.5rem;
  margin-top: 1.2rem;
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.top &&
    css`
      border-bottom: 1px solid var(--gray3);
      padding-bottom: 1rem;
    `}

  ${(props) =>
    props.bottom &&
    css`
      justify-content: center;
      margin: 1rem;
    `}
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
`;

export const Text = styled.span`
  font-size: 1.4rem;
  color: #083059;

  display: flex;
  align-items: center;

  .iconify {
    margin-right: 1rem;
  }

  ${(props) =>
    props.name &&
    css`
      font-weight: bold;
    `}
`;

export const Image = styled.img`
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 4rem;
  margin-top: 1rem;
`;

export const Button = styled.button`
  background: transparent;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 130rem;
  background: transparent;
  height: 1rem;

  &:nth-child(1) {
    top: -20rem;
  }
`;
