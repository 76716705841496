import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ContainerLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

export const Container = styled.div`
  display: flex;
  width: 22vh;
  flex-flow: column;
  margin-bottom: 16px;
  &:not(:last-child) {
    margin-right: 16px;
  }

  @media (max-width: 1096px) {
    display: flex;
    justify-content: center;
  }
`;

export const Card = styled(Link)`
  flex: 1;
  transition: transform 0.1s;
  &:hover {
    transform: scale(0.98);
  }
`;

export const Text = styled.div`
  text-align: left;
  font-size: 16px;
  margin-top: 1rem;
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
`;
