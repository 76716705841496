import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { socket } from '../services/contextSocket';
import { daysFrom } from '../helpers/time';
import { api } from '../services/api';
import { useAuth } from '../hooks/AuthProvider';

const MethodContext = createContext()

const MethodProvider = ({ children }) => {
  const { user, authenticated } = useAuth();
  const [teacherPlanning, setTeacherPlanning] = useState(null);
  const [lastPlanningReset, setLastPlanningReset] = useState(null);

  // fetch updated teacher planning data
  const fetchTeacherPlannning = async () => {
    const { data } = await api.get(`/planning/teacher/${user?.id}`);
    setTeacherPlanning(data)
  }

  // reset planning time
  const resetPlanningTime = () => {
    try {
      const data = { teacher: user?.id };
      socket.emit('teacherplanning:resethour', data);
      setTimeout(() => fetchTeacherPlannning(), 1000)
    } catch (err) {
      console.log(err);
      toast('Ocorreu um erro. Tente novamente!', { type: 'warning' });
    }
  };

  // get last planning reset
  const getLastPlanningReset = useCallback(async () => {
    if (!lastPlanningReset) {
      try {
        const { data } = await api.get(`/planning/teacher/${user?.id}`);
        const lastReset = data?.lastReset;
        if (!lastReset) resetPlanningTime();
        setLastPlanningReset(lastReset);
      } catch (err) {
        console.log(err);
      }
    }
  }, [lastPlanningReset, setLastPlanningReset]);

  // check reset time
  const checkResetTime = useCallback(async () => {
    const weekDay = new Date().getDay();
    if (weekDay === 1 && daysFrom(lastPlanningReset || Date.now()) > 1) {
      resetPlanningTime();
    }
  }, [lastPlanningReset]);

  let timerplanningreset;
  let timercheckreset;

  useEffect(() => {
    if (!user?.type === 'teacher') return
    timerplanningreset = setTimeout(() => authenticated && getLastPlanningReset(), 1000);
    return () => clearInterval(timerplanningreset);
  });

  useEffect(() => {
    if (!user?.type === 'teacher') return
    timercheckreset = setTimeout(() => checkResetTime(), 1000);
    return () => clearTimeout(timercheckreset);
  }, [lastPlanningReset]);

  return (
    <MethodContext.Provider value={{ teacherPlanning }}>
      {children}
    </MethodContext.Provider>
  )
}

function useMethod() {
  const context = useContext(MethodContext)

  if (!context) {
    throw new Error(
      'useMethod must be used within an MethodProvider'
    )
  }

  return context
}

export { MethodProvider, useMethod }