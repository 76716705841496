/* eslint-disable react-hooks/exhaustive-deps */
import { parseISO, format, addDays } from 'date-fns';
import React, { useCallback, useState } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAuth } from '../../../hooks/AuthProvider';
import { Appointment } from '../../../pages/Dashboard/Schedule';
import { api } from '../../../services/api';
import { zeroPad } from '../../../utils/zeroPad';
import Input from '../../Form/Input';
import {
  Button,
  Container,
  ContainerDate,
  ContainerHeader,
  ContainerHour,
  ContainerIcon,
  ContainerSelectedDate,
  ContainerText,
  HeaderSelectedDate,
  InfoSelected,
  Text,
  Wrapper,
  WrapperHours,
} from './styles';

interface CollapseScheduleProps {
  appointment: Appointment;
  fetchAppointments: () => void;
  setShowSchedule?: (data: boolean) => void;
}

function getDateTime(d: string | number | Date, time: string) {
  const date = new Date(d);

  const [hour, minutes] = time.split(':');

  date.setHours(Number(hour), Number(minutes), 0);

  return date;
}

export const CollapseSchedule = ({
  appointment,
  fetchAppointments,
  setShowSchedule,
}: CollapseScheduleProps) => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  function handleStartTimeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const [hourS, minuteS] = event.target.value.split(':');
    const hour = parseInt(hourS, 10);
    const minute = parseInt(minuteS, 10);

    setStartTime(`${zeroPad(hour, 2)}:${zeroPad(minute, 2)}`);
    let newSet = hour + 1;
    if (newSet === 24) newSet = 0;
    setEndTime(`${zeroPad(newSet, 2)}:${zeroPad(minute, 2)}`);
  }
  /*
  function handleEndTimeChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEndTime(event.target.value);
  } */

  const handleRescheduleAppointment = useCallback(async () => {
    try {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

      await api.patch(`/appointments/reschedule/${appointment._id}`, {
        timeZone,
        startTime: getDateTime(date, startTime),
        endTime: getDateTime(date, endTime),
      });

      toast(t('yourLessonHasBeenBooked'), { type: 'success' });

      if (setShowSchedule) {
        setShowSchedule(false);
      }
    } catch (err) {
      toast(t('youNeed12HoursAdvanceLesson'), {
        type: 'error',
      });
    } finally {
      fetchAppointments();
    }
  }, [appointment._id, date, endTime, fetchAppointments, startTime]);

  const handleCancelAppointment = useCallback(async () => {
    try {
      await api.patch(`/appointments/cancel/${appointment._id}`);

      toast(t('yourLessonHasBeenCanceled'), { type: 'success' });
    } catch (err) {
      toast('Error', { type: 'error' });
    } finally {
      fetchAppointments();
    }
  }, [appointment._id, fetchAppointments]);

  return (
    <Container>
      <ContainerHeader>
        <Text>{t('chooseDate')}</Text>
        <Text pointer onClick={() => setShowSchedule && setShowSchedule(false)}>
          <span className="iconify" data-icon="akar-icons:cross" />
        </Text>
      </ContainerHeader>
      <Wrapper>
        <ContainerDate>
          <ContainerText>
            <Text>{t('selecioneData')}</Text>
          </ContainerText>
          <Calendar
            className="react-calendar"
            onChange={setDate}
            value={date}
            minDate={addDays(new Date(), 1)}
            maxDate={new Date(user.planExpirationDate)}
          />
        </ContainerDate>
        <ContainerHour>
          <ContainerText>
            <Text>{t('chooseTime')}</Text>
          </ContainerText>
          <WrapperHours>
            <label htmlFor="startTime">{t('startingTime')}</label>
            <Input
              name="startTime"
              type="time"
              error={null}
              value={startTime}
              onChange={handleStartTimeChange}
              step="900"
            />
            <label htmlFor="endTime">{t('endTime')}</label>
            <Input
              className="noIcon"
              name="endTime"
              type="time"
              error={null}
              value={endTime}
              step="900"
              readonly
              // onChange={handleEndTimeChange}
            />
          </WrapperHours>
        </ContainerHour>

        <ContainerSelectedDate>
          <HeaderSelectedDate>
            <span className="iconify" data-icon="akar-icons:calendar" />
            <Text>{t('currentBooking')}</Text>
            <Text>
              {format(parseISO(appointment.startTime), 'dd/MM/yyyy - HH:mm')}
              {' '}
              {t('at')}
              {' '}
              {format(parseISO(appointment.endTime), 'HH:mm')}
            </Text>
          </HeaderSelectedDate>
          <InfoSelected>
            <Text>
              {format(new Date(date), 'dd/MM/yyyy')}
              {' '}
              -
              {startTime}
            </Text>
            <ContainerIcon>
              <span className="iconify" data-icon="fe:arrow-down" />
            </ContainerIcon>
          </InfoSelected>
          <InfoSelected>
            <Text>
              {format(new Date(date), 'dd/MM/yyyy')}
              {' '}
              -
              {endTime}
            </Text>
          </InfoSelected>
          <Button onClick={handleCancelAppointment}>
            <span className="iconify" data-icon="eva:close-fill" />
            {t('cancel')}
          </Button>
          <Button schedule onClick={handleRescheduleAppointment}>
            <span className="iconify" data-icon="bi:check" />
            {t('reschedule')}
          </Button>
        </ContainerSelectedDate>
      </Wrapper>
    </Container>
  );
};
