import { subMonths, parseISO, format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { HeaderReports } from '../HeaderReports';
import IconCash from '../../../assets/icon_cash_reports.svg';
import ImageConference from '../../../assets/img-conference.png';
import { useAuth } from '../../../hooks/AuthProvider';
import { api } from '../../../services/api';
import { formatPrice } from '../../../utils/format';
import {
  Container,
  Wrapper,
  ContainerList,
  Image,
  TextDetails,
  Button,
  ContainerText,
  ContainerIcon,
  ContainerDetails,
  ModalClass,
  ContainerModal,
  Text,
} from './styles';
import { useTranslation } from 'react-i18next';

export function ClassReports() {
  const { user, typeLogin } = useAuth();
  const { t } = useTranslation();

  const [classesReports, setClassesReports] = useState([]);
  const [appointmentToShow, setAppointmentToShow] = useState(null);
  const [startDate, setStartDate] = useState(subMonths(new Date(), 12));
  const [endDate, setEndDate] = useState(new Date());
  const [search, setSearch] = useState('');

  const [modalIsOpen, setIsOpen] = useState(false);
  const [webConferenceImageProfessor, setWebConferenceImageProfessor] =
    useState(null);
  const [webConferenceImageStudent, setWebConferenceImageStudent] =
    useState(null);

  const fetchClassesReports = useCallback(async () => {
    try {
      await api
        .get('/reports/classes/me', {
          params: { startDate, endDate, search },
        })
        .then(response => {
          setClassesReports(
            response.data.map(appointment => {
              return {
                ...appointment,
                teacher: appointment.attendees.find(t => t.type === 'teacher'),
                student: appointment.attendees.find(t => t.type === 'student'),
              };
            }),
          );
        });
    } catch (err) {
      console.error(err);
    }
  }, [startDate, endDate, search]);

  const fetchImageWebConference = async appointmentId => {
    setWebConferenceImageStudent(null);
    setWebConferenceImageProfessor(null);
    const { data } = await api.get(`/webconference/${appointmentId}`);

    if (!data.webconferenceImage) {
      return;
    }

    data.webconferenceImage.map(webImage => {
      const imageOrigin = `${process.env.REACT_APP_S3_URL}${webImage.imageURL}`;
      if (webImage.type.toLowerCase().includes('stud') && imageOrigin) {
        return setWebConferenceImageStudent(imageOrigin);
      } else if (webImage.type.toLowerCase().includes('stud') && !imageOrigin) {
        return setWebConferenceImageStudent(null);
      }

      if (webImage.type.toLowerCase().includes('teac') && imageOrigin) {
        return setWebConferenceImageProfessor(imageOrigin);
      } else if (webImage.type.toLowerCase().includes('teac') && !imageOrigin) {
        return setWebConferenceImageProfessor(null);
      }
    });
  };

  useEffect(() => {
    fetchClassesReports();
  }, [fetchClassesReports]);

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <Container>
      <HeaderReports
        search={search}
        startDate={startDate}
        endDate={endDate}
        onSearchChange={setSearch}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        searchEnabled={true}
      />
      <Wrapper>
        {classesReports?.map(appointment => (
          <ContainerList>
            <div className="container-profile" style={{ width: '180px' }}>
              <Image
                src={
                  user.type === 'student'
                    ? appointment.teacher?.avatarURL
                    : appointment.student?.avatarURL
                }
              />
              <ContainerText>
                {user.type === 'teacher' && (
                  <TextDetails typeLogin={user.type === 'student'} name>
                    {appointment.student?.fullName ?? appointment.student?.name}
                  </TextDetails>
                )}

                {user.type === 'student' && (
                  <>
                    <TextDetails typeLogin>
                      {appointment.teacher?.fullName ??
                        appointment.teacher?.name}
                    </TextDetails>
                    <TextDetails typeLogin>
                      {appointment.teacher?.address?.state}
                    </TextDetails>
                  </>
                )}
              </ContainerText>
            </div>
            <ContainerDetails>
              <ContainerIcon>
                <span
                  className="iconify"
                  data-icon="akar-icons:calendar"
                  data-inline="false"
                />
              </ContainerIcon>
              <ContainerText>
                {/* <TextDetails title>{t('classDate')}</TextDetails> */}
                <TextDetails>
                  {format(parseISO(appointment.startTime), 'dd/MM/yyyy')}
                </TextDetails>
              </ContainerText>
            </ContainerDetails>
            <ContainerDetails>
              <ContainerIcon>
                <span
                  className="iconify"
                  data-icon="ic:outline-watch-later"
                  data-inline="false"
                />
              </ContainerIcon>
              <ContainerText>
                {/* <TextDetails title>{t('classTime')}</TextDetails> */}
                <TextDetails>
                  <strong>
                    {format(parseISO(appointment.startTime), 'HH:mm')} {t('at')}{' '}
                    {format(parseISO(appointment.endTime), 'HH:mm')}
                  </strong>
                </TextDetails>
              </ContainerText>
            </ContainerDetails>
            {user.type === 'teacher' && (
              <ContainerDetails>
                <ContainerIcon>
                  <img src={IconCash} alt="" />
                </ContainerIcon>
                <ContainerText>
                  <TextDetails title>{t('amountReceived')}</TextDetails>
                  <TextDetails>
                    {formatPrice(appointment.receivedValue)}
                  </TextDetails>
                </ContainerText>
              </ContainerDetails>
            )}

            <Button
              onClick={() => {
                fetchImageWebConference(appointment._id);
                setAppointmentToShow(appointment);
                setIsOpen(true);
              }}
            >
              <span
                className="iconify"
                data-icon="akar-icons:eye"
                data-inline="false"
              />
              {t('view')}
            </Button>
          </ContainerList>
        ))}
      </Wrapper>

      {appointmentToShow && (
        <ModalClass
          overlayClassName="react-modal-overlay"
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        >
          <ContainerModal>
            <Text title>{t('classesReport')}</Text>
            <div className="container-img">
              <div className="container-imgs-obs">
                <img
                  src={
                    webConferenceImageProfessor ||
                    appointmentToShow.teacher.avatarURL
                  }
                  alt="Professor image"
                />
                <img
                  src={
                    webConferenceImageStudent ||
                    appointmentToShow.student.avatarURL
                  }
                  alt="Student image"
                />
              </div>
              <form>
                <Text>{t('observations')}:</Text>
                <textarea disabled readOnly placeholder="...">
                  {appointmentToShow.comment}
                </textarea>
              </form>
            </div>
            <button className="button" type="button" onClick={closeModal}>
              {t('fechar')}
            </button>
          </ContainerModal>
        </ModalClass>
      )}
    </Container>
  );
}
