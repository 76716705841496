import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const ContainerAside = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--gray2);
  width: 100%;
  // width: calc(100% - 250px);
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
