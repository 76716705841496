import CryptoJS from 'crypto-js';
import { useFormik } from 'formik';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
// import * as yup from 'yup';

import { api } from '../../../services/api';
import Input from '../../Form/Input';
import {
  Container,
  SubmitButton,
  PaymentSlipContainer,
  PixContainer,
  CompletePurchaseContainer,
} from './styles';
import './styles/styles.css';

Modal.setAppElement('#root');

interface ModalCheckoutClassPackageProps {
  isOpen: boolean;
  classPackageId: string;
  paymentMethod: 'BOLETO' | 'CREDIT_CARD' | 'PIX';
  onRequestClose: () => void;
}

interface FormData {
  creditCard: {
    holderName: string;
    number: string;
    expiryMonth: string;
    expiryYear: string;
    ccv: string;
  };
  creditCardHolderInfo: {
    postalCode: string;
    addressNumber: string;
  };
}

type PixQrCodeType = {
  encodedImage: string;
  payload: string;
};

type PaymentSlipType = {
  code: string;
  url: string;
};

function ModalCheckoutClassPackage({
  isOpen,
  classPackageId,
  paymentMethod,
  onRequestClose,
}: ModalCheckoutClassPackageProps) {
  const cvvInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [paymentSlip, setPaymentSlip] = useState<PaymentSlipType>();
  const [pixQrCode, setPixQrCode] = useState<PixQrCodeType>();
  const [paymentStatus, setPaymentStatus] = useState<'CONFIRMED'>();

  const handlePurchase = useCallback(async () => {
    try {
      setIsLoading(true);

      await api
        .post('/class-packages/purchase', {
          classPackageId,
          billingType: paymentMethod,
        })
        .then((response) => {
          if (paymentMethod === 'BOLETO') {
            setPaymentSlip(response.data.paymentSlip);
          } else if (paymentMethod === 'PIX') {
            setPixQrCode(response.data.pixQrCode);
          }
        });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [classPackageId, paymentMethod]);

  useEffect(() => {
    if (paymentMethod !== 'CREDIT_CARD') {
      handlePurchase();
    }
  }, [handlePurchase, paymentMethod]);

  async function handleCreditCardPurchase({
    creditCard,
    creditCardHolderInfo,
  }: FormData) {
    try {
      await api
        .post('/class-packages/purchase', {
          classPackageId,
          billingType: paymentMethod,
          creditCardHolderInfo,
          creditCard: CryptoJS.AES.encrypt(
            JSON.stringify(creditCard),
            process.env.REACT_APP_CRYPTO_KEY as string,
          ).toString(),
        })
        .then((response) => {
          setPaymentStatus(response.data.status);
        });
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errors = err.response && err.response.data.message;

      if (errors) {
        errors.forEach((error: { description: string }) => {
          toast.error(error.description);
        });
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      creditCard: {
        holderName: '',
        number: '',
        expiryMonth: '',
        expiryYear: '',
        ccv: '',
      },
      creditCardHolderInfo: {
        postalCode: '',
        addressNumber: '',
      },
    },
    onSubmit: handleCreditCardPurchase,
  });

  async function handleCopyToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);

      toast('Successfully copied to clipboard', { type: 'success' });
    } catch (err) {
      toast('Could not copy text', { type: 'error' });
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="checkout-modal-content"
    >
      <Container isLoading={isLoading}>
        {isLoading ? (
          <Loader
            visible
            type="ThreeDots"
            color="#124f8c"
            secondaryColor="#191919"
          />
        ) : (
          <>
            {paymentMethod === 'BOLETO' && paymentSlip && (
              <PaymentSlipContainer>
                <h2>{t('assetBarcode')}</h2>
                <p className="payment-slip-text">{paymentSlip.code}</p>
                <button
                  type="button"
                  onClick={() => {
                    handleCopyToClipboard(paymentSlip.code);
                  }}
                >
                  {t('copyCode')}
                </button>
                <a target="_blank" href={paymentSlip.url} rel="noreferrer">
                  {t('viewBillingStatement')}
                </a>
              </PaymentSlipContainer>
            )}
            {paymentMethod === 'PIX' && pixQrCode && (
              <PixContainer>
                <img
                  alt="pix"
                  src={`data:image/png;base64, ${pixQrCode.encodedImage}`}
                />
                <h2>Código</h2>
                <span className="pix-text">{pixQrCode.payload}</span>
                <button
                  type="button"
                  onClick={() => {
                    handleCopyToClipboard(pixQrCode.payload);
                  }}
                >
                  {t('copyCode')}
                </button>
              </PixContainer>
            )}
            {paymentMethod === 'CREDIT_CARD' && !paymentStatus ? (
              <>
                <h2>{t('buyAdditionalLessons')}</h2>
                <form onSubmit={formik.handleSubmit}>
                  <label className="label">{t('cardNumber')}</label>
                  <Input
                    placeholder={t('cardNumber')}
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.creditCard.number
                      .replace(/\s/g, '')
                      .replace(/(\d{4})/g, '$1 ')
                      .trim()}
                    name="creditCard.number"
                    maxLength={19}
                    error={formik.errors.creditCard?.number}
                    // onKeyDown={e => {
                    //   console.log(e.which);
                    //   if (e.which !== '#')
                    //     number.current?.classList.add('numberTransform');
                    // }}
                  />
                  <label className="label">{t('cardHolder')}</label>
                  <Input
                    placeholder={t('cardHolder')}
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.creditCard.holderName}
                    name="creditCard.holderName"
                    error={formik.errors.creditCard?.holderName}
                  />
                  <div className="row">
                    <div className="column">
                      <label className="label">{t('expirationDate')}</label>
                      <div className="row">
                        <select
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.creditCard.expiryMonth}
                          placeholder="Month"
                          name="creditCard.expiryMonth"
                        >
                          <option value="" disabled selected>
                            Month
                          </option>
                          {[
                            '01',
                            '02',
                            '03',
                            '04',
                            '05',
                            '06',
                            '07',
                            '08',
                            '09',
                            '10',
                            '11',
                            '12',
                          ].map((m) => (
                            <option value={m}>{m}</option>
                          ))}
                        </select>

                        <select
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.creditCard.expiryYear}
                          name="creditCard.expiryYear"
                        >
                          <option value="" disabled selected>
                            Year
                          </option>
                          {[
                            2020, 2021, 2023, 2024, 2025, 2026, 2027, 2028,
                            2029, 2030, 2031, 2032,
                          ].map((y) => (
                            <option value={y}>{y}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="column">
                      <label className="label">{t('CCV')}</label>
                      <Input
                        placeholder={t('CCV')}
                        type="text"
                        ref={cvvInputRef}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.creditCard.ccv}
                        name="creditCard.ccv"
                        error={formik.errors.creditCard?.ccv}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="column"
                      style={{ width: '80%', marginRight: '1rem' }}
                    >
                      <label className="label">{t('CEP')}</label>
                      <Input
                        placeholder={t('CEP')}
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.creditCardHolderInfo.postalCode}
                        name="creditCardHolderInfo.postalCode"
                        error={formik.errors.creditCardHolderInfo?.postalCode}
                      />
                    </div>
                    <div className="column" style={{ width: '20%' }}>
                      <label className="label">{t('number')}</label>
                      <Input
                        placeholder={t('number')}
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.creditCardHolderInfo.addressNumber}
                        name="creditCardHolderInfo.addressNumber"
                        error={
                          formik.errors.creditCardHolderInfo?.addressNumber
                        }
                      />
                    </div>
                  </div>

                  <SubmitButton
                    type="submit"
                    disabled={!formik.isValid}
                    isLoading={formik.isSubmitting}
                  >
                    {formik.isSubmitting ? (
                      <Loader
                        visible
                        type="ThreeDots"
                        color="#fff"
                        secondaryColor="#191919"
                        height={10}
                      />
                    ) : (
                      t('buyNow')
                    )}
                  </SubmitButton>
                </form>
              </>
            ) : (
              paymentMethod === 'CREDIT_CARD' && (
                <CompletePurchaseContainer>
                  <span className="iconify" data-icon="dashicons:yes-alt" />
                  <h2>{t('paymentConfirmed')}</h2>
                  <button type="button" onClick={onRequestClose}>
                    {t('fechar')}
                  </button>
                </CompletePurchaseContainer>
              )
            )}
          </>
        )}
      </Container>
    </Modal>
  );
}

export { ModalCheckoutClassPackage };
