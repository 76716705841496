import React from 'react';

import IconFacebook from '../../../assets/icon_facebook.svg';
import IconInstagram from '../../../assets/icon_instagram.svg';
import IconLinkedin from '../../../assets/icon_linkedin.svg';
import IconYT from '../../../assets/icon_yt.svg';
import {
  Container,
  ColumnLeft,
  IconsLeft,
  ContainerSocial,
  ImageSocial,
} from './styles';

function LeftColumn({ withColumn, leftIcon }) {
  return (
    <Container with={withColumn}>
      <ColumnLeft>
        <ContainerSocial>
          <ImageSocial src={IconFacebook} />
          <ImageSocial src={IconInstagram} />
          <ImageSocial src={IconLinkedin} />
          <ImageSocial src={IconYT} />
        </ContainerSocial>
      </ColumnLeft>
    </Container>
  );
}

export default LeftColumn;
