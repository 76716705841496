import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 1.2rem;
  background: #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray3);
`;

export const ContainerAbasNav = styled.div`
  display: flex;
  height: 5.8rem;
  border-bottom: 1px solid var(--gray3);
  margin-bottom: 1rem;
`;

export const Nav = styled(NavLink)`
  font-size: 2rem;
  text-decoration: none;
  color: #5e5873;
  font-weight: 650;
  width: 20.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: #ebe9f1;
  }
`;
