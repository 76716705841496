import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import en from '../../assets/usa-flag.png';
import pt from '../../assets/brazil-flag.png';
import { WrapperSelect, Flags, SelectLanguage } from './styles';

export const UpdateLang = () => {
  const { i18n } = useTranslation();
  const [valueFlag, setValueFlag] = useState('en');

  const flags = {
    en,
    pt,
  };

  const updateLanguage = (lg) => {
    i18n.changeLanguage(lg);
    setValueFlag(lg);
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 5,
    }),
    control: () => ({
      width: 120,
      display: 'flex',
      alignItems: 'center',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const indicatorSeparatorStyle = {
    alignSelf: 'stretch',
    backgroundColor: 'transparent',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
  };

  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
  };

  const options = [
    { value: 'en', label: 'English' },
    { value: 'pt', label: 'Portuguese' },
  ];

  return (
    <WrapperSelect>
      <Flags src={flags[valueFlag]} />
      <SelectLanguage
        name="select"
        className="selectlangage"
        components={{ IndicatorSeparator }}
        autocomplete="off"
        defaultValue={options}
        noOptionsMessage={() => <p>Não há opções</p>}
        options={options}
        autoFocus={false}
        onChange={(e) => updateLanguage(e.value)}
        styles={customStyles}
      />
      {}
    </WrapperSelect>
  );
};
