import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  min-width: 80.2rem;

  background: #fff;
  display: flex;
  border: 1px solid var(--gray3);
  border-radius: 0 0.5rem 0.5rem 0;

  ${props =>
    props.typeLogin === true &&
    css`
      border-radius: 0.5rem;
    `}

  @media (max-width: 1660px) {
    grid-area: t2;
  }

  @media (max-width: 900px) {
    grid-area: m;
  }

  @media (max-width: 1280px) {
    width: 100%;
    min-width: 60.2rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 63.2rem;
    min-width: 50.2rem;
  }
  @media (max-width: 468px) {
    margin-top: 1rem;
    max-width: 46rem;
    min-width: 42rem;
  }
`;

export const ContainerNav = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerAbasNav = styled.div`
  display: flex;
  height: 5.8rem;
  border-bottom: 1px solid var(--gray3);
  margin-bottom: 1rem;

  @media (max-width: 470px) {
    flex-direction: column;
    height: 10rem;
    justify-content: space-evenly;
    align-items: center;
  }
`;

export const Nav = styled(NavLink)`
  font-size: 2rem;
  text-decoration: none;
  font-weight: bold;
  color: #5e5873;
  width: 20.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: #ebe9f1;
  }
`;
