import styled, { css } from 'styled-components';

interface TextProps {
  subtitle?: boolean;
}

interface BurguerProps {
  showMenu?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 5rem;
  min-height: 5rem;
  background: #f3f3f3;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 1rem;

  @media (max-width: 768px) {
    position: fixed;
    z-index: 9999;
  }
`;

export const ContainerIcon = styled.div``;

export const Icon = styled.img`
  cursor: pointer;
`;

export const BarMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerNotification = styled.div`
  border-right: 1px solid #d8d6de;
  border-left: 1px solid #d8d6de;
  padding: 0 1rem;
  margin: 0 1rem;
  position: relative;
`;

export const ContainerUser = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;

  @media (max-width: 400px) {
    display: none;
  }
`;

export const Text = styled.span<TextProps>`
  font-size: 1.4rem;
  color: #6e6b7b;
  font-weight: 600;

  ${(props) => props.subtitle
    && css`
      font-size: 1.2rem;
      font-weight: 500;
      color: #b9b9c3;
    `}
`;

export const Image = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const Badge = styled.span`
  height: 1.4rem;
  width: 1.4rem;
  background: #dbad00;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 50%;
  position: absolute;
  top: -0.7rem;
  right: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BadgeStatus = styled.span`
  height: 1.4rem;
  width: 1.4rem;
  background: #28c76f;
  border-radius: 50%;
  position: absolute;
  border: 2px solid #fff;
  bottom: 0;
  right: -0.2rem;
`;

export const SpanBurguer = styled.span`
  font-size: 2.5rem;
  color: #fff;
  cursor: pointer;
`;

export const Burguer = styled.button<BurguerProps>`
  display: flex;

  ${(props) => props.showMenu === false
    && css`
      display: flex;
    `}

  @media(max-width: 768px) {
    display: block;
  }
`;
