import React, { useEffect, useState } from 'react';
import IconBreakthrough from '../../../assets/icon_breakthrough.png';
import IconWatch from '../../../assets/icon_watch.svg';
import { useAuth } from '../../../hooks/AuthProvider';
import { api } from '../../../services/api';
import { socket } from '../../../services/contextSocket';
import {
  Container,
  ContainerInfo,
  ImageHeader,
  Column,
  Hour,
  Button,
  ContainerProfile,
  ImageProfile,
  ContainerText,
  Text,
} from './styles';
import { customState } from '../../../utils/customState';
import { AddNotes } from './AddNotes';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
export function HeaderInfo({
  student,
  setIsOpen,
  setCanCloseModal,
  setShowPlacements,
  setIsLateFinishClass,
  onHideContentStudent,
  studentOnline,
}) {
  const location0 = useLocation();
  const location = customState(location0);

  const [hideContentStudent, setHideContentStudent] = useState();

  const [method, setMethod] = useState('');
  const [mission, setMission] = useState('');
  const [classUsers, setClassUsers] = useState('');
  const [isTimeSet, setIsTimeSet] = useState(false);
  const [canClose, setCanClose] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [open, setOpen] = useState(false);
  const [actualTime, setActualTime] = useState('');
  const [initTimer0, setInitTimer0] = useState(
    location?.state?.isExperimentalClass ? '00:25:00' : '01:00:00'
  );
  const [initTimerDate, setInitTImerDate] = useState(null);
  const [timer, setTimer] = useState('');
  const [classRoom, setClassRoom] = useState(location?.state?.appointmentId);
  const { t } = useTranslation();

  const createClass = async () => {
    try {
      const { data } = await api.get(
        `/class-initialize/appointment/${location?.state?.appointmentId}`,
      );

      if (!data) {
        const initialDate = new Date();

        const data = {
          appointment: location?.state?.appointmentId,
          initialDate: initialDate,
          teacher: location?.state?.user?.teacher?.id,
        };

        await api.post('/class-initialize/appointment', data);
      }
    } catch (err) {
      console.error(err);
      toast('Ocorreu um erro ao salvar dados do inicio da aula!', {
        type: 'success',
      });
    }
  }

  function fetchCreateClassRoom() {
    socket.emit('classroom:create', location?.state?.appointmentId);
  }

  useEffect(() => {
    socket.on('connect', () => {
      console.log('[IO] A new connection has been established 🚨');
      socket.emit('classroom:create', classRoom);
    });
  }, []);

  function fetchClassRoom() {
    socket.on('classroom:serverclassstudentjoin', room => {
      socket.emit('classroom:classtimesstudentjoin', { classRoom, actualTime });
    });
  }

  const setFirstTime = async () => {
    const { data } = await api.get(
      `/classtimecontrol/appointment/${location?.state?.appointmentId}`,
    );

    let initTimer = '01:00:00';

    if (location?.state?.isExperimentalClass) {
      initTimer = '00:25:00';
    }

    if (!data) {
      setNewTime(initTimer);
    }
  }

  const loadTimeData = async () => {
    const { data } = await api.get(
      `/classtimecontrol/appointment/${location?.state?.appointmentId}`,
    );

    let initTimer2 = '01:00:00';

    if (location?.state?.isExperimentalClass) {
      initTimer2 = '00:25:00';
    }

    if (data) {
      // let newHour = '00:00:00'
      let newHour = data.hours;
      setInitTimer0(newHour ?? initTimer2);
      setInitTImerDate(new Date());

      // se relógio é menor do que 50 minutos, ele pode finalizar a classe
      if (newHour < '00:50:00') {
        setCanClose(true);
      }

      if (newHour === '00:00:00') {
        setCanCloseModal(false);
        setIsOpen(true);
        setIsTimeSet(false);
      } else {
        setIsTimeSet(true);
      }
    } else {
      setInitTimer0(initTimer2);
      setInitTImerDate(new Date());
      setIsTimeSet(true);
    }
  }

  function loadMethodData() {
    setMethod(location?.state?.method);
    setMission(location?.state?.mission);
    setClassUsers(location?.state?.user);
  }

  const handleFinishClass = isFinish => {
    console.log('isStudentOnline!!!', studentOnline);
    if (!studentOnline) {
      setIsLateFinishClass(true);
    }
  };

  const initializeAll = async () => {
    await setFirstTime();
    loadMethodData();
    await createClass();
    await loadTimeData();
    fetchCreateClassRoom();
    setClassRoom(location?.state?.appointmentId);
    fetchClassRoom();
    setIsFinish(true);
  }

  useEffect(() => {
    initializeAll();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      clockCustom();
    }, 1000);
    return () => clearInterval(interval);
  }, [isTimeSet, studentOnline]);

  useEffect(() => {
    if (onHideContentStudent && hideContentStudent !== undefined) {
      onHideContentStudent(hideContentStudent);
    }
  }, [hideContentStudent]);

  function setNewTime(time) {
    try {
      const data = {
        appointment: location?.state?.appointmentId,
        hour: time,
      };

      socket.emit('class:updatehour', data);
    } catch (err) {
      console.error(err);
      toast('Ocorreu um erro ao salvar seus dados!', { type: 'success' });
    }
  }

  const clockCustom = () => {
    if (isTimeSet) {
      if (!initTimer0 || !initTimerDate) {
        return;
      }
      let c = 0;
      let lastSeconds = 0;

      let countMinutes = 0;

      let lastMinute = 0;
      let dateNow = new Date();
      let dif = Math.floor((dateNow.getTime() - initTimerDate.getTime())/1000);
      let myTimer = initTimer0;
      const splitedTime = myTimer.split(':');

      const initialHours = parseInt(splitedTime[0]);

      const initialMinutes = parseInt(splitedTime[1]);

      if (lastMinute === 0) {
        lastMinute = initialMinutes;
      }

      const initialSeconds = parseInt(splitedTime[2]);

      const hoursInSeconds = initialHours * 3600;

      const minutesInSeconds = initialMinutes * 60;

      c = parseInt(hoursInSeconds + minutesInSeconds + initialSeconds) - dif;

      if (c < -10) {
        // 10 segundos negativos já deve ter aberto o modal
        return;
      }
      if (c < 0) {
        // caso seja menos do que 0, existe a possibilidade de não ter passado em c = 0, por isso é importante ter essa função
        setIsOpen(true);
        setCanCloseModal(false);
        return;
      }

      let seconds = c % 60;

      const secondsInMinutes = (c - seconds) / 60;

      let minutes = secondsInMinutes % 60;
      let hours = (secondsInMinutes - minutes) / 60;

      if (hours < 0 || minutes < 0 || seconds < 0) {
        hours = 0;
        minutes = 0;
        seconds = 0;
      }

      if (lastSeconds === 0 && parseInt(seconds) === 59) {
        lastSeconds = parseInt(seconds);
      }

      if (hours.toString().length < 2) {
        hours = '0' + hours;
      }

      if (minutes.toString().length < 2) {
        minutes = '0' + minutes;
      }

      if (seconds.toString().length < 2) {
        seconds = '0' + seconds;
      }

      const secondsCalculation = lastSeconds - parseInt(seconds);

      if (secondsCalculation >= 10 || secondsCalculation < 0) {
        lastSeconds = parseInt(seconds);
        setNewTime(hours + ':' + minutes + ':' + seconds);
      }

      setActualTime(hours + ':' + minutes + ':' + seconds);

      if (c === 0) {
        setIsOpen(true);
        setCanCloseModal(false);
        setNewTime(hours + ':' + minutes + ':' + seconds);
      }

      // se relógio é menor do que 50 minutos, ele pode finalizar a classe
      if (hours + ':' + minutes + ':' + seconds < '00:50:00') {
        setCanClose(true);
      }

      if (lastMinute !== minutes) {
        lastMinute = minutes;
        countMinutes = countMinutes + 1;
      }
      console.log('countMinutes', countMinutes);
      console.log('isOnline', studentOnline);
      if (countMinutes >= 1 && studentOnline === false && hours + ':' + minutes + ':' + seconds < '00:50:00') {
        handleFinishClass(isFinish);
        setIsFinish(false);
      }
      myTimer = hours + ':' + minutes + ':' + seconds;
      setTimer(myTimer);
    }
  };

  const { typeLogin, user } = useAuth();
  return (
    <Container>
      <ContainerInfo typeLogin={typeLogin}>
        <Column columnleft>
          <ImageHeader src={IconBreakthrough} />
          <Text left>
            <strong>{method?.title}</strong>
          </Text>
        </Column>
        <Column columnright>
          <Hour>
            <img src={IconWatch} alt="" />
            <div className="container-text">
              <Text>
                <strong>{timer}</strong>
              </Text>
              <Text textTime>{t('remaining')}</Text>
            </div>
          </Hour>
          {!typeLogin && (
            <>
              <Button
                onClick={() => {
                  setHideContentStudent(!hideContentStudent);
                }}
              >
                {hideContentStudent ? 'Enable Mirroring' : 'Disable Mirroring'}
              </Button>

              <Button onClick={() => setShowPlacements(true)}>
                Placement Test{' '}
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-right-alt2"
                  data-inline="false"
                />
              </Button>
              {canClose && (
                <Button onClick={() => {
                  setIsOpen(true);
                  setCanCloseModal(true);
                }}>
                  {t('finishClassNow')}{' '}
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                    data-inline="false"
                  />
                </Button>
              )}
            </>
          )}

          <ContainerProfile>
            <ImageProfile
              onClick={() => handleShowDialog}
              src={student?.avatarURL}
            />
            <ContainerText>
              <Text name>{student?.fullName ?? ''}</Text>
              <ContainerText exam>
                <Text exam>
                  {student?.level?.method?.title?.toString()
                    ? student?.level.method.title.split('-')[0]
                    : ''}
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                    data-inline="false"
                  />
                </Text>
                <Text exam>{student?.level?.mission?.title ?? ''}</Text>
              </ContainerText>
            </ContainerText>
          </ContainerProfile>
        </Column>
      </ContainerInfo>
      {open && <AddNotes open={open} setOpen={setOpen} />}
    </Container>
  );
}
