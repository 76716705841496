import { useCallback, useState, useRef, useEffect } from 'react';
import { useAuth } from '../../../../hooks/AuthProvider';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import DatePicker from 'react-date-picker';

import {
  Container,
  Wrapper,
  ContainerInput,
  Input,
  ContainerWrapper,
  ContainerBio,
  TextArea,
  ContainerFile,
  Button,
  Select,
} from './styles';
import { api } from '../../../../services/api';
import { useTranslation } from 'react-i18next';
import { byCityName } from '../../../../lib/sort';

const validationSchema = yup.object({
  name: yup.string().required(''),
  email: yup.string().required(''),
  phoneNumber: yup.string().required(''),
  bio: yup.string(),
  linked: yup.string(),
  skype: yup.string(),
  number: yup.number(),
  street: yup.string(),
  district: yup.string(),
  complement: yup.string(),
  city: yup.string(),
  country: yup.string(),
  zipCode: yup.string(),
  state: yup.string(),
});

function Edit() {
  const today = new Date();
  const _18YearsAgo = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate(),
  );

  const { user, fetchUserData } = useAuth();
  const [birthdayDate, setBirthdayDate] = useState(new Date(user.birthdayDate));
  const [fileName, setFileName] = useState();
  const refInputFile = useRef(null);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async ({
      name,
      email,
      phoneNumber,
      bio,
      linked,
      skype,
      number,
      street,
      district,
      complement,
      city,
      country,
      zipCode,
      state,
    }) => {
      const codigoIbgeCidade =
        city && cities && cities.find(c => c.name === city)?.ibgeCode;

      const address = {
        number,
        street,
        district,
        complement,
        city,
        country,
        zipCode,
        state,
        codigoIbgeCidade,
      };

      try {
        console.log(user);
        await api.put(`/users/${user.id ? user.id : user._id}`, {
          name,
          email,
          phoneNumber,
          bio,
          birthdayDate,
          linked,
          skype,
          address,
        });

        await fetchUserData();

        toast('Successfully saved.', { type: 'success' });
      } catch (err) {
        console.log(err);
        toast('There was an error saving your data. Please try again.', {
          type: 'error',
        });
      }
    },
    [user, fetchUserData, birthdayDate, cities],
  );

  const formik = useFormik({
    initialValues: {
      name: user.name,
      email: user.email,
      phoneNumber: user.phoneNumber,
      bio: user.bio,
      linked: user.linked,
      skype: user.skype,
      number: user.address?.number,
      street: user.address?.street,
      district: user.address?.district,
      complement: user.address?.complement,
      city: user.address?.city,
      country: user.address?.country,
      zipCode: user.address?.zipCode,
      state: user.address?.state,
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  console.log(formik.value);

  const fetchStates = useCallback(async () => {
    await api.get('/states').then(response => {
      setStates(response.data.docs);
    });
  }, []);

  const fetchCities = useCallback(async () => {
    if (states) {
      const { code } = states.find(s => s.name === formik.values.state) || {};

      if (isNaN(Number(code))) return;

      await api.get(`/city/state/${Number(code)}`).then(response => {
        setCities(response.data.sort(byCityName));
      });
    }
  }, [states, formik.values.state]);

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);

  useEffect(() => {
    if (formik.values.state) {
      fetchCities();
    }
  }, [fetchCities]);

  const handleGetVideo = useCallback(async () => {
    const file = refInputFile.current.files[0];
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await api.patch('/users/video', formData);

    setFileName(file.name);

    fetchUserData();
  }, [user, fetchUserData, birthdayDate]);

  return (
    <Container onSubmit={formik.handleSubmit}>
      <ContainerBio>
        <ContainerWrapper>
          <Wrapper>
            <ContainerInput>
              <span>{t('nome')}:</span>
              <Input
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.errors.name}
                disabled
                placeholder={t('nome')}
              />
            </ContainerInput>
            <ContainerInput>
              <span>E-mail:</span>
              <Input
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.errors.email}
                disabled
                placeholder={t('informYourEmail')}
              />
            </ContainerInput>
          </Wrapper>
          <Wrapper>
            <ContainerInput>
              <span>{t('telefone')}:</span>
              <Input
                name="phoneNumber"
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                error={formik.errors.phoneNumber}
                placeholder={t('phoneNumber')}
              />
            </ContainerInput>
            <ContainerInput>
              <span>{t('birthDate')}:</span>

              <DatePicker
                clearIcon={null}
                calendarClassName="containercalendar"
                className="datePicker"
                onChange={setBirthdayDate}
                value={birthdayDate}
                maxDate={_18YearsAgo}
              />
            </ContainerInput>
          </Wrapper>
        </ContainerWrapper>

        {user.type === 'teacher' && (
          <ContainerInput>
            <span>Bio:</span>
            <TextArea
              name="bio"
              onChange={formik.handleChange}
              value={formik.values.bio}
              error={formik.errors.bio}
              placeholder={t('informYourBio')}
            />
          </ContainerInput>
        )}
      </ContainerBio>

      {user.type === 'teacher' && (
        <Wrapper>
          {/* <ContainerInput>
            <span>Skype:</span>
            <Input
              name="skype"
              onChange={formik.handleChange}
              value={formik.values.skype}
              error={formik.errors.skype}
              placeholder={t('yourSkypeUser')}
            />
          </ContainerInput> */}
          <ContainerInput>
            <span>LinkedIn:</span>
            <Input
              name="linked"
              onChange={formik.handleChange}
              value={formik.values.linked}
              error={formik.errors.linked}
              placeholder={t('linkLinkedinprofile')}
            />
          </ContainerInput>
          <ContainerInput>
            <div>
              <span style={{"display": "inline-block", "width": "auto"}}>{t('videoPresentation')}:</span>
              {(fileName || user.videoPresentationURL) && (
                <a style={{"display": "inline-block", "width": "auto", marginLeft: '10px'}} href={fileName ? fileName : user.videoPresentationURL} target="_blank">Link to video</a>
              )}
            </div>
            <ContainerFile onClick={() => refInputFile.current.click()}>
              <span>{fileName || t('uploadFile')}</span>
              <Input
                style={{ display: 'none' }}
                ref={refInputFile}
                type="file"
                onChange={handleGetVideo}
                accept=".mp4, .avi"
              />
            </ContainerFile>
          </ContainerInput>
          
        </Wrapper>
      )}

      <Wrapper>
        <ContainerInput>
          <span>{t('street')}:</span>
          <Input
            name="street"
            onChange={formik.handleChange}
            value={formik.values.street}
            error={formik.errors.street}
            placeholder={t('streetName')}
          />
        </ContainerInput>
        <ContainerInput>
          <span>{t('neighborhood')}:</span>
          <Input
            name="district"
            onChange={formik.handleChange}
            value={formik.values.district}
            error={formik.errors.district}
            placeholder={t('neighborhoodName')}
          />
        </ContainerInput>

        <ContainerInput>
          <span>{t('number')}:</span>
          <Input
            name="number"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.number}
            error={formik.errors.number}
            placeholder={t('number')}
          />
        </ContainerInput>
      </Wrapper>

      <Wrapper>
        <ContainerInput>
          <span>{t('complement')}:</span>
          <Input
            name="complement"
            onChange={formik.handleChange}
            value={formik.values.complement}
            error={formik.errors.complement}
            placeholder={t('complement')}
          />
        </ContainerInput>
        <ContainerInput>
          <span>{t('CEP')}:</span>
          <Input
            name="zipCode"
            onChange={formik.handleChange}
            value={formik.values.zipCode}
            error={formik.errors.zipCode}
            placeholder={t('zipCodeNumber')}
          />
        </ContainerInput>
      </Wrapper>

      <Wrapper>
        <ContainerInput>
          <span>{t('country')}:</span>
          <Input
            name="country"
            onChange={formik.handleChange}
            value={formik.values.country}
            error={formik.errors.country}
            placeholder={t('country')}
          />
        </ContainerInput>
        <ContainerInput>
          <span>{t('state')}:</span>
          <Select
            name="state"
            onChange={formik.handleChange}
            value={formik.values.state}
            placeholder={t('selectState')}
          >
            {states &&
              states.map(s => (
                <option key={s.id} value={s.name}>
                  {s.name}
                </option>
              ))}
          </Select>
        </ContainerInput>
        <ContainerInput>
          <span>{t('city')}:</span>
          <Select
            name="city"
            onChange={formik.handleChange}
            value={formik.values.city}
            placeholder={t('selectCity')}
          >
            {cities.map(c => (
              <option key={c.id} value={c.name}>
                {c.name}
              </option>
            ))}
          </Select>
        </ContainerInput>
      </Wrapper>

      {console.log(formik.values)}

      <Button type="submit">{t('salvar')}</Button>
    </Container>
  );
}

export default Edit;
