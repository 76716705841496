export const getErrorID = (statusCode: unknown) => {
  switch (statusCode) {
    case 409: {
      return 'ConflictError';
    }

    case 500: {
      return 'InternalServerError';
    }

    default: {
      return 'InternalServerError';
    }
  }
};
