import React from 'react';
import { Span } from '../../../../Span';
import moment from 'moment-timezone';
import {
  ContainerTitle,
  Title,
  ContainerTicketBodyPending,
  ContainerTicketBodyPendingItens,
  ContainerTicketBodyStatus,
  ContainerTicketBodyDate,
  ContainerTicketBodyDateInfo,
  ContainerTicketBodyPendingButton,
  ContainerTicketBodyComent,
  ContainerTicketBodySubComent,
} from './styles';
import { useTranslation } from 'react-i18next';

function HistoryPresence({ presence, desempenho, show, historic }) {
  function formatDateAppointment(dateAppointment) {
    console.log(dateAppointment);
    const date = moment(dateAppointment).format('MMMM Do, YYYY');
    return date;
  }

  function formatHourAppointment(dateAppointment) {
    const date = moment(dateAppointment).tz('America/Sao_Paulo').format('LT');
    return date;
  }

  const { t } = useTranslation();

  return (
    <>
      {presence && !desempenho && show && historic.students.length > 0 ? (
        <>
          <ContainerTitle>
            <Title>{t('presenceHistory')}</Title>
          </ContainerTitle>
          {historic.students.map((history, index) => (
            <>
              <ContainerTicketBodyPending>
                <ContainerTicketBodyPendingItens>
                  <ContainerTicketBodyDate>
                    <ContainerTicketBodyDateInfo>
                      <Span>{history?.methods?.title || ' - '} </Span>
                    </ContainerTicketBodyDateInfo>
                  </ContainerTicketBodyDate>
                  <ContainerTicketBodyDate>
                    <ContainerTicketBodyDateInfo>
                      <Span>
                        {history?.startTime
                          ? formatDateAppointment(history?.startTime)
                          : ' - '}
                      </Span>
                    </ContainerTicketBodyDateInfo>
                  </ContainerTicketBodyDate>
                  <ContainerTicketBodyDate>
                    <ContainerTicketBodyDateInfo>
                      {/* <Span>{t('classTime')}</Span> */}
                      <Span>
                        {history?.startTime
                          ? formatHourAppointment(history?.startTime)
                          : ' - '}{' '}
                        -{' '}
                        {history?.endTime
                          ? formatHourAppointment(history?.endTime)
                          : ''}
                      </Span>
                    </ContainerTicketBodyDateInfo>
                  </ContainerTicketBodyDate>
                </ContainerTicketBodyPendingItens>
              </ContainerTicketBodyPending>
            </>
          ))}
        </>
      ) : null}
    </>
  );
}

export default HistoryPresence;
