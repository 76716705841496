import React, { useCallback, useEffect, useState } from 'react';

import IconFlagEngland from '../../../assets/icon_bandeira_inglaterra.png';
import IconFlagEUA from '../../../assets/icon_bandeira_usa.png';
import { Container, Button, ContainerText, Text, IconFlag } from './styles';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { socket } from '../../../services/contextSocket';
import { useTranslation } from 'react-i18next';

export function ReadingComprehensionPlanning(props) {
  const { conteudo, currentContent, setCurrentContent } = props;
  const [audioText, setAudio] = useState();
  const { t } = useTranslation();

  const handleAudioGoogle = async (languageCode, name) => {
    try {
      const voice = { languageCode, name };
      const text = document.getElementsByName('text')[0].innerText;

      console.log(voice, text);
      const { data } = await api.post(`text/synthesize`, {
        voice,
        text,
      });

      setAudio(data.fileURL);
    } catch (err) {
      console.log(err);
      toast('There is an audio recording issue. Please check your settings.', {
        type: 'error',
      });
    }
  };

  function handleArrow({ key }) {
    const classRoom = location?.state?.appointmentId;
    if (key === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return prevState;
        }

        return prevState - 1;
      });

      if (current === 0) {
        current = current;
      } else {
        current = current - 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }

    if (key === 'ArrowRight') {
      setCurrentContent(prevState => {
        if (prevState === conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });

      socket.emit('classroom:create', classRoom);

      let current = currentContent;

      if (current === 0) {
        current = current;
      } else {
        current = current + 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }
  }

  function handleArrowClick(e) {
    if (e === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return;
        }

        return prevState - 1;
      });

      const classRoom = location.state?.appointmentId;

      let current = currentContent;

      if (current === 0) {
        current = current;
      } else {
        current = current - 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }
    if (e === 'ArrowRight') {
      setCurrentContent(prevState => {
        console.log(conteudo);
        if (prevState === conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });

      const classRoom = location.state?.appointmentId;

      socket.emit('classroom:create', classRoom);

      let current = currentContent;

      if (current === conteudo.length - 1) {
        current = current;
      } else {
        current = current + 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }
  }

  function handleGoToBeginning() {
    setCurrentContent(0);

    const content = conteudo[0];
    socket.emit('classroom:classcontent', { classRoom, content });

    const contentType = 'vocabulary';
    socket.emit('classroom:classcontenttype', { classRoom, contentType });

    const methodMission = {
      method: conteudo[0]?.method?.title,
      mission: conteudo[0]?.mission?.title,
    };
    socket.emit('classroom:classcontentmethodmission', {
      classRoom,
      methodMission,
    });
  }

  useEffect(() => {
    window.addEventListener('keydown', handleArrow);

    return () => {
      window.removeEventListener('keydown', handleArrow);
    };
  });

  return (
    <Container>
      <header>
        <div className="column">
          <Button onClick={e => handleGoToBeginning()}>
            {' '}
            <span
              className="iconify"
              data-icon="dashicons:arrow-left-alt2"
              data-inline="false"
            />{' '}
          </Button>
          <ContainerText>
            <Text>
              <strong>{conteudo[currentContent]?.method?.title}</strong>
            </Text>
            <Text>{conteudo[currentContent]?.mission?.title}</Text>
            <Text finaly>
              {conteudo[currentContent]?.type === 'notes'
                ? t('friendlyReminder')
                : conteudo[currentContent]?.readingComprehension?.title}
            </Text>
          </ContainerText>
        </div>
        <div className="column">
          {currentContent !== 0 && (
            <Button left onClick={e => handleArrowClick('ArrowLeft')}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
          {currentContent !== conteudo.length - 1 && (
            <Button finaly onClick={e => handleArrowClick('ArrowRight')}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
        </div>
      </header>

      <section
        style={
          {
            /*minHeight: '1200px'*/
          }
        }
      >
        <article name="text">
          {conteudo[currentContent]?.readingComprehension?.text
            ?.split('\n\n')
            ?.map((str, key) => (
              <p key={key}>
                {str?.split('\n')?.map((_str, index) => (
                  <React.Fragment key={index}>
                    <br></br> {_str}
                  </React.Fragment>
                ))}
              </p>
            ))}
          {conteudo[currentContent]?.notes?.text
            ?.split('\n\n')
            ?.map((str, key) => (
              <p key={key}>
                {str?.split('\n')?.map((_str, index) => (
                  <React.Fragment key={index}>
                    <br></br> {_str}
                  </React.Fragment>
                ))}
              </p>
            ))}
        </article>
        <footer>
          <IconFlag
            src={IconFlagEUA}
            onClick={() => handleAudioGoogle('en-US', 'en-US-Wavenet-A')}
          />
          <audio src={audioText} type="audio/mp3" controls />
          <IconFlag
            src={IconFlagEngland}
            onClick={() => handleAudioGoogle('en-GB', 'en-GB-Wavenet-A')}
          />
        </footer>
      </section>
    </Container>
  );
}
