/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Footer from '../../../components/Footer';
import { Header } from '../../../components/Header';
import { HeaderPlanningInfo } from '../../../components/Mission/HeaderPlanningInfo';
import { QuestionsPlanning } from '../../../components/Mission/QuestionsPlanning';
import { ReadingComprehensionPlanning } from '../../../components/Mission/ReadingComprehensionPlanning';
import { VocabularyPlanning } from '../../../components/Mission/VocabularyPlanning';
import { api } from '../../../services/api';
import { customState } from '../../../utils/customState';
import {
  Button,
  Column,
  Container,
  ContainerSelect,
  Content,
  Select,
  Wrapper,
  CustomContainer,
} from './styles.planning';

export function Planning() {
  const location0 = useLocation();
  const location: any = customState(location0);
  const [pauseCounter, setPauseCounter] = useState(false);
  const [valueVerification] = useState([
    { type: 'notes', id: '0', title: 'Notes' },
    { type: 'review', id: '1', title: 'Review' },
    { type: 'vocabulary', id: '2', title: 'Vocabulary' },
    { type: 'challenge', id: '3', title: 'Questions' },
    { type: 'reading_comprehension', id: '4', title: 'Reading Comprehesion' },
  ]);

  const [currentContent, setCurrentContent] = useState(0);

  const [selectionMission, setSelectionMission] = useState();
  const [selectionMethod, setSelectionMethod] = useState();
  const [selectionType, setSelectionType] = useState();
  const [selection, setSelection] = useState();

  const [methods, setMethods] = useState([]);
  const [missions, setMissions] = useState([]);
  const [conteudo, setConteudo] = useState<any[]>([]);
  const [conteudoFilter, setConteudoFilter] = useState<any[]>([]);

  const [missionsFilter, setMissionsFilter] = useState<any[]>();

  useEffect(() => {
    const _missions: any = missions.filter(
      (e: any) => e?.method?.id === selectionMethod,
    );

    setMissionsFilter(_missions ?? []);
    if (_missions?.length > 0) {
      setSelectionMission(_missions[0]?.id);
    }
  }, [selectionMethod]);

  async function fetchMethods() {
    const { data } = await api.get('/methods');
    setMethods(data.docs);
  }

  async function fetchMissions() {
    const { data } = await api.get('/missions?type=class');
    setMissions(data.docs);
  }

  // async function fetchConteudo() {
  //   const { data } = await api.get(`/contents/?type=vocabulary`);

  //   setSelectionMethod(data.docs[0].method.id);
  //   setSelectionMission(data.docs[0].mission.id);
  //   setSelection(data.docs[0].type);
  //   setSelectionType(data.docs[0].type);
  // }

  async function fetchLocationContent() {
    const methodId = location?.state?.methodId;
    const missionId = location?.state?.missionId;
    const type = location?.state?.type;

    // const { data } = await api.get(
    //   `/contents/?method=${methodId}&mission=${missionId}&type=${type}`,
    // );

    const { data } = await api.get(
      `/contents/?method=${methodId}&mission=${missionId}`,
    );

    if (data.docs[0] === undefined) {
      toast('Problemas ao Carregar conteudo', { type: 'error' });
    } else {
      setConteudo(data.docs);
      setSelectionMethod(data.docs[0].method.id);
      setSelectionMission(data.docs[0].mission.id);
      setSelection(data.docs[0].type);
      setSelectionType(data.docs[0].type);
    }
  }
  async function getAllMissions() {
    await fetchMissions();
    await fetchMethods();
    // fetchConteudo();
    await fetchLocationContent();
  }
  useEffect(() => {
    getAllMissions();
  }, []);

  const handleClickAtualizar = async (_e: any) => {
    // const { data } = await api.get(
    //   `/contents/?method=${selectionMethod}&mission=${selectionMission}&type=${selectionType}`,
    // );

    const { data } = await api.get(
      `/contents/?method=${selectionMethod}&mission=${selectionMission}`,
    );

    console.log('data', data);

    if (data.docs[0] === undefined) {
      toast('An error occurred loading this content. Try again later.', {
        type: 'error',
      });
    } else {
      // const _index = data.docs.findIndex((e: any) => e.type === 'review');
      const _index = data.docs.findIndex((e: any) => e.type === selectionType);
      if (_index >= 0) {
        setConteudo(data.docs);
        setSelectionMethod(data.docs[0].method.id);
        setSelectionMission(data.docs[0].mission.id);
        setSelection(data.docs[_index].type);
        setSelectionType(data.docs[_index].type);
        setCurrentContent(_index);
      } else {
        toast('An error occurred loading this content. Try again later.', {
          type: 'error',
        });
      }
    }
  };

  useEffect(() => {
    const _type = conteudo[currentContent]?.type;
    setSelection(_type);
  }, [currentContent]);

  return (
    <Container>
      <Header />
      <Wrapper>
        <HeaderPlanningInfo
          pauseCounter={pauseCounter}
          setPauseCounter={setPauseCounter}
        />
      </Wrapper>
      {!pauseCounter && (
        <CustomContainer
          onCopy={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
        >
          <Content>
            {selection === 'vocabulary' && (
              <VocabularyPlanning
                conteudo={conteudo}
                currentContent={currentContent}
                setCurrentContent={(e: any) => setCurrentContent(e)}
              />
            )}
            {['challenge', 'review'].includes(String(selection)) && (
              <QuestionsPlanning
                conteudo={conteudo?.map((c) => ({
                  ...c,
                  type: c.type === 'review' ? 'challenge' : c.type,
                }))}
                currentContent={currentContent}
                setCurrentContent={(e: any) => setCurrentContent(e)}
              />
            )}
            {['reading_comprehension', 'notes'].includes(String(selection)) && (
              <ReadingComprehensionPlanning
                conteudo={conteudo}
                currentContent={currentContent}
                setCurrentContent={(e: any) => setCurrentContent(e)}
              />
            )}
          </Content>
          <ContainerSelect>
            <Select
              value={selectionMethod}
              onChange={(event: any) => setSelectionMethod(event.target?.value)}
            >
              {methods?.map((method: any, _index) => (method?.title !== undefined ? (
                <option value={method?.id}>{method?.title}</option>
              ) : (
                ''
              )))}
            </Select>
            <Select
              value={selectionMission}
              onChange={(event: any) => setSelectionMission(event.target.value)}
            >
              {missionsFilter?.map((mission: any, _index) => (mission?.title !== undefined ? (
                <option value={mission?.id}>{mission?.title}</option>
              ) : (
                ''
              )))}
            </Select>
            <Select
              value={selectionType}
              onChange={(event: any) => setSelectionType(event.target.value)}
            >
              {valueVerification.map((e, _index) => (
                <option value={e.type}>{e.title}</option>
              ))}
            </Select>
            <Button middle onClick={(e) => handleClickAtualizar(e)}>
              Update
            </Button>
          </ContainerSelect>
        </CustomContainer>
      )}
      <Footer />
    </Container>
  );
}
