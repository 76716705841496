import styled, { css } from 'styled-components';

const ContainerStyle = `
  width: 100%;
  border: 1px solid var(--gray3);
  background: #fff;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 0.8rem;

  .noIcon {
    input[type=time]::-ms-clear {
      background: none;
    }
    input[type=time]::-webkit-clear-button {
      background: none; 
    }
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Container = styled.input`
  height: ${props => props.isTextArea ? "10rem" : "4rem"};
  ${ContainerStyle}
  ${(props) =>
    props.withError &&
    css`
      border: 1px solid #f00 !important;
      margin-bottom: 2px !important;
    `};
`;

export const ContainerTextArea = styled.textarea`
  height: ${props => props.isTextArea ? "10rem" : "4rem"};
  ${ContainerStyle}
  ${(props) =>
    props.withError &&
    css`
      border: 1px solid #f00 !important;
      margin-bottom: 2px !important;
    `};
`;

export const Error = styled.p`
  align-self: flex-start;
  padding-left: 1rem;
  font-size: 1.3rem;
  color: #f00;
`;
