/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ImageNoProfile from '../../assets/no_profile.png';
import {
  Container,
  ContainerTitle,
  Text,
  Image,
  ContainerText,
  ContainerSwitch,
  ContainerRadio,
  Label,
  Input,
  Span,
  Wrapper,
  LinkTarget,
} from './styles.notify';

type NotificationType = {
  _id: string;
  title: string;
  message: string;
  link: string;
  fileURL: string;
};

type ParametersType = {
  notifications: NotificationType[];
  watchNewNotifications: (timer: number) => void;
  offNotificationsAlert: () => void;
  onNotificationsAlert: () => void;
};

export const NotificationHeader = ({
  notifications,
  watchNewNotifications,
  offNotificationsAlert,
  onNotificationsAlert,
}: ParametersType) => {
  const [notificationAlert, setNotificationAlert] = useState(
    JSON.parse(localStorage.getItem('@TableTalks:notificationAlert') as string),
  );

  const { t } = useTranslation();

  const handleNotificationPermission = () => {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        setNotificationAlert(!notificationAlert);
        localStorage.setItem(
          '@TableTalks:notificationAlert',
          (!notificationAlert).toString(),
        );
      }
    });
  };

  useEffect(() => {
    if (notificationAlert === false) {
      offNotificationsAlert();
    } else {
      onNotificationsAlert();
      watchNewNotifications(30000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationAlert]);

  return (
    <Container className="containerNotification">
      <ContainerTitle>
        <Text>{t('notifications')}</Text>
      </ContainerTitle>
      <Wrapper>
        {notifications.map((n: NotificationType) => (
          <LinkTarget to={n.link} key={n._id}>
            <Image src={n.fileURL || ImageNoProfile} />
            <ContainerText>
              <Text notification>{n.title}</Text>
              <Text subtitle>{n.message}</Text>
            </ContainerText>
          </LinkTarget>
        ))}
      </Wrapper>

      <ContainerSwitch>
        <Text alert>{t('notificationAlert')}</Text>
        <ContainerRadio>
          <Label>
            <Input
              type="checkbox"
              checked={notificationAlert}
              onChange={handleNotificationPermission}
            />
            <Span className="slider QoOBAik" />
          </Label>
          <Label />
        </ContainerRadio>
      </ContainerSwitch>
    </Container>
  );
};
