import {
  Button,
  Card, CardActions, CardContent, Grid, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MonetaryUtil } from '../../../../utils/monetary-util';
import { usePurchaseContext } from '../hooks/context-hook';

const PREFIX = 'puchase-period-plans-plans-item';

function formatDescription(description: string): string[] {
  return description.split(';');
}

function PlansItems(): React.ReactElement {
  const context = usePurchaseContext();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} justifyContent="center" padding={2}>
      {context.plans.map((planItem, index) => (
        <Grid id={`${PREFIX}-${index}`} item sm={12} md={4}>
          <Card variant="elevation">
            <CardContent>
              <Typography variant="h4">
                {planItem.title}
              </Typography>
              <br />
              <Typography variant="h5">
                R$
                {' '}
                {MonetaryUtil.round(planItem.value, 2)}
              </Typography>
              <br />
              {formatDescription(planItem.description).map((itemDescription) => (
                <Typography variant="body1">
                  {' '}
                  -
                  {itemDescription}
                </Typography>
              ))}
            </CardContent>
            <CardActions>
              <Button variant="contained" color="success" onClick={() => context.setFormData({ ...context.formData, plan: planItem })}>{t('selecionar')}</Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export {
  PlansItems,
};
