import { format } from 'date-fns';
import queryString from 'query-string';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Watch from '../../../assets/svgMethod/icon_watch_colapse.svg';
import { CollapseSchedule } from '../../../components/Button/CollapseSchedule';
import { Appointment } from './index';
import {
  Button,
  Container,
  ContainerButtons,
  ContainerHour,
  ContainerIcon,
  ContainerInfoLesson,
  ContainerTextsInfoLesson,
  ContainerTextsUser,
  ContainerUser,
  Icon,
  Image,
  List,
  Text,
} from './styles.listSchedule';

interface ListScheduleDayItemProps {
  appointment: Appointment;
  fetchAppointments: () => void;
  onCancel: () => void;
}

export const ListScheduleDayItem = ({
  appointment,
  fetchAppointments,
  onCancel,
}: ListScheduleDayItemProps) => {
  const { t } = useTranslation();

  const [showSchedule, setShowSchedule] = useState(false);

  const student = useMemo(() => appointment.attendees.find(({ type }) => type === 'student'), [appointment.attendees]) as Appointment['attendees'][0];

  console.log('appointment', appointment);

  return (
    <Container>
      <List>
        <ContainerHour>
          <ContainerIcon>
            <Icon src={Watch} />
          </ContainerIcon>
          <Text>{format(new Date(appointment.startTime), 'HH:mm')}</Text>
        </ContainerHour>

        <ContainerUser>
          <Image
            src={`https://ui-avatars.com/api/?${queryString.stringify({
              background: '#124f8c',
              color: '#fff',
              name: `${student?.name}  ${student?.lastName}`,
            })}`}
          />

          <ContainerTextsUser>
            <Text>
              {student?.name}
              {' '}
              {student?.lastName}
            </Text>
            <Text>{student?.studentLevel}</Text>
          </ContainerTextsUser>
        </ContainerUser>

        {/* <ContainerInfoLesson division>
          <ContainerIcon>
            <Icon src={Headset} />
          </ContainerIcon>
          <ContainerTextsInfoLesson>
            <Text>{t('location')}</Text>
            <Text>{appointment.location}</Text>
          </ContainerTextsInfoLesson>
        </ContainerInfoLesson> */}

        <ContainerInfoLesson division>
          <ContainerIcon>
            <span
              className="iconify"
              data-icon="clarity:note-edit-line"
              style={{ fontSize: '3rem' }}
            />
            {/* <Icon src={Coffe} /> */}
          </ContainerIcon>
          <ContainerTextsInfoLesson>
            <Text>{t('notes')}</Text>
            <Text>{appointment.lastAppointment?.comment || ''}</Text>
          </ContainerTextsInfoLesson>
        </ContainerInfoLesson>

        <ContainerButtons>
          <Button
            onClick={() => {
              if (!showSchedule) {
                setShowSchedule(true);
              } else {
                setShowSchedule(false);
              }
            }}
          >
            <span className="iconify" data-icon="akar-icons:calendar" />
            {t('rescheduleClass')}
          </Button>
          <Button cancel onClick={onCancel}>
            <span className="iconify" data-icon="eva:close-fill" />
            {t('cancel')}
          </Button>
        </ContainerButtons>
        {showSchedule && (
          <CollapseSchedule
            appointment={appointment}
            fetchAppointments={fetchAppointments}
            setShowSchedule={setShowSchedule}
          />
        )}
      </List>
    </Container>
  );
};
