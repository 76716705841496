import _ from 'lodash'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import io from 'socket.io-client';
import { getSessionUserID } from '../helpers/session';
import { useTranslation } from 'react-i18next';
import { getLastTimeSeen, toWeekDayLong } from '../helpers/time';
import { useAuth } from '../hooks/AuthProvider';
const socket = io(process.env.REACT_APP_WS_URL);
const TIME_INTERVAL = 5000
const ONLINE_DELAY_TIME = 60000

let timersessionusers

const OnlineStatusContext = createContext()
const OnlineStatusProvider = ({ children }) => {
  const [sessionUsers, setSessionUsers] = useState({})
  const { t, i18n } = useTranslation()
  const { user } = useAuth()

  // reduce sessions to object
  const reduceSessions = (sessions) => sessions
    .reduce((newSessions, {
      userId, lastSeen
    }) => ({
      ...newSessions, [userId]: { userId, lastSeen }
    }), {})

  // session users listener
  const addListenerSessionUsers = () => {
    socket.emit('session:users');
    socket.on('server:sessionusers', (sessions) => {
      setSessionUsers(reduceSessions(sessions))
      socket.off('server:sessionusers')
      clearTimeout(timersessionusers)
    })
  }

  // get all users sessions
  useEffect(() => {
    socket.emit('session:users')
    timersessionusers = setTimeout(() => addListenerSessionUsers(), 1000)
    return () => {
      clearTimeout(timersessionusers)
      socket.off('server:sessionusers')
    }
  }, [!Object.values(sessionUsers).length])

  // check if user is online
  const checkIsOnline = useCallback((userId = getSessionUserID()) => {
    const onlineUser = sessionUsers[userId]
    if (!onlineUser) return false
    const lastSeenDate = new Date(onlineUser.lastSeen).getTime()
    return lastSeenDate + ONLINE_DELAY_TIME > Date.now()
  }, [sessionUsers])

  // get last seen status
  const getLastSeen = useCallback((userId = getSessionUserID()) => {
    const onlineUser = sessionUsers[userId]
    if (!onlineUser) return false
    const { lastTimeSeen } = getLastTimeSeen(onlineUser.lastSeen)
    const lastDaySeenString = toWeekDayLong(onlineUser.lastSeen, i18n.language)
    return `${lastDaySeenString} ${t('at')} ${lastTimeSeen}`
  }, [sessionUsers])

  // new online user listener
  const addListenerOnlineUser = () => {
    socket.on('server:onlineuser', (session) => {
      const onlineUser = sessionUsers[session.userId]
      if (!onlineUser || !_.isEqual(session, onlineUser)) {
        setSessionUsers(prevState => ({ ...prevState, [session.userId]: session}))
      }
    })
  }

  // listen for Users statuses
  useEffect(() => {
    socket.on('connect', () => {
      console.log('[IO] A new connection has been established 🚨');
    });
    // add online status listener
    addListenerOnlineUser()
    // cleanup
    return () => socket.off('server:onlineuser')
  }, [])

  // send student online status
  const online = () => {
    const userId = user?.id ? user?.id : user?._id
    if (userId) {
      socket.emit('session:onlineuser', { userId, lastSeen: Date.now() })
    }
  }

  // send online status
  useEffect(() => {
    const timeronline = setInterval(() => online(), TIME_INTERVAL)
    return () => clearInterval(timeronline)
  }, [])

  return (
    <OnlineStatusContext.Provider value={{ checkIsOnline, getLastSeen }}>
      {children}
    </OnlineStatusContext.Provider>
  )
}

function useOnlineStatus() {
  const context = useContext(OnlineStatusContext)

  if (!context) {
    throw new Error(
      'useOnlineStatus must be used within an OnlineStatusProvider'
    )
  }

  return context
}

export { OnlineStatusProvider, useOnlineStatus }