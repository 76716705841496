import styled from 'styled-components';

export const Container = styled.div`
  height: 7rem;
  width: 100%;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin-top: 1.2rem;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  /* @media (max-width: 1600px) {
    display: grid;
    grid-template-areas:
      'side prof1 prof2'
      'side footer footer';
    height: 11rem;
    justify-content: start;
  } */

  @media (max-width: 990px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'side'
      'prof1'
      'prof2'
      'footer';
    height: auto;
    justify-content: start;
    gap: 1rem;
    width: 100%;
  }
`;

export const ContainerBook = styled.div`
  height: 6.8rem;
  width: 19rem;
  background: #f6f6f6;
  padding: 0 1rem;
  border-right: 1px solid var(--gray3);
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  position: relative;

  margin-right: 2rem;

  /* @media (max-width: 1600px) {
    grid-area: side;
    height: 100%;
  } */

  @media (max-width: 990px) {
    width: 100%;
  }
`;

export const ImageBook = styled.img`
  margin-right: 2rem;
`;

export const ContainerTextsBook = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 2rem;
    font-weight: 900;
    color: #243342;
  }

  span + span {
    font-size: 1.2rem;
    font-weight: 500;
    color: #707070;
  }
`;

export const Text = styled.span``;

export const Arrow = styled.div`
  border-top: 1px solid var(--gray3);
  border-right: 1px solid var(--gray3);
  background: var(--gray5);
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  margin-left: 16px;
  position: absolute;
  right: -8px;

  @media (max-width: 990px) {
    display: none;
  }
`;

export const ContainerUser = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  /* @media (max-width: 1600px) {
    grid-area: prof1;
  } */
`;

export const Image = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

export const ContainerTextsUser = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.6rem;
    color: #707070;
  }

  span + span {
    color: #b9b9c3;
    font-size: 1.4rem;
  }
`;

export const ContainerHours = styled.div`
  height: 3.5rem;
  width: 100% auto;
  padding: 0 1.2rem;
  display: flex;
  align-items: center;
  color: #243342;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin: 0 1rem;
  font-size: 1.5rem;

  .iconify {
    font-size: 1.8rem;
    margin-right: 1rem;
  }

  /* @media (max-width: 1600px) {
    grid-area: prof2;
  } */
`;

export const ContainerInfoLesson = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

export const ContainerIcon = styled.div`
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: #28c76f12;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;

export const Icon = styled.img``;

export const ContainerTextsInfoLesson = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.4rem;
    color: #707070;
  }
`;

export const ContainerButtons = styled.div`
  position: absolute;
  right: 1.2rem;

  display: flex;
  align-items: center;

  /* @media (max-width: 1600px) {
    position: revert;
    flex-wrap: wrap;
  } */

  @media (max-width: 1040px) {
    grid-area: footer;
    position: revert;
    flex-wrap: wrap;
  }
`;

export const Select = styled.select`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4rem;
  width: 100% auto;
  padding: 0 1rem;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin-left: 1rem;

  color: #243342;
  cursor: pointer;
`;
