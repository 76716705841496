/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface AnyProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const Container = styled.div<AnyProps>`
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  overflow-y: scroll;
  padding-bottom: 1.2rem;
  background: #f9f9f9;

  @media (max-width: 1280px) {
    ${(props) => props.showMenu === false
      && css`
        width: 100%;
      `}
  }

  @media (max-width: 768px) {
    ${(props) => props.showMenu === false
      && css`
        width: 100%;
      `}
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div<AnyProps>`
  width: 100%;
  padding: 0 1.2rem;
  padding-top: 1.2rem;
  display: flex;
  flex-direction: column;

  ${(props) => props.multinivel
    && css`
      padding: 0 0 0 1.2rem;
      position: relative;
    `}

  @media (max-width: 768px) {
    display: grid;
    gap: 1rem;
    padding: 5rem 0.3rem;
  }
`;

export const HeaderStage = styled.div<AnyProps>`
  background: #fff;
  width: 100%;
  height: 7.4rem;
  border: 1px solid var(--gray3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;

  @media (max-width: 1190px) {
    height: auto;
  }

  @media (max-width: 560px) {
    /* height: 19rem; */
    padding-right: 0.5rem;
  }
`;

export const CardStage = styled.div<AnyProps>`
  display: flex;
  justify-content: flex-start;
  padding: 0 2rem;
  align-items: center;
  background: var(--gray5);
  height: 7.4rem;
  width: 100%;
  max-width: 30rem;
  cursor: pointer;
  position: relative;
  border-radius: 0 0.5rem 0.5rem 0;
  border: 1px solid var(--gray3);
  border-left: 0;
  border-radius: 0.5rem;

  .arrow {
    background: var(--gray5);
    border-top: 1px solid var(--gray3);
    border-right: 1px solid var(--gray3);
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    margin-left: 16px;
    position: absolute;
    right: -8px;

    @media (max-width: 1190px) {
      display: none;
    }
  }

  @media (max-width: 1190px) {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0 0.5rem 0 1rem;
    max-width: 100%;
    height: 7.4rem;
  }

  @media (max-width: 560px) {
    max-width: 100%;
    /* display: none; */
  }
`;

export const IconStage = styled.img`
  margin-right: 2rem;

  @media (max-width: 1280px) {
    margin-right: 1.5rem;
  }
`;

export const InfoStage = styled.div<AnyProps>`
  display: flex;
  flex-direction: column;

  .column {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ${(props) => props.iconHour
    && css`
      height: 3rem;
      min-width: 3rem;
      background: #28c76f12;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    `}

  ${(props) => props.hour
    && css`
      margin-left: 2rem;

      @media (max-width: 768px) {
        margin-left: 0;
        width: 100%;
      }
    `}

  ${(props) => props.name
    && css`
      margin-left: 1rem;

      span + span {
        font-weight: 650;
      }

      @media (max-width: 768px) {
        margin-left: 0.2rem;
      }
    `}

    ${(props) => props.container
    && css`
      flex-direction: row;
      height: 100%;
      width: 100%;
      align-items: center;
      position: relative;

      .container-select {
        display: flex;
      }

      .column + .column + .column {
        display: flex;
        /* position: absolute; */
        right: 1.2rem;
        justify-content: flex-start;
      }

      @media (max-width: 1190px) {
        display: grid;

        .column:first-child {
          display: grid;
          grid-template-columns: 1fr;
        }
      }
    `} /* @media (max-width: 768px) {
    .container-select {
      display: block;
    }
  } */

  /* @media (max-width: 560px) {
    display: block;
    width: 100%;
    .container-select {
      flex-direction: column;
    }

    .column {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.2rem;
      width: 100%;

      .container-select {
        display: flex;
        flex-direction: row;
      }
    }
  } */
`;

export const Text = styled.span<AnyProps>`
  ${(props) => props.titleStage
    && css`
      font-size: 1.7rem;
      font-weight: 950;
      color: #083059;
    `}

  ${(props) => props.description
    && css`
      font-size: 1.5rem;
      font-weight: 500;
      color: var(--gray7);
    `}

    ${(props) => props.hour
    && css`
      color: #13c8a9;
      font-size: 1rem;
      font-weight: 650;
      cursor: pointer;
    `}

    ${(props) => props.time
    && css`
      font-size: 1.2rem;
      font-weight: 650;
      color: #707070;
    `}

    ${(props) => props.name
    && css`
      margin-left: 0.3rem;
      color: #083059;
      font-size: 1.4rem;
      font-weight: 500;
    `}
`;

export const ContainerHour = styled.div<AnyProps>`
  display: flex;
  border: 1px solid var(--gray3);
  margin: 0 2rem;
  height: 5.3rem;
  min-width: 25rem;
  align-items: center;
  padding: 0 1.2rem;
  border-radius: 0.5rem;

  .iconify {
    font-size: 1.5rem;
    border-radius: 50%;
    color: #083059;
  }

  @media (min-width: 1290px) {
    width: 25rem;
    height: 6rem;
  }

  @media (max-width: 1190px) {
    width: 100%;
    margin: 0;
  }

  @media (max-width: 768px) {
    margin: 0;
    height: 5rem;
    width: 100%;
  }

  @media (max-width: 560px) {
    /* margin: 1.2rem 0.3rem 0 1.3rem; */
    height: 5rem;
    width: 100%;
  }
`;

export const ImageProfile = styled.img`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 768px) {
    align-self: center;
  }
`;

export const LinkStart = styled(Link)`
  color: #fff;
  height: 3.5rem;
  font-weight: 500;
  border-radius: 0.5rem;
  margin: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #083059;
  font-size: 1.5rem;
  padding-left: 1rem;
  width: 13rem;
  text-decoration: none;

  .iconify {
    margin: 0.1rem 0.5rem 0;
  }

  @media (max-width: 768px) {
    width: 7rem;
    padding-left: 0;
  }

  ${(props) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line implicit-arrow-linebreak
    props.disabled
    && css`
      background: #e1e1e1;
    `}
`;

export const ButtonStart = styled.button`
  color: #fff;
  height: 3.5rem;
  font-weight: 500;
  border-radius: 0.5rem;
  margin: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #083059;
  font-size: 1.5rem;
  padding-left: 1rem;
  width: 16rem;
  text-decoration: none;

  .iconify {
    margin: 0.1rem 0.5rem 0;
  }

  @media (max-width: 768px) {
    width: 7rem;
    padding-left: 0;
  }

  ${(props) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line implicit-arrow-linebreak
    props.disabled
    && css`
      background: #e1e1e1;
    `}
`;

export const Button = styled.button<AnyProps>`
  color: #fff;
  height: 3.5rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .iconify {
    margin: 0.1rem 0.5rem 0;
  }

  ${(props) => props.start
    && css`
      background: #083059;
      font-size: 1.3rem;
      padding-left: 1rem;
      width: 13rem;

      @media (max-width: 768px) {
        width: 7rem;
        padding-left: 0;
      }
    `}

  ${(props) => props.cancel
    && css`
      background: #9e0d0d;
      padding-right: 1rem;
      width: 11rem;
      font-size: 1.3rem;

      @media (max-width: 768px) {
        width: 7rem;
        padding-right: 0;
      }
    `}

    @media (max-width: 768px) {
    .iconify {
      display: none;
    }

    ${(props) => props.start
      && css`
        margin-left: 1rem;
      `}

    ${(props) => props.cancel
      && css`
        margin-left: 1.1rem;
      `}
  }

  @media (max-width: 560px) {
    margin: 0.3rem;
    .iconify {
      display: none;
      margin: 0;
    }

    ${(props) => props.start
      && css`
        margin-left: 19rem;
      `}

    ${(props) => props.cancel
      && css`
        margin-left: 2rem;
      `}
  }
`;

export const Select = styled.button<AnyProps>`
  height: 3.5rem;
  font-size: 1.3rem;
  width: 13rem;
  border-radius: 0.5rem;
  margin: 0 0.5rem;
  border: 1px solid var(--gray3);
  color: #083059;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;

  ${(props) => props.message
    && css`
      width: 18rem;
    `}

  @media (max-width: 768px) {
    width: 18rem;
  }

  @media (max-width: 560px) {
    margin: 0 0.3rem;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;

  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

export const ContainerMessage = styled.div`
  height: 46rem;
  width: 100%;
  margin-top: 1.2rem;

  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;

  .header-message {
    height: 5rem;
    width: 100%;
    background: #f9f9f9;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin-bottom: 1rem;

    span {
      font-size: 1.2rem;
      font-family: 'Montserrat';
      color: #5e5873;
    }

    .iconify {
      font-size: 1.5rem;
      color: #5e5873;
      cursor: pointer;
    }
  }
`;

export const ContainerCalendar = styled.div`
  position: absolute;
  background: #fff;
  z-index: 10;
  left: -16.8rem;
  width: 130rem;
`;

export const ContainerListProffy = styled.div`
  width: 100%;
  max-height: 49rem;
  background: #fff;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.6rem;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #083059;
    border-radius: 1rem;
  }
  ::selection {
    background-color: #083059;
  }
`;
