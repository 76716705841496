/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/button-has-type */
import * as React from 'react';

interface IProps {
  changeScreen: any;
}
function LocalButtons({ changeScreen }: IProps) {
  const [showVideo, setshowVideo] = React.useState<boolean>(false);
  const [picInPicStatus, setpicInPicStatus] = React.useState<boolean>(false);
  const [mute, setMute] = React.useState<boolean>(false);
  // delete channel
  // mute main audio
  function mutemainaudio() {
    const localView: any = document?.getElementById('localView');
    localView?.srcObject.getAudioTracks().forEach((track: any) => {
      // eslint-disable-next-line no-param-reassign
      track.enabled = !track.enabled;
    });
    setMute(!mute);
  }

  // stop and show video
  function stopandshowvideo() {
    const localView: any = document?.getElementById('localView');
    localView?.srcObject.getVideoTracks().forEach((track: any) => {
      // eslint-disable-next-line no-param-reassign
      track.enabled = !track.enabled;
    });
    setshowVideo(!showVideo);
  }

  // pic in pic
  async function picInPic() {
    const remoteView: any = document?.getElementById('remoteView');
    await remoteView?.requestPictureInPicture();
    // setpicInPicStatus(!picInPicStatus);
  }
  return (
    <>
      <button onClick={mutemainaudio} className="localButton">
        {mute ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 83.44 122.88"
            style={{ fill: 'white', transform: '-ms-filter' }}
          >
            <path d="M49.02,95.45v24.11c0,1.85-1.5,3.32-3.32,3.32c-1.85,0-3.32-1.5-3.32-3.32V95.45c-10.17-0.8-19.33-5.29-26.15-12.12 C8.67,75.78,3.99,65.35,3.99,53.87c0-1.85,1.5-3.32,3.32-3.32c1.85,0,3.32,1.5,3.32,3.32c0,9.63,3.95,18.4,10.3,24.78 c6.35,6.35,15.12,10.3,24.78,10.3c9.63,0,18.4-3.95,24.78-10.3c0.12-0.12,0.24-0.24,0.36-0.36l-8.59-8.59 c-4.2,4.17-9.98,6.75-16.32,6.75c-6.38,0-12.18-2.62-16.39-6.82c-4.21-4.21-6.82-10.01-6.82-16.39V30.15L1,8.45 c-1.34-1.34-1.34-3.5,0-4.84c1.34-1.34,3.5-1.34,4.84,0l91.71,91.71c1.34,1.34,1.34,3.5,0,4.84c-1.34,1.34-3.5,1.34-4.84,0 L75.53,82.97c-0.12,0.12-0.24,0.24-0.36,0.36C68.34,90.16,59.19,94.66,49.02,95.45L49.02,95.45z M57.51,64.95L29.4,36.85V53.2 c0,4.56,1.85,8.67,4.85,11.67c3,3,7.14,4.85,11.67,4.85C50.44,69.72,54.52,67.9,57.51,64.95L57.51,64.95z M80.38,59.16 c0.26-1.73,0.4-3.49,0.4-5.29c0-1.85,1.5-3.32,3.32-3.32c1.85,0,3.32,1.5,3.32,3.32c0,3.76-0.5,7.41-1.45,10.88L80.38,59.16 L80.38,59.16z M45.92,0c6.38,0,12.18,2.62,16.39,6.82c4.21,4.21,6.82,10.01,6.82,16.39v24.7l-6.7-6.7V23.19 c0-4.56-1.85-8.67-4.85-11.67l0,0c-3-3-7.14-4.85-11.67-4.85c-4.56,0-8.67,1.85-11.67,4.85c-0.25,0.25-0.5,0.51-0.73,0.78 l-4.71-4.71c0.24-0.26,0.48-0.51,0.73-0.76C33.74,2.62,39.55,0,45.92,0L45.92,0z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 83.44 122.88"
            style={{ fill: 'white', transform: '-ms-filter' }}
          >
            <path d="M45.04,95.45v24.11c0,1.83-1.49,3.32-3.32,3.32c-1.83,0-3.32-1.49-3.32-3.32V95.45c-10.16-0.81-19.32-5.3-26.14-12.12 C4.69,75.77,0,65.34,0,53.87c0-1.83,1.49-3.32,3.32-3.32s3.32,1.49,3.32,3.32c0,9.64,3.95,18.41,10.31,24.77 c6.36,6.36,15.13,10.31,24.77,10.31h0c9.64,0,18.41-3.95,24.77-10.31c6.36-6.36,10.31-15.13,10.31-24.77 c0-1.83,1.49-3.32,3.32-3.32s3.32,1.49,3.32,3.32c0,11.48-4.69,21.91-12.25,29.47C64.36,90.16,55.2,94.64,45.04,95.45L45.04,95.45z M41.94,0c6.38,0,12.18,2.61,16.38,6.81c4.2,4.2,6.81,10,6.81,16.38v30c0,6.38-2.61,12.18-6.81,16.38c-4.2,4.2-10,6.81-16.38,6.81 s-12.18-2.61-16.38-6.81c-4.2-4.2-6.81-10-6.81-16.38v-30c0-6.38,2.61-12.18,6.81-16.38C29.76,2.61,35.56,0,41.94,0L41.94,0z M53.62,11.51c-3-3-7.14-4.86-11.68-4.86c-4.55,0-8.68,1.86-11.68,4.86c-3,3-4.86,7.14-4.86,11.68v30c0,4.55,1.86,8.68,4.86,11.68 c3,3,7.14,4.86,11.68,4.86c4.55,0,8.68-1.86,11.68-4.86c3-3,4.86-7.14,4.86-11.68v-30C58.49,18.64,56.62,14.51,53.62,11.51 L53.62,11.51z" />
          </svg>
        )}
      </button>
      <button onClick={stopandshowvideo} className="localButton">
        {showVideo ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            style={{ fill: 'white', transform: '-ms-filter' }}
          >
            <path d="M18 7c0-1.103-.897-2-2-2H6.414L3.707 2.293 2.293 3.707l18 18 1.414-1.414L18 16.586v-2.919L22 17V7l-4 3.333V7zM16 14.586L8.414 7H16V14.586zM4 19h10.879l-2-2H4V8.121L2.145 6.265C2.054 6.493 2 6.74 2 7v10C2 18.103 2.897 19 4 19z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            style={{ fill: 'white', transform: '-ms-filter' }}
          >
            <path d="M18,7c0-1.103-0.897-2-2-2H4C2.897,5,2,5.897,2,7v10c0,1.103,0.897,2,2,2h12c1.103,0,2-0.897,2-2v-3.333L22,17V7l-4,3.333 V7z M16.002,17H4V7h12l0.001,4.999L16,12l0.001,0.001L16.002,17z" />
          </svg>
        )}
      </button>
      <button onClick={changeScreen} className="localButton">
        <img
          alt="local"
          width="20px"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACT0lEQVRoge2ZP2sUQRiHf2NEEbHyTyVyoKWInyCBKJYqxHwGrfQrCEZFiKKVaC3YiDZiZ2fjF9AiYpFoGYlRjIlZHovbu4x7O7czs5ubW7mnub2dnXmfmX3nbmdWmjBhQjIAA9wHfgAPAJPaKQjgNv+ykNrJG+Aa5dxI7VYJMAdklvSadZwBc6kdnQAzwIYl/B44Aryzzm0C51O7DgCcBr5ZokvAsbzsMPDBKvsOnE3t3Ac4Dixbgl+BTug1SchH96PP6A67S0kADoTmt2OeHByVsy0yBbyyRDJg3rPuRWDbqvsa2FtXqAM8AVYKjYeyCdx1xLiTl5fxsI78BWC9hnSRP444LnmA1eL1ezzlO5JeSDoUPQKDuNJhn+P8hqRHUZHopk2Pz8As4ApU1VafmPIo6OZ8j9mabTXaAa/HV2Bb0lT+db8xZstPt1ywH9yYgfhV5UW85oB25FVHfjfw7cDYUu+PIQc4JemyrDs1KnznQFXefpJ0Mjj4COdAFcHykpaaCNxIChW453HNT0nPmwjWVAoF3fY6sYq0/ldorDpQeDzJfOqMVQckTVvHX3wq7MYkDiYf+RlJT63Tb2Ibuwn8cj2QO+o0zRpwIrYDq0Ma/u2oM2wREiN/zte3bA48c1y7JWnRUbaYl8eSSVqW9FjSGWPM2+iW6C7EX1ojErIQv0TTC/EYaPNWiCXkvdXHuG1GWWLt3Q7sMWx0CdgyTAowXZLfR0PnSVKAK7T1pUQP4CrlXE/t5g2wUJC/ldopCHZeja7nn+16NTphwn/GX5MmKwone+e2AAAAAElFTkSuQmCC"
        />
      </button>
      <button onClick={picInPic} className="localButton">
        {picInPicStatus ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            style={{ fill: 'white', transform: '-ms-filter' }}
          >
            <path d="M20 5H16.83L15 3H9L7.17 5H4C2.9 5 2 5.9 2 7V19C2 20.11 2.9 21 4 21H20C21.11 21 22 20.11 22 19V7C22 5.9 21.11 5 20 5M20 19H4V7H8.05L9.88 5H14.12L16 7H20V19M5 12H7.1C7.65 9.29 10.29 7.55 13 8.1C13.76 8.25 14.43 8.59 15 9L13.56 10.45C13.11 10.17 12.58 10 12 10C10.74 10 9.6 10.8 9.18 12H11L8 15L5 12M16.91 14C16.36 16.71 13.72 18.45 11 17.9C10.25 17.74 9.58 17.41 9 17L10.44 15.55C10.9 15.83 11.43 16 12 16C13.27 16 14.41 15.2 14.83 14H13L16 11L19 14H16.91Z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            style={{ fill: 'white', transform: '-ms-filter' }}
          >
            <path d="M20 5H17L15 3H9L7 5H4C2.9 5 2 5.9 2 7V19C2 20.11 2.9 21 4 21H20C21.11 21 22 20.11 22 19V7C22 5.9 21.11 5 20 5M5 12H7.1C7.65 9.29 10.29 7.55 13 8.1C13.76 8.25 14.43 8.59 15 9L13.56 10.45C13.11 10.17 12.58 10 12 10C10.74 10 9.6 10.8 9.18 12H11L8 15L5 12M16.91 14C16.36 16.71 13.72 18.45 11 17.9C10.25 17.74 9.58 17.41 9 17L10.44 15.55C10.9 15.83 11.43 16 12 16C13.27 16 14.41 15.2 14.83 14H13L16 11L19 14H16.91Z" />
          </svg>
        )}
      </button>
    </>
  );
}

export default LocalButtons;
