import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import LogoImage from '../../assets/icon_logo_login.png';
import LeftColumn from '../../components/Base/LeftColumn';
import Footer from '../../components/Footer';
import Input from '../../components/Form/Input';
import PhoneNumberInput from '../../components/Form/PhoneNumberInput';
import Select from '../../components/Form/Select';
import { PerfilType } from '../../enums/PerfilType';
import { getErrorID } from '../../helpers/error';
import { useAuth } from '../../hooks/AuthProvider';
import { useQuery } from '../../hooks/useQuery';
import Yup from '../../lib/yup';
import { api } from '../../services/api';
import {
  Button,
  CheckBox,
  Container,
  ContainerInput,
  ContainerLabel,
  ContainerTerms,
  ErrorContainer,
  ErrorLabel,
  Forms,
  Label,
  Logo,
  SpanTerms,
  Wrapper,
} from './styles';

interface Option {
  label: string;
  value: string;
}

interface FormData {
  name: string;
  lastName: string;
  type: Option;
  document: string;
  email: string;
  phoneNumber: string;
  birthdayDate: string;
  password: string;
  passwordConfirmation: string;
}

const defaultValidations = {
  name: Yup.string().required('nameIsARequiredField'),
  lastName: Yup.string().required('lastNameIsARequiredField'),
  type: Yup.object({
    label: Yup.string(),
    value: Yup.string().required(),
  }),
  email: Yup.string().email().required('emailIsARequiredField'),
  phoneNumber: Yup.string().required('phoneNumberIsARequiredField'),
  birthdayDate: Yup.string().required('birthdayDateIsARequiredField'),
  password: Yup.string().required('passwordIsARequiredField'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'passwordMustMatch',
  ),
};

const studentValidationSchema = Yup.object({
  ...defaultValidations,
  document: Yup.string()
    .validCPFType('cpfNeedToBeValid')
    .required('documentIsARequiredField'),
});

const teacherValidationSchema = Yup.object({
  ...defaultValidations,
  document: Yup.string()
    // .validCNPJType('cnpjNeedToBeValid') // uncomment to activate CNPJ validation
    .required('documentIsARequiredField'),
});

export const Signup = () => {
  const { t } = useTranslation();

  const { signIn } = useAuth();

  const history = useHistory();

  const { type } = useQuery();

  const [acceptedTermsOfService, setAcceptedTermsOfService] = useState(false);

  const options = [
    { value: PerfilType.STUDENT, label: `${t('Aluno')}` },
    { value: PerfilType.TEACHER, label: `${t('Professor')}` },
    { value: PerfilType.AFILIATE, label: `${t('afiliado')}`}
  ];

  function handleAcceptedTermsOfServiceChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setAcceptedTermsOfService(event.target.checked);
  }

  const handleSubmit = useCallback(
    async (data: FormData) => {
      const formSend = {
        ...data,
        document: `${data.document}`
          .split('.')
          .join('')
          .split(',')
          .join('')
          .split('-')
          .join(''),
        type: data.type.value,
      };
      try {
        if (!acceptedTermsOfService) {
          throw new Error(t('AceiteAPoliticaDePrivacidade'));
        }

        await api.post('/users', formSend);

        const { redirect, to } = await signIn({
          email: data.email,
          password: data.password,
        });

        if (redirect) {
          if (to === '/dashboard') {
            document.location.href = to;
          } else {
            history.push(to);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        let message = err?.response?.data?.message;
        const code = err?.response?.data?.code;

        // console.log({ code, message: t(message) });

        if (code !== 201) {
          if (code === 400) {
            toast.error(t(message));
            return;
          }
          if (code === 409) {
            toast.error(t('ConflictError'));
            return;
          }
          const errorID = getErrorID(err?.response?.code);
          message = t(errorID);

          toast(message, { type: 'error' });
        }
      }
    },
    [acceptedTermsOfService, history, signIn, t],
  );

  const getValidationByUser = () => (type === 'teacher' ? teacherValidationSchema : studentValidationSchema);

  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      type: options.find((o) => o.value === type) || options[0],
      document: '',
      email: '',
      phoneNumber: '',
      birthdayDate: '',
      password: '',
      passwordConfirmation: '',
      termsOfService: '',
    },
    validationSchema: getValidationByUser(),
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <LeftColumn withColumn="72%" leftIcon="-4%" />
      <Wrapper>
        <Logo src={LogoImage} />
        <Forms onSubmit={formik.handleSubmit}>
          <ContainerInput>
            <ContainerLabel>
              <Label>{t('nome')}</Label>
              <Input
                name="name"
                type="text"
                placeholder={t('nome')}
                onChange={formik.handleChange}
                value={formik.values.name}
                error={t(formik.errors.name || '')}
              />
            </ContainerLabel>
            <ContainerLabel>
              <Label>{t('sobrenome')}</Label>
              <Input
                name="lastName"
                type="text"
                placeholder={t('sobrenome')}
                onChange={formik.handleChange}
                value={formik.values.lastName}
                error={t(formik.errors.lastName || '')}
              />
            </ContainerLabel>
          </ContainerInput>
          <ContainerInput>
            <ContainerLabel>
              <Label>{t('user')}</Label>
              <Select
                name="type"
                autocomplete="off"
                options={options}
                autoFocus={false}
                value={formik.values.type}
                error={t(formik.errors.type?.value || '')}
                onChange={(e: unknown) => {
                  formik.setFieldValue('type', e);
                }}
              />
            </ContainerLabel>
          </ContainerInput>
          <ContainerInput>
            <ContainerLabel>
              <Label>{t('documento')}</Label>
              <Input
                name="document"
                type="text"
                placeholder={t('documento')}
                onChange={formik.handleChange}
                value={formik.values.document.toString()}
                error={t(formik.errors.document || '')}
              />
            </ContainerLabel>
          </ContainerInput>
          <ContainerInput>
            <ContainerLabel>
              <Label>E-mail</Label>
              <Input
                name="email"
                type="email"
                placeholder="E-mail"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={t(formik.errors.email || '')}
              />
            </ContainerLabel>
          </ContainerInput>
          <ContainerInput>
            <ContainerLabel>
              <Label>{t('telefone')}</Label>
              <PhoneNumberInput
                inputProps={{ name: 'phone', type: 'tel' }}
                onChange={(phone: string) => {
                  formik.setFieldValue('phoneNumber', phone);
                }}
                value={formik.values.phoneNumber}
                error={t(formik.errors.phoneNumber || '')}
              />
            </ContainerLabel>
            <ContainerLabel>
              <Label>{t('aniversario')}</Label>
              <Input
                name="birthdayDate"
                type="date"
                placeholder={t('aniversario')}
                onChange={formik.handleChange}
                value={formik.values.birthdayDate}
                error={t(formik.errors.birthdayDate || '')}
              />
            </ContainerLabel>
          </ContainerInput>
          <ContainerInput>
            <ContainerLabel>
              <Label>{t('Senha')}</Label>
              <Input
                name="password"
                type="password"
                placeholder={t('Senha')}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={t(formik.errors.password || '')}
              />
            </ContainerLabel>
            <ContainerLabel>
              <Label>{t('repetirSenha')}</Label>
              <Input
                name="passwordConfirmation"
                type="password"
                placeholder={t('repetirSenha')}
                onChange={formik.handleChange}
                value={formik.values.passwordConfirmation}
                error={t(formik.errors.passwordConfirmation || '')}
              />
            </ContainerLabel>
          </ContainerInput>
          <ContainerTerms>
            <div>
              <CheckBox
                name="termsOfService"
                type="checkbox"
                // value={acceptedTermsOfService}
                onChange={handleAcceptedTermsOfServiceChange}
              />
              <SpanTerms>
                {t('EuLiEAceitoA')}
                <b>
                  <a href="/policy" style={{ color: 'inherit' }}>
                    {t('PoliticaDePrivacidade')}
                  </a>
                </b>
              </SpanTerms>
            </div>
            <ErrorContainer>
              {!acceptedTermsOfService && (
                <ErrorLabel>{t('AcceptTermsError')}</ErrorLabel>
              )}
            </ErrorContainer>
          </ContainerTerms>
          <ContainerInput terms>
            <Button
              left
              onClick={() => {
                history.push('/signin');
              }}
            >
              {t('JaTenhoUmaConta')}
            </Button>
            <Button
              register
              right
              type="submit"
              disabled={formik.isSubmitting || !acceptedTermsOfService}
            >
              {t('Registrar')}
            </Button>
          </ContainerInput>
        </Forms>

        <Footer />
      </Wrapper>
    </Container>
  );
};
