import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid var(--gray3);
  height: 40rem;
  width: 100%;
  border-radius: 0 0 0.5rem 0.5rem;
`;

export const Text = styled.span`
  font-size: 1.5rem;
  color: #083059;
  ${props =>
    props.title &&
    css`
      font-size: 2rem;
      width: 100%;
      border-bottom: 1px solid var(--gray3);
      padding: 1.5rem;
      color: #5e5873;
      font-weight: 650;
      margin-bottom: 1.2rem;
    `}

  ${props =>
    props.modal &&
    css`
      font-size: 3.1rem;
      color: #5e5873;
    `}

  ${props =>
    props.name &&
    css`
      font-size: 1.4rem;
      font-weight: 650;
    `}
`;

export const ContainerCalendar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 23rem;
  width: 100%;
  margin-top: 1.2rem;
  padding: 0 1.2rem;
`;

export const Content = styled.div`
  height: 23rem;
  width: 100rem;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 1.2rem;
  margin-top: 1.5rem;

  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    height: 0.3rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 1rem;
  }
  ::selection {
    background-color: transparent;
  }

  @media (max-width: 1600px) {
    width: 90rem;
  }

  @media (max-width: 1400px) {
    width: 80rem;
  }

  @media (max-width: 1200px) {
    width: 70rem;
  }

  @media (max-width: 1000px) {
    width: 60rem;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  justify-content: space-between;
  background: #fbfbfb;
  height: 23rem;
  min-width: 14rem;
  margin: 0 1.2rem;
`;

export const Image = styled.img`
  height: 4.8rem;
  width: 4.8rem;
  object-fit: cover;
  border-radius: 50%;
`;

export const BoxSchedule = styled.div`
  height: 10rem;
  width: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 1.2rem;
  border: 1px solid #dbad00;
  border-radius: 0.5rem;

  span {
    color: #083059;
  }
`;

export const TextCalendar = styled.span`
  color: #fff;
  font-size: 1.4rem;
  width: 100%;
  text-align: center;

  ${props =>
    props.top &&
    css`
      margin-bottom: 1rem;
      margin-top: 1rem;
    `}

  .iconify {
    margin: 0 0.5rem;
  }
`;

export const Icon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -1.5rem;
  background: #dbad00;
  padding: 0.3rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;

  .iconify {
    color: #083059;
  }
`;

export const Button = styled.button`
  font-size: 1.5rem;
  height: 100%;
  color: #083059;
  cursor: pointer;

  ${props =>
    props.confirm &&
    css`
      height: 4rem;
      width: 30rem;
      align-self: center;
      margin-top: 2rem;
      background: #083059;
      color: #fff;
      padding: 0 3rem;
      border-radius: 0.5rem;
      font-weight: 500;
      cursor: pointer;
      transition: 0.2s;

      :hover {
        filter: brightness(0.8);
      }
    `}
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 50rem;
  background: #fff;
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .iconify {
    font-size: 15rem;
    color: #fff;
  }

  .container-check {
    background: #13c8a9;
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .container-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    button {
      height: 4rem;
      width: 20rem;
      background: #083059;
      color: #fff;
      border-radius: 0.5rem;
      margin-top: 3rem;
      cursor: pointer;
      transition: 0.2s;

      :hover {
        filter: brightness(0.8);
      }
    }
  }
`;

export const SpanIconClose = styled.span`
  font-size: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  color: #083059;
  cursor: pointer;
`;
