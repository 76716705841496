import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #fff;
  border-left: 1px solid var(--gray3);
  border-top: 1px solid var(--gray3);
  border-bottom: 1px solid var(--gray3);
  border-radius: 0.5rem 0 0 0.5rem;
  width: 100%;
  max-width: 32rem;

  ${props =>
    props.typeLogin === true &&
    css`
      background: transparent;
      border: 0;
    `}

  @media (max-width: 1660px) {
    grid-area: t1;
  }

  @media (max-width: 900px) {
    grid-area: t;
    max-width: 100%;
  }

  @media (max-width: 468px) {
    max-width: 100%;
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${props =>
    props.student &&
    css`
      padding-right: 1.2rem;
    `}
`;

export const ContainerTitle = styled.div`
  border-bottom: 1px solid var(--gray3);
  height: 5.8rem;
  display: flex;
  align-items: center;

  @media (max-width: 1280px) {
    padding-left: 0.5rem;
  }
`;

export const TitleInfo = styled.span`
  font-size: 2rem;
  font-weight: 650;
  color: #707070;
  width: 100%;
`;

export const ContainerCash = styled.div`
  width: 30rem;
  height: 17.5rem;
  background: #083059;
  align-self: center;
  margin: 1rem;
  border-radius: 5px;

  display: flex;
  flex-direction: column;

  select {
    height: 3.5rem;
    width: 24rem;
    background: #fff;
    color: var(--green1);
    border: 0;
    margin: 0.5rem 1rem 0.5rem;
    align-self: center;
    padding: 0 1rem;
  }

  button {
    height: 3.5rem;
    width: 26rem;
    margin: 0.5rem;
    align-self: center;
    font-weight: 300;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 375px) {
    width: 90%;

    button {
      width: 90%;
    }

    select {
      width: 90%;
    }
  }
`;

export const ContainerWithdraw = styled.div`
  width: 30rem;
  height: 11.2rem;
  background: #083059;
  align-self: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;

  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 375px) {
    min-width: 90%;
  }
`;

export const ContainerCashTitle = styled.div`
  display: flex;
`;

export const IconCash = styled.img`
  border-right: 1px solid #243342;
  border-bottom: 1px solid #243342;
  padding: 1rem 2rem;

  ${props =>
    props.student &&
    css`
      border-bottom: 0;
    `}

  ${props =>
    props.studentLesson &&
    css`
      border-bottom: 0;
      border-right: 1px solid #00000030;
    `}

  ${props =>
    props.studentExtract &&
    css`
      border: 0;
    `}

  @media (max-width: 1280px) {
    padding: 0.5rem 1rem;
  }
`;

export const ContainerAmount = styled.div`
  width: 100%;

  ${props =>
    props.extern &&
    css`
      border-bottom: 1px solid #243342;
      padding: 1rem 2rem;

      display: flex;
      flex-direction: column;
    `}

  ${props =>
    props.internal &&
    css`
      display: flex;
    `}

    ${props =>
    props.externAmount &&
    css`
      border-bottom: 1px solid #243342;
      padding: 1rem 2rem;

      display: flex;
      flex-direction: column;
    `}

  ${props =>
    props.student &&
    css`
      display: flex;
      flex-direction: column;
    `}

    @media (max-width: 1280px) {
    ${props =>
      props.extern &&
      css`
        padding: 0.5rem 1rem;
      `}

    ${props =>
      props.externAmount &&
      css`
        padding: 0.5rem 1rem;
      `}
  }
`;

export const TitleCash = styled.span`
  color: #fff;
  font-size: 1.2rem;

  ${props =>
    props.value &&
    css`
      font-size: 2.5rem;
      margin-left: 0.5rem;
      font-weight: bold;
    `}

  ${props =>
    props.amount &&
    css`
      font-size: 1.3rem;
    `}

    ${props =>
    props.withdraw &&
    css`
      margin: 1rem 0 0 2rem;
      font-size: 1.3rem;
    `}

    ${props =>
    props.withdrawValue &&
    css`
      margin: 0 2rem;
      font-size: 1.8rem;
      font-weight: 650;
    `}

    ${props =>
    props.valueTitleWithdraw &&
    css`
      font-size: 2rem;
      font-weight: bold;
    `}

    ${props =>
    props.titleStars &&
    css`
      font-size: 1.3rem;
    `}

    ${props =>
    props.countStars &&
    css`
      font-size: 1.5rem;
      font-weight: bold;
      color: #dbad00;
      margin-right: 0.5rem;
    `}

    ${props =>
    props.titleNivel &&
    css`
      font-size: 2rem;
      font-weight: bold;
    `}

    @media(max-width: 1280px) {
    ${props =>
      props.value &&
      css`
        margin-left: 0.5rem;
      `}

    ${props =>
      props.withdraw &&
      css`
        margin: 0.5rem 0 0 1rem;
      `}

    ${props =>
      props.withdrawValue &&
      css`
        margin: 0 1rem;
      `}

    ${props =>
      props.countStars &&
      css`
        color: #dbad00;
        margin-right: 0.5rem;
      `}

    ${props =>
      props.titleNivel &&
      css`
        font-weight: bold;
      `}
  }
`;

export const ContaineStars = styled.div`
  width: 30rem;
  height: 4rem;
  background: #06231f;
  align-self: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;

  @media (max-width: 1280px) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 375px) {
    min-width: 90%;
  }
`;

export const CountStars = styled.div`
  display: flex;
  align-items: center;
`;

export const Bio = styled.div`
  width: 30rem;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-self: center;

  @media (max-width: 1280px) {
    padding: 0.5rem 0;
    width: 30rem;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 375px) {
    min-width: 90%;
  }
`;

export const TextBio = styled.span`
  width: 100%;
  text-align: justify;
  font-size: 1.4rem;
  color: #6e6b7b;

  ${props =>
    props.title &&
    css`
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
      font-weight: bold;
    `}

  ${props =>
    props.coupon &&
    css`
      font-size: 1.8rem;
      margin-bottom: 0.5rem;
      font-weight: 650;
      color: #fff;
      text-align: center;
    `}

  @media (max-width: 1280px) {
    ${props =>
      props.title &&
      css`
        margin-bottom: 0.5rem;
      `}
  }
`;

export const ContaineCoupon = styled.div`
  width: 30rem;
  height: 6rem;
  background: #06231f;
  align-self: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;

  @media (max-width: 1280px) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 375px) {
    min-width: 90%;
  }
`;

export const ContainerContact = styled.div`
  width: 30rem;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-self: center;

  @media (max-width: 1280px) {
    padding: 0.5rem 0;
    width: 30rem;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 468px) {
    margin-left: 3rem;
    width: 100%;
  }
`;

export const ButtonContact = styled.button`
  height: 4.7rem;
  width: 30rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #243342;
  border-radius: 5px;
  border: 1px solid #a0a5a3;
  padding: 0 0.2rem;
  margin-bottom: 0.5rem;

  .iconify {
    font-size: 2rem;
    margin-left: 1rem;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 468px) {
    width: 90%;
  }
`;

export const TextButton = styled.span`
  border-left: 1px solid #a0a5a3;
  font-weight: 650;
  margin: 0 1rem;
  padding: 1.5rem 2rem 0;
  height: 100%;
  width: 100%;

  @media (max-width: 1280px) {
    /* margin: 0 1rem; */
    padding: 1rem 0.5rem 0 0.5em;
    font-size: 1.4rem;
  }

  @media (max-width: 468px) {
    font-size: 1.6rem;
  }
`;

// PROFILE

export const ContainerInfoClass = styled.div`
  width: 100%;
  height: 6rem;
  background: #083059;
  align-self: center;
  margin-top: 1rem;
  border-radius: 5px;

  display: flex;
  align-items: center;

  ${props =>
    props.student &&
    css`
      margin-top: 0;
    `}

  ${props =>
    props.count &&
    css`
      justify-content: space-between;
      background: #031f3a;
    `}

  ${props =>
    props.extract &&
    css`
      height: 10rem;
      flex-direction: column;
      justify-content: space-between;
    `}
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;

  ${props =>
    props.count &&
    css`
      margin-left: 0;
    `}

  ${props =>
    props.pendences &&
    css`
      flex-direction: row;
      justify-content: space-between;
      margin-left: 0;
      padding: 1.5rem 2rem;
      margin-bottom: 1rem;
      border: 1px solid var(--gray3);
      border-radius: 0.5rem;
      background: #fff;

      span {
        color: #083059;
        font-weight: bold;
        font-size: 1.7rem;
      }
    `}
`;

export const Text = styled.span`
  color: #fff;
  font-size: 1.6rem;
  height: 100%;

  display: flex;
  align-items: center;

  .iconify {
    margin-right: 0.5rem;
  }

  ${props =>
    props.student &&
    css`
      font-size: 2rem;
      font-weight: bold;
    `}

  ${props =>
    props.studentCountLesson &&
    css`
      font-size: 1.9rem;
      font-weight: bold;
    `}

  ${props =>
    props.studentTop &&
    css`
      font-size: 1.2rem;
    `}

    ${props =>
    props.studentValue &&
    css`
      font-size: 1.6rem;
    `}

    ${props =>
    props.pendences &&
    css`
      color: #707070;
      font-size: 1.6rem;
      font-weight: 65 Medium;
      margin: 2rem 0 1rem;
      height: 3rem;
    `}
`;

export const Count = styled.div`
  height: 100%;
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #00000030;

  span {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--primary-color-yellow);
  }
`;

export const ContainerExtract = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  ${props =>
    props.top &&
    css`
      border-bottom: 1px solid #243342;
      justify-content: space-between;
      padding: 0 2rem;

      span {
        margin-top: 1rem;
      }
    `}

  ${props =>
    props.topExtract &&
    css`
      justify-content: space-between;
      padding-left: 2rem;
      padding-right: 0.5rem;

      span {
        margin-top: 1rem;
      }
    `}
`;

export const Pendences = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-self: center;
`;

export const ContainerSelect = styled.div`
  height: 3.5rem;
  width: 26rem;
  margin: 1rem 1rem 0.5rem;
  align-self: center;
  padding: 0 1rem;
  background: #fff;
  display: flex;
  align-items: center;

  border-radius: 0.4rem;

  img {
    height: 3.5rem;
    width: 3.5rem;
  }

  /* @media (max-width: 900px) {
    width: 100%;
  } */
`;
