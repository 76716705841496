/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useAuth } from '../../../hooks/AuthProvider';
// import * as yup from 'yup';
import { api } from '../../../services/api';
import { maskCPF } from '../../../utils/format';
import Input from '../../Form/Input';
import {
  CompleteWithdrawContainer,
  Container,
  ErrorMessage,
  NumberFormatInput,
  SubmitButton,
} from './styles';
import './styles/styles.css';
import { banks } from './utils';

Modal.setAppElement('#root');

interface ModalRequestWithdrawProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export interface IBank {
  code: string;
}

interface FormData {
  value: number;
  bankAccount: {
    bank: IBank;
    accountName: string;
    ownerName: string;
    cpfCnpj: string;
    agency: string;
    account: string;
    accountDigit: string;
    bankAccountType: string;
  };
}

function currencyFormatter(value: string | number) {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format((value as number) / 100);

  return `${amount}`;
}

function ModalRequestWithdraw({
  isOpen,
  onRequestClose,
}: ModalRequestWithdrawProps) {
  const { user } = useAuth();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [withdrawStatus, setWithdrawStatus] = useState<'PENDING'>();

  const handleRequestWithdraw = useCallback(async (values: FormData) => {
    setIsLoading(true);

    await api
      .post('/withdrawals/request', {
        ...values,
        bankAccount: {
          ...values.bankAccount,
          cpfCnpj: values.bankAccount.cpfCnpj.replace(/[^\d]+/g, ''),
          agency: String(values.bankAccount.agency),
        },
      })
      .then((response) => {
        setWithdrawStatus(response.data.status);
      })
      .catch((err) => {
        const data = err?.response?.data;
        const errors = data?.message || data?.error?.message || [];
        errors.forEach(
          (error: any) => error?.description && toast.error(error?.description),
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  const formik = useFormik({
    initialValues: {
      value: 0,
      bankAccount: {
        bank: { code: '' },
        accountName: '',
        ownerName: user.fullName ?? user.name,
        cpfCnpj: user.document,
        agency: '',
        account: '',
        accountDigit: '',
        bankAccountType: 'CONTA_CORRENTE',
      },
    },
    validationSchema: yup.object().shape({
      value: yup
        .number()
        .required('Valor é obrigatório')
        .min(5, 'Valor mínimo de R$ 5,00')
        .max(user.balance * 100, 'Saldo insuficiente'),
      bankAccount: yup.object().shape({
        accountName: yup.string(),
        ownerName: yup.string().required('Campo obrigatório'),
        cpfCnpj: yup.string().required('Campo obrigatório'),
        agency: yup.string().required('Campo obrigatório'),
        account: yup.string().required('Campo obrigatório'),
        accountDigit: yup.string().required('Campo obrigatório'),
        bankAccountType: yup.string().required('Campo obrigatório'),
        bank: yup
          .object()
          .shape({ code: yup.string().required('Campo obrigatório') }),
      }),
    }),
    onSubmit: handleRequestWithdraw,
  });

  console.log('ERROR', formik.errors);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="request-withdraw-modal-content"
    >
      <Container isLoading={isLoading}>
        {isLoading ? (
          <Loader
            visible
            type="ThreeDots"
            color="#124f8c"
            secondaryColor="#191919"
          />
        ) : (
          !withdrawStatus && (
            <>
              <h2>{t('requestWithdrawal')}</h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="column">
                  <label className="label">Valor</label>
                  <NumberFormatInput
                    value={formik.values.value}
                    onValueChange={(values) => {
                      formik.setFieldValue('value', values.floatValue);
                    }}
                    format={currencyFormatter}
                    thousandSeparator="."
                    decimalSeparator=","
                    isNumericString
                    error={!!formik.errors.value}
                    placeholder="Valor"
                  />
                  {!!formik.errors.value && (
                    <ErrorMessage>{formik.errors.value}</ErrorMessage>
                  )}
                </div>
                <div className="column">
                  <label className="label">Tipo de conta</label>
                  <div className="row">
                    <select
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.bankAccount.bankAccountType}
                      placeholder="Tipo da conta"
                      name="bankAccount.bankAccountType"
                    >
                      <option value="" disabled selected>
                        Tipo da conta
                      </option>
                      {[
                        { label: 'Conta corrente', value: 'CONTA_CORRENTE' },
                        { label: 'Conta poupança', value: 'CONTA_POUPANCA' },
                      ].map((a) => (
                        <option value={a.value}>{a.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="column">
                  <label className="label">Instituição</label>
                  <div className="row">
                    <select
                      onChange={(event: { target: { value: any } }) => {
                        formik.setFieldValue(
                          'bankAccount.bank.code',
                          String(event.target.value),
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.bankAccount.bank.code}
                      name="bankAccount.bank.code"
                      // error={formik.errors.bankAccount?.bank?.code}
                    >
                      <option value="" disabled selected>
                        Instituição
                      </option>
                      {banks.map((a) => (
                        <option value={a.value}>
                          {`${a.value} - ${a.label}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <Input
                    placeholder={t('bankCode')}
                    type="number"
                    onChange={(event: { target: { value: any } }) => {
                      formik.setFieldValue(
                        'bankAccount.bank.code',
                        String(event.target.value),
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.bankAccount.bank.code}
                    name="bankAccount.bank.code"
                    error={formik.errors.bankAccount?.bank?.code}
                  /> */}
                </div>
                <div className="column">
                  <label className="label">Agência (sem dígito)</label>
                  <Input
                    placeholder="Agência"
                    type="number"
                    onChange={(event: { target: { value: any } }) => {
                      formik.setFieldValue(
                        'bankAccount.agency',
                        String(event.target.value),
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.bankAccount.agency}
                    name="bankAccount.agency"
                    error={formik.errors.bankAccount?.agency}
                  />
                </div>
                <div className="row">
                  <div
                    className="column"
                    style={{ width: '70%', marginRight: '1rem' }}
                  >
                    <label className="label">Conta</label>
                    <div className="row">
                      <Input
                        placeholder="Conta"
                        type="number"
                        onChange={(event: { target: { value: any } }) => {
                          formik.setFieldValue(
                            'bankAccount.account',
                            String(event.target.value),
                          );
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.bankAccount.account}
                        name="bankAccount.account"
                        maxLength={19}
                        error={formik.errors.bankAccount?.account}
                      />
                    </div>
                  </div>
                  <div className="column" style={{ width: '30%' }}>
                    <label className="label">Dígito</label>
                    <Input
                      placeholder="Dígito"
                      type="number"
                      onChange={(event: { target: { value: any } }) => {
                        formik.setFieldValue(
                          'bankAccount.accountDigit',
                          String(event.target.value),
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.bankAccount.accountDigit}
                      name="bankAccount.accountDigit"
                      maxLength={2}
                      error={formik.errors.bankAccount?.accountDigit}
                    />
                  </div>
                </div>
                <div className="column">
                  <label className="label">Nome Completo</label>
                  <Input
                    placeholder="Nome Completo"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bankAccount.ownerName}
                    name="bankAccount.ownerName"
                    error={formik.errors.bankAccount?.ownerName}
                  />
                </div>
                <div className="column">
                  <label className="label">CPF</label>
                  <Input
                    placeholder="CPF"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={maskCPF(formik.values.bankAccount.cpfCnpj)}
                    name="bankAccount.cpfCnpj"
                    error={formik.errors.bankAccount?.cpfCnpj}
                  />
                </div>

                <SubmitButton
                  type="submit"
                  disabled={!formik.isValid}
                  isLoading={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <Loader
                      visible
                      type="ThreeDots"
                      color="#fff"
                      secondaryColor="#191919"
                      height={10}
                    />
                  ) : (
                    t('requestWithdrawal')
                  )}
                </SubmitButton>
              </form>
            </>
          )
        )}
        {withdrawStatus === 'PENDING' && (
          <CompleteWithdrawContainer>
            <span className="iconify" data-icon="dashicons:yes-alt" />
            <h2>{t('transferSent')}</h2>
            <button type="button" onClick={onRequestClose}>
              {t('fechar')}
            </button>
          </CompleteWithdrawContainer>
        )}
      </Container>
    </Modal>
  );
}

export { ModalRequestWithdraw };
