import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ContainerInfo } from '../../../components/ProfileComponent/ContainerInfo';
import { StudentList } from '../../../components/ProfileComponent/StudentList';
import { ProfileComponent } from '../../../components/ProfileComponent';
import Footer from '../../../components/Footer';
import { Container, ContainerBody, Content } from './styles';
import { EditProfile } from '../../../components/ProfileComponent/EditProfile';
import { StudentsProfile } from '../../../components/ProfileComponent/StudentsProfile';
import { MyAnnotations } from '../../../components/ProfileComponent/MyAnnotations';
import { DashboardStudentMultiple } from './DashboardStudentMultiple';
import { DashboardStudentQuiz } from './DashboardStudentQuiz';
import { DashboardStudentWritten } from './DashboardStudentWritten';
import { Header } from '../../../components/Header';

export function Profile({ match }) {
  console.log('[##### PROFILE SCREEN ####]');
  return (
    <Container>
      <Header />
      <ContainerBody>
        <ContainerInfo match={match} />
        <Content>
          <Route
            exact
            path={`${match.url}`}
            render={() => <Redirect to={`${match.url}/info`} />}
          />
          <Route
            path={`${match.path}/info`}
            match={match}
            component={(props) => <ProfileComponent {...props} />}
          />
          <Route
            path={`${match.path}/liststudents`}
            component={(props) => <StudentList {...props} />}
          />
          <Route
            path={`${match.path}/editprofile`}
            match={match}
            component={(props) => <EditProfile {...props} />}
          />
          <Route
            path={`${match.path}/classes`}
            match={match}
            component={(props) => <StudentsProfile {...props} />}
          />
          <Route
            path={`${match.path}/mynote`}
            match={match}
            component={(props) => <MyAnnotations {...props} />}
          />
          <Route
            path={`${match.path}/written`}
            match={match}
            component={(props) => <DashboardStudentWritten {...props} />}
          />
          <Route
            path={`${match.path}/quiz`}
            match={match}
            component={(props) => <DashboardStudentQuiz {...props} />}
          />
          <Route
            path={`${match.path}/multiple`}
            match={match}
            component={(props) => <DashboardStudentMultiple {...props} />}
          />
        </Content>
      </ContainerBody>
      <Footer />
    </Container>
  );
}
