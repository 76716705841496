import { useEffect, useState } from 'react';
import { api } from '../../../../services/api';
import { useAuth } from '../../../../hooks/AuthProvider';
import { toast } from 'react-toastify';
import * as C from './styles';
import { useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';

export const Book = () => {
  const { user } = useAuth();

  const { pathname } = useLocation();

  const [books, setBooks] = useState([]);
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  // Carregar livros
  useEffect(() => {
    loadBooks();
  }, []);

  // Ajustar url, caso estiver sem barra no final coloque, caso contrário, permaneça da mesma maneira
  useEffect(() => {
    const newUrl = pathname.slice(-1) === '/' ? pathname : pathname + '/';
    setUrl(newUrl);
  }, [pathname]);

  const loadBooks = async () => {
    try {
      setLoading(true);
      const {
        data: { docs },
      } = await api.get(`/methods/availables?user=${user._id}`);

      setBooks(docs);
      setLoading(false);
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <>
      {loading && (
        <C.ContainerLoader>
          <Loader
            visible
            type="ThreeDots"
            color="#124f8c"
            secondaryColor="#191919"
          />
        </C.ContainerLoader>
      )}
      {!loading &&
        books.map((book, index) => (
          <C.Container
            key={index}
            onCopy={e => e.preventDefault()}
            onDragStart={e => e.preventDefault()}
          >
            <C.Card
              style={{
                filter: book.available ? 'grayscale(0)' : 'grayscale(1)',
                pointerEvents: book.available ? 'auto' : 'none',
              }}
              to={`${url + book._id}/content`}
            >
              <C.Image src={book.thumbnailURL} alt={book.title} />
            </C.Card>
            <C.Text>{book.title}</C.Text>
          </C.Container>
        ))}
    </>
  );
};
