import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 10px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  background: #f9f9f9;

  @media (max-width: 800px) {
    padding: 50px 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
`;
