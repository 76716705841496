import styled, { css } from 'styled-components';

interface TextProps {
  right?: boolean;
  headerModal?: boolean;
}

interface ModalButtonsProps {
  right?: boolean;
}

interface ButtonsCheckModalProps {
  checked?: boolean;
  close?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  width: 100%;
  color: #707070;

  span {
    font-size: 1.6rem;
  }
`;

export const Text = styled.span<TextProps>`
  ${(props) => props.right
    && css`
      border-right: 1px solid var(--gray3);
      padding-right: 1rem;
    `}

  ${(props) => props.headerModal
    && css`
      color: #5e5873;
      font-size: 1.6rem;
      font-weight: 600;
    `}
`;

export const Icon = styled.button`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4rem;
  height: 4rem;

  .iconify {
    font-size: 1.8rem;
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;
  border-bottom: 1px solid var(--gray3);
  position: relative;
  font-size: 1.5rem;
  color: #707070;

  span {
    margin-right: 1rem;
  }

  @media (max-width: 990px) {
    flex-wrap: wrap;
  }
`;

export const IconList = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #28c76f12;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;

export const Image = styled.img``;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 1.2rem;

  @media (max-width: 990px) {
    position: revert;
  }
`;

export const Button = styled.button`
  height: 4rem;
  padding: 0 1.2rem;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  color: #243342;
  cursor: pointer;

  .iconify {
    font-size: 1.6rem;
    margin-left: 0.5rem;
  }
`;

export const ContainerModal = styled.div`
  background: #fff;
  height: 40rem;
  /* width: 100rem; */
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
`;

export const ContainerHeaderModal = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 7rem;
  min-height: 7rem;
  padding: 1rem;
  border-bottom: 1px solid var(--gray3);
  position: relative;
`;

export const ContainerUser = styled.div`
  display: flex;
  align-items: center;
  margin: 0 3rem;
`;

export const ImageProfile = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

export const ContainerTextsUser = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.6rem;
    color: #707070;
  }

  span + span {
    color: #b9b9c3;
    font-size: 1.4rem;
  }
`;

export const ContainerButtonsModal = styled.div`
  position: absolute;
  right: 0;

  display: flex;
  align-items: center;
  height: 100%;

  span {
    font-size: 1.6rem;
    color: #243342;
    font-weight: 600;
    padding: 0 2rem;
  }
`;

export const ButtonsModal = styled.button<ModalButtonsProps>`
  height: 6.9rem;
  width: 6rem;
  border-left: 1px solid var(--gray3);
  border-right: 1px solid var(--gray3);

  ${(props) => props.right
    && css`
      border-right: 0;
    `}
`;

export const WrapperModal = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
`;

export const ContainerModalWrapper = styled.div`
  height: 100% auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin-bottom: 2rem;

  div + div {
    border-bottom: 0;
  }
`;

export const ContainerText = styled.div`
  border-bottom: 1px solid var(--gray3);
  padding: 4rem 2rem;
  position: relative;

  display: flex;
  align-items: center;

  span {
    font-size: 1.4rem;
    font-weight: 600;
    color: #243342;
  }
`;

export const ButtonCheckLesson = styled.button<ButtonsCheckModalProps>`
  margin-right: 2rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;

  background: ${(props) => (props.checked ? '#083059' : '#fff')};
  border: ${(props) => !props.checked && '1px solid #083059'};

  .iconify {
    color: ${(props) => (props.checked ? '#fff' : '#083059')};
    font-size: 2rem;
  }

  ${(props) => props.close
    && css`
      background: #ff0000;
      background: ${props.checked ? '#ff0000' : '#fff'};
      border: ${!props.checked && '1px solid #ff0000'};

      .iconify {
        color: ${props.checked ? '#fff' : '#ff0000'};
        font-size: 2rem;
      }
    `}
`;

export const ButtonModal = styled.button`
  height: 4rem;
  width: 100%;
  background: #243342;
  color: #fff;
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;

  .iconify {
    font-size: 1.6rem;
    margin-right: 0.5rem;
    margin-bottom: 0.3rem;
  }
`;
