import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useState } from 'react';
import ReactModal from 'react-modal';

import { isValidCPF } from '../../../../helpers/cpf';
import { api } from '../../../../services/api';
import { API_ROUTES } from '../../../../types/api-routes';
import { usePurchaseContext } from '../hooks/context-hook';

function PurchaseFormStepTwo(): React.ReactElement {
  const [modalCupom, setModalCupom] = useState<boolean>(false);
  const context = usePurchaseContext();
  const { formData } = context;
  
  function handleValidadeCupom(): void {
    api.get(`${API_ROUTES.PLANS_CUPOM}/${formData.cupomcode?.toUpperCase()}`).then((response) => {
      const { data } = response;
      context.setCommission(data);

      if (data == null) {
        context.setFormData({ ...formData, cupomcode: undefined });
        setModalCupom(true);
      }
    });
  }

  function handleCloseModal(): void {
    setModalCupom(false);
  }

  function handleChangeCpf(cpfcnpj: string): void {
    const {
      addFieldError, setFormData, formData, removeFieldError,
    } = context;

    removeFieldError('cpfcnpj');

    if (!isValidCPF(cpfcnpj)) {
      addFieldError('cpfcnpj', 'Cpf inválido');
    }
    setFormData({ ...formData, cpfcnpj });
  }
  
  function getInstallmentCount(installmentCount: number): React.ReactElement[] {
    const menuItem: React.ReactElement[] = [];
    
    for (let i = 1; i <= installmentCount; i++) {
      menuItem.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    
    return menuItem;
  }

  return (
    <>
     <ReactModal 
      contentLabel="Cupom inválido"
      isOpen={modalCupom}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>Cupom não encontrado</Grid>
          <Grid item xs={6}><Button variant="contained" color="success" fullWidth onClick={handleCloseModal}>Fechar</Button></Grid>
          <Grid item xs={6}></Grid>
        </Grid>
    </ReactModal>

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          <b>Forma de Pagamento</b>
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <FormControl error={!!context.formError?.cpf} fullWidth>
          <InputLabel htmlFor="purchase-form-cpf" error={!!context.formError?.cpfcnpj}>CPF</InputLabel>
          <OutlinedInput
            error={!!context.formError?.cpfcnpj}
            label="CPF"          
            fullWidth
            id="purchase-form-cpf"
            defaultValue={formData.cpfcnpj}
            onBlur={(event) => (handleChangeCpf(event.target.value))}
          />
          <FormHelperText id="purchase-form-numero">{context.formError?.cpfcnpj}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        {/* <FormControl fullWidth> */}
        {/*   <InputLabel htmlFor="purchase-form-codigo-indicacao">Código de indicação</InputLabel> */}
        <TextField
          id="purchase-form-codigo-indicacao"
          label="Código de indicação"
          fullWidth
          defaultValue={formData.cupomcode}
          helperText={context.commission?.fullName ? `Cupom Válido - ${context.commission?.fullName}` : ''}
          onBlur={(event) => context.setFormData({ ...formData, cupomcode: event.target.value })}
        />
        {/* </FormControl> */}
      </Grid>
      <Grid item xs={1}>
        <Button variant="contained" color="success" fullWidth onClick={handleValidadeCupom}>Validar</Button>
      </Grid>

      {/* RADIO GROUP */}
      <Grid item xs={12}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="PIX"
          name="radio-buttons-group"
          row
          onChange={(event, value) => context.setFormData({ ...formData, billingType: value })}
        >
          <FormControlLabel value="PIX" control={<Radio />} label="PIX" />
          <FormControlLabel value="CREDIT_CARD" control={<Radio />} label="Cartão de credito" />
          <FormControlLabel value="BOLETO" control={<Radio />} label="Boleto" />
        </RadioGroup>
      </Grid>

      {/* DADOS CARTAO DE CREDITO */}
      {formData.billingType === 'CREDIT_CARD' && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">
              <b>Dados do cartão de credito</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="purchase-form-credit-card-name">Nome do Cartão</InputLabel>
              <OutlinedInput
                id="purchase-form-credit-card-name"
                label="Nome do Cartão"
                defaultValue={formData.creditCard?.holderName}
                onBlur={(event) => context.setFormData({ ...formData, creditCard: {...formData.creditCard, holderName: event.target.value}})}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="purchase-form-installment-count">Número de parcelas</InputLabel>
              <Select
                labelId="purchase-form-installment-count"
                id="purchase-form-installment-count"
                value={formData.installmentCount || 1}
                label="Número de parcelas"
                onChange={(event) => context.setFormData({ ...formData, installmentCount: Number(event.target.value)})}
              >
                {getInstallmentCount(formData.plan?.installmentCount || 0)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel htmlFor="purchase-form-credit-card-number">Número do cartão</InputLabel>
              <OutlinedInput
                id="purchase-form-credit-card-number"
                label="Número do cartão"
                defaultValue={formData.creditCard?.number}
                inputMode="numeric"
                onBlur={(event) => context.setFormData({ ...formData, creditCard: {...formData.creditCard, number: event.target.value}})}
              />
            </FormControl>
          </Grid>          
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="purchase-form-credit-card-expire-month">Mês de expiração</InputLabel>
              <OutlinedInput
                id="purchase-form-credit-card-expire-month"
                label="Mês de expiração"
                defaultValue={formData.creditCard?.expiryMonth}
                inputMode="numeric"
                onBlur={(event) => context.setFormData({ ...formData, creditCard: {...formData.creditCard, expiryMonth: Number(event.target.value)}})}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="purchase-form-credit-card-expire-year">Ano de expieração</InputLabel>
              <OutlinedInput
                id="purchase-form-credit-card-expire-year"
                label="Ano de expieração"
                defaultValue={formData.creditCard?.expiryYear}
                inputMode="numeric"
                onBlur={(event) => context.setFormData({ ...formData, creditCard: {...formData.creditCard, expiryYear: Number(event.target.value)}})}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <FormControl fullWidth>
              <InputLabel htmlFor="purchase-form-credit-card-ccv">CCV</InputLabel>
              <OutlinedInput
                id="purchase-form-credit-card-ccv"
                label="CCV"
                inputMode="numeric"
                defaultValue={formData.creditCard?.ccv}
                onBlur={(event) => context.setFormData({ ...formData, creditCard: {...formData.creditCard, ccv: Number(event.target.value)}})}
              />
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>   
    </>
  );
}

export {
  PurchaseFormStepTwo,
};
