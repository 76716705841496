import ReactPlayer from 'react-player';

import { Text } from '../../styles';

export default function Video({
  title,
  url,
  desc,
  visibleSlide,
  viewModal,
  onChangeModal,
  customWidth,
  index,
  img,
}) {
  return (
    <div className="container-video">
      <div
        style={{
          backgroundImage: "url('" + img + "')",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          marginBottom: '15px',
          width: customWidth ? customWidth : '30rem',
        }}
      >
        <div
          style={{
            height: '17rem',
            cursor: 'pointer',
            alignSelf: 'center',
            marginBottom: '10px',
            textAlign: 'center'
          }}
          onClick={() => {
            viewModal ? onChangeModal(false) : onChangeModal(true);
          }}
        >
          <span
            className="iconify"
            data-icon="material-symbols:play-circle-outline"
            data-inline="false"
            style={{
              fontSize: 50,
              color: 'white',
              position: 'relative',
              top: '7rem'
            }}
          />
        </div>
      </div>
      <Text title>
        {title?.toString()?.substring(0, 50)}{' '}
        {title?.toString()?.length > 50 ? '...' : ''}
      </Text>
      <Text>
        {desc?.toString()?.substring(0, 150)}{' '}
        {desc?.toString()?.length > 150 ? '...' : ''}
      </Text>
    </div>
  );
}
