/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FiPauseCircle as PauseIcon,
  FiPlayCircle as PlayIcon,
} from 'react-icons/fi';

import IconHeader from '../../../assets/logo_top_banner.png';
import IconBreakthrough from '../../../assets/icon_breakthrough.png';
import IconWatch from '../../../assets/icon_watch.svg';
import { useAuth } from '../../../hooks/AuthProvider';
import { customState } from '../../../utils/customState';
import { api } from '../../../services/api';
import { socket } from '../../../services/contextSocket';
import {
  Container,
  ContainerInfo,
  ImageHeader,
  Column,
  Hour,
  Text,
  ContainerPauseButton,
  ContainerCounter,
} from './styles';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

export function HeaderPlanningInfo(props) {
  const location0 = useLocation();
  const location = customState(location0);
  const history = useHistory();

  const [[mins, secs], setTime] = useState([0, 0]);
  const [method, setMethod] = useState({});
  const [timeInMethod, setTimeInMethod] = useState(0);
  const { t } = useTranslation();

  function loadTimeData() {
    const time = location?.state?.time;
    const timeData = time.split(':');

    if (timeData[1] == 0) {
      let min = timeData[0] - 1;
      setTime([min, 59]);
    } else {
      setTime([timeData[0], timeData[1]]);
    }
  }

  async function fetchMethod() {
    const { data } = await api.get(`/methods/${location?.state?.methodId}`);

    if (data) {
      setMethod(data);
    }
  }

  useEffect(() => {
    loadTimeData();
    fetchMethod();
  }, []);

  function setNewTime(time) {
    try {
      const data = {
        teacher: location?.state?.teacher,
        hour: time,
      };

      socket.emit('teacherplanning:updatehour', data);
    } catch (err) {
      console.log(err);
      toast('Ocorreu um erro ao salvar seus dados!', { type: 'success' });
    }
  }

  const tick = async () => {
    const MaxTimeMethodTwoHoursInSecond = 7200;

    if (timeInMethod >= MaxTimeMethodTwoHoursInSecond) {
      toast.warn(t('maxTimeInMethodAlert'));
      history.push({
        pathname: '/dashboard/method',
      });
      return;
    }

    if (Number(mins) === 0 && secs === 0) {
      setNewTime('00:00');
      history.push({
        pathname: '/dashboard/method',
      });
    } else if (secs === 0) {
      setNewTime(`${mins}:00`);
      setTime([mins - 1, '59']);
    } else {
      setTime([mins, secs - 1]);
    }

    setTimeInMethod(timeInMethod + 1);
  };

  useEffect(() => {
    const timerId = setInterval(
      () => (props.pauseCounter ? null : tick()),
      1000,
    );
    return () => clearInterval(timerId);
  });

  const { typeLogin, user } = useAuth();
  return (
    <Container>
      <header>
        <img className="image-banner" src={IconHeader} alt="" />
        {user.type === 'teacher' && (
          <Text titleHeader>
            {t('haveTwoHoursPerWeek')}
            {t('itWillUpdateEveryMonday')}
          </Text>
        )}
      </header>
      <ContainerInfo typeLogin={typeLogin}>
        <Column columnleft>
          <ImageHeader src={IconBreakthrough} />
          <Text left>
            <strong>{method.title}</strong>
          </Text>
        </Column>
        <Column columnright>
          <ContainerCounter>
            <ContainerPauseButton
              onClick={() => {
                props.setPauseCounter(!props.pauseCounter);
                history.push({
                  pathname: '/dashboard/method',
                });
              }}
            >
              {!props.pauseCounter ? <PauseIcon /> : <PlayIcon />}
            </ContainerPauseButton>
            <Hour>
              <img src={IconWatch} alt="" />
              <div className="container-text">
                <Text>
                  <strong>
                    {`${mins.toString().padStart(2, '0')}:${secs
                      .toString()
                      .padStart(2, '0')}`}{' '}
                    min
                  </strong>
                </Text>
                <Text textTime>{t('remaining')}</Text>
              </div>
            </Hour>
          </ContainerCounter>
        </Column>
      </ContainerInfo>
    </Container>
  );
}
