import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface TextProps {
  subtitle?: boolean;
  mail?: boolean;
}

interface LinksProps {
  bottom?: boolean;
}

export const Container = styled.div`
  width: 35rem;
  height: 26rem;
  background: #fff;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  position: absolute;
  right: 0;
  top: 4rem;

  display: flex;
  flex-direction: column;
  z-index: 9;

  @media (max-width: 560px) {
    width: auto;
  }
`;

export const ContainerUser = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2rem;
  width: 100%;
  height: 8rem;
  border-bottom: 1px solid var(--gray3);
  position: relative;
  cursor: pointer;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const Image = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
`;

export const Text = styled.span<TextProps>`
  font-size: 1.6rem;
  color: #6e6b7b;
  font-weight: 600;

  ${(props) => props.subtitle
    && css`
      font-size: 1.4rem;
      color: #b9b9c3;
      font-weight: 500;
    `}

  ${(props) => props.mail
    && css`
      font-size: 1.2rem;
      color: #b9b9c3;
      font-weight: 500;
    `}
`;

export const BadgeStatus = styled.span`
  height: 1.4rem;
  width: 1.4rem;
  background: #28c76f;
  border-radius: 50%;
  position: absolute;
  border: 2px solid #fff;
  left: 5.5rem;
  top: 5rem;
`;

export const ContainerLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
`;

export const Links = styled(Link)<LinksProps>`
  font-size: 1.8rem;
  color: #6e6b7b;
  font-weight: 600;
  text-decoration: none;
  padding: 1rem 0;
  border-bottom: 1px solid var(--gray3);

  ${(props) => props.bottom
    && css`
      border: 0;
    `}
`;
