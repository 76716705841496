import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import IconFlagEngland from '../../../../../assets/icon_bandeira_inglaterra.png';
import IconFlagEUA from '../../../../../assets/icon_bandeira_usa.png';
import { api } from '../../../../../services/api';
import { Container, Button, ContainerText, Text, IconFlag } from './styles';

let audio = null;

export function Questions(props) {
  const { conteudo, currentContent, setCurrentContent } = props;
  const { t } = useTranslation();

  const handleAudioGoogle = async (languageCode, name, text) => {
    try {
      const voice = { languageCode, name };

      const { data } = await api.post(`text/synthesize`, {
        voice,
        text,
      });

      if (audio) audio.pause();
      audio = new Audio(data.fileURL);
      audio.play();
    } catch (err) {
      console.log(err);
      toast('There is an audio recording issue. Please check your settings.', {
        type: 'error',
      });
    }
  };

  function handleArrow({ key }) {
    if (key === 'ArrowLeft') {
      document.querySelector('.arrow-left')?.click();
    }

    if (key === 'ArrowRight') {
      document.querySelector('.arrow-right')?.click();
    }
  }

  function handleArrowClick(e) {
    if (e === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return;
        }

        return prevState - 1;
      });
    }
    if (e === 'ArrowRight') {
      setCurrentContent(prevState => {
        if (prevState === conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleArrow);

    return () => {
      window.removeEventListener('keydown', handleArrow);
    };
  });

  return (
    <Container>
      <header>
        <div className="column">
          {currentContent === conteudo.length - 1 && (
            <Button onClick={() => setCurrentContent(0)}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}

          <ContainerText>
            <Text>
              <strong>{conteudo[currentContent]?.method?.title}</strong>
            </Text>
            <Text>{conteudo[currentContent]?.mission?.title}</Text>
            <Text finaly>{t('questoes')}</Text>
          </ContainerText>
        </div>
        <div className="column">
          {currentContent !== 0 && (
            <Button
              left
              onClick={e => handleArrowClick('ArrowLeft')}
              className="arrow-left"
            >
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
          {currentContent !== conteudo.length - 1 && (
            <Button
              finaly
              onClick={e => handleArrowClick('ArrowRight')}
              className="arrow-right"
            >
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
        </div>
      </header>
      <div className="boxQuestions">
        {conteudo[currentContent]?.questions?.map((question, index) => (
          <section>
            <Text list>{question.text}</Text>
            <div>
              <IconFlag
                src={IconFlagEUA}
                onClick={() =>
                  handleAudioGoogle('en-US', 'en-US-Wavenet-A', question.text)
                }
              />
              <IconFlag
                src={IconFlagEngland}
                onClick={() =>
                  handleAudioGoogle('en-GB', 'en-GB-Wavenet-A', question.text)
                }
              />
            </div>
          </section>
        ))}
      </div>
    </Container>
  );
}
