import styled from 'styled-components';

import BackgroundGreen from '../../assets/background_login_gren.png';

export const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundGreen});
  background-size: cover;
  margin-right: auto;
  margin-left: auto;
`;

export const LogoutButton = styled.button`
  position: relative;
  color: white;
  font-size: 16px;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 24px;
  padding-bottom: 24px;

  @media (min-width: 0px) {
    max-width: 1200px;
  }

  @media (min-width: 600px) {
    padding: 24px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img``;

export const ContainerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoFooter = styled.img``;

export const TextFooter = styled.span`
  color: #fff;
  margin-left: 1rem;
`;

export const ButtonRecord = styled.button`
  height: 7rem;
  width: 7rem;
  background: #f9f9f9;
  border-radius: 50%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
`;

export const ContainerRecord = styled.div`
  height: 5rem;
  width: 5rem;
  background: #ebe9f1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageRecord = styled.img``;
