import React, { useEffect, useState, useCallback, createRef } from 'react';
import { Chat } from '../../../components/Base/Chat';
import Footer from '../../../components/Footer';
import Session from '../../../components/Kinesis/Session';
import { Vocabulary } from '../../../components/Mission/Vocabulary';
import { Questions } from '../../../components/Mission/Questions';
import { ReadingComprehension } from '../../../components/Mission/ReadingComprehension';
import { PlacementTest } from '../../../components/Mission/PlacementTest';
import { HeaderInfo } from '../../../components/Mission/HeaderInfo';
import { useAuth } from '../../../hooks/AuthProvider';
import { useLocation, Prompt } from 'react-router-dom';
import { socket } from '../../../services/contextSocket';
import { useScreenshot } from 'use-react-screenshot';
import Modal from 'react-modal';
import {
  Container,
  Wrapper,
  Content,
  ColumnContent,
  ContainerImage,
  Image,
  ContainerSelect,
  Column,
  Select,
  Button,
  ApplyOralExam,
  Card,
} from './styles';
import { useHistory } from 'react-router-dom';
import { Header } from '../../../components/Header';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { Exams } from '../../../components/Mission/Exams';
import { FinishModal } from './FinishModal/index';
import { useTranslation } from 'react-i18next';
import { useOnlineStatus } from '../../../context/OnlineStatusContext';
import { getLevelFromExam, getTypeFromExam } from '../../../helpers/exam';
import { ExamsAppliedModal } from '../../../components/Modals/ExamsAppliedModal';
import { customState } from '../../../utils/customState';
export function Mission() {
  const location0 = useLocation();
  const location = customState(location0);
  const { t } = useTranslation();

  const { user, typeLogin } = useAuth();
  const classRoom = location?.state?.appointmentId;

  const classUsers = location?.state?.user;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [canClose, setCanClose] = useState(true);

  const [showPlacements, setShowPlacements] = useState(false);
  const [valueVerification] = useState([
    { type: 'review', id: '0', title: 'Review' },
    { type: 'vocabulary', id: '1', title: 'Vocabulary' },
    { type: 'challenge', id: '2', title: 'Questions' },
    { type: 'reading_comprehension', id: '3', title: 'Reading Comprehesion' },
  ]);

  const [currentContent, setCurrentContent] = useState(0);

  const [studentOnline, setStudentOnline] = useState(false);
  const [lastSeenStudent, setLastSeenStudent] = useState(0);

  const [WaitedSince, setWaitedSince] = useState();

  const [selectionMission, setSelectionMission] = useState('');
  const [selectionMethod, setSelectionMethod] = useState('');
  const [selectionType, setSelectionType] = useState();
  const [selection, setSelection] = useState();

  const [methods, setMethods] = useState([]);
  const [nextMethodObj, setNextMethodObj] = useState();
  const [missions, setMissions] = useState([]);
  const [allMissions, setAllMissions] = useState([]);
  const [finalExamsApplied, setFinalExamsApplied] = useState(false);
  const [oralExamToApply, setOralExamToApply] = useState();
  const [showStudentExamsAppliedModal, setShowStudentExamsAppliedModal] =
    useState(false);

  const [isApplyExamVisible, setIsApplyExamVisible] = useState(false);

  const [examType, setExamType] = useState('oral');
  const [examLevel, setExamLevel] = useState('midterm');
  const [reloads, setReloads] = useState(0);

  const [conteudo, setConteudo] = useState([]);
  const [closeSession, setCloseSession] = useState(false);
  const [handleTakePhoto, setTakePhoto] = useState(false);
  const [handleUploadPhoto, setUploadPhoto] = useState(false);
  const [student, setStudent] = useState();
  const [restartConnection, setRestartConnection] = useState(false);

  const [isLateFinishClass, setIsLateFinishClass] = useState(false);

  const { checkIsOnline } = useOnlineStatus();

  function handleIsLateFinishClass(value) {
    if (!isLateFinishClass && value) {
      setIsLateFinishClass(value);
    }
  }

  useEffect(() => {
    socket.on('connect', () => {
      console.log('[IO] A new connection has been established 🚨');
      socket.emit('classroom:create', classRoom);
    });
  }, []);

  async function fetchMethods(_student) {
    if (student && !_student) _student = student;

    const { data } = await api.get('/methods');
    let _data = [];

    const _methodCurrentId =
      _student?.level?.method?.id ?? location?.state?.method?.id;
    if (Array.isArray(data?.docs) && _methodCurrentId) {
      const foundIndex = data.docs.findIndex(e => e.id === _methodCurrentId);
      if (foundIndex >= 0) {
        _data.push(data.docs[foundIndex]);

        if (data?.docs[foundIndex + 1]._id) {
          setNextMethodObj(data?.docs[foundIndex + 1]);
        }
      }
    }

    setMethods(_data);
    if (_data.length > 0) {
      await fetchMisionsByMethod(_methodCurrentId, _student);
    }
  }

  async function fetchLocationContent(_student) {
    if (student && !_student) _student = student;

    const methodId = _student?.level?.method?.id ?? location?.state?.method?.id;
    const missionId =
      _student?.level?.mission?.id ?? location?.state?.mission?.id;

    const { data } = await api.get(
      `/contents/?method=${methodId}&mission=${missionId}`,
    );

    if (data.docs[0] == undefined) {
      toast(
        'There is a problem loading the content. Please check your internet connection.',
        { type: 'error' },
      );
    } else {
      const _content = data.docs.filter(e => e.type !== 'notes');

      setSelectionMethod(methodId ?? _content[0].method.id);
      setSelectionMission(missionId ?? _content[0].mission.id);
      setConteudo(_content);
      setSelection(_content[0].type);
      setSelectionType(_content[0].type);
    }
  }

  async function fetchStudent() {
    const response = await api.get(
      `/users/student/${location?.state?.user?.student.id}`,
    );
    setStudent(response.data);
    return response.data;
  }

  // isolating next mission logic
  const getNextMission = async (methodId, missionId, type) => {
    let url = `/missions/?method=${methodId}`;
    if (type) {
      url = `/missions/?method=${methodId}&type=${type}`;
    }
    const { data } = await api.get(url);

    let nextMethod = methods.find(method => method.id === methodId);
    let nextMission = null;

    const indexFound = data?.docs?.findIndex(
      mission => mission.id === missionId,
    );
    if (indexFound >= 0) {
      const nextIndex = indexFound + 1;
      if (data?.docs?.length > nextIndex) {
        nextMission = data?.docs[nextIndex];
      } else if (nextMethodObj.id) {
        const resMissionsNext = await api.get(
          `/missions/?method=${nextMethodObj.id}`,
        );
        if (resMissionsNext?.data?.docs?.length > 0) {
          nextMethod = nextMethodObj;
          nextMission = resMissionsNext.data.docs[0];
        }
      }
    }

    return { nextMethod, nextMission };
  };

  const getExamLevelAndType = exam => {
    const examLevel = getLevelFromExam(exam);
    const examType = getTypeFromExam(exam);

    return { examLevel, examType };
  };

  // handle exams content gap
  const handleExamMissions = async (nextMission, _mission) => {
    const sameMethod = _mission.method === nextMission.method;
    try {
      // filter missions that are tests (to apply)
      const examMissions = allMissions.filter(mission => {
        return (
          mission.order + 1 > _mission.order &&
          (!sameMethod || mission.order < nextMission.order) &&
          mission.type === 'test'
        );
      });

      // get pending exams on next position order
      const [open, close, oralExamToApply] = [...examMissions].slice(0, 3);

      for (const exam of [open, close]) {
        // get exam level and type
        const { examLevel, examType } = getExamLevelAndType(exam);
        try {
          // apply exam
          await applyExam(examLevel, examType, exam);
        } catch (err) {
          toast(`${exam.title} ${t('examAlreadyApplied')}`, {
            type: 'success',
          });
          console.error(err);
        }
      }

      return { oralExamToApply };
    } catch (err) {
      console.log('error', err);
      return err;
    }
  };

  // multiple purpose for Next Mission button (warn)
  const onNextMission = async () => {
    let nextCount = localStorage.getItem(`class-${classRoom}-nextbutton`)
    if (!nextCount) {
      nextCount = '0';
    }
    nextCount = parseInt(nextCount);
    const _method = student?.level?.method;
    const _mission = student?.level?.mission;
    const studentMethodSelected = _method.id === selectionMethod;
    const studentMissionSelected = _mission.id === selectionMission;

    // get student next mission
    let { nextMethod, nextMission } = await getNextMission(
      selectionMethod,
      selectionMission,
      null,
    );

    // check if next mission is the next on order
    const isMissionNextOnOrder =
      _mission?.order &&
      nextMission?.order &&
      _mission?.order + 1 === nextMission.order;
    console.log(nextMission);
    console.log(isMissionNextOnOrder);
    console.log(_mission);
    console.log(finalExamsApplied);
    // check is not on student level
    const isNotOnStudentLevel =
      !studentMethodSelected || !studentMissionSelected;

    // check next mission order
    // check final exams applied
    const errorMessage2Classes = 'You cannot teach more than 2 lessons per class. Do a review instead.';
    if (isMissionNextOnOrder && nextMission.type === 'test') {
      if (nextCount >= 2) {
        toast(
          errorMessage2Classes,
          { type: 'error' },
        );
        return false;
      }
      nextCount += 1;
      localStorage.setItem(`class-${classRoom}-nextbutton`, nextCount);
      // handle exam missions
      let { oralExamToApply } = await handleExamMissions(nextMission, _mission);
      // check pending exams
      if (oralExamToApply) {
        setOralExamToApply(oralExamToApply);
      }
    } else if (isMissionNextOnOrder || finalExamsApplied) {
      // if student level is selected
      if (studentMethodSelected && studentMissionSelected) {
        if (nextCount >= 2) {
          toast(
            errorMessage2Classes,
            { type: 'error' },
          );
          return false;
        }
        nextCount += 1;
        localStorage.setItem(`class-${classRoom}-nextbutton`, nextCount);
        // reset pending exams
        if (nextMethod.order !== _method.order) {
          setFinalExamsApplied(false);
        }
        // elevate level
        elevateStudentLevel(nextMethod, nextMission);
      }
    } else if (isNotOnStudentLevel) {
      // avoid changes on errors
      try {
        if (nextMission.type === 'test') {
          const tempMission = await getNextMission(
            selectionMethod,
            selectionMission,
            'class',
          );
          nextMethod = tempMission.nextMethod;
          nextMission = tempMission.nextMission;
        }
        setSelectionMethod(nextMethod.id);
        setSelectionMission(nextMission.id);
        await handleUpdateContent(nextMethod.id, nextMission.id);
      } catch (err) {
        toast(
          'Cannot update the content. Please check your internet connection.',
          { type: 'error' },
        );
      }
    }
  };

  const refreshMission = async () => {
    let myStudent = await fetchStudent();
    const _method = myStudent?.level?.method;
    const _mission = myStudent?.level?.mission;
    setSelectionMethod(_method.id);
    setSelectionMission(_mission.id);

    await handleUpdateContent(_method.id, _mission.id);
    await fetchMethods(myStudent);
    // await fetchLocationContent(myStudent);
  };

  // stutent level elevation logic
  const elevateStudentLevel = useCallback(
    async (_nextMethod, _nextMission) => {
      if (_nextMethod?.id && _nextMission?.id) {
        try {
          await api
            .post('/students/next-mission', {
              method: _nextMethod?.id,
              mission: _nextMission?.id,
              student: location.state?.user.student.id,
            })
            .then(_ => {
              // Nivelar aluno
              setSelectionMethod(_nextMethod?.id);
              setSelectionMission(_nextMission?.id);
              handleUpdateContent(_nextMethod?.id, _nextMission?.id);

              // Atualizar state do estudante
              const _student = { ...student };
              _student.level.method = _nextMethod;
              _student.level.mission = _nextMission;
              setStudent(_student);

              if (_nextMethod._id === nextMethodObj._id) {
                fetchMethods();
              } else {
                // Acrescentar mais uma missao no filtro
                const _missionsState = [...missions];
                _missionsState.push(_nextMission);
                setMissions(_missionsState);
              }

              setSelectionMission(_nextMission?.id);
            });
        } catch (err) {
          toast.error(
            'An error occurred loading this content. Try again later.',
          );
        }
      } else {
        toast.error('There are no more lessons.');
      }
    },
    [student, missions],
  );

  async function getClassInitialData() {
    let result = await api.get(`/appointments/${classRoom}`);
    if (result.data.lastSeenStudent) {
      setLastSeenStudent(result.data.lastSeenStudent);
    }
  }

  async function startAppointment() {
    await api.patch(`/appointments/start/${classRoom}`);
  }

  const onIsWait = value => {
    if (value) {
      const timeStamp = new Date().getTime();
      setWaitedSince(timeStamp);
    } else {
      setWaitedSince(null);
    }
  };
  const cancelClassErrorConnection = async () => {
    let reasonConnection = 'Problems to connect camera and audio with teacher';
    await api.get(
      `/actions/teacher/cancel/before/${classRoom}?reason=${reasonConnection}`,
    );

    toast(t('yourLessonHasBeenCancelledDueCamera'), {
      type: 'warning',
    });
    socket.emit('classroom:create', classRoom);
    socket.emit('classroom:finishclass', { classRoom });
    setCloseSession(true);
  };
  // Verificar se estudante esta online;
  useEffect(() => {
    let interval = null;

    const aliveStudent = () => {
      const timeStamp = new Date().getTime();
      let online = false;
      if (lastSeenStudent) {
        online = true;
      }
      if (online && studentOnline !== online) {
        setStudentOnline(online);
      }

      if (WaitedSince > 0 && timeStamp - WaitedSince > 30000 && online) {
        // if (true) {
        setTimeout(async () => {
          let preventReload = false;
          setReloads(reloads + 1);
          if (reloads[1] > 50) {
            // cancel class
            preventReload = true;
            await cancelClassErrorConnection();
          }
          if (!preventReload) {
            // window.location.reload();
            setRestartConnection(true);
            toast('Starting connection with video class!', { type: 'success' });
          }
        }, 1500);
      }
    };

    interval = setInterval(() => aliveStudent(), 8000);
    aliveStudent();

    return () => {
      clearInterval(interval);
    };
  }, [lastSeenStudent, WaitedSince]);
  /* end */

  // Mounted
  useEffect(() => {
    createClassRoom();
    startAppointment();
    getClassInitialData();

    (async () => {
      const _student = await fetchStudent();
      await fetchMethods(_student);
      await fetchLocationContent(_student);
    })();

    // Estudante esta avisando que esta online
    socket.on('classroom:serverstatusstudent', room => {
      if (room === classRoom) {
        const timeStamp = new Date().getTime();
        setLastSeenStudent(timeStamp);
      }
    });
    /* end */

    // Avisando estudante que estou online
    let intervalTeacher = null;
    function alive() {
      socket.emit('classroom:statusteacher', { classRoom, teacherId: user.id });
    }
    intervalTeacher = setInterval(() => alive(), 7500);
    alive();
    /* end */

    // unmounted
    return () => {
      clearInterval(intervalTeacher);
    };
  }, []);
  /* end - Mounted */

  useEffect(() => {
    fetchStudent();
  }, [showPlacements]);

  useEffect(() => {
    if (restartConnection) {
      setTimeout(() => {
        setRestartConnection(false);
      }, 1000);
    }
  }, [restartConnection]);

  function createClassRoom() {
    socket.emit('classroom:create', classRoom);
  }

  const handleUpdateContent = async (_selectionMethod, _selectionMission) => {
    // prevent to show oral exam message when changing content
    setOralExamToApply(null);

    const { data } = await api.get(
      `/contents/?method=${_selectionMethod ?? selectionMethod}&mission=${
        _selectionMission ?? selectionMission
      }`,
    );

    const _content = data.docs.filter(e => e.type !== 'notes');
    if (!_content) {
      return false;
    }
    if (_content[0] == undefined) {
      toast('An error occurred loading this content. Try again later.', {
        type: 'error',
      });
    } else {
      if (!_selectionMethod) {
        const _content = data.docs.filter(e => e.type === selectionType);
        if (_content[0] == undefined) {
          toast('An error occurred loading this content. Try again later.', {
            type: 'error',
          });
          return false;
        }
      }
      setConteudo(_content);

      let _index = _content.findIndex(e => e.type === selectionType);
      if (_selectionMethod) {
        _index = 0;
      }
      console.log(_selectionMethod);
      console.log(_index);
      if (_index >= 0) {
        setSelectionType(_content[_index].type);
        console.log(_content[_index]);
        setCurrentContent(_index);
        const contentType = _content[_index].type;
        setSelection(contentType);
        socket.emit('classroom:classcontent', {
          classRoom,
          content: _content[_index],
        });
        socket.emit('classroom:classcontenttype', { classRoom, contentType });
      }
    }
  };

  async function fetchMisionsByMethod(method, _student) {
    if (student && !_student) _student = student;

    setSelectionMethod(method);

    const { data } = await api.get(`/missions/?method=${method}`);

    // save all mission including exams
    setAllMissions(data?.docs);

    let _data = [...data?.docs];

    const _missionCurrentId =
      _student?.level?.mission?.id ?? location?.state?.mission?.id;
    if (Array.isArray(data?.docs) && _missionCurrentId) {
      const found = data.docs.find(e => e.id === _missionCurrentId);
      if (found?.order) {
        _data = data.docs.filter(e => e.order <= found.order);
      }
    }

    setMissions(_data.filter(mission => mission.type === 'class'));
    setSelectionMission(_missionCurrentId);
  }

  useEffect(() => {
    console.log('CURRENT CONTENT', currentContent);
    const _type = conteudo[currentContent]?.type;
    setSelection(_type);
  }, [currentContent]);

  const onLevelChange = async ({ method, mission }) => {
    setSelectionMethod(method);
    setSelectionMission(mission);
    await handleUpdateContent(method, mission);

    let myStudent = await fetchStudent();
    await fetchMethods(myStudent);
    // await fetchLocationContent(_student);
  };

  const onHideContentStudent = (hide = false) => {
    const _content = {
      ...conteudo[currentContent],
      hideContentStudent: hide ? 1 : 0,
    };
    socket.emit('classroom:classcontent', { classRoom, content: _content });
  };

  const closeModalExam = () => {
    setIsApplyExamVisible(false);
  };

  // decouple apply exam call
  const applyExam = async (_examLevel, _examType, exam) => {
    if ((_examType || examType) === 'oral') {
      setExamLevel(_examLevel);
      setExamType('oral');
      setIsApplyExamVisible(true);
      return;
    }

    await api
      .post('/exams/student/apply', {
        method: exam.method,
        student: location.state.user.student.id,
        level: _examLevel || examLevel,
        type: _examType || examType,
      })
      .then(response => {
        console.error(response);
      });

    toast(t('examSuccessfullyApplied'), { type: 'success' });
  };

  // decouple apply oral exam
  const handleApplyOralExam = useCallback(async () => {
    try {
      const { examLevel, examType } = getExamLevelAndType(oralExamToApply);
      await applyExam(examLevel, examType, oralExamToApply);
    } catch (err) {
      console.error(err);
    }
  }, [oralExamToApply]);

  // notify applied exams to student
  const notifyAppliedExams = () =>
    socket.emit('classroom:examsapplied', { studentId: student.id });

  let timerexamsapplied;
  useEffect(() => {
    timerexamsapplied = setTimeout(() => addExamsAppliedListener(), 1000);
    return () => {
      clearTimeout(timerexamsapplied);
      socket.off('server:examsapplied');
    };
  }, []);

  // exams applied listener
  const addExamsAppliedListener = () => {
    socket.on('server:examsapplied', ({ studentId }) => {
      // check if is current student
      if (studentId === user.id) {
        setShowStudentExamsAppliedModal(true);
      }
    });
  };

  function handlePlacementTestClose({ nextMethod, nextMission }) {
    if (nextMethod) {
      setSelectionMethod(nextMethod);
    }
    if (nextMission) {
      setSelectionMission(nextMission);
      handleClickEditar();
    }

    setShowPlacements(false);
  }

  useEffect(() => {
    setIsOpen(isLateFinishClass);
    setCanClose(true);
  }, [isLateFinishClass]);

  return (
    <>
      {isApplyExamVisible && (
        <>
          <Modal
            className="react-modal-content"
            overlayClassName="react-modal-overlay"
            isOpen={true}
            onRequestClose={closeModalExam}
            contentLabel="Oral Exam"
          >
            <div>
              <Exams
                examLevel={examLevel}
                examType={examType}
                level={student?.level}
                onCancel={() => {
                  setIsApplyExamVisible(false);
                }}
                location0={location0}
                onFinish={async () => {
                  setIsApplyExamVisible(false);
                  notifyAppliedExams();
                  refreshMission();
                }}
              />
            </div>
          </Modal>
        </>
      )}

      <Container>
        <Prompt
          when={!modalIsOpen}
          message={() => t('messageDisconnectClassroom')}
        />
        <Header />
        <Wrapper>
          <HeaderInfo
            setIsOpen={setIsOpen}
            setCanCloseModal={setCanClose}
            student={student}
            setShowPlacements={setShowPlacements}
            setIsLateFinishClass={handleIsLateFinishClass}
            onHideContentStudent={onHideContentStudent}
            studentOnline={studentOnline}
          />
          {!typeLogin && !showPlacements && (
            <ContainerSelect>
              <Column>
                <Select
                  defaultValue={selectionMethod}
                  value={selectionMethod}
                  onChange={({ target }) => fetchMisionsByMethod(target.value)}
                >
                  {methods?.map((method, index) =>
                    method.title != undefined ? (
                      <option value={method.id}>{method.title}</option>
                    ) : (
                      ''
                    ),
                  )}
                </Select>
                <Select
                  defaultValue={selectionMission}
                  value={selectionMission}
                  onChange={({ target }) => setSelectionMission(target.value)}
                >
                  {missions?.map((mission, index) =>
                    mission.title != undefined ? (
                      <option value={mission.id}>{mission.title}</option>
                    ) : (
                      ''
                    ),
                  )}
                </Select>
                <Select
                  value={selectionType}
                  onChange={({ target }) => setSelectionType(target.value)}
                >
                  {valueVerification.map((e, index) => (
                    <option value={e.type}>{e.title}</option>
                  ))}
                </Select>
                <Button middle onClick={() => handleUpdateContent()}>
                  {t('alterar')}
                </Button>
              </Column>

              {/* <Column secondary>
                <Select
                  value={examLevel}
                  onChange={({ target }) => setExamLevel(target.value)}
                >
                  <option value="midterm">{t('midtermExam')}</option>
                  <option value="final">{t('finalExam')}</option>
                </Select>
                <Select
                  value={examType}
                  onChange={({ target }) => setExamType(target.value)}
                >
                  <option value="oral">{t('oral')}</option>
                  <option value="written">{t('written')}</option>
                  <option value="multiple_choice">{t('multiplChoice')}</option>
                </Select>
                <Button
                  onClick={() => {
                    handleApplyExam();
                  }}
                >
                  {t('apply')}
                </Button>
              </Column> */}
            </ContainerSelect>
          )}
        </Wrapper>
        <Content>
          {oralExamToApply ? (
            <ApplyOralExam>
              <Card>
                <h1>{t('applyOralExamMessage')}</h1>
                <Button onClick={handleApplyOralExam}>
                  {t('applyOralExam')}
                </Button>
              </Card>
            </ApplyOralExam>
          ) : (
            !showPlacements &&
            !isApplyExamVisible && (
              <>
                {selection === 'vocabulary' && (
                  <Vocabulary
                    conteudo={conteudo}
                    currentContent={currentContent}
                    setCurrentContent={e => setCurrentContent(e)}
                    nextMission={onNextMission}
                  />
                )}
                {['challenge', 'review'].includes(selection) && (
                  <Questions
                    conteudo={conteudo?.map(c => ({
                      ...c,
                      type: c.type === 'review' ? 'challenge' : c.type,
                    }))}
                    currentContent={currentContent}
                    setCurrentContent={e => setCurrentContent(e)}
                    nextMission={onNextMission}
                  />
                )}

                {['reading_comprehension', 'notes'].includes(selection) && (
                  <ReadingComprehension
                    conteudo={conteudo}
                    currentContent={currentContent}
                    setCurrentContent={e => setCurrentContent(e)}
                    nextMission={onNextMission}
                  />
                )}
              </>
            )
          )}

          {showPlacements && (
            <PlacementTest
              setShowPlacements={handlePlacementTestClose}
              onLevelChange={onLevelChange}
              student={student}
            />
          )}
          <ColumnContent>
            <ContainerImage>
              {!restartConnection && (
                <Session
                  region={process.env.REACT_APP_AWSREGION}
                  accessKeyId={process.env.REACT_APP_ACCESSKEYID}
                  secretAccessKey={process.env.REACT_APP_SECRETACCESSKEY}
                  sessionName={location?.state?.appointmentId}
                  setRestartConnection={setRestartConnection}
                  role="master"
                  handleClose={closeSession}
                  handleTakePhoto={handleTakePhoto}
                  setTakePhoto={setTakePhoto}
                  onIsWait={value => onIsWait(value)}
                />
              )}
            </ContainerImage>

            <ContainerImage chat>
              <Chat
                sentId={student?.id}
                name={`${student?.name}`}
                imageURL={student?.avatarURL}
              />
            </ContainerImage>
          </ColumnContent>
        </Content>

        <ExamsAppliedModal
          open={showStudentExamsAppliedModal}
          onRequestClose={() => setShowStudentExamsAppliedModal(false)}
        />

        <FinishModal
          isOpen={modalIsOpen}
          canClose={canClose}
          classRoom={classRoom}
          setIsOpen={setIsOpen}
          mission={conteudo[0]?.mission}
          setUploadPhoto={setUploadPhoto}
          setCloseSession={setCloseSession}
          setTakePhoto={setTakePhoto}
        />
      </Container>
    </>
  );
}
