import Box from '@mui/material/Box';
import React, { useEffect } from 'react';

import { Plan } from '../../../../models/plan';
import { api } from '../../../../services/api';
import { API_ROUTES } from '../../../../types/api-routes';
import { FormPurchaseContainer } from '../form/form-purchase-container';
import { usePurchaseContext } from '../hooks/context-hook';
import { PlansItems } from '../plans/plans-items';

function Purchase(): React.ReactElement {
  const context = usePurchaseContext();
  useEffect(() => {
    loadPlans();
  }, []);

  function loadPlans(): void {
    api.get(API_ROUTES.PLANS).then((response) => {
      const { data } = response;
      context.setPlans(data
        .map((item: Plan) => item as Plan));
    });
  }

  return (
    <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
      {!context.formData.plan ? <PlansItems /> : <FormPurchaseContainer />}
    </Box>
  );
}

export {
  Purchase,
};
