import { isStudent, isTeacher } from '../../hooks/AuthProvider';
import { ColumnInfoProfile } from './ColumnInfoProfile';
import { ContainerSchedule } from './ContainerSchedule';
import NavProfile from './NavProfile';
import { Container } from './styles';

export function ProfileComponent({ match }) {
  return (
    <Container>
      <ColumnInfoProfile match={match} />
      <NavProfile match={match} />
      {(isStudent() || isTeacher())
        && <ContainerSchedule match={match} />}
    </Container>
  );
}
