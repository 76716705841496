import React from 'react';
import { useTranslation } from 'react-i18next';

import LogoImage from '../../assets/logo_top_banner.png';
import LogoFooterWhite from '../../assets/logo_white_footer.svg';
import {
  Container,
  ContainerFooter,
  ContainerQuestions,
  ContainerTop,
  Logo,
  LogoFooter,
  Text,
  TextFooter,
  Wrapper,
} from './styles';

export const RegisterTestDone = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Logo src={LogoImage} />
      <ContainerQuestions>
        <ContainerTop>
          <Text>{t('attention')}</Text>
        </ContainerTop>
        <Wrapper>
          <Text warning>{t('notificationApplication')}</Text>
          <Text warning>{t('thankYouApply')}</Text>
        </Wrapper>
      </ContainerQuestions>
      <ContainerFooter>
        <LogoFooter src={LogoFooterWhite} />
        <TextFooter>Copyright © 2021 Table Talks</TextFooter>
      </ContainerFooter>
    </Container>
  );
};
