import axios from 'axios';

export const googleAuthenticatorService = {

  async getKey() {

    const result = await axios.get('https://google-authenticator.p.rapidapi.com/new/',
      {
        headers: {
          'x-rapidapi-host': 'google-authenticator.p.rapidapi.com',
          'x-rapidapi-key': 'c007aab83amsh290fe39657c3b87p1a170fjsn57475dc6ad25'
        }
      },
    )
    return result.data
  },

  async getQrCode(key, user) {


    const result = await axios.get('https://google-authenticator.p.rapidapi.com/enroll/',
      {
        params: {
          secret: key,
          account: user,
          issuer: 'TableTalks'
        },
        headers: {
          'x-rapidapi-host': 'google-authenticator.p.rapidapi.com',
          'x-rapidapi-key': 'c007aab83amsh290fe39657c3b87p1a170fjsn57475dc6ad25'
        }
      },
    )
    return result.data
  },

  async validateUser(code, key) {

    const result = await axios.get('https://google-authenticator.p.rapidapi.com/validate/',
      {
        params: {
          code,
          secret: key
        },
        headers: {
          'x-rapidapi-host': 'google-authenticator.p.rapidapi.com',
          'x-rapidapi-key': 'c007aab83amsh290fe39657c3b87p1a170fjsn57475dc6ad25'
        }
      },
    )
    return result.data
  },
  
}
