import React from 'react';

import { DeliveredMessagem, TextMessage, ContainerMessage } from './styles';

export function Delivered({ content, imageURL = '' }) {
  if (!content) return false;
  
  return (
    <ContainerMessage delivered>
      <DeliveredMessagem>
        <TextMessage white>
          {content}
        </TextMessage>
      </DeliveredMessagem>
      {imageURL != ''
        ? <img src={imageURL} alt="" />
        : <img src="https://tabletalks-method.s3.sa-east-1.amazonaws.com/users/avatar.png" alt="" />
      }
    </ContainerMessage>
  );
}
