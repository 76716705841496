import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import IconLeft from '../../../assets/logo_icon_login.png';

export const ContainerMenu = styled.div`
  display: flex;
  height: 100%;
  width: 25rem;
  min-width: 25rem;
  flex-direction: column;
  background: var(--green1);
  overflow: auto !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 1.5rem;
  transition: all 0.4s;

  &::-webkit-scrollbar {
    display: none;
  }

  ${props =>
    props.showMenu === true
      ? css`
          width: 9rem;
          min-width: 9rem;
        `
      : css`
          width: 25rem;
          min-width: 25rem;
        `}

  @media (max-width: 1280px) {
    width: 20rem;
    min-width: 20rem;
    transition: all 0.9s;

    ${props =>
      props.showMenu === true
        ? css`
            width: 9rem;
            min-width: 9rem;
          `
        : css`
            width: 20rem;
            min-width: 20rem;
          `}
  }

  @media (max-width: 768px) {
    display: none;
    overflow: hidden;
    transition: all 0.9s;

    ${props =>
      props.showMenu === true &&
      css`
        display: block;
        width: 100%;
        min-width: 100%;
        position: fixed;
        z-index: 40;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `}
  }
`;

export const ContainerDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SpanMenu = styled.span`
  font-size: 2.5rem;
  color: #fff;
  cursor: pointer;
`;

export const Button = styled.button`
  display: none;

  ${props =>
    props.showMenu === false &&
    css`
      display: flex;
    `}

  @media(max-width: 768px) {
    display: block;
  }
`;

export const ButtonResponsive = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: grid;
    height: 4rem;
    width: 4rem;

    .iconify {
      color: #ffab00;
      font-size: 4rem;
    }
  }
`;

export const LogoTableTalks = styled.img.attrs({ src: IconLeft })`
  align-self: flex-start;
  transition: 0.5s all;
  width: 4rem;
  margin: 10px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const NavLinkRouter = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: transparent;
  padding: 1rem 1.5rem;
  font-size: 2rem;
  font-weight: 500;
  position: relative;
  border-radius: 5px;

  .iconify {
    font-size: 2rem;
  }

  &.active {
    background: #fff;
    color: var(--green1);
    border-radius: 5px;
  }

  &:hover {
    background: #fff;
    color: var(--green1);

    svg {
      color: var(--green1);
    }
  }

  @media (max-width: 768px) {
    .iconify {
      font-size: 3rem;
    }
  }
`;

export const NavRouter = styled.button`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: transparent;
  padding: 1rem 1.5rem;
  font-size: 2rem;
  font-weight: 500;
  position: relative;
  border-radius: 5px;

  .iconify {
    font-size: 2rem;
  }

  &.active {
    background: #fff;
    color: var(--green1);
    border-radius: 5px;
  }

  &:hover {
    background: #fff;
    color: var(--green1);

    svg {
      color: var(--green1);
    }
  }

  @media (max-width: 768px) {
    .iconify {
      font-size: 3rem;
    }
  }
`;

export const NavText = styled.span`
  margin-left: 1.5rem;
  font-size: 1.4rem;

  ${props =>
    props.showMenu === true &&
    css`
      display: none;
    `};

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Text = styled.span`
  display: none;
  margin-left: 1.5rem;

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    align-self: center;
    justify-items: center;
    font-size: 1.8rem;
  }
`;
