/* eslint-disable  */
import React, { Fragment, useEffect, useState } from 'react';

import { ListCorrectionItem } from './ListCorrection.Item';
import { ListScheduleDayItem } from './ListScheduleDay.Item';
import Lock from '../../../assets/svgMethod/lock_icon.svg';
import Watch from '../../../assets/svgMethod/watch_icon.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { api } from '../../../services/api';

import {
  Arrow,
  Button,
  ButtonNoBorder,
  Container,
  ContainerBook,
  ContainerHour,
  ContainerIcon,
  ContainerInfo,
  ContainerListInfo,
  ContainerPlanning,
  ContainerItems,
  ContainerItems2,
  ContainerTextsBook,
  ImageBook,
  ImageClock,
  Select,
  Text,
  ButtonPlanning,
} from './styles.listItem';
import { ListActiveMethodStudents } from './ListActiveMethodStudents';
import { useAuth } from '../../../hooks/AuthProvider';
import { format } from 'date-fns-tz';
import { useMethod } from '../../../context/MethodContext';

type TTimeControl = {
  hours: string;
};

interface IMethod {
  _id: string;
  title: string;
  lessons: number;
  icon: string;
  activeStudents: IStudent[];
  graduateStudents: IStudent[];
  timecontrol?: TTimeControl;
}

interface IMission {
  title: string;
}

interface IStudent {
  _id: string;
  avatarURL: string;
  fullName: string;
  name: string;
  studentLevel: string;
}
interface IQuestionsAnswers {
  _id: string;
  question: string;
  answer: string;
  isCorrected: boolean;
  isCorrectAnswer: number;
  obs: string;
}

interface ICorrections {
  _id: string;
  title: string;
  mission: IMission;
  student: IStudent;
  exam: any;
  quiz: any;
  exerciseDate: Date;
  totalCorrected: number;
  totalNotVerificated: number;
  answers: IQuestionsAnswers[];
}

interface IMethods {
  method: IMethod;
  corrections: ICorrections[];
  numberCorrections: number;
  students: IStudent[];
  numberStudents: number;
  graduateStudents: IStudent[];
  numberGraduateStudents: number;
}

type TMethod = {
  _id: string;
  icon: string;
  id: string;
  lessons: string;
  title: string;
};

type TMission = {
  id: string;
  title: string;
  type: string;
};

type TUser = {
  avatar: string;
  id: string;
  type: string;
};

type TUsers = {
  student: TUser;
  teacher: TUser;
};

type TAppointment = {
  appointmentId: string;
  method: TMethod;
  mission: TMission;
  user: TUsers;
};

interface BookProps {
  imageBook: string;
  title: string;
  method: IMethods;
  setScheduleDay?: void | undefined;
  mission?: TAppointment;
  fetchMethods: any;
}

export const ListItems = ({
  imageBook,
  title,
  method,
  mission,
  fetchMethods,
}: BookProps) => {
  const { t } = useTranslation();

  console.log('[METHOD]', method);

  const history = useHistory();

  const [openPlanning, setOpenPlanning] = useState(false);
  const [activatePlanningButton, setActivatePlanningButton] = useState(false);
  const [showScheduleItem, setShowScheduleItem] = useState<boolean>(false);
  const [showCorrectionItem, setCorrectionItem] = useState<boolean>(false);
  const [showActiveStudents, setActiveStudents] = useState<boolean>(false);

  const [selectionMission, setSelectionMission] = useState<any>();
  const [selectionType, setSelectionType] = useState<any>();
  const [missions, setMissions] = useState([]);
  const { user } = useAuth();
  const { teacherPlanning } = useMethod();

  const [valueVerification] = useState([
    { type: 'vocabulary', id: '0', title: 'Vocabulary' },
    { type: 'challenge', id: '1', title: 'Questions' },
    { type: 'reading_comprehension', id: '2', title: 'Reading Comprehesion' },
  ]);

  const [[mins, secs], setTime] = useState([0, 0]);
  const [planningStartTime, setPlanningStartTime] = useState<string>('');
  const [planningLimitDate, setPlanningLimitDate] = useState<string>('');

  const reset = () => setTime([parseInt('0'), parseInt('0')]);

  useEffect(() => {
    if (teacherPlanning?.hours) {
      setPlanningStartTime(teacherPlanning.hours);
      const time = teacherPlanning.hours.split(':');
      setTime(time);
    }
  }, [teacherPlanning]);

  // async function fetchMissions() {
  //   const { data } = await api.get('/missions');
  //   setMissions(data.docs);
  //   setSelectionMission(data?.docs[0]?.id);
  //   setSelectionType('vocabulary');
  // }

  const tick = () => {
    if (mins === 0 && secs === 0) {
      reset();
      setOpenPlanning(false);
    } else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };

  function handleGoToPlanning(methodId: any) {
    const planningClass = {
      methodId: methodId,
      missionId: selectionMission,
      type: selectionType,
      time: planningStartTime,
      teacher: user?.id,
    };
    localStorage.setItem('state', JSON.stringify(planningClass));
    history.push({
      pathname: '/dashboard/method/planning',
      state: planningClass,
    });
  }

  function handleSetSelectedMission(event: React.FormEvent<HTMLSelectElement>) {
    const mission: string = event.currentTarget.value;

    setSelectionMission(mission);
  }

  function handleSetSelectedType(event: React.FormEvent<HTMLSelectElement>) {
    const type: string = event.currentTarget.value;

    setSelectionType(type);
  }

  const numberOfCorrections =
    method?.numberCorrections > 0
      ? `${method.numberCorrections} Pending Corrections`
      : 'No Pending Corrections';

  async function handleSetStartTime() {
    const { data } = await api.get(`/planning/teacher/${user?.id}`);
    const time = data?.hours.split(':');

    if (data) {
      setPlanningStartTime(data?.hours ?? '120:00');
      setTime([time[0], time[1]]);
      if (parseInt(time[0]) > 0 && parseInt(time[1]) > 0) {
        setOpenPlanning(true);
      }
    } else {
      setPlanningStartTime('120:00');
      setTime([120, 0]);
      setOpenPlanning(true);
    }
  }

  function handleSetOpenPlanning() {
    if (mins == 0 && secs == 0) {
      setOpenPlanning(false);
      setActivatePlanningButton(false);
    } else {
      setOpenPlanning(true);
      setActivatePlanningButton(true);
    }
  }

  async function handleSetLimitDate() {
    const { data } = await api.get(`/config/planning/limitdate`);

    if (data) {
      const limitDate = new Date(Date.parse(data.value));
      setPlanningLimitDate(format(limitDate, 'dd/MM/yyyy'));
    } else {
      setPlanningLimitDate('');
    }
  }

  async function fetchMissionsByMethod(method: any) {
    const { data } = await api.get(`/missions/method/${method}?type=class`);

    if (data) {
      setMissions(data.docs);
      setSelectionMission(data?.docs[0]?.id);
      setSelectionType('vocabulary');
    }
  }

  useEffect(() => {
    if (openPlanning === false) {
      return;
    }

    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  useEffect(() => {
    handleSetStartTime();
    handleSetLimitDate();
    fetchMissionsByMethod(method?.method?._id);
  }, []);

  return (
    <Fragment>
      <Container isOpenPlanning={openPlanning}>
        <ContainerBook isOpenPlanning={openPlanning}>
          <ImageBook src={imageBook} isOpenPlanning={openPlanning} />
          <ContainerTextsBook>
            <Text>{title.split('-')[0].trim()}</Text>
            <Text>{title}</Text>
          </ContainerTextsBook>
          <Arrow isOpenPlanning={openPlanning} />
        </ContainerBook>
        <ContainerPlanning isOpenPlanning={openPlanning}>
          <ContainerIcon onClick={() => handleSetOpenPlanning()}>
            {!openPlanning && <ImageClock src={Lock} clock />}
            {!!openPlanning && <ImageClock src={Watch} />}
          </ContainerIcon>
          <ContainerHour>
            <Text>{planningStartTime} min</Text>
            <Text>
              {t('remaining')} {planningLimitDate}
            </Text>
          </ContainerHour>
        </ContainerPlanning>
        <ContainerItems isVisible={!openPlanning}>
          {!!openPlanning && (
            <>
              <Select onChange={e => handleSetSelectedMission(e)}>
                {missions?.map((mission: any, _index) =>
                  mission?.title !== undefined ? (
                    <option value={mission?.id}>{mission?.title}</option>
                  ) : (
                    ''
                  ),
                )}
              </Select>

              <Select onChange={e => handleSetSelectedType(e)}>
                {valueVerification.map((e, _index) => (
                  <option value={e.type}>{e.title}</option>
                ))}
              </Select>
            </>
          )}
          {!!openPlanning && !!activatePlanningButton && (
            <ButtonPlanning
              onClick={() => handleGoToPlanning(method?.method?._id)}
            >
              {t('view')}{' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
              />
            </ButtonPlanning>
          )}
          {!!openPlanning && !activatePlanningButton && (
            <ButtonPlanning
              disabled
              onClick={() => handleGoToPlanning(method?.method?._id)}
            >
              {t('view')}{' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
              />
            </ButtonPlanning>
          )}
        </ContainerItems>

        {/* <ContainerItems2 isOpenPlanning={openPlanning}>
          <Button
            onClick={() => {
              if (!showCorrectionItem) {
                setCorrectionItem(true);
              } else {
                setCorrectionItem(false);
              }
            }}
          >
            <span className="iconify" data-icon="bi:chat-left-text"></span>
            {numberOfCorrections}
            <span className="iconify" data-icon="fe:arrow-down"></span>
          </Button>
          <Button
            onClick={() => {
              if (!showScheduleItem) {
                setShowScheduleItem(true);
              } else {
                setShowScheduleItem(false);
              }
            }}
          >
            <span className="iconify" data-icon="akar-icons:calendar"></span>
            Day Schedule
            <span className="iconify" data-icon="fe:arrow-down"></span>
          </Button>
        </ContainerItems2> */}

        <ContainerListInfo isOpenPlanning={openPlanning}>
          <ContainerInfo>
            <span className="iconify" data-icon="icons8:student"></span>
            <Text>{method?.numberGraduateStudents} Student(s)</Text>
          </ContainerInfo>
          <ContainerInfo option>
            <span className="iconify" data-icon="bx:bx-user"></span>
            <Text>{method?.numberStudents} Student(s)</Text>
          </ContainerInfo>
          <ContainerInfo icon>
            <ButtonNoBorder
              onClick={() => {
                if (!showActiveStudents) {
                  setActiveStudents(true);
                } else {
                  setActiveStudents(false);
                }
              }}
            >
              <span className="iconify" data-icon="fe:arrow-down"></span>
            </ButtonNoBorder>
          </ContainerInfo>
        </ContainerListInfo>
      </Container>
      {showScheduleItem && (
        <ListScheduleDayItem
          setShowScheduleItem={setShowScheduleItem}
          method={method}
        />
      )}
      {showCorrectionItem && (
        <ListCorrectionItem
          corrections={method?.corrections}
          setCorrectionItem={setCorrectionItem}
          fetchMethods={fetchMethods}
        />
      )}

      {showActiveStudents && (
        <ListActiveMethodStudents
          students={method?.students}
          setActiveStudents={setActiveStudents}
        />
      )}
    </Fragment>
  );
};
