import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: #f9f9f9;
`;

export const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 2rem;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f9f9f9;

  @media (max-width: 800px) {
    padding: 6rem 2rem;
  }

  @media (max-width: 425px) {
    padding: 7rem 1rem;
  }
`;

export const WrapperContent = styled.div`
  position: relative;
`;

export const WrapperBook = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 1096px) {
    justify-content: center;
  }
`;
