import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { useParams, useHistory } from 'react-router-dom';

import { api } from '../../../../services/api';
import { useAuth } from '../../../../hooks/AuthProvider';
import * as C from './styles';
import { Vocabulary } from './Vocabulary';
import { Questions } from './Questions';
import { ReadingComprehension } from './ReadingComprehension';
import { All } from './All';

export const Content = () => {
  const {
    user: {
      level: { method, mission },
      name,
      lastName,
      avatarURL,
    },
  } = useAuth();

  const { idMethod } = useParams();
  const history = useHistory();

  const [showContent, setShowContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [valueVerification] = useState([
    { id: '0', type: 'all', title: 'All' },
    { id: '1', type: 'vocabulary', title: 'Vocabulary' },
    { id: '2', type: 'challenge', title: 'Questions' },
    { id: '3', type: 'reading_comprehension', title: 'Reading Comprehension' },
    // { id: '4', type: 'notes', title: 'Notes' },
    { id: '5', type: 'review', title: 'Review' },
  ]);

  const [currentContent, setCurrentContent] = useState(0);

  const [selection, setSelection] = useState();
  const [selectionMission, setSelectionMission] = useState();
  const [selectionMethod, setSelectionMethod] = useState();
  const [selectionType, setSelectionType] = useState();

  const [methods, setMethods] = useState([]);
  const [missions, setMissions] = useState([]);
  const [content, setContent] = useState([]);

  const fetchMethods = async () => {
    setLoading(true);
    const {
      data: { docs },
    } = await api.get('/methods');
    setMethods(docs);
  };

  useEffect(() => {
    const _type = content[currentContent]?.type;
    setSelection(_type);
  }, [currentContent]);

  const fetchContent = async () => {
    // const {
    //   data: { docs: missions },
    // } = await api.get(`/missions?method=${idMethod}&limit=1000`);
    // const {
    //   data: { docs: content },
    // } = await api.get(
    //   `/contents?method=${idMethod}&mission=${missions.at(0)?._id}&limit=1000`,
    // );

    const {
      data: { docs: missions },
    } = await api.get(`/missions?method=${idMethod}&type=class&limit=1000`);
    const {
      data: { docs },
    } = await api.get(
      `/contents?method=${idMethod}&mission=${missions.at(0)?._id}&limit=1000`,
    );

    const _content = docs?.filter(e => e.type !== 'notes');

    const canAccess = await canAccessThisMethodAndMission(
      _content[0]?.method?.order,
      _content[0]?.mission?.order,
    );

    if (!canAccess) {
      history.replace('/dashboard/tt/method');
    }

    setSelection(_content[0].type);
    setSelectionMethod(_content[0].method.id);
    setSelectionMission(_content[0].mission.id);
    setSelectionType(_content[0].type);

    setLoading(false);
    setShowContent(true);

    setContent(_content.sort((a, b) => a.order - b.order));
    setMissions(missions);
  };

  const handleClickBuscar = async () => {
    let endpoint = null;
    if (selectionType !== 'all') {
      endpoint = `/contents?method=${selectionMethod}&mission=${selectionMission}&type=${selectionType}&limit=1000`;
    } else {
      endpoint = `/contents?method=${selectionMethod}&mission=${selectionMission}&limit=1000`;
    }

    const {
      data: { docs },
    } = await api.get(endpoint);

    if (docs[0] === undefined) return toast.error(t('noContentForFilter'));

    const _content = docs.filter(e => e.type !== 'notes');

    let canAccess = await canAccessThisMethodAndMission(
      _content[0]?.method?.order,
      _content[0]?.mission?.order,
    );
    if (_content.length > 0 && _content[0].method.typeMethod === "INTRODUTORIO") {
      canAccess = true;
    }
    if (!canAccess) {
      return toast.warning(
        'Access denied. You are not allowed to access this page.!',
      );
    }
    setCurrentContent(0);
    setShowContent(true);
    setSelection(selectionType !== 'all' ? _content[0].type : 'all');

    setContent(_content.sort((a, b) => a.order - b.order));
  };

  const handleSelectMethod = async idMethod => {
    const {
      data: { docs },
    } = await api.get(`/missions?method=${idMethod}&type=class&limit=1000`);
    setMissions(docs);
    setSelectionMethod(idMethod);
    setSelectionMission(docs[0].id);
  };

  const canAccessThisMethodAndMission = async (
    orderMethodMongo,
    orderMissionMongo,
  ) => {
    try {
      const {
        data: { order: orderMethod, typeMethod: typeMethod },
      } = await api.get(`/methods/${method._id}`);
      const {
        data: { order: orderMission },
      } = await api.get(`/missions/${mission._id}`);

      if (typeMethod === 'INTRODUTORIO') {
        return true;
      } else if (orderMethodMongo > orderMethod) {
        return false;
      } else if (
        orderMethodMongo === orderMethod &&
        orderMissionMongo !== orderMission
      ) {
        if (orderMissionMongo > orderMission) {
          return false;
        }
      } else if (
        orderMethodMongo < orderMethod ||
        (orderMethodMongo === orderMethod && orderMissionMongo === orderMission)
      ) {
        const homeworks = await api.get(`/homework`, {
          params: { method: selectionMethod, mission: selectionMission },
        });
        if (homeworks.data.length === 0) {
          return false;
        }
      }

      return true;
    } catch (e) {
      throw new Error(e);
    }
  };

  const lockWhenExitScreen = () => {
    setBlocked(true);
  };

  const releaseWhenEnteringTheScreen = () => {
    setBlocked(false);
  };

  async function getAllMethods() {
    await fetchMethods();
    await fetchContent();
  }
  useEffect(() => {
    getAllMethods();
    window.addEventListener('focus', releaseWhenEnteringTheScreen);
    window.addEventListener('blur', lockWhenExitScreen);

    return () => {
      window.removeEventListener('focus', releaseWhenEnteringTheScreen);
      window.removeEventListener('blur', lockWhenExitScreen);
    };
  }, []);

  return (
    <>
      {blocked && <C.SecondScreen />}
      {loading && (
        <C.ContainerLoader>
          <Loader
            visible
            type="ThreeDots"
            color="#124f8c"
            secondaryColor="#191919"
          />
        </C.ContainerLoader>
      )}
      {!loading && (
        <>
          {/* <C.BackgroundGreen>
            <C.ImageProfile src={avatarURL} />
            <C.Info>
              <C.FullName>
                {name} {lastName}
              </C.FullName>
              <C.Level>
                {method?.title?.substr(0, 2)} {'>'} {mission?.title}
              </C.Level>
            </C.Info>
          </C.BackgroundGreen> */}

          <C.Container
            onCopy={e => e.preventDefault()}
            onDragStart={e => e.preventDefault()}
          >
            <C.Content>
              {showContent && (
                <>
                  {selection === 'all' && <All conteudo={content} />}
                  {selection === 'vocabulary' && (
                    <Vocabulary
                      conteudo={content}
                      currentContent={currentContent}
                      setCurrentContent={e => setCurrentContent(e)}
                    />
                  )}

                  {['challenge', 'review'].includes(selection) && (
                    <Questions
                      conteudo={content?.map(c => ({
                        ...c,
                        type: c.type === 'review' ? 'challenge' : c.type,
                      }))}
                      currentContent={currentContent}
                      setCurrentContent={e => setCurrentContent(e)}
                    />
                  )}

                  {['reading_comprehension'].includes(selection) && (
                    <ReadingComprehension
                      conteudo={content}
                      currentContent={currentContent}
                      setCurrentContent={e => setCurrentContent(e)}
                    />
                  )}
                </>
              )}
            </C.Content>
            <C.Filter>
              <C.Select
                defaultValue={selectionMethod}
                value={selectionMethod}
                onChange={({ target }) => handleSelectMethod(target.value)}
              >
                {methods?.map(method => {
                  if (method.id === idMethod) {
                    return (
                      <option key={method.id} selected value={method.id}>
                        {method.title}
                      </option>
                    );
                  }

                  return (
                    <option key={method.id} value={method.id}>
                      {method.title}
                    </option>
                  );
                })}
              </C.Select>
              <C.Select
                defaultValue={selectionMission}
                value={selectionMission}
                onChange={({ target }) => setSelectionMission(target.value)}
              >
                {missions?.map(mission => (
                  <option key={mission.id} value={mission.id}>
                    {mission.title}
                  </option>
                ))}
              </C.Select>
              <C.Select
                defaultValue={selectionType}
                value={selectionType}
                onChange={({ target }) => setSelectionType(target.value)}
              >
                {valueVerification.map(e => (
                  <option key={e.id} value={e.type}>
                    {e.title}
                  </option>
                ))}
              </C.Select>
              <C.Button middle onClick={handleClickBuscar}>
                Submit
              </C.Button>
            </C.Filter>
          </C.Container>
        </>
      )}
    </>
  );
};
