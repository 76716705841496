import { ListTicket } from '../../../../components/ListTicket';

import { Container } from './styles';

export function Finished({ calls }) {
  return (
    <Container>
      {calls.map((call, index) => (
        <ListTicket 
          key={index}
          idChamado={call.idchamado}
          ticket={call.protocolo}
          title={call.titulo}
          description={call.mensagem}
          status={call.descsituacao}
          createdAt={call.data_criacao}
        />
      ))}
    </Container>
  );
}
