enum API_ROUTES {
  PLANS= '/plans',
  STATES='/states',
  CITIES_STATE='city/state',
  PLANS_CUPOM='plans/coupon',
  PLANS_CHECKOUT='/plans/checkout',
}

export {
  API_ROUTES,
};
