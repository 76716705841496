import styled, { css } from 'styled-components';

import BackgroundGreen from '../../assets/background_login_gren.png';

interface ButtonTopProps {
  quetions?: boolean;
  right?: boolean;
}

interface TextTopProps {
  button?: boolean;
  quetions?: boolean;
  warning?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundGreen});
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
`;

export const Logo = styled.img``;

export const ContainerQuestions = styled.div`
  width: 80rem;
  height: 50rem;
  background: #fff;
  margin: 3rem 0;
  border-radius: 0.4rem;
`;

export const ContainerTop = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid var(--gray3);

  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Text = styled.span<TextTopProps>`
  font-size: 1.8rem;
  color: #5e5873;
  font-weight: 600;

  ${(props) => props.button
    && css`
      font-weight: 500;
      color: #243342;
      font-size: 1.5rem;
      padding: 0 2rem;
    `}

  ${(props) => props.quetions
    && css`
      padding: 2rem 0;
    `}

  ${(props) => props.warning
    && css`
      padding: 2rem 0;
      font-weight: 500;
      margin: 0 12rem;
    `}
`;

export const ContainerButtonsTop = styled.div`
  position: absolute;
  right: 0;
`;

export const ButtonTop = styled.button<ButtonTopProps>`
  padding: 0 1.5rem;
  border-right: 1px solid var(--gray3);
  border-left: 1px solid var(--gray3);
  height: 5rem;
  cursor: pointer;
  transition: 0.2s;

  :hover {
    background: var(--gray3);
  }

  .iconify {
    font-size: 2rem;
  }

  ${(props) => props.right
    && css`
      border-right: 0;

      :hover {
        border-radius: 0 0.4rem 0 0;
      }
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonQuestions = styled.button<ButtonTopProps>`
  height: 5rem;
  width: 60%;
  background: #083059;
  border-radius: 0.4rem;
  margin-top: 1rem;
  color: #fff;
  font-size: 1.6rem;

  ${(props) => props.quetions
    && css`
      background: #243342;
      width: 25rem;
      height: 3.5rem;
      font-size: 1.4rem;
      font-family: 'Montserrat';
      margin-top: 2rem;
    `}
`;

export const ContainerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoFooter = styled.img``;

export const TextFooter = styled.span`
  color: #fff;
  margin-left: 1rem;
`;

export const TextArea = styled.textarea`
  height: 20rem;
  width: 60rem;
  border-radius: 0.4rem;
  border: 1px solid var(--gray3);
`;
