import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f9f9f9;

  overflow-y: auto;

  .iconify {
    font-size: 3rem;
  }

  .menuitem {
    font-size: 1.8rem;

    svg {
      margin-right: 1rem;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 7.4rem;
  padding: 1.2rem;
  border-radius: 0.5rem;
`;

export const HeaderStage = styled.div`
  background: #fff;
  width: 100%;
  height: 7.4rem;
  border: 1px solid var(--gray3);
  display: flex;
  align-items: center;
  margin-top: 1.2rem;
  position: relative;
  border-radius: 0.5rem;
  border-left: 0;
  justify-content: space-between;

  @media (max-width: 560px) {
    height: 19rem;
    padding-right: 0.5rem;
  }

  .buttons-container {
    padding: 0 16px;
  }
`;

export const CardStage = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 2rem;
  align-items: center;
  background: var(--gray5);
  height: 7.4rem;
  width: 32rem;
  cursor: pointer;
  position: relative;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;

  .arrow {
    background: var(--gray5);
    border-top: 1px solid var(--gray3);
    border-right: 1px solid var(--gray3);
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    margin-left: 16px;
    position: absolute;
    right: -8px;
  }

  @media (max-width: 768px) {
    padding: 0 0.5rem 0 1rem;
    width: 30rem;
    height: 7.4rem;
  }

  @media (max-width: 560px) {
    display: none;
  }
`;

export const IconStage = styled.img`
  margin-right: 2rem;

  @media (max-width: 1280px) {
    margin-right: 1.5rem;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-size: 1.9rem;
    color: #083059;
    font-weight: 600;
  }

  span + span {
    font-size: 1.2rem;
    color: #707070;
    font-weight: 500;
  }
`;

export const Text = styled.span`
  ${props =>
    props.title &&
    css`
      font-size: 2rem;
      color: #5e5873;
      margin-bottom: 1.2rem;
      font-weight: 600;
    `}
`;

export const MenuText = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  margin-left: 1.2rem;
`;

export const Button = styled.button`
  height: 3.5rem;
  background: #083059;
  color: #fff;
  margin-left: 1.5rem;
  padding: 0 2rem;
  border-radius: 0.5rem;
  cursor: pointer;

  ${props =>
    props.view &&
    css`
      background: #fff;
      border: 1px solid var(--gray3);
      color: #083059;
    `}

  ${props =>
    props.next &&
    css`
      background: #fff;
      color: #083059;
      position: absolute;
      right: 2rem;

      .iconify {
        font-size: 2.4rem;
      }
    `}

  ${props =>
    props.certificate &&
    css`
      margin-left: 0;
      margin-top: 1.2rem;
      width: 100%;
    `}
`;

export const ContainerCertificate = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
`;

export const CertificateImage = styled.img`
  width: 110rem;
  height: 82rem;
  object-fit: cover;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  width: 23rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  padding: 10% 0;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const EmptyListContainer = styled.div`
  display: flex;
  padding: 10% 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
