import React, { Fragment } from 'react';

import { Container, Error } from './styles';

const customStyles = {
  option: provided => ({
    ...provided,
    padding: 5,
  }),
  control: () => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: '#fff',
  marginBottom: 8,
  marginTop: 8,
  width: 1,
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

function FormSelect({ error, ...rest }) {
  return (
    <Fragment>
      <Container
        components={{ IndicatorSeparator }}
        classNamePrefix="react-select"
        noOptionsMessage={() => <p>Não há opções</p>}
        styles={customStyles}
        withError={!!error}
        {...rest}
      />
      {!!error && <Error>{error}</Error>}
    </Fragment>
  );
}

FormSelect.defaultProps = {
  error: undefined,
};

export default FormSelect;
