import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 1.2rem;
  background: #fff;
  display: flex;
  border: 1px solid var(--gray3);
`;

export const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 20px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  background: #f9f9f9;

  @media (max-width: 425px) {
    padding: 7rem 1rem;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0;
`;

export const Title = styled.div`
  display: flex;
  font-size: 18px;
  color: var(--gray4);
  font-weight: 700;
  margin: 5px 0;

  svg {
    font-size: 32px;
    margin-right: 15px;
    color: var(--main-color);
  }
`;

export const ContainerTicketAba = styled.div`
  align-items: center;
  width: 15%;
  height: 100%;
  position: relative;
  cursor: pointer;

  &&:hover {
    background-color: #ebe9f1;
  }

  Span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
  }
`;

export const ContainerTicketButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 70%;
  background-color: #fff;

  Button {
    height: 100%;
    width: 30%;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const ContainerTicketBodyPending = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  overflow: hidden;
  padding: 25px 0;
  transition: all 0.3s ease-out;
  width: 100%;
  margin-bottom: 15px;
  background-color: #fff;
  padding: 0;
  border: 1px solid #e0dfe5;

  @media (max-width: 1280px) {
    height: 6rem;
  }
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

export const ContainerTicketBodyPendingItens = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: 1280px) {
    height: 6rem;
  }
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

export const ContainerTicketBodyNumber = styled.div`
  width: 20%;
  Span {
    font-size: 16px;
  }
`;

export const ContainerTicketBodyStatus = styled.div`
  width: 10%;

  Span {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    min-width: 5rem;
  }
`;

export const ContainerTicketBodyDate = styled.div`
  width: 25%;
  display: flex;
  flex-direction: row;
  p {
    width: 30%;
    font-size: 12px;
    background-color: #e5f8ed;
    text-align: center;
  }

  @media (max-width: 1280px) {
    width: 18rem;
  }
  @media (max-width: 768px) {
    min-width: 20rem;
  }
`;

export const ContainerTicketBodyDateInfo = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  Span {
    font-weight: normal;
    width: 100%;
    font-size: 12px;
    vertical-align: middle;
  }
  @media (max-width: 768px) {
    margin-left: 1rem;
  }
`;

export const ContainerTicketBodyPendingButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 30%;
  background-color: #fff;
`;

export const ContainerTicketBodyComent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  p {
    width: 30%;
    font-size: 12px;
    background-color: #e5f8ed;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  @media (max-width: 768px) {
    min-width: 20rem;
  }
`;

export const ContainerTicketBodySubComent = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  Span {
    font-weight: normal;
    width: 100%;
    font-size: 12px;
    margin-top: auto;
    margin-bottom: auto;
    @media (max-width: 768px) {
      min-width: 20rem;
    }
  }
`;
