import styled, { css } from 'styled-components';

interface TextProps {
  right?: boolean;
}

interface buttonProps {
  color?: string;
  back?: string;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  width: 100%;
  color: #707070;

  span {
    font-size: 1.6rem;
  }
`;

export const Text = styled.span<TextProps>`
  ${(props) => props.right
    && css`
      border-right: 1px solid var(--gray3);
      padding-right: 1rem;
    `}
`;

export const Icon = styled.button`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4rem;
  height: 4rem;

  .iconify {
    font-size: 1.8rem;
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;
  border-bottom: 1px solid var(--gray3);
  position: relative;
  font-size: 1.5rem;
  color: #707070;

  span {
    margin-right: 1rem;
  }

  @media (max-width: 990px) {
    flex-wrap: wrap;
  }
`;

export const IconList = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #28c76f12;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;

export const Image = styled.img``;
export const Button = styled.button<buttonProps>`
  border: 1px solid #ebe9f1;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  height: 3.8rem;
  width: 18rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
`;

export const ContainerNotes = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 1.2rem;

  @media (max-width: 990px) {
    position: revert;
  }
`;
