import styled from 'styled-components';

export const ContainerTitle = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0;
`;

export const Title = styled.div`
  display: flex;
  font-size: 18px;
  color: var(--gray4);
  font-weight: 700;
  margin: 5px 0;

  svg {
    font-size: 32px;
    margin-right: 15px;
    color: var(--main-color);
  }
`;

export const ContainerTicketBodyPending = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 25px 0;
  transition: all 0.3s ease-out;
  width: 100%;
  margin-bottom: 15px;
  background-color: #fff;
  padding: 0;
  border: 1px solid #e0dfe5;
`;

export const ContainerLessons = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 12px;
  }

  table td,
  table th {
    border: 1px dotted #ebe9f1;
    padding: 6px;
    width: 10%;
  }

  table tr {
    transition: all 0.2s ease;
  }

  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ebe9f1;
    color: black;
  }
`;
