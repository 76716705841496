/* eslint-disable no-nested-ternary */
import { format } from 'date-fns';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

import Select from '../../../components/Form/Select';
import { Header } from '../../../components/Header';
import { CancelAppointmentModal } from '../../../components/Modals/CancelAppointmentModal';
import { api } from '../../../services/api';
import { ListScheduleDayItem } from './ListScheduleDay.Item';
import { Request } from './Request';
import {
  ButtonIcon,
  Container,
  ContainerDates,
  ContainerDay,
  ContainerSearch,
  ContainerWeek,
  EmptyListContainer,
  // Input,
  LoadingContainer,
  SubHeader,
  Text,
  Wrapper,
} from './styles';

const getAllDaysInMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return Array.from({ length: 150 }, (_, i) => {
    const day1 = new Date(year, month - 1, 1);
    day1.setDate(day1.getDate() + i);
    return day1;
  }).map((d) => d);
};
function getDateYYYYMMDD(date: Date) {
  return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
}
function getWeekOfMonth() {
  const date = new Date();
  const startWeekDayIndex = 1; // 1 MonthDay 0 Sundays
  const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstDay = firstDate.getDay();

  let weekNumber = Math.ceil((date.getDate() + firstDay) / 7);
  if (startWeekDayIndex === 1) {
    if (date.getDay() === 0 && date.getDate() > 1) {
      weekNumber -= 1;
    }

    if (firstDate.getDate() === 1 && firstDay === 0 && date.getDate() > 1) {
      weekNumber += 1;
    }
  }
  return weekNumber;
}

export interface Method {
  _id: string;
  title: string;
  id: string;
}

export interface Mission {
  _id: string;
  title: string;
  id: string;
}

export interface Attendee {
  name: string;
  lastName: string;
  type: string;
  email: string;
  avatarURL: string;
  fullName: string;
  id: string;
  studentLevel: string;
}

export interface Appointment {
  _id: string;
  method: Method;
  mission: Mission;
  location: string;
  comment: string;
  status: string;
  attendees: Attendee[];
  startTime: string;
  endTime: string;
  originalStartTime: string;
  timeZone: string;
  isExperimentalClass: boolean;
  createdAt: Date;
  updatedAt: Date;
  isReschedulable: boolean;
  isCancelable: boolean;
  startsIn: number;
  id: string;
  teacher: Attendee;
  enableStart?: boolean;
  lastAppointment?: any;
}

export const Schedule = () => {
  const { t } = useTranslation();

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const D = new Date();
  const dateNow = new Date(D.getFullYear(), D.getMonth(), D.getDate());

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeWeek, setActiveWeek] = useState(getWeekOfMonth() - 1);
  const [activeDate, setActiveDate] = useState(dateNow);
  const [isCancelAppointmentModalOpen, setIsCancelAppointmentModalOpen] = useState(false);
  const [appointmentToCancel, setAppointmentToCancel] = useState<Appointment>();
  const [status, setStatus] = useState('accepted');

  const options = useMemo(
    () => [
      { value: 'accepted', label: t('accepted') },
      { value: 'cancelled', label: t('cancelled') },
    ],
    [t],
  );

  const [lastExperimentalClass, setLastExperimentalClass] = useState<Appointment>();

  const week = useMemo(() => getAllDaysInMonth().slice(activeWeek * 7, activeWeek * 7 + 7), [activeWeek]);

  function handleNextWeek() {
    setActiveWeek((prevState) => prevState + 1);
  }

  function handlePrevWeek() {
    setActiveWeek((prevState) => prevState - 1);
  }

  function handleSelectDate(date: Date) {
    setActiveDate(new Date(date));
  }

  function handleOpenCancelAppointmentModal(appointment: Appointment) {
    setAppointmentToCancel(appointment);
    setIsCancelAppointmentModalOpen(true);
  }

  function handleCloseCancelAppointmentModal() {
    setIsCancelAppointmentModalOpen(false);
    setAppointmentToCancel(undefined);
  }
  // schedule fetch bug porque na primeira vez carrega o activeDay vai sem timezone
  const fetchAppointments = useCallback(async () => {
    setIsLoading(true);
    await api
      .get('/appointments/me', {
        params: {
          date: activeDate,
          status,
          timeZone,
        },
      })
      .then((response) => {
        console.log('aquii!', response.data);
        setAppointments(response.data);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDate, status, timeZone]);

  async function fetchLastExperimentalClass() {
    await api.get('/appointments/last-experimental-class').then((response) => {
      setLastExperimentalClass(response.data);
    });
  }

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  useEffect(() => {
    fetchLastExperimentalClass();
  }, []);

  const handleAcceptAppointment = useCallback(
    async (id: string) => {
      try {
        await api.patch(`/appointments/accept/${id}`);

        toast('Schedule accepted successfully', { type: 'success' });
      } catch (err) {
        toast('Error', { type: 'error' });
      } finally {
        fetchAppointments();
        fetchLastExperimentalClass();
      }
    },
    [fetchAppointments],
  );

  // const handleRescheduleAppointment = useCallback(
  //   async (id: string, { startTime, endTime }) => {
  //     try {
  //       await api.patch(`/appointments/reschedule/${id}`, {
  //         startTime,
  //         endTime,
  //       });

  //       toast('Schedule reschedule successfully', { type: 'success' });
  //     } catch (err) {
  //       toast('Error', { type: 'error' });
  //     } finally {
  //       fetchAppointments();
  //     }
  //   },
  //   [fetchAppointments],
  // );

  const handleCancelAppointment = useCallback(
    async (id: string, reason: string) => {
      try {
        await api.patch(`/appointments/cancel/${id}`, {
          reason,
        });

        toast(t('yourLessonHasBeenCanceled2'), { type: 'success' });
      } catch (err) {
        toast('Error', { type: 'error' });
      } finally {
        fetchAppointments();
      }
    },
    [fetchAppointments],
  );

  return (
    <>
      <Container>
        <Header />

        <Wrapper>
          {lastExperimentalClass && (
            <Request
              appointment={lastExperimentalClass}
              onAccept={handleAcceptAppointment}
              fetchAppointments={() => {
                fetchAppointments();
                fetchLastExperimentalClass();
              }}
            />
          )}

          <SubHeader>
            <Text>{t('schedule')}</Text>

            <ContainerSearch>
              <div style={{ width: '18rem' }}>
                <Select
                  name="type"
                  options={options}
                  autoFocus={false}
                  error={null}
                  value={options.find((o) => o.value === status) || options[0]}
                  onChange={(e: { value: string }) => {
                    setStatus(e?.value);
                  }}
                />
              </div>
            </ContainerSearch>
          </SubHeader>

          <ContainerDates>
            <Text>
              <span className="iconify" data-icon="akar-icons:calendar" />
              {' '}
              {t('today')}
              :
            </Text>
            <ButtonIcon disabled={activeWeek === 0} onClick={handlePrevWeek}>
              <span className="iconify" data-icon="dashicons:arrow-left-alt2" />
            </ButtonIcon>
            <ContainerWeek>
              {week.map((d) => (
                <ContainerDay
                  active={getDateYYYYMMDD(d) === getDateYYYYMMDD(activeDate)}
                  onClick={() => {
                    handleSelectDate(d);
                  }}
                >
                  <Text>{format(new Date(d), 'dd/MMMM')}</Text>
                </ContainerDay>
              ))}
            </ContainerWeek>
            <ButtonIcon disabled={activeWeek === 21} onClick={handleNextWeek}>
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
              />
            </ButtonIcon>
          </ContainerDates>
          {isLoading ? (
            <LoadingContainer>
              <Loader
                visible
                type="ThreeDots"
                color="#124f8c"
                secondaryColor="#191919"
              />
            </LoadingContainer>
          ) : appointments.length === 0 ? (
            <EmptyListContainer>
              <h1>{t('noResultsFound')}</h1>
            </EmptyListContainer>
          ) : (
            appointments.map((appointment) => (
              <ListScheduleDayItem
                appointment={appointment}
                fetchAppointments={fetchAppointments}
                onCancel={() => {
                  handleOpenCancelAppointmentModal(appointment);
                }}
              />
            ))
          )}
        </Wrapper>
      </Container>
      {isCancelAppointmentModalOpen && (
        <CancelAppointmentModal
          open={isCancelAppointmentModalOpen}
          appointment={appointmentToCancel}
          onSubmit={handleCancelAppointment}
          onRequestClose={handleCloseCancelAppointmentModal}
        />
      )}
    </>
  );
};
