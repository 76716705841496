import { subMonths } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/AuthProvider';
import { api } from '../../../services/api';
import { formatPrice } from '../../../utils/format';
import { HeaderReports } from '../HeaderReports';
import { format } from 'date-fns';
import {
  Container,
  ContainerSpace,
  ContainerTypes,
  ListFinancial,
  TitleType,
} from './styles';
import { useTranslation } from 'react-i18next';

export function FinancialReportsStudents() {
  const { user } = useAuth();

  const [financialReportsStudents, setFinancialReportsStudents] = useState([]);
  const [startDate, setStartDate] = useState(subMonths(new Date(), 12));
  const [endDate, setEndDate] = useState(new Date());
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const fetchFinancialReportsStudents = useCallback(async () => {
    try {
      /**
       * Logica de pegar primeiro e ultimo dia do mes selecionado
       */
      let startDateState = startDate;
      let endDateState = endDate;

      if (typeof startDate === 'string' && startDate.indexOf(':') === -1) {
        startDateState += ' 00:00';
      }

      if (typeof endDate === 'string' && endDate.indexOf(':') === -1) {
        endDateState += ' 00:00';
      }

      const _startDate = new Date(startDateState);
      const _endDate = new Date(endDateState);

      const __startDate = new Date(
        _startDate.getFullYear(),
        _startDate.getMonth(),
        1,
      );

      const __endDate = new Date(
        _endDate.getFullYear(),
        _endDate.getMonth() + 1,
        0,
      );
      /* end */

      await api
        .get('/reports/financialstudents/me', {
          params: {
            startDate: format(__startDate, 'yyyy-MM-dd'),
            endDate: format(__endDate, 'yyyy-MM-dd'),
            search,
          },
        })
        .then(response => {
          setFinancialReportsStudents(response.data.docs);
        });
    } catch (err) {
      console.log(err);
    }
  }, [startDate, endDate, search]);

  useEffect(() => {
    fetchFinancialReportsStudents();
  }, [fetchFinancialReportsStudents]);

  return (
    <Container>
      <HeaderReports
        search={search}
        startDate={startDate}
        endDate={endDate}
        onSearchChange={setSearch}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        searchEnabled={false}
      />
      <ContainerTypes>
        <ContainerSpace>
          <TitleType title>{t('date')}</TitleType>
        </ContainerSpace>
        <ContainerSpace>
          {user.type === 'student' ? (
            <TitleType title>{t('purchasedClasses')}</TitleType>
          ) : (
            <TitleType title>{t('givenClasses')}</TitleType>
          )}
        </ContainerSpace>
        <ContainerSpace>
          <TitleType title>Payment Method</TitleType>
        </ContainerSpace>
        {/* <ContainerSpace>
          <TitleType title>Plan Or Individual</TitleType>
        </ContainerSpace> */}
        <ContainerSpace>
          <TitleType title>{t('value')}</TitleType>
        </ContainerSpace>
      </ContainerTypes>

      {financialReportsStudents.map(financialReport => (
        <ListFinancial>
          <ContainerSpace>
            <TitleType>{financialReport.paymentDate}</TitleType>
          </ContainerSpace>
          <ContainerSpace>
            <TitleType>{financialReport.quantity}</TitleType>
          </ContainerSpace>
          <ContainerSpace>
            <TitleType>{financialReport.billingType}</TitleType>
          </ContainerSpace>
          {/* <ContainerSpace>
            <TitleType>{financialReport.plan ? 'Plan Payment' : 'Individual Purchase'}</TitleType>
          </ContainerSpace> */}
          <ContainerSpace>
            <TitleType>{formatPrice(financialReport.value)}</TitleType>
          </ContainerSpace>
        </ListFinancial>
      ))}
    </Container>
  );
}
