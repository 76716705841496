import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-right: 1.2rem;
  border: 1px solid var(--gray3);
  width: 100%;
  /* max-height: 68rem; */

  header {
    height: 6rem;
    background: #f9f9f9;
    border-bottom: 1px solid var(--gray3);
    border-radius: 0.5rem 0.5rem 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 1rem;
    border-bottom: 1px solid var(--gray3);

    @media (max-width: 1300px) {
      display: grid;
      grid-template-columns: 5fr 1fr;
      justify-content: space-between;
      justify-items: start;
    }
  }

  .boxQuestions {
    height: 80rem;
    overflow-y: scroll;
  }

  footer {
    height: 6rem;
    background: #f9f9f9;
    width: 100%;
    border-radius: 0 0 0.5rem 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    .iconify {
      margin-right: 2rem;
    }
  }

  .column {
    display: flex;
    align-items: center;
  }
`;

export const Button = styled.button`
  height: 6rem;
  width: 8rem;
  border-right: 1px solid var(--gray3);
  color: #243342;
  cursor: pointer;
  display: ${props => props.disable && 'none'};

  .iconify {
    font-size: 2rem;
  }

  ${props =>
    props.finaly &&
    css`
      border-right: none;
      border-left: 1px solid var(--gray3);
    `}

  ${props =>
    props.left &&
    css`
      border-left: 1px solid var(--gray3);
    `}
`;

export const ContainerText = styled.div`
  display: flex;
  margin-left: 1.2rem;

  @media (max-width: 560px) {
    display: none;
  }
`;

export const Text = styled.span`
  font-size: 1.9rem;
  color: #243342;
  margin-right: 1.2rem;
  padding-right: 1.2rem;
  border-right: 1px solid var(--gray3);

  ${props =>
    props.finaly &&
    css`
      border-right: none;
    `}

  ${props =>
    props.list &&
    css`
      border-right: none;
    `}

  ${props =>
    props.count &&
    css`
      border-right: none;
      padding: 0;
      margin-left: 1.2rem;
    `}
`;

export const IconFlag = styled.img`
  height: 4rem;
  width: 4rem;
  margin: 0 1rem;

  @media (max-width: 1100px) {
    margin: 0 0.1rem;
  }
`;
