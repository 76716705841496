/* eslint-disable @typescript-eslint/no-explicit-any */

// get user from storage
const user: any = JSON.parse(localStorage.getItem('@TableTalks:user') || '{}');

// helper to check user online status
export const isUserStudent = () => user.type === 'student';

// helper to check if user is a teacher
export const isUserTeacher = () => user.type === 'teacher';

// helper to get user from session
export const getSessionUserID = () => user._id || user.id;
