import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../../components/Button';
import { Span } from '../../../../components/Span';
import { checkIsVideo } from '../../../../helpers/url';
import { api } from '../../../../services/api';
import { customState } from '../../../../utils/customState';
import {
  Container,
  ContainerBody,
  LoadingContainer,
  NextButtonContainer,
  Writtenexercises,
  WrittenexercisesBody,
  WrittenexercisesHeader,
  WrittenexercisesQuestions,
  WrittenexercisesQuestionsButtons,
  WrittenexercisesQuestionsChoice,
  WrittenexercisesQuestionsGroupButtons,
  WrittenexercisesQuestionsUniqueButton,
  ContainerQuestion,
  QuestionBody,
  ImageBody,
  Video,
  VideoBody,
} from './styles';

export function DashboardStudentMultiple() {
  const { t } = useTranslation();
  console.log('nvfskdjnfjsdvnufoewrhewoiurewo');
  const history = useHistory();
  const location0 = useLocation();
  const history2 = customState(location0);
  const id = history2.state.id;

  const [score, setScore] = useState();
  const [homework, setHomework] = useState();
  const [isHomeworkLoading, setIsHomeworkLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const [answers, setAnswers] = useState([]);

  const handleNextQuestion = async () => {
    let responseText = '';
    if (answers) {
      responseText = answers.find(
        a => a.questionId === activeQuestion?._id,
      )?.answer;
      if (
        responseText === null ||
        responseText === undefined ||
        responseText === ''
      ) {
        responseText = '';
      }
    }
    if (responseText === '') {
      toast('Select an answer', {
        type: 'error',
      });
      return false;
    }
    await api.put(`/homework/${id}`, { answers });

    setActivePage(prevState => {
      if (
        homework.from === 'quiz' &&
        homework.quiz.multipleChoiceQuestions &&
        homework.quiz.multipleChoiceQuestions.length - 1 === prevState
      ) {
        return prevState;
      }

      if (
        homework.from === 'exam' &&
        homework.exam.multipleChoiceQuestions &&
        homework.exam.multipleChoiceQuestions.length - 1 === prevState
      ) {
        return prevState;
      }

      return prevState + 1;
    });
  };

  const handleSubmit = async () => {
    let responseText = '';
    console.log(answers);
    console.log(activeQuestion);
    if (answers) {
      responseText = answers.find(
        a => a.questionId === activeQuestion?._id,
      )?.answer;
      console.log(responseText);
      if (
        responseText === null ||
        responseText === undefined ||
        responseText === ''
      ) {
        responseText = '';
      }
    }
    if (responseText === '') {
      toast('Select an answer', {
        type: 'error',
      });
      return false;
    }
    console.log('answers', answers);
    await api.put(`/homework/${id}/submit`, { answers }).then(response => {
      setScore(response.data.score);
    });
  };

  const handlePreviousQuestion = answerOption => {
    setActivePage(prevState => {
      if (prevState <= 0) {
        return prevState;
      }

      return prevState - 1;
    });
  };

  const handleAnswerChange = useCallback(answer => {
    setAnswers(prevState => {
      const dataUpdate = [...prevState];

      const answerIndex = dataUpdate.findIndex(
        a => a.questionId === answer.questionId,
      );

      if (answerIndex <= -1) {
        return [...prevState, answer];
      }

      dataUpdate[answerIndex] = answer;

      return [...dataUpdate];
    });
  }, []);

  const activeQuestion = useMemo(() => {
    if (!homework) return {};
    if (homework.from === 'quiz') {
      if (!homework.quiz) return {};
      return homework.quiz.multipleChoiceQuestions[activePage];
    }
    if (homework.from === 'exam') {
      if (!homework.exam) return {};
      return homework.exam.multipleChoiceQuestions[activePage];
    }
  }, [activePage, homework]);

  useEffect(() => {
    fetchHomework();
  }, []);
  const maxQuestionsNumbers = data => {
    if (!data) return 0;
    if (data.from === 'quiz') {
      if (!data.quiz) return 0;
      return data.quiz.multipleChoiceQuestions.length;
    }
    if (data.from === 'exam') {
      if (!data.exam) return 0;
      return data.exam.multipleChoiceQuestions.length;
    }
  };
  const fetchHomework = useCallback(async () => {
    try {
      setIsHomeworkLoading(true);

      await api.get(`homework/${id}`).then(async response => {
        const { data } = response;

        if (data.status === null) {
          await api.patch(`homework/${id}/update-status`, {
            status: 'started',
          });
        }

        setHomework(data);
        setScore(data.score.hits ? data.score : undefined);
        setActivePage(data.answers && data.answers.length);
        if (data.answers && data.answers.length >= maxQuestionsNumbers(data)) {
          setActivePage(maxQuestionsNumbers(data) - 1);
        }
        setAnswers(
          data.answers.map(answer => ({
            questionId: answer.questionId,
            question: answer.question,
            answer: Number(answer.answer),
          })),
        );
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsHomeworkLoading(false);
    }
  }, []);

  if (!homework || isHomeworkLoading) {
    return (
      <LoadingContainer>
        <Loader
          visible
          type="ThreeDots"
          color="#124f8c"
          secondaryColor="#191919"
        />
      </LoadingContainer>
    );
  }

  const isVideo = activeQuestion ? checkIsVideo(activeQuestion.fileURL) : false;

  return (
    <Container>
      <ContainerBody>
        <Writtenexercises>
          <WrittenexercisesHeader>
            <Link to="/dashboard/method">{t('voltar')}</Link>
            <Span>{homework?.quiz?.title}</Span>
          </WrittenexercisesHeader>
          <WrittenexercisesBody>
            <WrittenexercisesQuestions>
              {score ? (
                <Span>
                  {t('youScored', { score: (score.grade * 10).toFixed(2) })}
                </Span>
              ) : (
                activeQuestion && (
                  <ContainerQuestion>
                    {!!activeQuestion.fileURL && !isVideo && (
                      <ImageBody>
                        {!isVideo && <img src={activeQuestion.fileURL} />}
                      </ImageBody>
                    )}
                    {isVideo && (
                      <VideoBody>
                        <Video src={activeQuestion.fileURL} controls />
                      </VideoBody>
                    )}
                    <QuestionBody>
                      <Span>{activeQuestion.title}</Span>
                      <WrittenexercisesQuestionsChoice>
                        {activeQuestion.answers?.map((answer, index) => {
                          const isSelected = answers.some(
                            a =>
                              a.questionId === activeQuestion._id &&
                              a.answer === index,
                          );

                          return (
                            <Button
                              color={isSelected ? '#083059' : '#fff'}
                              fontColor={isSelected ? '#fff' : '#8a8895'}
                              title={answer.text}
                              onClick={() => {
                                handleAnswerChange({
                                  questionId: activeQuestion._id,
                                  question: activeQuestion.title,
                                  answer: index,
                                });
                              }}
                            />
                          );
                        })}
                      </WrittenexercisesQuestionsChoice>
                      <WrittenexercisesQuestionsButtons>
                        {/* <div className="buttons-container"> */}
                        {/* <NextButtonContainer> */}
                        <Button
                          color="#DBAD00"
                          fontColor="#fff"
                          title={t('finishLater')}
                          onClick={() =>
                            history.push('/dashboard/homework/written')
                          }
                        />
                        <Button
                          color={'#083059'}
                          // color={activePage != 0 ? '#083059' : '#999'}
                          fontColor="#fff"
                          title={t('previous')}
                          // style={{ width: '49%', marginRight: '2%' }}
                          onClick={handlePreviousQuestion}
                        />
                        {homework.from === 'quiz' && homework.quiz && (
                          <>
                            {homework.quiz.multipleChoiceQuestions.length -
                              1 ===
                            activePage ? (
                              <Button
                                color="#06231F"
                                fontColor="#fff"
                                title={t('submit')}
                                // style={{ width: '49%' }}
                                onClick={handleSubmit}
                              />
                            ) : (
                              <Button
                                color="#083059"
                                fontColor="#fff"
                                title={t('next')}
                                // style={{ width: '49%' }}
                                onClick={handleNextQuestion}
                              />
                            )}
                          </>
                        )}
                        {homework.from === 'exam' &&
                          homework.exam &&
                          homework.exam.multipleChoiceQuestions && (
                            <>
                              {homework.exam.multipleChoiceQuestions.length -
                                1 ===
                              activePage ? (
                                <Button
                                  color="#06231F"
                                  fontColor="#fff"
                                  title={t('submit')}
                                  // style={{ width: '49%' }}
                                  onClick={handleSubmit}
                                />
                              ) : (
                                <Button
                                  color="#06231F"
                                  fontColor="#fff"
                                  title={t('next')}
                                  // style={{ width: '49%' }}
                                  onClick={handleNextQuestion}
                                />
                              )}
                            </>
                          )}
                        {/* </NextButtonContainer> */}
                        {/* </div> */}
                      </WrittenexercisesQuestionsButtons>
                    </QuestionBody>
                  </ContainerQuestion>
                )
              )}
            </WrittenexercisesQuestions>
          </WrittenexercisesBody>
        </Writtenexercises>
      </ContainerBody>
    </Container>
  );
}
