import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface TextProps {
  notification?: boolean;
  subtitle?: boolean;
  alert?: boolean;
}

export const Container = styled.div`
  width: 30rem;
  height: 37rem;

  background: #fff;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 50;
  right: 0;
`;

export const ContainerTitle = styled.div`
  border-bottom: 1px solid var(--gray3);
  width: 100%;
  height: 5rem;
  padding: 3rem 1rem;

  display: flex;
  align-items: center;
`;

export const Text = styled.span<TextProps>`
  font-size: 1.8rem;
  color: #5e5873;

  ${(props) => props.notification
    && css`
      font-size: 1.4rem;
      font-family: 'Montserrat';
    `}

  ${(props) => props.subtitle
    && css`
      font-size: 1.2rem;
      font-family: 'Montserrat';
      font-weight: 400;
    `}

  ${(props) => props.alert
    && css`
      font-size: 1.4rem;
      font-family: 'Montserrat';
      font-weight: 600;
    `}
`;

export const Image = styled.img`
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`;

export const ContainerSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  width: 100%;
  padding: 3rem 1rem;
`;

export const ContainerRadio = styled.div`
  display: flex;
  input:checked + .slider {
    background-color: #13c8a9;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #13c8a9;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  left: 20px;
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Span = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 15px;

  :before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const LinkTarget = styled(Link)`
  border-bottom: 1px solid var(--gray3);
  width: 100%;
  height: 5rem;
  padding: 3rem 1rem;
  text-decoration: none;

  display: flex;
  align-items: center;
`;
