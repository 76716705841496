import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { googleAuthenticatorService } from '../../../../services/googleAuthenticator';
import { twoFactorAuthService } from '../../../../services/twoFactorAuth';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import Input from '../../../../components/Input';
import { Button } from '../../../../components/Button';

import { useAuth } from '../../../../hooks/AuthProvider';

import {
  Container,
  Wrapper,
  Col25,
  WrapperForm,
  ContainerText,
  Step,
  Position,
  ContainerButton,
  ButtonPass,
  QRCodeBox,
  QRCodeImage,
} from './styles';

export function GoogleAuthenticator() {
  const formRef = useRef(null);
  const { t } = useTranslation();
  const { user } = useAuth();
  const history = useHistory();

  const [qrCode, setQrCode] = useState('');
  const [keyCode, setKeyCode] = useState('');
  const [openStep, setOpenStep] = useState(1);
  const [valueCode, setValueCode] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const resultKey = await googleAuthenticatorService.getKey();
        const key = resultKey.replace('Your new secret: ', '');
        const userFull = `${user.name}  ${user.lastName}`;

        const resultQrCode = await googleAuthenticatorService.getQrCode(key.trim(), userFull);
        setKeyCode(key.trim());
        setQrCode(resultQrCode);
      } catch (err) {
        console.log(err);
      }
    })()
  }, [])

  const handleSubmit = () => {
    (async () => {
      try {
        const result = await googleAuthenticatorService.validateUser(
          valueCode,
          keyCode,
        );

        if (result == 'True') {
          await twoFactorAuthService.updateTwoFactorAuth('google', keyCode);
          toast(t('authenticatorSuccess'), { type: 'success' })
          history.go(0);
        } else {
          toast(t('authenticatorError'), { type: 'error' })
        }
      } catch (err) {
        toast(`Ocorreu um erro: ${err}`, { type: 'error' })
      }
    })()
  };

  const handleNext = () => { setOpenStep(prevState => prevState + 1) }

  const handleBack = () => { setOpenStep(prevState => prevState - 1) }

  const removeGoogleAuth = () => {
    (async () => {
      try {
        await twoFactorAuthService.updateTwoFactorAuth('email', '');
        history.go(0);
      } catch (err) {
        toast(t('removeAuthenticationError'), { type: 'error' })

      }
    })()
  }

  return (
    <Container>
      {user.twoFactorAuthentication.type == 'google' ? <ButtonPass remove onClick={removeGoogleAuth}>
        {t('removeGoogle')}
      </ButtonPass> :
        <>
          <Wrapper>
            <Col25 notActive={openStep !== 1}>
              <Step>
                {t('passo')} 1 <Position notActive={openStep !== 1} />
              </Step>
              <ContainerText>
                <span>{t('codigoGoogleText1')}</span>
                <span>{t('codigoGoogleText2')}</span>
                <span>{keyCode ?? ''}</span>
                <QRCodeBox openStep={openStep}>
                  {qrCode ? (
                    <QRCodeImage src={`${qrCode}`} alt="qrcode" />
                  ) : (
                    <p>{t('carregando')} ...</p>
                  )}
                </QRCodeBox>
              </ContainerText>
            </Col25>
            <Col25 notActive={openStep !== 2}>
              <Step>
                {t('passo')} 2 <Position notActive={openStep !== 2} />
              </Step>
              <ContainerText>
                <span>{t('codigoGoogleText3')}</span>
                <span>{t('codigoGoogleText4')}</span>
                <span>{keyCode ?? ''}</span>
              </ContainerText>
            </Col25>
            <Col25 notActive={openStep !== 3}>
              <Step>
                {t('passo')} 3 <Position notActive={openStep !== 3} />
              </Step>
              <WrapperForm ref={formRef} onSubmit={handleSubmit}>
                <span>{t('codigoGoogle')}</span>
                <Input
                  placeholder={t('codigoGoogle')}
                  type="text"
                  name="password"
                  onChange={(e) => setValueCode(e.target.value)}
                  disabled={openStep == 3 ? false : true}
                  maxLength={6} />
                <span>{t('codigoGoogleText5')}</span>
                <Button
                  color="#ebe9f1"
                  fontColor="#243342"
                  title={t('salvar')}
                  type="submit"
                  valueCode={valueCode} />
              </WrapperForm>
            </Col25>
          </Wrapper>
          <ContainerButton>
            <ButtonPass back openStep={openStep} onClick={handleBack}>
              <span className="iconify" data-icon="dashicons:arrow-left-alt2" />
              Voltar
            </ButtonPass>
            <ButtonPass next openStep={openStep} onClick={handleNext}>
              Avançar{' '}
              <span className="iconify" data-icon="dashicons:arrow-right-alt2" />
            </ButtonPass>
          </ContainerButton>
        </>
      }
    </Container>
  );
}
