/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import closeImg from '../../../assets/icon_close.svg';
import Input from '../../../components/Form/Input';
import { Container } from './styles';
import './styles/styles.css';

Modal.setAppElement('#root');

export function CancelAppointmentModal({
  open,
  appointment,
  onSubmit,
  onRequestClose,
}) {
  const { t } = useTranslation();

  const [reason, setReason] = useState('');

  function handleReasonChange(event) {
    setReason(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    onSubmit(appointment._id, reason);

    onRequestClose();
  }

  return (
    <Modal
      isOpen={open}
      contentLabel=""
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" />
      </button>
      <Container onSubmit={handleSubmit}>
        <h2>{t('cancelClass')}</h2>
        <textarea rows={5} value={reason} onChange={handleReasonChange} />
        <button type="submit">{t('cancel')}</button>
      </Container>
    </Modal>
  );
}
