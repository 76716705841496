import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import IconFlagEngland from '../../../assets/icon_bandeira_inglaterra.png';
import IconFlagEUA from '../../../assets/icon_bandeira_usa.png';
import Modal from 'react-modal';

import { api } from '../../../services/api';
import {
  Container,
  ContainerText,
  Text,
  Image,
  IconFlag,
  BottonModal,
} from './styles';
import { useTranslation } from 'react-i18next';

export function StudentVocabulary({ conteudo, methodMission }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const handleAudioGoogle = async (languageCode, name) => {
    try {
      const voice = { languageCode, name };
      const text = document.getElementsByName('text')[0].innerText;

      const { data } = await api.post(`text/synthesize`, {
        voice,
        text,
      });

      var audio = new Audio(data.fileURL);
      audio.play();
    } catch (err) {
      console.error(err);
      toast('There is an audio recording issue. Please check your settings.', {
        type: 'error',
      });
    }
  };

  const handleShowDialog = value => {
    setIsOpen(value);
  };

  useEffect(() => {
    const _elem = document?.getElementById(`video-vocabulary-${conteudo?.id}`);
    if (_elem) {
      if (conteudo?.videoPlay == 1) {
        _elem.play();
      }
      if (conteudo?.videoPlay == 0) {
        _elem.pause();
      }
    }
  }, [conteudo]);

  return (
    <Container>
      <header>
        <div className="column">
          <ContainerText>
            <Text>
              <strong>{methodMission?.method}</strong>
            </Text>
            <Text>{methodMission?.mission}</Text>
            <Text finaly>{t('vocabulario')}</Text>
          </ContainerText>
        </div>
      </header>

      <section>
        {isOpen && conteudo?.vocabulary?.fileType.indexOf('image') > -1 && (
          <Modal
            className="react-modal-content"
            overlayClassName="react-modal-overlay"
            isOpen={isOpen}
            onRequestClose={false}
          >
            <Image
              onClick={() => handleShowDialog(false)}
              src={conteudo?.vocabulary?.fileURL}
            />
            <center>
              <BottonModal onClick={() => handleShowDialog(false)}>
                {t('fechar')}
              </BottonModal>
            </center>
          </Modal>
        )}

        {!isOpen && conteudo?.vocabulary?.fileType.indexOf('image') > -1 && (
          <Image
            onClick={() => handleShowDialog(true)}
            src={conteudo?.vocabulary?.fileURL}
          />
        )}

        {conteudo?.vocabulary?.fileType.indexOf('video') > -1 && (
          <center>
            <video
              id={`video-vocabulary-${conteudo.id}`}
              style={{
                maxWidth: '100%',
                maxHeight: '59rem',
                objectFit: 'contain',
              }}
              src={conteudo?.vocabulary?.fileURL}
              type="video/mp4"
              controls
            />
          </center>
        )}
      </section>
      <footer>
        <IconFlag
          src={IconFlagEUA}
          onClick={() => handleAudioGoogle('en-US', 'en-US-Wavenet-A')}
        />
        <Text name="text" footer>
          {conteudo?.title}
        </Text>
        <IconFlag
          src={IconFlagEngland}
          onClick={() => handleAudioGoogle('en-GB', 'en-GB-Wavenet-A')}
        />
      </footer>
    </Container>
  );
}
