/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable default-case */
import { differenceInMinutes, differenceInSeconds, format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import IconBreakthrough from '../../../assets/svgMethod/icon_breakthrough.svg';
import IconEffective from '../../../assets/svgMethod/icon_Effective.svg';
import IconMastery from '../../../assets/svgMethod/icon_Mastery.svg';
import IconStudent from '../../../assets/svgMethod/icon_student.svg';
import IconThreshold from '../../../assets/svgMethod/icon_Threshold.svg';
import IconVantage from '../../../assets/svgMethod/icon_Vantage.svg';
import IconWaystage from '../../../assets/svgMethod/icon_Waystage.svg';
import ImageLogo from '../../../assets/svgMethod/logo_banner.svg';
import { Chat } from '../../../components/Base/Chat';
import { CollapseSchedule } from '../../../components/Button/CollapseSchedule';
import { Header } from '../../../components/Header';
import { CancelAppointmentModal } from '../../../components/Modals/CancelAppointmentModal';
import { useAuth } from '../../../hooks/AuthProvider';
import { api } from '../../../services/api';
import { Appointment } from '../Schedule';
import { ListItems } from './ListItem';
import {
  Button,
  Container,
  ContainerActions,
  ContainerBanner,
  ContainerButtons,
  ContainerHour,
  ContainerShowComponents,
  ContainerTextsProfile,
  ContainerUser,
  ImageProfile,
  ImageStudent,
  Logo,
  Text,
  TitleBanner,
  Wrapper,
} from './styles';

interface INextClass {
  nextClassHour: string;
  startClassDate: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  student?: any;
  nextClass?: any;
}

interface IMethod {
  _id: string;
  title: string;
  lessons: number;
  icon: string;
  united: string;
  activeStudents: IStudent[];
  graduateStudents: IStudent[];
}

interface IMission {
  title: string;
}

interface IStudent {
  _id: string;
  avatarURL: string;
  fullName: string;
  name: string;
  studentLevel: string;
}
interface IQuestionsAnswers {
  _id: string;
  question: string;
  answer: string;
  isCorrected: boolean;
  isCorrectAnswer: number;
  obs: string;
}

interface ICorrections {
  _id: string;
  title: string;
  mission: IMission;
  student: IStudent;
  exam: any;
  quiz: any;
  exerciseDate: Date;
  totalCorrected: number;
  totalNotVerificated: number;
  answers: IQuestionsAnswers[];
}

interface IMethods {
  method: IMethod;
  corrections: ICorrections[];
  numberCorrections: number;
  students: IStudent[];
  numberStudents: number;
  graduateStudents: IStudent[];
  numberGraduateStudents: number;
}

type TMethod = {
  _id: string;
  icon: string;
  id: string;
  lessons: string;
  method: string;
  title: string;
  united: string;
};

type TMission = {
  id: string;
  title: string;
  type: string;
};

type TUser = {
  avatar: string;
  id: string;
  type: string;
  studentLevel: string;
};

type TUsers = {
  student: TUser;
  teacher: TUser;
};

type TAppointment = {
  appointmentId: string;
  isExperimentalClass: boolean;
  method: TMethod;
  mission: TMission;
  user: TUsers;
};

export const Method = () => {
  console.log('#### METHOD SCREEN ####');
  const { user } = useAuth();

  const [booksInfo, setBooksInfo] = useState<IMethods[]>();
  const [nextClass, setNextClass] = useState<INextClass>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [showChat, setShowChat] = useState<boolean>(false);
  const [showReschedule, setShowReschedule] = useState<boolean>(false);
  const [mission, setMission] = useState<TAppointment>();
  const [nextAppointment, setNextAppointment] = useState<Appointment>();
  const [hasNextClass, setHasNextClass] = useState(false);
  const [showCancelButton] = useState(true);
  const [showRescheludeButton, setShowRescheludeButton] = useState(true);
  const [timeToClass, setTimeToClass] = useState(0);
  const [enableStart, setEnableStart] = useState<boolean>(false);
  const [isCancelAppointmentModalOpen, setIsCancelAppointmentModalOpen] = useState(false);

  const setActionButtons = () => {
    let differenceTime = 0;

    const timestamp = new Date();
    const actualTime = new Date(timestamp);
    const appointmentTime = nextClass?.startClassDate;

    if (appointmentTime) {
      const nextClassTime = new Date(appointmentTime);
      differenceTime = differenceInMinutes(nextClassTime, actualTime);
    }

    const differenceTimeInHours = differenceTime / 60;

    setTimeToClass(differenceTimeInHours);

    if (differenceTimeInHours > 12) {
      setShowRescheludeButton(true);
    } else {
      setShowRescheludeButton(false);
    }
  };

  const checkLoadTime = useCallback(async (loadTimeData: any) => {
    try {
      const { data } = await api.get(
        `/classtimecontrol/appointment/${loadTimeData?.appointmentId}`,
      );
      console.log(data);
      if (!data) {
        setHasNextClass(true);
        return;
      }
      let tempDate = data.hours;
      if (data.hours === '00:00:00') {
        const tempDate0 = data.date.split('T');
        if (tempDate0.length > 0) {
          const tempDate1 = tempDate0[1].split(':');
          if (tempDate1.length > 1) {
            tempDate = `${tempDate1[0]}:${tempDate1[1]}:00`;
          }
        }
      }
      console.log(tempDate);
      setHasNextClass(tempDate !== '00:00:00');
    } catch (err) {
      console.error('erro', err);
      setHasNextClass(false);
    }
  }, []);

  const fetchNextClass = useCallback(async () => {
    setIsLoading(true);

    if (!user) {
      setNextClass(undefined);
      setIsLoading(false);
      setHasNextClass(false);
    }

    await api.get(`/appointments/next-class/${user.id}`).then((response) => {
      setNextClass(response.data);
      setIsLoading(false);
      setMission(response.data.mission);
      checkLoadTime(response.data.mission);

      if (response?.data?.nextClassHour !== '-') {
        setHasNextClass(true);
        setActionButtons();
      } else {
        setHasNextClass(false);
        setActionButtons();
      }
    });
  }, [user]);

  const goToMission = () => {
    localStorage.setItem('state', JSON.stringify(mission));
    history.push({
      pathname: '/dashboard/method/mission',
      state: mission,
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!nextClass) {
        return;
      }

      const { startClassDate } = nextClass || {};

      const currentDate = new Date();

      const differenceTime = differenceInSeconds(
        new Date(startClassDate),
        currentDate,
      );

      let startTemp = enableStart;

      if (differenceTime <= 30 && differenceTime >= -599) {
        startTemp = true;
      }

      if (differenceTime <= -600) {
        startTemp = false;
      }

      if (nextClass?.nextClass?.status.toString() === 'in_progress') {
        startTemp = true;
      }

      if (startTemp !== enableStart) {
        setEnableStart(startTemp);
      }
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [nextClass]);

  const handleCancelAppointment = useCallback(
    async (appointment: any, reason: string) => {
      try {
        if (timeToClass < 12) {
          await api.get(
            `/actions/teacher/cancel/after/${appointment?.mission?.appointmentId}?reason=${reason}`,
          );
        } else {
          await api.get(
            `/actions/teacher/cancel/before/${appointment?.mission?.appointmentId}?reason=${reason}`,
          );
        }

        toast(t('yourLessonHasBeenCanceled2'), { type: 'success' });
      } catch (err) {
        console.error(err);
      } finally {
        setHasNextClass(false);
      }
    },
    [],
  );

  const fetchAppointments = useCallback(async () => {
    try {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

      await api
        .get<Appointment[]>('/appointments/me', {
          params: { timeZone, status: 'accepted' },
        })
        .then((response) => {
          const teacher = response?.data[0]?.attendees.find(
            (a) => a.type === 'teacher',
          ) as Appointment['attendees'][0];

          setNextAppointment({
            ...response.data[0],
            teacher,
          });
        });
    } catch (err) {
      console.error(err);
    }
  }, []);

  const fetchMethods = useCallback(() => {
    setIsLoading(true);

    if (!user) {
      setBooksInfo(undefined);
      setIsLoading(false);
    }

    api.get(`/methods/teacher/${user.id}`).then((response) => {
      const books = response.data.map((item: IMethods) => {
        switch (item.method.icon) {
          case 'IconBreakthrough':
            // eslint-disable-next-line no-param-reassign
            item.method.icon = IconBreakthrough;
            return item;
          case 'IconWaystage':
            // eslint-disable-next-line no-param-reassign
            item.method.icon = IconWaystage;
            return item;
          case 'IconThreshold':
            // eslint-disable-next-line no-param-reassign
            item.method.icon = IconThreshold;
            return item;
          case 'IconVantage':
            // eslint-disable-next-line no-param-reassign
            item.method.icon = IconVantage;
            return item;
          case 'IconEffective':
            // eslint-disable-next-line no-param-reassign
            item.method.icon = IconEffective;
            return item;
          case 'IconMastery':
            // eslint-disable-next-line no-param-reassign
            item.method.icon = IconMastery;
            return item;
          default:
            return item;
        }
      });

      setBooksInfo(books);
      setIsLoading(false);
    });
  }, [user]);

  useEffect(() => {
    fetchAppointments();
    fetchNextClass();
    fetchMethods();
  }, [fetchNextClass, fetchMethods, fetchAppointments]);

  /* ANOTAÇÃO DA ÚLTIMA AULA DO PROFESSOR */
  const handleNote = () => {
    const note = nextAppointment?.lastAppointment?.comment
      ? nextAppointment?.lastAppointment?.comment
      : t('noNotes');

    toast(note, {
      type: 'info',
      autoClose: false,
      toastId: 'note',
      theme: 'light',
    });
  };

  return (
    <Container>
      <Header />

      <Wrapper>
        <ContainerBanner>
          <Logo src={ImageLogo} />
          <TitleBanner>
            You have 15 minutes per week to study the method and plan your classes.
            It will be updated every Monday.
          </TitleBanner>
        </ContainerBanner>
        {hasNextClass && (
          <ContainerActions>
            <ContainerHour>
              <ImageStudent src={IconStudent} />
              {isLoading ? (
                <Text>
                  {t('nextClass')}
                  : --:--
                  {' '}
                </Text>
              ) : (
                <Text>
                  {t('nextClass')}
                  {' '}
                  :
                  {' '}
                  {nextClass?.startClassDate
                    ? format(new Date(nextClass.startClassDate), 'hh:mm aa')
                    : ' --:-- '}
                </Text>
              )}
            </ContainerHour>

            {isLoading ? (
              <ContainerUser>
                <ImageProfile src="https://tabletalks-method.s3.sa-east-1.amazonaws.com/users/avatar.png" />
                <ContainerTextsProfile>
                  <Text> - </Text>
                  <Text> - </Text>
                </ContainerTextsProfile>
              </ContainerUser>
            ) : (
              <ContainerUser>
                {nextClass?.student?.avatar ? (
                  <ImageProfile src={nextClass?.student?.avatar} />
                ) : (
                  <ImageProfile src="https://tabletalks-method.s3.sa-east-1.amazonaws.com/users/avatar.png" />
                )}
                <ContainerTextsProfile>
                  <Text>
                    {`${
                      nextClass?.student?.fullName
                    ?? nextClass?.student?.name
                    ?? ' - '
                    }`}
                  </Text>
                  <Text>
                    {' '}
                    {nextClass?.student?.studyLevel ?? ' - '}
                    {' '}
                  </Text>
                </ContainerTextsProfile>
              </ContainerUser>
            )}

            <ContainerButtons>
              <button type="button" onClick={() => handleNote()}>
                <span
                  className="iconify"
                  data-icon="clarity:note-edit-line"
                  style={{ fontSize: '3rem', color: '#fff' }}
                />
              </button>
              {enableStart && (
                <Button onClick={goToMission}>
                  {t('comecar')}
                  {' '}
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                  />
                </Button>
              )}
              {showRescheludeButton && (
                <Button
                  schedule
                  onClick={() => {
                    if (!showReschedule) {
                      setShowReschedule(true);
                      setShowChat(false);
                    } else {
                      setShowReschedule(false);
                    }
                  }}
                >
                  <span className="iconify" data-icon="akar-icons:calendar" />
                  {t('reagendar')}
                </Button>
              )}
              <Button
                schedule
                onClick={() => {
                  if (!showChat) {
                    setShowChat(true);
                    setShowReschedule(false);
                  } else {
                    setShowChat(false);
                  }
                }}
              >
                <span className="iconify" data-icon="bi:chat-left-text" />
                {t('enviarMensagem')}
              </Button>
              {showCancelButton && (
                <Button
                  cancel
                  onClick={() => setIsCancelAppointmentModalOpen(true)}
                >
                  <span className="iconify" data-icon="eva:close-fill" />
                  {t('cancelar')}
                </Button>
              )}
            </ContainerButtons>
            {nextAppointment && showReschedule && (
              <CollapseSchedule
                appointment={nextAppointment}
                fetchAppointments={fetchAppointments}
                setShowSchedule={setShowReschedule}
              />
            )}
          </ContainerActions>
        )}

        {showChat && (
          <ContainerShowComponents>
            <Chat
              sentId={nextClass?.student?.id}
              name={nextClass?.student?.fullName ?? nextClass?.student?.name}
              imageURL={nextClass?.student?.avatar}
            />
          </ContainerShowComponents>
        )}
        {booksInfo?.map((item) => (
          <>
            <ListItems
              key={item.method.title}
              imageBook={item.method.icon}
              title={item.method.title}
              method={item}
              mission={mission}
              fetchMethods={fetchMethods}
            />
          </>
        ))}

        {isCancelAppointmentModalOpen && (
          <CancelAppointmentModal
            open={isCancelAppointmentModalOpen}
            appointment={nextClass}
            onSubmit={(id: any, reason: any) => handleCancelAppointment(nextClass, reason)}
            onRequestClose={() => setIsCancelAppointmentModalOpen(false)}
          />
        )}
      </Wrapper>
    </Container>
  );
};
