import React, { useRef, useState, useEffect } from 'react';
import ImageStudent from '../../../assets/icon_student.svg';
import ImageStudentNotActive from '../../../assets/icon_student_green.svg';
import {
  Container,
  Button,
  Content,
  BoxSchedule,
  Text,
  Image,
  MenuButton,
} from './styles';
import { api } from '../../../services/api';
import { parseISO, format } from 'date-fns';

function HeaderSchedule({ appointments }) {
  const wrapperRef = useRef(null);

  return (
    <Container>
      <Button
        onClick={() => {
          wrapperRef.current.scrollTo({
            top: 0,
            left: wrapperRef.current.scrollLeft - 120,
            behavior: 'smooth',
          });
        }}
      >
        <span
          className="iconify"
          data-icon="dashicons:arrow-left-alt2"
          data-inline="false"
        />
      </Button>
      <Content ref={wrapperRef}>
        {appointments.map((appointment,i) => (
          <BoxSchedule key={appointment._id} notActive={i !== 0}>
            <Image src={ImageStudent} />
            <MenuButton
              className="iconify"
              data-icon="carbon:overflow-menu-vertical"
              data-inline="false"
            />
            <Text top>
              <span
                className="iconify"
                data-icon="akar-icons:calendar"
                data-inline="false"
              />
              {format(parseISO(appointment.startTime), 'dd/MM/yyyy')}
            </Text>
            <Text>
              <span
                className="iconify"
                data-icon="ic:outline-watch-later"
                data-inline="false"
              />
              {format(parseISO(appointment.startTime), 'HH:mm')}
            </Text>
          </BoxSchedule>
        ))}
      </Content>
      <Button
        onClick={() => {
          wrapperRef.current.scrollTo({
            top: 0,
            left: 120 + wrapperRef.current.scrollLeft,
            behavior: 'smooth',
          });
        }}
      >
        <span
          className="iconify"
          data-icon="dashicons:arrow-right-alt2"
          data-inline="false"
        />
      </Button>
    </Container>
  );
}

export default HeaderSchedule;
