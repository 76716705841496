import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import IconFlagEngland from '../../../assets/icon_bandeira_inglaterra.png';
import IconFlagEUA from '../../../assets/icon_bandeira_usa.png';
import Modal from 'react-modal';
import { socket } from '../../../services/contextSocket';
import { api } from '../../../services/api';
import { useLocation } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';

import {
  Container,
  Button,
  ContainerText,
  Text,
  Image,
  IconFlag,
  BottonModal,
  ContainerImageFullScreen,
  WrapperHeaderImageFullScreen,
  WrapperButtonClose,
  ImageFull,
  TitleImageFullScreen,
} from './styles';
import { useTranslation } from 'react-i18next';

export function VocabularyPlanning(props) {
  const { conteudo, currentContent, setCurrentContent } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleAudioGoogle = async (languageCode, name) => {
    try {
      const voice = { languageCode, name };
      const text = document.getElementsByName('text')[0].innerText;

      const { data } = await api.post(`text/synthesize`, {
        voice,
        text,
      });

      var audio = new Audio(data.fileURL);
      audio.play();
    } catch (err) {
      console.error(err);
      toast('There is an audio recording issue. Please check your settings.', {
        type: 'error',
      });
    }
  };

  const handleShowDialog = value => {
    setIsOpen(value);
  };

  function handleArrow({ key }) {
    const classRoom = location?.state?.appointmentId;
    if (key === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return prevState;
        }

        return prevState - 1;
      });

      let current = currentContent;

      if (current === 0) {
        current = current;
      } else {
        current = current - 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }

    if (key === 'ArrowRight') {
      setCurrentContent(prevState => {
        if (prevState === conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });

      socket.emit('classroom:create', classRoom);

      let current = currentContent;

      if (current === 0) {
        current = current;
      } else {
        current = current + 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }
  }

  function handleArrowClick(e) {
    if (e === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return;
        }

        return prevState - 1;
      });

      const classRoom = location.state?.appointmentId;

      let current = currentContent;

      if (current === 0) {
        current = current;
      } else {
        current = current - 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }

    if (e === 'ArrowRight') {
      setCurrentContent(prevState => {
        if (prevState === conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });

      const classRoom = location.state?.appointmentId;

      socket.emit('classroom:create', classRoom);

      let current = currentContent;

      if (current === conteudo.length - 1) {
        current = current;
      } else {
        current = current + 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }
  }

  function handleGoToBeginning() {
    setCurrentContent(0);

    const content = conteudo[0];
    socket.emit('classroom:classcontent', { classRoom, content });

    const contentType = 'vocabulary';
    socket.emit('classroom:classcontenttype', { classRoom, contentType });

    const methodMission = {
      method: conteudo[0]?.method?.title,
      mission: conteudo[0]?.mission?.title,
    };
    socket.emit('classroom:classcontentmethodmission', {
      classRoom,
      methodMission,
    });
  }

  useEffect(() => {
    window.addEventListener('keydown', handleArrow);

    return () => {
      window.removeEventListener('keydown', handleArrow);
    };
  });

  return (
    <Container>
      <header>
        <div className="column">
          {currentContent === conteudo.length - 1 && (
            <Button onClick={e => handleGoToBeginning()}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}

          <ContainerText>
            <Text>
              <strong>{conteudo[currentContent]?.method?.title}</strong>
            </Text>
            <Text>{conteudo[currentContent]?.mission?.title}</Text>
            <Text finaly>{t('vocabulario')}</Text>
          </ContainerText>
        </div>
        <div className="column">
          {currentContent !== 0 && (
            <Button left onClick={e => handleArrowClick('ArrowLeft')}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
          {currentContent !== conteudo.length - 1 && (
            <Button finaly onClick={e => handleArrowClick('ArrowRight')}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
        </div>
      </header>

      <section style={{ minHeight: '1200px' }}>
        {isOpen &&
          conteudo[currentContent]?.vocabulary?.fileType.indexOf('image') >
            -1 && (
            <ContainerImageFullScreen>
              <WrapperHeaderImageFullScreen>
                <TitleImageFullScreen>
                  {conteudo[currentContent]?.title}
                </TitleImageFullScreen>
              </WrapperHeaderImageFullScreen>
              <WrapperButtonClose
                type="button"
                onClick={() => handleShowDialog(false)}
              >
                <AiOutlineClose size={30} />
              </WrapperButtonClose>

              <ImageFull
                src={conteudo[currentContent]?.vocabulary?.fileURL}
                alt={conteudo[currentContent]?.title}
              />
            </ContainerImageFullScreen>
          )}

        {!isOpen &&
          conteudo[currentContent]?.vocabulary?.fileType.indexOf('image') >
            -1 && (
            <Image
              onClick={() => handleShowDialog(true)}
              src={conteudo[currentContent]?.vocabulary?.fileURL}
            />
          )}

        {conteudo[currentContent]?.vocabulary?.fileType.indexOf('video') >
          -1 && (
          <center>
            <video
              // style={{maxWidth: '100%', maxHeight: '59rem', objectFit: 'contain'}}
              style={{
                maxWidth: '100%',
                maxHeight: '90%',
                objectFit: 'contain',
              }}
              src={conteudo[currentContent]?.vocabulary?.fileURL}
              type="video/mp4"
              controls
            />
          </center>
        )}
        <footer>
          <IconFlag
            src={IconFlagEUA}
            onClick={() => handleAudioGoogle('en-US', 'en-US-Wavenet-A')}
          />
          <Text name="text" footer>
            {conteudo[currentContent]?.title}
          </Text>
          <IconFlag
            src={IconFlagEngland}
            onClick={() => handleAudioGoogle('en-GB', 'en-GB-Wavenet-A')}
          />
        </footer>
      </section>
    </Container>
  );
}
