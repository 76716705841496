import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ContainerBody,
  Writtenexercises,
  WrittenexercisesHeader,
  WrittenexercisesBody,
  WrittenexercisesImage,
  WrittenexercisesQuestions,
  ImageLogo,
  WrittenexercisesQuestionsButtons,
  WrittenexercisesQuestionsUniqueButton,
  LoadingContainer,
  NextButtonContainer,
  ImageCustom,
  ImageContainer,
  Video,
  VideoBody,
} from './styles';
import { Span } from '../../../../components/Span';
import { Button } from '../../../../components/Button';
import { customState } from '../../../../utils/customState';
import { useHistory } from 'react-router-dom';
import { api } from '../../../../services/api';
import { ContainerColumn2Header } from '../../Guideline/ReadBook/styles';
import { checkIsVideo } from '../../../../helpers/url';

export function DashboardStudentWritten() {
  const { t } = useTranslation();

  const history = useHistory();
  const location0 = useLocation();
  const history2 = customState(location0);
  const id = history2.state.id;

  const [score, setScore] = useState();
  const [homework, setHomework] = useState();
  const [isHomeworkLoading, setIsHomeworkLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const [answers, setAnswers] = useState([]);

  const handleNextQuestion = async () => {
    let responseText = '';
    if (answers) {
      responseText = answers.find(
        a => a.questionId === activeQuestion?._id,
      )?.answer;
      if (
        responseText == null ||
        responseText == undefined ||
        responseText == ''
      ) {
        responseText = '';
      }
    }
    if (responseText === '') {
      toast('You need to add an answer', {
        type: 'error',
      });
      return false;
    }
    await api.put(`/homework/${id}`, { answers });

    setActivePage(prevState => {
      if (homework.quiz?.writtenQuestions.length - 1 === prevState) {
        return prevState;
      }

      return prevState + 1;
    });
  };

  const handleSubmit = async () => {
    let responseText = '';
    if (answers) {
      responseText = answers.find(
        a => a.questionId === activeQuestion?._id,
      )?.answer;
      if (
        responseText === null ||
        responseText === undefined ||
        responseText === ''
      ) {
        responseText = '';
      }
    }
    if (responseText === '') {
      toast('You need to add an answer', {
        type: 'error',
      });
      return false;
    }
    await api.put(`/homework/${id}/submit`, { answers }).then(response => {
      setScore(response.data.score);
    });

    toast('Your homework has been submitted. You will be notified shortly', {
      type: 'info',
    });

    history.push('/dashboard/homework/written');
  };

  const handlePreviousQuestion = answerOption => {
    setActivePage(prevState => {
      if (prevState <= 0) {
        return prevState;
      }
      return prevState - 1;
    });
  };

  const handleAnswerChange = useCallback(answer => {
    setAnswers(prevState => {
      const dataUpdate = [...prevState];

      const answerIndex = dataUpdate.findIndex(
        a => a.questionId === answer.questionId,
      );

      if (answerIndex <= -1) {
        return [...prevState, answer];
      }

      dataUpdate[answerIndex] = answer;

      return [...dataUpdate];
    });
  }, []);
  const maxQuestionsNumbers = data => {
    if (!data) return 0;
    if (data.from === 'quiz' && data.quiz) {
      return data.quiz.writtenQuestions.length;
    }
    if (data.from === 'exam' && data.exam) {
      return data.exam.writtenQuestions.length;
    }
  };
  const activeQuestion = useMemo(() => {
    if (!homework) return {};
    if (homework.from === 'quiz' && homework.quiz) {
      return homework.quiz.writtenQuestions[activePage];
    }
    if (homework.from === 'exam' && homework.exam) {
      return homework.exam.writtenQuestions[activePage];
    }
  }, [activePage, homework]);

  useEffect(() => {
    fetchHomework();
  }, []);

  const fetchHomework = useCallback(async () => {
    try {
      setIsHomeworkLoading(true);

      await api.get(`homework/${id}`).then(async response => {
        const { data } = response;

        if (data?.status === null) {
          console.log(
            await api.patch(`homework/${id}/update-status`, {
              status: 'started',
            }),
          );
        }

        if (!data) return false;

        setHomework(data);
        setScore(data?.score);
        if (data && data.answers) {
          setActivePage(data.answers && data.answers.length);
          if (
            data.answers &&
            data.answers.length >= maxQuestionsNumbers(data)
          ) {
            setActivePage(maxQuestionsNumbers(data) - 1);
          }
        }
        if (data?.answers) {
          setAnswers(
            data?.answers.map(answer => ({
              questionId: answer?.questionId,
              question: answer?.question,
              answer: answer?.answer,
            })),
          );
        }
        setIsHomeworkLoading(false);
      });
    } catch (err) {
      console.error(err);
      setIsHomeworkLoading(false);
    } finally {
      setIsHomeworkLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchHomework();
  }, []);

  if (isHomeworkLoading) {
    return (
      <LoadingContainer>
        <Loader
          visible
          type="ThreeDots"
          color="#124f8c"
          secondaryColor="#191919"
        />
      </LoadingContainer>
    );
  } else if (!homework) {
    return (
      <Container>
        <h3
          style={{ fontSize: '26px', textAlign: 'center', marginTop: '20px' }}
        >
          {t('thereAreNo')} {t('openEndedQuestions')}!
        </h3>
      </Container>
    );
  }

  const isVideo = activeQuestion ? checkIsVideo(activeQuestion.fileURL) : false;

  return (
    <Container>
      <ContainerBody>
        <Writtenexercises>
          <WrittenexercisesHeader>
            <Link to="/dashboard/method">{t('voltar')}</Link>
            <Span>{homework?.quiz?.title}</Span>
          </WrittenexercisesHeader>
          <WrittenexercisesBody>
            {activeQuestion?.fileURL && (
              <WrittenexercisesImage>
                {activeQuestion.fileURL && !isVideo && (
                  <ImageContainer>
                    <ImageCustom src={activeQuestion.fileURL} />
                  </ImageContainer>
                )}
                {isVideo && (
                  <ImageContainer>
                    <Video src={activeQuestion.fileURL} controls />
                  </ImageContainer>
                )}
              </WrittenexercisesImage>
            )}
            <WrittenexercisesQuestions>
              {activeQuestion ? (
                <WrittenexercisesQuestions>
                  <Span>{activeQuestion.text}</Span>
                  <textarea
                    placeholder="Answer here"
                    value={
                      answers.find(a => a.questionId === activeQuestion?._id)
                        ?.answer || ''
                    }
                    onChange={event => {
                      handleAnswerChange({
                        questionId: activeQuestion?._id,
                        question: activeQuestion?.text,
                        answer: event.target.value,
                      });
                    }}
                  />
                </WrittenexercisesQuestions>
              ) : (
                <>
                  <WrittenexercisesQuestions></WrittenexercisesQuestions>
                </>
              )}

              <WrittenexercisesQuestionsButtons>
                <Button
                  color="#DBAD00"
                  fontColor="#083059"
                  title={t('finishLater')}
                  onClick={() => history.push('/dashboard/homework/written')}
                />
                {activePage !== 0 && (
                  <Button
                    color="#083059"
                    fontColor="#fff"
                    title={t('previous')}
                    onClick={handlePreviousQuestion}
                  />
                )}
                {maxQuestionsNumbers(homework) - 1 === activePage ? (
                  <Button
                    color="#083059"
                    fontColor="#fff"
                    title={t('submit')}
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button
                    color="#083059"
                    fontColor="#fff"
                    title={t('next')}
                    onClick={handleNextQuestion}
                  />
                )}
              </WrittenexercisesQuestionsButtons>
              {/* <WrittenexercisesQuestionsUniqueButton>
                <Button
                  color="#DBAD00"
                  fontColor="#083059"
                  title={t('finishLater')}
                  onClick={() => history.push('/dashboard/homework/written')}
                />
              </WrittenexercisesQuestionsUniqueButton> */}
            </WrittenexercisesQuestions>
          </WrittenexercisesBody>
        </Writtenexercises>
      </ContainerBody>
    </Container>
  );
}
