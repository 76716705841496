import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
`;

export const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 1.2rem;
  border: 1px solid var(--gray3);

  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;

  @media (max-width: 425px) {
    padding: 7rem 1rem;
  }
`;

export const Writtenexercises = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #fff;
`;

export const WrittenexercisesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  padding: 1rem;
  background: #fff;
  border-bottom: 1px solid var(--gray3);
  position: relative;

  span {
    font-size: 2rem;
    padding: 0.5rem;
    color: #8a8895;
  }

  a {
    font-size: 2rem;
    color: #8a8895;
    left: 2rem;
    position: absolute;
    text-decoration: none;
  }
`;

export const WrittenexercisesBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background: #fff;
  margin-top: 3rem;
`;

export const WrittenexercisesImage = styled.div`
  font-size: 1.8rem;
  padding: 0.5rem;
  color: #8a8895;
`;

export const WrittenexercisesQuestions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-top: 1rem;
  width: 100%;

  span {
    font-size: 2rem;
    padding: 0.5rem;
    color: #8a8895;
    margin-bottom: 2rem;
  }
`;

export const ImageLogo = styled.img`
  display: block;
  width: 73rem;
  object-fit: cover;
  height: 61rem;
  margin: 0 3rem;
`;

export const WrittenexercisesQuestionsChoice = styled.div`
  margin-top: 2rem;

  button {
    margin-top: 2rem;
    border: 1px solid var(--gray3);

    :hover {
      background-color: #dbad00;
      color: #083059;
    }
  }
`;

export const WrittenexercisesQuestionsGroupButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14vh;
  bottom: 0;
`;

export const WrittenexercisesQuestionsButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;

  button {
    border-radius: 0.5rem;
  }

  button + button {
    margin-left: 1rem;
  }
`;

export const NextButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const WrittenexercisesQuestionsUniqueButton = styled.div`
  margin-top: 2rem;
`;

export const ContainerQuestion = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const QuestionBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const VideoBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Video = styled.video`
  max-width: 100%;
  max-height: auto;
  width: 100%;
  height: auto;
`;
