export const getLevelFromExam = (exam) => {
    if (exam.title.toLowerCase().includes('midterm')) {
        return 'midterm'
    }

    if (exam.title.toLowerCase().includes('final')) {
        return 'final'
    }
    
    return false
}

export const getTypeFromExam = (exam) => {
    if (exam.title.toLowerCase().includes('open')) {
        return 'written'
    }

    if (exam.title.toLowerCase().includes('closed')) {
        return 'multiple_choice'
    }
    
    if (exam.title.toLowerCase().includes('oral')) {
        return 'oral'
    }

    return false
}