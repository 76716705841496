import { useField } from '@unform/core';
import React, { Fragment, useEffect, useRef } from 'react';
import { Container, Error } from './styles';

export default function Input({ name, type, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: type === 'file' ? 'files[0]' : 'value',
      // clearValue(ref) {
      //   ref.value = '';
      // },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, registerField]);

  return (
    <Fragment>
      <Container
        type={type}
        withError={!!error}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
        className="uninput"
      />
      {!!error && <Error>{error}</Error>}
    </Fragment>
  );
}
