/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Text,
  Wrapper,
  ContainerTitle,
  ContainerTypes,
  TitleType,
  DataType,
  ContainerSpace,
  ListFinancial,
  Button,
} from './styles';

interface StateProps {
  setShowPerformance: Dispatch<SetStateAction<boolean>>;
  performances: {
    data?: any[];
  };
}

export const ListPerformance = ({
  setShowPerformance,
  performances,
}: StateProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <ContainerTitle>
          <Text>{t('Histórico de Desempenho')}</Text>
          <Button onClick={() => setShowPerformance(false)} close>
            <span className="iconify" data-icon="eva:close-outline" />
          </Button>
        </ContainerTitle>

        <ContainerTypes>
          <ContainerSpace>
            <TitleType>{t('Método')}</TitleType>
          </ContainerSpace>
          <ContainerSpace>
            <TitleType>{t('Missão')}</TitleType>
          </ContainerSpace>
          <ContainerSpace>
            <TitleType>{t('Performance')}</TitleType>
          </ContainerSpace>
        </ContainerTypes>

        {performances?.data?.map((item) => (
          <ListFinancial>
            <ContainerSpace>
              <DataType>{item?.methods?.title}</DataType>
            </ContainerSpace>
            <ContainerSpace>
              <DataType>{item?.missions?.title}</DataType>
            </ContainerSpace>
            <ContainerSpace>
              <DataType>{item?.performance}</DataType>
            </ContainerSpace>
          </ListFinancial>
        ))}
      </Wrapper>
    </Container>
  );
};
