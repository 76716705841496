/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ListActiveMethodStudentsItem } from './ListActiveMethodStudentsItem';
import {
  ButtonNoBorder,
  Container,
  ContainerTitle,
  Text,
} from './styles.ListActiveMethodStudents';

/* eslint-disable react/destructuring-assignment */

interface IStudent {
  _id: string;
  avatarURL: string;
  fullName: string;
  name: string;
  studentLevel: string;
}

interface IActiveMethodStudentsProps {
  students: IStudent[];
  setActiveStudents: (showCorrectionItem: boolean) => void;
}

export const ListActiveMethodStudents = ({
  students,
  setActiveStudents,
}: IActiveMethodStudentsProps) => {
  const [isCollapsed, setIsCollapsed] = useState<any>({});
  const { t } = useTranslation();

  return (
    <Container>
      <ContainerTitle>
        <Text>{t('activeStudents')}</Text>
        <ButtonNoBorder
          onClick={() => {
            setActiveStudents(false);
          }}
        >
          <span className="iconify" data-icon="eva:close-outline" />
        </ButtonNoBorder>
      </ContainerTitle>

      {students?.map((item) => (
        <ListActiveMethodStudentsItem
          student={item}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      ))}
    </Container>
  );
};
