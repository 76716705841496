import { parseISO, format } from 'date-fns';

export function secondsToHms(d: number) {
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  return `${h}:${m}:${s}s`;
}
export function smartSecondsToHms(d: number, date: string) {
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  if (s < 0 || m < 0 || h < 0) {
    return 'Now';
  }
  if (h >= 24) {
    return format(parseISO(date), 'dd/MM/yyyy');
  }
  return `${h}:${m}:${s}s`;
}
