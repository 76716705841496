import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackgroundWhite from '../../assets/background_login_white.png';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColumnRight = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${BackgroundWhite});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8rem 0 4rem 0;

  @media (max-width: 1280px) {
    padding: 4rem 0 2rem 0;
  }
`;

export const ButtonSignUp = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 4rem;
  width: 100%;
  border: 0;
  border-radius: 5px;
  color: #fff;
  background: ${(props) => props.color};
  font-size: 1.25rem;
  font-weight: bold;
  transition: 0.2s;
  cursor: pointer;

  :hover {
    filter: brightness(0.8);
  }
`;

export const WrapperForm = styled.form`
  width: 36.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1280px) {
    width: 28.1rem;
  }
`;

export const ChoiceLabel = styled.label`
  width: 36.1rem;
  height: 8.4rem;
  border-radius: 6px;
  margin-bottom: 2.5rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: relative;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 36.1rem;
    height: 8.4rem;

    border-radius: 6px;

    border: 1px solid #ccc;
    background-color: white;
  }
  .switch {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    content: '${(props) => props.value}';
    font-size: 2rem;
    height: 8.4rem;
    width: 27rem;
    color: white;
    background: var(--secondary-color-blue);
    transition: 0.4s;
    border-radius: 6px;
  }

  .arrow {
    background: var(--secondary-color-blue);
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    margin-left: -6px;
  }
  .arrow2 {
    background: var(--secondary-color-blue);
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    margin-right: -6px;
  }

  .switch img {
    margin: 0 2.2rem;
  }

  input:checked + .slider {
    background-color: white;
    border: 1px solid #ccc;
  }

  input:checked + .slider > .switch {
    transform: translateX(90px);
  }
  input:checked + .arrow {
    transform: translateX(40px);
  }

  @media (max-width: 1280px) {
    width: 28.1rem;

    .slider {
      width: 28.1rem;
      height: 7rem;
    }

    .switch {
      height: 7rem;
      width: 19rem;
    }

    .switch img {
      margin: 0 1rem;
      width: 4.5rem;
    }
  }
`;

export const ImageLogo = styled.img`
  height: 154px;
  width: 154px;
  margin: 0 0 1.5rem 0;

  @media (max-width: 1280px) {
    width: 12.4rem;
    height: 9.4rem;
  }
`;

export const ImageStudent = styled.img`
  min-width: 3.5rem;
  max-width: 4.5rem;
  height: auto;
  margin: 0 2rem;

  @media (max-width: 1280px) {
    min-width: 3rem;
    max-width: 4rem;
    margin: 0 2rem;
  }
`;

export const LabelForgot = styled(Link)`
  color: var(--secondary-color-blue);
  margin: 0.8rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  align-self: center;
  text-decoration: none;
`;

export const TextPlatform = styled.div`
  color: var(--secondary-color-blue);
  margin: 3rem 0;
  text-align: end;

  span {
    font-size: 1.6rem;
    font-weight: 500;
  }

  @media (max-width: 1280px) {
    width: 28.1rem;
    margin: 1.5rem 0;
  }
`;

export const FooterCopy = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 38.1rem;
  color: var(--secondary-color-blue);

  @media (max-width: 1280px) {
    width: 28.1rem;
  }
`;

export const FooterIconTalks = styled.img`
  margin-right: 2rem;

  @media (max-width: 1280px) {
    margin-right: 1rem;
  }
`;

export const TextCopy = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const ContainerFlags = styled.div`
  padding: 1rem 0;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;
