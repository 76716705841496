import styled from 'styled-components';

export const Container = styled.button`
  height: 4rem;
  width: 100%;
  border: 0;
  border-radius: 5px;
  color: ${(props) => props.fontColor};
  background: ${(props) => props.color};
  font-size: 1.25rem;
  font-weight: bold;
  transition: 0.2s;
  cursor: pointer;
  display:  ${(props) => props.valueCode != '' ? 'block' : 'none'};

  &:hover {
    filter: brightness(0.8)
  }
`;
