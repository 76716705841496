/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/button-has-type */
import * as React from 'react';

import { api } from '../../services/api';
import { urltoFile } from '../../utils/format';

interface IProps {
  localStream: string;
  sessionName: string;
  stopVideo: any;
}

function Local({ localStream, sessionName, stopVideo }: IProps) {
  // const [mute, setMute] = React.useState<boolean>(false);
  // delete channel
  // mute main audio
  const handleSavePhoto = async (file: any) => {
    try {
      const { data } = await api.post(`webconference/${sessionName}`, {
        type: 'teacher',
        file,
      });
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    let mounted = true;
    function mountStream() {
      const localView: any = document?.getElementById('localView');
      console.log(localStream);
      if (localStream && stopVideo === false) {
        localView.srcObject = localStream;
        localView.muted = true;
      } else if (localStream && stopVideo === true) {
        if (localView.srcObject) {
          localView.srcObject.getTracks().map((track: any) => track.stop());
        }
      }
    }
    if (mounted) mountStream();
    return () => {
      mounted = false;
    };
  }, [localStream, stopVideo]);

  return localStream ? (
    <div>
      <div id="localContent">
        <video
          width="100%"
          id="localView"
          autoPlay
          playsInline
          style={{ transform: 'rotateY(180deg)' }}
        />
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Local;
