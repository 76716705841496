/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import { addMinutes, endOfMonth } from 'date-fns';
import { differenceInMinutes, differenceInSeconds } from 'date-fns/esm';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

import IconBreakthrough from '../../../assets/icon_breakthrough.png';
import { Chat } from '../../../components/Base/Chat';
import { CollapseSchedule } from '../../../components/Button/CollapseSchedule';
import HeaderSchedule from '../../../components/Dashboard/HeaderSchedule';
import { LeftColumnProfile } from '../../../components/Dashboard/LeftColumnProfile';
import { ListProffy } from '../../../components/Dashboard/ListProffy';
import { ListQuest } from '../../../components/Dashboard/ListQuest';
import LocationProffy from '../../../components/Dashboard/LocationProffy';
import { SelectLesson } from '../../../components/Dashboard/SelectLesson';
import Footer from '../../../components/Footer';
import { Header } from '../../../components/Header';
import { CancelAppointmentModal } from '../../../components/Modals/CancelAppointmentModal';
import { useAuth } from '../../../hooks/AuthProvider';
import { api, api2 } from '../../../services/api';
import { getWeekDaysSkip } from '../../../utils/getWeekDays';
import { smartSecondsToHms } from '../../../utils/secondsToHms';
import { Appointment } from '../Schedule';
import {
  Button,
  CardStage,
  Container,
  ContainerCalendar,
  ContainerHour,
  ContainerListProffy,
  ContainerMessage,
  Content,
  HeaderStage,
  IconStage,
  ImageProfile,
  InfoStage,
  LinkStart,
  ButtonStart,
  Select,
  Text,
  Wrapper,
  LoadingContainer,
} from './styles';

interface DashboardStudentProps {
  showMenu?: boolean;
}

type Query = {
  startTime: string;
  endTime: string;
  weekday: number;
  onlyTeachersIKnow: boolean;
  experimentalClass: boolean;
};

type Schedule = {
  tempStartTime: string;
  tempEndTime: string;
  startTime: string;
  endTime: string;
  originalStartTime: string;
  timeZone: string;
  teacherId: string;
  location: string;
  isExperimentalClass: boolean | undefined;

  weekday: number;
  onlyTeachersIKnow: boolean;
  experimentalClass: boolean;
  teacher: {
    _id: string;
    fullName: string;
    name: string;
    avatarURL: string;
  };
};
export function DashboardStudent({ showMenu }: DashboardStudentProps) {
  const { user, fetchUserData } = useAuth();

  const { t } = useTranslation();

  const [appointments, setAppointments] = useState<Appointment[]>();
  const [nextAppointment, setNextAppointment] = useState<Appointment>();
  const [showChat, setShowChat] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isCancelAppointmentModalOpen, setIsCancelAppointmentModalOpen] = useState(false);
  const [queries, setQueries] = useState<Query[]>([]);
  const [availableTeachers, setAvailableTeachers] = useState<any[]>([]);
  const [mission, setMission] = useState<Appointment>();
  const [selectedAppointments, setSelectedAppointments] = useState<Schedule[]>(
    [],
  );
  const [enableStart, setEnableStart] = useState<boolean>(false);
  const [teacherEntered, setTeacherEntered] = useState<boolean>(false);
  const [loadingAppointments, setLoadingAppointments] = useState<boolean>(false);

  const fetchAvailableTeachers = useCallback(async () => {
    try {
      const weekdays = queries.map((query) => query.weekday).join(',');
      const startTimes = queries.map((query) => query.startTime).join(',');
      const endTimes = queries.map((query) => query.endTime).join(',');

      await api
        .get('/teachers/available', {
          params: {
            weekdays,
            startTimes,
            endTimes,
          },
        })
        .then((response) => {
          if (!response.data.length) {
            toast(t('NotAvailableTeacher'), {
              type: 'warning',
            });
          }
          setAvailableTeachers(response.data);
        });
    } catch (err) {
      toast('Error', { type: 'error' });
    }
  }, [queries]);

  const handleAddQuery = useCallback((query: Query) => {
    setQueries((prevState) => {
      const queryAlreadyExists = prevState.find(
        (q) => q.weekday === query.weekday && q.startTime === query.startTime,
      );

      if (queryAlreadyExists) {
        return prevState;
      }

      return [...prevState, query];
    });
  }, []);

  const handleRemoveQuery = useCallback((index: number) => {
    setQueries((prevState) => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  }, []);
  function getDateTime(d: string | number | Date, time: string) {
    const date = new Date(d);

    const [hour, minutes] = time.split(':');

    date.setHours(Number(hour), Number(minutes), 0);

    return date;
  }
  const handleSelectAppointment = (schedules: Schedule[]) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const sameHours = [] as any[];
    for (let i = 0; i < selectedAppointments.length; i += 1) {
      const dayCustom = `${selectedAppointments[i].weekday}/${selectedAppointments[i].startTime}`;
      sameHours.push(dayCustom);
    }
    for (let i = 0; i < schedules.length; i += 1) {
      const dayCustom = `${schedules[i].weekday}/${schedules[i].startTime}`;
      if (!sameHours.includes(dayCustom)) {
        sameHours.push(dayCustom);
      } else {
        toast.error(t('youHaveAlreadyBookedLessonThisTime'));
        return;
      }
    }

    const credits = user?.credits || 12;

    let completeArray = [] as Schedule[];
    for (let i = 0; i < credits; i += 1) {
      const createAppointmentData = schedules.map((appointment) => {
        const query = queries.find(
          (q) => q.weekday === appointment.weekday
            && q.startTime === appointment.startTime
            && q.endTime === appointment.endTime,
        );

        const { date } = getWeekDaysSkip(i * 7).find(
          ({ weekday }) => weekday === appointment.weekday,
        ) as { date: Date };

        const startTime = getDateTime(
          date,
          appointment.startTime,
        ).toISOString();
        const endTime = query?.experimentalClass
          ? addMinutes(new Date(startTime), 25).toISOString()
          : getDateTime(date, appointment.endTime).toISOString();

        return {
          tempStartTime: appointment.startTime,
          tempEndTime: appointment.endTime,
          startTime,
          endTime,
          originalStartTime: startTime,
          timeZone,
          teacherId: appointment.teacher._id,
          location: 'TableTalks',
          isExperimentalClass: query?.experimentalClass,
          weekday: appointment.weekday,
          onlyTeachersIKnow: appointment.onlyTeachersIKnow,
          experimentalClass: appointment.experimentalClass,
          teacher: appointment.teacher,
        };
      });
      completeArray = [...completeArray, ...createAppointmentData];
    }

    setSelectedAppointments((prevState) => [...prevState, ...completeArray]);
  };

  function handleOpenCancelAppointmentModal() {
    setIsCancelAppointmentModalOpen(true);
  }

  function handleCloseCancelAppointmentModal() {
    setIsCancelAppointmentModalOpen(false);
  }
  const verifyEnableStart = (myAppointment: any, differenceTime: number) => {
    if (myAppointment.status === 'in_progress') {
      setTeacherEntered(true);
      setEnableStart(true);
      return;
    }

    if (differenceTime <= 30 * 60 && differenceTime >= -599) {
      setTeacherEntered(false);
      setEnableStart(true);
    }
    if (differenceTime <= -600) {
      setTeacherEntered(false);
      setEnableStart(false);
    }
  };
  const fetchAppointments = useCallback(async () => {
    try {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

      const minutesLate = 10 * 60000;
      const dateNow = new Date();
      const endDate = endOfMonth(
        new Date(dateNow.setMonth(dateNow.getMonth() + 12)),
      );
      const response = await api2.get<Appointment[]>('/appointments/me', {
        params: {
          timeZone,
          status: 'accepted',
          startDate: new Date(new Date().getTime() - minutesLate),
          endDate,
        },
      });
      // Ordenar a Aula
      response.data.sort((a, b) => {
        if (a.startTime > b.startTime) {
          return 1;
        }
        if (a.startTime < b.startTime) {
          return -1;
        }
        return 0;
      });

      setAppointments(response.data);

      setMission(response.data[0]);

      if (response.data && response.data.length > 0) {
        const teacher = response.data[0].attendees.find(
          (a) => a.type === 'teacher',
        ) as Appointment['attendees'][0];

        /* if (
          response.data[0].startsIn >= -599 ||
          response.data[0].status === 'in_progress'
        ) {
          setNextAppointment({
            ...response.data[0],
            teacher,
          });
        } */
        setNextAppointment({
          ...response.data[0],
          teacher,
        });
        verifyEnableStart(response.data[0], response.data[0].startsIn);
      } else {
        // eslint-disable-next-line
        // @ts-ignore
        setNextAppointment(false);
      }
    } catch (err) {
      toast.error('error');
    }
  }, []);

  useEffect(() => {
    fetchAppointments();
    const interval = setInterval(() => {
      fetchAppointments();
    }, 10000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!nextAppointment) {
        return;
      }

      const { startTime } = nextAppointment || {};

      const currentDate = new Date();

      const differenceTime = differenceInSeconds(
        new Date(startTime),
        currentDate,
      );

      verifyEnableStart(nextAppointment, differenceTime);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [nextAppointment]);
  const handleRemoveSelected = (index: number) => {
    const newArray = JSON.parse(JSON.stringify(selectedAppointments));
    newArray.splice(index, 1);
    setSelectedAppointments(newArray);
  };
  const handleCreateAppointments = useCallback(async () => {
    try {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      let countTotalClasses = 0;
      let errorScheduleTime = false;
      const currentDate = new Date();
      for (let i = 0; i < selectedAppointments.length; i += 1) {
        const differenceTime = differenceInMinutes(
          new Date(selectedAppointments[i].startTime),
          currentDate,
        );
        if (differenceTime < 12 * 60) {
          errorScheduleTime = true;
        }
        if (!selectedAppointments[i].isExperimentalClass) {
          countTotalClasses += 1;
        }
      }
      if (countTotalClasses > user.credits) {
        toast.error(t('cantScheduleCredits'));
        return;
      }
      if (errorScheduleTime) {
        toast.error(t('cantScheduleTime'));
        return;
      }

      await api.post('/appointments', selectedAppointments);

      toast.success('The appointment has been created!');

      setQueries([]);
      setAvailableTeachers([]);
      setSelectedAppointments([]);

      fetchAppointments();
      await fetchUserData();
    } catch (err) {
      toast.error(t('errorClasseCreate'));
    }
  }, [fetchAppointments, selectedAppointments, queries]);

  const handleCancelAppointment = useCallback(
    async (id, reason) => {
      try {
        await api.patch(`/appointments/cancel/${id}`, {
          reason,
        });

        toast(t('yourLessonHasBeenCanceled2'), { type: 'success' });
        await fetchUserData();
      } catch (err) {
        toast('Error', { type: 'error' });
      } finally {
        fetchAppointments();
      }
    },
    [fetchAppointments],
  );

  return (
    <>
      <Container showMenu={showMenu}>
        <Header />
        <Wrapper>
          {nextAppointment && (
            <HeaderStage>
              <InfoStage container>
                <div className="column">
                  <CardStage>
                    <IconStage src={IconBreakthrough} />
                    <InfoStage>
                      <Text titleStage>{nextAppointment?.method?.title}</Text>
                      <Text description>{nextAppointment?.mission?.title}</Text>
                    </InfoStage>
                    <span className="arrow" />
                  </CardStage>
                  <ContainerHour>
                    <InfoStage iconHour>
                      <span
                        className="iconify"
                        data-icon="ic:outline-watch-later"
                        data-inline="false"
                      />
                    </InfoStage>
                    <InfoStage hour>
                      <Text time>
                        {t('classStartsOn', {
                          startTime: smartSecondsToHms(
                            nextAppointment.startsIn,
                            nextAppointment.startTime,
                          ),
                        })}
                      </Text>
                      {/* <Text hour>{t('enableReminder')}</Text> */}
                    </InfoStage>
                  </ContainerHour>
                </div>
                <div className="column">
                  <ImageProfile src={nextAppointment.teacher.avatarURL} />
                  <InfoStage name>
                    <Text name>{t('teacher')}</Text>
                    <Text name>
                      {nextAppointment.teacher.fullName
                        ?? nextAppointment.teacher.name}
                    </Text>
                  </InfoStage>
                  {enableStart && !teacherEntered && (
                    <ButtonStart disabled>
                      {t('waitTeacher')}
                      <span
                        className="iconify"
                        data-icon="dashicons:arrow-right-alt2"
                        data-inline="false"
                      />
                    </ButtonStart>
                  )}
                  {enableStart && teacherEntered && (
                    <LinkStart
                      onClick={() => {
                        localStorage.setItem('state', JSON.stringify(mission));
                      }}
                      to={{
                        pathname: '/dashboard/student/classroom',
                        state: mission,
                      }}
                    >
                      {t('start')}
                      <span
                        className="iconify"
                        data-icon="dashicons:arrow-right-alt2"
                        data-inline="false"
                      />
                    </LinkStart>
                  )}

                  {/* <LinkStart to="/dashboard/student/classroom">
                    {t('start')}
                    <span
                      className="iconify"
                      data-icon="dashicons:arrow-right-alt2"
                      data-inline="false"
                    />
                  </LinkStart> */}
                </div>
                <div className="column">
                  <div className="container-select">
                    {nextAppointment.isReschedulable && (
                      <Select
                        onClick={() => {
                          if (showCalendar === false) {
                            setShowCalendar(true);
                            setShowChat(false);
                          } else {
                            setShowCalendar(false);
                          }
                        }}
                      >
                        <span
                          className="iconify"
                          data-icon="akar-icons:calendar"
                          data-inline="false"
                        />
                        {t('reagendar')}
                        <span
                          className="iconify"
                          data-icon="dashicons:arrow-down-alt2"
                          data-inline="false"
                        />
                      </Select>
                    )}
                    <Select
                      message
                      onClick={() => {
                        if (showChat === false) {
                          setShowChat(true);
                          setShowCalendar(false);
                        } else {
                          setShowChat(false);
                        }
                      }}
                    >
                      <span
                        className="iconify"
                        data-icon="bi:chat-left-text"
                        data-inline="false"
                      />
                      {t('enviarMensagem')}
                      <span
                        className="iconify"
                        data-icon="dashicons:arrow-down-alt2"
                        data-inline="false"
                      />
                    </Select>
                  </div>
                  <Button cancel onClick={handleOpenCancelAppointmentModal}>
                    <span
                      className="iconify"
                      data-icon="ci:close-small"
                      data-inline="false"
                    />
                    {t('cancelar')}
                  </Button>
                </div>
              </InfoStage>
            </HeaderStage>
          )}
          {loadingAppointments && (
            <LoadingContainer>
              <Loader
                visible
                type="ThreeDots"
                color="#124f8c"
                secondaryColor="#191919"
              />
            </LoadingContainer>
          )}
          {!loadingAppointments && appointments && appointments.length > 0 && (
            <HeaderSchedule appointments={appointments} />
          )}

          <Content>
            <LeftColumnProfile />
            <Wrapper multinivel>
              {showChat && nextAppointment && nextAppointment.teacher && (
                <ContainerMessage>
                  <div className="header-message">
                    <span>{t('messages')}</span>
                    <div className="buttons-right">
                      <span className="iconify" data-icon="eva:expand-fill" />
                      <button type="button" onClick={() => setShowChat(false)}>
                        <span className="iconify" data-icon="eva:close-fill" />
                      </button>
                    </div>
                  </div>
                  <Chat
                    sentId={nextAppointment?.teacher?.id}
                    name={nextAppointment?.teacher?.name}
                    imageURL={nextAppointment?.teacher?.avatarURL}
                  />
                </ContainerMessage>
              )}

              {nextAppointment && showCalendar && (
                <ContainerCalendar>
                  <CollapseSchedule
                    appointment={nextAppointment}
                    fetchAppointments={fetchAppointments}
                    setShowSchedule={setShowCalendar}
                  />
                </ContainerCalendar>
              )}

              {/* ANTIGO LUGAR DO HEADER SCHEDULE */}

              {/* {appointments && appointments.length > 0 && (
                <HeaderSchedule appointments={appointments} />
              )} */}
              <LocationProffy
                queries={queries}
                fetchAvailableTeachers={fetchAvailableTeachers}
                onAddQuery={handleAddQuery}
                onRemoveQuery={handleRemoveQuery}
              />

              {availableTeachers && availableTeachers.length > 0 && (
                <>
                  <ContainerListProffy>
                    {availableTeachers.map((teacher) => (
                      <ListProffy
                        teacher={teacher}
                        isSelected={Boolean(
                          selectedAppointments.find(
                            (appointment) => appointment.teacher._id === teacher._id,
                          ),
                        )}
                        onSelectAppointment={handleSelectAppointment}
                      />
                    ))}
                  </ContainerListProffy>
                  {selectedAppointments.length > 0 && (
                    <SelectLesson
                      selectedAppointments={selectedAppointments}
                      onCreateAppointments={handleCreateAppointments}
                      handleRemove={handleRemoveSelected}
                    />
                  )}
                </>
              )}
              <ListQuest
                title={t('openEndedQuestions')}
                type="open"
                url="homework/writing"
              />
              <ListQuest
                title={t('closedEndedQuestions')}
                type="multiple_choice"
                url="homework/multiple"
              />
              {/* <ListQuest
              title="Exercícios de Quiz"
              url="/dashboard/profile/written"
            /> */}
            </Wrapper>
          </Content>
        </Wrapper>
        <Footer />
      </Container>
      {isCancelAppointmentModalOpen && nextAppointment && (
        <CancelAppointmentModal
          open={isCancelAppointmentModalOpen}
          appointment={nextAppointment}
          onSubmit={handleCancelAppointment}
          onRequestClose={handleCloseCancelAppointmentModal}
        />
      )}
    </>
  );
}
