import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin: 10px 10px 0;

  h2 {
    color: #191919;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .modal-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  Button {
    width: min-content;
    padding: 0 1.5rem;
    height: 4rem;
    color: var(--main-color);
    border-radius: 0.25rem;
    border: 0;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    font-weight: 600;
    transition: filter 0.2s ease;
    &:hover {
      filter: brightness(0.9);
    }
  }

  Button:not(:last-child) {
    margin-right: 1.5rem;
  }

  Button[type='submit'] {
    width: 20%;
    padding: 0 1.5rem;
    height: 4rem;
    background: var(--main-color);
    color: #ffffff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    font-weight: 600;
    transition: filter 0.2s ease;
    &:hover {
      filter: brightness(0.9);
    }
  }

  .sigCanvas {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0.25rem;
  }
`;
