import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  width: 100%;
  height: 20rem;
  margin-top: 1.2rem;
  border-radius: 0.5rem;
  background: #083059;
  background: linear-gradient(
    90deg,
    #083059 0%,
    #15477a 50%,
    #3282d2 100%
  );

  ${props =>
    props.showCalendar === true &&
    css`
      height: 40rem;
    `}

  @media(max-width: 500px) {
    display: grid;
    height: auto;
  }
`;

export const Text = styled.span`
  color: #707070;
  font-size: 1.5rem;

  ${props =>
    props.title &&
    css`
      font-size: 2.3rem;
      font-weight: 750;
      color: #fff;
    `}

  ${props =>
    props.level &&
    css`
      color: #083059;
    `}
`;

export const ContainerConfig = styled.div`
  height: 5rem;
  width: 90%;
  background: #fff;
  margin: 2rem 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  .column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .column2 {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 100%;
  }

  .iconify {
    font-size: 1.6rem;
    color: #707070;
  }

  @media (max-width: 1200px) {
    /* display: grid; */
    width: 98%;
    height: auto;
  }

  @media (max-width: 890px) {
    display: flex;
    width: auto;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 98%;
  }

  @media (max-width: 560px) {
    /* width: 95%; */
    display: grid;
    width: auto;
    height: auto;

    .column, .column2 {
      margin-top: 0.5rem;
    }
  }

  @media (max-width: 500px) {
    display: grid;
    height: 100%;
    justify-items: center;
    align-items: center;
    justify-content: space-around;
    align-content: center;

    .column, .column2 {
      justify-items: center;
      align-items: center;
      align-content: space-evenly;
      justify-content: space-evenly;
    }
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 2rem;
  border-right: 1px solid #ccc;

  ${props =>
    props.watch &&
    css`
      border: 0;
      padding-right: 0;
    `}

  .react-time-picker {
    display: inline-flex;
    position: relative;
  }
  .react-time-picker,
  .react-time-picker *,
  .react-time-picker *:before,
  .react-time-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-time-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 0;
  }
  .react-time-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-family: 'Neue Haas Grotesk Display Pro' !important;
  }
  .react-time-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-time-picker__inputGroup__input {
    min-width: 1em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    color: #707070;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-time-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-time-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-time-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-time-picker__inputGroup__amPm {
    font: inherit;
    -moz-appearance: menulist;
  }
  .react-time-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  .react-time-picker__button:enabled {
    cursor: pointer;
  }
  .react-time-picker__button:enabled:hover .react-time-picker__button__icon,
  .react-time-picker__button:enabled:focus .react-time-picker__button__icon {
    stroke: #0078d7;
  }
  .react-time-picker__button:disabled .react-time-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-time-picker__button svg {
    display: inherit;
  }
  .react-time-picker__clock {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    border: thin solid #a0a096;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .react-time-picker__clock--closed {
    display: none;
  }

  @media (max-width: 560px) {
    border-right: 0;
    padding-right: 1rem;
  }
`;

export const Button = styled.button`
  font-size: 1.5rem;
  height: 100%;
  color: #083059;
  cursor: pointer;

  ${props =>
    props.calendar &&
    css`
      color: #fff;
    `}

  ${props =>
    props.search &&
    css`
      height: 4rem;
      background: #dbad00;
      color: #fff;
      padding: 0 3rem;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: 0.2s;

      :hover {
        filter: brightness(0.8);
      }
    `}

    ${props =>
    props.plus &&
    css`
      display: none;
    `}

    ${props =>
    props.add &&
    css`
      margin-left: 1.5rem;
      font-weight: 750;
      font-size: 1.7rem;
    `}

  @media (max-width: 560px) {
    ${props =>
      props.add &&
      css`
        display: none;
      `}

    ${props =>
      props.plus &&
      css`
        display: flex;

        .iconify {
          color: #083059;
        }
      `}
  }
`;

export const ContainerLevel = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid #ccc;
  height: 100%;
  padding-left: 1rem;

  .radio {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 560px) {
    border-right: 0;
  }
`;

export const ButtonHour = styled.button``;
