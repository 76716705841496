import styled, { css } from 'styled-components';

interface LessonProps {
  division?: boolean;
}

interface TextModalProps {
  mission?: boolean;
  headerModal?: boolean;
}

interface TextModalProps2 {
  isCorrect?: boolean;
  isWrong?: boolean;
}

interface ModalButtonsProps {
  right?: boolean;
}

interface ButtonsCheckModalProps {
  close1?: boolean;
  close2?: boolean;
  check1?: boolean;
  check2?: boolean;
  viewMode?: boolean;
  warning?: boolean;
}

export const Container = styled.div`
  width: 100%;
  background: #fff;

  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  border-left: 1px solid var(--gray3);
  border-right: 1px solid var(--gray3);
  border-bottom: 1px solid var(--gray3);
  border-radius: 0.4rem;

  position: relative;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #707070;

  span {
    font-size: 1.6rem;
  }

  .iconify {
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const Text = styled.span``;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--gray3);

  padding: 1.5rem 0;

  @media (max-width: 1030px) {
    gap: 0.2rem;
  }
`;

export const ContainerIcon = styled.div`
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: #28c76f12;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;

export const Icon = styled.img``;

export const ContainerHour = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  span {
    font-size: 1.4rem;
    color: #707070;
  }
`;

export const ContainerUser = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

export const Image = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

export const ContainerTextsUser = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.6rem;
    color: #707070;
  }

  span + span {
    color: #b9b9c3;
    font-size: 1.4rem;
  }
`;

export const ContainerInfoLesson = styled.div<LessonProps>`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  ${(props) => props.division
    && css`
      border-left: 1px solid var(--gray3);
      border-right: 1px solid var(--gray3);
      padding: 0 1rem;
    `}
`;

export const ContainerTextsInfoLesson = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.4rem;
    color: #707070;
  }
`;

export const ContainerButtons = styled.div`
  position: absolute;
  right: 1.2rem;

  display: flex;
  align-items: center;

  @media (max-width: 1030px) {
    position: revert;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4rem;
  width: 100% auto;
  padding: 0 1rem;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin-left: 1rem;

  color: #243342;
  cursor: pointer;

  .iconify {
    font-size: 1.6rem;
    margin-left: 1rem;
  }
`;

export const ButtonNoBorder = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4rem;
  width: 100% auto;
  padding: 0 1rem;
  margin-left: 1rem;

  color: #243342;
  cursor: pointer;

  .iconify {
    font-size: 1.6rem;
    margin-left: 1rem;
  }
`;

// MODAL

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100rem;
  padding: 1rem;
  border-radius: 0.4rem;
  background: #fff;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--gray3);
    padding-bottom: 2rem;

    div + span {
      width: 20rem;
    }

    .iconify {
      font-size: 1.6rem;
      color: #5e5873;
      margin-left: 10rem;
    }
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
`;

export const TextsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
`;

export const ImageModal = styled.img`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const TextModal = styled.span<TextModalProps>`
  font-size: 1.6rem;
  color: #5e5873;

  ${(props) => props.mission
    && css`
      color: #b9b9c3;
      font-size: 1.4rem;
    `}
`;

export const TextModal2 = styled.span<TextModalProps2>`
  font-size: 1.6rem;
  color: #5e5873;
  margin-right: 90px;

  ${(props) => props.isCorrect
    && css`
      color: #fff;
    `}
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerTextList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  span {
    border: 1px solid var(--gray3);
    padding: 1rem;
  }

  span + span {
    border-top: 0;
  }
`;

export const ButtonModal = styled.button`
  width: 100%;
  margin: 2rem 0;
  padding: 1rem;
  background: #243342;
  color: #fff;
  font-size: 1.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
`;

export const ContainerModalContinue = styled.div`
  background: #fff;
  height: 50rem;
  overflow-y: auto;
  width: 100%;
  border-radius: 0.4rem;

  display: flex;
  flex-direction: column;
`;

export const ContainerHeaderModal = styled.div`
  display: flex;
  align-items: center;
  height: 7rem;
  min-height: 7rem;
  padding: 1rem;
  border-bottom: 1px solid var(--gray3);
  position: relative;
`;

export const ContainerUserModal = styled.div`
  display: flex;
  align-items: center;
  margin: 0 3rem;
`;

export const ImageProfile = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

export const ContainerImage = styled.div`
  border-bottom: 1px solid var(--gray3);
  padding: 4rem 2rem;
  position: relative;

  text-align: center;
`;

export const ContainerShowHide = styled.div`
  width: 100%;
  position: relative;
  padding-top: 20px;
  color: blue;

  text-align: center;
`;

export const ImageExercise = styled.img`
  max-width: 100%;
  max-height: 450px;
`;

export const ContainerTextsUserModal = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.6rem;
    color: #707070;
  }

  span + span {
    color: #b9b9c3;
    font-size: 1.4rem;
  }
`;

export const ContainerButtonsModal = styled.div`
  position: absolute;
  right: 0;

  display: flex;
  align-items: center;
  height: 100%;

  span {
    font-size: 1.6rem;
    color: #243342;
    font-weight: 600;
    padding: 0 2rem;
  }
`;

export const ButtonsModal = styled.button<ModalButtonsProps>`
  height: 6.9rem;
  width: 6rem;
  border-left: 1px solid var(--gray3);
  border-right: 1px solid var(--gray3);

  ${(props) => props.right
    && css`
      border-right: 0;
    `}
`;

export const WrapperModal = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
`;

export const ContainerModalWrapper = styled.div`
  height: 100% auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin-bottom: 2rem;
`;

export const Video = styled.video`
  max-width: 100%;
  max-height: auto;
  width: 100%;
  height: auto;
`;

export const ContainerText = styled.div`
  border-bottom: 1px solid var(--gray3);
  padding: 4rem 2rem;
  position: relative;

  display: flex;
  align-items: center;

  span {
    font-size: 1.4rem;
    font-weight: 600;
    color: #243342;
  }
`;

export const ContainerTextResposta = styled.div<TextModalProps2>`
  border-bottom: 1px solid var(--gray3);
  padding: 2rem 1rem;
  position: relative;

  display: flex;
  align-items: center;

  span {
    font-size: 1.3rem;
    font-weight: 400;
    /* color: #243342; */
  }

  ${(props) => props.isCorrect
    && css`
      background-color: #243342;
    `}
  ${(props) => props.isWrong
    && css`
      background-color: #dbad00;
    `}
`;

export const ButtonCheckLesson = styled.button<ButtonsCheckModalProps>`
  margin-right: 2rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background-color: transparent;

  .iconify {
    color: #fff;
    font-size: 2rem;
  }

  ${(props) => props.viewMode
    && css`
      cursor: default;
    `}

  ${(props) => props.warning
    && css`
      border: solid 1px orange;
      color: orange;
    `}

  ${(props) => props.close1
    && css`
      border: solid 1px #ff0000;
      .iconify {
        color: #ff0000;
        font-size: 2rem;
      }
      color: #ff0000;
    `}

  ${(props) => props.close2
    && css`
      background-color: #ff0000;
      border: solid 1px #ff0000;
    `}

  ${(props) => props.check1
    && css`
      border: solid 1px #083059;
      .iconify {
        color: #083059;
        font-size: 2rem;
      }
    `}

  ${(props) => props.check2
    && css`
      border: solid 1px #083059;
      background-color: #083059;
    `}
`;

export const ButtonModalContinue = styled.button`
  height: 4rem;
  width: 100%;
  background: #243342;
  color: #fff;
  border-radius: 0.4rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;

  .iconify {
    font-size: 1.6rem;
    margin-right: 0.5rem;
    margin-bottom: 0.3rem;
  }
`;

export const ShowHideButton = styled.a`
  font-size: 18px;
  cursor: pointer;
`;

export const ContainerButtonCancel = styled.div`
  width: 100%;
  text-align: center;
`;
export const ButtonModalCancel = styled.button`
  height: 4rem;
  width: auto;
  background: transparent;
  color: #243342;
  border-radius: 0.4rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;

  .iconify {
    font-size: 1.6rem;
    margin-right: 0.5rem;
    margin-bottom: 0.3rem;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 5px;
`;
