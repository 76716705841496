import React from 'react';
import { Switch } from 'react-router-dom';
import { ConfirmEmail } from '../pages/ConfirmEmail';
import { Dashboard } from '../pages/Dashboard';
import { InsertEmail } from '../pages/InsertEmail';
import { LandingPage } from '../pages/LandingPage';
import { NewCode } from '../pages/NewCode';
import { Plans } from '../pages/Plans';
import { RegisterTestDone } from '../pages/RegisterTestDone';
import { SignIn } from '../pages/SignIn';
import { Signup } from '../pages/Signup';
import { PlacementTest } from '../pages/PlacementTest';
import Route from './Route';
import { ForgotPassword } from '../pages/ForgotPassword';
import { RecoverPassword } from '../pages/RecoverPassword';
import { ConfirmAuth } from '../pages/ConfirmAuth';
import { NewUser } from '../pages/NewUser';
import { Policy } from '../pages/Policy';
import { Terms } from '../pages/Terms';

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={Signup} />
      <Route path="/newcode" component={NewCode} />
      <Route path="/plans" component={Plans} />
      <Route path="/insertemail" component={InsertEmail} />
      <Route path="/confirmemail" component={ConfirmEmail} />
      <Route path="/ConfirmAuth" component={ConfirmAuth} />
      <Route path="/policy" component={Policy} />
      <Route path="/terms" component={Terms} />
      <Route
        isPrivate
        path="/placement-test-done"
        component={RegisterTestDone}
      />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/recoverpassword/:token" component={RecoverPassword} />
      <Route path="/new-user/:token" component={NewUser} />
      <Route isPrivate path="/placement-test" component={PlacementTest} />
      <Route isPrivate path="/dashboard" component={Dashboard} />
    </Switch>
  );
}

export default Routes;
