import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import LogoImage from '../../assets/icon_logo_login.png';
import Footer from '../../components/Footer';
import { useQuery } from '../../hooks/useQuery';
import {
  Container,
  Logo,
  SpanTerms,
  Wrapper,
  Span,
  Topic,
  ContainerImage,
  ContainerTerms,
} from './styles';

export const Policy = () => (
  <Container>
    <Wrapper>
      <ContainerImage>
        <Logo src={LogoImage} />
      </ContainerImage>
      <ContainerTerms>
        <SpanTerms>Política de Privacidade</SpanTerms>
      </ContainerTerms>
      <br />
      <br />
      <Span>
        O Table Talks &eacute; uma Plataforma de ensino online que atua
        mediando a rela&ccedil;&atilde;o entre alunos e professores
        interessados na L&iacute;ngua Inglesa. Por meio da Plataforma, o aluno
        encontra o instrutor de idiomas. Al&eacute;m de desempenhar este papel
        mediador, a Table Talks fornece material pedag&oacute;gico de
        excel&ecirc;ncia e gratuito, possibilitando, tanto ao professor quanto
        ao aluno, know-how no referido idioma, especial e relativamente ao
        dom&iacute;nio gramatical, profici&ecirc;ncia e prola&ccedil;&atilde;o
        lingu&iacute;stica.
      </Span>
      <Span>
        Al&eacute;m de zelar pelo ensino e aprendizado da L&iacute;ngua
        Inglesa, a Table Talks se preocupa com a seguran&ccedil;a dos dados
        pessoais dos usu&aacute;rios da Plataforma, respeitando sempre o
        direito &agrave; privacidade. A fim propiciar seguran&ccedil;a e
        transpar&ecirc;ncia aos nossos usu&aacute;rios, possu&iacute;mos uma
        r&iacute;gida Pol&iacute;tica de Privacidade, que pode ser mudada
        periodicamente, com o intuito de resguardar os dados de forma mais
        confi&aacute;vel e segura.
      </Span>
      <Span>
        A nossa Pol&iacute;tica de Privacidade esclarece como coletamos,
        armazenamos e empregamos os dados pessoais dos usu&aacute;rios. Ao
        acessar o Aplicativo e utilizar nossos Servi&ccedil;os, o
        usu&aacute;rio concorda, expressa e voluntariamente, com todas as
        cl&aacute;usulas da Pol&iacute;tica de Privacidade, adiante
        pormenorizadas. Se o usu&aacute;rio n&atilde;o concordar com nossa
        Pol&iacute;tica, ser&aacute; invi&aacute;vel a
        utiliza&ccedil;&atilde;o de Aplicativo e respectivos Servi&ccedil;os.
      </Span>
      <Topic>1. Dados Coletados</Topic>
      <Span>
        1.1. Todas as informa&ccedil;&otilde;es confiadas no cadastro
        ser&atilde;o de uso da Table Talks, sendo incorporadas ao banco de
        dados da Companhia. Para navegar em certas &aacute;reas do site, bem
        como para usar o aplicativo, &eacute; necess&aacute;rio efetuar o
        cadastro. Nosso objetivo &eacute; conhecer o usu&aacute;rio. O
        endere&ccedil;o de e-mail fornecido no cadastro ser&aacute; o meio de
        contato priorit&aacute;rio com a Table Talks. Por isso, o
        usu&aacute;rio deve fornecer dados pessoais verdadeiros, precisos,
        atuais e completos, bem como manter atualizadas estas
        informa&ccedil;&otilde;es.
      </Span>
      <Span>
        1.2. O servidor da Table Talks n&atilde;o coleta as
        informa&ccedil;&otilde;es automaticamente, isto &eacute;, o simples
        acesso &agrave; nossa Plataforma ou site n&atilde;o faz com que os
        dados sobre dom&iacute;nio e e-mail sejam coletados. Somente coletamos
        informa&ccedil;&otilde;es das pessoas que, de alguma forma, fornecem
        dados diretamente a Plataforma, por exemplo, por meio de cadastramento
        de email para receber an&uacute;ncios sobre promo&ccedil;&otilde;es,
        cadastramento de e-mail quando do registro no Aplicativo, troca de
        mensagens e outros. As informa&ccedil;&otilde;es somente ser&atilde;o
        agregadas &agrave; Table Talks se forem fornecidas voluntariamente.
      </Span>
      <Span>
        1.3. Ao se cadastrar, o usu&aacute;rio aceita fornecer seus dados
        pessoais, os termos desta Pol&iacute;tica de Privacidade e os Termos e
        Condi&ccedil;&otilde;es Gerais de Uso do site e aplicativo. Neste
        momento, o usu&aacute;rio poder&aacute; fazer a op&ccedil;&atilde;o
        por receber ou n&atilde;o avisos sobre produtos e servi&ccedil;os da
        Table Talks e seus parceiros. Esta decis&atilde;o poder&aacute; ser
        tomada no ato do cadastramento e, eventualmente, a qualquer tempo no
        link de autoatendimento.
      </Span>
      <Topic>2. Uso dos dados coletados</Topic>
      <Span>
        2.1. As informa&ccedil;&otilde;es s&atilde;o coletadas com o intuito
        de melhorar o conte&uacute;do e Servi&ccedil;os fornecidos pela Table
        Talks, al&eacute;m de notificar o usu&aacute;rio sobre
        atualiza&ccedil;&otilde;es, promo&ccedil;&otilde;es e quaisquer outras
        campanhas empreendidas pela Companhia.
      </Span>
      <Span>
        2.2. Os dados pessoais do usu&aacute;rio n&atilde;o ser&atilde;o
        fornecidos ou comercializados a terceiros em nenhuma hip&oacute;tese,
        salvo no estrito cumprimento de determina&ccedil;&otilde;es judiciais
        ou quando autorizados. Todas as informa&ccedil;&otilde;es fornecidas a
        parceiros, patrocinadores, anunciantes e empresas de pesquisa
        ser&atilde;o apenas de grupos de usu&aacute;rio, para
        defini&ccedil;&atilde;o de segmentos de mercado.
      </Span>
      <Span>
        2.3. Os dados pessoais coletados poder&atilde;o ser utilizados para
        permitir a melhor customiza&ccedil;&atilde;o de sua experi&ecirc;ncia
        no site e no aplicativo da Table Talks, al&eacute;m de permitir o
        constante aperfei&ccedil;oamento da Plataforma. Sendo assim,
        informa&ccedil;&otilde;es geogr&aacute;ficas, demogr&aacute;ficas e de
        perfil de consumo, a t&iacute;tulo exemplificativo, poder&atilde;o
        ajudar na melhora do site e aplicativo.
      </Span>
      <Span>
        2.4. Ao fornecer o n&uacute;mero de telefone m&oacute;vel, o
        usu&aacute;rio concorda com a possibilidade de a Companhia empreender
        campanha que ofere&ccedil;a produtos e servi&ccedil;os via chamada voz
        telef&ocirc;nica, ou mesmo envie mensagens para aplicativo
        multiplataforma de mensagens instant&acirc;neas e chamadas de voz para
        smartphones ao qual o n&uacute;mero fornecido esteja cadastrado.
      </Span>
      <Topic>3. Cookies e links</Topic>
      <Span>
        3.1. Para facilitar o acesso, ser&aacute; gerado um cookie no
        computador do usu&aacute;rio. Este cookie &eacute; um arquivo pequeno
        para reconhecer, acompanhar e armazenar a navega&ccedil;&atilde;o do
        usu&aacute;rio na internet. Todas as informa&ccedil;&otilde;es
        coletadas pela Table Talks s&atilde;o usadas com a finalidade de
        manter atualizados os dados de usu&aacute;rio e acesso, informar sobre
        conte&uacute;do, analisar m&eacute;tricas e estat&iacute;sticas de
        site, responder d&uacute;vidas ou solicita&ccedil;&otilde;es do
        usu&aacute;rio, realizar campanhas de divulga&ccedil;&atilde;o ou de
        promo&ccedil;&atilde;o de produtos da Table Talks e de seus parceiros.
      </Span>
      <Span>
        3.2. O usu&aacute;rio poder&aacute; bloquear o uso de cookies no seu
        navegador. Esta decis&atilde;o pode, entretanto, acarretar o uso
        limitado do site ou aplicativo e em eventual preju&iacute;zo &agrave;
        sua funcionalidade.
      </Span>
      <Span>
        3.3. A Table Talks pode disponibilizar links para p&aacute;ginas de
        terceiros, mas n&atilde;o se responsabiliza pela pol&iacute;tica de
        privacidade desses sites.
      </Span>
      <Topic>4. Seguran&ccedil;a e Garantias</Topic>
      <Span>
        4.1. Apesar do uso de criptografia e das melhores pr&aacute;ticas em
        seguran&ccedil;a da internet, nenhuma transmiss&atilde;o de dados
        &eacute; 100% segura. Assim, em que peses os esfor&ccedil;os para
        preserva&ccedil;&atilde;o de suas informa&ccedil;&otilde;es pessoais,
        n&atilde;o &eacute; poss&iacute;vel garantir a seguran&ccedil;a de
        todas as informa&ccedil;&otilde;es que voc&ecirc; venha a nos
        fornecer. Assim, &eacute; uma decis&atilde;o pessoal do usu&aacute;rio
        a utiliza&ccedil;&atilde;o do servi&ccedil;o nestas
        condi&ccedil;&otilde;es.
      </Span>
      <Span>
        4.2. Quando recebemos e transferimos certos tipos de
        informa&ccedil;&atilde;o confidencial, como informa&ccedil;&otilde;es
        financeiras, dados de cart&atilde;o de cr&eacute;dito e afins,
        redirecionamos os usu&aacute;rios a servidores seguros, notificando-os
        por meio de uma janela pop-up em nosso Aplicativo ou site.
      </Span>
      <Span>
        4.3. O usu&aacute;rio ser&aacute; o &uacute;nico respons&aacute;vel
        pela utiliza&ccedil;&atilde;o de sua conta de usu&aacute;rio e senha,
        devendo zelar pela sua confidencialidade e adequada
        utiliza&ccedil;&atilde;o. Nunca forne&ccedil;a sua senha a
        ningu&eacute;m. O usu&aacute;rio poder&aacute; alterar sua senha a
        qualquer tempo, mediante acesso &agrave; p&aacute;gina e campo
        espec&iacute;ficos.
      </Span>
      <Span>
        4.4. Sugerimos ao usu&aacute;rio, como forma de
        prote&ccedil;&atilde;o, que desconecte o Aplicativo quando do
        t&eacute;rmino da atualiza&ccedil;&atilde;o, evitando que,
        eventualmente, terceiros tenham acesso ao seu conte&uacute;do. Nesse
        caso, a Table Talks n&atilde;o se responsabiliza por quest&otilde;es
        relacionadas ao descuido do usu&aacute;rio em rela&ccedil;&atilde;o as
        suas informa&ccedil;&otilde;es pessoais.
      </Span>
      <Topic>5. Disposi&ccedil;&otilde;es Gerais</Topic>
      <Span>
        5.1. Consentimento. O usu&aacute;rio declara que leu e compreendeu a
        Pol&iacute;tica de Privacidade, sujeitando-se &agrave;s regras de
        coleta, armazenamento e uso detalhadamente descritas aqui.
      </Span>
      <Span>
        5.2. Conforme Termos e Condi&ccedil;&otilde;es de Uso, a
        utiliza&ccedil;&atilde;o da Plataforma da Table Talks &eacute;
        restrita aos maiores de 18 (dezoito) anos, ressalvada a
        hip&oacute;tese do menor devidamente representado, com idade igual ou
        superior a 12 (doze) anos.
      </Span>
      <Span>
        5.3. Exclus&atilde;o da Conta. Quando solicitado pelo usu&aacute;rio,
        sua conta ser&aacute; exclu&iacute;da no prazo de 30 (trinta) dias,
        extinguindo-se as informa&ccedil;&otilde;es cadastradas, ressalvadas
        as hip&oacute;teses de guarda obrigat&oacute;ria de registros
        previstas em lei. A solicita&ccedil;&atilde;o poder&aacute; ser feita
        por meio da Central de Atendimento (conforme link a ser inserido). No
        caso de usu&aacute;rios que j&aacute; realizaram pagamentos
        atrav&eacute;s do aplicativo, algumas informa&ccedil;&otilde;es
        associadas aos mesmos ser&atilde;o mantidas em nossa base de dados
        para futuras referencias cont&aacute;beis.
      </Span>
      <Span>
        5.4. A Table Talks poder&aacute; modificar esta Pol&iacute;tica de
        Privacidade a qualquer tempo. As altera&ccedil;&otilde;es
        estar&atilde;o dispon&iacute;veis neste espa&ccedil;o.
      </Span>
      <Span>
        5.5. Contato. Caso o usu&aacute;rio tenha d&uacute;vidas sobre o
        cadastramento desta Pol&iacute;tica de Privacidade poder&aacute;
        enviar e-mail para suporte@tabletalks.com.br
      </Span>
      <Span>
        5.6. A &uacute;ltima atualiza&ccedil;&atilde;o da nossa
        pol&iacute;tica de privacidade ocorreu em 06 de abril de 2020. Ao
        utilizar a nossa Plataforma a partir desta data, voc&ecirc; concorda
        com os termos e pol&iacute;ticas de dados e cookies atualizados.
      </Span>
      <Footer />
    </Wrapper>
  </Container>
);
