import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 1.2rem;
  width: 100%;
  height: 100%;

  overflow-y: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  height: 75rem;
  width: 100%;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.6rem;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #083059;
    border-radius: 1rem;
  }
  ::selection {
    background-color: #083059;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 6rem;
  width: 100%;
  border-bottom: 1px solid var(--gray3);
`;

export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  .datepicker-toggle {
    position: relative;
    height: 3.5rem;
    width: 16rem;
    border-radius: 0.5rem;
    border: 1px solid var(--gray3);

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    color: #243342;
    font-size: 1.5rem;
  }

  .datepicker-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
  }
  .datepicker-input::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .input-search {
    border: 1px solid #ebe9f1;
    width: 27rem;
    height: 3.5rem;
    margin-left: 1rem;
    border-radius: 0.5rem;
    padding: 0 1rem;
  }
`;

export const Text = styled.span`
  ${(props) =>
    props.title &&
    css`
      color: #5e5873;
      font-size: 2rem;
      font-weight: 650;
      margin-left: 2rem;
    `}

  ${(props) =>
    props.date &&
    css`
      color: #083059;
      font-size: 1.5rem;
      margin: 0 1rem;
    `}

  ${(props) =>
    props.name &&
    css`
      color: #083059;
      font-size: 1.7rem;
      font-weight: 650;
      margin: 0 1rem;
    `}

  ${(props) =>
    props.subtitle &&
    css`
      color: #083059;
      font-size: 1.6rem;
      margin: 0 1rem;
    `}

  ${(props) =>
    props.textDate &&
    css`
      color: #b9b9c3;
      font-size: 0.9rem;
      margin: 0 1rem;
    `}

  ${(props) =>
    props.dateList &&
    css`
      color: #b9b9c3;
      font-size: 1.4rem;
      margin: 0 1rem;
    `}
`;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

export const ContainerList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 8rem;
  border-bottom: 1px solid var(--gray3);
  padding: 0 1rem;
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background: #28c76f12;

  .iconify {
    color: #083059;
    font-size: 1.6rem;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;

  width: 100%auto;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const TextNote = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  color: #b9b9c3;
  font-size: 1.4rem;
  max-width: 50rem;
`;

export const Button = styled.button`
  color: #083059;
  font-size: 1.4rem;
  height: 3.5rem;
  padding: 0 2rem;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  .iconify {
    margin-right: 1rem;
    font-size: 1.6rem;
  }
`;
