import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 15rem;
  width: 100%;
  background: #fff;
  margin-top: 1.2rem;
  padding: 0 1.2rem;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;

  @media (max-width: 768px) {
    overflow-x: auto;
    padding: 0;
  }
`;

export const Button = styled.button`
  color: #083059;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100rem;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 1.2rem;

  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    height: 0.3rem;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 1rem;
  }
  ::selection {
    background-color: #fff;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const BoxSchedule = styled.div`
  background: #083059;
  height: 10rem;
  min-width: 14rem;
  scroll-snap-align: start;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 1.2rem;
  border-radius: 0.5rem;

  ${props =>
    props.notActive &&
    css`
      background: #f9f8fe;

      span {
        color: #083059;
      }
    `}

  @media (max-width: 768px) {
    width: 18rem;
  }
`;

export const Text = styled.span`
  color: #fff;
  font-size: 1.6rem;
  width: 100%;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.top &&
    css`
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
      border-bottom: 1px solid #ffffff10;
    `}

  .iconify {
    margin-right: 0.5rem;
  }
`;

export const Image = styled.img`
  position: absolute;
  top: -1.5rem;
  background: #083059;
  padding: 0.3rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 2px solid #fff;

  ${props =>
    props.notActive &&
    css`
      background: #f9f8fe;
    `}
`;

export const MenuButton = styled.span`
  position: absolute;
  color: #fff;
  font-size: 1.6rem;

  top: 0.3rem;
  right: 0.3rem;

  .iconify {
    font-size: 1.6rem;
    color: #fff;
  }

  ${props =>
    props.notActive &&
    css`
      color: #083059;
    `}
`;
