import React, { useEffect, useState } from 'react';
import { Chat } from '../../../components/Base/Chat';
import Footer from '../../../components/Footer';
import LogoGreenSvg from '../../../assets/icon-green.svg';
import { StudentVocabulary } from '../../../components/Mission/StudentVocabulary';
import { StudentQuestions } from '../../../components/Mission/StudentQuestions';
import { StudentReadingComprehension } from '../../../components/Mission/StudentReadingComprehension';
import { toast } from 'react-toastify';
import Session from '../../../components/Kinesis/Session';
import { socket } from '../../../services/contextSocket';
import { useLocation, useHistory } from 'react-router-dom';
import { customState } from '../../../utils/customState';
import {
  Container,
  Wrapper,
  Content,
  ColumnContent,
  ContainerImage,
  Image,
  LogoTableGreen,
} from './styles';
import { Header } from '../../../components/Header';
import { StudentHeaderInfo } from '../../../components/Mission/StudentHeaderInfo';

export function StudentClassroom() {
  const location0 = useLocation();
  const location = customState(location0);

  const history = useHistory();

  const [hideContentStudent, setHideContentStudent] = useState();

  const [closeSession, setCloseSession] = useState(false);
  const [handleTakePhoto, setTakePhoto] = useState(false);

  const [teacherOnline, setTeacherOnline] = useState(false);
  const [lastSeenTeacher, setLastSeenTeacher] = useState(0);

  const [restartConnection, setRestartConnection] = useState(false);

  const teacher = location?.state?.attendees.filter(
    attendee => attendee.type === 'teacher',
  );

  const student = location?.state?.attendees.filter(
    attendee => attendee.type === 'student',
  );

  const classRoom = location.state.id;

  useEffect(() => {
    socket.on('connect', () => {
      console.log('[IO] A new connection has been established 🚨');
      socket.emit('classroom:create', classRoom);
    });
  }, []);

  const [conteudo, setConteudo] = useState([]);

  const [methodMission, setMethodMission] = useState({});

  function fetchCreateClassRoom() {
    socket.emit('classroom:create', classRoom);
  }

  function fetchClassRoom() {
    socket.on('classroom:serverclasscontent', classContent => {
      if (classContent?.hideContentStudent === 1) {
        setHideContentStudent(true);
      }

      if (classContent?.hideContentStudent === 0) {
        setHideContentStudent(false);
      }

      setConteudo(classContent);
    });

    socket.on(
      'classroom:serverclasscontentmethodmission',
      classMethodMission => {
        setMethodMission(classMethodMission);
      },
    );

    socket.on(
      'classroom:serverclasscontentmethodmission',
      classMethodMission => {
        setMethodMission(classMethodMission);
      },
    );

    socket.emit('classroom:classstudentjoin', { classRoom });

    socket.on('classroom:serverfinishclass', classRoom => {
      history.push({
        pathname: '/dashboard/method',
      });
    });

    socket.on('classroom:serverreload', classRoom => {
      // window.location.reload();
      setRestartConnection(true);
    });
  }

  useEffect(() => {
    if (restartConnection) {
      setTimeout(() => {
        setRestartConnection(false);
      }, 1000);
    }
  }, [restartConnection]);

  // Verificar se professor esta online;
  useEffect(() => {
    let interval = null;

    const aliveTeacher = () => {
      const timeStamp = new Date().getTime();
      if (lastSeenTeacher + 8000 > timeStamp) {
        setTeacherOnline(true);
      } else {
        setTeacherOnline(false);
      }
    };

    interval = setInterval(() => aliveTeacher(), 8000);
    aliveTeacher();

    return () => {
      clearInterval(interval);
    };
  }, [lastSeenTeacher]);
  /* end */

  // Mounted
  useEffect(() => {
    fetchCreateClassRoom();
    fetchClassRoom();

    // Professor esta avisando que esta online
    socket.on('classroom:serverstatusteacher', room => {
      console.log('classroom:serverstatusteacher');
      if (room === classRoom) {
        const timeStamp = new Date().getTime();
        setLastSeenTeacher(timeStamp);
      }
    });
    /* end */

    // Avisando professor que estou online
    let intervalStudent = null;
    function alive() {
      socket.emit('classroom:statusstudent', { classRoom, studentId: student[0]?.id });
    }
    intervalStudent = setInterval(() => alive(), 7500);
    alive();
    /* end */

    // unmounted
    return () => {
      clearInterval(intervalStudent);
    };
  }, []);

  return (
    <Container>
      <Header />
      <Wrapper>
        <StudentHeaderInfo />
      </Wrapper>
      <Content>
        {hideContentStudent && (
          <LogoTableGreen>
            <img src={LogoGreenSvg} />
          </LogoTableGreen>
        )}

        {!hideContentStudent && (
          <>
            {conteudo?.type === 'vocabulary' && (
              <StudentVocabulary
                conteudo={conteudo}
                methodMission={methodMission}
              />
            )}

            {['challenge', 'review'].includes(String(conteudo?.type)) && (
              <StudentQuestions
                conteudo={conteudo}
                methodMission={methodMission}
              />
            )}

            {['reading_comprehension'].includes(conteudo?.type) && (
              <StudentReadingComprehension
                conteudo={conteudo}
                methodMission={methodMission}
              />
            )}
          </>
        )}

        <ColumnContent>
          <ContainerImage>
            {/* <Image src="https://media.bizj.us/view/img/11644841/womanbusinessowner*1200xx4597-2591-526-429.jpg" /> */}
            {!restartConnection && (
              <Session
                region={process.env.REACT_APP_AWSREGION}
                accessKeyId={process.env.REACT_APP_ACCESSKEYID}
                secretAccessKey={process.env.REACT_APP_SECRETACCESSKEY}
                sessionName={classRoom}
                setRestartConnection={setRestartConnection}
                role="viewer"
                handleClose={closeSession}
                handleTakePhoto={handleTakePhoto}
                setTakePhoto={setTakePhoto}
              />
            )}
          </ContainerImage>

          <ContainerImage chat>
            <Chat
              sentId={teacher[0]?.id}
              name={teacher[0]?.fullName ?? teacher[0]?.name}
              imageURL={teacher[0]?.avatarURL}
              statusChat={teacherOnline}
            />
          </ContainerImage>
        </ColumnContent>
      </Content>

      <Footer />
    </Container>
  );
}
