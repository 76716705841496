import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f9f9f9;
  overflow-y: auto;
`;

export const Wrapper = styled.div`
  padding: 1.2rem;
  margin-bottom: 2rem;
`;

export const HeaderComponent = styled.div`
  background: #083059;
  margin-top: 1.2rem;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
  height: 6rem;
  font-size: 2rem;
  color: #fff;

  span {
    margin-right: 3rem;
  }

  @media (max-width: 550px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: auto;
  }

  @media (max-width: 390px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    height: auto;
  }
`;

export const Text = styled.span`
  color: #b9b9c3;
  font-size: 1.4rem;

  ${props =>
    props.header &&
    css`
      font-size: 2rem;
      color: #fff;
      font-weight: 650;
    `}
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;

  ${props =>
    props.selects &&
    css`
      justify-content: flex-end;
      width: 32rem;

      select {
        color: var(--secondary-color-blue);
        background: #fff;
        height: 3.8rem;
        margin: 0;
        width: 14rem;
        font-size: 1.7rem;
        margin-left: 1rem;
        padding: 0 2rem;
        border-radius: 0;
      }

      @media (max-width: 550px) {
        width: 100%;
      }

      @media (max-width: 390px) {
        width: 100%;
        gap: 0.4rem;
      }
    `}

  @media (max-width: 550px) {
    display: grid;
    height: auto;
    width: 100%;
  }

  @media (max-width: 390px) {
    display: grid;
    justify-content: center;
  }
`;

export const Select = styled.select`
  color: var(--secondary-color-blue);
  background: #fff;
  height: 3.8rem;
  margin-left: 1rem;
  padding: 0 2rem;

  @media (max-width: 550px) {
    display: grid;
    width: 100% !important;
  }
`;

export const Nav = styled(NavLink)`
  font-size: 2rem;
  text-decoration: none;
  font-weight: bold;
  color: #fff;
  width: 100% auto;
  height: 6rem;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: #031f3a;

    ${props =>
      props.left &&
      css`
        border-radius: 0.5rem 0 0 0;
      `}
  }
`;
