import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: #f9f9f9;
`;

export const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 2rem;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  background: #f9f9f9;

  @media (max-width: 768px) {
    margin-top: 6rem;
  }

  @media (max-width: 425px) {
    padding: 7rem 1rem;
  }
`;

export const ContainerChat = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;
export const Column1 = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 8;
`;
export const Column2 = styled.div`
  overflow: hidden;
  width: 50vh;
  height: 100%;

  @media (max-width: 900px) {
    width: 100%;
  }

  //Ajustar essa parte, para quando Clicar no Botão
  /* @media (max-width: 480px) {
    display: none;
  } */
`;

export const UsersHeader = styled.div`
  width: 100%;
  min-height: 6rem;
  padding: 1rem;
  background: white;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-left: 1px solid var(--gray3);
  border-bottom: 1px solid var(--gray3);
`;

export const ContentAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Avatar = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const ColumnHeaderMessage = styled.div`
  display: flex;
  width: auto;
  width: 100%;
  padding-left: 1rem;
`;

export const InputSearch = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  height: 4rem;
  font-size: 15px;
  padding: 0.7rem;
`;

export const ContentUsers = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  background: white;
  border-left: 1px solid var(--gray3);
  height: 100%;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--gray6);
    opacity: 0.6;
    border-radius: 20px;
  }
`;

export const TitleHeaderUsers = styled.h1`
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--color-dashboard);
  margin: 2rem 1.5rem;
`;

// export const ChatListContainer = styled.div`
//   width: 30%;
//   border-left: 1px solid #ccc;
//   color: black;
//   height: 100%;
//   background: blue;
//   padding: 2rem;
// `;

// export const ContainerTitle = styled.div`
//   display: flex;
//   align-self: stretch;
//   align-items: center;
//   justify-content: space-between;
//   padding: 1rem;
// `;

// export const Title = styled.div`
//   display: flex;
//   font-size: 15px;
//   color: var(--green1);
//   font-weight: 500;
//   margin: 0;
//   color: #002e5c;
// `;

// export const ContainerUsers = styled.div`
//   display: flex;
//   padding: 1rem;
//   ${(props) =>
//     props.isSelect &&
//     `
//     background-color: #002e5c;
//     color: white;
//     border-radius: 5px;
//   `}
// `;

// export const HeaderMessage = styled.div`
//   width: 100%;
//   display: flex;
//   padding: 1rem;
//   background-color: lime;
//   border-bottom: 1px solid var(--gray3);
//   align-items: center;
// `;

// export const UserMessagePicture = styled.img`
//   width: 4rem;
//   height: 4rem;
//   object-fit: cover;
//   border: 1px solid white;
//   border-radius: 50%;
//   margin-left: 0.05rem;
// `;

// export const DetailsUser = styled.div`
//   justify-content: center;
//   align-items: center;
//   display: flex;
//   flex-direction: column;
//   margin-left: 1rem;
//   width: calc(100% - 6rem);
//   align-items: baseline;
//   background: orange;
//   & i {
//     color: #ccc;
//   }
// `;

// export const InputSearch = styled.input`
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   margin: 0 1rem;
//   width: 100%;
//   height: 4rem;
//   font-size: 15px;
//   padding: 0.7rem;
// `;
