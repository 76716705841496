import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f9f9f9;

  display: flex;
  flex-direction: column;
`;

export const Text = styled.span`
  ${props =>
    props.title &&
    css`
      font-size: 2rem;
      font-weight: 650;
      color: #5e5873;
      margin: 2rem 1.2rem;
    `}
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1.2rem;
`;

export const HeaderNav = styled.div`
  width: 100%;
  height: 8rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    width: 100%;
    height: auto;
    gap: 0.5rem;
  }
`;

export const Column = styled.div`
  display: flex;

  @media (max-width: 375px) {
    ${props =>
      props.buttons &&
      css`
        display: block;
      `}
  }
`;

export const Nav = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
  padding: 1.5rem 1.5rem;

  user-select: none;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  font-size: 2rem;
  color: #5e5873;

  &:hover {
    background-color: #ccc;
  }

  &.active {
    background-color: #ccc;

    ${props =>
      props.left &&
      css`
        border-radius: 0.5rem 0 0 0.5rem;
      `}
  }

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

export const Button = styled.button`
  background: #083059;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 1.2rem;
  border-radius: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
  font-size: 1.4rem;

  :hover {
    filter: brightness(0.8);
  }

  .iconify {
    font-size: 2rem;
    margin-right: 1rem;
  }

  ${props =>
    props.newTicket &&
    css`
      background: #ebe9f1;
      color: #5e5873;
    `}

  @media(max-width: 768px) {
    padding: 1rem;
    font-size: 1.4rem;
    margin-right: 0;
    width: 100%;
    height: 100%;

    .iconify {
      display: none;
    }
  }
`;

export const OpenWhatsApp = styled.a`
  background: #083059;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 1.2rem;
  border-radius: 0.5rem;
  transition: 0.2s;
  text-decoration: none;
  font-size: 1.4rem;
  cursor: pointer;

  :hover {
    filter: brightness(0.8);
  }

  .iconify {
    font-size: 2rem;
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
    font-size: 1.4rem;
    margin-right: 0;
    width: 100%;
    height: 100%;

    .iconify {
      display: none;
    }
  }
`;

export const ContainerRoutes = styled.div`
  display: flex;
  justify-content: center;
`;
