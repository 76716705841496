import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Span } from '../../Span';
import { Button } from '../../Button';
import HistoryPresence from './DatasStudents/HistoryPresence';
import Performance from './DatasStudents/Performance';
import { useAuth } from '../../../hooks/AuthProvider';
import { api } from '../../../services/api';
import {
  Container,
  ContainerBody,
  ContainerTitle,
  Title,
  ContainerTicketBodyPending,
  ContainerTicketBodyPendingItens,
  ContainerTicketBodyStatus,
  ContainerTicketBodyDate,
  ContainerTicketBodyDateInfo,
  ContainerTicketBodyPendingButton,
} from './styles';
import { useTranslation } from 'react-i18next';

export function StudentsProfile() {
  const { user } = useAuth();
  const [historyPresence, setHistoryPresence] = useState(false);
  const [performance, setPerformance] = useState(false);
  const [studentId, setStudentId] = useState(0);
  const [liststudents, setListstudents] = useState([]);
  const [statistic, setStatistic] = useState({});
  const [historic, setHistoric] = useState({});
  const { t } = useTranslation();

  async function openHistory(id) {
    await handleHistoric(id);
    setHistoryPresence(prev => !prev);
    setPerformance(false);

    setStudentId(id);
  }

  async function openPerformance(id) {
    await handleStatistic(id);
    setPerformance(prev => !prev);
    setHistoryPresence(false);
    setStudentId(id);
  }

  function formatDateAppointment(dateAppointment) {
    const date = moment(dateAppointment).format('MMMM Do, YYYY');
    return date;
  }

  const handleStatistic = useCallback(async id => {
    try {
      await api
        .get(`/teachers/profile/${user.id}/student/${id}/statistics`)
        .then(response => {
          setStatistic(response.data);
        });
    } catch (err) {
      console.log('Erro :', err);
    }
  }, []);

  const handleHistoric = useCallback(async id => {
    try {
      await api
        .get(`/teachers/profile/${user.id}/student/${id}/presence`)
        .then(response => {
          setHistoric(response.data);
        });
    } catch (err) {
      console.log('Erro :', err);
    }
  }, []);

  useEffect(() => {
    async function fetchListStudents() {
      await api.get(`/teachers/profile/${user.id}/students`).then(response => {
        setListstudents(response.data);
      });
    }

    fetchListStudents();
  }, []);

  return (
    <>
      <Container>
        <ContainerBody>
          <ContainerTitle>
            <Title>{t('alunos')}</Title>
          </ContainerTitle>
          {liststudents.map((student, index) => (
            <>
              <ContainerTicketBodyPending>
                <ContainerTicketBodyPendingItens>
                  <ContainerTicketBodyDate>
                    <ContainerTicketBodyDateInfo>
                      <Span>{student.student.name}</Span>
                      <Span>{student.student.studentLevel}</Span>
                    </ContainerTicketBodyDateInfo>
                  </ContainerTicketBodyDate>
                  <ContainerTicketBodyStatus>
                    <Span>
                      {student.numberAppointments} {t('aulas')}
                    </Span>
                  </ContainerTicketBodyStatus>
                  <ContainerTicketBodyDate>
                    <ContainerTicketBodyDateInfo>
                      <Span>{t('lastClass')}</Span>
                      <Span>
                        {formatDateAppointment(student.lastAppointment)}
                      </Span>
                    </ContainerTicketBodyDateInfo>
                  </ContainerTicketBodyDate>

                  <ContainerTicketBodyPendingButton>
                    <Button
                      color="#fff"
                      fontColor="#243342"
                      title={t('classesReport')}
                      onClick={() => openHistory(student.student.id)}
                    />
                  </ContainerTicketBodyPendingButton>
                  <ContainerTicketBodyPendingButton>
                    <Button
                      color="#fff"
                      fontColor="#243342"
                      title={t('performance')}
                      onClick={() => openPerformance(student.student.id)}
                    />
                  </ContainerTicketBodyPendingButton>
                </ContainerTicketBodyPendingItens>
              </ContainerTicketBodyPending>

              <HistoryPresence
                presence={historyPresence}
                desempenho={performance}
                show={studentId == student.student.id}
                historic={historic}
              />
              <Performance
                presence={historyPresence}
                desempenho={performance}
                show={studentId == student.student.id}
                statistic={statistic}
              />
            </>
          ))}
        </ContainerBody>
      </Container>
    </>
  );
}
