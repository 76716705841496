import { useTranslation } from 'react-i18next';
import { DeliveredMessagem, TextMessage, ContainerMessage, WrapperAttachment, HeaderAttachment, LinkAttachment } from './styles';

export function Delivered({ content, avatarURL, attachments = [] }) {
  const { t } = useTranslation();
  return (
      <ContainerMessage delivered>
        <DeliveredMessagem>
          <TextMessage white>
            {content}
            {attachments.length > 0 &&
              <WrapperAttachment delivered>
                <HeaderAttachment>{t('attachments')}</HeaderAttachment>
                {attachments.map((attachment, index) => (
                  <LinkAttachment key={index} href={attachment.url} target="_blank">{attachment.nome} ({(attachment.tamanhobytes / 1000).toFixed(1)} KB)</LinkAttachment>
                ))}
              </WrapperAttachment>
            }
          </TextMessage>
        </DeliveredMessagem>
        <img src={avatarURL} alt="" />
      </ContainerMessage>
  );
}
