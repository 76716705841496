import React from 'react';
import IconFooterTalks from '../../assets/icon-green.svg';
import { FooterCopy, FooterIconTalks, TextCopy } from './styles';
import { useAuth } from '../../hooks/AuthProvider';
import { useLocation } from 'react-router-dom';
function Footer() {
  const { authUser } = useAuth();

  const location = useLocation();

  if (location.pathname === '/dashboard/method/planning') {
    return null;
  }

  return (
    <FooterCopy>
      {authUser && (
        <>
          <FooterIconTalks src={IconFooterTalks} />
          <TextCopy>Copyright © 2024 Table Talks</TextCopy>
        </>
      )}
      {!authUser && (
        <>
          <TextCopy>COPYRIGHT © 2024</TextCopy>
          <strong>Table Talks Web</strong>
        </>
      )}
    </FooterCopy>
  );
}

export default Footer;
