import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import IconHeader from '../../../assets/logo_top_banner.png';
import IconBreakthrough from '../../../assets/icon_breakthrough.png';
import IconWatch from '../../../assets/icon_watch.svg';
import { useAuth } from '../../../hooks/AuthProvider';
import { customState } from '../../../utils/customState';
import { api } from '../../../services/api';
import {
  Container,
  ContainerInfo,
  ImageHeader,
  Column,
  Hour,
  Button,
  ContainerProfile,
  ImageProfile,
  ContainerText,
  Text,
  ButtonAddNote,
} from './styles';
import { AddNotes } from '../HeaderInfo/AddNotes';

export function StudentHeaderInfo() {
  const location0 = useLocation();
  const location = customState(location0);

  const { t } = useTranslation();
  const [method, setMethod] = useState('');
  const [mission, setMission] = useState('');
  const [classUsers, setClassUsers] = useState('');
  const [openNotes, setOpenNotes] = useState(false);
  const [isStopedTime, setIsStopedTime] = useState(false);
  const [isTimeSet, setIsTimeSet] = useState(false);

  const [initTimer0, setInitTimer0] = useState('00:00:00');
  const [initTimerDate, setInitTImerDate] = useState(null);

  const [timer, setTimer] = useState('00:00:00');

  const [isTeacherOnline, setIsTeacherOnline] = useState(false);

  async function loadInitialTime() {
    let isOnline = isTeacherOnline;

    while (!isOnline) {
      const { data } = await api.get(
        `/class-initialize/appointment/${location.state._id}`,
      );

      if (data?.teacher) {
        isOnline = true;
        setIsTeacherOnline(true);
      }
    }
  }

  async function loadTimeData() {
    const { data } = await api.get(
      `/classtimecontrol/appointment/${location.state._id}`,
    );

    if (data) {
      setInitTimer0(data.hours ?? '01:00:00');
      setInitTImerDate(new Date());

      if (data.hours === '00:00:00') {
        setIsTimeSet(false);
      } else {
        setIsTimeSet(true);
      }
    } else {
      setInitTimer0('01:00:00');
      setInitTImerDate(new Date());
      setIsTimeSet(true);
    }
  }

  function fetchTeacherUserData() {
    const teacher = location?.state?.attendees.filter(function (attendee) {
      return attendee.type === 'teacher';
    });

    setClassUsers(teacher[0]);
  }

  function loadMethodData() {
    setMethod(location?.state?.method);
    setMission(location?.state?.mission);
  }

  useEffect(() => {
    loadInitialTime();
    loadTimeData();
    loadMethodData();
    fetchTeacherUserData();
  }, []);

  let countMinutes = 0;

  let lastMinute = 0;

  let isFinish = true;
  const clockCustom = () => {
    if (isTeacherOnline) {
      if (isTimeSet) {
        if (!isStopedTime) {
          if (!initTimer0 || !initTimerDate) {
            return;
          }

          let dateNow = new Date();
          let dif = Math.floor((dateNow.getTime() - initTimerDate.getTime())/1000);
          let myTimerf = initTimer0;

          const splitedTime = myTimerf.split(':');

          const initialHours = parseInt(splitedTime[0]);

          const initialMinutes = parseInt(splitedTime[1]);

          const initialSeconds = parseInt(splitedTime[2]);

          const hoursInSeconds = initialHours * 3600;

          const minutesInSeconds = initialMinutes * 60;

          let c = parseInt(hoursInSeconds + minutesInSeconds + initialSeconds) - dif;

          if (c < 0) {
            return;
          }

          let seconds = c % 60;

          const secondsInMinutes = (c - seconds) / 60;
          let minutes = secondsInMinutes % 60;
          let hours = (secondsInMinutes - minutes) / 60;

          if (hours.toString().length < 2) {
            hours = '0' + hours;
          }

          if (minutes.toString().length < 2) {
            minutes = '0' + minutes;
          }

          if (seconds.toString().length < 2) {
            seconds = '0' + seconds;
          }

          if (lastMinute !== minutes) {
            lastMinute = minutes;
            countMinutes = countMinutes + 1;
          }
  
          if (countMinutes > 10) {
            if (!isTeacherOnline && isFinish) {
              isFinish = false;
              setIsLateFinishClass(true);
            }
          }

          setTimer(hours + ':' + minutes + ':' + seconds);
        }
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      clockCustom();
    }, 1000);
    return () => clearInterval(interval);
  }, [isTimeSet, isTeacherOnline]);

  const { typeLogin, user } = useAuth();
  return (
    <Container>
      <AddNotes open={openNotes} setOpen={setOpenNotes} />
      <header>
        <img className="image-banner" src={IconHeader} alt="" />
        {user.type === 'teacher' && (
          <Text titleHeader>
            {t('haveTwoHoursPerWeek')}
            {t('itWillUpdateEveryMonday')}
          </Text>
        )}
      </header>
      <ContainerInfo typeLogin={typeLogin}>
        <Column columnleft>
          <ImageHeader src={IconBreakthrough} />
          <Text left>
            <strong>{method.title}</strong>
          </Text>
        </Column>
        <Column columnright>
          <ButtonAddNote onClick={() => setOpenNotes(true)}>{t('addNote')}</ButtonAddNote>
          <Hour>
            <img src={IconWatch} alt="" />
            <div className="container-text">
              <Text>
                <strong>{timer}</strong>
              </Text>
              <Text textTime>{t('remaining')}</Text>
            </div>
          </Hour>
          {!typeLogin && (
            <>
              <Button onClick={() => setShowPlacements(true)}>
                {t('placementTest')}{' '}
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-right-alt2"
                  data-inline="false"
                />
              </Button>
              <Button onClick={() => setIsOpen(true)}>
                {t('finishClassNow')}{' '}
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-right-alt2"
                  data-inline="false"
                />
              </Button>
            </>
          )}

          <ContainerProfile>
            <ImageProfile
              onClick={() => handleShowDialog}
              src={classUsers?.avatarURL}
            />
            <ContainerText>
              <Text name>{classUsers?.fullName ?? classUsers?.name ?? ''}</Text>
            </ContainerText>
          </ContainerProfile>
        </Column>
      </ContainerInfo>
    </Container>
  );
}
