import React, { useEffect, useState } from 'react';

import { api } from '../../../../services/api';
import { toast } from 'react-toastify';
import ImageConference from '../../../../assets/img-conference.png';
import { socket } from '../../../../services/contextSocket';
import { useLocation } from 'react-router-dom';
import {
  ModalMission,
  ContainerModal,
  Container,
  ContainerButtons,
  Button,
  Text,
  Form,
  Textarea,
  ClockContainer,
  Clock,
} from './styles';
import { useTranslation } from 'react-i18next';

export function FinishModal({
  isOpen,
  canClose,
  setIsOpen,
  mission,
  setCloseSession,
  setTakePhoto,
  classRoom,
}) {
  const { t } = useTranslation();
  const [PhotoNotTaken, setPhonoNotTaken] = useState(true);

  const [buttonStudentPresent, setButtonStudentPresent] = useState(true);

  const [buttonFinishedMission, setButtonFinishedMission] = useState(true);

  const [showFinishButton, setShowFinishButton] = useState(false);

  const [secondsRemaining, setSecondsRemaining] = useState(180);

  const [finishClassData, setFinishClassData] = useState({
    isStudentPresent: true,
    finishedMission: true,
    signatureURL: '',
    finishObservation: '',
  });
  function minusSecond() {
    if (!secondsRemaining) {
      handleTakePhoto();
      setTimeout(async () => {
        await finishClassEndClass();
      }, 5000);
      return;
    }
    setTimeout(() => {
      if (isOpen) {
        setSecondsRemaining(secondsRemaining - 1);
      }
    }, 1000);
  }
  useEffect(() => {
    if (isOpen) {
      minusSecond();
    }
  }, [secondsRemaining]);

  useEffect(() => {
    if (isOpen) {
      if (secondsRemaining !== 180) {
        setSecondsRemaining(180);
      } else {
        minusSecond();
      }
    } else {
      setSecondsRemaining(180);
    }
  }, [isOpen]);

  function convertSecondsToMinutes(seconds) {
    let minutes1 = Math.floor(seconds / 60);
    let seconds1 = seconds % 60;
    return `${('0' + minutes1).slice(-2)}:${('0' + seconds1).slice(-2)}`;
  }

  function closeModal() {
    if (!canClose) {
      toast('You cant dismiss it', {
        type: 'warning',
      });
      return;
    }
    setIsOpen(false);
  }

  const handleTakePhoto = () => {
    setPhonoNotTaken(false);
    setTakePhoto(true);
  };

  const handleFinishClassSetValues = (type, value) => {
    switch (type) {
      case 'isStudentPresent':
        finishClassData.isStudentPresent = value;
        setFinishClassData(finishClassData);

        if (value) {
          setButtonStudentPresent(true);
        } else {
          setButtonStudentPresent(false);
        }

        break;

      case 'finishedMission':
        finishClassData.finishedMission = value;
        setFinishClassData(finishClassData);

        if (value) {
          setButtonFinishedMission(true);
        } else {
          setButtonFinishedMission(false);
        }

        break;

      case 'signatureURL':
        finishClassData.signatureURL = value;
        setFinishClassData(finishClassData);
        break;

      case 'finishObservation':
        finishClassData.finishObservation = value;
        setFinishClassData(finishClassData);
        break;

      default:
        break;
    }
  };

  const handleSavePhoto = async (file, type, appointment) => {
    try {
      const { data } = await api.post(`webconference/${appointment}`, {
        appointment,
        type,
        file,
      });
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const uploadPhoto = async () => {
    const localDisplay = document.getElementsByClassName('localDisplay');
    const remoteDisplay = document.getElementsByClassName('remoteDisplay');
    if (localDisplay.length > 0) {
      const localImage = localDisplay[0].getAttribute('src');
      if (localImage) {
        await handleSavePhoto(localImage, 'Teacher', classRoom);
      }
    }
    if (remoteDisplay.length > 0) {
      const remoteImage = remoteDisplay[0].getAttribute('src');
      if (remoteImage) {
        await handleSavePhoto(remoteImage, 'Studant', classRoom);
      }
    }
  };

  const handleFinishClass = async () => {
    if (PhotoNotTaken) {
      toast(t('takePhoto'), { type: 'error' });
    } else {
      await finishClassEndClass();
    }
  };
  const finishClassEndClass = async () => {
    await uploadPhoto();
    await api
      .post(`/appointments/finish/${classRoom}`, {
        signatureURL: finishClassData.signatureURL,
        finishedMission: finishClassData.finishedMission,
        comment: finishClassData.finishObservation,
        isStudentPresent: finishClassData.isStudentPresent,
      })
      .then(async response => {
        socket.emit('classroom:create', classRoom);
        socket.emit('classroom:finishclass', { classRoom });
        setCloseSession(true);
        setPhonoNotTaken(false);
      });
    toast(t('classesCompletedSuccessfully'), { type: 'success' });
  };

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  return (
    <ModalMission
      overlayClassName="react-modal-overlay"
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <ContainerModal>
        <Text title>{t('finishClassNow')}</Text>
        <div className="imageConference">
          <img alt="" className="localDisplay" />
          <img alt="" className="remoteDisplay" />
        </div>
        <Container className="container-img">
          <Form>
            <Text>{t('observacao')}:</Text>
            <Textarea
              onChange={e =>
                handleFinishClassSetValues('finishObservation', e.target.value)
              }
              name="finishObservation"
              placeholder="..."
            />
            <ContainerButtons className="container-buttons">
              <Text text>
                {t('haveYouFinish')} {mission?.title} ?
              </Text>
              <Button
                className={buttonFinishedMission ? 'active-button' : ''}
                onClick={() =>
                  handleFinishClassSetValues('finishedMission', true)
                }
                type="button"
              >
                {t('sim')}
              </Button>
              <Button
                className={!buttonFinishedMission ? 'active-button' : ''}
                onClick={() =>
                  handleFinishClassSetValues('finishedMission', false)
                }
                type="button"
              >
                {t('nao')}
              </Button>
            </ContainerButtons>
            <ContainerButtons className="container-buttons">
              <Text text>Is the Student present?</Text>
              <Button
                className={buttonStudentPresent ? 'active-button' : ''}
                onClick={() =>
                  handleFinishClassSetValues('isStudentPresent', true)
                }
                type="button"
              >
                {t('sim')}
              </Button>
              <Button
                className={!buttonStudentPresent ? 'active-button' : ''}
                onClick={() =>
                  handleFinishClassSetValues('isStudentPresent', false)
                }
                type="button"
              >
                {t('nao')}
              </Button>
            </ContainerButtons>
          </Form>
        </Container>
        <ClockContainer>
          <Clock>
            <span
              className="iconify"
              data-icon="ic:outline-watch-later"
              data-inline="false"
            />
            <span style={{ marginLeft: '5px' }}>
              {convertSecondsToMinutes(secondsRemaining)} to automatically
              finish
            </span>
          </Clock>
        </ClockContainer>
        <Button
          className="button"
          type="button"
          onClick={() => handleTakePhoto()}
        >
          {t('takePicture')}
        </Button>
        {!showFinishButton && (
          <Button
            className="button"
            type="button"
            onClick={() => handleFinishClass()}
          >
            {t('finalizar')}
          </Button>
        )}
      </ContainerModal>
    </ModalMission>
  );
}
