import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { Button } from '../../components/Button';
import { Span } from '../../components/Span';
import Logo from '../../assets/icon_logo_login.png';
import IconFooterTalks from '../../assets/icon-green.svg';
import { toast } from 'react-toastify';

import {
  Container,
  Column1,
  IconsLeft,
  Column2,
  ScrollForm,
  BaseDiv,
  Column2Form,
  ImageLogo,
  FooterCopy,
  FooterIconTalks,
  TextCopy,
  LinkConfirm,
  Error,
} from './styles';
import { api } from '../../services/api';

export function RecoverPassword() {
  const { token } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, SetconfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleResetPassword = useCallback(async () => {
    if (!newPassword || !confirmPassword || newPassword !== confirmPassword) {
      setError('As senhas não podem ser vázias e devem bater');
      return;
    }

    try {
      const response = await api.post(
        'password/reset',
        {
          password: newPassword,
          token: token,
        }
      );

      if (!response) {
        toast('Falha ao alterar o password.');
        history.push('/');
      }

      toast('Password alterado com sucesso!');
      history.push('/');
    } catch (err) {
      toast('Falha ao alterar o password.');
      history.push('/');
    }
  }, [newPassword, confirmPassword, token]);

  return (
    <Container>
      <Column1>
        <IconsLeft />
      </Column1>
      <Column2>
        <ScrollForm>
          <ImageLogo src={Logo} />
          <Column2Form>
            <BaseDiv>
              <Span>
                {t('Vamos resetar a sua senha. Escolha uma nova senha.')}
              </Span>
            </BaseDiv>

            <BaseDiv isForm>
              <input
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Nova senha"
                name="newPassword"
              />
              <input
                value={confirmPassword}
                onChange={(e) => SetconfirmPassword(e.target.value)}
                placeholder="Confirmar senha"
                name="confirmPassword"
              />
            </BaseDiv>

            {error && <Error>{t(error)}</Error>}

            <BaseDiv>

                <Button
                  onClick={handleResetPassword}
                  color="#243342"
                  fontColor="#fff"
                  title="Resetar senha"
                />

            </BaseDiv>
          </Column2Form>
          <FooterCopy>
            <FooterIconTalks src={IconFooterTalks} />
            <TextCopy>Copyright © 2021 Table Talks</TextCopy>
          </FooterCopy>
        </ScrollForm>
      </Column2>
    </Container>
  );
}
