import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { api } from '../../../../services/api';
import { ExamMultipleChoice } from '../ExamMutipleChoice';
import { ExamWritten } from '../ExamWritten';

// import { Container } from './styles';
export interface WrittenQuestion {
  text: string;
  _id: string;
}

export interface Exam {
  _id: string;
  method: string;
  mission: string;
  type: 'open' | 'multiple_choice';
  writtenQuestions: WrittenQuestion[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  multipleChoiceQuestions: any[];
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  id: string;
}

export function Exam() {
  const { idMission } = useParams();

  const [exam, setExam] = useState<Exam>();

  const fetchExam = useCallback(async () => {
    await api
      .get('/homework/student/exam', {
        params: {
          mission: idMission,
        },
      })
      .then((response) => {
        console.log(response);
        setExam(response.data);
      });
  }, [idMission]);

  useEffect(() => {
    fetchExam();
  }, [fetchExam]);

  if (!exam) {
    return <div>Carregando...</div>;
  }

  if (exam.type === 'open') {
    return <ExamWritten data={exam} />;
  }

  if (exam.type === 'multiple_choice') {
    return <ExamMultipleChoice data={exam} />;
  }

  return <div />;
}
