import React, { Fragment, useCallback } from 'react';
import { Container, Error, ContainerTextArea } from './styles';


function Input({ error,  ...rest }) {
  console.log(rest)

  return (
    <Fragment>
      {rest?.isTextArea ? <ContainerTextArea withError={!!error} {...rest}/> : <Container withError={!!error} {...rest} />}
      {!!error && <Error>{error}</Error>}
    </Fragment>
  );
}

export default Input;
