import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import IconBallon from '../../../assets/icon_breakthrough.png';
import { api } from '../../../services/api';
import {
  Container,
  Text,
  ContainerExercise,
  ContainerExerciseList,
  ContainerText,
  Image,
  Button,
  ContainerModal,
  ContainerHeaderModal,
  ContainerListModal,
  ListModal,
  ContainerTextModal,
  BottonModal,
} from './styles';

export function ListAnswer({ isOpen, homework, onRequestClose }) {
  const { t } = useTranslation();
  
  return (
    <Modal
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Example Modal"
    >
      <ContainerHeaderModal>
        <Text titleModal>
          Exercise {homework.method.title} - {homework.mission.title}
        </Text>
      </ContainerHeaderModal>
      <ContainerListModal>
        {homework.answers.map((answer, index) => (
          <ListModal>
            <ContainerTextModal>
              <Text>{answer.question}</Text>
            </ContainerTextModal>
            <ContainerTextModal bottom>
              <Text>{answer.answer}</Text>
            </ContainerTextModal>
          </ListModal>
        ))}
      </ContainerListModal>
      <BottonModal onClick={onRequestClose}>{t('fechar')}</BottonModal>
    </Modal>
  );
}
