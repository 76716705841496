import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Text = styled.span`
  color: #5e5873;
  font-size: 1.5rem;
  padding-top: 1rem;

  ${props =>
    props.title &&
    css`
      font-size: 2rem;
    `}
`;

export const Input = styled.input`
  height: 4rem;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  margin-left: 3rem;
  width: 28rem;
  padding: 2rem;
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ContainerColumn1 = styled.div`
  flex-basis: 18%;
  border: 1px solid #ebe9f1;
  margin-right: 2%;
  height: calc(100vh - 85px);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #083059;
    border-radius: 1rem;
  }
  ::selection {
    background-color: #083059;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
export const ContainerColumn1Item = styled.div`
  height: 33rem;
  margin-bottom: 1rem;
  img {
    margin-top: 0.5rem;
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
  }
`;

export const ContainerColumn1Title = styled.div`
  span {
    font-size: 1.8rem;
  }
`;

export const ContainerColumn1Body = styled.div`
  span {
    font-size: 1.4rem;
  }
`;

export const ContainerColumn1Button = styled.div`
  button {
    margin-top: 1rem;
    cursor: pointer;
    width: 80%;
    height: 3rem;
    background-color: #083059;
    color: #fff;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ContainerColumn3 = styled.div`
  flex-basis: 18%;
  border: 1px solid #ebe9f1;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ContainerColumn2 = styled.div`
  flex-basis: 60%;
  border: 1px solid #ebe9f1;
  background-color: #fff;
  margin-right: 2%;
  height: calc(100vh - 85px);

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

export const ContainerColumn2Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ebe9f1;
  margin-bottom: 1rem;

  a {
    font-size: 1.6rem;
    color: #5e5873;
    text-decoration: none;
    margin: 1rem;
  }
`;

export const ContainerColumn2HeaderText = styled.span`
  color: #5e5873;
  font-size: 1.5rem;
  text-align: justify;
  padding: 1.5rem 1.5rem;
`;

export const ContainerColumn2Body = styled.div`
  height: calc(100% - 60px);

  img {
    width: 100%;

    @media (max-width: 768px) {
      width: 100%;
      height: 60%;
    }
  }

  .react-pdf__Page__canvas {
    width: 80% !important;
    height: 100% !important;
  }
`;

export const ContainerColumn3Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ContainerColumn3HeaderText = styled.span`
  color: #5e5873;
  font-size: 1.5rem;
  text-align: justify;
  padding: 1.5rem 1.5rem;
  font-weight: 600;
`;

export const ContainerBodyVideos = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 1.2rem;
  height: calc(100vh - 145px);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #083059;
    border-radius: 1rem;
  }
  ::selection {
    background-color: #083059;
  }
`;

export const ContainerBodyVideosSize = styled.div`
  width: 95%;

  margin: 0 auto;
`;

export const ContainerBodyVideosText = styled.div`
  Span {
    font-size: 1.6rem;
    color: #898794;
  }
`;

export const ContainerBodyVideosBody = styled.div`
  margin-top: 1rem;
  overflow-y: auto;
  height: 100%;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #083059;
    border-radius: 10px;
  }
  ::selection {
    background-color: #083059;
  }
`;

export const ContainerBodyVideosBodyItem = styled.div`
  height: 20rem;
`;

export const ContainerBodyVideosBodyItemText = styled.div`
  padding-top: 1.2rem;
`;
