import styled, { css } from 'styled-components';

interface TextProps {
  alert?: boolean;
}

interface ButtonProps {
  check?: boolean;
}

interface DateProps {
  active?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const LoadingContainer = styled.div`
  display: flex;
  padding: 10% 0;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const EmptyListContainer = styled.div`
  display: flex;
  padding: 10% 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.2rem;

  @media (max-width: 768px) {
    margin-top: 6rem;
  }
`;

export const RequestBox = styled.div`
  height: 6rem;
  width: 100%;
  background: #243342;

  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  padding: 0 2rem;
  position: relative;
`;

export const Icon = styled.img`
  margin-right: 2rem;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid #06231f;
  height: 100%;
  padding: 0 1rem;
`;

export const Text = styled.span<TextProps>`
  color: #fff;
  font-size: 1.8rem;

  ${(props) => props.alert
    && css`
      font-weight: 900;
    `}
`;

export const Image = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

export const ContainerUser = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
`;

export const ContainerTextsUser = styled.div`
  display: flex;
  flex-direction: column;

  span + span {
    font-size: 1.4rem;
  }
`;

export const ContainerButtons = styled.div`
  position: absolute;
  right: 2rem;

  display: flex;
  align-items: center;
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  background: #ffffff;
  color: #243342;
  margin-left: 1rem;
  padding: 0 1rem;
  border-radius: 0.4rem;
  cursor: pointer;

  .iconify {
    font-size: 1.6rem;
    margin: 0 1rem;
  }

  ${(props) => props.check
    && css`
      padding-right: 3rem;
      background: #dbad00;
      color: #fff;
    `}
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;

  span {
    font-size: 1.8rem;
    font-weight: 900;
    color: #243342;
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 1.4rem;
    font-weight: 500;
    color: #707070;
    font-family: 'Montserrat';
  }
`;

export const Input = styled.input`
  border: 1px solid #00000016;
  height: 4rem;
  margin: 0 1rem;
  border-radius: 0.4rem;
  padding: 0 1rem;

  &::placeholder {
    color: #b9b9c3;
  }
`;

export const ButtonStatus = styled.button`
  border: 1px solid #00000016;
  color: #6e6b7b;
  height: 4rem;
  width: 12rem;
  border-radius: 0.4rem;
  padding: 0 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerDates = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #707070;
    width: 8rem;
  }
`;

export const ButtonIcon = styled.button`
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  border-radius: 50%;
  background: #f3f2f7;
  cursor: pointer;
  .iconify {
    font-size: 1.6rem;
    color: #7367f0;
  }

  ${(props) => props.disabled
    && css`
      cursor: default;

      .iconify {
        color: gray;
      }
    `}
`;

export const ContainerWeek = styled.div`
  width: 100%;
  display: flex;
  background: #f3f2f7;
  margin: 2rem 1rem;
  height: 3rem;
  align-items: center;
  border-radius: 3rem;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const ContainerDay = styled.div<DateProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3rem;
  cursor: pointer;
  span {
    color: #6e6b7b;
    font-family: 'Montserrat';
    font-size: 1.4rem;
  }

  ${(props) => props.active
    && css`
      cursor: default;
      background: #243342;
      height: 4rem;
      border-radius: 4rem;

      span {
        font-size: 1.5rem;
        font-weight: 600;
        color: #fff;
      }
    `}
`;
