/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';

import { Chat } from '../../../components/Base/Chat';
import { useOnlineStatus } from '../../../context/OnlineStatusContext';
import {
  BadgeStatus,
  ButtonMessage,
  ContainerShowComponents,
  ContainerTextsUser,
  ContainerUser,
  Image,
  List,
  Text,
} from './styles.ListActiveMethodStudents';

interface IStudent {
  _id: string;
  avatarURL: string;
  fullName: string;
  name: string;
  studentLevel: string;
}

interface IActiveMethodStudentsProps {
  student: IStudent;
  isCollapsed: any;
  setIsCollapsed: any;
}

export const ListActiveMethodStudentsItem = ({
  student,
  isCollapsed,
  setIsCollapsed,
}: IActiveMethodStudentsProps) => {
  const { t } = useTranslation();

  const handleCollapseClick = async (item: IStudent) => {
    setIsCollapsed((prevState: any) => ({
      [student._id]: !prevState[item._id],
    }));
  };

  const { checkIsOnline } = useOnlineStatus();

  return (
    <List>
      <ContainerUser>
        {checkIsOnline(student._id) && <BadgeStatus online />}
        <Image src={student.avatarURL} />
        <ContainerTextsUser>
          <Text>{student.fullName ?? student.name}</Text>
          <Text>{student.studentLevel}</Text>
        </ContainerTextsUser>
      </ContainerUser>
      <ContainerUser>
        <ButtonMessage onClick={() => handleCollapseClick(student)}>
          <span className="iconify" data-icon="bi:chat-left-text" />
          {t('enviarMensagem')}
        </ButtonMessage>

        {isCollapsed[student._id] && (
          <ContainerShowComponents>
            <Chat
              sentId={student?._id}
              name={student.fullName ?? student.name}
              imageURL={student.avatarURL}
            />
          </ContainerShowComponents>
        )}
      </ContainerUser>
    </List>
  );
};
