import { api } from './api';

export const twoFactorAuthService = {
  async updateTwoFactorAuth(type, keyCode) {
    const result = await api.put('/users/update-two-factor', {
      twoFactorAuthentication: {
        type,
      },
      keyGoogleAuth: keyCode,
    });
    return result.data;
  },
};
