import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 40rem;
  height: 33.1rem;
  background: #fff;
  border: 1px solid var(--gray3);
  margin-top: 1.2rem;
  border-radius: 0.5rem;

  @media (max-width: 1660px) {
    max-width: 100%;
    margin-top: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin-top: 0px;
    height: 30.4rem;
  }
  @media (max-width: 468px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const ContainerTitle = styled.div`
  width: 100%;
  height: 5.8rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  /* padding: 1rem 1.5rem 1.5rem 1.5rem; */
  border-bottom: 1px solid var(--gray3);

  ${props =>
    props.header &&
    css`
      background: #ebe9f1;
      display: flex;
      justify-content: space-between;
      height: 5rem;
    `}
`;

export const Title = styled.span`
  font-size: 2rem;
  color: #707070;
  font-weight: 650;

  ${props =>
    props.header &&
    css`
      font-size: 1.6rem;
      color: #083059;
      font-weight: 650;
    `}

  ${props =>
    props.values &&
    css`
      font-size: 1.6rem;
      margin-left: 2rem;
      color: #083059;
      font-weight: 650;
    `}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 20.2rem;
  padding: 0 1rem;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.6rem;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #083059;
    border-radius: 1rem;
  }
  ::selection {
    background-color: #083059;
  }
`;

export const ContainerList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #ebe9f1;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const TextDetails = styled.span`
  font-size: 1.4rem;
  color: #5e5873;
  margin-left: 0.5rem;

  ${props =>
    props.classes &&
    css`
      color: #06231f;
      margin-left: 2rem;
    `}

  ${props =>
    props.name &&
    css`
      font-weight: bold;
    `}
`;
