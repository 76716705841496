import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
`;

export const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 10px;

  overflow-y: auto;
  overflow-x: hidden;
  background: #f9f9f9;

  @media (max-width: 425px) {
    padding: 7rem 1rem;
  }
`;

export const Writtenexercises = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  flex-direction: column;
  padding: 10px;
  background: #fff;
`;

export const WrittenexercisesHeader = styled.div`
  display: flex;
  width: 100%;
  height: 25%;
  flex-direction: row;
  padding: 10px;
  background: #fff;
  margin-top: 10px;
  border: 1px solid var(--gray3);
  Span {
    width: 50%;
    font-size: 18px;
    font-weight: 600;
    padding: 5px;
    color: #8a8895;
  }
`;

export const WrittenexercisesBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #fff;
  margin-top: 30px;
`;

export const WrittenexercisesImage = styled.div`
  width: 50%;
  font-size: 18px;
  font-weight: 600;
  padding: 5px;
  color: #8a8895;

  img {
    width: 80%;
  }
`;

export const WrittenexercisesQuestions = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  font-size: 18px;
  font-weight: 600;
  padding: 5px;
  margin-top: 10px;

  Span {
    width: 50%;
    font-size: 18px;
    font-weight: 600;
    padding: 5px;
    color: #8a8895;
    margin-bottom: 10px;
  }
`;

export const ImageLogo = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`;

export const WrittenexercisesQuestionsChoice = styled.div`
  margin-top: 2vh;
  border-radius: 0px;

  Button {
    margin-top: 2vh;
    border: 1px solid var(--gray3);

    :hover {
      background-color: #dbad00;
      color: #083059;
    }
  }
`;

export const WrittenexercisesQuestionsGroupButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14vh;
  bottom: 0;
`;

export const WrittenexercisesQuestionsButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2vh;
  Button {
    width: 45%;
    border-radius: 0px;
  }
`;

export const WrittenexercisesQuestionsUniqueButton = styled.div`
  margin-top: 2vh;
  border-radius: 0px;
`;
