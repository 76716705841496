import styled, { css } from 'styled-components';
import BackgroundWhite from '../../assets/background_message.svg'; // TODO Alterar imagem de fundo

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: inherit;
`;

export const HeaderMessage = styled.div`
  width: 100%;
  height: 6rem;
  padding: 1rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--gray3);
  border-radius: 0 0 0.5rem 0.5rem;
`;

export const ColumnHeaderMessage = styled.div`
  display: flex;
  width: auto;
  @media (max-width: 728px) {
    max-width: 20rem;
  }
`;

export const ContentAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Avatar = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const DetailsUser = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  width: auto;
  align-items: baseline;
  & i {
    color: #ccc;
  }
`;

export const TextContact = styled.h1`
  color: #5e5873;
  font-weight: 1.2rem;
  font-weight: 500;
`;

export const InputSearch = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 1rem;
  width: 100%;
  height: 4rem;
  font-size: 15px;
  padding: 0.7rem;

  @media (max-width: 728px) {
    max-width: 10rem;
  }
`;

export const BackgroundChat = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  background-image: url(${BackgroundWhite});
  border-right: 1px solid var(--gray3);
  border-left: 1px solid var(--gray3);

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--gray6);
    opacity: 0.6;
    border-radius: 20px;
  }
`;

export const Form = styled.form`
  background-color: white;
  border: 1px solid var(--gray3);
  padding-bottom: .7rem;
`;

export const ContainerSend = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;
`;

export const InputSend = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 1rem;
  height: 100%;
  padding: 0.7rem;
  width: calc(100% - 10rem);
  font-size: 15px;
`;

export const ButtonSend = styled.button`
  width: 10rem;
  height: 100%;
  background-color: #002e5c;
  border-radius: 5px;
  color: white;
  padding: 1rem;
  transition: 300ms;
  cursor: pointer;
  &:hover {
    background-color: #014440;
  }
`;

export const InputHidden = styled.input`
  display: none;
`;

export const BoxAttachmentTemporary = styled.div`
  display: flex;
  background-color: #e9e9e9;
  padding: 0.7rem;
  border-radius: .5rem;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 .1rem;
`;

export const WrapperAttachmentTemporaryRow = styled.div`
  display: flex;
  padding: 0 1rem;
  flexWrap: wrap;
`;

export const NameAttachmentTemporary = styled.span`
  font-size: 12px;
  font-weight: 400;
`;
export const SizeAttachmentTemporary = styled.span`
  margin-left: 0.5rem;
  font-size: 10px;
`;

export const WrapperInfoAttachmentTemporary = styled.div`
`;

// COMPONENTS DE MENSSAGEM

export const SentMessagem = styled.div`
  align-self: flex-start;
  max-width: 30rem;
  background: white;
  padding: 1.2rem;
  border-radius: 6px;
  margin-bottom: 1.2rem;
  box-shadow: 0.3rem 0.3rem 0.3rem var(--gray3);
`;
export const DeliveredMessagem = styled.div`
  align-self: flex-end;
  background: #002e5c;
  width: auto;
  max-width: 30rem;
  padding: 1.2rem;
  border-radius: 6px;
  margin-bottom: 1.2rem;
  box-shadow: 0.3rem 0.3rem 0.3rem var(--gray3);
`;

export const LinkAttachment = styled.a``;
export const WrapperAttachment = styled.div`
  padding-top: .5rem;
  border-top: 1px solid ${prop => prop.delivered ? 'rgba(255,255,255, .2)' : 'var(--gray3)'};
  margin-top: 2rem;
`;
export const HeaderAttachment = styled.div``;

export const TextMessage = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;

  a {
    display: block;
    color: #68bbe4;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  ${(props) =>
    props.gray &&
    css`
      color: var(--gray7);
    `};
  ${(props) =>
    props.white &&
    css`
      color: white;
    `};
`;

export const ContainerMessage = styled.div`
  display: flex;

  img {
    border-radius: 50%;
    width: 3.3rem;
    height: 3.3rem;
    object-fit: cover;
    margin-right: 1rem;
  }

  ${(props) =>
    props.delivered &&
    css`
      align-self: flex-end;
      justify-content: flex-end;
      width: auto;

      img {
        margin-left: 1rem;
        margin-right: 0;
      }
    `}
`;

export const ContainerLoader = styled.div`
  display: flex;
  height: 30rem;
  justify-content: center;
  align-items: center;
  background-image: url(${BackgroundWhite});
`;