import React from 'react';
import { StudentList } from '../../ProfileComponent/StudentList';

import { Container } from './styles';

export function Multi({ seletion, status, method, type }) {
  return (
    <Container>
      <StudentList
        status={status}
        method={method}
        type={type}
        seletion={seletion}
      />
    </Container>
  );
}
