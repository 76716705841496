import React, { useState, useEffect, useCallback } from 'react';
import ReactStars from 'react-stars';
import IconCashImage from '../../../assets/icon_cash.svg';
import iconAmountImage from '../../../assets/icon_graphic_amount.svg';
import IconDolar from '../../../assets/icon_dolar.svg';
import IconStudentProfile from '../../../assets/icon_student_profile.svg';
import IconCheck from '../../../assets/icon_check.svg';
import IconMedia from '../../../assets/icon_media_graphic.svg';
import GraphicMedia from '../../../assets/icon_media_lesson.svg';
import IconWithdraw from '../../../assets/svgProfile/select_widra.svg';
import { Select } from '../../Select';
import { Button } from '../../Button';
import { isAfiliate, isStudent, isTeacher, useAuth } from '../../../hooks/AuthProvider';
import {
  Container,
  ContainerWithdraw,
  ContaineStars,
  CountStars,
  Bio,
  TextBio,
  ContainerContact,
  ButtonContact,
  TextButton,
  ContainerInfo,
  ContainerTitle,
  TitleInfo,
  ContainerCash,
  ContainerCashTitle,
  IconCash,
  ContainerAmount,
  TitleCash,
  ContainerInfoClass,
  ContainerText,
  Text,
  Count,
  ContainerExtract,
  Pendences,
  ContainerSelect,
  ContaineCoupon,
} from './styles';
import { formatPrice, formatPhoneNumber } from '../../../utils/format';
import { format, parseISO } from 'date-fns';
import { ModalRequestWithdraw } from '../../Modals/ModalRequestWithdraw';
import { api } from '../../../services/api';
import { useTranslation } from 'react-i18next';

export function ColumnInfoProfile() {
  const { user, fetchUserData } = useAuth();
  const [lastPendingWithdraw, setLastPendingWithdraw] = useState();
  const [withdrawType, setWithdrawType] = useState('bankTransfer');
  const [isRequestWithdrawModalOpen, setIsRequestWithdrawModalOpen] =
    useState(false);

  const { t } = useTranslation();

  function handleRequestWithdrawOpen() {
    setIsRequestWithdrawModalOpen(true);
  }

  async function handleRequestWithdrawClose() {
    setIsRequestWithdrawModalOpen(false);
    await fetchLastPendingWithdraw();
    await fetchUserData();
  }

  const fetchLastPendingWithdraw = useCallback(async () => {
    try {
      await api
        .get('/withdrawals/me', {
          params: { status: 'PENDING' },
        })
        .then(response => {
          setLastPendingWithdraw(response.data[0]);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (user.type === 'teacher') {
      fetchLastPendingWithdraw();
    }
  }, [fetchLastPendingWithdraw]);

  return (
    <>
      <Container typeLogin={user.type === 'student'}>
        {(isTeacher() || isAfiliate()) && (
          <ContainerInfo>
            <ContainerTitle>
              {/* <TitleInfo>{t('myData')}</TitleInfo> */}
            </ContainerTitle>
            <ContainerCash>
              <ContainerCashTitle>
                <IconCash src={IconCashImage} />
                <ContainerAmount extern>
                  <ContainerAmount internal>
                    <TitleCash>R$</TitleCash>
                    <TitleCash value>{formatPrice(user.balance)}</TitleCash>
                  </ContainerAmount>
                  <TitleCash amount>{t('balance')}</TitleCash>
                </ContainerAmount>
              </ContainerCashTitle>
              <ContainerSelect>
                <img src={IconWithdraw} alt="" />
                <Select
                  value={withdrawType}
                  onChange={e => setWithdrawType(e.target.value)}
                >
                  <option value="none">{t('typeOfWithdrawal')}</option>
                  <option value="bankTransfer">{t('bankTransfer')}</option>
                  {/* <option value="IconWithdraw">PIX</option>
                <option value="IconWithdraw">PAYPAL</option>
                <option value="OI">Bitcoin</option> */}
                </Select>
              </ContainerSelect>
              <Button
                title={t('request')}
                color="#DBAD00"
                type="button"
                fontColor="#06231F"
                onClick={handleRequestWithdrawOpen}
              />
            </ContainerCash>

            <ContainerWithdraw>
              <ContainerCashTitle>
                <IconCash src={iconAmountImage} />
                <ContainerAmount externAmount>
                  <ContainerAmount internal>
                    <TitleCash>R$</TitleCash>
                    <TitleCash valueTitleWithdraw>
                      {formatPrice(user.totalEarnings)}
                    </TitleCash>
                  </ContainerAmount>
                  <TitleCash amount>{t('totalAlreadyReceived')}</TitleCash>
                </ContainerAmount>
              </ContainerCashTitle>
              {lastPendingWithdraw && (
                <>
                  <TitleCash withdraw>
                    {t('pendingWithdrawal')}:{' '}
                    {format(
                      parseISO(lastPendingWithdraw.scheduleDate),
                      'dd/MM/yyyy',
                    )}
                  </TitleCash>
                  <TitleCash withdrawValue>
                    {formatPrice(lastPendingWithdraw.value)}
                  </TitleCash>
                </>
              )}
            </ContainerWithdraw>

            {isTeacher() &&
              <>
                <ContaineStars>
                  <TitleCash titleStars>
                    {t('teacherSince')}:{' '}
                    {format(parseISO(user.createdAt), 'dd/MM/yyyy')}
                  </TitleCash>
                  {/*<CountStars>
                    <TitleCash countStars>{user.rating || t('notRated')}</TitleCash>
                    <ReactStars
                    style={{ marginLeft: 20 }}
                    count={5}
                    size={15}
                    value={user.rating}
                    color2="#DBAD00"
                    />
                  </CountStars>*/}
                </ContaineStars>            
                <Bio>
                  <TextBio title>{t('aboutMe')}</TextBio>
                  <TextBio>{user.bio}</TextBio>
                </Bio>
              </>
            }
            <ContainerContact>
              <ButtonContact>
                <span
                  className="iconify"
                  data-icon="feather:mail"
                  data-inline="false"
                  bar
                />
                <TextButton>{user.email}</TextButton>

                <span
                  className="iconify"
                  data-icon="dashicons:arrow-right-alt2"
                  data-inline="false"
                />
              </ButtonContact>
              <ButtonContact>
                <span
                  className="iconify"
                  data-icon="carbon:phone-filled"
                  data-inline="false"
                  bar
                />

                <TextButton>{formatPhoneNumber(user.phoneNumber)}</TextButton>

                <span
                  className="iconify"
                  data-icon="dashicons:arrow-right-alt2"
                  data-inline="false"
                />
              </ButtonContact>
              {user.skype && (
                <ButtonContact>
                  <span
                    className="iconify"
                    data-icon="ant-design:skype-outlined"
                    data-inline="false"
                    bar
                  />

                  <TextButton>@{user.skype}</TextButton>

                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                    data-inline="false"
                  />
                </ButtonContact>
              )}
              {user.linked && (
                <ButtonContact>
                  <span
                    className="iconify"
                    data-icon="bx:bxl-linkedin"
                    data-inline="false"
                    bar
                  />

                  <TextButton>@{user.linked}</TextButton>

                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                    data-inline="false"
                  />
                </ButtonContact>
              )}
            </ContainerContact>
            <Bio>
              <TextBio title>{t('referralCoupon')}</TextBio>
              <ContaineCoupon>
                <TextBio coupon>
                  {user?.cupomcode ? user?.cupomcode : ''}
                </TextBio>
              </ContaineCoupon>
            </Bio>
          </ContainerInfo>
        )}

        {isStudent() && (
          <ContainerInfo student>
            <ContainerInfoClass student>
              <IconCash src={iconAmountImage} student />
              <ContainerText>
                <Text studentTop>{t('yourLevel')}</Text>
                <Text student>{user.level?.mission?.title}</Text>
              </ContainerText>
            </ContainerInfoClass>
            <ContainerInfoClass extract>
              {/* <ContainerExtract topExtract>
                <Text>{t('lastPurchase')}</Text>
                <Text>
                  <span
                    className="iconify"
                    data-icon="akar-icons:eye"
                    data-inline="false"
                  />{' '}
                  {t('seeExtract')}
                </Text>
              </ContainerExtract> */}
              {user.lastPurchase && (
                <ContainerExtract>
                  <IconCash src={IconDolar} studentExtract />
                  <ContainerText>
                    <Text studentTop>{t('quantity')}</Text>
                    <Text studentCountLesson>
                      {user.lastPurchase.quantity} {t('aulas')}
                    </Text>
                  </ContainerText>
                  <ContainerText>
                    <Text studentTop>{t('value')}</Text>
                    <Text studentValue>
                      {formatPrice(user.lastPurchase.value)}
                    </Text>
                  </ContainerText>
                </ContainerExtract>
              )}
            </ContainerInfoClass>
            <ContainerInfoClass count>
              <IconCash src={IconStudentProfile} studentLesson />
              <ContainerText count>
                <Text>{t('classesAvailable')}</Text>
              </ContainerText>
              <Count>
                <ContainerText count>
                  <Text>{user.credits}</Text>
                </ContainerText>
              </Count>
            </ContainerInfoClass>
            <ContainerInfoClass count>
              <IconCash src={IconCheck} studentLesson />
              <ContainerText count>
                <Text>{t('completedLessons')}</Text>
              </ContainerText>
              <Count>
                <ContainerText count>
                  <Text>{user.completedLessons}</Text>
                </ContainerText>
              </Count>
            </ContainerInfoClass>
            <ContainerInfoClass count>
              <IconCash src={IconMedia} studentLesson />
              <ContainerText count>
                <Text>{t('averageOfExams')}</Text>
              </ContainerText>
              <Count>
                <ContainerText count>
                  <Text>
                    {user.examsScoresSum
                      ? parseFloat(user.examsScoresSum).toFixed(2)
                      : null}
                  </Text>
                </ContainerText>
              </Count>
            </ContainerInfoClass>
            <ContainerInfoClass count>
              <IconCash src={GraphicMedia} studentLesson />
              <ContainerText count>
                <Text>{t('averageOfActivities')}</Text>
              </ContainerText>
              <Count>
                <ContainerText count>
                  <Text>
                    {user.activityAverage
                      ? parseFloat(user.activityAverage).toFixed(2)
                      : null}
                  </Text>
                </ContainerText>
              </Count>
            </ContainerInfoClass>

            <Pendences>
              <Text pendences>{t('pendencies')}</Text>
              <ContainerText pendences>
                <Text>{t('openEndedQuestions')}</Text>
                <Text>{user.pendingWrittenHomework}</Text>
              </ContainerText>
              <ContainerText pendences>
                <Text>{t('closedEndedQuestions')}</Text>
                <Text>{user.pendingMultipleChoiceHomework}</Text>
              </ContainerText>
              {/* <ContainerText pendences>
                <Text>{t('quizHomeworks')}</Text>
                <Text>9</Text>
              </ContainerText> */}
            </Pendences>

            <Pendences>
              <Text pendences>{t('schedule')}</Text>
              <ContainerText pendences>
                <Text>{t('nextLessons')}</Text>
                <Text>{user.nextLessons}</Text>
              </ContainerText>
            </Pendences>
          </ContainerInfo>
        )}
      </Container>
      {isRequestWithdrawModalOpen && (
        <ModalRequestWithdraw
          isOpen={isRequestWithdrawModalOpen}
          onRequestClose={handleRequestWithdrawClose}
        />
      )}
    </>
  );
}
