/* eslint-disable @typescript-eslint/no-explicit-any */
export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export function urltoFile(url: string, filename: string, mimeType: any) {
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
}

export function formatPhoneNumber(phoneNumber: string) {
  const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
  const str = phoneNumber.replace(/[^0-9]/g, '').slice(0, 11);

  const result = str.replace(regex, '($1) $2-$3');

  return result;
}

export const maskCPF = (value: string) => {
  if (!value) {
    return '';
  }
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const maskPhone = (value: string) => value
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '($1) $2')
  .replace(/(\d{5})(\d{4})(\d)/, '$1-$2');

export const maskCEP = (value: string) => value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2');

export const maskDate = (value: string) => value
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{4})(\d)/, '$1');
