import React from 'react';

import { Container, Error } from './styles';

export const Select = ({ ...rest }) => (
  <>
  <Container  {...rest} />
   
  </>
)
;
