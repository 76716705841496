import React from 'react';
import { useTranslation } from 'react-i18next';

import IconPix from '../../../../assets/icon_pix.svg';
import {
  Container,
  Wrapper,
  MethodPayment,
  DivInput,
  Input,
  Button,
} from './styles';


import { useCallback } from 'react';
import { useAuth } from '../../../../hooks/AuthProvider';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { api } from '../../../../services/api';
const validationSchema = yup.object({
  pixkey: yup.string().required('')
});

function Financial() {

  const { user, fetchUserData } = useAuth();

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async ({
      pixkey
    }) => {

      try {
        console.log(user);
        await api.put(`/users/${user.id ? user.id : user._id}`, {
          pixkey: pixkey
        });

        await fetchUserData();

        toast('Successfully saved.', { type: 'success' });
      } catch (err) {
        console.log(err);
        toast('There was an error saving your data. Please try again.', {
          type: 'error',
        });
      }
    },
    [user, fetchUserData],
  );

  const formik = useFormik({
    initialValues: {
      pixkey: user.pixkey
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  console.log(formik.value);
  return (
    <Container onSubmit={formik.handleSubmit}>
      <Wrapper>
        {/* <MethodPayment>
          <span>Paypal ({t('enterYourReceivingEmail')})</span>
          <DivInput>
            <div>
              <span
                className="iconify"
                data-icon="teenyicons:paypal-outline"
                data-inline="false"
              />
            </div>
            <Input placeholder="E-mail" name="email" />
          </DivInput>
        </MethodPayment> */}
        <MethodPayment>
          <span>PIX ({t('informYourKeyToReceiveViaPix')})</span>
          <DivInput>
            <div>
              <img src={IconPix} alt="" />
            </div>
            <Input
                name="pixkey"
                onChange={formik.handleChange}
                value={formik.values.pixkey}
                error={formik.errors.pixkey}
                placeholder="Your PIX Key"
              />
          </DivInput>
        </MethodPayment>
        {/* <MethodPayment>
          <span>Bitcoin ({t('informYourWalletToReceive')})</span>
          <DivInput>
            <div>
              <span
                className="iconify"
                data-icon="bi:currency-bitcoin"
                data-inline="false"
              />
            </div>
            <Input placeholder="E-mail" name="email" />
          </DivInput>
        </MethodPayment> */}
      </Wrapper>
      <Button type="submit">{t('salvar')}</Button>
    </Container>
  );
}

export default Financial;
