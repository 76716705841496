import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Input from '../../../../components/Input';
import { Button } from '../../../../components/Button';

import getValidationErrors from '../../../../utils/getValidationErrors';

import { Container, Col25, WrapperForm, ContainerTip } from './styles';
import { api } from '../../../../services/api';

const validationSchema = Yup.object({
  password: Yup.string().required('passwordIsARequiredField'),
  newPassword: Yup.string().required('passwordIsARequiredField'),
  retryPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'passwordMustMatch',
  ),
});


export function UpdatePassword() {
  const formRef = useRef(null);
  const { t } = useTranslation();

  const handleSubmit = useCallback(async (data) => {

    try {

      await validationSchema.validate(data, {
        abortEarly: false
      })

      const { password, newPassword } = data;

      api.put('/password', {
        currentPassword:  password,
        password: newPassword
      }).then(response => {
        toast(t('passwordChangeSu'), { type: 'success' })
      }).catch(err => {
        toast('passwordChangeError!', { type: 'error' })
      })

    } catch (err) {

      const errors = getValidationErrors(err)

      formRef.current.setErrors(errors)
    }

  }, [])


  return (
    <Container>
      <Col25>
        <WrapperForm ref={formRef} onSubmit={handleSubmit}>
          <span>{t('SenhaAtual')}</span>
          <Input
            placeholder={t('SenhaAtual')}
            type="password"
            name="password"

          />
          <br />
          <br />
          <span>{t('novaSenha')}</span>
          <Input
          placeholder={t('novaSenha')}
          type="password"

          name="newPassword" />
          <br />
          <span>{t('repetirSenha')}</span>
          <Input
            placeholder={t('repetirSenha')}
            type="password"
            name="retryPassword"
          />
          <Button
            color="#002e5c"
            fontColor="white"
            title={t('salvar')}
            type="submit"
          />
        </WrapperForm>
      </Col25>
      <Col25>
        <ContainerTip>
          <span>{t('dicaSegurança')}</span>
          <span>{t('tip1')}</span>
          <span>{t('tip2')}</span>
        </ContainerTip>
      </Col25>
    </Container>
  );
}
