/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

interface IProps {
  remoteStreams: any;
  stopVideo: any;
}

function Remote(this: any, { remoteStreams, stopVideo }: IProps) {
  // initalize remote streams
  // side effects
  React.useEffect(() => {
    let mounted = true;
    function mountStream() {
      const remoteView: any = document.getElementById('remoteView');
      if (remoteStreams && stopVideo === false) {
        remoteView.srcObject = remoteStreams;
        // remoteView.muted = true;
      } else if (remoteStreams && stopVideo === true) {
        if (remoteView.srcObject) {
          remoteView.srcObject.getTracks().map((track: any) => track.stop());
        }
      }
    }
    if (mounted) mountStream();
    return () => {
      mounted = false;
    };
  }, [remoteStreams]);

  return remoteStreams ? (
    <div className="remoteVideo">
      <video
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          transform: 'rotateY(180deg)',
        }}
        className="localViews"
        id="remoteView"
        autoPlay
        playsInline
      />
    </div>
  ) : (
    <div className="remoteVideo" />
  );
}

export default Remote;
