/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable import-helpers/order-imports */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './index.css';
import { toast } from 'react-toastify';
import { KinesisClient, Role } from '../../utils/kinesis';
import Loader from './Loader';
import Local from './Local';
import LocalButtons from './LocalButtons';
import Remote from './Remote';
import Wait from './Wait';
import { socket } from '../../services/contextSocket';
// import { urltoFile } from '../../utils/format';

function Session(awsConfig: any) {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    region,
    accessKeyId,
    secretAccessKey,
    sessionName,
    role,
    handleClose,
    handleTakePhoto,
    setTakePhoto,
    onIsWait,
    setRestartConnection,
  } = awsConfig;

  const kinesisClient = new KinesisClient(
    {
      region /* requried */,
      accessKeyId /* requried */,
      secretAccessKey /* requried */,
    },
    setRestartConnection,
  );
  const [isLoading, setisLoading] = React.useState<boolean>(true);
  const [afterSomeTime, setAfterSomeTime] = React.useState<boolean>(false);
  const [isWait, setisWait] = React.useState<boolean>(true);
  const [remoteStream, setRemoteStream] = React.useState<any>(null);
  const [localStream, setLocalStream] = React.useState<any>(null);
  const [, setSignalingClient] = React.useState<any>(null);
  const [stopVideo, setStopVideo] = React.useState<boolean>(false);
  // const [peerConnection, setPeerConnection] = React.useState<any>(null);
  // initialize master

  React.useEffect(() => {
    if (onIsWait) {
      onIsWait(isWait);
    }
  }, [isWait]);

  async function exitSession() {
    setStopVideo(true);
    kinesisClient.stopAll(role);
  }
  async function initializeMaster() {
    console.log('[MASTER]');
    /* listen to local streams */
    kinesisClient.on('localstream', (event) => {
      /* set local streams */
      console.log('[LOCAL STREAMS]', event);

      console.log('AUDIO TRACKS', event.getAudioTracks());
      if (!localStream) setLocalStream(event);
    });

    kinesisClient.on('remotestream', (event) => {
      setisWait(false);
      setRemoteStream(event.streams[event.streams.length - 1]);
    });
    kinesisClient.on('disconnected', async (event) => {
      // stopDevices(localStream);
      console.log('disconnect master');
      console.log(event);
      await exitSession();
      toast('Disconnect - code 01', { type: 'info' });
      history.push('/dashboard/method');
      // window.location.reload();
    });
    kinesisClient.on('connected', async (event) => {
      setSignalingClient(event);
      console.log(sessionName);
      socket.emit('classroom:create', sessionName);
      socket.emit('classroom:reload', sessionName);
      console.log('connection', event);
      console.log('entrou master');
    });

    await kinesisClient.getMedia({
      audio: {
        sampleSize: 5,
        echoCancellation: true,
      },
      video: {
        width: {
          min: 640,
          max: 1024,
        },
        height: {
          min: 480,
          max: 768,
        },
      },
    });

    setisLoading(false);

    try {
      /* get channle arn */
      await kinesisClient.getChannelARN(sessionName);
      /* create a channel incase not available */
      await kinesisClient.setChannelARN(sessionName);
      /* provide role */
      await kinesisClient.setKinesisClient({
        clientId: sessionName,
        role: Role.MASTER,
      });
      /* connect to master and listen to remote tracks */
      kinesisClient.masterConnect();
    } catch (error) {
      stopDevices(localStream);
      await exitSession();
      toast('error to connect 00', { type: 'error' });
      history.push('/dashboard/method');
    }
  }

  // initialize viewer
  async function initializeViewer() {
    console.log('aqui', sessionName);
    /**
     * get media stream
     * - This will prompt user to open camera and audio
     * - provide constraints
     * OR
     * set media stream
     * - generate local stream and send to kinesis client
     */
    console.log('[VIEWER]');
    /* listen to local streams */
    kinesisClient.on('localstream', (event) => {
      console.log('[LOCAL STREAMS]', event);
      /* set local streams */
      setLocalStream(event);
    });
    /* listen to remote streams */
    kinesisClient.on('remotestream', (event) => {
      setisWait(false);
      setRemoteStream(event.streams[event.streams.length - 1]);
    });
    kinesisClient.on('connected', (event) => {
      console.log('connection', event);
      console.log('connect viewer');
      setSignalingClient(event);
    });
    await kinesisClient.getMedia({
      audio: {
        sampleSize: 8,
        echoCancellation: true,
      },
      video: {
        width: {
          min: 640,
          max: 1024,
        },
        height: {
          min: 480,
          max: 768,
        },
      },
    });
    setisLoading(false);
    try {
      /* get channle arn */
      await kinesisClient.getChannelARN(sessionName);
      /* provide role and client id */
      await kinesisClient.setKinesisClient({
        role: Role.VIEWER,
        clientId: sessionName,
      });
      kinesisClient.viewerConnect();
    } catch (error) {
      stopDevices(localStream);
      toast(t('waitingForTeacher'), { type: 'error' });
      setTimeout(() => {
        history.push('/dashboard/method');
        // document.location.href = '/dashboard/method';
      }, 3000);

      console.log('[ERROR]', error);
    }
  }
  function changeScreen() {
    const remoteSession: any = document.getElementById('remoteSession');
    const localSession: any = document.getElementById('localSession');
    const loaderSection: any = document.getElementById('loaderSection');
    if (remoteSession.classList.length > 0) {
      remoteSession.classList.remove('remoteSession');
      localSession.className += 'localSession';
      if (loaderSection) {
        loaderSection.classList.remove('loaderSectionHeightRemote');
        loaderSection.classList.add('loaderSectionHeightLocal');
      }
    } else {
      remoteSession.className += 'remoteSession';
      localSession.classList.remove('localSession');
      if (loaderSection) {
        loaderSection.classList.remove('loaderSectionHeightLocal');
        loaderSection.classList.add('loaderSectionHeightRemote');
      }
    }
  }

  kinesisClient.on('disconnected', async (event) => {
    stopDevices(localStream);
    console.log(event);
    console.log('disconnect viewer');
    await exitSession();
    toast('Disconnect - code 02', { type: 'info' });
    history.push('/dashboard/method');
    // window.location.reload();
  });
  window.addEventListener('beforeunload', exitSession);
  // const handleSavePhoto = async (file: any, type: any, appointment: any) => {
  //   try {
  //     const { data } = await api.post(`webconference/${sessionName}`, {
  //       appointment,
  //       type,
  //       file,
  //     });
  //     console.log(data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  async function capturePhoto(elementCam: any, elementDisplay: any) {
    const canvas = document.createElement('canvas');
    canvas.width = 640;
    canvas.height = 480;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(elementCam, 0, 0, canvas.width, canvas.height);
    const dataURI = canvas.toDataURL('image/jpeg');
    console.log(dataURI);
    console.log(elementDisplay);
    elementDisplay[0].setAttribute('src', dataURI);
  }
  // side effects
  React.useEffect(() => {
    let mounted = true;
    function checkRole() {
      if (role === 'master') {
        console.log('master');
        initializeMaster();
      } else {
        console.log('viewer');
        initializeViewer();
      }
    }

    setTimeout(() => {
      if (mounted) checkRole();
    }, 1000);
    return () => {
      mounted = false;
    };
  }, []);

  React.useEffect(() => {
    async function closeMySession() {
      if (handleClose) {
        stopDevices(localStream);
        await exitSession();
        toast('Disconnect - code 03', { type: 'info' });
        history.push('/dashboard/method');
        // window.location.reload();
      }
    }
    closeMySession();
  }, [handleClose]);

  // React.useEffect(() => {
  //   if (handleUploadPhoto === true) {
  //     const localDisplay = document.getElementsByClassName('localDisplay');
  //     const remoteDisplay = document.getElementsByClassName('remoteDisplay');
  //     if (localDisplay.length > 0) {
  //       const localImage = localDisplay[0].getAttribute('src');
  //       if (localImage) {
  //         handleSavePhoto(localImage, 'Studant', sessionName);
  //       }
  //     }
  //     if (remoteDisplay.length > 0) {
  //       const remoteImage = remoteDisplay[0].getAttribute('src');
  //       if (remoteImage) {
  //         handleSavePhoto(remoteImage, 'Teacher', sessionName);
  //       }
  //     }
  //   }
  // }, [handleUploadPhoto]);

  React.useEffect(() => {
    const localView: any = document.getElementById('localView');
    const remoteView: any = document.getElementById('remoteView');
    const localDisplay = document.getElementsByClassName('localDisplay');
    const remoteDisplay = document.getElementsByClassName('remoteDisplay');
    if (handleTakePhoto === true && remoteStream) {
      capturePhoto(remoteView, remoteDisplay);
    }
    if (handleTakePhoto === true) {
      capturePhoto(localView, localDisplay);
    }
    setTakePhoto(false);
  }, [handleTakePhoto]);
  React.useEffect(() => {
    const unlisten = history.listen(async (location: any) => {
      await exitSession();
      console.log('new location: ', location);
      // do your magic things here
      // reset the search: clear the results and the search input field
    });
    return function cleanup() {
      unlisten();
    };
  }, []);

  /**
   * Desativar a camera e audio ao sair do componente
   */
  React.useEffect(() => {
    const _stream = localStream;
    return () => {
      stopDevices(_stream);
    };
  }, [localStream]);

  const stopDevices = async (_stream: any) => {
    if (_stream) {
      await _stream.getTracks().forEach((track: any) => {
        track.stop();
      });
    }
  };
  /* end */
  setTimeout(() => {
    setAfterSomeTime(true);
  }, 15000);
  if (isLoading) {
    return (
      <div style={{ display: 'block', width: '100%' }}>
        <Loader />
        {afterSomeTime && (
        <div
          style={{
            color: 'red',
            textAlign: 'center',
            fontSize: '16px',
            padding: '5px',
            paddingBottom: '0px',
          }}
        >
          Verifique suas permissões de câmera e aúdio.
          <br />
          Caso esteja habilitado e demore muito para iniciar entre no Firefox
          ou Chrome.
        </div>
        )}
      </div>
    );
  }
  return (
    <div id="sessionContent">
      <div id="remoteSession">
        {isWait ? <Wait /> : <></>}
        <Remote remoteStreams={remoteStream} stopVideo={stopVideo} />
      </div>

      <div id="localSession" className="localSession">
        <Local
          localStream={localStream}
          sessionName={sessionName}
          stopVideo={stopVideo}
        />
      </div>
      <div id="localButtonSection">
        <LocalButtons changeScreen={changeScreen} />
      </div>
    </div>
  );
}

export default Session;
