import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #fff;
  width: 100%;
  padding: 1.2rem;
  border-right: 1px solid var(--gray3);
  border-left: 1px solid var(--gray3);
  border-radius: 0.5rem;
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 10rem;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;

  .container-buttons {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1280px) {
    padding: 1rem;
  }

  @media (max-width: 768px) {
    padding: 0.5rem;
    .container-buttons {
      display: flex;
      flex-direction: column;

      button {
        width: 100%;
        margin: 0.5rem;
        max-width: 20rem;
      }
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  min-width: 20rem;
`;

export const Image = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const Text = styled.span`
  font-size: 1.7rem;
  color: #083059;
  font-weight: 500;

  ${props =>
    props.date &&
    css`
      color: #707070;
      font-size: 1.3rem;
    `}

  ${props =>
    props.name &&
    css`
      font-weight: 750;
    `}

  ${props =>
    props.star &&
    css`
      display: flex;
      align-items: center;
      color: #dbad00;
      font-size: 1.5rem;
      font-weight: bold;
    `}
`;

export const Button = styled.button`
  ${props =>
    props.curriculum &&
    css`
      height: 4rem;
      padding: 0 2rem;
      font-size: 1.1rem;
      font-weight: 750;
      border: 1px solid #e15757;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #e15757;

      .iconify {
        font-size: 2rem;
        margin-right: 0.5rem;
      }

      @media (max-width: 1280px) {
        padding: 0 1rem;
      }

      @media (max-width: 768px) {
        padding: 0 0.5rem;
      }
    `}

  ${props =>
    props.select &&
    css`
      color: ${props => (props.isSelected ? '#083059' : '#fff')};
      background: ${props => (props.isSelected ? '#fff' : '#083059')};
      height: 4rem;
      width: 10rem;
      font-size: 1.5rem;
      margin-right: 0.5rem;
      padding: 0 1rem;
      border-radius: 0.5rem;
      margin-left: 0.5rem;
      border: ${props => (props.isSelected ? '1px solid #083059' : 'none')};
    `}

  ${props =>
    props.message &&
    css`
      border: 1px solid #083059;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem;
      border-radius: 0.5rem;
      color: #083059;

      .iconify {
        font-size: 1.6rem;
        margin: 0.5rem;
      }

      @media (max-width: 768px) {
        .iconify {
          display: none;
        }
      }
    `}
`;

export const ContainerCalendar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10rem;
  width: 40%;
  margin-top: 1.2rem;
  padding: 0 1.2rem;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.2rem;

  /* flex-wrap: nowrap;
  overflow-x: scroll; */
`;

export const BoxSchedule = styled.div`
  background: #f9f8fe;
  height: 6rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 1.2rem;

  span {
    color: #083059;
    font-size: 1.2rem;
  }

  @media (max-width: 1280px) {
    width: 9rem;
    margin: 0 0.8rem;
  }

  @media (max-width: 768px) {
    width: 9rem;
    margin: 0 0.5rem;
  }
`;

export const TextCalendar = styled.span`
  color: #fff;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;

  ${props =>
    props.top &&
    css`
      margin-bottom: 1rem;
      margin-top: 1rem;
    `}

  .iconify {
    margin: 0 0.5rem;
  }
`;

export const Icon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -1.3rem;
  background: #dbad00;
  padding: 0.3rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;

  .iconify {
    color: #083059;
  }
`;

export const ButtonDates = styled.button`
  font-size: 1.5rem;
  height: 100%;
  color: #083059;
`;

export const ContainerMessage = styled.div`
  height: 46rem;
  margin-top: 1.2rem;

  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;

  .header-message {
    height: 5rem;
    width: 100%;
    background: #f9f9f9;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin-bottom: 1rem;

    span {
      font-size: 1.2rem;
      font-family: 'Montserrat';
      color: #5e5873;
    }

    .iconify {
      font-size: 1.5rem;
      color: #5e5873;
      cursor: pointer;
    }
  }
`;
