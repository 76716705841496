import React, { useState, useEffect, useCallback } from 'react';
import { api } from '../../../services/api';
import { customState } from '../../../utils/customState';
import { Container, Text, Button, ContainerText } from './styles';
import { ModalSubmitStudentPlacementTest } from '../../../components/Modals/ModalSubmitStudentPlacementTest';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks/AuthProvider';

export function PlacementTest(props) {
  const { setShowPlacements, onLevelChange, student } = props;

  const [showTest, setShowTest] = useState(false);
  const [content, setContent] = useState();

  const user = useAuth()
  const location0 = useLocation();
  const location = customState(location0);

  const [currentMethod, setCurrentMethod] = useState();
  const [currentMission, setCurrentMission] = useState();

  const [isSubmitPlacementTestModalOpen, setIsSubmitPlacementTestModalOpen] =
    useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const _methodId = student?.level?.method?.id;
    const _missionId = student?.level?.mission?.id;

    if (_methodId && _missionId) {
      setCurrentMethod({ ...student.level.method });
      setCurrentMission({ ...student.level.mission });
    }
  }, [student]);

  useEffect(() => {
    fetchContent();
  }, [currentMethod, currentMission]);

  const handleSubmit = useCallback(
    async ({ method, mission }) => {
      try {
        await api
          .post('/students/placement-test', {
            method,
            mission,
            student: location.state?.user.student.id,
            teacher: user._id
          })
          .then(response => {
            const { message } = response.data;

            toast.success(message);

            if (method && mission) {
              onLevelChange({ method, mission });
            }
          });
      } catch (err) {
        toast.error('Submit failed');
      } finally {
        setIsSubmitPlacementTestModalOpen(false);
        setShowPlacements(false);
      }
    },
    [location],
  );

  const fetchMethod = useCallback(async (isNext = false) => {
    const response = await api.get('/methods', {
      params: {
        page: isNext ? currentMethod.order + 1 : currentMethod.order - 1,
        limit: 1,
      },
    });

    const { totalDocs, docs } = response?.data;

    if (totalDocs > 0 && docs[0].id) {
      setCurrentMethod(docs[0]);

      const resMissionsNext = await api.get(`/missions/?method=${docs[0].id}&type=class`);
      if (resMissionsNext?.data?.docs?.length > 0) {

        if (isNext) {
          setCurrentMission(resMissionsNext.data.docs[0])
        } else {
          setCurrentMission(resMissionsNext.data.docs[resMissionsNext.data.docs.length - 1])
        }
      }
    }
  }, [currentMethod]);

  const fetchMission = useCallback(async (next = true) => {

    const { data } = await api.get(`/missions/?method=${currentMethod.id}&type=class`);

    let _nextMission = null;

    const indexFound = data?.docs?.findIndex(e => e.id === currentMission.id);
    if (indexFound >= 0) {
      const nextIndex = next ? indexFound + 1 : indexFound - 1;
      if (data?.docs?.length > nextIndex) {
        _nextMission = data?.docs[nextIndex]
      } else {
        fetchMethod(true);
      }
    }

    if (_nextMission) {
      setCurrentMission(_nextMission);
    }
  }, [currentMethod, currentMission]);

  const fetchContent = async () => {
    if (!currentMethod?._id && !currentMission?._id) return;

    const response = await api.get(`/contents`, {
      params: {
        type: 'review',
        limit: 1,
        method: currentMethod?._id,
        mission: currentMission?._id,
      },
    });

    const { totalDocs, docs } = response?.data;

    if (totalDocs > 0) {
      setContent(docs[0]);
    } else {
      if (!content) {
        handleGetNextMission()
      } else {
        setContent({});
      }
    }
  };

  function handleGetNextMission() {
    fetchMission(true);
  }

  function handleGetPreviousMethod() {
    if (currentMethod && currentMethod.order > 1) {
      fetchMethod(false);
    }
  }

  function handleGetPreviousMission() {
    if (currentMission && currentMission.order > 1) {
      fetchMission(false);
    } else {
      handleGetPreviousMethod();
    }
  }

  const backHomeMissionLevel = () => {
    const _methodId = student?.level?.method?.id;
    const _missionId = student?.level?.mission?.id;

    if (_methodId && _missionId) {
      setCurrentMethod({ ...student.level.method });
      setCurrentMission({ ...student.level.mission });
    }
  }

  return (
    <>
      <Container>
        <header>
          <div className="column">
            <Button
              left
              onClick={() => {
                backHomeMissionLevel();
              }}
            >
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>

            {content && (
              <ContainerText>
                <Text finaly>
                  {currentMethod?.title} - {currentMission?.title}
                </Text>
              </ContainerText>
            )}
          </div>
          <div className="column">
            {!(currentMethod?.id === student?.level?.method?.id && currentMission?.id === student?.level?.mission?.id) && (
              <Button left onClick={handleGetPreviousMission}>
                {' '}
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-left-alt2"
                  data-inline="false"
                />{' '}
              </Button>
            )}

            <Button finaly onClick={handleGetNextMission}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
                data-inline="false"
              />{' '}
            </Button>
          </div>
        </header>
        {!showTest && (
          <section className="not-active-list">
            <article>
              Placing a student at the right level is beneficial to the learner
              and teacher.
              <br />
              <br />
              We have created a placement test to help you place the student at
              their right English level, accurately and systematically.
              <br />
              <br />
              You should stop the test when you realize your student is
              producing the structures incorrectly or missing some words while
              answering. For example: I go ? school in the morning... She don't
              work anymore... I'm not study now...
              <br />
              <br />
              Whenever you fell that any question you have to ask during the
              units has nothing to do with your client's life, be flexible to
              formulate another one that is more appropriate to his reality
              using the same structure.
              <br />
              <br />
              If you have any question, please contact us at{' '}
              <strong>hello@tabletalks.com.br</strong>
            </article>
            <div>
              <Button
                onClick={() => setShowPlacements(false)}
                style={{ marginRight: '10px' }}
              >
                {t('cancelar')}
              </Button>
              <Button onClick={() => setShowTest(true)}>{t('startNow')}</Button>
            </div>
          </section>
        )}

        {showTest && content && (
          <>
            <section className="active-list">
              {content.questions?.map((question, i) => (
                <div key={question?._id} className="list">
                  <Text>
                    {i + 1} - {question.text}
                  </Text>
                </div>
              ))}
              <div className="actions">
                <Button
                  cancel
                  onClick={() => {
                    setShowTest(false);
                  }}
                >
                  {t('cancelar')}
                </Button>
                <Button
                  close
                  onClick={() => {
                    setIsSubmitPlacementTestModalOpen(true);
                    // setSeletion('0');
                    // setShowPlacements(false);
                  }}
                >
                  Finalizar
                </Button>
              </div>
            </section>
          </>
        )}
      </Container>
      {isSubmitPlacementTestModalOpen && (
        <ModalSubmitStudentPlacementTest
          isOpen={isSubmitPlacementTestModalOpen}
          suggestedMethod={currentMethod}
          suggestedMission={currentMission}
          studentLevel={{ method: student?.level?.method, mission: student?.level?.mission }}
          onSubmit={handleSubmit}
          onRequestClose={() => {
            setIsSubmitPlacementTestModalOpen(false);
          }}
        />
      )}
    </>
  );
}
