import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 29rem;
  min-width: 29rem;
  margin-top: 1.2rem;

  @media (max-width: 1200px) {
    display: grid;
    width: 100%;
  }
`;

export const ContainerProfile = styled.div`
  width: 100%;
  height: 35rem;
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid var(--gray3);

  padding: 1rem;

  @media (max-width: 1200px) {
    display: grid;
    grid-template-areas:
      't t t'
      'c1 c2 c3'
      'f1 f2 f3';
    gap: 2rem;
    grid-auto-rows: min-content;
    height: auto;
  }

  @media (max-width: 500px) {
    display: block;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 1rem;

  .badge {
    height: 1.4rem;
    width: 1.4rem;
    background: #28c76f;
    border-radius: 50%;
    position: absolute;
    border: 2px solid #fff;
    bottom: 0;
    left: 5rem;
  }

  div {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    line-height: 2.3rem;
    padding: 0;

    span + span {
      font-size: 1.5rem;
      color: #b9b9c3;
      font-weight: 500;
      margin-left: 0;
    }
  }

  @media (max-width: 1200px) {
    grid-area: 't';
  }
`;

export const ImageProfile = styled.img`
  height: 5.6rem;
  width: 5.6rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const ContainerTextList = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 3.5rem;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  span {
    font-size: 1.3rem;
  }

  div {
    padding-left: 1rem;
    width: 3.5rem;
    border-left: 1px solid #70707010;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .book {
    padding-left: 2rem;
    width: 10rem;
    border-left: 1px solid #70707010;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1200px) {
    ${props =>
      props.gridArea &&
      css`
        grid-area: ${props.gridArea};
      `}
  }
`;

export const Text = styled.span`
  color: #6e6b7b;
  font-size: 1.7rem;
  font-weight: 650;
`;

export const ContainerPayment = styled.div`
  margin-top: 1.2rem;
  width: 100%;
  height: 20rem;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid var(--gray3);

  display: flex;
  flex-direction: column;

  span {
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }

  @media (max-width: 500px) {
    height: auto;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  width: 100%;

  @media (max-width: 1200px) {
    gap: 1rem;
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

export const Button = styled.button`
  height: 3rem;
  width: 100%;
  font-weight: 650;
  font-size: 1.3rem;
  color: ${props =>
    props.checked ? '1px solid #083059' : '1px solid #6e6b7b'};
  border: ${props =>
    props.checked ? '1px solid #083059' : '1px solid var(--gray3)'};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .cards {
    height: 1.5rem;
    width: 1.7rem;
    margin-right: 0.5rem;
  }

  .pix-image {
    height: 1.4rem;
    width: 1.4rem;
    margin-right: 0.5rem;
  }

  .code-bar {
    height: 1.1rem;
    width: 1.1rem;
    margin-right: 0.5rem;
  }

  ${props =>
    props.submitPay &&
    css`
      height: 3.5rem;
      width: 100%;
      background: #083059;
      color: #fff;
      font-weight: 500;
      font-size: 1.5rem;
    `}

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Select = styled.select`
  height: 3.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--gray3);
  color: #6e6b7b;
  font-weight: 650;
  font-size: 1.3rem;
  padding: 0 1rem;
  margin: 1rem 0 1.5rem 0;
`;

export const ContainerVideo = styled.div`
  width: 100%;
  max-height: 73rem;

  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  margin-top: 1.2rem;
`;

export const ContainerTitleVideo = styled.div`
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid #70707010;

  display: flex;
  align-items: center;

  span {
    font-size: 2rem;
    margin-left: 2rem;
  }
`;

export const WrapperVideos = styled.div`
  width: 100%;
  max-height: 68rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.6rem;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #083059;
    border-radius: 1rem;
  }
  ::selection {
    background-color: #083059;
  }
`;

export const ContainerContentVideo = styled.div`
  height: auto;
  width: 23rem;
  margin-top: 1.2rem;

  display: flex;
  flex-direction: column;

  span {
    margin-top: 1.2rem;
  }
`;
