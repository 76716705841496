/* eslint-disable react/button-has-type */
/* eslint-disable arrow-body-style */
import { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { api } from '../../../../services/api';
import { ListEbooks } from './ListEbooks';
import { ListVideos } from './ListVideos';
// import { Button } from '../../../../components/Button';
import {
  Container,
  ContainerHeader,
  Text,
  Input,
  Sections,
  ContainerColumn2,
  ContainerColumn2Header,
  ContainerColumn2HeaderText,
  ContainerColumn2Body,
} from './styles';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  ButtonPass,
  ContainerPDFControls,
} from '../../../TeachingKnowledgeTest/Pdf/styles';
import { customState } from '../../../../utils/customState';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function ReadBook() {
  const [ebooks, setEbooks] = useState([]);
  const [videos, setVideos] = useState([]);
  const [pdfActivePage, setPdfActivePage] = useState(1);
  const [file, setFilePdf] = useState(1);
  const [pdfNumberOfPages, setPdfNumberOfPages] = useState(0);
  const containerRef = useRef(null);
  const history = useHistory();
  const location0 = useLocation();
  const history2 = customState(location0);
  const { t } = useTranslation();

  const loadGuidelines = useCallback(async () => {
    try {
      const { data } = await api.get('/guidelines');

      const ebooks = data.filter(item => item.type === 'ebook');
      const videos = data.filter(item => item.type === 'video');

      const ebook = ebooks.filter(
        itemEbook => itemEbook.id === history2.state.id,
      );

      setFilePdf(ebook[0].fileURL);
      setEbooks(ebooks);
      setVideos(videos);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    loadGuidelines();
  }, [loadGuidelines]);

  const handleDocumentLoadSuccess = useCallback(({ numPages }) => {
    setPdfNumberOfPages(numPages);
  }, []);

  const setEbookFile = useCallback(async file => {
    setFilePdf(file);
  }, []);

  const [page, setPage] = useState({});

  const calculateScale = ({ originalWidth, originalHeight }) => {
    const globalScale = 1;

    if (!page || !containerRef.current) {
      return globalScale;
    }

    const { clientWidth, clientHeight } = containerRef.current;

    const scaleX = clientWidth / originalWidth;
    const scaleY = clientHeight / originalHeight;

    // return globalScale * Math.min(scaleX, scaleY) - 0.045;
    return globalScale;
  };

  return (
    <center>
      <Container>
        <ContainerHeader>
          <Text title>{ebooks.title}</Text>
          <Input placeholder={t('pesquisar')} />
        </ContainerHeader>
        <Sections>
          <ListEbooks data={ebooks} setEbookFile={setEbookFile} />
          <ContainerColumn2>
            <ContainerColumn2Header>
              <ContainerColumn2HeaderText>
                {ebooks.description}
              </ContainerColumn2HeaderText>
              <Link to="/dashboard/guidelines">X</Link>
            </ContainerColumn2Header>

            <ContainerColumn2Body>
              <Container ref={containerRef}>
                <Document file={file} onLoadSuccess={handleDocumentLoadSuccess}>
                  {/* <a className="button" href={file} target="_blank" rel="noopener noreferrer">
                Open in New Tab
              </a> */}
                  <Page
                    tar
                    scale={calculateScale(page)}
                    pageNumber={pdfActivePage}
                    width={window.innerWidth > 768 ? ((window.innerWidth / 2) - 60) : (window.innerWidth - 60)}
                    onLoadSuccess={value => {
                      setPage(value);
                    }}
                  />
                </Document>

                <ContainerPDFControls>
                  <ButtonPass
                    disabled={pdfActivePage <= 1}
                    onClick={() => {
                      setPdfActivePage(prevState => prevState - 1);
                    }}
                  >
                    <span
                      className="iconify"
                      data-icon="dashicons:arrow-left-alt2"
                    />
                  </ButtonPass>
                  <ButtonPass
                    next
                    disabled={pdfActivePage === pdfNumberOfPages}
                    onClick={() => {
                      setPdfActivePage(prevState => prevState + 1);
                    }}
                  >
                    <span
                      className="iconify"
                      data-icon="dashicons:arrow-right-alt2"
                    />
                  </ButtonPass>
                </ContainerPDFControls>
                <span>
                  {pdfActivePage} {t('of')} {pdfNumberOfPages}
                </span>
              </Container>
            </ContainerColumn2Body>
          </ContainerColumn2>
          <ListVideos data={videos} />
        </Sections>
      </Container>
    </center>
  );
}
