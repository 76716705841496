import styled, { css } from 'styled-components';

interface ContainerProps {
  isLoading?: boolean;
}

interface SubmitButtonProps {
  isLoading?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${(props) => props.isLoading
    && css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
      width: 100%;
    `};

  p {
    color: #243342;
    font-weight: 500;
    font-size: 19px;
    margin-bottom: 4rem;
    height: 48px;
    border-bottom: #707070 solid 1px;
  }

  form {
    display: flex;
    flex-direction: column;

    .column {
      display: flex;
      flex-direction: column;
    }
    .row {
      display: flex;
      flex-direction: row;
    }
  }

  label {
    color: #243342;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  input {
    margin-bottom: 1rem;
  }

  select {
    height: 45px;
    width: 100%;
    border: 1px solid var(--gray3);
    background: #fff;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 1rem;
  }
`;

export const SubmitButton = styled.button<SubmitButtonProps>`
  width: 199px;
  padding: 0 1.5rem;
  height: 44px;
  background: #243342;
  color: #ffffff;
  border-radius: 0.25rem;
  border: 0;
  font-size: 1.2rem;
  margin-top: 1.5rem;
  font-weight: 600;
  transition: filter 0.2s ease;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    background: #ccc;
    border-color: #ccc;
    box-shadow: none;
  }

  ${(props) => props.isLoading
    && css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`;

export const ErrorMessage = styled.p`
  align-self: flex-start;
  padding-left: 1rem;
  font-size: 1.3rem;
  color: #f00;
`;
