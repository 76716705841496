import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider } from 'react-cookie';
import { MethodProvider } from './context/MethodContext';
import { NoScreenshotProvider } from './context/NoScreenshotContext';
import { OnlineStatusProvider } from './context/OnlineStatusContext';
import AuthProvider from './hooks/AuthProvider';
import Routes from './routes';
import GlobalStyles from './styles/global';

const client = new ApolloClient({
  uri: 'https://gql.tabletalks.com.br/v1/graphql',
  headers: {
    'x-hasura-admin-secret': 'beca2182-48b5-485c-958c-d01c31157e09',
  },
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <ToastContainer theme="colored" />
      <NoScreenshotProvider>
        <CookiesProvider>
          <AuthProvider>
            <MethodProvider>
              <OnlineStatusProvider>
                <GlobalStyles />
                <Router>
                  <Routes />
                </Router>
              </OnlineStatusProvider>
            </MethodProvider>
          </AuthProvider>
        </CookiesProvider>
      </NoScreenshotProvider>
    </ApolloProvider>
  );
}

export default App;
