import React from 'react';

import { SentMessagem, TextMessage, ContainerMessage } from './styles';

export function Sent({ content, imageURL = '' }) {
  if (!content) return false;

  return (
    <ContainerMessage>
      {imageURL != ''
        ? <img src={imageURL} alt="" />
        : <img src="https://tabletalks-method.s3.sa-east-1.amazonaws.com/users/avatar.png" alt="" />
      }
      <SentMessagem>
        <TextMessage gray>{content}</TextMessage>
      </SentMessagem>
    </ContainerMessage>
  );
}
