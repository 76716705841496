import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 0 2rem;
`;

export const ContainerTypes = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 5.8rem;
  width: 100%;
`;

export const ContainerSpace = styled.div`
  width: 13rem;
  margin-right: 2rem;
`;

export const TitleType = styled.span`
  font-size: 1.5rem;
  color: #5e5873;
  font-weight: 650;

  ${(props) =>
    props.title &&
    css`
      font-size: 1.3rem;
    `}
`;

export const ListFinancial = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid var(--gray3);
`;
