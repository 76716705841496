/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import * as yup from 'yup';

// import * as yup from 'yup';
import { api } from '../../../services/api';
import { Container, SubmitButton } from './styles';
import './styles/styles.css';

Modal.setAppElement('#root');

type Method = {
  id: string;
  _id: string;
  title: string;
  order: number;
};

type Mission = {
  id: string;
  _id: string;
  title: string;
  order: number;
};

interface FormData {
  method: string;
  mission: string;
}

interface ModalSubmitStudentPlacementTestProps {
  isOpen: boolean;
  suggestedMethod: Method;
  suggestedMission: Mission;
  studentLevel?: { method: Method; mission: Mission };
  onSubmit: (data: FormData) => void;
  onRequestClose: () => void;
}

function ModalSubmitStudentPlacementTest({
  isOpen,
  suggestedMethod,
  suggestedMission,
  studentLevel,
  onSubmit,
  onRequestClose,
}: ModalSubmitStudentPlacementTestProps) {
  const [methods, setMethods] = useState<Method[]>();
  const [missions, setMissions] = useState<Mission[]>();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      method: studentLevel?.method?._id ?? '',
      mission: studentLevel?.mission?._id ?? '',
    },
    validationSchema: yup.object().shape({
      method: yup.string().required('O método é obrigatório'),
      mission: yup.string().required('O método é obrigatório'),
    }),
    onSubmit,
  });

  const fetchMethods = useCallback(async () => {
    await api.get('/methods').then((response) => {
      let _data = [...response?.data?.docs];

      if (Array.isArray(response?.data?.docs) && studentLevel?.method?._id) {
        const found = response.data.docs.find((e: Method) => e._id === studentLevel?.method?._id);

        if (found?.order) {
          _data = response.data.docs.filter((e: Method) => e.order >= found.order);
        }
      }

      setMethods(_data);
    });
  }, []);

  const fetchMissions = useCallback(async () => {
    if (!formik?.values?.method) return;
    await api
      .get('/missions?type=class', {
        params: {
          method: formik.values.method,
        },
      })
      .then((response) => {
        let _data = [...response?.data?.docs];

        if (Array.isArray(response?.data?.docs) && studentLevel?.mission?._id) {
          const found = response.data.docs.find((e: Mission) => e._id === studentLevel?.mission?._id);

          if (found?.order) {
            _data = response.data.docs.filter((e: Mission) => e.order > found.order);
          }
        }

        setMissions(_data);

        if (_data.length > 0 && formik) {
          formik.setFieldValue('mission', _data[0]?.id);
        }
      });
    // eslint-disable-next-line
  }, [formik?.values?.method]);

  useEffect(() => {
    fetchMethods();
  }, [fetchMethods]);

  useEffect(() => {
    fetchMissions();
  }, [fetchMissions]);

  useEffect(() => {
    if (!studentLevel?.method?._id) {
      api.get('/methods').then((response) => {
        setMethods(response.data.docs);
        formik.setFieldValue('method', response.data.docs[0]?.id);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="request-withdraw-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <span className="iconify" data-icon="carbon:close" />
      </button>
      <Container>
        <p>{t('basedEvalutionPlace')}</p>

        <p>
          {t('weSuggest')}
          {' '}
          {suggestedMethod?.title}
          ,
          {' '}
          {suggestedMission?.title}
        </p>
        <form onSubmit={formik.handleSubmit}>
          <div className="column">
            <label className="label">{t('method')}</label>
            <div className="row">
              <select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.method}
                placeholder={t('selectMethod')}
                name="method"
                disabled={!formik.values.method}
              >
                <option value="" disabled selected>
                  {t('method')}
                </option>
                {methods?.map((m) => (
                  <option value={m?._id}>{m?.title}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="column">
            <label className="label">{t('mission')}</label>
            <div className="row">
              <select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mission}
                placeholder={t('selectMission')}
                name="mission"
              >
                <option value="" disabled selected>
                  {t('mission')}
                </option>
                {missions?.map((m) => (
                  <option value={m?._id}>{m?.title}</option>
                ))}
              </select>
            </div>
          </div>

          <SubmitButton
            type="submit"
            disabled={!formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {formik.isSubmitting ? (
              <Loader
                visible
                type="ThreeDots"
                color="#fff"
                secondaryColor="#191919"
                height={10}
              />
            ) : (
              'Submit'
            )}
          </SubmitButton>
        </form>
      </Container>
    </Modal>
  );
}

export { ModalSubmitStudentPlacementTest };
