import styled, { css } from 'styled-components';

export const Container = styled.input`
  height: 4rem;
  width: 100%;
  border: 1px solid var(--gray3);
  background: #fff;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 0.8rem;

  ${(props) =>
    props.withError &&
    css`
      border: 1px solid #f00;
      margin-bottom: 2px;
    `};
`;

export const Error = styled.p`
  align-self: flex-start;
  padding-left: 1rem;
  font-size: 1.3rem;
  color: #f00;
`;
