import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { rgba } from 'polished'

export const ContainerLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--gray3);
`

export const ButtonBack = styled.button`
  display: flex;
  align-items: center;
  background-color: #064944;
  border-radius: .5rem;
  padding: 1rem;
  color: #fff;
  &:hover {
    background-color: ${ rgba('#064944', .8) };
  }
`;

export const Title = styled.h1`
  flex: 1;
`;

export const List = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100rem;
  border-radius: .5rem;
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 1rem;
  
  ${prop => 
    (prop.color) 
    ? css`
      background-color: ${ rgba(prop.color, 1) };
      &:hover {
        background-color: ${ rgba('#fff', .7) };
      }
    `
    : css`
      background-color: #06231F;
      opacity: .2;
      pointer-events: none;
    `
  };
`;

export const Text = styled.div`
  flex: 1;
  text-align: center;
  padding: 1rem;
  
  ${prop => 
    (prop.color) 
    ? css`
      color: ${ rgba(prop.color, 1) };
      &:hover {
        color: #000;
      }
    `
    : css`
      color: #fff;
    `
  };
`;