import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

export const Container = styled(PhoneInput).attrs((props) => ({
  inputStyle: {
    width: '100%',
    borderRadius: '0.4rem',
    border: `1px solid ${props.withError ? '#f00' : '#EBE9F1'}`,
  },
  buttonStyle: { border: '1px solid #EBE9F1' },
}))``;

export const Error = styled.p`
  align-self: flex-start;
  padding-left: 1rem;
  font-size: 1.3rem;
  color: #f00;
`;
