import styled, { css } from 'styled-components';
import Modal from 'react-modal';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f9f9f9;

  overflow: auto;
`;

export const ModalMission = styled(Modal)`
  overflow-y: auto;
  height: 100%;
`;

export const Wrapper = styled.div`
  margin: 1.2rem;
`;

export const Content = styled.div`
  padding: 0 1.2rem 1.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const ClockContainer = styled.div`
  padding: 0 10px;
  width: 100%;
  text-align: center;
`;

export const Clock = styled.div`
  font-size: 20px;
  color: red;
  font-weight: bold;
`;

export const Text = styled.span`
  color: #5e5873;
  font-size: 2rem;
  width: 100%;

  ${props =>
    props.title &&
    css`
      width: 100%;
      border-bottom: 1px solid var(--gray3);
      text-align: center;
      padding-bottom: 2rem;
      margin-bottom: 1rem;
    `}

  ${props =>
    props.text &&
    css`
      /* width: 100%; */
    `}

  ${props =>
    props.error &&
    css`
      color: red;
    `}
`;

export const ContainerModal = styled.div`
  background: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;

  textarea {
    width: 100%;
    height: 12rem;
    border: 1px solid var(--gray3);
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0.5rem 0;
    outline: none;
    resize: none;
  }

  form {
    padding: 0 0 1rem 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .container-img {
    display: flex;
  }

  .imageConference {
    display: flex;
    width: 70%;
  }
  .localDisplay {
    display: flex;
    width: 50%;
  }
  .remoteDisplay {
    display: flex;
    width: 50%;
  }

  .imageConference img {
    display: flex;
    max-width: 50%;
  }

  .container-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 6rem;
      border: 1px solid var(--gray3);
      border-radius: 0.5rem;
      padding: 0 6rem;
      font-size: 2rem;
      color: #243342;
      cursor: pointer;

      :hover {
        filter: brightness(0.8);
      }
    }

    .active-button {
      background: #243342;
      color: #fff;
    }

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      width: 100%;
    }

    @media (max-width: 560px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
  }

  .button {
    width: 100%;
    height: 6rem;
    background: #243342;
    color: #fff;
    margin-top: 1rem;
    border-radius: 0.5rem;
    font-size: 2rem;
    cursor: pointer;

    :hover {
      filter: brightness(0.8);
    }
  }
`;

export const Form = styled.form``;

export const Textarea = styled.textarea``;

export const ContainerButtons = styled.div``;

export const Button = styled.button``;
