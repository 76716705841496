import { useTranslation } from 'react-i18next';
import ThumbnailPdf from '../../../../assets/thumbnail-pdf.png';
import {
  Text,
  ContainerColumn1,
  ContainerColumn1Item,
  ContainerColumn1Title,
  ContainerColumn1Body,
  ContainerColumn1Button,
} from './styles';

export function ListEbooks({ data = [], setEbookFile }) {
  function handleOnclick(file) {
    setEbookFile(file);
  }

  const { t } = useTranslation();

  return (
    <ContainerColumn1>
      {data.map(book => (
        <ContainerColumn1Item>
          <img
            src={
              (book?.thumbnailURL?.includes('.pdf')
                ? ThumbnailPdf
                : book?.thumbnailURL) || ThumbnailPdf
            }
            alt="ebook"
          />
          <ContainerColumn1Title>
            <Text title>
              {book.title?.toString()?.substring(0, 50)}{' '}
              {book.title?.toString()?.length > 50 ? '...' : ''}
            </Text>
          </ContainerColumn1Title>
          <ContainerColumn1Body>
            <Text>
              {book.description?.toString()?.substring(0, 150)}{' '}
              {book.description?.toString()?.length > 150 ? '...' : ''}
            </Text>
          </ContainerColumn1Body>
          <ContainerColumn1Button>
            <button onClick={e => handleOnclick(book.fileURL)}>{t('read')}</button>
          </ContainerColumn1Button>
        </ContainerColumn1Item>
      ))}
    </ContainerColumn1>
  );
}
