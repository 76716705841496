/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';

interface ContainerProps {
  isOpenPlanning: boolean;
}

interface BookProps {
  isOpenPlanning: boolean;
}

interface ImageBookProps {
  isOpenPlanning: boolean;
}

interface ArrowProps {
  isOpenPlanning: boolean;
}

interface ContainerPlanningProps {
  isOpenPlanning: boolean;
}

interface ClockProps {
  clock?: boolean;
}

interface PlanningProps {
  disabled?: boolean;
}

interface ItemsProps {
  isVisible?: boolean;
}

interface Items2Props {
  isOpenPlanning?: boolean;
}

interface ListInfoProps {
  isOpenPlanning?: boolean;
}

interface InfoProps {
  line?: boolean;
  option?: boolean;
  icon?: boolean;
}

interface IButtonPlanning {
  disabled?: any;
}

export const Container = styled.div<ContainerProps>`
  /* height: 7rem; */
  width: 100%;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin-top: 1.2rem;

  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 1100px) {
    ${({ isOpenPlanning }) => isOpenPlanning
      && css`
        display: grid;
        grid-template-columns: 1fr;
      `}
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const ContainerBook = styled.div<BookProps>`
  height: 4.8rem;
  width: 15rem;
  background: #f6f6f6;
  padding: 0 1rem;
  border-right: 1px solid var(--gray3);
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  position: relative;

  margin-right: 2rem;

  /* @media (max-width: 1702px) {
    height: 12rem;
  } */

  @media (max-width: 1100px) {
    ${({ isOpenPlanning }) => isOpenPlanning
      && css`
        width: 100%;
      `}
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ImageBook = styled.img<ImageBookProps>`
  margin-right: 2rem;

  @media (max-width: 1100px) {
    ${({ isOpenPlanning }) => isOpenPlanning
      && css`
        display: grid;
        grid-template-columns: 1fr;
        justify-content: space-between;
      `}
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
  }
`;

export const ContainerTextsBook = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.5rem;
    font-weight: 900;
    color: #243342;
  }

  span + span {
    font-size: 1.2rem;
    font-weight: 500;
    color: #707070;
  }
`;

export const Text = styled.span``;

export const Arrow = styled.div<ArrowProps>`
  border-top: 1px solid var(--gray3);
  border-right: 1px solid var(--gray3);
  background: var(--gray5);
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  margin-left: 16px;
  position: absolute;
  right: -8px;

  @media (max-width: 1100px) {
    ${({ isOpenPlanning }) => isOpenPlanning
      && css`
        display: none;
      `}
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ContainerPlanning = styled.div<ContainerPlanningProps>`
  display: flex;
  align-items: center;
  height: 3.5rem;
  width: 100% auto;
  border-radius: 0.4rem;
  /* margin-right: 1rem; */

  /* @media (max-width: 1702px) {
    display: grid;
    grid-template-areas:
      'icon hour'
      'button button';
    height: auto;
    justify-content: center;
    justify-items: center;
    grid-row-gap: 2.5rem;
  } */

  @media (max-width: 1100px) {
    ${({ isOpenPlanning }) => isOpenPlanning
      && css`
        width: 100%;
        height: auto;
      `}
  }

  @media (max-width: 768) {
    width: 100%;
    display: grid;
    grid-template-areas: 'icon hour button';
    grid-template-rows: 1fr;
    height: auto;
    grid-column-gap: 2rem;
    grid-row-gap: 0;
  }
`;

export const ContainerItems = styled.div<ItemsProps>`
  display: flex;
  align-items: center;
  height: 3.5rem;
  width: 100% auto;
  border-radius: 0.4rem;
  margin-right: 1rem;

  /* @media (max-width: 1702px) {
    display: grid;
    height: 12rem;
    justify-content: center;
    align-items: center;
  } */

  @media (max-width: 1100px) {
    display: grid;
    width: auto;
    margin: 0 1rem;
    align-content: space-evenly;
    grid-template-columns: 1fr;
    height: ${(props) => (props.isVisible ? 'auto' : '12rem')};
  }
`;

export const ContainerItems2 = styled.div<Items2Props>`
  display: flex;
  align-items: center;
  height: 3.5rem;
  width: 100% auto;
  border-radius: 0.4rem;
  margin-right: 1rem;

  /* ${(props) => props.isOpenPlanning
    && css`
      display: grid;
      align-content: space-around;
      margin-right: 0;
    `} */

  /* @media (max-width: 1702px) {
    display: grid;
    height: 12rem;
    justify-content: center;
    align-items: center;
  } */

  /* @media (max-width: 1100px) {
    display: grid;
    width: auto;
    margin: 0 1rem;
    align-content: space-evenly;
    grid-template-columns: 1fr;
  } */
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  /* @media (max-width: 1702px) {
    width: 100%;
    grid-area: icon;
  } */
`;

export const ImageClock = styled.img<ClockProps>`
  padding: 0.3rem 1.5rem;
  border-radius: 0.4rem 0 0 0.4rem;
  background: #225444;

  ${(props) => props.clock
    && css`
      background-image: linear-gradient(to bottom, #fdfdfd, #dcdcdc);
      border-right: 1px solid #ccc;
      border-top: 1px solid var(--gray3);
    `}

  @media(max-width: 1440px) {
    padding: 0.1rem 1.4rem;
  }
`;

export const ContainerHour = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  background-image: linear-gradient(to bottom, #fdfdfd, #dcdcdc);

  span {
    font-size: 1.25rem;
    font-weight: 600;
    color: #243342;
  }

  span + span {
    font-size: 1.2rem;
    font-weight: 500;
  }

  @media (max-width: 1100px) {
    width: 100%;
    grid-area: hour;
  }
`;

export const ContainerTextPlanning = styled.div<PlanningProps>`
  padding: 0 1rem;
  border: 1px solid var(--gray3);
  height: 3.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 1.25rem;
  }

  ${(props) => props.disabled
    && css`
      span {
        color: #ebe9f1;
      }
    `}
`;

export const Button = styled.button`
  padding: 0 1rem;
  border: 1px solid var(--gray3);
  color: #243342;
  margin: 0 1rem;
  font-size: 1.25rem;
  height: 3.5rem;
  border-radius: 0.4rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  .iconify {
    margin: 0 0.5rem;
  }
`;

export const ButtonNoBorder = styled.button`
  padding: 0 1rem;
  color: #243342;
  margin: 0 1rem;
  font-size: 1.25rem;
  height: 3.5rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  .iconify {
    margin: 0 0.5rem;
  }
`;

export const ContainerListInfo = styled.div<ListInfoProps>`
  display: flex;
  justify-content: flex-end;
  /* width: 100%; */
  position: absolute;
  right: 1rem;

  /* @media (max-width: 1080px) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 4fr 4fr 1fr;
    max-width: 100%;
    position: relative;
    margin: 1rem;
    padding-left: 1rem;
  } */

  /* @media (max-width: 1702px) {
    display: grid;
    justify-items: center;
  } */

  @media (max-width: 1100px) {
    ${({ isOpenPlanning }) => isOpenPlanning
      && css`
        position: revert;
      `}
  }

  @media (max-width: 768px) {
    position: revert;
  }
`;

export const ContainerInfo = styled.div<InfoProps>`
  color: #707070;
  font-size: 1.4rem;
  padding: 0 1rem;

  display: flex;
  align-items: center;

  .iconify {
    font-size: 1.25rem;
    margin-right: 0.5rem;
  }

  ${(props) => props.line
    && css`
      border-left: 1px solid var(--gray3);
      border-right: 1px solid var(--gray3);
    `}

  ${(props) => props.option
    && css`
      border-right: 1px solid var(--gray3);
    `}

  ${(props) => props.icon
    && css`
      .iconify {
        color: #2b493f;
        margin-right: 0;
      }
    `}

  @media (max-width: 1100px) {
    padding: 0 1rem;
  }
`;

export const Select = styled.select`
  padding: 0 1rem;
  border: 1px solid var(--gray3);
  color: #243342;
  margin: 0 1rem;
  font-size: 1.25rem;
  height: 3.5rem;
  max-width: 150px;
  border-radius: 0.4rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1100px) {
    display: grid;
    position: relative;
    max-width: 100%;
  }
`;

export const ButtonPlanning = styled.button<IButtonPlanning>`
  padding: 0 1rem;
  border: 1px solid var(--gray3);
  height: 3.5rem;
  max-width: 150px;
  margin: 0 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 1.25rem;
  }

  ${(props) => props.disabled
    && css`
      span {
        color: #ebe9f1;
      }
    `}

  /* @media(max-width: 1702px) {
    grid-area: button;
    width: 100%;
    max-height: 120px;
    padding: 0 1rem;
  } */

  @media (max-width: 1100px) {
    position: relative;
    max-width: 100%;
  }
`;
