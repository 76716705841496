import * as React from 'react';

function Loader() {
  return (
    <div id="loaderSection">
      <div
        style={{
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ textAlign: 'center' }} className="loaderText">
          Carregando câmera
          <br />
          e aúdio...
        </div>
      </div>
    </div>
  );
}

export default Loader;
