import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ButtonPassProps {
  next?: boolean;
  disabled?: boolean;
}

interface TextTopProps {
  button?: boolean;
  quetions?: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: #f9f9f9;
  overflow: hidden;
`;

export const ContainerTop = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid var(--gray3);
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Text = styled.span<TextTopProps>`
  font-size: 1.8rem;
  color: #5e5873;
  font-weight: 600;
  ${(props) => props.button
    && css`
      font-weight: 500;
      color: #243342;
      font-size: 1.5rem;
      padding: 0 2rem;
    `}
  ${(props) => props.quetions
    && css`
      padding: 2rem 0;
    `}
`;

export const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 24px 0;
`;

export const Content = styled.div`
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  @media (min-width: 0px) {
    max-width: 1200px;
  }
  @media (min-width: 600px) {
    margin: 0 24px;
  }
`;

export const ContainerRoutes = styled.div`
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
`;

export const Title = styled.div`
  display: flex;
  font-size: 2rem;
  color: #5e5873;
  font-weight: 650;
`;

export const NavButton = styled(NavLink)`
  display: flex;
  color: black;
  padding: 1.5rem 1.5rem;
  border-right: 1px solid #00000012;
  user-select: none;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  font-size: 2rem;
  color: #5e5873;

  &:hover {
    background-color: #ccc;
  }

  &.active {
    background-color: #ccc;
  }
`;

export const ContainerButton = styled.div`
  margin-top: 5rem;
  border-top: 1px solid var(--gray3);
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 1rem;
`;

export const ButtonPass = styled.button<ButtonPassProps>`
  height: 5rem;
  width: 15rem;
  border-radius: 0.4rem;
  background: #ebe9f1;
  color: #243342;
  margin-right: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) => props.disabled
    && css`
      background: ${props.disabled ? '#cccccc' : '#ebe9f1'};
      color: #fff;
    `}

  ${(props) => props.next
    && css`
      background: ${props.disabled ? '#cccccc' : '#243342'};
      color: #fff;
    `}
`;
