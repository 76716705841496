/* eslint-disable @typescript-eslint/no-explicit-any */
import { utcToZonedTime, format } from 'date-fns-tz';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Coffe from '../../../assets/svgMethod/icon_coffe.svg';
import Headset from '../../../assets/svgMethod/icon_headset.svg';
import Watch from '../../../assets/svgMethod/icon_watch_colapse.svg';
import { CollapseSchedule } from '../../../components/Button/CollapseSchedule';
import { ListPerformance } from '../../../components/Performance';
import { ListPresence } from '../../../components/Presence';
import { api } from '../../../services/api';
import { Container } from '../Guideline/ReadBook/styles';
import { Appointment, Attendee } from '../Schedule';
import {
  Text,
  List,
  ContainerIcon,
  ContainerHour,
  ContainerUser,
  Image,
  ContainerTextsUser,
  ContainerInfoLesson,
  ContainerTextsInfoLesson,
  Icon,
  ContainerButtons,
  Button,
} from './styles.listSchedule';

interface StateProps {
  appointments: Appointment[];
  fetchAppointments: () => void;
}

export const ListScheduleDayItemLine = ({
  appointments,
  fetchAppointments,
}: StateProps) => {
  const [showSchedule, setShowSchedule] = useState(false);
  const [showPresence, setShowPresence] = useState(false);
  const [presences, setPresences] = useState<any>([]);
  const [showPerformance, setShowPerformance] = useState(false);
  const [performances, setPerformances] = useState<any>([]);
  const { t } = useTranslation();

  function getProfile(attendees: any[], type: string) {
    const student = attendees.filter(
      (attendee: any) => attendee.type === 'student',
    );

    if (!student) {
      return '';
    }

    switch (type) {
      case 'avatar':
        return student[0].avatarURL;

      case 'name':
        return student[0].fullName ?? student[0].name;

      case 'level':
        return student[0].studentLevel;

      default:
        return '';
    }
  }

  function getStudent(attendees: any[]) {
    const student = attendees.filter(
      (attendee: any) => attendee.type === 'student',
    );

    if (!student) {
      return '';
    }

    return student[0].id;
  }

  async function getPresence(attendees: Attendee[]) {
    const student = getStudent(attendees);

    const presencesReturn = await api.get(`/presence/student/${student}`);

    setPresences(presencesReturn);

    if (!showPresence) {
      setShowPresence(true);
    } else {
      setShowPresence(false);
    }
  }

  async function getPerformance(attendees: Attendee[]) {
    const student = getStudent(attendees);

    const performancesReturn = await api.get(`/performance/student/${student}`);

    setPerformances(performancesReturn);

    if (!showPerformance) {
      setShowPerformance(true);
    } else {
      setShowPerformance(false);
    }
  }

  return (
    <Container>
      {appointments.length > 0
        && appointments.map((item) => (
          <>
            <List>
              <ContainerHour>
                <ContainerIcon>
                  <Icon src={Watch} />
                </ContainerIcon>
                <Text>
                  {format(
                    utcToZonedTime(new Date(item.startTime), 'Europe/London'),
                    'hh:mm aa',
                    { timeZone: 'Europe/London' },
                  )}
                </Text>
              </ContainerHour>
              <ContainerUser>
                <Image src={getProfile(item.attendees, 'avatar')} />
                <ContainerTextsUser>
                  <Text>{getProfile(item.attendees, 'name')}</Text>
                  <Text>{getProfile(item.attendees, 'level')}</Text>
                </ContainerTextsUser>
              </ContainerUser>
              <ContainerInfoLesson division>
                <ContainerIcon>
                  <Icon src={Headset} />
                </ContainerIcon>
                <ContainerTextsInfoLesson>
                  <Text>{t('location')}</Text>
                  <Text>{item.location}</Text>
                </ContainerTextsInfoLesson>
              </ContainerInfoLesson>
              <ContainerInfoLesson>
                <ContainerIcon>
                  <Icon src={Coffe} />
                </ContainerIcon>
                <ContainerTextsInfoLesson>
                  <Text>{t('observacao')}</Text>
                  <Text>{item.mission.title}</Text>
                </ContainerTextsInfoLesson>
              </ContainerInfoLesson>
              <ContainerButtons>
                <Button onClick={() => getPresence(item?.attendees)}>
                  <span className="iconify" data-icon="akar-icons:calendar" />
                  {t('presenceHistory')}
                </Button>
                <Button onClick={() => getPerformance(item?.attendees)}>
                  <span className="iconify" data-icon="akar-icons:calendar" />
                  {t('performance')}
                </Button>
                <Button
                  onClick={() => {
                    if (!showSchedule) {
                      setShowSchedule(true);
                    } else {
                      setShowSchedule(false);
                    }
                  }}
                >
                  <span className="iconify" data-icon="akar-icons:calendar" />
                  {t('reagendar')}
                </Button>
                <Button cancel>
                  <span className="iconify" data-icon="eva:close-fill" />
                  {t('cancelar')}
                </Button>
              </ContainerButtons>
            </List>
            <div>
              {showPresence && (
                <ListPresence
                  presences={presences}
                  setShowPresence={setShowPresence}
                />
              )}
              {showPerformance && (
                <ListPerformance
                  performances={performances}
                  setShowPerformance={setShowPerformance}
                />
              )}
              {showSchedule && (
                <CollapseSchedule
                  appointment={item}
                  fetchAppointments={fetchAppointments}
                  setShowSchedule={setShowSchedule}
                />
              )}
            </div>
          </>
        ))}
    </Container>
  );
};
