import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const ContainerProfile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  height: 32.3rem;
  border-radius: 0.5rem;

  background: #fff;
  border: 1px solid var(--gray3);

  @media (max-width: 900px) {
    height: auto;
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    height: auto;
  }

  /* @media (max-width: 375px) {
    height: 50rem;
  } */
`;

export const BackgroundImageProfile = styled.img`
  width: 100%;
  height: 24.4rem;
  object-fit: cover;
  border-radius: 0.5rem;
`;

export const InfoProfile = styled.div`
  display: flex;
  position: relative;
  height: 8rem;
  padding: 0 2rem 0 18rem;
  width: 100%;
  cursor: pointer;

  @media (max-width: 900px) {
    flex-direction: column;
    height: auto;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    /* height: 6.5rem; */
    padding: 0 1rem 0 14rem;
  }

  @media (max-width: 500px) {
    display: contents;
  }

  /* @media (max-width: 375px) {
    display: block;
    height: 20rem;
    padding: 0 1rem;
    padding-bottom: 1rem;
  } */
`;

export const ImageProfile = styled.img`
  height: 11.2rem;
  width: 11.2rem;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  left: 3rem;
  top: -5.6rem;

  @media (max-width: 500px) {
    position: revert;
    top: -10.6rem;
    left: 18.5rem;
    height: 18rem;
    width: 18rem;
  }

  /* @media (max-width: 375px) {
    top: -9.6rem;
    left: 13.5rem;
    height: 18rem;
    width: 18rem;
    border: 3px solid var(--gray3);
  } */
`;

export const InfoUser = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  margin-right: 6rem;

  @media (max-width: 768px) {
    width: 10rem;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin-right: 0;
    align-items: center;
  }
`;

export const NameUser = styled.span`
  color: #243342;
  font-size: 1.9rem;
  margin-bottom: 0.3rem;
  font-weight: bold;
  font-family: 'Neue Haas Grotesk Display Pro';

  @media (max-width: 500px) {
    margin-top: 0.5rem;
    font-size: 1.9rem;
    font-weight: bold;
  }
`;

export const UserTag = styled.span`
  color: #243342;
  font-size: 1.9rem;
  font-weight: 300;
  font-family: 'Neue Haas Grotesk Display Pro';

  @media (max-width: 500px) {
    font-size: 1.9rem;
    margin-bottom: 1rem;
  }
`;

export const ContainerEdit = styled.div`
  width: 39rem;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;

  border-left: 1px solid var(--gray3);
  border-right: 1px solid var(--gray3);

  @media (max-width: 768px) {
    width: 35rem;
  }

  @media (max-width: 500px) {
    width: 35rem;
    padding: 0 0.2rem;
  }

  @media (max-width: 320px) {
    display: grid;
    width: 100%;
    justify-items: center;
    justify-content: center;
  }
`;

export const ContainerCount = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--gray3);
  cursor: pointer;
  text-decoration: none;
  color: #243342;

  .iconify {
    margin: 0 0.5rem;
    font-size: 1.6rem;
  }

  ${props =>
    props.student &&
    css`
      padding: 0.5rem;

      .iconify {
        margin: 0 1.5rem;
        font-size: 1.6rem;
      }
    `}
`;

export const EditProfile = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #243342;
  font-size: 1.6rem;

  .iconify {
    margin: 0 0.5rem;
    font-size: 1.6rem;
  }

  ${props =>
    props.student &&
    css`
      justify-content: space-between;
      padding-left: 1rem;

      .iconify {
        margin: 0;
      }
    `}
`;

export const Span = styled.img`
  font-size: 1.5rem;
  color: #243342;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const SpanCount = styled.span`
  font-size: 1.6rem;
  color: #243342;
`;

export const CountStudent = styled.span`
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  text-align: center;
  align-self: center;
  & span {
    margin-right: 20px;
  }
`;

export const Radar = styled.div`
  width: 31.6rem;
  background: #06231f;
  height: 5.5rem;
  align-self: center;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  /* margin-left: 35rem; */

  @media (max-width: 1400px) {
    width: 60%;
    margin-left: 0;
  }

  @media (max-width: 1024px) {
    height: 4rem;
    width: 25.6rem;
    margin-left: 0%;
  }

  @media (max-width: 1024px) {
    width: 30rem;
  }
`;

export const ContainerIcon = styled.div`
  height: 100%;
  width: 6rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #243342;
`;

export const IconRadar = styled.span`
  color: #fff;
  width: 2.1rem;
  height: 2.4rem;
`;

export const ContainerRadarText = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const TextRadar = styled.span`
  color: #fff;
  padding: 0 0.5rem;
  font-size: 1.4rem;
  text-justify: initial;
`;

export const Switch = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContainerSpace = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${props =>
    props.radar &&
    css`
      /* align-self: end; */
      justify-content: flex-end;
    `}

  @media(max-width: 500px) {
    display: grid;
    justify-items: center;
    /* margin-top: -10rem; */
    height: auto;

    ${props =>
      props.radar &&
      css`
        /* display: none; */
      `}
  }

  /* @media (max-width: 375px) {
    margin-bottom: 1rem;
    margin-top: 9rem;
    flex-direction: column;

    ${props =>
    props.radar &&
    css`
      display: none;
    `}
  } */
`;
