export function getWeekDays() {
  const today = new Date();

  return Array(7)
    .fill(7)
    .map((_, i) => {
      const date = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + i,
      );

      return {
        weekday: date.getDay(),
        date,
      };
    });
}
export function getWeekDaysSkip(skip: number) {
  const today = new Date();

  return Array(7)
    .fill(7)
    .map((_, i) => {
      const date = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + i + skip,
      );

      return {
        weekday: date.getDay(),
        date,
      };
    });
}
