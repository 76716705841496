import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  margin-bottom: 1.2rem;
`;

export const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 1.2rem;
  border: 1px solid var(--gray3);

  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;

  @media (max-width: 425px) {
    padding: 7rem 1rem;
  }
`;

export const Writtenexercises = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #fff;
`;

export const WrittenexercisesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  padding: 1rem;
  background: #fff;
  border-bottom: 1px solid var(--gray3);
  position: relative;

  span {
    font-size: 1.8rem;
    padding: 0.5rem;
    color: #8a8895;
    text-align: center;
  }

  a {
    position: absolute;
    left: 2rem;
    font-size: 2rem;
    color: #8a8895;
    text-decoration: none;
  }
`;

export const WrittenexercisesBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background: #fff;
  margin-top: 3rem;

  @media (max-width: 600px) {
    display: block;
  }
`;

export const WrittenexercisesImage = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  padding: 0.5rem;
  color: #8a8895;
  width: 100%;
`;

export const WrittenexercisesQuestions = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  padding: 0.5rem;
  color: #8a8895;
  margin-top: 1rem;
  width: 100%;

  span {
    font-size: 2rem;
    padding: 0.5rem;
    color: #8a8895;
    margin-bottom: 2rem;
    /* margin-left: 1rem; */
    text-align: left;
  }

  input {
    border: 1px solid var(--gray3);
    height: 4rem;
    padding-left: 1rem;
    border-radius: 0.5rem;
    width: 100%;
  }

  textarea {
    border: 1px solid var(--gray3);
    height: 12rem;
    padding-left: 1rem;
    border-radius: 0.5rem;
    width: 100%;
  }
`;

export const ImageLogo = styled.img`
  display: block;
  width: 73rem;
  height: 61rem;
  object-fit: cover;
  margin-left: 2rem;
  margin-right: 3rem;
`;

export const ImageCustom = styled.img`
  max-width: 100%;
`;

export const ImageContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const WrittenexercisesQuestionsButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;

  button {
    border-radius: 0.5rem;
  }

  button + button {
    margin-left: 1rem;
  }
`;

export const WrittenexercisesQuestionsUniqueButton = styled.div`
  margin-top: 2rem;
  border-radius: 0px;

  button {
    border-radius: 0.5rem;
  }
`;

export const NextButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
export const VideoBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Video = styled.video`
  max-width: 100%;
  max-height: auto;
  width: 100%;
  height: auto;
`;
