import styled from 'styled-components';

import BackgroundWhite from '../../assets/background_login_white.png';

export const Container = styled.div`
  display: flex;

  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 3rem 25rem;
  background-image: url(${BackgroundWhite});
  background-repeat: repeat;
  background-size: cover;
  overflow-y: auto;

  p {
    font-size: 1.6rem;
    align-items: center;
  }

  @media (max-width: 700px) {
    padding: 3rem;
  }
`;

export const Span = styled.span`
  text-align: justify;
  font-size: 1.6rem;
  margin-bottom: 1rem;
`;

export const Topic = styled.h1`
  text-align: left;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
`;

export const ContainerTerms = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 1.2rem;
  flex-direction: column;
`;

export const SpanTerms = styled.span`
  font-size: 3rem;
  font-family: 'Montserrat';
  margin-bottom: 2.5rem;

  b {
    color: #dbad00;
    margin-left: 0.5rem;
  }
`;

export const Logo = styled.img`
  align-items: center;
  width: 20rem;
  margin-bottom: 3rem;
`;

export const ContainerImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;
