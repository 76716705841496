import React, { useState } from 'react';
import TimePicker from 'react-time-picker';
import Box from '@mui/material/Box';
import Radio from '../../Radio';
import 'react-calendar/dist/Calendar.css';
import CaroselDates from '../CaroselDates';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { zeroPad } from '../../../utils/zeroPad';
import {
  Container,
  Text,
  ContainerConfig,
  ContainerText,
  Button,
  ContainerLevel,
  ButtonHour,
} from './styles';
import { useTranslation } from 'react-i18next';

import { WEEKDAYS } from '../../../utils/constants';
import { getDay, getHours, getMinutes, roundTime } from '../../../helpers/time';

function LocationProffy({
  queries,
  fetchAvailableTeachers,
  onAddQuery,
  onRemoveQuery,
}) {
  const { t } = useTranslation();
  const [startTime, setStartTime] = useState(
    `${getHours() + 1 > 23 ? '00' : getHours() + 1}:00`,
  );
  const [endTime, setEndTime] = useState(
    `${getHours() + 2 > 23 ? '00' : getHours() + 2}:00`,
  );
  const [onlyTeachersIKnow, setOnlyTeachersIKnow] = useState(false);
  const [experimentalClass, setExperimentalClass] = useState(false);
  const [weekday, setWeekday] = useState(() => {
    return WEEKDAYS[getDay()];
  });

  const setTimeUp = () => {
    let [hour, minute] = startTime.split(':');
    minute = Number(minute) + 15;

    if (minute === Number('60')) {
      hour++;
      minute = '00';
    }
    if (hour === Number('24')) {
      hour = '00';
    }
    setStartTime(`${zeroPad(hour, 2)}:${zeroPad(minute, 2)}`);
    let newSet = Number(hour) + 1;
    if (newSet == Number('24')) newSet = '00';
    setEndTime(`${zeroPad(newSet, 2)}:${zeroPad(minute, 2)}`);
  };

  const setTimeDown = () => {
    let [hour, minute] = startTime.split(':');
    hour = Number(hour);
    minute = Number(minute);

    if (minute <= Number('00')) {
      hour--;
      minute = '45';
    } else {
      minute -= 15;
    }

    if (hour < Number('00')) {
      hour = '23';
    }

    if (
      weekday.value === getDay() &&
      hour === getHours() &&
      minute >= getMinutes()
    ) {
      return;
    }

    setStartTime(`${zeroPad(hour, 2)}:${zeroPad(minute, 2)}`);
    let newSet = Number(hour) + 1;
    if (newSet == Number('24')) newSet = '00';
    setEndTime(`${zeroPad(newSet, 2)}:${zeroPad(minute, 2)}`);
  };

  const handleWeekdayUp = () => {
    let today = new Date();

    setWeekday(prevState => {
      if (prevState.value === 6) {
        return WEEKDAYS[0];
      }

      return WEEKDAYS[prevState.value + 1];
    });
  };

  const checkHoursByDay = weekDay => {
    let prevDay = weekDay - 1 < 0 ? 6 : weekDay - 1;
    let [, prevMinute] = startTime.split(':');
    let { hour, minute } = roundTime(getHours(), getMinutes());
    if (prevDay === getDay() && minute < prevMinute) {
      setStartTime(`${zeroPad(hour, 2)}:${zeroPad(minute, 2)}`);
      let newSet = Number(hour) + 1;
      if (newSet == Number('24')) newSet = '00';
      setEndTime(`${zeroPad(newSet, 2)}:${zeroPad(minute, 2)}`);
    }
  };

  const handleWeekdayDown = () => {
    setWeekday(prevState => {
      checkHoursByDay(prevState.value);
      if (prevState.value === 0) return WEEKDAYS[6];
      if (prevState.value === getDay()) return WEEKDAYS[prevState.value];
      return WEEKDAYS[prevState.value - 1];
    });
  };

  function handleOnlyTeachersIKnowChange(event) {
    setOnlyTeachersIKnow(event.target.checked);
  }

  function handleExperimentalClassChange(event) {
    setExperimentalClass(event.target.checked);
  }

  function handleAddQuery() {
    onAddQuery({
      startTime,
      endTime,
      weekday: weekday.value,
      onlyTeachersIKnow,
      experimentalClass,
    });
  }

  return (
    <Container showCalendar={queries.length > 0}>
      <Text title>{t('findPrivateEnglishTeacher')}</Text>
      <ContainerConfig>
        <div className="column">
          <span
            className="iconify"
            data-icon="akar-icons:calendar"
            data-inline="false"
          />
          <ContainerText>
            {/* <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  className="dayPicker"
                  openTo="day"
                  views={['year', 'month', 'day']}
                  value={dayPicker}
                  onChange={(newValue) => {
                    setDay(newValue);
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Text ref={inputRef} >{t(inputProps.value)}</Text>
                      {InputProps?.endAdornment}
                    </Box>
                  )}
                />
              </LocalizationProvider>
            </ThemeProvider> */}
            <ButtonHour type="button" onClick={handleWeekdayUp}>
              <span
                className="iconify"
                data-icon="dashicons:arrow-up-alt2"
                data-inline="false"
              />
            </ButtonHour>
            <Text>{t(weekday.label)}</Text>
            <ButtonHour type="button" onClick={handleWeekdayDown}>
              <span
                className="iconify"
                data-icon="dashicons:arrow-down-alt2"
                data-inline="false"
              />
            </ButtonHour>
          </ContainerText>

          <span
            className="iconify"
            data-icon="ic:outline-watch-later"
            data-inline="false"
          />
          <Text>{t('from')}</Text>
          <ContainerText watch>
            <ButtonHour onClick={setTimeUp}>
              <span
                className="iconify"
                data-icon="dashicons:arrow-up-alt2"
                data-inline="false"
              />
            </ButtonHour>
            <Text>{startTime}</Text>
            {/*           <TimePicker
              disabled
              onChange={setStartTime}
              value={startTime}
              clearIcon={null}
              clockIcon={null}
              disableClock
              format='HH:mm'
              className="react-time-picker"
            /> */}
            <ButtonHour onClick={setTimeDown}>
              <span
                className="iconify"
                data-icon="dashicons:arrow-down-alt2"
                data-inline="false"
              />
            </ButtonHour>
          </ContainerText>
          <Text>{t('to')}</Text>
          <ContainerText>
            <Text>{endTime}</Text>
            {/*             <TimePicker
              disabled
              onChange={setEndTime}
              value={endTime}
              clearIcon={null}
              clockIcon={null}
              disableClock
              format='HH:mm'
              className="react-time-picker"
            /> */}
          </ContainerText>
        </div>
        <div className="column2">
          {/* <ContainerLevel>
            <Text level>{t('meetYourTeacher')}</Text>
            <Radio
              className="radio"
              checked={experimentalClass}
              onChange={handleExperimentalClassChange}
            />
          </ContainerLevel> */}
          <ContainerLevel select>
            <Text level>{t('listOnlyTeachersIHaveTakenLessonsFrom')}</Text>
            <Radio
              className="radio"
              checked={onlyTeachersIKnow}
              onChange={handleOnlyTeachersIKnowChange}
            />
          </ContainerLevel>
          <Button add onClick={handleAddQuery}>
            {t('add')}
          </Button>
          <Button plus>
            <span
              className="iconify"
              data-icon="fa-solid:search-plus"
              data-inline="false"
            />
          </Button>
        </div>
      </ContainerConfig>

      {queries.length > 0 && (
        <>
          <CaroselDates
            width="80rem"
            list="false"
            queries={queries}
            onRemoveQuery={onRemoveQuery}
          />
          <Button search onClick={fetchAvailableTeachers}>
            {t('searchTeachers')}
          </Button>
        </>
      )}
    </Container>
  );
}

export default LocationProffy;
