import styled, { css } from 'styled-components';

interface ButtonsProps {
  cancel?: boolean;
  close?: boolean;
}

export const Container = styled.div`
  min-height: 30rem;
  width: 100%;
  padding: 0 2rem;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  position: relative;
  background: #fff;

  @media (max-width: 375px) {
    display: block;
    height: 17rem;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 50rem;
  background: #fff;
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .iconify {
    font-size: 15rem;
    color: #fff;
  }

  .container-check {
    background: #13c8a9;
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .container-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    button {
      height: 4rem;
      width: 20rem;
      background: #083059;
      color: #fff;
      border-radius: 0.5rem;
      margin-top: 3rem;
      cursor: pointer;
      transition: 0.2s;

      :hover {
        filter: brightness(0.8);
      }
    }
  }
`;

export const ContainerSpace = styled.div`
  width: 13rem;
  margin-right: 2rem;
`;

export const DataType = styled.span`
  font-size: 1.3rem;
  color: #5e5873;
  font-weight: 650;
`;

export const TitleType = styled.span`
  font-size: 1.5rem;
  color: #5e5873;
  font-weight: 650;
`;

export const ListFinancial = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid var(--gray3);
`;

export const ContainerTypes = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 5.8rem;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 375px) {
    margin-top: 1rem;
    justify-content: center;
  }
`;

export const ContainerIcon = styled.div`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  background: #dbad0018;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  .iconify {
    font-size: 1.8rem;
    color: #243342;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerStatus = styled.div`
  height: 4rem;
  padding: 0 1rem;
  background: #dbad0018;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
`;

export const Text = styled.span`
  font-size: 2rem;
  color: #707070;
`;

export const Select = styled.button`
  padding: 0 1rem;
  height: 4rem;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  color: #243342;

  @media (max-width: 375px) {
    width: 20rem;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
`;

export const List = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  padding: 1.5rem 0;
  position: relative;
`;

export const ContainerTitle = styled.div`
  color: #707070;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 1.6rem;
  }

  .iconify {
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const ContainerContent = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--gray3);
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
`;

export const Button = styled.button<ButtonsProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4rem;
  width: 100%auto;
  padding: 0 1rem;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin-left: 1rem;

  color: #243342;
  cursor: pointer;

  .iconify {
    font-size: 1.6rem;
    margin-right: 1rem;
  }

  ${(props) => props.cancel
    && css`
      background: #9e0d0d;
      color: #fff;
    `}

  ${(props) => props.close
    && css`
      border: 0;
    `}
`;
