import styled from 'styled-components';

interface ButtonProps {
  backgroundColor: string;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 28px;
  flex-direction: column;
  background-color: #fff;

  h2 {
    font-size: 20px;
    color: #5e5873;
    font-weight: 600;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    object-fit: contain;
    border-radius: 4px;

    margin-bottom: 20px;
  }
`;

export const ExamContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #f3f3f3;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 4px;

  .exam-info-container {
    display: flex;
    flex-direction: column;
    p {
      font-size: 15px;
      color: #06231f;
    }
  }

  button {
    height: 38px;
    width: 125px;
    border-radius: 0.5rem;
    border-right: 1px solid var(--gray3);
    background: #243342;
    color: #fff;
    cursor: pointer;

    :hover {
      filter: brightness(0.8);
    }
  }
`;

export const OralExamContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  justify-content: space-between;
`;

export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 280px;
  text-align: center;
  justify-content: space-between;

  p {
    color: #06231f;
    font-size: 20px;
    padding-bottom: 90px;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 200px;

  h2 {
    color: #06231f;
    font-size: 20px;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;

  img {
    height: 154px;
    margin-bottom: 70px;
  }

  span {
    color: #243342;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .wrapper {
    width: 100%;
  }

  .progress-bar {
    width: 100%;
    background-color: #243342;
    border-radius: 3px;
  }

  .progress-bar-fill {
    display: block;
    height: 22px;
    background-color: #dbad00;
    border-radius: 3px;
    transition: width 500ms ease-in-out;
  }
`;

export const OptionItem = styled.button<{ error: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  vertical-align: middle;
  font-size: 15px;
  background-color: ${(props) => (props.error ? '#f44336' : '#243342')};
  text-align: center;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;

  &:nth-child(4) {
    margin-bottom: 30px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  color: #fff;
  font-size: 15px;
  background-color: ${(props) => props.backgroundColor};
  text-align: center;
  padding: 15px;
  border-radius: 4px;

  .icon {
    margin-right: 20px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ButtonStart = styled.div`
  flex: 1;
  text-align: right;
  margin-right: 20px;
`;
