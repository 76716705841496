/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
// import { Container, BodyBook } from './styles';
import './styles/styles.css';

const ModalVideo = ({ open, closed, urlVideo }) => (
  <Modal
    isOpen={open}
    contentLabel="Example Modal"
    onRequestClose={closed}
    overlayClassName="Overlay"
    className="ModalVideo"
  >
    <ReactPlayer controls url={urlVideo} style={{maxWidth: '100%'}} className="video" />
  </Modal>
);

export default ModalVideo;
