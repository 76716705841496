import React, { useState, useCallback } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import { Button } from '../../components/Button';
import { Span } from '../../components/Span';
import { Input } from '../../components/Input';
import Logo from '../../assets/icon_logo_login.png';
import IconFooterTalks from '../../assets/icon-green.svg';
import { googleAuthenticatorService } from '../../services/googleAuthenticator';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Column1,
  IconsLeft,
  Column2,
  ScrollForm,
  BaseDiv,
  Column2Form,
  ImageLogo,
  FooterCopy,
  FooterIconTalks,
  TextCopy,
  BaseDivCode,
  LinkDashboard,
  TextTitle,
} from './styles';
import { useAuth } from '../../hooks/AuthProvider';

export function ConfirmAuth() {
  const { setAuthUser, setTypeLogin, validateCode, renewCookie } = useAuth();
  const [code, setCode] = useState('');
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      try {
        await validateCode(code);

        // set new cookie
        renewCookie()
        // history.push('/dashboard');
      } catch (err) {
        toast(t('authenticatorError'), { type: 'error' })
      }
    },
    [history, validateCode, code],
  );

  return (
    <Container>
      <Column1>
        <IconsLeft />
      </Column1>
      <Column2>
        <ScrollForm onSubmit={handleSubmit}>
          <ImageLogo src={Logo} />
          <TextTitle>
            {t('checkemailconfirmation')}
          </TextTitle>
          <Column2Form>
            <BaseDiv>
              <Span>{t('confirmAuthenticationCode')}</Span>
            </BaseDiv>
            <BaseDivCode>
              <ReactCodeInput
                autoFocus
                fieldWidth={45}
                fieldHeight={40}
                onChange={setCode}
              />
            </BaseDivCode>
            <BaseDiv>
              <Button
                color="#243342"
                fontColor="#fff"
                title={t('confirmar')}
                type="submit"
              />
            </BaseDiv>
          </Column2Form>
          <FooterCopy>
            <FooterIconTalks src={IconFooterTalks} />
            <TextCopy>Copyright © 2021 Table Talks</TextCopy>
          </FooterCopy>
        </ScrollForm>
      </Column2>
    </Container>
  );
}
