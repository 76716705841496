import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Footer from '../../../components/Footer';
import { LoginHistory } from './LoginHistory';
import { UpdatePassword } from './UpdatePassword';
import { GoogleAuthenticator } from './GoogleAuthenticator';
import {
  Container,
  ContainerBody,
  ContainerTitle,
  Title,
  ContainerNav,
  NavButton,
  ContainerRoutes,
} from './styles';
import { Header } from '../../../components/Header';

export function Settings({ match }) {
  const { t } = useTranslation();

  return (
    <Container>
      <Header />
      <ContainerTitle>
        <Title>{t('configuracoes')}</Title>
      </ContainerTitle>
      <ContainerBody>
        <ContainerNav>
        <NavButton to="./googleAuthenticator">{t('autenticacaoGoogle')}</NavButton>
          <NavButton to="./updatePassword">{t('alterarSenha')}</NavButton>
          <NavButton to="./loginHistory">{t('historicoLogin')}</NavButton>
        </ContainerNav>
        <ContainerRoutes>
          <Route
            path={`${match.path}/googleAuthenticator`}
            component={(props) => <GoogleAuthenticator {...props} />}
          />
          <Route
            exact
            path={`${match.url}/`}
            render={() => <Redirect to={`${match.url}/updatePassword`} />}
          />
          <Route
            path={`${match.path}/loginHistory`}
            component={(props) => <LoginHistory {...props} />}
          />
          <Route
            path={`${match.path}/updatePassword`}
            component={(props) => <UpdatePassword {...props} />}
          />
        </ContainerRoutes>
      </ContainerBody>
      <Footer />
    </Container>
  );
}
