import React from 'react';
import ReactPlayer from 'react-player';

import { Container } from './styles';

interface VideoProps {
  onEnded: () => void;
}

export const Video = ({ onEnded }: VideoProps) => (
  <Container>
    <ReactPlayer
        // url="https://www.youtube.com/watch?v=rUWxSEwctFU"
      url="https://www.youtube.com/embed/xIBdJeVrOIw"
      width="100%"
      height="70vh"
      style={{
        alignSelf: 'center',
        marginBottom: '1rem',
        borderRadius: '0.5rem',
      }}
      onEnded={onEnded}
    />
  </Container>
);
