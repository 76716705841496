import React from 'react';
import { StudentList } from '../../ProfileComponent/StudentList';

import { Container } from './styles';

export function Written({ seletion, status, method, type }) {
  return (
    <Container>
      <StudentList status={status} method={method} type={type} seletion={seletion}/>
    </Container>
  );
}
