import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: #f9f9f9;
`;

export const ContainerBody = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: 100%;
  flex-direction: column;
  padding: 0;
  margin: 1.5rem;
  margin-top: 0;
  overflow-y: auto;
  overflow-x: hidden;

  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;

  @media (max-width: 425px) {
    padding: 7rem 1rem;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
`;

export const Title = styled.div`
  display: flex;
  font-size: 2rem;
  color: #5e5873;
  font-weight: 650;
  margin: 5px 0;
  margin: 2rem 0;
`;

export const ContainerNav = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  border-bottom: 1px solid var(--gray3);
`;

export const NavButton = styled(NavLink)`
  display: flex;
  color: black;
  padding: 1.5rem 1.5rem;
  border-right: 1px solid #00000012;
  user-select: none;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  font-size: 2rem;
  color: #5e5873;

  &:hover {
    background-color: #ccc;
  }

  &.active {
    background-color: #ccc;
  }
`;

export const ContainerRoutes = styled.div`
  padding: 1rem;
`;
