import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Header } from '../../components/Header';
import TabPanel from '../../components/TabPanel';
import { useAuth } from '../../hooks/AuthProvider';
import { api } from '../../services/api';
import { Done } from './Done';
import { MultipleChoices } from './MultipleChoices';
import questions from './MultipleChoices/data.json';
import { Pdf } from './Pdf';
import {
  ButtonPass,
  Container,
  ContainerBody,
  ContainerButton,
  ContainerRoutes,
  ContainerTop,
  Content,
  Title,
} from './styles';
import { Video } from './Video';

export interface MultipleChoiceAnswer {
  question: string;
  answer: string;
}

export const TeachingKnowledgeTest = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { user, fetchUserData } = useAuth();

  const history = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const [watchedVideo, setWatchedVideo] = useState(false);
  const [pdfNumberOfPages, setPdfNumberOfPages] = useState(0);
  const [pdfActivePage, setPdfActivePage] = useState(1);
  const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState<
    MultipleChoiceAnswer[]
  >([]);

  useEffect(() => {
    if (user.teachingKnowledgeTest.status) setActiveStep(3);
  }, [user]);

  const handleBack = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  const handleNext = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  const handleDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setPdfNumberOfPages(numPages);
    },
    [],
  );

  const handleMultipleChoiceAnswerChange = useCallback(
    (answer: MultipleChoiceAnswer) => {
      setMultipleChoiceAnswers((prevState) => {
        const dataUpdate = [...prevState];

        const answerIndex = dataUpdate.findIndex(
          (a) => a.question === answer.question,
        );

        if (answerIndex <= -1) {
          return [...prevState, answer];
        }

        dataUpdate[answerIndex] = answer;

        return [...dataUpdate];
      });
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    const isLastStep = activeStep === 2;

    if (isLastStep) {
      const multipleChoiceAnswersCorrection = multipleChoiceAnswers.map(
        ({ answer, question }) => {
          const { title, correctAnswer } = questions.find((q) => q.title === question) || {};

          const isCorrectAnswer = correctAnswer === answer ? 1 : 0;

          return {
            question: title,
            answer,
            isCorrectAnswer,
          };
        },
      );

      const score = multipleChoiceAnswersCorrection.reduce(
        (prevValue, { isCorrectAnswer }) => {
          const i = isCorrectAnswer === 1 ? 1 : 0;

          return prevValue + i;
        },
        0,
      );

      const totalQuestions = questions.length;

      const average = score / totalQuestions;
      const averagePercentage = Math.ceil(average * 100);

      const status = await api
        .post('teaching-knowledge-test', {
          score,
          average,
          averagePercentage,
          totalQuestions,
        })
        .then((response) => response.data);

      await fetchUserData();

      if (status === 'approved') {
        // history.push('/dashboard');
        document.location.href = '/dashboard';
      } else {
        handleNext();
      }
    } else {
      handleNext();
    }
  }, [activeStep, fetchUserData, history, multipleChoiceAnswers]);

  return (
    <>
      <Container>
        <Header />
        <ContainerBody>
          <Content>
            <br />
            <ContainerTop>
              <Title>{t('teachingKnowledgeTest')}</Title>
            </ContainerTop>
            <ContainerRoutes ref={containerRef}>
              <TabPanel value={activeStep} index={0}>
                <Video
                  onEnded={() => {
                    setWatchedVideo(true);
                  }}
                />
              </TabPanel>
              <TabPanel value={activeStep} index={1}>
                <Pdf
                  containerRef={containerRef}
                  activePage={pdfActivePage}
                  numberOfPages={pdfNumberOfPages}
                  onNextPage={() => {
                    setPdfActivePage((prevState) => prevState + 1);
                  }}
                  onPrevPage={() => {
                    setPdfActivePage((prevState) => prevState - 1);
                  }}
                  onDocumentLoadSuccess={handleDocumentLoadSuccess}
                />
              </TabPanel>
              <TabPanel value={activeStep} index={2}>
                <MultipleChoices
                  onSubmit={handleSubmit}
                  answers={multipleChoiceAnswers}
                  onMultipleChoiceAnswerChange={
                    handleMultipleChoiceAnswerChange
                  }
                />
              </TabPanel>
              <TabPanel value={activeStep} index={3}>
                <Done
                  onRedo={() => {
                    setActiveStep(0);
                  }}
                />
              </TabPanel>
            </ContainerRoutes>
            {activeStep !== 3 && (
              <ContainerButton>
                {activeStep === 0
                  || (activeStep < 2 && (
                    <ButtonPass onClick={handleBack}>
                      <span
                        className="iconify"
                        data-icon="dashicons:arrow-left-alt2"
                      />
                      Previous
                    </ButtonPass>
                  ))}
                <ButtonPass
                  next
                  disabled={
                    (activeStep === 0 && !watchedVideo)
                    || (activeStep === 1 && pdfActivePage !== 46)
                    || (activeStep === 2 && multipleChoiceAnswers.length !== 14)
                  }
                  onClick={handleSubmit}
                >
                  {activeStep === 2 ? t('enviar') : t('next')}
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                  />
                </ButtonPass>
              </ContainerButton>
            )}
          </Content>
        </ContainerBody>
      </Container>
    </>
  );
};
