import React, { createContext, useContext, useEffect } from 'react'

const NoScreenshotContext = createContext()

const NoScreenshotProvider = ({ children }) => {
  // helpers to hide and show sensitive content
  const hideContent = () => document.getElementById('root').style.display = 'none'
  const showContent = () => document.getElementById('root').style.display = 'block'
  
  // helper to clear clipboard data
  const clearClipboardData = () => {
    try {
      return window.clipboardData.setData('text', 'Access   Restricted')
    } catch (err) {
      return
    }
  }

  // handle sensitive content hiding
  const handleHideContent = event => {
    // check for event keys
    // console.log(event);
    const isShiftKey = event.shiftKey
    const isMetaKey = event.metaKey // win key or mac command key
    const isPrintScreenKey = event.which === 44
    const isSKey = event.which === 83
    const isGKey = event.which === 71
    const isMacScreenshotNumbers = event.which == 52
    // const isMacScreenshotNumbers = event.which >= 50 && event.which <= 53
    // const isMacScreenshotKeys = (isShiftKey && isMetaKey && isMacScreenshotNumbers)
    const isMacScreenshotKeys = (isShiftKey && isMacScreenshotNumbers)
    const isWinScreenshotKeys = (isPrintScreenKey) || (isShiftKey && isMetaKey && isSKey) || (isMetaKey && isGKey)
    const isScreenshotKey = isMacScreenshotKeys || isWinScreenshotKeys

    // hide content only on shortcut combinations
    if (isScreenshotKey) {
      hideContent()    
      setInterval(clearClipboardData(), 300)
    }
  }
  
  useEffect(() => {
    document.addEventListener('contextmenu', (e) => e.preventDefault())
    window.addEventListener('keydown', handleHideContent)
    window.addEventListener('keyup', showContent)

    return () => {
      window.removeEventListener('keydown', handleHideContent)
      window.addEventListener('keyup', showContent)
    }
  }, [])
  
  return (
    <NoScreenshotContext.Provider value={{}}>
      {children}
    </NoScreenshotContext.Provider>
  )
}

function useNoScreenshot() {
  const context = useContext(NoScreenshotContext)

  if (!context) {
    throw new Error(
      'useNoScreenshot must be used within an NoScreenshotProvider'
    )
  }

  return context
}

export { NoScreenshotProvider, useNoScreenshot }