import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 40rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  z-index: 0;

  @media (max-width: 1660px) {
    display: grid;
    width: 100%;
    max-width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-area: f;
    gap: 1rem;
    margin-left: 0;
  }

  @media (max-width: 1280px) {
    flex-direction: column;
  }

  @media (max-width: 900px) {
    grid-area: f;
  }

  @media (max-width: 768px) {
    margin-left: 0px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
    max-width: 100%;
  }

  @media (max-width: 550px) {
    display: grid;
    grid-template-columns: 1fr;
  }

  @media (max-width: 468px) {
    margin-left: 0px;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
    max-width: 100%;
  }
`;
