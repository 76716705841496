import { useTranslation } from 'react-i18next';
import { SentMessagem, TextMessage, ContainerMessage, LinkAttachment, WrapperAttachment, HeaderAttachment } from './styles';

export function Sent({ content, avatarURL, attachments = [] }) {
  const { t } = useTranslation();
  return (
      <ContainerMessage>
        <img src={avatarURL} alt="" />
        <SentMessagem>
          <TextMessage gray>
            {content}
            {attachments.length > 0 &&
              <WrapperAttachment >
                <HeaderAttachment>{t('attachments')}</HeaderAttachment>
                {attachments.map((attachment, index) => (
                  <LinkAttachment key={index} href={attachment.url} target="_blank">{attachment.nome} ({(attachment.tamanhobytes / 1000).toFixed(1)} KB)</LinkAttachment>
                ))}
              </WrapperAttachment>
            }
          </TextMessage>
        </SentMessagem>
      </ContainerMessage>
  );
}
