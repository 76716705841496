import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContainerQuestions, ContainerTop, Text, Wrapper,
} from './styles';

export const Done = () => {
  const { t } = useTranslation();

  return (
    <ContainerQuestions>
      <ContainerTop>
        <Text>{t('attention')}</Text>
      </ContainerTop>
      <Wrapper>
        <Text warning>{t('notificationApplication')}</Text>
        <Text warning>{t('thankYouApply')}</Text>
      </Wrapper>
    </ContainerQuestions>
  );
};
