import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import IconFooterTalks from '../../assets/icon-green.svg';
import Logo from '../../assets/icon_logo_login.png';
import LeftColumn from '../../components/Base/LeftColumn';
import { Button } from '../../components/Button';
import Input from '../../components/Form/Input';
import { UpdateLang } from '../../components/UpdateLang';
import { useAuth } from '../../hooks/AuthProvider';
import { useQuery } from '../../hooks/useQuery';
import { toast } from 'react-toastify';
import {
  ButtonSignUp,
  ColumnRight,
  Container,
  ContainerFlags,
  FooterCopy,
  FooterIconTalks,
  ImageLogo,
  LabelForgot,
  TextCopy,
  TextPlatform,
  WrapperForm,
} from './styles';

const validationSchema = yup.object({
  email: yup.string().email('').required('emailIsARequiredField'),
  password: yup.string().required('passwordIsARequiredField'),
});

export function SignIn() {
  const { t } = useTranslation();
  const { signIn } = useAuth();
  const { type } = useQuery();

  const [login, setLogin] = useState(false);
  const [optionLogin, setOptionLogin] = useState('');

  const history = useHistory();

  const submitSignIn = useCallback(
    async ({ email, password }) => {
      try {
        const { redirect, to } = await signIn({ email, password });

        console.log('[REDIRECT TO] ', to);

        if (redirect) {
          if (to === '/dashboard') {
            document.location.href = to;
          } else {
            history.push(to);
          }
        }
      } catch (err) {
        toast('Incorrect email/password combination', { type: 'error' });
      }
    },
    [history, signIn],
  );

  useEffect(() => {
    if (!type || !['student', 'teacher'].includes(type)) {
      history.push({
        pathname: '/signin',
        search: 'student',
      });
    }

    if (login === false && type === 'teacher') {
      setOptionLogin('Teacher');
    } else {
      setLogin(true);
      setOptionLogin('Student');
    }
  }, [history, login, type]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: submitSignIn,
  });

  return (
    <Container>
      <LeftColumn withColumn="100%" leftIcon="8%" />
      <ColumnRight>
        <ImageLogo src={Logo} />
        <WrapperForm onSubmit={formik.handleSubmit}>
          {/* <ChoiceLabel> */}
          {/*   <input */}
          {/*     type="checkbox" */}
          {/*     value={optionLogin} */}
          {/*     checked={login} */}
          {/*     onChange={e => { */}
          {/*       setLogin(e.target.checked); */}
          {/*       history.push({ */}
          {/*         pathname: '/signin', */}
          {/*         search: `?type=${e.target.checked ? 'student' : 'teacher'}`, */}
          {/*       }); */}
          {/*     }} */}
          {/*   /> */}

          {/*   <span className="slider"> */}
          {/*     <div className="switch"> */}
          {/*       {!login && ( */}
          {/*         <> */}
          {/*           <img src={IconTeacherWhite} alt="" /> */}
          {/*           <p>{optionLogin}</p> */}
          {/*           <span className="arrow2" /> */}
          {/*         </> */}
          {/*       )} */}
          {/*       {!!login && ( */}
          {/*         <> */}
          {/*           <span className="arrow" /> */}
          {/*           <p>{optionLogin}</p> */}
          {/*           <img src={IconStudentWhite} alt="" /> */}
          {/*         </> */}
          {/*       )} */}
          {/*     </div> */}
          {/*     <ImageStudent src={IconTeacher} /> */}
          {/*     <ImageStudent src={IconStudent} /> */}
          {/*   </span> */}
          {/* </ChoiceLabel> */}

          <Input
            name="email"
            placeholder="E-mail"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={t(formik.errors.email)}
          />

          <Input
            name="password"
            placeholder={t('Senha')}
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={t(formik.errors.password)}
          />
          <Button color="#DBAD00" title="Login" type="submit" />
          <LabelForgot to="/forgotpassword">{t('EsqueciSenha')}</LabelForgot>
          <ButtonSignUp
            color="#243342"
            title="Sign Up"
            to={`/signup?type=${optionLogin.toLocaleLowerCase()}`}
          >
            {t('Registrar')}
          </ButtonSignUp>
          <TextPlatform>
            <span>{t('DescricaoLogin')}</span>

            <span>{t('DescricaoLogin2')}</span>
          </TextPlatform>

          <ContainerFlags>
            <UpdateLang />
          </ContainerFlags>
        </WrapperForm>
        <FooterCopy>
          <FooterIconTalks src={IconFooterTalks} />
          <TextCopy>Copyright © 2024 Table Talks</TextCopy>
        </FooterCopy>
      </ColumnRight>
    </Container>
  );
}
