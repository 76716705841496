export const customState = (location: any) => {
  const local1 = localStorage.getItem('state');
  let stateFinal = null;
  if (location.state) {
    stateFinal = location.state;
  } else if (local1) {
    stateFinal = JSON.parse(local1);
  }
  return {
    state: stateFinal,
  };
};
