import styled, { css } from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    display: block;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 768px) {
    display: block;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const Col25 = styled.div`
  width: 25%;
  text-align: left;

  span {
    color: #5e5873;
  }

  ${(props) =>
    props.notActive &&
    css`
      span {
        color: #5e587330;
      }

      input {
        border: 1px solid #5e587330;
        ::placeholder {
          color: #5e587330;
        }
      }
    `}

  @media (max-width: 900px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    width: 75%;
  }
`;

export const Step = styled.span`
  font-size: 1.5rem;
  display: block;

  border-bottom: 1px solid var(--gray3);
  padding-bottom: 1rem;
  margin: 2rem 0;
  position: relative;
`;

export const WrapperForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 2rem;

  span {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const ContainerText = styled.div`
  width: 100%;
  padding-right: 2rem;

  span {
    display: block;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
`;

export const Position = styled.div`
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  background: #083059;
  border-radius: 50%;
  bottom: -0.7rem;

  ${(props) =>
    props.notActive &&
    css`
      background: #b9b9c3;
    `}
`;

export const ContainerButton = styled.div`
  margin-top: 5rem;
  margin-left: 2rem;

  display: flex;
`;

export const ButtonPass = styled.button`
  height: 4rem;
  width: 15rem;
  border-radius: 0.4rem;
  background: ${(props) => props.remove ? 'red' : '#ebe9f1'};
  color: ${(props) => props.remove ? 'white' : '#243342'};
  margin-right: 1rem;

  display: ${(props) => props.openStep == 1 & props.back || props.openStep == 3 & props.next ? 'none' : 'flex'};
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) =>
    props.next &&
    css`
      background: #243342;
      color: #fff;
    `}
`;

export const QRCodeBox = styled.div`
    border: 3px solid #000;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    width: 165px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    display: ${(props) => props.openStep != 1 ? 'none' : 'flex'};
`;

export const QRCodeImage = styled.img`
    width: 150px;
    border-radius: 10px;
`;