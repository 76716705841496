import { format, utcToZonedTime } from 'date-fns-tz';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Text,
  Wrapper,
  ContainerTitle,
  ContainerTypes,
  TitleType,
  DataType,
  ContainerSpace,
  ListFinancial,
  Button,
} from './styles';

interface StateProps {
  setShowPresence: Dispatch<SetStateAction<boolean>>;
  presences: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any[];
  };
}

export const ListPresence = ({ setShowPresence, presences }: StateProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <ContainerTitle>
          <Text>{t('Histórico de Presença')}</Text>
          <Button onClick={() => setShowPresence(false)} close>
            <span className="iconify" data-icon="eva:close-outline" />
          </Button>
        </ContainerTitle>

        <ContainerTypes>
          <ContainerSpace>
            <TitleType>{t('Método')}</TitleType>
          </ContainerSpace>
          <ContainerSpace>
            <TitleType>{t('Missão')}</TitleType>
          </ContainerSpace>
          <ContainerSpace>
            <TitleType>{t('Data Presença')}</TitleType>
          </ContainerSpace>
        </ContainerTypes>

        {presences?.data?.map((item) => (
          <ListFinancial>
            <ContainerSpace>
              <DataType>{item?.methods?.title}</DataType>
            </ContainerSpace>
            <ContainerSpace>
              <DataType>{item?.missions?.title}</DataType>
            </ContainerSpace>
            <ContainerSpace>
              <DataType>
                {format(
                  utcToZonedTime(new Date(item?.presenceDate), 'Europe/London'),
                  'dd/MM/yyyy hh:mm aa',
                  { timeZone: 'Europe/London' },
                )}
              </DataType>
            </ContainerSpace>
          </ListFinancial>
        ))}
      </Wrapper>
    </Container>
  );
};
