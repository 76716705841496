import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ContainerBody,
  Writtenexercises,
  WrittenexercisesHeader,
  WrittenexercisesBody,
  WrittenexercisesImage,
  WrittenexercisesQuestions,
  ImageLogo,
  WrittenexercisesQuestionsButtons,
  WrittenexercisesQuestionsUniqueButton,
  LoadingContainer,
  NextButtonContainer,
  ImageCustom,
  ImageContainer,
  Video,
  VideoBody,
} from './styles';
import { Span } from '../../../../components/Span';
import { Button } from '../../../../components/Button';
import { useHistory } from 'react-router-dom';
import { api } from '../../../../services/api';
import { ContainerColumn2Header } from '../../Guideline/ReadBook/styles';
import { checkIsVideo } from '../../../../helpers/url';

export function ExamWritten({ data }) {
  const { t } = useTranslation();

  const history = useHistory();

  const id = '61c1f5a1670e2acc5e9bf83a';

  const [score, setScore] = useState();
  const [homework, setHomework] = useState();
  const [isHomeworkLoading, setIsHomeworkLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const [answers, setAnswers] = useState([]);

  const handleNextQuestion = async () => {
    let responseText = '';
    if (answers) {
      responseText = answers.find(
        a => a.questionId === activeQuestion?._id,
      )?.answer;
      if (
        responseText === null ||
        responseText === undefined ||
        responseText === ''
      ) {
        responseText = '';
      }
    }
    if (responseText === '') {
      toast('You need to add an answer', {
        type: 'error',
      });
      return false;
    }
    await api.put(`/homework/${data.id}`, { answers });

    setActivePage(prevState => {
      if (data.exam.writtenQuestions.length - 1 === prevState) {
        return prevState;
      }

      return prevState + 1;
    });
  };
  const maxQuestionsNumbers = () => {
    if (!data) return 0;
    return data.exam.writtenQuestions.length;
  };
  const handleSubmit = async () => {
    let responseText = '';
    if (answers) {
      responseText = answers.find(
        a => a.questionId === activeQuestion?._id,
      )?.answer;
      if (
        responseText === null ||
        responseText === undefined ||
        responseText === ''
      ) {
        responseText = '';
      }
    }
    if (responseText === '') {
      toast('You need to add an answer', {
        type: 'error',
      });
      return false;
    }
    console.log('answers', answers);
    await api.put(`/homework/${data.id}/submit`, { answers }).then(response => {
      setScore(response.data.score);
    });

    toast('Your homework has been submitted. You will be notified shortly', {
      type: 'info',
    });

    history.go(-1);
  };

  const handlePreviousQuestion = answerOption => {
    setActivePage(prevState => {
      if (prevState <= 0) {
        return prevState;
      }

      return prevState - 1;
    });
  };

  const handleAnswerChange = useCallback(answer => {
    setAnswers(prevState => {
      const dataUpdate = [...prevState];

      const answerIndex = dataUpdate.findIndex(
        a => a.questionId === answer.questionId,
      );

      if (answerIndex <= -1) {
        return [...prevState, answer];
      }

      dataUpdate[answerIndex] = answer;

      return [...dataUpdate];
    });
  }, []);

  const activeQuestion = useMemo(() => {
    if (!data) return {};
    return data.exam.writtenQuestions[activePage];
  }, [activePage, data]);

  if (!data) {
    return (
      <LoadingContainer>
        <Loader
          visible
          type="ThreeDots"
          color="#124f8c"
          secondaryColor="#191919"
        />
      </LoadingContainer>
    );
  }

  const isVideo = activeQuestion ? checkIsVideo(activeQuestion.fileURL) : false;

  return (
    <Container>
      <ContainerBody>
        <Writtenexercises>
          <WrittenexercisesHeader>
            <Link to="/dashboard/method">{t('voltar')}</Link>
            <Span>{data.mission.title}</Span>
          </WrittenexercisesHeader>
          <WrittenexercisesBody>
            {activeQuestion.fileURL && (
              <WrittenexercisesImage>
                {activeQuestion.fileURL && !isVideo && (
                  <ImageContainer>
                    <ImageCustom src={activeQuestion.fileURL} />
                  </ImageContainer>
                )}
                {isVideo && (
                  <ImageContainer>
                    <Video src={activeQuestion.fileURL} controls />
                  </ImageContainer>
                )}
              </WrittenexercisesImage>
            )}
            <WrittenexercisesQuestions>
              {activeQuestion ? (
                <WrittenexercisesQuestions>
                  <div className="text-container">
                    <span>
                      {activePage + 1} - {activeQuestion.text}
                    </span>
                    <span>
                      {activePage + 1} - {data.exam.writtenQuestions.length}
                    </span>
                  </div>
                  <textarea
                    placeholder=""
                    rows="15"
                    value={
                      answers.find(a => a.questionId === activeQuestion?._id)
                        ?.answer || ''
                    }
                    onChange={event => {
                      handleAnswerChange({
                        questionId: activeQuestion?._id,
                        question: activeQuestion?.text,
                        answer: event.target.value,
                      });
                    }}
                  />
                </WrittenexercisesQuestions>
              ) : (
                <>
                  <WrittenexercisesQuestions></WrittenexercisesQuestions>
                </>
              )}

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  marginTop: 'auto',
                }}
              >
                <WrittenexercisesQuestionsButtons>
                  {activePage !== 0 && (
                    <Button
                      color="#DBAD00"
                      fontColor="#fff"
                      title={t('voltar')}
                      onClick={handlePreviousQuestion}
                    />
                  )}

                  {maxQuestionsNumbers() - 1 === activePage ? (
                    <Button
                      color="#DBAD00"
                      fontColor="#fff"
                      title={t('submit')}
                      onClick={handleSubmit}
                    />
                  ) : (
                    <Button
                      color="#DBAD00"
                      fontColor="#fff"
                      title={t('next')}
                      onClick={handleNextQuestion}
                    />
                  )}
                </WrittenexercisesQuestionsButtons>
                <WrittenexercisesQuestionsUniqueButton>
                  <Button
                    color="#06231F"
                    fontColor="#fff"
                    title={t('finishLater')}
                    onClick={() => history.go(-1)}
                  />
                </WrittenexercisesQuestionsUniqueButton>
              </div>
            </WrittenexercisesQuestions>
          </WrittenexercisesBody>
        </Writtenexercises>
      </ContainerBody>
    </Container>
  );
}
