import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import BackgroundWhite from '../../assets/background_login_white.png';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColumnRight = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${BackgroundWhite});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8rem 0 4rem 0;

  strong {
    color: var(--green1);
  }

  @media (max-width: 1280px) {
    padding: 4rem 0 2rem 0;
  }
`;

export const Column2Form = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 2rem;
  }

  .title {
    font-size: 1.5rem;
  }

  @media (max-width: 1280px) {
    width: 28.1rem;

    .title {
      font-weight: 400;
      margin-bottom: 2rem;
    }
  }
`;

export const BaseImage = styled(Link)`
  width: 25rem;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin-top: 1rem;
  padding: 1rem;
  cursor: pointer;
  text-decoration: none;

  :hover {
    filter: brightness(0.95);
  }

  span {
    font-size: 2rem;
  }
`;

export const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 4rem;
  width: 100%;
  border: 0;
  border-radius: 0.4rem;
  color: #fff;
  background: ${(props) => props.color};
  font-size: 1.25rem;
  font-weight: bold;
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;

  :hover {
    filter: brightness(0.8);
  }

  ${(props) =>
    props?.forgoutPassword &&
    css`
      height: auto;
    `}
`;

export const BaseDiv = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin-top: 2rem;

  span {
    color: #243342;
    font-size: 1.2rem;
    cursor: pointer;
    strong {
      color: #243342;
      font-weight: bold;
    }
  }

  button {
    width: 100%;
    height: 2rem;
    color: #fff;
  }
`;

export const BaseEnd = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  background: red;

  span {
    color: #243342;
    font-size: 1.4rem;
    text-align: end;
    strong {
      color: #243342;
      font-weight: bold;
    }
  }
`;

export const ImageLogo = styled.img`
  height: 11.4rem;
  width: 15.4rem;
  margin-bottom: 2rem;
`;

export const ImageUsers = styled.img`
  margin-right: 3rem;
  height: 3.5rem;
`;

export const TextPlatform = styled.div`
  color: var(--secondary-color-blue);
  margin: 3rem 0;
  text-align: end;
  width: 28rem;
  align-self: flex-end;

  span {
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

export const ContainerFlags = styled.div`
  padding: 1rem 0;
  width: 26rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const FooterCopy = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 25rem;
  color: var(--secondary-color-blue);

  @media (max-width: 1280px) {
    width: 28.1rem;
  }
`;

export const FooterIconTalks = styled.img`
  margin-right: 2rem;

  @media (max-width: 1280px) {
    margin-right: 1rem;
  }
`;

export const TextCopy = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;
`;
