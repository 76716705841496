import { subMonths, parseISO, format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';

// import { useAuth } from '../../../hooks/AuthProvider';
import { api } from '../../../services/api';
import { HeaderReports } from '../HeaderReports';
import {
  Container,
  Wrapper,
  ContainerList,
  Image,
  TextDetails,
  ContainerText,
  ContainerIcon,
  ContainerDetails,
} from './styles';

export function CouponReports() {
  // const { user, typeLogin } = useAuth();
  // const { t } = useTranslation()t;

  const [classesReports, setClassesReports] = useState([]);
  const [startDate, setStartDate] = useState(subMonths(new Date(), 12));
  const [endDate, setEndDate] = useState(new Date());
  const [search, setSearch] = useState('');

  const fetchClassesReports = useCallback(async () => {
    try {
      await api
        .get('/reports/coupons/me', {
          params: { startDate, endDate, search },
        })
        .then((response) => {
          setClassesReports(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  }, [startDate, endDate, search]);

  useEffect(() => {
    fetchClassesReports();
  }, [fetchClassesReports]);

  return (
    <Container>
      <HeaderReports
        search={search}
        startDate={startDate}
        endDate={endDate}
        onSearchChange={setSearch}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        searchEnabled
      />
      <Wrapper>
        {classesReports?.map((user) => (
          <ContainerList>
            <div className="container-profile" style={{ width: '220px' }}>
              <Image src={user.avatarURL} />
              <ContainerText>
                <TextDetails typeLogin>{user?.name}</TextDetails>
                <TextDetails typeLogin>{user?.email}</TextDetails>
              </ContainerText>
            </div>
            <ContainerDetails>
              <ContainerIcon>
                <span
                  className="iconify"
                  data-icon="akar-icons:calendar"
                  data-inline="false"
                />
              </ContainerIcon>
              <ContainerText>
                <TextDetails>
                  {format(parseISO(user.createdAt), 'dd/MM/yyyy')}
                </TextDetails>
              </ContainerText>
            </ContainerDetails>
            <div style={{ marginLeft: '20px' }}>
              <ContainerDetails>
                <ContainerIcon>
                  <span
                    marginLeft="10px"
                    className="iconify"
                    data-icon="akar-icons:money"
                    data-inline="false"
                  />
                </ContainerIcon>
                <ContainerText>
                  <TextDetails>
                    {user?.value}
                  </TextDetails>
                </ContainerText>
              </ContainerDetails>
            </div>
          </ContainerList>
        ))}
      </Wrapper>
    </Container>
  );
}
