import axios from 'axios';
import { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Pending } from './Pending';
import { Finished } from './Finished';
import {
  Container,
  Content,
  Text,
  HeaderNav,
  Column,
  Nav,
  Button,
  ContainerRoutes,
  OpenWhatsApp,
} from './styles';
import { ModalTicket } from '../../../components/Modals/ModalTicket';
import { Header } from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks/AuthProvider';
import { ModalTicketConfirmation } from '../../../components/Modals/ModalTicketConfirmation';
import Loader from 'react-loader-spinner';

export function Ticket({ match }) {
  const { t } = useTranslation();

  const { user } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalConfirmationIsOpen, setModalConfirmationIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [protocoloOfNewCall, setProtocoloOfNewCall] = useState(0);
  const [pendingCalls, setPendingCalls] = useState([]);
  const [closeCalls, setCloseCalls] = useState([]);

  useEffect(() => {
    handleLoadCalls();
  }, []);

  const handleLoadCalls = async () => {
    try {
      setLoading(true);
      const { data: { data, erro } } = await axios.get(`https://api.tomticket.com/chamados/b40b8bc6aa8c3891087231bd5b097c09/1?idcliente=${user.email}`);
      setLoading(false);

      if (erro) return;

      const callsPending = data.filter(call => call.descsituacao !== 'Finalizado');
      const callsClose = data.filter(call => call.descsituacao === 'Finalizado');

      setPendingCalls(callsPending);
      setCloseCalls(callsClose);
    } catch (error) {
      toast.error(t('thereWasAnErrorListingYourCalls'));
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModalConfirmation = (protocolo) => {
    setProtocoloOfNewCall(protocolo);
    setModalConfirmationIsOpen(true);
    handleLoadCalls();
  };

  return (
    <Container>
      <Header />
      <Text title>{t('tickets')}</Text>
      <Content style={{ overflow: 'auto', padding: '1rem 1.2rem'}}>
        <HeaderNav>
          <Column>
            <Nav
              left
              onClick={() => {
                localStorage.setItem('state', JSON.stringify(pendingCalls));
              }}
              to={{
                pathname: `${match.url}/pending`,
                state: {
                  calls: pendingCalls,
                },
              }}
            >
              {t('pending')}
            </Nav>
            <Nav
              onClick={() => {
                localStorage.setItem('state', JSON.stringify(closeCalls));
              }}
              to={{
                pathname: `${match.url}/finalized`,
                state: {
                  calls: closeCalls,
                },
              }}
            >
              {t('finished')}
            </Nav>
          </Column>
          <Column buttons>
            <OpenWhatsApp href="https://wa.me/5534991833668" target="_blank">
              <span
                className="iconify"
                data-icon="akar-icons:whatsapp-fill"
                data-inline="false"
              />{' '}
              {t('openTicketViaWhatsApp')}
            </OpenWhatsApp>
            <Button newTicket onClick={openModal}>
              {t('openNewTicket')}
            </Button>
          </Column>
        </HeaderNav>
        <ContainerRoutes>
          {loading &&
            <Loader
              visible
              type="ThreeDots"
              color="#124f8c"
              secondaryColor="#191919"
            />
          }
          {!loading &&
            <>
              <Route
                exact
                path={`${match.url}/`}
                render={() => <Redirect to={`${match.url}/pending`} />}
              />
              <Route
                path={`${match.path}/pending`}
                component={props => <Pending calls={pendingCalls} {...props} />}
              />
              <Route
                path={`${match.path}/finalized`}
                component={props => <Finished calls={closeCalls} {...props} />}
              />
            </>
          }
        </ContainerRoutes>
      </Content>
      {modalIsOpen &&
        <ModalTicket
          open={modalIsOpen}
          closed={closeModal}
          openModalConfirmation={openModalConfirmation}
        />
      }
      {modalConfirmationIsOpen &&
        <ModalTicketConfirmation
          open={modalConfirmationIsOpen}
          closedModalConfirmation={() => setModalConfirmationIsOpen(false)}
          protocoloOfNewCall={protocoloOfNewCall}
        />
      }
    </Container>
  );
}
