import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import BackgroundGreen from '../../assets/background_login_gren.png';
import BackgroundWhite from '../../assets/background_login_white.png';
import IconLeft from '../../assets/logo_icon_login.png';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;

  overflow-y: auto;
`;

export const Column1 = styled.div`
  width: 50%;
  background-image: url(${BackgroundGreen});
  /* background-repeat: no-repeat; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconsLeft = styled.img.attrs({ src: IconLeft })`
  position: absolute;
  width: auto;
  height: 90%;
  left: -4%;
`;

export const Column2 = styled.div`
  width: 100%;
  background-image: url(${BackgroundWhite});
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ScrollForm = styled.div`
  padding: 5rem 0;
  width: 25%;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Column2Form = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
`;

export const BaseDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isForm }) =>
    isForm &&
    css`
      flex-direction: column;
    `}

  Span {
    width: 70%;
    color: #243342;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }

  Input {
    height: 4rem;
    padding: 0 1rem;
    border: 1px solid var(--gray3);
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    width: 100%;
  }
`;

export const ImageLogo = styled.img`
  height: 114px;
  width: 154px;
  margin-bottom: 40px;
`;

export const FooterCopy = styled.div`
  min-width: 75%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 320px;
  color: var(--secondary-color-blue);
`;

export const FooterIconTalks = styled.img`
  margin-right: 2rem;
`;

export const TextCopy = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const LinkConfirm = styled(Link)`
  width: 100%;

  button {
    width: 100%;
    height: 42px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    font-size: 12px;
  }
`;

export const Error = styled.p`
  align-self: flex-start;
  padding-left: 1rem;
  font-size: 1.3rem;
  color: #f00;
`;
