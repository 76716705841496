/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import closeImg from '../../../../assets/icon_close.svg';
import Input from '../../../../components/Form/Input';
import { Container } from './styles';
import './styles/styles.css';
import SignatureCanvas from 'react-signature-canvas';
import { api } from '../../../../services/api';

Modal.setAppElement('#root');

export function AcceptCertificateModal({
  open,
  certificate,
  onSubmit,
  onRequestClose,
}) {
  const sigCanvasRef = useRef(null);

  const { t } = useTranslation();

  const [signature, setSignature] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchSignature() {
      const response = await api.get(`/signatures`);
      setSignature(response.data);
    }

    fetchSignature(signature);
  }, []);

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      const fileContent =
        sigCanvasRef.current &&
        sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');

      await api
        .post('/signatures', { fileContent })
        .then(response => response.data);

      onRequestClose();
      onSubmit(certificate);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Modal
      isOpen={open}
      contentLabel=""
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt={t('closeModal')} />
      </button>
      <Container onSubmit={handleSubmit}>
        {!isLoading && signature && <h2>{t('signCertificate')}</h2>}
        {isLoading ? (
          <h1>Loading...</h1>
        ) :  (
          <>
            {!signature &&
              <>
                <h2>{t('insertYouSignature')}</h2>
                <div
                  style={{width: '500px', height: '200px'}}
                >
                  <SignatureCanvas
                    ref={sigCanvasRef}
                    penColor="black"
                    backgroundColor="#ffffff"
                    style={{width: '500px', height: '200px'}}
                    canvasProps={{ width: '500px', height: '200px', className: 'sigCanvas' }}
                  />
                </div>
              </>
            }
            {signature &&
              <>
                <div>
                  <img src={signature.fileURL} style={{maxHeight: '200px', maxWidth: '100%'}} />
                </div>
              </>
            }
          </>
        )}

        <div className="modal-actions">
          <button disabled={isLoading} type="button" onClick={onRequestClose}>
            {t('cancel')}
          </button>
          {signature &&
            <button
                disabled={isLoading}
                type="button"
                onClick={() => {
                  sigCanvasRef.current && sigCanvasRef.current.clear();
                  setSignature(null)
                }}
              >
                Change signature
            </button>
          }
          {!signature && (
            <button
              disabled={isLoading}
              type="button"
              onClick={() => {
                sigCanvasRef.current && sigCanvasRef.current.clear();
              }}
            >
              {t('clear')}
            </button>
          )}
          <button disabled={isLoading} type="submit">
            {t('submit')}
          </button>
        </div>
      </Container>
    </Modal>
  );
}
