import React, { useCallback, useEffect, useState } from 'react';

import IconFlagEngland from '../../../assets/icon_bandeira_inglaterra.png';
import IconBackURL from '../../../assets/icon_back.png';
import IconFlagEUA from '../../../assets/icon_bandeira_usa.png';
import {
  Container,
  Button,
  ContainerText,
  Text,
  IconFlag,
  IconBack,
  ButtonNextMission,
} from './styles';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { customState } from '../../../utils/customState';
import { socket } from '../../../services/contextSocket';
import { lineHeight } from '@mui/system';
import { useTranslation } from 'react-i18next';

export function ReadingComprehension(props) {
  const { conteudo, currentContent, setCurrentContent, nextMission } = props;
  const { t } = useTranslation();
  const location0 = useLocation();
  const location = customState(location0);
  const classRoom = location?.state?.appointmentId;

  const [audioText, setAudio] = useState();

  function createClassRoom() {
    socket.emit('classroom:create', classRoom);
  }

  function fetchClassRoom(content) {
    socket.emit('classroom:classcontent', { classRoom, content });

    const contentType = 'vocabulary';
    socket.emit('classroom:classcontenttype', { classRoom, contentType });

    const methodMission = {
      method: conteudo[currentContent]?.method?.title,
      mission: conteudo[currentContent]?.mission?.title,
    };

    socket.emit('classroom:classcontentmethodmission', {
      classRoom,
      methodMission,
    });

    socket.on('classroom:serverclassstudentjoin', () => {
      socket.emit('classroom:classcontent', { classRoom, content });
    });
  }

  useEffect(() => {
    createClassRoom();
    fetchClassRoom(conteudo[currentContent]);
  }, [conteudo]);

  const handleAudioGoogle = async (languageCode, name) => {
    try {
      const voice = { languageCode, name };
      const text = document.getElementsByName('text')[0].innerText;

      const { data } = await api.post(`text/synthesize`, {
        voice,
        text,
      });

      setAudio(data.fileURL);
    } catch (err) {
      console.error(err);
      toast('There is an audio recording issue. Please check your settings.', {
        type: 'error',
      });
    }
  };

  function handleArrow({ key }) {
    if (key === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return prevState;
        }

        return prevState - 1;
      });

      if (current === 0) {
        current = current;
      } else {
        current = current - 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }

    if (key === 'ArrowRight') {
      setCurrentContent(prevState => {
        if (prevState === conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });

      socket.emit('classroom:create', classRoom);

      let current = currentContent;

      if (current === 0) {
        current = current;
      } else {
        current = current + 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }
  }

  function handleArrowClick(e) {
    if (e === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return;
        }

        return prevState - 1;
      });

      const classRoom = location.state?.appointmentId;

      let current = currentContent;

      if (current === 0) {
        current = current;
      } else {
        current = current - 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }
    if (e === 'ArrowRight') {
      setCurrentContent(prevState => {
        if (prevState === conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });

      const classRoom = location.state?.appointmentId;

      socket.emit('classroom:create', classRoom);

      let current = currentContent;

      if (current === conteudo.length - 1) {
        current = current;
      } else {
        current = current + 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }
  }

  function handleGoToBeginning() {
    setCurrentContent(0);

    const content = conteudo[0];
    socket.emit('classroom:classcontent', { classRoom, content });

    const contentType = 'vocabulary';
    socket.emit('classroom:classcontenttype', { classRoom, contentType });

    const methodMission = {
      method: conteudo[0]?.method?.title,
      mission: conteudo[0]?.mission?.title,
    };
    socket.emit('classroom:classcontentmethodmission', {
      classRoom,
      methodMission,
    });
  }

  useEffect(() => {
    window.addEventListener('keydown', handleArrow);

    return () => {
      window.removeEventListener('keydown', handleArrow);
    };
  });

  return (
    <Container>
      <header>
        <div className="column">
          <Button onClick={e => handleGoToBeginning()}>
            {' '}
            <span
              className="iconify"
              data-icon="dashicons:arrow-left-alt2"
              data-inline="false"
            />{' '}
          </Button>
          <ContainerText>
            <Text>
              <strong>{conteudo[currentContent]?.method?.title}</strong>
            </Text>
            <Text>{conteudo[currentContent]?.mission?.title}</Text>
            <Text finaly>
              {conteudo[currentContent]?.type === 'notes'
                ? t('notes')
                : conteudo[currentContent]?.readingComprehension?.title}
            </Text>
          </ContainerText>
        </div>
        <div className="column">
          {currentContent !== 0 && (
            <Button left onClick={e => handleArrowClick('ArrowLeft')}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
          {currentContent !== conteudo.length - 1 && (
            <Button finaly onClick={e => handleArrowClick('ArrowRight')}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}

          {/* Next Mission */}
          <ButtonNextMission onClick={e => nextMission()}>
            Next Mission{' '}
            <span
              className="iconify"
              data-icon="dashicons:arrow-right-alt2"
              data-inline="false"
            />
          </ButtonNextMission>
          {/* end */}
        </div>
      </header>

      <section>
        <article name="text" style={{ lineHeight: '1.2' }}>
          {conteudo[currentContent]?.readingComprehension?.text
            ?.split('\n\n')
            ?.map((str, key) => (
              <p key={key}>
                {str?.split('\n')?.map((_str, index) => (
                  <React.Fragment key={index}>
                    <br></br> {_str}
                  </React.Fragment>
                ))}
              </p>
            ))}

          {conteudo[currentContent]?.notes?.text
            ?.split('\n\n')
            ?.map((str, key) => (
              <p key={key}>
                {str?.split('\n')?.map((_str, index) => (
                  <React.Fragment key={index}>
                    <br></br> {_str}
                  </React.Fragment>
                ))}
              </p>
            ))}
        </article>
      </section>

      <footer>
        <IconFlag
          src={IconFlagEUA}
          onClick={() => handleAudioGoogle('en-US', 'en-US-Wavenet-A')}
        />
        <audio src={audioText} type="audio/mp3" controls />
        <IconFlag
          src={IconFlagEngland}
          onClick={() => handleAudioGoogle('en-GB', 'en-GB-Wavenet-A')}
        />

        {/* {currentContent === conteudo.length - 1 && (
          <IconBack src={IconBackURL} onClick={() => handleGoToBeginning()} />
        )} */}
      </footer>
    </Container>
  );
}
