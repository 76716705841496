import styled from 'styled-components';

export const FooterCopy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 38.1rem; */
  color: var(--secondary-color-blue);
  margin-top: 2rem;
  margin-bottom: 1rem;

  strong {
    color: #083059;
    margin-left: 1rem;
    font-size: 1.4rem;
  }
`;

export const FooterIconTalks = styled.img`
  margin-right: 2rem;
`;

export const TextCopy = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;
`;
