import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from '../../components/Button';
import { Span } from '../../components/Span';
import Logo from '../../assets/icon_logo_login.png';
import IconFooterTalks from '../../assets/icon-green.svg';
import { toast } from 'react-toastify';

import {
  Container,
  Column1,
  IconsLeft,
  Column2,
  ScrollForm,
  BaseDiv,
  Column2Form,
  ImageLogo,
  FooterCopy,
  FooterIconTalks,
  TextCopy,
  LinkConfirm,
  Error,
} from './styles';
import { validEmail } from '../../utils/mask';
import { api } from '../../services/api';

export function ForgotPassword() {
  const { goBack, history } = useHistory();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(true);

  const handleSendEmail = useCallback(async () => {
    const isMailValid = validEmail(email);

    if (!isMailValid) {
      setEmailIsValid(isMailValid);
      return;
    }

    try {
      const response = await api.post('/password/forgot', {
        email,
      });
      goBack();
      toast('Um e-mail de recuperação foi enviado ao seu e-mail');
    } catch (error) {
      console.log('[ERROR SEND EMAIL]', error);
      toast('Ops! Ocorreu um erro ao enviar o e-mail de recuperação');
      goBack();
    }
  }, [email]);

  return (
    <Container>
      <Column1>
        <IconsLeft />
      </Column1>
      <Column2>
        <ScrollForm>
          <ImageLogo src={Logo} />
          <Column2Form>
            <BaseDiv>
              <Span>
                {t('Informe o seu e-mail para recuperar a sua senha')}
              </Span>
            </BaseDiv>
            <BaseDiv>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail"
                name="email"
              />
              {!emailIsValid && <Error>{t('Email inválido')}</Error>}
            </BaseDiv>
            <BaseDiv>
              <Button
                onClick={handleSendEmail}
                color="#243342"
                fontColor="#fff"
                title="Enviar Link de Recuperação"
              />
            </BaseDiv>
          </Column2Form>
          <FooterCopy>
            <FooterIconTalks src={IconFooterTalks} />
            <TextCopy>Copyright © 2021 Table Talks</TextCopy>
          </FooterCopy>
        </ScrollForm>
      </Column2>
    </Container>
  );
}
