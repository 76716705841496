import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 0 2rem;
`;

export const ContainerTypes = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 5.8rem;
  width: 100%;

  @media (max-width: 1280px) {
    height: 4rem;
  }
`;

export const ContainerSpace = styled.a`
  width: 13rem;
  margin-right: 2rem;
  cursor: pointer;

  ${(props) =>
    props.download &&
    css`
      width: 0;
    `}

  .iconify {
    font-size: 1.4rem;
  }

  @media (max-width: 1280px) {
    margin-right: 1rem;
    width: 9.4rem;

    ${(props) =>
      props.download &&
      css`
        width: 0;
      `}

    .iconify {
      font-size: 1.4rem;
    }

    ${(props) =>
      props.list &&
      css`
        margin-right: 2rem;
      `}
  }
`;

export const TitleType = styled.span`
  font-size: 1.3rem;
`;

export const ListFinancial = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid var(--gray3);
`;
