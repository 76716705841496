import React, { useState } from 'react';
import {
  Document, Page, pdfjs, PDFPageProxy,
} from 'react-pdf';

import FilePdf from '../../../assets/methods_guide.pdf';
import { ButtonPass, Container, ContainerPDFControls } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfProps {
  containerRef: React.RefObject<HTMLDivElement>;
  numberOfPages: number;
  activePage: number;
  onNextPage: () => void;
  onPrevPage: () => void;
  onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void;
}

export const Pdf = ({
  containerRef,
  numberOfPages,
  activePage,
  onNextPage,
  onPrevPage,
  onDocumentLoadSuccess,
}: PdfProps) => {
  const [page, setPage] = useState<PDFPageProxy>({} as PDFPageProxy);

  const calculateScale = ({ originalWidth, originalHeight }: PDFPageProxy) => {
    const globalScale = 1;

    if (!page || !containerRef.current) {
      return globalScale;
    }

    const { clientWidth, clientHeight } = containerRef.current;

    const scaleX = clientWidth / originalWidth;
    const scaleY = clientHeight / originalHeight;

    return globalScale * Math.max(scaleX, scaleY) - 0.045;
  };

  return (
    <Container>
      <Document file={FilePdf} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          scale={calculateScale(page)}
          pageNumber={activePage}
          onLoadSuccess={(value) => {
            setPage(value);
          }}
        />
      </Document>
      <span>
        {activePage}
        {' '}
        of
        {numberOfPages}
      </span>
      <ContainerPDFControls>
        <ButtonPass disabled={activePage <= 1} onClick={onPrevPage}>
          <span className="iconify" data-icon="dashicons:arrow-left-alt2" />
        </ButtonPass>
        <ButtonPass
          next
          disabled={activePage === numberOfPages}
          onClick={onNextPage}
        >
          <span className="iconify" data-icon="dashicons:arrow-right-alt2" />
        </ButtonPass>
      </ContainerPDFControls>
    </Container>
  );
};
