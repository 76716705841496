import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import LogoImage from '../../assets/logo_top_banner.png';
import LogoFooterWhite from '../../assets/logo_white_footer.svg';
import TabPanel from '../../components/TabPanel';
import { useAuth } from '../../hooks/AuthProvider';
import { api } from '../../services/api';
import { blobToBase64 } from '../../utils/blobToBase64';
import { Done } from './Done';
import { MultipleChoices } from './MultipleChoices';
import {
  Container,
  ContainerFooter,
  Logo,
  LogoFooter,
  TextFooter,
  LogoContainer,
  Content,
  LogoutButton,
} from './styles';
import { TextReading } from './TextReading';
import { Written } from './Written';

export interface MultipleChoiceAnswer {
  questionId: string;
  question: string;
  answer: number;
}

export interface WrittenAnswer {
  questionId: string;
  question: string;
  answer: string;
}

export interface TextReadingAnswer {
  questionId: string;
  question: string;
  audioURL: string | null;
}

export const PlacementTest = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState<
    MultipleChoiceAnswer[]
  >([]);
  const [writtenAnswers, setWrittenAnswers] = useState<WrittenAnswer[]>([]);
  const [textReadingAnswer, setTextReadingAnswer] = useState<TextReadingAnswer>();
  const { signOut } = useAuth();

  const handleNext = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  const handleMultipleChoiceAnswerChange = useCallback(
    (answer: MultipleChoiceAnswer) => {
      setMultipleChoiceAnswers((prevState) => {
        const dataUpdate = [...prevState];

        const answerIndex = dataUpdate.findIndex(
          (a) => a.questionId === answer.questionId,
        );

        if (answerIndex <= -1) {
          return [...prevState, answer];
        }

        dataUpdate[answerIndex] = answer;

        return [...dataUpdate];
      });
    },
    [],
  );

  const handleWrittenAnswerChange = useCallback((answer: WrittenAnswer) => {
    setWrittenAnswers((prevState) => {
      const dataUpdate = [...prevState];

      const answerIndex = dataUpdate.findIndex(
        (a) => a.questionId === answer.questionId,
      );

      if (answerIndex <= -1) {
        return [...prevState, answer];
      }

      dataUpdate[answerIndex] = answer;

      return [...dataUpdate];
    });
  }, []);

  const handleTextReadingAnswerChange = useCallback(
    (answer: TextReadingAnswer) => {
      setTextReadingAnswer(answer);
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);

      const isLastStep = activeStep === 2;

      if (isLastStep) {
        const base64Data = await blobToBase64(
          textReadingAnswer?.audioURL as string,
        );

        const {
          data: { url },
        } = await axios.post(process.env.REACT_APP_AUDIO_UPLOAD_URL as string, {
          file: { mime: 'video/webm', data: base64Data },
        });

        await api.post('/placement-test', {
          multipleChoiceAnswers,
          writtenAnswers,
          textReadingAnswer: {
            questionId: textReadingAnswer?.questionId,
            question: textReadingAnswer?.question,
            answer: url,
          },
        });

        toast('Your application has been submitted!', { type: 'success' });

        handleNext();
      } else {
        handleNext();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  }, [activeStep, multipleChoiceAnswers, writtenAnswers, textReadingAnswer]);

  return (
    <Container>
      <Content>
        <LogoContainer>
          <Logo src={LogoImage} />
        </LogoContainer>
        <LogoutButton onClick={signOut}>Logout</LogoutButton>
        <TabPanel index={0} value={activeStep}>
          <MultipleChoices
            answers={multipleChoiceAnswers}
            onMultipleChoiceAnswerChange={handleMultipleChoiceAnswerChange}
            onSubmit={handleSubmit}
          />
        </TabPanel>
        <TabPanel index={1} value={activeStep}>
          <Written
            answers={writtenAnswers}
            onWrittenAnswerChange={handleWrittenAnswerChange}
            onSubmit={handleSubmit}
          />
        </TabPanel>
        <TabPanel index={2} value={activeStep}>
          <TextReading
            answer={textReadingAnswer}
            isSubmitting={isSubmitting}
            onTextReadingAnswerChange={handleTextReadingAnswerChange}
            onSubmit={handleSubmit}
          />
        </TabPanel>
        <TabPanel index={3} value={activeStep}>
          <div style={{ textAlign: 'center' }}>
            <Done />
          </div>
        </TabPanel>
        <ContainerFooter>
          <LogoFooter src={LogoFooterWhite} />
          <TextFooter>Copyright © 2021 Table Talks</TextFooter>
        </ContainerFooter>
      </Content>
    </Container>
  );
};
