import styled, { css } from 'styled-components';

interface TextTopProps {
  button?: boolean;
  quetions?: boolean;
  warning?: boolean;
}

export const ContainerQuestions = styled.div`
  width: 80rem;
  height: 50rem;
  background: #fff;
  margin: 3rem 0;
  border-radius: 0.4rem;
  display: inline-block;
`;

export const ContainerTop = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid var(--gray3);

  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Text = styled.span<TextTopProps>`
  font-size: 1.8rem;
  color: #5e5873;
  font-weight: 600;

  ${(props) => props.button
    && css`
      font-weight: 500;
      color: #243342;
      font-size: 1.5rem;
      padding: 0 2rem;
    `}

  ${(props) => props.quetions
    && css`
      padding: 2rem 0;
    `}

  ${(props) => props.warning
    && css`
      padding: 2rem 0;
      font-weight: 500;
      margin: 0 12rem;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
