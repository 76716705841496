import { format, parseISO } from 'date-fns';
import React from 'react';
import { useOnlineStatus } from '../../../context/OnlineStatusContext';
import {
  Avatar,
  ChatTabContainer,
  ContentAvatar,
  Indicator,
  InfosUser,
  NameUser,
  NewMessage,
  Number,
  Text1,
  WrapperTime,
  BadgeStatus,
} from './styles';

export function ChatTab({ isOpen, badgeCount, data, handleMessageActive }) {
  const { checkIsOnline } = useOnlineStatus();
  if (typeof data != 'object') return false;

  return (
    <ChatTabContainer
      white
      isOpen={isOpen}
      onClick={() => handleMessageActive(data)}
    >
      <span className="arrow" />
      <Indicator />
      <ContentAvatar>
        <BadgeStatus online={checkIsOnline(data.id)} />
        <Avatar src={data.avatarURL} />
      </ContentAvatar>

      <InfosUser>
        <NameUser black>{data.fullName ?? data.name}</NameUser>
        <Text1 gray>{data.content}</Text1>
      </InfosUser>
      <WrapperTime>
        <Text1 gray>{format(parseISO(data.created_at), 'H:mm')}</Text1>
        <NewMessage>
          {badgeCount > 0 && (
            <Number badge isOpen={isOpen} badgeCount={badgeCount}>
              {badgeCount}
            </Number>
          )}
        </NewMessage>
      </WrapperTime>
    </ChatTabContainer>
  );
}
