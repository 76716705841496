import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../hooks/AuthProvider';
import {
  Container,
  ContainerTitle,
  Title,
  Wrapper,
  ContainerList,
  Profile,
  Image,
  TextDetails,
} from './styles';
import { api } from '../../../services/api';
import { formatPrice } from '../../../utils/format';
import { useTranslation } from 'react-i18next';

export function ContainerClass() {
  const { user, typeLogin } = useAuth();
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);

  async function fetchStudents() {
    await api.get('/account/students/me').then(response => {
      console.log(response);
      setStudents(response.data);
    });
  }

  async function fetchTeachers() {
    await api.get('/account/teachers/me').then(response => {
      console.log(response);
      setTeachers(response.data);
    });
  }

  useEffect(() => {
    if (user.type === 'student') {
      fetchTeachers();
    }
    if (user.type === 'teacher') {
      fetchStudents();
    }
  }, [user]);

  return (
    <Container>
      {!typeLogin ? (
        <>
          <ContainerTitle>
            <Title>{t('yourStudents')}</Title>
          </ContainerTitle>
          <ContainerTitle header>
            <Title header>{t('student')}</Title>
            <Profile>
              <Title values>{t('total')}</Title>
              <Title values>{t('value')}</Title>
            </Profile>
          </ContainerTitle>
        </>
      ) : (
        <>
          <ContainerTitle>
            <Title>{t('myTeachers')}</Title>
          </ContainerTitle>
          <ContainerTitle header>
            <Title header>{t('professores')}</Title>
            <Profile>
              <Title values>{t('total')}</Title>
            </Profile>
          </ContainerTitle>
        </>
      )}
      <Wrapper>
        {students.map(item => (
          <ContainerList>
            <Profile>
              {item?.student?.avatarURL ?? <Image src={item.student.avatarURL} />}
              <TextDetails
                name
              >{`${item.student.name} ${item.student.lastName}`}</TextDetails>
            </Profile>
            <Profile>
              <TextDetails classes>
                {item.classes} {t('aulas')}
              </TextDetails>
              {!typeLogin && (
                <TextDetails classes>{formatPrice(item.value)}</TextDetails>
              )}
            </Profile>
          </ContainerList>
        ))}
        {teachers.map(item => (
          <ContainerList>
            <Profile>
              <Image src={item.teacher.avatarURL} />
              <TextDetails
                name
              >{`${item.teacher.name} ${item.teacher.lastName}`}</TextDetails>
            </Profile>
            <Profile>
              <TextDetails classes>
                {item.classes} {t('aulas')}
              </TextDetails>
            </Profile>
          </ContainerList>
        ))}
      </Wrapper>
    </Container>
  );
}
