import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
  height: 46rem;
  background: #fff;
  border: 1px solid var(--gray3);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 2rem;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MethodPayment = styled.div`
  display: flex;
  flex-direction: column;
  width: 38.2rem;
  margin-right: 3rem;

  span {
    margin: 0 0 0.5rem 0;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const DivInput = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .iconify {
    font-size: 2rem;
    color: #b9b9c3;
  }

  div {
    padding: 0 1.5rem;
    height: 4.8rem;
    border: 1px solid var(--gray3);
    border-radius: 0.5rem 0 0 0.5rem;

    display: flex;
    align-items: center;
  }
`;

export const Input = styled.input`
  height: 4.8rem;
  border-top: 1px solid var(--gray3);
  border-right: 1px solid var(--gray3);
  border-bottom: 1px solid var(--gray3);
  border-radius: 0 0.5rem 0.5rem 0;
  padding: 0 1rem;
  width: 100%;
`;

export const Button = styled.button`
  height: 3.8rem;
  width: 38.5rem;
  background: #243342;
  color: #fff;
  border-radius: 0.5rem;
  margin-left: 2rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    width: 95%;
  }
  @media (max-width: 468px) {
    width: 90%;
  }
`;
