import styled, { css } from 'styled-components';

export const ContainerCalendar = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  height: 15rem;
  width: ${(props) => props.width};
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    height: 0.3rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 1rem;
  }
  ::selection {
    background-color: transparent;
  }
`;

export const BoxSchedule = styled.div`
  background: #f9f8fe;

  height: 10rem;
  min-width: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0.5rem;
  margin: 0 1.2rem;

  span {
    color: #083059;
  }

  ${(props) =>
    props.list === 'true' &&
    css`
      height: 7rem;
      min-width: 9.8rem;
      border: 1px solid #dbad00;
    `}
`;

export const TextCalendar = styled.span`
  color: #fff;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;

  ${(props) =>
    props.top &&
    css`
      margin-bottom: 1rem;
      margin-top: 1rem;
    `}

  .iconify {
    margin: 0 0.5rem;
  }

  ${(props) =>
    props.list === 'true' &&
    css`
      font-size: 1rem;
    `}
`;

export const Icon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -1.5rem;
  background: #dbad00;
  padding: 0.3rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;

  .iconify {
    color: #083059;
  }
`;

export const Button = styled.button`
  font-size: 1.5rem;
  height: 100%;
  color: #083059;

  ${(props) =>
    props.calendar &&
    css`
      color: #fff;
      cursor: pointer;
    `}

  ${(props) =>
    props.search &&
    css`
      height: 4rem;
      background: #dbad00;
      color: #fff;
      padding: 0 3rem;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: 0.2s;

      :hover {
        filter: brightness(0.8);
      }
    `}

    ${(props) =>
    props.list === 'true' &&
    css`
      color: #083059;
    `}
`;

export const SpanIconClose = styled.span`
  font-size: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  color: #083059;
  cursor: pointer;
`;
