/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { format } from 'date-fns';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import {
  IoCheckmarkSharp,
  IoClose,
  IoReloadOutline,
  IoSendSharp,
} from 'react-icons/io5';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import banner from '../../../assets/exams-banner.jpg';
import logo from '../../../assets/logo_green.png';
import { api } from '../../../services/api';
import { customState } from '../../../utils/customState';
import {
  Button,
  ButtonsContainer,
  Container,
  ExamContainer,
  OptionItem,
  OptionsContainer,
  OralExamContainer,
  ProgressContainer,
  ScoreContainer,
  ButtonStart,
} from './styles';

interface IExamsProps {
  examLevel: 'midterm' | 'final';
  examType: 'oral' | 'written';
  onCancel: () => void;
  onFinish: () => void;
  level?: {
    method: Method;
    mission: Mission;
  };
  location0: any;
  nextMissionPositionCallback: () => void;
}

interface Method {
  _id: string;
  id?: string;
  title: string;
}

interface Mission {
  _id: string;
  id?: string;
  title: string;
}

interface WrittenQuestion {
  text: string;
  _id: string;
}

export interface Exam {
  _id: string;
  method: string;
  mission: Mission;
  type: string;
  writtenQuestions: WrittenQuestion[];
}

interface Correction {
  questionId: string;
  pronunciation: boolean;
  vocabulary: boolean;
  structure: boolean;
}

const OPTIONS: Array<{
  label: string;
  value: 'pronunciation' | 'vocabulary' | 'structure';
}> = [
  { label: 'Pronunciation', value: 'pronunciation' },
  { label: 'Vocabulary', value: 'vocabulary' },
  { label: 'Structure', value: 'structure' },
];

function Exams({
  examLevel = 'midterm',
  examType = 'oral',
  onCancel,
  onFinish,
  level,
  location0,
}: IExamsProps) {
  const location: { state: any } = customState(location0);
  const { t } = useTranslation();

  const [exam, setExam] = useState<Exam>({} as Exam);
  const [activePage, setActivePage] = useState(0);
  const [hasExamStarted, setHasExamStarted] = useState(false);

  const [correction, setCorrection] = useState<Correction[]>([]);
  const [score, setScore] = useState(null);

  const progress = useMemo(() => {
    if (exam.writtenQuestions) {
      const { writtenQuestions } = exam;
      const total = writtenQuestions.length;

      return `${Math.round(
        ((activePage === 0 ? activePage : activePage + 1) * 100) / total,
      )}%`;
    }
    return '';
  }, [activePage, exam]);

  const activeQuestion = useMemo(() => {
    if (!exam.writtenQuestions) return null;
    return exam.writtenQuestions[activePage];
  }, [activePage, exam]);

  const fetchExam = useCallback(async () => {
    await api
      .get<Exam>(`/exams/class/${examLevel}/${examType}`, {
        params: {
          method: level?.method?.id ?? location.state.method.id,
        },
      })
      .then((response) => {
        if (response.data) {
          setExam(response.data);
          setCorrection(
            response?.data?.writtenQuestions?.map((question) => ({
              questionId: question._id,
              pronunciation: true,
              vocabulary: true,
              structure: true,
            })),
          );
        } else {
          onCancel();

          toast(t('thereAreNoExamsAvailable'), {
            type: 'warning',
          });
        }
      });
  }, [
    examLevel,
    examType,
    level?.method?.id,
    location.state.method.id,
    onCancel,
  ]);

  useEffect(() => {
    fetchExam();
  }, []);

  const handleBack = useCallback(() => {
    setActivePage((prevState) => {
      if (activePage === 0) {
        return prevState;
      }
      return prevState - 1;
    });
  }, [activePage]);

  const handleNext = useCallback(() => {
    setActivePage((prevState) => {
      if (prevState + 1 === exam?.writtenQuestions?.length) {
        return prevState;
      }
      return prevState + 1;
    });
  }, [exam?.writtenQuestions?.length]);

  const handleCorrection = useCallback(
    (
      questionId: string,
      key: 'pronunciation' | 'vocabulary' | 'structure',
      value: boolean,
    ) => {
      setCorrection((prevState) => {
        const dataUpdate = [...prevState];

        const answerIndex = dataUpdate.findIndex(
          (a) => a.questionId === questionId,
        );

        if (answerIndex <= -1) {
          return [...prevState, { ...prevState[answerIndex], [key]: value }];
        }

        dataUpdate[answerIndex] = {
          ...dataUpdate[answerIndex],
          [key]: value,
        };

        return [...dataUpdate];
      });
    },
    [],
  );

  const handleRestart = useCallback(() => {
    setActivePage(0);
    setHasExamStarted(false);
    setCorrection(
      exam.writtenQuestions.map((question) => ({
        questionId: question._id,
        pronunciation: true,
        vocabulary: true,
        structure: true,
      })),
    );
    setScore(null);
  }, [exam.writtenQuestions]);

  const handleSubmit = useCallback(async () => {
    await api
      .post('/exams/oral/submit', {
        correction,
        examId: exam._id,
        appointmentId: location.state.appointmentId,
        teacherId: location.state.user.teacher.id,
        studentId: location.state.user.student.id,
      })
      .then((response) => {
        setScore(response.data.score);
      })
      .catch(console.error);
  }, [correction, exam, location]);

  function handleExamStart() {
    setHasExamStarted(true);
  }

  return (
    <Container>
      <h2>
        {examLevel === 'midterm' ? t('midtermOralExam') : t('finalOralExam')}
      </h2>

      {hasExamStarted ? (
        <OralExamContainer>
          {score !== null && (
            <ScoreContainer>
              <span />
              {score >= 75 ? (
                <>
                  <p>
                    {t('youpassedCongratulations')}
                    <br />
                    {' '}
                    {t('weAreVeryProudOfYou')}
                    <br />
                    {' '}
                    {t('keepUpTheGoodWork')}
                    <br />
                    {' '}
                    The score is:
                    <b style={{ paddingLeft: '5px' }}>
                      {score}
                      /100
                    </b>
                  </p>
                  <Button
                    style={{ marginLeft: 'auto' }}
                    backgroundColor="#dbad00"
                    onClick={onFinish}
                  >
                    <FaArrowRight className="icon" />
                    <span>{t('nextMission')}</span>
                  </Button>
                </>
              ) : (
                <>
                  <p>
                    {t('youFailedTheExam')}
                    <br />
                    {' '}
                    {t('butItIsNotFailure')}
                    <br />
                    {' '}
                    {t('itIsUnfinishedSuccess')}
                    .
                    <br />
                    {' '}
                    {t('goodLuckNextTime')}
                    <br />
                    {' '}
                    The score is:
                    <b style={{ paddingLeft: '5px' }}>
                      {score}
                      /100
                    </b>
                  </p>
                  <Button
                    style={{ marginLeft: 'auto' }}
                    backgroundColor="#dbad00"
                    onClick={handleRestart}
                  >
                    <IoReloadOutline className="icon" />
                    <span>{t('restartTest')}</span>
                  </Button>
                </>
              )}
            </ScoreContainer>
          )}
          {activeQuestion && score == null && (
            <OptionsContainer>
              <h2>{activeQuestion?.text}</h2>
              {OPTIONS.map(({ label, value }) => {
                const isCorrect = Boolean(
                  correction.find((c) => c.questionId === activeQuestion._id)?.[
                    value
                  ],
                );

                return (
                  <OptionItem
                    key={value}
                    error={!isCorrect}
                    onClick={() => {
                      handleCorrection(activeQuestion._id, value, !isCorrect);
                    }}
                  >
                    {isCorrect ? (
                      <IoCheckmarkSharp
                        className="icon"
                        color="#fff"
                        size={20}
                      />
                    ) : (
                      <IoClose className="icon" color="#fff" size={20} />
                    )}
                    <span>{label}</span>
                    <span />
                  </OptionItem>
                );
              })}
              <ButtonsContainer>
                {activePage === 0 ? (
                  <span />
                ) : (
                  <Button backgroundColor="#243342" onClick={handleBack}>
                    <FaArrowLeft className="icon" />
                    <span>{t('voltar')}</span>
                  </Button>
                )}
                {activePage + 1 === exam?.writtenQuestions.length ? (
                  <Button backgroundColor="#dbad00" onClick={handleSubmit}>
                    <IoSendSharp
                      style={{ transform: 'rotate(320deg)' }}
                      className="icon"
                    />
                    {t('finalizar')}
                  </Button>
                ) : (
                  <Button backgroundColor="#dbad00" onClick={handleNext}>
                    <FaArrowRight className="icon" />
                    <span>{t('next')}</span>
                  </Button>
                )}
              </ButtonsContainer>
            </OptionsContainer>
          )}
          <ProgressContainer>
            <img src={logo} alt="logo" />
            <span>
              {t('progress')}
              :
              {' '}
            </span>
            <div className="wrapper">
              <div className="progress-bar">
                <span
                  className="progress-bar-fill"
                  style={{ width: progress }}
                />
              </div>
            </div>
          </ProgressContainer>
        </OralExamContainer>
      ) : (
        <>
          <img src={banner} alt="logo" />
          <ExamContainer>
            <div className="exam-info-container">
              <p>{format(new Date(), 'MMMM dd, yyyy hh:mm')}</p>
              <p>
                {t('Professor')}
                :
                {location.state.user.teacher.name}
              </p>
            </div>

            <ButtonStart>
              <button type="button" onClick={handleExamStart}>
                {t('start')}
              </button>
            </ButtonStart>

            <button type="button" onClick={onCancel}>
              {t('cancel')}
            </button>
          </ExamContainer>
        </>
      )}
    </Container>
  );
}

export { Exams };
