import React, { useEffect, useState } from 'react';
import { Container, Input, ContainerDate, Text } from './styles';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

export function HeaderReports({
  search = '',
  onSearchChange,
  startDate = new Date(),
  endDate = new Date(),
  onStartDateChange,
  onEndDateChange,
  searchEnabled,
}) {
  const [tempSearch, setTempSearch] = useState('');
  if (typeof startDate === 'string' && startDate.indexOf(':') === -1) {
    startDate += ' 00:00';
  }

  if (typeof endDate === 'string' && endDate.indexOf(':') === -1) {
    endDate += ' 00:00';
  }
  useEffect(() => {
    const delayDevounceFn = setTimeout(() => {
      onSearchChange(tempSearch);
    }, 1000);
    return () => clearTimeout(delayDevounceFn);
  }, [tempSearch]);
  const { t } = useTranslation();

  return (
    <Container>
      {searchEnabled && (
        <Input
          placeholder="Search"
          type="text"
          value={tempSearch}
          onChange={e => {
            setTempSearch(e.target.value);
          }}
        />
      )}
      <ContainerDate>
        <Text>{t('from')}</Text>
        <span className="datepicker-toggle">
          <span
            className="iconify"
            data-icon="akar-icons:calendar"
            data-inline="false"
          />
          <span>{format(new Date(startDate), 'MMM/yyyy')}</span>
          <Input
            type="date"
            className="datepicker-input"
            value={endDate}
            onChange={e => {
              onStartDateChange(e.target.value);
            }}
          />
          <span
            className="iconify"
            data-icon="dashicons:arrow-down-alt2"
            data-inline="false"
          />
        </span>
        <Text>{t('to')}</Text>
        <span className="datepicker-toggle">
          <span
            className="iconify"
            data-icon="akar-icons:calendar"
            data-inline="false"
          />
          <span>{format(new Date(endDate), 'MMM/yyyy')}</span>
          <Input
            type="date"
            className="datepicker-input"
            value={endDate}
            onChange={e => {
              onEndDateChange(e.target.value);
            }}
          />
          <span
            className="iconify"
            data-icon="dashicons:arrow-down-alt2"
            data-inline="false"
          />
        </span>
      </ContainerDate>
    </Container>
  );
}
