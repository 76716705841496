import { Menu, MenuItem } from '@szhsin/react-menu';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { FaFacebookSquare, FaLinkedin, FaTwitter } from 'react-icons/fa';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { Document, Page } from 'react-pdf';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import IconBreakthrough from '../../../assets/svgMethod/icon_breakthrough.svg';
import { Header } from '../../../components/Header';
import { api } from '../../../services/api';
import {
  Button,
  CardStage,
  Container,
  ContainerButton,
  ContainerCertificate,
  ContainerText,
  HeaderStage,
  IconStage,
  Text,
  Wrapper,
} from './styles';
import { useTranslation } from 'react-i18next';

const STATUS = {
  approved: 'granted',
  requested: 'certificateRequested',
  rejected: 'certificateRequestDenied',
  awaiting_request: 'requestCertificate',
};

export function Certificate() {
  const { t } = useTranslation();

  const [certificates, setCertificates] = useState([]);
  const [showColapseCertificate, setShowColapseCertificate] = useState({});

  async function fetchCertificates() {
    const response = await api.get('/certificates');
    console.log(response.data)
    setCertificates(response.data);
  }

  useEffect(() => {
    fetchCertificates();
  }, []);

  async function handleRequestCertificate(id) {
    try {
      const requestDate = new Date();

      await api.patch(`/certificates/request/${id}`, {
        requestDate,
      });

      toast('Certificate issued!', {
        type: 'success',
      });
    } catch (err) {
      toast('Error', {
        type: 'error',
      });
    } finally {
      fetchCertificates();
    }
  }

  function handleFacebookShare(url, quote) {
    const params = queryString.stringify({ u: url, quote });
    window.open(
      `https://www.facebook.com/sharer/sharer.php?${params}`,
      '_blank',
    );
  }

  function handleLinkedinShare(url) {
    const params = queryString.stringify({ url });
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?${params}`,
      '_blank',
    );
  }

  function handleTwitterShare(url, text) {
    const params = queryString.stringify({ url, text });
    window.open(`https://twitter.com/intent/tweet??${params}`, '_blank');
  }

  return (
    <Container>
      <Header />

      <Wrapper>
        <Text title>{t('certificates')}</Text>
        {certificates.map(certificate => (
          <>
            <HeaderStage>
              <CardStage>
                <IconStage src={IconBreakthrough} />
                <ContainerText>
                  <Text>{certificate.method.title}</Text>
                  <Text>{certificate.mission.title}</Text>
                </ContainerText>
                <span className="arrow" />
              </CardStage>
              <Button
                onClick={() => {
                  if (certificate.status === 'awaiting_request') {
                    handleRequestCertificate(certificate.id);
                  }
                }}
              >
                {t(STATUS[certificate.status])}
              </Button>
              {certificate.status === 'approved' && certificate.fileURL && (
                <>
                  <Button
                    view
                    onClick={() =>
                      setShowColapseCertificate({
                        [certificate.id]:
                          !showColapseCertificate[certificate.id],
                      })
                    }
                  >
                    {t('viewCertificate')}
                  </Button>
                  <Button
                    next
                    onClick={() => {
                      setShowColapseCertificate({
                        [certificate.id]:
                          !showColapseCertificate[certificate.id],
                      });
                    }}
                  >
                    {showColapseCertificate[certificate.id] ? (
                      <MdOutlineKeyboardArrowDown size="3rem" />
                    ) : (
                      <MdOutlineKeyboardArrowRight size="3rem" />
                    )}
                  </Button>
                </>
              )}
            </HeaderStage>
            {showColapseCertificate[certificate.id] && (
              <ContainerCertificate>
                <Document file={certificate.fileURL}>
                  <Page pageNumber={1} />
                </Document>
                <ContainerButton>
                  <Menu
                    menuStyles={{ width: '23rem' }}
                    menuButton={<Button certificate> {t('share')}</Button>}
                  >
                    <MenuItem
                      className="menuitem"
                      onClick={() => {
                        handleFacebookShare(
                          'https://developer.mozilla.org/pt-BR/docs/Web/HTML',
                          'Aprenda desenvolvimento web no MDN!',
                        );
                      }}
                    >
                      <FaFacebookSquare />
                      Facebook
                    </MenuItem>
                    <MenuItem
                      className="menuitem"
                      onClick={() => {
                        handleLinkedinShare(
                          'https://developer.mozilla.org/pt-BR/docs/Web/HTML',
                        );
                      }}
                    >
                      <FaLinkedin />
                      Linkedin
                    </MenuItem>
                    <MenuItem
                      className="menuitem"
                      onClick={() => {
                        handleTwitterShare(
                          'https://developer.mozilla.org/pt-BR/docs/Web/HTML',
                          'Aprenda desenvolvimento web no MDN!',
                        );
                      }}
                    >
                      <FaTwitter />
                      Twitter
                    </MenuItem>
                  </Menu>

                  <a href={certificate.fileURL} download target="_blank" >
                    <Button certificate> {t('download')}</Button>
                  </a>
                </ContainerButton>
              </ContainerCertificate>
            )}
          </>
        ))}
      </Wrapper>
    </Container>
  );
}
