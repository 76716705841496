import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { AiOutlineClose } from 'react-icons/ai';
import IconFlagEngland from '../../../../../assets/icon_bandeira_inglaterra.png';
import IconFlagEUA from '../../../../../assets/icon_bandeira_usa.png';
import * as C from '../All/styles';

import { Container, Button, ContainerText, Text, IconFlag } from './styles';
import { api } from '../../../../../services/api';
import { useTranslation } from 'react-i18next';

let audio = null;

export function Vocabulary(props) {
  const { conteudo, currentContent, setCurrentContent } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const handleAudioGoogle = async (languageCode, name) => {
    try {
      const voice = { languageCode, name };
      const text = document.getElementsByName('text')[0].innerText;

      const { data } = await api.post(`text/synthesize`, {
        voice,
        text,
      });

      if (audio) audio.pause();
      audio = new Audio(data.fileURL);
      audio.play();
    } catch (err) {
      console.log(err);
      toast('There is an audio recording issue. Please check your settings.', {
        type: 'error',
      });
    }
  };

  const handleShowDialog = value => {
    setIsOpen(value);
  };

  const handleArrow = ({ key }) => {
    if (key === 'ArrowLeft') {
      document.querySelector('.arrow-left')?.click();
    }

    if (key === 'ArrowRight') {
      document.querySelector('.arrow-right')?.click();
    }
  };

  function handleArrowClick(e) {
    if (e === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return;
        }

        return prevState - 1;
      });
    }
    if (e === 'ArrowRight') {
      setCurrentContent(prevState => {
        if (prevState === conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleArrow);

    return () => {
      window.removeEventListener('keyup', handleArrow);
    };
  }, []);

  useEffect(() => {
    if (
      isOpen &&
      conteudo[currentContent]?.vocabulary?.fileType.indexOf('video') > -1
    ) {
      setIsOpen(false);
    }
  }, [conteudo, currentContent]);

  return (
    <Container>
      <header>
        <div className="column">
          {currentContent === conteudo.length - 1 && (
            <Button onClick={() => setCurrentContent(0)}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
          <ContainerText>
            <Text>
              <strong>{conteudo[currentContent]?.method?.title}</strong>
            </Text>
            <Text>{conteudo[currentContent]?.mission?.title}</Text>
            <Text finaly>{t('vocabulario')}</Text>
          </ContainerText>
        </div>
        <div className="column">
          {currentContent !== 0 && (
            <Button
              left
              onClick={e => handleArrowClick('ArrowLeft')}
              className="arrow-left"
            >
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
          {currentContent !== conteudo.length - 1 && (
            <Button
              finaly
              onClick={e => handleArrowClick('ArrowRight')}
              className="arrow-right"
            >
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
        </div>
      </header>

      {isOpen &&
        conteudo[currentContent]?.vocabulary?.fileType.indexOf('image') >
          -1 && (
          <C.ContainerImageFullScreen>
            <C.WrapperHeaderImageFullScreen>
              <C.TitleImageFullScreen>
                {conteudo[currentContent]?.title}
              </C.TitleImageFullScreen>
            </C.WrapperHeaderImageFullScreen>
            <C.WrapperButtonClose
              type="button"
              onClick={() => handleShowDialog(false)}
            >
              <AiOutlineClose size={30} />
            </C.WrapperButtonClose>

            <C.ImageFull
              src={conteudo[currentContent]?.vocabulary?.fileURL}
              alt={conteudo[currentContent]?.title}
            />
          </C.ContainerImageFullScreen>
        )}

      {conteudo[currentContent]?.vocabulary?.fileType.indexOf('image') > -1 && (
        <C.ContainerImage>
          <C.Image
            onClick={() => handleShowDialog(true)}
            src={conteudo[currentContent]?.vocabulary?.fileURL}
            alt={conteudo[currentContent]?.title}
          />
        </C.ContainerImage>
      )}

      {conteudo[currentContent]?.vocabulary?.fileType.indexOf('video') > -1 && (
        <center
          style={
            {
              /*height: 'calc(100vh - 325px)'*/
            }
          }
        >
          <video
            style={{ maxWidth: '100%', height: '100%' }}
            src={conteudo[currentContent]?.vocabulary?.fileURL}
            type="video/mp4"
            controls
          />
        </center>
      )}

      <footer>
        <IconFlag
          src={IconFlagEUA}
          onClick={() => handleAudioGoogle('en-US', 'en-US-Wavenet-A')}
        />
        <Text name="text" footer>
          {conteudo[currentContent]?.title}
        </Text>
        <IconFlag
          src={IconFlagEngland}
          onClick={() => handleAudioGoogle('en-GB', 'en-GB-Wavenet-A')}
        />
      </footer>
    </Container>
  );
}
