import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import IconBreakthrough from '../../../assets/svgMethod/icon_breakthrough.svg';
// import IconThreshold from '../../../assets/svgMethod/icon_Threshold.svg';
// import IconVantage from '../../../assets/svgMethod/icon_Vantage.svg';
// import IconWaystage from '../../../assets/svgMethod/icon_Waystage.svg';
import { Header } from '../../../components/Header';
import { api } from '../../../services/api';
import { Appointment } from '../Schedule';
import { ListItemAssigments } from './ListAssigments.Item';
import {
  Container, Wrapper, ContainerHeader, Text, Select,
} from './styles';

// const BooksInfo = [
//   { image: IconBreakthrough, title: 'Breakthrough', united: 'A1' },
//   { image: IconWaystage, title: 'Waystage', united: 'A2' },
//   { image: IconThreshold, title: 'Threshold', united: 'B1' },
//   { image: IconVantage, title: 'Vantage', united: 'B2' },
// ];

export interface Student {
  _id: string;
  name: string;
  lastName: string;
  avatarURL: string;
}

export interface Method {
  _id: string;
  title: string;
}

export interface Mission {
  title: string;
}

export interface Assignment {
  status: string;
  type: string;
  student: Student;
  method: Method;
  mission: Mission;
  pending: number;
  paused: number;
  completed: number;
  nextAppointment: Appointment;
}

export const Assignments = () => {
  const { t } = useTranslation();

  const [assignments, setAssignments] = useState<Assignment[]>();

  async function fetchAssignments() {
    try {
      await api.get('/assignments').then((response) => {
        console.log(JSON.stringify(response.data));
        setAssignments(response.data);
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchAssignments();
  }, []);

  /*
    <ContainerHeader>
      <Select>
        <option value="1">{t('all')}</option>
      </Select>
    </ContainerHeader>
  */
  return (
    <Container>
      <Header />
      <Wrapper>
        <Text titleAssigments>{t('tarefas')}</Text>

        {assignments?.map((item) => (
          <>
            <ListItemAssigments key={item.student._id} data={item} />
          </>
        ))}
      </Wrapper>
    </Container>
  );
};
