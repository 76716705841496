import { useEffect, useState } from 'react';
import DotDotDot from '../../../assets/ellipsis.svg';
import io from 'socket.io-client';
import { Sent } from './Sent';
import { Delivered } from './Delivered';
import {
  Container,
  HeaderMessage,
  ColumnHeaderMessage,
  ContentAvatar,
  Avatar,
  DetailsUser,
  TextContact,
  StatusContainer,
  Status,
  Icon,
  InputSearch,
  BackgroundChat,
  ContainerSend,
  InputSend,
  ButtonSend,
  Form,
  WrapperOptions,
} from './styles';
import { Option } from './Option';
import { useTranslation } from 'react-i18next';
import { useOnlineStatus } from '../../../context/OnlineStatusContext';

let searchTimer = null;
let auxSentIdOfScopedChat = null;
let auxOptions = null;

const socket = io(process.env.REACT_APP_WS_URL);
socket.on('connect', () => {
  console.log('[IO] A new connection has been established 🚨');
});

export const Chat = ({
  sentId,
  imageURL,
  name,
  handleShowChatAndUpdateList = null,
  handleRemoveLastMessageWhenClear = null,
  showOptionDelete = null,
  statusChat = false,
}) => {
  const [user, setUser] = useState();
  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem('@TableTalks:user'));
    if (_user?.id) _user._id = _user.id;
    if (_user?._id) _user.id = _user._id;
    setUser(_user);
  }, []);

  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [headerSearch, setHeaderSearch] = useState('');
  const [messageFiltered, setMessageFiltered] = useState([]);
  const [activeSearch, setActiveSearch] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const { checkIsOnline, getLastSeen } = useOnlineStatus();

  const isOnline = checkIsOnline(sentId);
  const lastSeen = getLastSeen(sentId);

  // Pegar todas mensagens para exibir ao trocar o destinatário
  useEffect(() => {
    if (!socket || !user?.id) return false;
    socket.emit('getMessages', { delivered: user.id, sent: sentId });
    setHeaderSearch('');
    auxSentIdOfScopedChat = sentId;
  }, [sentId, user]);

  // unifying message filter
  const byActiveMessages = ({ clear_message, delete_contact }) => {
    return !(
      (clear_message instanceof Array && clear_message.includes(user.id)) ||
      (delete_contact instanceof Array && delete_contact.includes(user.id))
    );
  };

  // Pegar todas mensagens para exibir quando é carregado a página
  // Fica ouvindo quando o remetente envia uma mensagem para exibir
  useEffect(() => {
    if (!socket || !user?.id) return false;
    socket.on('getMessage', newMessage => {
      const ultimoEl = newMessage[newMessage.length - 1];
      if (
        auxSentIdOfScopedChat == ultimoEl.delivered ||
        auxSentIdOfScopedChat == ultimoEl.sent
      ) {
        if (user.id == ultimoEl.delivered || user.id == ultimoEl.sent) {
          if (user.id == ultimoEl.sent) {
            socket.emit('receivedTheMessages', {
              delivered: user.id,
              sent: auxSentIdOfScopedChat,
            });
          }
          setMessages(newMessage.filter(byActiveMessages));
        }
      }
    });
    socket.on('clearMessages', newMessage => {
      if (user.id == newMessage[0].delivered) {
        setMessages([]);
        if (handleRemoveLastMessageWhenClear)
          handleRemoveLastMessageWhenClear(newMessage[0].sent);
      }
    });
    socket.on('deleteContact', newMessage => {
      if (user.id == newMessage[0].delivered) {
        if (handleShowChatAndUpdateList)
          handleShowChatAndUpdateList(newMessage[0].sent);
      }
    });
    socket.on('getMessages', newMessage => {
      if (newMessage.includes(user.id)) {
        setMessages(newMessage.filter(byActiveMessages));
      }
    });

    socket.emit('getMessages', { delivered: user.id, sent: sentId });

    document.addEventListener('click', handleOptionsWhenClickOut);

    return () => {
      socket.off();
      document.removeEventListener('click', handleOptionsWhenClickOut);
    };
  }, [user]);

  // Desce a barra de rolagem ao receber ou enviar uma nova mensagem
  useEffect(() => {
    document.querySelector('.kJsXAoY').scrollTop =
      document.querySelector('.kJsXAoY').scrollHeight;
  }, [showOptions]);

  useEffect(() => {
    document.querySelector('.kJsXAoY').scrollTop =
      document.querySelector('.kJsXAoY').scrollHeight;
  }, [messages]);

  // Filtrar mensagens
  useEffect(() => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      if (headerSearch.trim()) {
        const messageFilteredLocal = messages.filter(({ content }) => {
          if (!content) return false;

          content = content.toLocaleLowerCase();

          if (content.indexOf(headerSearch.toLocaleLowerCase()) > -1)
            return true;

          return false;
        });

        setMessageFiltered(messageFilteredLocal);
        setActiveSearch(true);
      } else {
        setActiveSearch(false);
      }
    }, 500);
  }, [headerSearch]);

  // Envia uma mensagem para o destinatário
  const handleSendMessage = e => {
    e.preventDefault();
    if (message.trim()) {
      socket.emit('getMessage', {
        content: message,
        delivered: user?.id,
        sent: sentId,
      });
      socket.emit('updateContactsAndMessages', {
        delivered: user?.id,
        sent: sentId,
      });
      setMessage('');
    }
  };

  const handleClearAndDeleteContact = option => {
    switch (option) {
      case 'clear':
        socket.emit('clearMessages', { delivered: user?.id, sent: sentId });
        setShowOptions(false);
        break;
      case 'delete':
        socket.emit('deleteContact', { delivered: user?.id, sent: sentId });
        setShowOptions(false);
        break;
    }
  };

  const handleOptionsWhenClickOut = e => {
    if (e.target.classList.contains('kJsQeRT') == false && auxOptions == true) {
      auxOptions = false;
      setShowOptions(false);
    }
  };

  const handleOptionsShow = () => {
    auxOptions = !showOptions;
    setShowOptions(auxOptions);
  };

  return (
    <Container>
      <HeaderMessage>
        <ColumnHeaderMessage>
          <ContentAvatar>
            <Avatar src={imageURL} />
          </ContentAvatar>
          <DetailsUser>
            <TextContact>{name}</TextContact>
            <StatusContainer>
              <Status online={isOnline}></Status>
              <p>
                {isOnline
                  ? 'online'
                  : lastSeen
                  ? `${t('lastSeen')} (${lastSeen})`
                  : 'offline'}
              </p>
            </StatusContainer>
          </DetailsUser>
        </ColumnHeaderMessage>

        <ColumnHeaderMessage>
          <InputSearch
            placeholder={t('searchMessage')}
            value={headerSearch}
            onChange={e => setHeaderSearch(e.target.value)}
          />
          <Icon
            src={DotDotDot}
            onClick={handleOptionsShow}
            className="kJsQeRT"
          />
          {showOptions && (
            <WrapperOptions>
              <Option
                handleClearAndDeleteContact={handleClearAndDeleteContact}
                showOptionDelete={showOptionDelete}
              />
            </WrapperOptions>
          )}
        </ColumnHeaderMessage>
      </HeaderMessage>

      <BackgroundChat className="kJsXAoY">
        {activeSearch
          ? messageFiltered.map((m, i) => (
              <div key={i}>
                {m.delivered == user?.id ? (
                  <Delivered content={m.content} imageURL={user?.avatarURL} />
                ) : (
                  <Sent content={m.content} imageURL={imageURL} />
                )}
              </div>
            ))
          : messages.map((m, i) => (
              <div key={i}>
                {m.delivered == user?.id ? (
                  <Delivered content={m.content} imageURL={user?.avatarURL} />
                ) : (
                  <Sent content={m.content} imageURL={imageURL} />
                )}
              </div>
            ))}
      </BackgroundChat>

      <Form onSubmit={handleSendMessage}>
        <ContainerSend>
          <InputSend
            placeholder={t('enviarMensagem')}
            value={message}
            onChange={e => setMessage(e.target.value)}
            autoFocus
          />
          <ButtonSend type="submit">{t('enviar')}</ButtonSend>
        </ContainerSend>
      </Form>
    </Container>
  );
};
