import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  margin: 10px;
`;

export const TicketHeader = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  span {
    cursor: pointer;
    font-size: 14px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const TicketTitle = styled.input`
  height: 5rem;
  margin-bottom: 10px;
  border: 1px solid #ebe9f1;
  color: grey;
  padding-left: 10px;
`;

export const TicketBody = styled.textarea`
  height: 40%;
  margin-bottom: 10px;
  padding-top: 10px;
  border: 1px solid #ebe9f1;
  color: grey;
  padding-left: 10px;
`;

export const TicketDepartament = styled.select`
  height: 10%;
  margin-bottom: 10px;
  border: 1px solid #ebe9f1;
  color: grey;
  padding-left: 10px;
`;

export const TicketButtonAttachment = styled.button`
  height: 5rem;
  margin-bottom: 5rem;
  border: 1px solid #ebe9f1;
  color: grey;
  text-align: left;
  padding-left: 1.5rem;
`;

export const TicketCalled = styled.div`
  margin-bottom: 2rem;
`;

export const ButtonSend = styled.button`
  width: 100%;
  border: 1px solid #ebe9f1;
  background-color: ${prop =>
    prop.disabled ? 'rgba(8, 91, 85, 0.5)' : 'rgba(8, 91, 85, 1)'};
  cursor: ${prop => (prop.disabled ? 'default' : 'pointer')};
  color: #fff;
  padding: 10px;
  border-radius: 0;
`;

export const InputHidden = styled.input`
  display: none;
`;
