/* eslint-disable no-nested-ternary */
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import queryString from 'query-string';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Appointment } from '..';

import IconStar from '../../../../assets/svgSchedule/icon_star.svg';
import { CollapseSchedule } from '../../../../components/Button/CollapseSchedule';
import {
  Button,
  ContainerButtons,
  ContainerText,
  ContainerTextsUser,
  ContainerUser,
  Icon,
  Image,
  RequestBox,
  Text,
} from './styles';

interface RequestProps {
  appointment: Appointment;
  onAccept: (id: string) => void;
  fetchAppointments: () => void;
}

export const Request = ({
  appointment,
  onAccept,
  fetchAppointments,
}: RequestProps) => {
  const { t } = useTranslation();

  const [showSchedule, setShowSchedule] = useState(false);

  function toggleSchedule() {
    setShowSchedule((prevState) => !prevState);
  }

  const student = useMemo(() => appointment.attendees.find(({ type }) => type === 'student'), [appointment.attendees]) as Appointment['attendees'][0];

  return (
    <RequestBox>
      <Icon src={IconStar} />
      <ContainerText>
        <Text alert>{t('notice')}</Text>
        <Text>{t('thereTrialLessonRequest')}</Text>
      </ContainerText>
      <ContainerUser>
        <Image
          src={`https://ui-avatars.com/api/?${queryString.stringify({
            background: '#124f8c',
            color: '#fff',
            name: `${student?.name}  ${student?.lastName}`,
          })}`}
        />
        <ContainerTextsUser>
          <Text>
            {student?.name}
            {' '}
            {student?.lastName}
          </Text>
          <Text>
            {t('on')}
            {' '}
            <strong>
              {format(parseISO(appointment?.startTime as string), 'dd/MM/yyyy')}
            </strong>
            {' '}
            {t('at')}
            {' '}
            <strong>
              {format(parseISO(appointment?.startTime as string), 'HH:mm')}
            </strong>
            {' '}
          </Text>
        </ContainerTextsUser>
      </ContainerUser>

      <ContainerButtons>
        <Button type="button" onClick={toggleSchedule}>
          <span className="iconify" data-icon="akar-icons:calendar" />
          {t('chooseDate')}
          <span className="iconify" data-icon="dashicons:arrow-down-alt2" />
        </Button>
        {showSchedule && (
          <CollapseSchedule
            appointment={appointment}
            fetchAppointments={fetchAppointments}
            setShowSchedule={setShowSchedule}
          />
        )}
        <Button
          check
          onClick={() => {
            onAccept(appointment._id);
          }}
        >
          <span className="iconify" data-icon="akar-icons:check" />
          {t('accept')}
        </Button>
      </ContainerButtons>
    </RequestBox>
  );
};
