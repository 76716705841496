import { Grid, Typography } from '@mui/material';
import React from 'react';

import { usePurchaseContext } from '../hooks/context-hook';

function PurchaseFormStepThreeCheckout(): React.ReactElement {
  const context = usePurchaseContext();
  
  function renderPix(): React.ReactElement {
    return (
      <>
        <Grid item xs={12} justifyContent="center" alignItems="center" display="flex">
          <img src={`data:image/png;base64,${context.purchaseResponse?.pixQrCode?.encodedImage}`} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>Chave Pix</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {context.purchaseResponse?.pixQrCode?.payload}
          </Typography>
        </Grid>
      </>
    )
  }
  
  function renderPaymentSlip(): React.ReactElement {
    return(
      <>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography variant="h6">
            Click no link para visualizar o boleto de pagamento.            
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <a href={context.purchaseResponse?.paymentSlip?.url} target="_blank" rel="noreferrer" >
            <Typography variant="h6">            
              {context.purchaseResponse?.paymentSlip?.url}
            </Typography>
          </a>
        </Grid>
      </>
    )
  }
  
  return(
    <Grid container spacing={2}>
      {context.purchaseResponse?.pixQrCode?.encodedImage && renderPix()}
      {context.purchaseResponse?.paymentSlip?.url && renderPaymentSlip()}
    </Grid>
  )
}

export {
  PurchaseFormStepThreeCheckout,
}