import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-color: #fff;
  padding: 0;
  border: 1px solid var(--gray3);
  padding: 2rem;

  .datePicker {

  div {
    /* height: 3.8rem; */
    width: 100%;
    background: #fff;
    border: 1px solid var(--gray3);
    border-radius: 0.5rem;
    }
  }

  p {
    font-size: 1.4rem;
    text-align: justify;
    width: 38.5rem;
    margin-bottom: 2rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;


export const ContainerFile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 3.8rem;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  padding: 0 1rem;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom: 2rem;
  width: 100%;

  span {
    font-size: 1.1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  height: 3.8rem;
  width: 100%;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  padding: 0 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Select = styled.select`
  height: 3.8rem;
  width: 100%;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  padding: 0 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const ContainerBio = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TextArea = styled.textarea`
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  height: 18rem;
  width: 60rem;
  padding: 1rem;

  @media (min-width: 1290px) {
    min-width: 80%;
  }

  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SwitchButton = styled.div`
  height: 5rem;
  width: 38.5rem;
  background: #06231f;
  color: #fff;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  margin-bottom: 0.5rem;

  span {
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  width: 38.5rem;
  background: #083059;
  color: #fff;
  padding: 10px;
  border-radius: 0.5rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FooterWrapper = styled.div`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    @media (max-width: 768px) {
      min-width: 100%;
      margin-top: 2rem;
    }
  }
`;
