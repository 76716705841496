/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconNotification from '../../assets/svgSchedule/icon_notification.svg';
import { useAuth } from '../../hooks/AuthProvider';

// eslint-disable-next-line import/no-cycle
import { useMenu } from '../../pages/Dashboard';
import { api2 } from '../../services/api';
import { UpdateLang } from '../UpdateLang';
import { MenuBar } from './MenuBar';
import { NotificationHeader } from './NotificationHeader';
import {
  Container,
  ContainerIcon,
  Icon,
  BarMenu,
  ContainerNotification,
  ContainerUser,
  ContainerText,
  Text,
  Image,
  Badge,
  BadgeStatus,
  Burguer,
  SpanBurguer,
} from './styles';

type NotificationType = {
  _id: string;
  title: string;
  message: string;
  link: string;
  fileURL: string;
  visualized: boolean;
};

let auxNotification: NotificationType[] = [];
let qttNotificationsNotViewed = 0;
let alertOn: boolean;
let clearingBadge: boolean;
let auxShowNotification: boolean;
const n: Notification[] = [];
let headerTimeout: NodeJS.Timeout;

export const Header = () => {
  const { user } = useAuth();

  const { t, i18n } = useTranslation();

  const { showMenu, setShowMenu } = useMenu();

  const isActiveAlert = JSON.parse(
    localStorage.getItem('@TableTalks:notificationAlert') as string,
  );
  const [showNotification, setShowNotification] = useState(false);
  const [showMenuBar, setShowMenuBar] = useState(false);
  const [countBadge, setCountBadge] = useState(0);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  function getNotifications() {
    api2.get(`/notifications?lang=${i18n.language}&page=1`).then((response) => {
      const { docs } = response.data;
      // Verifica se existe notificações listadas
      if (auxNotification.length > 0) {
        // Filtra as notificações vindas para saber se tem alguma nova
        const notificationsNewFiltered = docs.filter(
          (doc: NotificationType) => {
            const exist = auxNotification.some(
              (notification: NotificationType) => notification._id === doc._id,
            );
            return !exist;
          },
        );
        // Verifica se tem notificações novas
        if (notificationsNewFiltered.length > 0) {
          // Notifica o usuário se o alerta estiver ligado
          if (alertOn === true) {
            notificationsNewFiltered.forEach(
              (notification: NotificationType) => {
                n.push(
                  new Notification(notification.title, {
                    icon: notification.fileURL,
                    body: notification.message,
                  }),
                );
              },
            );
          }

          // Adiciona as novas notificações na lista e o número de notificações não visualizadas no badge
          const notificationsNew = [
            ...notificationsNewFiltered,
            ...auxNotification,
          ];

          qttNotificationsNotViewed = notificationsNew.reduce(
            (acum: number, notification: NotificationType) => {
              if (notification.visualized === false) {
                return acum + 1;
              }
              return acum + 0;
            },
            0,
          );
          auxNotification = notificationsNew;
        } else {
          // Adiciona as mesmas notificações na lista e o número de notificações não visualizadas no badge
          qttNotificationsNotViewed = docs.reduce(
            (acum: number, notification: NotificationType) => {
              if (notification.visualized === false) {
                return acum + 1;
              }
              return acum + 0;
            },
            0,
          );
          auxNotification = docs;
        }
      } else {
        // Preencherá a lista assim que renderizar o componente e o número de notificações não visualizadas no badge
        qttNotificationsNotViewed = docs.reduce(
          (acum: number, notification: NotificationType) => {
            if (notification.visualized === false) {
              return acum + 1;
            }
            return acum + 0;
          },
          0,
        );
        auxNotification = docs;
      }

      if (clearingBadge === false) setCountBadge(qttNotificationsNotViewed);
      setNotifications(auxNotification);
    });
  }

  const watchNewNotifications = useCallback(() => {
    clearingBadge = false;

    if (headerTimeout) {
      clearTimeout(headerTimeout);
    }

    headerTimeout = setTimeout(() => {
      getNotifications();
      watchNewNotifications();
    }, 60000);
  }, []);

  const OpenShowNotification = async () => {
    if (showNotification === false) {
      clearingBadge = true;
      setShowNotification(true);
      setShowMenuBar(false);
      auxShowNotification = true;
      setCountBadge(0);

      const hasNotificationNotView = notifications.some(
        (notification: NotificationType) => notification.visualized === false,
      );

      n.forEach((v) => v.close());

      if (hasNotificationNotView) await api2.put('/notifications');
    } else {
      setShowNotification(false);
      auxShowNotification = false;
    }
  };

  const OpenShowMenuBar = () => {
    if (showMenuBar === false) {
      setShowMenuBar(true);
      setShowNotification(false);
      auxShowNotification = false;
    } else {
      setShowMenuBar(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleIconNotificationWhenClickOut = (e: any) => {
    if (
      e.target.classList.contains('kJsZzAQ') === false
      && e.target.closest('.containerNotification') === null
      && auxShowNotification === true
    ) {
      auxShowNotification = false;
      setShowNotification(false);
    }
  };

  const offNotificationsWithAlert = () => {
    alertOn = false;
  };

  const onNotificationsWithAlert = () => {
    alertOn = true;
  };

  useEffect(() => {
    if (isActiveAlert === null) {
      localStorage.setItem('@TableTalks:notificationAlert', 'false');
      alertOn = false;
    } else {
      alertOn = isActiveAlert;
    }
    getNotifications();
    watchNewNotifications();

    document.addEventListener('click', handleIconNotificationWhenClickOut);

    return () => document.removeEventListener('click', handleIconNotificationWhenClickOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ContainerIcon>
        {/* <Icon /> */}
        <Burguer showMenu={showMenu} onClick={() => setShowMenu(!showMenu)}>
          <SpanBurguer
            className="iconify"
            data-icon="feather:menu"
            data-inline="false"
            style={{ color: '#000' }}
          />
        </Burguer>
      </ContainerIcon>

      <BarMenu>
        {user.type !== 'teacher' && <UpdateLang />}
        <ContainerNotification>
          {countBadge > 0 && <Badge>{countBadge}</Badge>}
          <Icon
            src={IconNotification}
            onClick={OpenShowNotification}
            className="kJsZzAQ"
          />
          {showNotification && (
            <NotificationHeader
              notifications={notifications}
              watchNewNotifications={watchNewNotifications}
              offNotificationsAlert={offNotificationsWithAlert}
              onNotificationsAlert={onNotificationsWithAlert}
            />
          )}
        </ContainerNotification>
        <ContainerUser onClick={OpenShowMenuBar}>
          {showMenuBar && <MenuBar />}
          <ContainerText>
            <Text>{user.fullName ?? user.name}</Text>
            <Text subtitle>
              @
              {t(user.type)}
            </Text>
          </ContainerText>
          <Image src={user.avatarURL} />
          <BadgeStatus />
        </ContainerUser>
      </BarMenu>
    </Container>
  );
};
