import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 40.4rem;
  /* max-width: 40rem; */
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  z-index: 0;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }

  @media (max-width: 468px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const ContainerTitle = styled.div`
  width: 100%;
  height: 5.8rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 1px solid var(--gray3);

  ${props =>
    props.hours &&
    css`
      justify-content: center;

      .hours {
        font-size: 1.4rem;
        border: 1px solid var(--gray3);
        border-radius: 0.5rem;
        padding: 1rem;
        color: #6e6b7b;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .react-time-picker {
          display: inline-flex;
          position: relative;
        }
        .react-time-picker,
        .react-time-picker *,
        .react-time-picker *:before,
        .react-time-picker *:after {
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }
        .react-time-picker--disabled {
          background-color: #f0f0f0;
          color: #6d6d6d;
        }
        .react-time-picker__wrapper {
          display: flex;
          flex-grow: 1;
          flex-shrink: 0;
          border: 0;
        }
        .react-time-picker__inputGroup {
          min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
          flex-grow: 1;
          padding: 0 2px;
          box-sizing: content-box;
          color: #707070;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem;
          font-family: 'Neue Haas Grotesk Display Pro' !important;
        }
        .react-time-picker__inputGroup__divider {
          padding: 1px 0;
          white-space: pre;
        }
        .react-time-picker__inputGroup__input {
          min-width: 1em;
          height: 100%;
          position: relative;
          padding: 0 1px;
          border: 0;
          background: none;
          color: #707070;
          font: inherit;
          box-sizing: content-box;
          -moz-appearance: textfield;
        }
        .react-time-picker__inputGroup__input::-webkit-outer-spin-button,
        .react-time-picker__inputGroup__input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        .react-time-picker__inputGroup__input:invalid {
          background: rgba(255, 0, 0, 0.1);
        }
        .react-time-picker__inputGroup__input--hasLeadingZero {
          margin-left: -0.54em;
          padding-left: calc(1px + 0.54em);
        }
        .react-time-picker__inputGroup__amPm {
          font: inherit;
          -moz-appearance: menulist;
        }
        .react-time-picker__button {
          border: 0;
          background: transparent;
          padding: 4px 6px;
        }
        .react-time-picker__button:enabled {
          cursor: pointer;
        }
        .react-time-picker__button:enabled:hover
          .react-time-picker__button__icon,
        .react-time-picker__button:enabled:focus
          .react-time-picker__button__icon {
          stroke: #0078d7;
        }
        .react-time-picker__button:disabled .react-time-picker__button__icon {
          stroke: #6d6d6d;
        }
        .react-time-picker__button svg {
          display: inherit;
        }
        .react-time-picker__clock {
          width: 200px;
          height: 200px;
          max-width: 100vw;
          padding: 25px;
          background-color: white;
          border: thin solid #a0a096;
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 1;
        }
        .react-time-picker__clock--closed {
          display: none;
        }

        button {
          background: transparent;
          border: 0;
          color: #707070;
        }
      }

      .icon-up {
        position: absolute;
        top: -1rem;
      }

      .icon-down {
        position: absolute;
        bottom: -1.2rem;
      }

      .as {
        margin: 0 1rem;
        color: #6e6b7b;
        font-size: 1.4rem;
      }

      button {
        background: #083059;
        color: #fff;
        margin-left: 1rem;
        padding: 1rem;
        border-radius: 0.5rem;
      }
    `}

  ${props =>
    props.week &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2rem;
      color: #083059;

      .iconify {
        font-size: 2rem;
      }
    `}
`;

export const Title = styled.span`
  font-size: 2rem;
  color: #707070;
  font-weight: 650;

  ${props =>
    props.day &&
    css`
      color: #083059;
      border-left: 1px solid var(--gray3);
      border-right: 1px solid var(--gray3);
      height: 100%;
      width: 80%;
      text-align: center;
      padding-top: 2rem;
      font-weight: bold;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.2rem;
  height: 28.5rem;

  ${props =>
    props.typeLogin === false &&
    css`
      height: 21.9rem;
    `}

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.6rem;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #083059;
    border-radius: 1rem;
  }
  ::selection {
    background-color: #083059;
  }
`;

export const Time = styled.input``;

export const Button = styled.button``;
