import React, { useState, useEffect, useCallback } from 'react';
import { parseISO, format, subMonths } from 'date-fns';

import { HeaderReports } from '../HeaderReports';
import {
  Container,
  ContainerTypes,
  ContainerSpace,
  TitleType,
  ListFinancial,
} from './styles';
import { api } from '../../../services/api';
import { formatPrice } from '../../../utils/format';
import { useTranslation } from 'react-i18next';

export function WithdrawalReports() {
  const [withdrawals, setWithdrawals] = useState([]);
  const [startDate, setStartDate] = useState(subMonths(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const fetchWithdrawals = useCallback(async () => {
    try {
      await api
        .get('/reports/withdrawals/me', {
          params: { startDate, endDate, search },
        })
        .then(response => {
          setWithdrawals(response.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [startDate, endDate, search]);

  useEffect(() => {
    fetchWithdrawals();
  }, [fetchWithdrawals]);

  console.log(withdrawals);
  return (
    <Container>
      <HeaderReports
        search={search}
        startDate={startDate}
        endDate={endDate}
        onSearchChange={setSearch}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        searchEnabled={false}
      />
      <ContainerTypes>
        {/* <ContainerSpace>
          <TitleType>{t('withdrawalSubmitted')}</TitleType>
        </ContainerSpace> */}
        <ContainerSpace>
          <TitleType>{t('withdrawalSubmitted')}</TitleType>
        </ContainerSpace>
        <ContainerSpace>
          <TitleType>{t('withdrawalProcessed')}</TitleType>
        </ContainerSpace>
        <ContainerSpace>
          <TitleType>{t('withdrawalPayout')}</TitleType>
        </ContainerSpace>
        <ContainerSpace>
          <TitleType>{t('withdrawalGrossIncome')}</TitleType>
        </ContainerSpace>
        <ContainerSpace>
          <TitleType>{t('withdrawalNetIncome')}</TitleType>
        </ContainerSpace>
      </ContainerTypes>

      {withdrawals.map(withdrawal => (
        <ListFinancial>
          {/* <ContainerSpace list>
            <TitleType>{withdrawal.status}</TitleType>
          </ContainerSpace> */}
          <ContainerSpace list>
            <TitleType>
              {format(parseISO(withdrawal.requestDate), 'dd/MM/yyyy')}
            </TitleType>
          </ContainerSpace>
          <ContainerSpace list>
            <TitleType>
              {format(parseISO(withdrawal.scheduleDate), 'dd/MM/yyyy')}
            </TitleType>
          </ContainerSpace>
          <ContainerSpace list>
            <TitleType>
              {withdrawal.effectiveDate
                ? format(parseISO(withdrawal.effectiveDate), 'dd/MM/yyyy')
                : t('waiting')}
            </TitleType>
          </ContainerSpace>
          <ContainerSpace list>
            <TitleType>{formatPrice(withdrawal.value)}</TitleType>
          </ContainerSpace>
          {withdrawal.transactionReceiptUrl && (
            <ContainerSpace
              download
              list
              target="_blank"
              href={withdrawal.transactionReceiptUrl}
            >
              <span
                className="iconify"
                data-icon="eva:download-outline"
                data-inline="false"
              />
            </ContainerSpace>
          )}
        </ListFinancial>
      ))}
    </Container>
  );
}
