import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../hooks/AuthProvider';
import {
  Container,
  ContainerUser,
  ContainerText,
  Image,
  Text,
  BadgeStatus,
  ContainerLinks,
  Links,
} from './styles.menu';

export const MenuBar = () => {
  const { user, signOut } = useAuth();

  const { t } = useTranslation();

  return (
    <Container>
      <ContainerUser>
        <BadgeStatus />
        <Image src={user.avatarURL} />
        <ContainerText>
          <Text>{user.fullName ?? user.name}</Text>
          <Text subtitle>
            @
            {t(user.type)}
          </Text>
          <Text mail>{user.email}</Text>
        </ContainerText>
      </ContainerUser>

      <ContainerLinks>
        <Links to="/dashboard/profile/editprofile/profile">
          {t('myProfile')}
        </Links>
        <Links to="/dashboard/profile/info/finalcialreports">
          {t('financialHistory')}
        </Links>
        <Links to="/">{t('serviceTerms')}</Links>
        <Links to="/" bottom onClick={signOut}>
          {t('logOut')}
        </Links>
      </ContainerLinks>
    </Container>
  );
};
