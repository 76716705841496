import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 4rem;
  margin-bottom: 1.2rem;
  border: 1px solid var(--gray3);

  display: flex;
  align-items: center;

  .iconify {
    font-size: 1.8rem;
    color: #083059;
  }

  span {
    color: #06231f;
    font-size: 1.8rem;
  }

  .watch {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-right: 1px solid var(--gray3);
    height: 100%;
  }

  .text-container {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-right: 1px solid var(--gray3);
    height: 100%;
    width: 100%;
  }

  .container-buttons {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;

    button {
      padding-right: 1rem;
      border-right: 1px solid var(--gray3);
      height: 100%;
    }

    button + button {
      padding-right: 0;
      padding-left: 1rem;
      border-right: 0;
    }
  }
`;
