import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-right: 1.2rem;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  width: 100%;
  /* max-height: 68rem; */

  header {
    height: 6rem;
    background: #f9f9f9;
    border-bottom: 1px solid var(--gray3);
    border-radius: 0.5rem 0.5rem 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .not-active-list {
    background: #fff;
    height: 61.6rem;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    article {
      font-size: 1.9rem;
      color: #243342;
      text-align: justify;
    }
  }

  .active-list {
    height: 59rem;
    padding: 2rem 0;
    background: #fff;
    border-bottom: 1px solid var(--gray3);

    .list {
      margin: 1.5rem 2rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--gray3);
    }
  }

  .actions {
    height: 6rem;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 100%;
    }
  }

  .column {
    display: flex;
    align-items: center;
  }
`;

export const Button = styled.button`
  height: 4rem;
  width: 20rem;
  border-radius: 0.5rem;
  border-right: 1px solid var(--gray3);
  background: #243342;
  color: #fff;
  cursor: pointer;

  :hover {
    filter: brightness(0.8);
  }

  ${(props) =>
    props.cancel &&
    css`
      margin-left: 2rem;
      margin-right: 1rem;
      border-right: 0;
    `}

  ${(props) =>
    props.close &&
    css`
      margin-left: 1rem;
      margin-right: 2rem;
      background: #dbad00;
      border-right: 0;
    `}

    ${(props) =>
    props.finaly &&
    css`
      border-right: none;
      height: 6rem;
      width: 8rem;
      border-bottom: 1px solid var(--gray3);
      color: #243342;
      background: #f9f9f9;
      border-radius: 0;
    `}

  ${(props) =>
    props.left &&
    css`
      border-left: 1px solid var(--gray3);
      height: 6rem;
      width: 8rem;
      border-right: 1px solid var(--gray3);
      border-bottom: 1px solid var(--gray3);
      color: #243342;
      background: #f9f9f9;
      border-radius: 0;
    `}
`;

export const ContainerText = styled.div`
  display: flex;
  margin-left: 1.2rem;
`;

export const Text = styled.span`
  font-size: 1.9rem;
  color: #243342;
`;
