import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  input:checked + .slider {
    background-color: #13c8a9;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #13c8a9;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  left: 20px;
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Span = styled.span`
  ${(props) =>
    props.slider &&
    css`
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 15px;

      :before {
        position: absolute;
        content: '';
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    `}
`;
