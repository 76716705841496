import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 8rem;
  width: 100%;
  padding: 0 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.2rem;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem 0.5rem 0 0;

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
    height: auto;
  }

  @media (max-width: 375px) {
    /* display: flex; */
    height: 17rem;
  }
`;

export const Column = styled.div`
  display: flex;
  align-items: center;

  ${props =>
    props.middle &&
    css`
      justify-content: flex-start;
      width: 70rem;

      @media (max-width: 768px) {
        width: 26rem;
      }

      @media (max-width: 375px) {
        width: 100%;
      }
    `}

  @media (max-width: 375px) {
    margin-top: 1rem;
    justify-content: center;
  }
`;

export const ContainerIcon = styled.div`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  background: #dbad0018;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  .iconify {
    font-size: 1.8rem;
    color: #243342;
  }

  ${props =>
    props.date &&
    css`
      margin-left: 1rem;
    `}
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerStatus = styled.div`
  height: 4rem;
  padding: 0 1rem;
  background: #dbad0018;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
`;

export const Text = styled.span`
  font-size: 2rem;
  color: #707070;

  ${props =>
    props.status &&
    css`
      color: #243342;
      font-size: 1.6rem;
    `}

  ${props =>
    props.ticket &&
    css`
      width: auto;
      overflow: hidden;
    `}

  ${props =>
    props.title &&
    css`
      font-size: 1.6rem;
      overflow: hidden;
      max-width: 100px;
    `}

    ${props =>
    props.description &&
    css`
      font-size: 1.4rem;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${props =>
    props.openDate &&
    css`
      font-size: 1rem;
    `}

  ${props =>
    props.date &&
    css`
      font-size: 1.4rem;
    `}
`;

export const Select = styled.button`
  padding: 0 1rem;
  height: 4rem;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  color: #243342;

  @media (max-width: 375px) {
    width: 20rem;
  }
`;
