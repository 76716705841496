import { useEffect, useRef, useState } from 'react';
import { Sent } from './Sent';
import { Delivered } from './Delivered';
import {
  Container,
  HeaderMessage,
  ColumnHeaderMessage,
  ContentAvatar,
  Avatar,
  DetailsUser,
  TextContact,
  InputSearch,
  BackgroundChat,
  ContainerSend,
  InputSend,
  ButtonSend,
  Form,
  ContainerLoader,
  InputHidden,
  BoxAttachmentTemporary,
  NameAttachmentTemporary,
  SizeAttachmentTemporary,
  WrapperInfoAttachmentTemporary,
  WrapperAttachmentTemporaryRow,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/AuthProvider';
import { removeTags } from '../../utils/removeTags';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { AiOutlinePaperClip, AiOutlineClose } from "react-icons/ai";
import { toast } from 'react-toastify';

let searchTimer = null;
const avatarURL = "https://tabletalks-method.s3.sa-east-1.amazonaws.com/users/avatar.png";

export const ChatTicket = ({ historic, idChamado, status, loading }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const [headerSearch, setHeaderSearch] = useState('');
  const [historicFilteredLocal, setHistoricFiltered] = useState([]);
  const [attachmentsTemporary, setAttachmentsTemporary] = useState([]);
  const [activeSearch, setActiveSearch] = useState(false);

  const filesElement = useRef(null);

  useEffect(() => {
    downScrollChat();
  }, [loading]);

  // Filtrar mensagens
  useEffect(() => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      if (headerSearch.trim()) {
        const historicFilteredLocal = historic.filter(({ mensagem, origem }) => {
          if (origem === 'A') {
            mensagem = removeTags(mensagem.toLocaleLowerCase());
          } else {
            mensagem = mensagem.toLocaleLowerCase();
          }

          if (mensagem.indexOf(headerSearch.toLocaleLowerCase()) > -1 ) return true;

          return false;
        });

        setHistoricFiltered(historicFilteredLocal);
        setActiveSearch(true);
      } else {
        setActiveSearch(false);
      }
    }, 500);
  }, [headerSearch]);

  // Desce a barra de rolagem ao receber ou enviar uma nova mensagem
  const downScrollChat = () => {
    if (!loading)
      document.querySelector('.kJsXAoY').scrollTop = document.querySelector('.kJsXAoY').scrollHeight;
  };

  // Envia a resposta para o atendente
  const handleSendMessage = async (e, idChamado) => {
    e.preventDefault();
    if (attachmentsTemporary.length > 0 && !message.trim()) {
      return toast.error(t('informTheAnswerToTheAttendant'));
    }

    if (message.trim()) {
      try {
        const formData = new FormData();
        formData.append('mensagem', message);

        attachmentsTemporary.forEach((file, index) => {
          formData.append(`anexos[${index}]`, file);
        });

        const { data: { erro } } = await axios.post(`https://api.tomticket.com/chamado/b40b8bc6aa8c3891087231bd5b097c09/${idChamado}/responder`, formData);

        if (!erro) {
          historic.push({ id: Date.now(), origem: 'C', mensagem: message });
          if (attachmentsTemporary.length > 0)
            toast.info(t('attachmentsSentToViewUpdateChat'));
        }

        setMessage('');
        setAttachmentsTemporary([]);
        downScrollChat();
      } catch (e) {
        console.log(e);
      }
    }
  }

  // Armazenar na state arquivos a serem enviados
  const saveFilesTemporarily = () => {
    if (filesElement.current.files.length > 0)
      setAttachmentsTemporary([...attachmentsTemporary, ...filesElement.current.files]);
  };

  // Abrir a caixa de dialogo
  const openAttachmentBox = (idChamado) => {
    document.querySelector(`.input-hidden-${idChamado}`).click();
  };

  // Remover arquivo temporário da state
  const removeAttachmentTemporary = (indexParam) => {
    const filesNew = attachmentsTemporary.filter((attachment, index) => index !== indexParam);
    setAttachmentsTemporary(filesNew);
  };

  return (
    <Container>
      <HeaderMessage>
        <ColumnHeaderMessage>
          <ContentAvatar>
            <Avatar src={avatarURL} />
          </ContentAvatar>
          <DetailsUser>
            <TextContact>{t('support')}</TextContact>
          </DetailsUser>
        </ColumnHeaderMessage>

        <ColumnHeaderMessage>
          <InputSearch
            placeholder={t('searchMessage')}
            value={headerSearch}
            onChange={(e) => setHeaderSearch(e.target.value)}
          />
        </ColumnHeaderMessage>
      </HeaderMessage>

      {loading &&
        <ContainerLoader>
          <Loader
            visible
            type="ThreeDots"
            color="#124f8c"
            secondaryColor="#191919"
          />
        </ContainerLoader>
      }

      {!loading &&
        <BackgroundChat className="kJsXAoY">
          {activeSearch
            ?
              historicFilteredLocal.map((h) => (
                <div key={h.id}>
                  {h.origem === 'C'
                    ? <Delivered content={h.mensagem} attachments={h.anexos} avatarURL={user.avatarURL} />
                    : <Sent content={removeTags(h.mensagem)} attachments={h.anexos} avatarURL={avatarURL} />
                  }
                </div>
              ))
            :
              historic.map((h) => (
                <div key={h.id}>
                  {h.origem === 'C'
                    ? <Delivered content={h.mensagem} attachments={h.anexos} avatarURL={user.avatarURL} />
                    : <Sent content={removeTags(h.mensagem)} attachments={h.anexos} avatarURL={avatarURL} />
                  }
                </div>
              ))
          }
        </BackgroundChat>
      }

      {status !== 'Finalizado' &&
        <Form onSubmit={(e) => handleSendMessage(e, idChamado)}>
          <ContainerSend>
            <AiOutlinePaperClip
              size={25}
              color={'#5E5873'}
              title={t('attachments')}
              onClick={() => openAttachmentBox(idChamado)}
              style={{cursor: 'pointer'}}
            />
            <InputHidden
              type="file"
              multiple
              className={`input-hidden-${idChamado}`}
              ref={filesElement}
              onChange={saveFilesTemporarily}
            />
            <InputSend
              placeholder={t('typeAMessage')}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              autoFocus
            />
            <ButtonSend type="submit">
              {t('enviar')}
            </ButtonSend>
          </ContainerSend>
          <WrapperAttachmentTemporaryRow>
            {attachmentsTemporary.map((attachment, index) => (
              <BoxAttachmentTemporary key={index}>
                <WrapperInfoAttachmentTemporary>
                  <NameAttachmentTemporary>{attachment.name}</NameAttachmentTemporary>
                  <SizeAttachmentTemporary>({(attachment.size / 1000000).toFixed(2)} MB)</SizeAttachmentTemporary>
                </WrapperInfoAttachmentTemporary>
                <AiOutlineClose
                  size={15}
                  color={'#000'}
                  title={t('remove')}
                  style={{cursor: 'pointer', marginLeft: '.5rem'}}
                  onClick={() => removeAttachmentTemporary(index)}
                />
              </BoxAttachmentTemporary>
            ))}
          </WrapperAttachmentTemporaryRow>
        </Form>
      }
    </Container>
  );
};
