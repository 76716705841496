import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-right: 1.2rem;
  border: 1px solid var(--gray3);
  width: 100%;
  /* max-height: 68rem; */

  header {
    height: 6rem;
    background: #f9f9f9;
    border-bottom: 1px solid var(--gray3);
    border-radius: 0.5rem 0.5rem 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  footer {
    height: 6rem;
    background: #f9f9f9;
    width: 100%;
    border-radius: 0 0 0.5rem 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .column {
    display: flex;
    align-items: center;
  }

  @media (max-width: 560px) {
    height: 45rem;
  }
`;

export const Button = styled.button`
  height: 6rem;
  width: 8rem;
  border-right: 1px solid var(--gray3);
  color: #243342;
  cursor: pointer;
  display: ${props => props.disable && 'none'};

  .iconify {
    font-size: 2rem;
  }

  ${props =>
    props.finaly &&
    css`
      border-right: none;
    `}

  ${props =>
    props.left &&
    css`
      border-left: 1px solid var(--gray3);
    `}
`;

export const ContainerText = styled.div`
  display: flex;
  margin-left: 1.2rem;

  @media (max-width: 560px) {
    display: none;
  }
`;

export const Text = styled.span`
  font-size: 1.9rem;
  color: #243342;
  margin-right: 1.2rem;
  padding-right: 1.2rem;
  border-right: 1px solid var(--gray3);

  ${props =>
    props.finaly &&
    css`
      border-right: none;
    `}

  ${props =>
    props.footer &&
    css`
      border-right: none;
      margin: 0 6rem;
    `}
`;

export const Image = styled.img`
  width: 100%;
  /* height: 59rem; */
  height: 90%;
  object-fit: contain;

  @media (max-width: 560px) {
    height: 32rem;
  }
`;

export const IconFlag = styled.img`
  height: 6rem;
  width: 6rem;
`;

export const BottonModal = styled.div`
  height: 4rem;
  width: 100%;
  background: #083059;
  border-radius: 0.4rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #fff;
  font-size: 1.6rem;
`;

/* ESTILO DA IMAGEM FULL SCREEN */

export const ImageFull = styled.img`
  max-height: calc(100vh - 90px);
  max-width: 100%;
  border: 1px solid #fff;
`;

export const ContainerImageFullScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.9);
`;

export const WrapperHeaderImageFullScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 20px;
`;

export const TitleImageFullScreen = styled.p`
  font-size: 16px;
  color: #fff;
  background-color: #000;
  padding: 1rem 2rem;
  border: 1px solid #fff;
`;

export const WrapperButtonClose = styled.button`
  color: #fff;
  position: absolute;
  right: 0;
  margin-right: 2rem;
  margin-top: 2rem;

  &:hover {
    color: var(--primary-color-yellow);
  }
`;
