import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import IconBreakthrough from '../../../assets/svgMethod/icon_breakthrough.svg';
import { Header } from '../../../components/Header';
import { api } from '../../../services/api';
import {
  Button,
  CardStage,
  Container,
  ContainerText,
  HeaderStage,
  IconStage,
  Text,
  Wrapper,
  EmptyListContainer,
  LoadingContainer,
} from './styles';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { AcceptCertificateModal } from './AcceptCertificateModal';

import { useQuery } from '../../../hooks/useQuery';

export function PendingCertificates() {
  const { t } = useTranslation();

  const query = useQuery();

  const [certificates, setCertificates] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [certificateToAccept, setCertificateToAccept] = useState();
  const [isAcceptCertificateModalOpen, setIsAcceptCertificateModalOpen] =
    useState(false);

  async function fetchCertificates() {
    setIsLoading(true);
    const response = await api.get('/certificates', {
      params: {
        status: 'requested',
      },
    });
    setCertificates(response.data);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchCertificates();
  }, []);

  useEffect(() => {
    async function fetchCertificate() {
      try {
        const response = await api.get(`/certificates/${query.id}`);
        setCertificateToAccept(response.data.id);
        setIsAcceptCertificateModalOpen(true);
      } catch (err) {
        console.log(err);
        toast(t('thereIsNoCertificateAvailable'), { type: 'error' });
      }
    }
    if (query.id) {
      fetchCertificate();
    }
  }, []);

  function handleAcceptCertificateModalOpen(certificate) {
    setCertificateToAccept(certificate);
    setIsAcceptCertificateModalOpen(true);
  }

  function handleAcceptCertificateModalClose() {
    setCertificateToAccept(undefined);
    setIsAcceptCertificateModalOpen(false);
  }

  async function handleAcceptCertificate(id, signatureId) {
    setIsLoading(true);

    try {
      await api.patch(`/certificates/accept/${id}`, {
        signatureId,
      });

      toast('Certificate Accepted Successfully!', {
        type: 'success',
      });
    } catch (err) {
      toast('Error to update the certificate', {
        type: 'error',
      });
    } finally {
      fetchCertificates();
    }
  }

  async function handleRejectCertificate(id) {
    try {
      await api.patch(`/certificates/reject/${id}`);

      toast('Certificate Rejected Successfully!', {
        type: 'success',
      });
    } catch (err) {
      toast('Error', {
        type: 'error',
      });
    } finally {
      fetchCertificates();
    }
  }

  return (
    <>
      <Container>
        <Header />

        <Wrapper>
          <Text title>{t('certificadosPendentes')}</Text>
          {isLoading ? (
            <LoadingContainer>
              <Loader
                visible
                type="ThreeDots"
                color="#124f8c"
                secondaryColor="#191919"
              />
            </LoadingContainer>
          ) : !certificates || certificates.length === 0 ? (
            <EmptyListContainer>
              <h1>{t('thereIsNoPendingRequest')}</h1>
            </EmptyListContainer>
          ) : (
            certificates.map(certificate => (
              <HeaderStage>
                <CardStage>
                  <IconStage src={IconBreakthrough} />
                  <ContainerText>
                    <Text>
                      {certificate.student.name +
                        ' ' +
                        certificate.student.lastName}
                    </Text>
                    <Text>A1 {' > '} Breakthrough</Text>
                  </ContainerText>
                  <span className="arrow" />
                </CardStage>
                <div className="buttons-container">
                  <Button
                    view
                    onClick={() => {
                      handleRejectCertificate(certificate.id);
                    }}
                  >
                    {t('deny')}
                  </Button>
                  <Button
                    onClick={() => {
                      handleAcceptCertificateModalOpen(certificate.id);
                    }}
                  >
                    {t('grant')}
                  </Button>
                </div>
              </HeaderStage>
            ))
          )}
        </Wrapper>
      </Container>
      {isAcceptCertificateModalOpen && (
        <AcceptCertificateModal
          open={isAcceptCertificateModalOpen}
          certificate={certificateToAccept}
          onSubmit={handleAcceptCertificate}
          onRequestClose={handleAcceptCertificateModalClose}
        />
      )}
    </>
  );
}
