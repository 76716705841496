import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background: #fff;
  width: 100%;
  /* max-height: 27rem; */
  border: 1px solid var(--gray3);
  margin-top: 1.2rem;
  border-radius: 0.5rem;
`;

export const ContainerTitle = styled.div`
  width: 100%;
  height: 5.8rem;
  background: #083059;
  color: #fff;
  border-radius: 0.5rem 0.5rem 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  @media (max-width: 500px) {
    display: grid;
    gap: 1rem;
    grid-auto-rows: min-content;
    height: auto;
    padding-bottom: 1rem;
  }

  @media (max-width: 400px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
  }
`;

export const ContainerBetween = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 400px) {
    display: grid;
    gap: 1rem;
    width: 100%;
  }
`;

export const Text = styled.span`
  font-size: 1.4rem;
  color: #b9b9c3;

  ${props =>
    props.title &&
    css`
      font-size: 2rem;
      font-weight: 650;
      color: #fff;
    `}

  ${props =>
    props.breakthrough &&
    css`
      font-size: 1.9rem;
      color: #083059;
      font-weight: 950;
    `}
`;

export const Select = styled.select`
  background: #fff;
  color: #083059;
  padding: 0 2rem;
  height: 4rem;
  margin-left: 1rem;
  font-weight: 650;
  font-size: 1.7rem;

  ${props =>
    props.secondSelect &&
    css`
      font-weight: 500;
    `}
`;

export const List = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem 1rem;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  border-bottom: 1px solid var(--gray3);

  ${props =>
    props.bottom &&
    css`
      border-bottom: 0;
    `}

  @media (max-width: 1280px) {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  @media (max-width: 768px) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 400px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
  }
`;

export const Image = styled.img``;

export const Button = styled.button`
  background: #083059;
  color: #fff;
  padding: 0 6rem;
  height: 4rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;
  font-size: 1.6rem;

  :hover {
    filter: brightness(0.8);
  }

  .iconnify {
    font-size: 1.6rem;
    margin-left: 1rem;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const ContainerIcon = styled.div`
  height: 3.5rem;
  width: 21rem;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  .iconify {
    margin: 0 0.5rem;
  }

  span {
    color: #243342;
    font-size: 1.5rem;
  }

  ${props =>
    props.pendent &&
    css`
      border: 1px solid #dbad00;
      span {
        color: #dbad00;
      }

      .iconify {
        color: #dbad00;
      }

      .iconify + span + .iconify {
        color: #fff;
      }
    `}
`;
