import React, { createContext, useState } from 'react';

import { City } from '../../../../models/city';
import { GenericType } from '../../../../models/generic-type';
import { Plan } from '../../../../models/plan';
import { PurchaseResponse } from '../../../../models/purchase-response';
import { State } from '../../../../models/state';

interface IFormData {
  city?: City;
  state?: State;
  address?: string;
  number?: string;
  cep?: string;
  billingType?: string;
  cupomcode?: string;
  cpfcnpj?: string;
  plan?: Plan;
  installmentCount?: number;
  creditCard?: {
    ccv?: number;
    expiryMonth?: number;
    expiryYear?: number;
    holderName?: string;
    number?: string;
  }
}

interface IFormError {
  [key: string]: string | [] | undefined,  
}

export interface IPurchaseContext {
  plans: Plan[];
  formData: IFormData;
  commission?: Record<string, GenericType>;
  formError?: IFormError;
  purchaseResponse?: PurchaseResponse;

  setPlans: (value: Plan[]) => void;
  setFormData: (value: IFormData) => void;
  setCommission: (value: Record<string, GenericType>) => void;
  setFormError: (value: IFormError) => void;
  addFieldError: (field: string, text: string) => void;
  removeFieldError: (field: string) => void;
  setPurchaseResponse: (value: PurchaseResponse) => void;
}

const defaultState = {
  plans: [],
  formData: {},

  setPlans: (value: Plan[]) => {},
  setFormData: (value: IFormData) => {},
  setCommission: (value: Record<string, GenericType>) => {},
  setFormError: (value: IFormError) => {},
  addFieldError: (field: string, text: string) => {},
  removeFieldError: (field: string) => {},
  setPurchaseResponse: (value: PurchaseResponse) => {},
};

const PurchaseContext = createContext<IPurchaseContext>(defaultState);

interface PurchaseContextProps {
  children: React.ReactNode;
}

function PurchaseContextProvider(props: PurchaseContextProps): React.ReactElement {
  const [plans, setPlans] = useState<Plan[]>(defaultState.plans);
  const [formData, setFormData] = useState<IFormData>({});
  const [commission, setCommission] = useState({});
  const [formError, setFormError] = useState({});
  const [purchaseResponse, setPurchaseResponse] = useState<PurchaseResponse>({})
  const { children } = props;

  function handleFieldFormError(field: string, text: string): void {
    setFormError({ ...formError, [field]: text });
  }

  function handleRemoveFieldError(field: string): void {
    setFormError({ ...formError, [field]: undefined });
  }

  return (
    <PurchaseContext.Provider value={{
      plans,
      formData,
      commission,
      formError,
      purchaseResponse,
      setCommission: (value) => setCommission(value),
      setPlans: (value) => setPlans(value),
      setFormData: (value) => setFormData(value),
      setFormError: (value) => setFormError(value),
      addFieldError: (field, text) => handleFieldFormError(field, text),
      removeFieldError: (field: string) => handleRemoveFieldError(field),
      setPurchaseResponse: (value) => setPurchaseResponse(value),
    }}
    >
      {children}
    </PurchaseContext.Provider>
  );
}

export default PurchaseContext;
export { PurchaseContextProvider };
