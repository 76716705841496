import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Column,
  ContainerText,
  ContainerIcon,
  ContainerStatus,
  Text,
  Select,
} from './styles';
import { ContainerMessage } from '../../pages/Dashboard/Ticket/Pending/styles'
import { ChatTicket } from '../ChatTicket';
import { getDatePtBr, getTimePtBr } from '../../utils/getDateTimePtBr';

export function ListTicket({
  idChamado,
  ticket,
  title,
  description,
  status,
  createdAt
}) {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState({});
  const [historic, setHistoric] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCollapseClick = async (ticket, idChamado) => {
    setIsCollapsed(prevState => ({ ...isCollapsed, [ticket]: !prevState[ticket] }));

    if (isCollapsed[ticket] === false || isCollapsed[ticket] === undefined) {
      setLoading(true);
      const { data: { data } } = await axios.get(`https://api.tomticket.com/chamado/b40b8bc6aa8c3891087231bd5b097c09/${idChamado}`);
      data.historico.push({
        id: data.idchamado,
        origem: data.tipochamado === 'Externo' ? 'C' : 'A',
        mensagem: data.mensagem,
        anexos: data.anexos,
      });
      setHistoric(data.historico.reverse());
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Column>
          <ContainerIcon>
            <span
              className="iconify"
              data-icon="bi:chat-left-text"
              data-inline="false"
            />
          </ContainerIcon>
          <Text ticket>#{ticket}</Text>
          <ContainerStatus>
            <Text status>{status === 'Finalizado' ? t('finish') : t('open')}</Text>
          </ContainerStatus>
          <ContainerText>
            <Text title>{title}</Text>
            <Text description>{description}</Text>
          </ContainerText>
        </Column>
        <Column middle>
          <ContainerIcon>
            <span
              className="iconify"
              data-icon="akar-icons:calendar"
              data-inline="false"
            />
          </ContainerIcon>
          <ContainerText>
            <Text openDate>{t('openingDate')}</Text>
            <Text date>{getDatePtBr(createdAt)}</Text>
          </ContainerText>
          <ContainerIcon date>
            <span
              className="iconify"
              data-icon="ic:outline-watch-later"
              data-inline="false"
            />
          </ContainerIcon>
          <ContainerText>
            <Text openDate>{t('openingTime')}</Text>
            <Text date>{getTimePtBr(createdAt)}</Text>
          </ContainerText>
        </Column>
        <Column>
          <Select onClick={() => handleCollapseClick(ticket, idChamado)}>
            {t('viewTickets') + ' '}
            <span className="iconify" data-icon="fe:arrow-down"></span>
          </Select>
        </Column>
      </Container>
      
      {isCollapsed[ticket] && 
        <ContainerMessage>
          <ChatTicket historic={historic} idChamado={idChamado} status={status} loading={loading} />
        </ContainerMessage>
      }
    </>
  );
}
