import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/AuthProvider';
import { api } from '../../../services/api';
import { parseISO, format } from 'date-fns';

import {
  Container,
  Wrapper,
  Header,
  Text,
  ContainerSearch,
  WrapperList,
  ContainerList,
  ContainerIcon,
  Image,
  ContainerText,
  ContainerInfo,
  TextNote,
  Button,
} from './styles';
import { ListNoteViewModal } from './ListNoteViewModal';
import { useTranslation } from 'react-i18next';

export function MyAnnotations() {
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [notes, setNotes] = useState();
  const { typeLogin, user, signOut } = useAuth();
  const [open, setOpen] = useState(false);
  const [filteredNotes, setFilteredNotes] = useState();
  const [currentNote, setCurrentNote] = useState();

  const { t } = useTranslation();

  const handleOpenModal = index => {
    setCurrentNote(index);
    setOpen(true);
  };

  const handleOnchangeStartDate = value => {
    setStartDate(value);
    const filteredDataNotes = notes.filter(
      data =>
        new Date(data.createdAt) >= new Date(value) &&
        new Date(data.createdAt) <= new Date(endDate),
    );
    setFilteredNotes(filteredDataNotes);
  };

  const handleOnchangeEndDate = value => {
    setEndDate(value);
    const filteredDataNotes = notes.filter(
      data =>
        new Date(data.createdAt) >= new Date(startDate) &&
        new Date(data.createdAt) <= new Date(value),
    );
    setFilteredNotes(filteredDataNotes);
  };

  const handleOnchangeSearch = value => {
    const filteredDataNotes = notes.filter(
      data =>
        data.description.toLowerCase().startsWith(value) &&
        new Date(data.createdAt) >= new Date(startDate) &&
        new Date(data.createdAt) <= new Date(endDate),
    );
    setFilteredNotes(filteredDataNotes);
  };

  const handleLoadNotes = useCallback(async () => {
    const { data } = await api.get(`notes/?page=1`);
    setNotes(data.docs);
    setFilteredNotes(data.docs);
  });

  useEffect(() => {
    handleLoadNotes();
  }, []);

  return (
    <Container>
      <Wrapper>
        <Header>
          <Text title>{t('myAnnotations')}</Text>
          <ContainerSearch>
            <Text date>{t('from')}</Text>
            <span className="datepicker-toggle">
              <span
                className="iconify"
                data-icon="akar-icons:calendar"
                data-inline="false"
              />
              <span>{format(new Date(startDate), 'dd/MM/yyyy')}</span>
              <input
                type="date"
                className="datepicker-input"
                value={startDate}
                onChange={e => handleOnchangeStartDate(e.target.value)}
              />
              <span
                className="iconify"
                data-icon="dashicons:arrow-down-alt2"
                data-inline="false"
              />
            </span>
            <Text date>{t('to')}</Text>
            <span className="datepicker-toggle">
              <span
                className="iconify"
                data-icon="akar-icons:calendar"
                data-inline="false"
              />
              <span>{format(new Date(endDate), 'dd/MM/yyyy')}</span>
              <input
                type="date"
                className="datepicker-input"
                value={endDate}
                onChange={e => handleOnchangeEndDate(e.target.value)}
              />
              <span
                className="iconify"
                data-icon="dashicons:arrow-down-alt2"
                data-inline="false"
              />
            </span>

            <input
              onChange={e => handleOnchangeSearch(e.target.value)}
              type="text"
              placeholder={t('search')}
              className="input-search"
            />
          </ContainerSearch>
        </Header>

        <WrapperList>
          {filteredNotes && (
            <>
              {filteredNotes.map((note, index) => (
                <ContainerList>
                  <ContainerInfo>
                    <Image src={user.avatarURL} />
                    <ContainerText>
                      <Text name>{user.fullName ?? user.name}</Text>
                      <Text subtitle>
                        {note?.method?.title} - {note?.mission?.title}
                      </Text>
                    </ContainerText>
                  </ContainerInfo>
                  <ContainerInfo>
                    <ContainerIcon>
                      <span
                        className="iconify"
                        data-icon="akar-icons:calendar"
                        data-inline="false"
                      />
                    </ContainerIcon>
                    <ContainerText>
                      <Text textDate>{t('date')}</Text>
                      <Text dateList>
                        {format(new Date(note.createdAt), 'dd/MM/yyyy')}
                      </Text>
                    </ContainerText>
                  </ContainerInfo>
                  <ContainerInfo>
                    <ContainerText>
                      <Text textDate>{t('titulo')}</Text>
                      <Text dateList>{note.type}</Text>
                    </ContainerText>
                  </ContainerInfo>
                  <ContainerInfo>
                    <TextNote>{note.description}</TextNote>
                  </ContainerInfo>
                  <ContainerInfo>
                    <Button onClick={() => handleOpenModal(index)}>
                      <span
                        className="iconify"
                        data-icon="akar-icons:eye"
                        data-inline="false"
                      />
                      {t('view')}
                    </Button>
                  </ContainerInfo>
                  {open && (
                    <ListNoteViewModal
                      note={notes[currentNote]}
                      open={open}
                      setOpen={setOpen}
                    />
                  )}
                </ContainerList>
              ))}
            </>
          )}
        </WrapperList>
      </Wrapper>
    </Container>
  );
}
