import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../../components/Button';
import { Span } from '../../../../components/Span';
import { api } from '../../../../services/api';
import { checkIsVideo } from '../../../../helpers/url';
import {
  Container,
  ContainerBody,
  LoadingContainer,
  NextButtonContainer,
  Writtenexercises,
  WrittenexercisesBody,
  WrittenexercisesHeader,
  WrittenexercisesQuestions,
  WrittenexercisesQuestionsButtons,
  WrittenexercisesQuestionsChoice,
  WrittenexercisesQuestionsGroupButtons,
  WrittenexercisesQuestionsUniqueButton,
  WrittenexercisesImage,
  ImageLogo,
  ImageCustom,
  ImageContainer,
  Video,
  VideoBody,
} from './styles';

export function ExamMultipleChoice({ data }) {
  const { t } = useTranslation();

  const history = useHistory();

  const id = '61c1f6002ce7ed7f63f6bff6';

  const [score, setScore] = useState();
  const [homework, setHomework] = useState();
  const [isHomeworkLoading, setIsHomeworkLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const [answers, setAnswers] = useState([]);

  const handleNextQuestion = async () => {
    let responseText = '';
    if (answers) {
      responseText = answers.find(
        a => a.questionId === activeQuestion?._id,
      )?.answer;
      if (
        responseText === null ||
        responseText === undefined ||
        responseText === ''
      ) {
        responseText = '';
      }
    }
    if (responseText === '') {
      toast('Select an answer', {
        type: 'error',
      });
      return false;
    }
    await api.put(`/homework/${data.id}`, { answers });

    setActivePage(prevState => {
      if (data.exam.multipleChoiceQuestions.length - 1 === prevState) {
        return prevState;
      }

      return prevState + 1;
    });
  };

  const handleSubmit = async () => {
    let responseText = '';
    if (answers) {
      responseText = answers.find(
        a => a.questionId === activeQuestion?._id,
      )?.answer;
      if (
        responseText === null ||
        responseText === undefined ||
        responseText === ''
      ) {
        responseText = '';
      }
    }
    if (responseText === '') {
      toast('Select an answer', {
        type: 'error',
      });
      return false;
    }
    console.log('answers', answers);
    await api.put(`/homework/${data.id}/submit`, { answers }).then(response => {
      setScore(response.data.score);
    });
  };

  const handlePreviousQuestion = answerOption => {
    setActivePage(prevState => {
      if (prevState <= 0) {
        return prevState;
      }

      return prevState - 1;
    });
  };

  const handleAnswerChange = useCallback(answer => {
    setAnswers(prevState => {
      const dataUpdate = [...prevState];

      const answerIndex = dataUpdate.findIndex(
        a => a.questionId === answer.questionId,
      );

      if (answerIndex <= -1) {
        return [...prevState, answer];
      }

      dataUpdate[answerIndex] = answer;

      return [...dataUpdate];
    });
  }, []);

  const activeQuestion = useMemo(() => {
    if (!data) return {};
    return data.exam.multipleChoiceQuestions[activePage];
  }, [activePage, data]);

  useEffect(() => {
    fetchHomework();
  }, []);
  const maxQuestionsNumbers = () => {
    if (!data) return 0;
    return data.exam.multipleChoiceQuestions.length;
  };
  const fetchHomework = useCallback(async () => {
    try {
      setIsHomeworkLoading(true);

      await api.get(`homework/${id}`).then(async response => {
        const { data } = response;

        if (data.status === null) {
          await api.patch(`homework/${id}/update-status`, {
            status: 'started',
          });
        }

        setHomework(data);
        setScore(data.score.hits ? data.score : undefined);
        setActivePage(data.answers && data.answers.length);
        if (data.answers && data.answers.length >= maxQuestionsNumbers()) {
          setActivePage(maxQuestionsNumbers() - 1);
        }
        setAnswers(
          data.answers.map(answer => ({
            questionId: answer.questionId,
            question: answer.question,
            answer: Number(answer.answer),
          })),
        );
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsHomeworkLoading(false);
    }
  }, []);

  if (!data) {
    return (
      <LoadingContainer>
        <Loader
          visible
          type="ThreeDots"
          color="#124f8c"
          secondaryColor="#191919"
        />
      </LoadingContainer>
    );
  }

  const isVideo = activeQuestion ? checkIsVideo(activeQuestion.fileURL) : false;

  return (
    <Container>
      <ContainerBody>
        <Writtenexercises>
          <WrittenexercisesHeader>
            <Link to="/dashboard/method">{t('voltar')}</Link>
            <Span>{data.mission.title}</Span>
          </WrittenexercisesHeader>
          <WrittenexercisesBody>
            <WrittenexercisesImage>
              <ImageLogo src="https://www.significadodossonhosonline.com/wp-content/uploads/2019/10/sonhar-com-paraquedas.jpg" />
            </WrittenexercisesImage>
            <WrittenexercisesQuestions>
              {score ? (
                <Span>
                  {t('youScored', { score: (score.grade * 10).toFixed(2) })}
                </Span>
              ) : (
                activeQuestion && (
                  <>
                    {activeQuestion.fileURL && !isVideo && (
                      <ImageContainer>
                        <ImageCustom src={activeQuestion.fileURL} />
                      </ImageContainer>
                    )}
                    {isVideo && (
                      <ImageContainer>
                        <Video src={activeQuestion.fileURL} controls />
                      </ImageContainer>
                    )}
                    <div className="text-container">
                      <span>
                        {activePage + 1} - {activeQuestion.title}
                      </span>
                      <span>
                        {activePage + 1} -{' '}
                        {data.exam.multipleChoiceQuestions.length}
                      </span>
                    </div>
                    <WrittenexercisesQuestionsChoice>
                      {activeQuestion.answers?.map((answer, index) => {
                        const isSelected = answers.some(
                          a =>
                            a.questionId === activeQuestion._id &&
                            a.answer === index,
                        );

                        return (
                          <Button
                            color={isSelected ? '#06231F' : '#fff'}
                            fontColor={isSelected ? '#fff' : '#06231F'}
                            title={answer.text}
                            onClick={() => {
                              handleAnswerChange({
                                questionId: activeQuestion._id,
                                question: activeQuestion.title,
                                answer: index,
                              });
                            }}
                          />
                        );
                      })}
                      <div className="buttons-container">
                        <NextButtonContainer>
                          <Button
                            color={activePage != 0 ? '#06231F' : '#999'}
                            fontColor="#fff"
                            title={t('voltar')}
                            style={{ width: '49%', marginRight: '2%' }}
                            onClick={handlePreviousQuestion}
                          />
                          {data.exam.multipleChoiceQuestions.length - 1 ===
                          activePage ? (
                            <Button
                              color="#06231F"
                              fontColor="#fff"
                              title={t('submit')}
                              style={{ width: '49%' }}
                              onClick={handleSubmit}
                            />
                          ) : (
                            <Button
                              color="#06231F"
                              fontColor="#fff"
                              title={t('next')}
                              style={{ width: '49%' }}
                              onClick={handleNextQuestion}
                            />
                          )}
                        </NextButtonContainer>
                        <Button
                          color="#06231F"
                          fontColor="#fff"
                          title={t('finishLater')}
                          onClick={() =>
                            history.push('/dashboard/homework/written')
                          }
                        />
                      </div>
                    </WrittenexercisesQuestionsChoice>
                  </>
                )
              )}
            </WrittenexercisesQuestions>
          </WrittenexercisesBody>
        </Writtenexercises>
      </ContainerBody>
    </Container>
  );
}
