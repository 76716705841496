import formatDate from 'date-fns/format';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { boolean } from 'yup';
import IconBallon from '../../../assets/icon_breakthrough.png';
import { api } from '../../../services/api';
import { ListCorrectionModal } from '../../../pages/Dashboard/Method/ListCorrectionModal';
import { ListCorrectionClosedModal } from '../../../pages/Dashboard/Method/ListCorrectionClosedModal';
import {
  BottonModal,
  Button,
  Container,
  ContainerExercise,
  ContainerExerciseList,
  ContainerHeaderModal,
  ContainerListModal,
  ContainerText,
  ContainerTextModal,
  Image,
  ListModal,
  Text,
} from './styles';

export function StudentList({ status, method, type }) {
  const { t } = useTranslation();

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [homeworks, setHomeworks] = useState();
  const [homework, setHomework] = useState();
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);

  const handleLoadHomeworks = useCallback(async () => {
    const { data } = await api.get(`homework`, {
      params: {
        type,
        status,
        method,
      },
    });
    setHomeworks(data);
  }, [type, status]);

  useEffect(() => {
    handleLoadHomeworks();
  }, [handleLoadHomeworks]);

  const handleOpenModal = event => {
    setHomework(event);
    console.log('teste', event);
    if (event.type === 'multiple_choice') {
      setClose(true);
    } else {
      setOpen(true);
    }
  };

  const handleToViewButtonClick = id => {
    if (window.location.pathname === '/dashboard/homework/written') {
      localStorage.setItem('state', JSON.stringify({ id, headerInvisible: true }));
      history.push('writing', { id, headerInvisible: true });
    } else {
      localStorage.setItem('state', JSON.stringify({ id }));
      history.push('multiple', { id });
    }
  };

  function closeModal() {
    setShowModal(false);
  }

  return (
    <Container>
      {(status === 'corrected' ||
        status === 'failed' ||
        status === 'passed') && (
        <>
          {homeworks?.map((homework, index) => (
            <ContainerExercise>
              <ContainerExerciseList>
                <img className="Icon" src={IconBallon} alt="" />
                <ContainerText sizeFixed>
                  <Text title>{homework?.method?.title}</Text>
                  <Text>{homework?.mission?.title}</Text>
                </ContainerText>
                {homework.teacher && (
                  <>
                    <Image src={homework?.teacher?.avatarURL} />
                    <ContainerText>
                      <Text proffy>{t('teacher')}</Text>
                      <Text name>
                        {homework?.teacher?.name +
                          ' ' +
                          homework?.teacher?.lastName}
                      </Text>
                    </ContainerText>
                  </>
                )}
                {homework?.studentStartDate && homework?.studentEndDate && (
                  <>
                    <div className="container-icon">
                      <span
                        className="iconify"
                        data-icon="akar-icons:calendar"
                        data-inline="false"
                      />
                    </div>
                    <ContainerText>
                      <Text date>{t('dueDate')}</Text>
                      <Text hours>
                        {formatDate(
                          new Date(homework?.studentStartDate),
                          'HH:mm',
                        )}{' '}
                        as{' '}
                        {formatDate(
                          new Date(homework?.studentEndDate),
                          'HH:mm',
                        )}
                      </Text>
                    </ContainerText>
                  </>
                )}

                {homework?.correctionStartDate && homework?.correctionEndDate && (
                  <>
                    <div className="container-icon">
                      <span
                        className="iconify"
                        data-icon="akar-icons:calendar"
                        data-inline="false"
                      />
                    </div>
                    <ContainerText>
                      <Text date>{t('dueDate')}</Text>
                      <Text hours>
                        {formatDate(
                          new Date(homework?.correctionStartDate),
                          'HH:mm',
                        )}{' '}
                        {t('at')}{' '}
                        {formatDate(
                          new Date(homework?.correctionEndDate),
                          'HH:mm',
                        )}
                      </Text>
                    </ContainerText>
                  </>
                )}
                <div className="container-icon">
                  <span
                    className="iconify"
                    data-icon="akar-icons:file"
                    data-inline="false"
                  />
                </div>
                <ContainerText>
                  {/* <Text date>{t('grade')}</Text> */}
                  <Text note>{homework?.score?.grade?.toFixed(2)}</Text>
                </ContainerText>
                <div className="container-icon">
                  <span
                    className="iconify"
                    data-icon="akar-icons:check"
                    data-inline="false"
                  />
                </div>
                <ContainerText>
                  <Text>
                    {t('hits')}: {homework?.score?.hits}
                  </Text>
                </ContainerText>
                <div className="container-icon">
                  <span
                    className="iconify"
                    data-icon="eva:close-fill"
                    data-inline="false"
                  />
                </div>
                <ContainerText>
                  <Text>
                    {t('mistakes')}: {homework?.score?.misses}
                  </Text>
                </ContainerText>
                <ContainerText>
                  {/* {homework.type === 'open' && ( */}
                  <Button
                    back="#fff"
                    color="#083059"
                    style={{ marginRight: '10px' }}
                    onClick={() => handleOpenModal(homework)}
                  >
                    <span
                      className="iconify"
                      data-icon="akar-icons:eye"
                      data-inline="false"
                    />
                    {t('view')}
                  </Button>
                  {/* )} */}
                </ContainerText>
              </ContainerExerciseList>
            </ContainerExercise>
          ))}
        </>
      )}

      <>
        {type === 'open' && status === 'pending' && (
          <>
            {homeworks?.map((homework, index) => (
              <ContainerExercise>
                <ContainerExerciseList>
                  <img className="Icon" src={IconBallon} alt="" />
                  <ContainerText sizeFixed>
                    <Text title>{homework?.method?.title}</Text>
                    <Text>{homework?.mission?.title}</Text>
                  </ContainerText>
                  {homework.teacher && (
                    <>
                      <Image src={homework?.teacher?.avatarURL} />
                      <ContainerText>
                        <Text proffy>{t('teacher')}</Text>
                        <Text name>
                          {homework?.teacher?.name +
                            ' ' +
                            homework?.teacher?.lastName}
                        </Text>
                      </ContainerText>
                    </>
                  )}
                </ContainerExerciseList>
                <ContainerExerciseList>
                  {/* <Button
                    back="#fff"
                    color="#083059"
                    onClick={() => {
                      setShowModal(true);
                      setHomework(homework);
                    }}
                  >
                    <span
                      className="iconify"
                      data-icon="akar-icons:eye"
                      data-inline="false"
                    />
                    {t('view')}
                  </Button> */}

                  {homework.status === 'started' ? (
                    <Button
                      back="#083059"
                      color="#fff"
                      inital
                      onClick={() => handleToViewButtonClick(homework.id)}
                    >
                      <span
                        className="iconify"
                        data-icon="fluent:text-bullet-list-tree-16-filled"
                        data-inline="false"
                      />
                      {t('continue')}
                      <span
                        className="iconify"
                        data-icon="dashicons:arrow-right-alt2"
                        data-inline="false"
                      />
                    </Button>
                  ) : homework.status === 'pending' ? (
                    <Button
                      back="#083059"
                      color="#fff"
                      inital
                      onClick={() => handleToViewButtonClick(homework.id)}
                    >
                      <span
                        className="iconify"
                        data-icon="fluent:text-bullet-list-tree-16-filled"
                        data-inline="false"
                      />
                      {t('start')}
                      <span
                        className="iconify"
                        data-icon="dashicons:arrow-right-alt2"
                        data-inline="false"
                      />
                    </Button>
                  ) : null}
                </ContainerExerciseList>
              </ContainerExercise>
            ))}
          </>
        )}
        {type === 'multiple_choice' && status === 'pending' && (
          <>
            {homeworks?.map((homework, index) => (
              <>
                <ContainerExercise>
                  <ContainerExerciseList>
                    <img className="Icon" src={IconBallon} alt="" />
                    <ContainerText sizeFixed>
                      <Text title>{homework?.method?.title}</Text>
                      <Text>{homework?.mission?.title}</Text>
                    </ContainerText>
                    {homework.teacher && (
                      <>
                        <Image src={homework?.teacher?.avatarURL} />
                        <ContainerText>
                          <Text proffy>{t('teacher')}</Text>
                          <Text name>
                            {homework?.teacher?.fullName ??
                              homework?.teacher?.name}
                          </Text>
                        </ContainerText>
                      </>
                    )}
                  </ContainerExerciseList>
                  <ContainerExerciseList>
                    {/* <Button
                      back="#fff"
                      color="#083059"
                      onClick={() => {
                        setShowModal(true);
                        setHomework(homework);
                      }}
                    >
                      <span
                        className="iconify"
                        data-icon="akar-icons:eye"
                        data-inline="false"
                      />
                      {t('view')}
                    </Button> */}
                    <Button
                      back="#083059"
                      color="#fff"
                      inital
                      onClick={() => handleToViewButtonClick(homework.id)}
                    >
                      <span
                        className="iconify"
                        data-icon="fluent:text-bullet-list-tree-16-filled"
                        data-inline="false"
                      />

                      {homework.status == 'started' ? (
                        <>
                          {t('continue')}
                          <span
                            className="iconify"
                            data-icon="dashicons:arrow-right-alt2"
                            data-inline="false"
                          />
                        </>
                      ) : homework.status == 'pending' ? (
                        <>
                          {t('start')}
                          <span
                            className="iconify"
                            data-icon="dashicons:arrow-right-alt2"
                            data-inline="false"
                          />
                        </>
                      ) : null}
                    </Button>
                  </ContainerExerciseList>
                </ContainerExercise>
              </>
            ))}
          </>
        )}
      </>
      {open && (
        <ListCorrectionModal
          viewMode
          correction={homework}
          modalIsOpen={open}
          setIsOpen={setOpen}
        />
      )}
      {close && (
        <ListCorrectionClosedModal
          viewMode
          correction={homework}
          modalIsOpen={close}
          setIsOpen={setClose}
        />
      )}
      {showModal && (
        <Modal
          className="react-modal-content"
          overlayClassName="react-modal-overlay"
          isOpen={showModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          {homework && (
            <>
              <ContainerHeaderModal>
                <Text titleModal>
                  {t('exercise')} {homework?.method?.title} -{' '}
                  {homework?.mission?.title}
                </Text>
              </ContainerHeaderModal>
              <ContainerListModal>
                {homework?.answers.map((answer, index) => (
                  <ListModal>
                    <ContainerTextModal>
                      <Text>
                        {index + 1}. {answer?.question}
                      </Text>
                    </ContainerTextModal>
                    <ContainerTextModal bottom>
                      <Text>{answer?.answer}</Text>
                    </ContainerTextModal>
                  </ListModal>
                ))}
              </ContainerListModal>
            </>
          )}
          <BottonModal onClick={closeModal}>{t('fechar')}</BottonModal>
        </Modal>
      )}
    </Container>
  );
}
