import axios from 'axios';

export const blobToBase64 = async (url: string) => {
  const blob = await axios
    .get(url, {
      responseType: 'blob',
    })
    .then((response) => response.data);

  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.readAsDataURL(blob);

    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};
