import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isAfiliate,
  isStudent, isTeacher, useAuth,
} from '../../../hooks/AuthProvider';
import { api } from '../../../services/api';
import {
  ContainerMenu,
  ContainerDrop,
  NavLinkRouter,
  NavText,
  LogoTableTalks,
  ButtonResponsive,
  Text,
  NavRouter,
} from './styles';

export const Menu = (props) => {
  const { t } = useTranslation();
  const { token, user, signOut } = useAuth();
  const [isPurchasePeriod, setIsPurchasePeriod] = useState();
  const passedTeachingKnowledgeTest = isTeacher() && user.teachingKnowledgeTest.status === 'approved';
  const isStudantAndPlan = isStudent() && user.plan;

  useEffect(() => {
    fetchPurchasePeriod();
  }, []);

  function fetchPurchasePeriod() {
    api.get('/plans')
      .then((res) => res.data[0])
      .then(purchasePeriod => {
        const now = new Date();
        const startingData = new Date(
          purchasePeriod.startingDate?.replaceAll('-', '/'),
        );
        const endingData = new Date(purchasePeriod.endingDate?.replaceAll('-', '/'));
    
        if (now >= startingData && now <= endingData) {
          setIsPurchasePeriod(true);
        } else {
          setIsPurchasePeriod(false);
        }
      });
  }

  function createTeacherMenu() {
    let menuItems = [
      renderKnowledgeTest(),
    ];

    if (passedTeachingKnowledgeTest) {
      menuItems = [
        ...menuItems,
        renderMenu('method', 'icons8:student', 'metodo'),
        renderMenu('profile', 'ant-design:user-outlined', 'profile'),
        renderMenu('messages', 'bi:chat-left-text', 'mensagens'),
        renderMenu('pending-certificates', 'teenyicons:certificate-outline', 'certificadosPendentes'),
        renderMenu('schedule', 'akar-icons:calendar', 'schedule'),
        renderMenu('assignments', 'fluent:clipboard-task-list-rtl-24-regular', 'tarefas'),
        renderMenu('guidelines', 'bx:bx-cube', 'guias'),
      ];
    }

    menuItems = [
      ...menuItems,
      renderMenu('help', 'bx:bx-help-circle', 'ajuda'),
      renderMenu('settings', 'bx:bx-cog', 'configuracoes'),
    ];

    return menuItems;
  }

  function createStudentMenu() {
    return [
      renderMenu('method', 'ant-design:control-outlined', 'dashboard'),
      renderMenu('tt/method', 'icons8:student', 'metodo'),
      renderMenu('profile', 'ant-design:user-outlined', 'profile'),
      renderMenu('homework', 'cib:when-i-work', 'tarefasAluno'),
      renderMenu('messages', 'bi:chat-left-text', 'mensagens'),
      renderMenu('certificate', 'teenyicons:certificate-outline', 'certificado'),
      renderMenu('help', 'bx:bx-help-circle', 'ajuda'),
      renderMenu('settings', 'bx:bx-cog', 'configuracoes'),
      renderMenu('guidelines', 'bx:bx-cube', 'guias'),
    ];
  }

  function createAfiliateMenu() {
    return [
      renderMenu('profile', 'ant-design:user-outlined', 'profile'),
    ];
  }

  function renderMenu(route, icon, name) {
    return (
      <NavLinkRouter to={`${props.match.path}/${route}`}>
        <span
          className="iconify"
          data-icon={icon}
          data-inline="false"
        />
        <NavText showMenu={props.showMenu}>{t(name)}</NavText>
        <Text onClick={() => setShowMenu(false)}>{t(name)}</Text>
      </NavLinkRouter>
    );
  }

  function renderKnowledgeTest() {
    if (passedTeachingKnowledgeTest) {
      return (
        <NavLinkRouter to={`${props.match.path}/teaching-knowledge-test`}>
        <span
          className="iconify"
          data-icon="icons8:student"
          data-inline="false"
        />
        <NavText showMenu={props.showMenu}>
          {t('testeDeConhecimentoDeEnsino')}
        </NavText>
        <Text onClick={() => setShowMenu(false)}>
          {t('testeDeConhecimentoDeEnsino')}
        </Text>
      </NavLinkRouter>
      )
    }
    return null;
  }

  return (
    <ContainerMenu showMenu={props.showMenu}>
      <ContainerDrop>
        <LogoTableTalks />
        {/* <Button showMenu={showMenu} onClick={() => setShowMenu(true)}>
          <SpanMenu
            className="iconify"
            data-icon="feather:menu"
            data-inline="false"
          />
        </Button> */}
        <ButtonResponsive
          showMenu={props.showMenu}
          onClick={() => setShowMenu(false)}
        >
          <span
            className="iconify"
            data-icon="eva:close-fill"
            data-inline="false"
          />
        </ButtonResponsive>
      </ContainerDrop>
      
      {/* show menu items */}
      {isTeacher() && createTeacherMenu()}
      {isStudantAndPlan && createStudentMenu()}
      {isAfiliate() && createAfiliateMenu()}

      {isPurchasePeriod
        && (
        <NavRouter
          onClick={() => {
            window.location.href = `https://automation-lp.tabletalks.com.br/video?token=${token}`;
          }}
        >
          <span
            className="iconify"
            data-icon="bx:bx-money"
            data-inline="false"
          />
          <NavText showMenu={showMenu}>{t('buyPlan')}</NavText>
          <Text onClick={() => setShowMenu(false)}>{t('buy')}</Text>
        </NavRouter>
        )}
      <NavLinkRouter to="/" exact onClick={signOut}>
        <span
          className="iconify"
          data-icon="mdi:logout-variant"
          data-inline="false"
        />
        <NavText showMenu={props.showMenu}>{t('logOut')}</NavText>
        <Text onClick={() => setShowMenu(false)}>{t('logOut')}</Text>
      </NavLinkRouter>
    </ContainerMenu>
  );
};
