import React, { useState, useEffect, useCallback } from 'react';
import Icon from '../../../assets/icon_breakthrough.png';
import {
  Container,
  ContainerTitle,
  ContainerBetween,
  Text,
  Select,
  List,
  Image,
  Button,
  ContainerText,
  ContainerIcon,
} from './styles';
import { ListCorrectionModal } from '../../../pages/Dashboard/Method/ListCorrectionModal';
import { ListCorrectionClosedModal } from '../../../pages/Dashboard/Method/ListCorrectionClosedModal';
import { api } from '../../../services/api';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/AuthProvider';

const STATUS = [
  {
    value: 'pending',
    label: 'pending',
  },
  {
    value: 'corrected',
    label: 'approved',
  },
  {
    value: 'failed',
    label: 'failed',
  },
  {
    value: 'passed',
    label: 'passed',
  },
];

export function ListQuest({ title, type, url }) {
  const { t } = useTranslation();

  const { user } = useAuth();
  const history = useHistory();

  const [methods, setMethods] = useState([]);
  const [homeworks, setHomeworks] = useState([]);
  const [method, setMethod] = useState();
  const [myHomework, setMyHomework] = useState();
  const [status, setStatus] = useState(STATUS[0].value);
  const [open, setOpen] = useState(false);

  const handleOpenModal = event => {
    console.log(event);
    setMyHomework(event);
    setOpen(true);
  };

  const fetchHomeworks = useCallback(async () => {
    if (!method) return;
    await api
      .get(`/homework`, { params: { type, method, status } })
      .then(response => {
        setHomeworks(response.data);
      });
  }, [method, status]);

  useEffect(() => {
    fetchHomeworks();
  }, [fetchHomeworks]);

  useEffect(() => {
    async function fetchMethods() {
      await api.get('/methods').then(response => {
        setMethods(response.data.docs);
        setMethod(user?.level?.method?._id);
      });
    }

    fetchMethods();
  }, []);

  return (
    <Container>
      <ContainerTitle>
        <ContainerBetween>
          <Text title>{title}</Text>
        </ContainerBetween>
        <ContainerBetween>
          <Select
            value={method}
            onChange={({ target }) => setMethod(target.value)}
          >
            {methods?.map(method => (
              <option key={method._id} value={method._id}>
                {method.title}
              </option>
            ))}
          </Select>
          <Select
            secondSelect
            value={status}
            onChange={({ target }) => setStatus(target.value)}
          >
            {STATUS.map(e => (
              <option key={e.value} name="" value={e.value}>
                {t(e.label)}
              </option>
            ))}
          </Select>
        </ContainerBetween>
      </ContainerTitle>
      {homeworks?.map(homework => (
        <List>
          <ContainerBetween>
            <Image src={Icon} />
            <ContainerText>
              <Text breakthrough>{homework.method.title}</Text>
              <Text>{homework.mission.title}</Text>
            </ContainerText>
          </ContainerBetween>
          <ContainerBetween>
            {homework.status === 'pending' || homework.status === 'started' ? (
              <Button
                to={url}
                onClick={() => {
                  localStorage.setItem('state', JSON.stringify({ id: homework._id }));
                  history.push(url, { id: homework._id });
                }}
              >
                {t('comecar')}
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-right-alt2"
                  data-inline="false"
                />
              </Button>
            ) : null}
            {['correction_completed', 'passed', 'failed'].includes(
              homework.status,
            ) &&
              homework.score && (
                <>
                  <Button
                    style={{ marginRight: '10px' }}
                    onClick={() => handleOpenModal(homework)}
                  >
                    View
                    <span
                      className="iconify"
                      data-icon="dashicons:arrow-right-alt2"
                    />
                  </Button>
                  <ContainerIcon>
                    <span
                      className="iconify"
                      data-icon="el:signal"
                      data-inline="false"
                    />
                    <Text>
                      Score: <b>{homework.score?.grade.toFixed(2)}</b>
                    </Text>
                  </ContainerIcon>
                </>
              )}

            {['awaiting_correction', 'correction_started'].includes(
              homework.status,
            ) && (
              <ContainerIcon pendent>
                <span
                  className="iconify"
                  data-icon="el:signal"
                  data-inline="false"
                />
                <Text>{t('pendingCorrection')}</Text>
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-right-alt2"
                  data-inline="false"
                />
              </ContainerIcon>
            )}
          </ContainerBetween>
        </List>
      ))}
      {open && myHomework && myHomework.type === 'multiple_choice' && (
        <ListCorrectionClosedModal
          viewMode
          correction={myHomework}
          modalIsOpen={open}
          setIsOpen={setOpen}
        />
      )}
      {open && myHomework && myHomework.type != 'multiple_choice' && (
        <ListCorrectionModal
          viewMode
          correction={myHomework}
          modalIsOpen={open}
          setIsOpen={setOpen}
        />
      )}
    </Container>
  );
}
