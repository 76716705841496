import { Autocomplete } from '@mui/lab';
import {
  FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, TextField, Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';

import { City } from '../../../../models/city';
import { State } from '../../../../models/state';
import { usePurchaseContext } from '../hooks/context-hook';

interface PropsPurchaseFormStepOne {
  cities: City[],
  states: State[],
}

function PurchaseFormStepOne(props: PropsPurchaseFormStepOne): ReactElement {
  const context = usePurchaseContext();
  const { formData } = context;
  const { cities, states } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          <b>Seu Endereço</b>
        </Typography>
      </Grid>
      <Grid item md={10}>
        <FormControl error={!!context.formError?.address} fullWidth>
          <InputLabel htmlFor="purchase-form-endereco">Endereço</InputLabel>
          <OutlinedInput
            id="purchase-form-endereco"
            label="Endereço"
            defaultValue={formData.address}
            onBlur={(event) => context.setFormData({ ...formData, address: event.target.value })}
          />
          <FormHelperText id="purchase-form-endereco">{context.formError?.address}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item md={2}>
        <FormControl error={!!context.formError?.number} fullWidth>
          <InputLabel htmlFor="purchase-form-numero">Número</InputLabel>
          <OutlinedInput
            id="purchase-form-numero"
            label="Número"
            defaultValue={formData.number}
            onBlur={(event) => context.setFormData({ ...formData, number: event.target.value })}
          />
          <FormHelperText id="purchase-form-numero">{context.formError?.number}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item md={6}>
        <FormControl fullWidth error={!!context.formError?.state}>
          <Autocomplete
            disablePortal
            id="combo-box-estado"
            renderInput={(params) => <TextField {...params} error={!!context.formError?.state} label="Estado" />}
            options={states}
            getOptionLabel={(option) => option.name}
            defaultValue={formData.state}
            onChange={(event, newValue) => context.setFormData({ ...formData, state: newValue as State })}
            fullWidth
          />
          <FormHelperText id="combo-box-estado">{context.formError?.state}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item md={6}>
        <FormControl fullWidth error={!!context.formError?.city}>
          <Autocomplete
            disablePortal
            defaultValue={formData.city}
            id="combo-box-city"
            renderInput={(params) => <TextField {...params} error={!!context.formError?.city} label="Cidade" />}
            options={cities}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => context.setFormData({ ...formData, city: newValue as City })}
            fullWidth
          />
          <FormHelperText id="combo-box-city">{context.formError?.city}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item md={12}>
        <FormControl error={!!context.formError?.cep} fullWidth>
          <InputLabel htmlFor="purchase-form-cep">Cep</InputLabel>
          <OutlinedInput
            id="purchase-form-cep"
            label="Cep"
            defaultValue={formData.cep}
            onBlur={(event) => context.setFormData({ ...formData, cep: event.target.value })}
          />
          <FormHelperText id="purchase-form-cep">{context.formError?.cep}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export {
  PurchaseFormStepOne,
};
