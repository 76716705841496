import React, { useRef } from 'react';

import {
  Content,
  BoxSchedule,
  Icon,
  ContainerCalendar,
  TextCalendar,
  Button,
  SpanIconClose,
} from './styles';
import { WEEKDAYS } from '../../../utils/constants';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { getWeekDays } from '../../../utils/getWeekDays';

function CaroselDates({
  width,
  list,
  queries = [],
  onRemoveQuery,
  onRemoveSchedule,
}) {
  const wrapperRef = useRef(null);

  const { t } = useTranslation();

  const handleRemove = list === 'true' ? onRemoveSchedule : onRemoveQuery;

  return (
    <ContainerCalendar width={width}>
      <Button
        list={list}
        calendar
        onClick={() => {
          wrapperRef.current.scrollTo({
            top: 0,
            left: wrapperRef.current.scrollLeft - 120,
            behavior: 'smooth',
          });
        }}
      >
        <span
          className="iconify"
          data-icon="dashicons:arrow-left-alt2"
          data-inline="false"
        />
      </Button>
      <Content ref={wrapperRef}>
        {queries.map((query, i) => (
          <BoxSchedule list={list}>
            <Icon>
              <span
                className="iconify"
                data-icon="bi:check-lg"
                data-inline="false"
              />
            </Icon>
            <button
              onClick={() => {
                handleRemove(i);
              }}
            >
              <SpanIconClose
                className="iconify"
                data-icon="eva:close-outline"
                data-inline="false"
              />
            </button>
            <TextCalendar top list={list}>
              {t('every')} {t(WEEKDAYS[query.weekday].label)} <br />
              {/* format(
                getWeekDays().find(({ weekday }) => weekday === query.weekday)
                  .date,
                'dd/MM/yyyy',
              ) */}
            </TextCalendar>
            <TextCalendar list={list}>
              <span
                className="iconify"
                data-icon="ic:outline-watch-later"
                data-inline="false"
              />
              {query.startTime}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
                data-inline="false"
              />
              {query.endTime}
            </TextCalendar>
          </BoxSchedule>
        ))}
      </Content>
      <Button
        list={list}
        calendar
        onClick={() => {
          wrapperRef.current.scrollTo({
            top: 0,
            left: 120 + wrapperRef.current.scrollLeft,
            behavior: 'smooth',
          });
        }}
      >
        <span
          className="iconify"
          data-icon="dashicons:arrow-right-alt2"
          data-inline="false"
        />
      </Button>
    </ContainerCalendar>
  );
}

export default CaroselDates;
