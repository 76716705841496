import styled from 'styled-components';

export const ContainerLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

export const SecondScreen = styled.div`
  position: absolute;
  /* background-color: #f9f9f9; */
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

export const Container = styled.div`
  display: flex;
  background: #f9f9f9;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }

  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 560px) {
    margin-bottom: 3rem;
  }
`;

export const Content = styled.div`
  padding: 0 1.2rem;
  flex: 1;

  @media (max-width: 768px) {
    padding: 0;
    order: 1;
  }

  @media (max-width: 560px) {
    padding: 0;
    order: 1;
  }
`;

export const Select = styled.select`
  width: 30rem;
  height: 3.8rem;
  padding: 0 1rem;
  border: 1px solid var(--gray3);
  color: #243342;
  margin-bottom: 1.2rem;
  border-radius: 0.5rem;

  option {
    color: #243342;
    background: #fff;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  height: 3.8rem;
  padding: 0 1rem;
  background: #243342;
  color: #fff;
  border-radius: 0.5rem;
`;

export const ContainerBanner = styled.div`
  padding: 0 0 0 1.2rem;
  display: flex;
  height: 12rem;
`;

export const BannerEbook = styled.img`
  flex: 1;
  max-width: 100%;
`;

export const BackgroundGreen = styled.div`
  margin: 0 0 1rem 1.2rem;
  padding: 0.7rem 2rem;
  background-color: #0f5c56;
  display: flex;
  align-items: center;

  @media (max-width: 560px) {
    margin-left: 0;
  }
`;

export const ImageProfile = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
`;

export const Info = styled.div`
  margin-left: 1.5rem;
  color: #fff;
`;

export const FullName = styled.p`
  font-size: 14px;
`;

export const Level = styled(FullName)`
  word-spacing: 3px;
  font-size: 12px;
`;
