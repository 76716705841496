import React, { createContext, useCallback, useContext, useRef, useState, } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Menu } from '../../components/Base/Menu';
import { Guideline } from './Guideline';
import { Profile } from './Profile';
import { Message } from './Message';
import { Settings } from './Settings';
import { Container, ContainerAside } from './styles';
import { Assignments } from './Assignments';
import { Certificate } from './Certificate';
import { PendingCertificates } from './PendingCertificates';

import { Ticket } from './Ticket';
import { Schedule } from './Schedule';
import { DashboardStudent } from './DashboardStudent/index';
import { useAuth } from '../../hooks/AuthProvider';
import { Mission } from './Mission';
import { StudentMission } from './StudentMission';
import { StudentClassroom } from './StudentClassRoom';

import { ReadBook } from './Guideline/ReadBook';
import { Homeworks } from './Homeworks';
import { Method } from './Method';
import { TeachingKnowledgeTest } from '../../pages/TeachingKnowledgeTest';
import { Methods } from './Methods';
import { Planning } from './Planning/index';
import { PurchasePlans } from './PurchasePeriodAlert/index';
import { PerfilType } from '../../enums/PerfilType';

const MenuContext = createContext();

const Dashboard = ({ match }) => {
  console.log('### RAPAZ DASHBOARD AEHAUEA ###');

  const menuRef = useRef({});
  const { user, loading, typeLogin } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const isTeacher = user.type === PerfilType.TEACHER;
  const isStudent = user.type === PerfilType.STUDENT;
  const isAfiliate = user.type === PerfilType.AFILIATE;

  const openMenu = useCallback(() => {
    menuRef.current.openMenu();
  }, []);

  const teacherPassedKnowledgeTest =
    user &&
    user.type === 'teacher' &&
    user.teachingKnowledgeTest.status === 'approved';

  const teacherPassedPlacementTest =
    user && user.type === 'teacher' && user.placementTest.status === 'approved';

  const redirectTo = !teacherPassedPlacementTest
    ? '/placement-test' 
    : !teacherPassedKnowledgeTest
    ? `${match.url}/teaching-knowledge-test`
    : `${match.url}/method`;

  function renderTeacherRoutes() {
    return <ContainerAside>
    {user.type === 'teacher' ? (
      <>
        <Route
          exact
          path={`${match.url}/`}
          render={() => <Redirect to={redirectTo} />}
        />
        {teacherPassedKnowledgeTest ? (
          <Route
            exact
            path={`${match.path}/method`}
            component={props => (
              <Method
                match={match}
                {...props}
                setShowMenu={setShowMenu}
                showMenu={showMenu}
              />
            )}
          />
        ) : (
          <Route
            exact
            path={`${match.path}/teaching-knowledge-test`}
            component={props => (
              <TeachingKnowledgeTest
                match={match}
                {...props}
                setShowMenu={setShowMenu}
                showMenu={showMenu}
              />
            )}
          />
        )}
      </>
    ) : (
      <>
        <Route
          exact
          path={`${match.url}/`}
          render={() => <Redirect to={`${match.url}/profile/info/classreports`} />}
        />
        <Route
          exact
          path={`${match.path}/profile/info/classreports`}
          component={props => (
            <DashboardStudent
              match={match}
              {...props}
              setShowMenu={setShowMenu}
              showMenu={showMenu}
            />
          )}
        />
      </>
    )}
    <Route
      path={`${match.path}/profile`}
      component={props => (
        <Profile
          match={match}
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
          typeLogin={typeLogin}
        />
      )}
    />
    {user && user.type === 'student' && (
      <Route
        path={`${match.path}/tt/method`}
        component={props => (
          <Methods
            {...props}
            setShowMenu={setShowMenu}
            showMenu={showMenu}
          />
        )}
      />
    )}
    <Route
      path={`${match.path}/messages`}
      component={props => (
        <Message
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      )}
    />
    <Route
      exact
      path={`${match.path}/guidelines`}
      component={props => (
        <Guideline
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      )}
    />
    <Route
      path={`${match.path}/guidelines/readbook`}
      component={props => (
        <ReadBook
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      )}
    />
    <Route
      path={`${match.path}/assignments`}
      component={props => (
        <Assignments
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      )}
    />
    <Route
      path={`${match.path}/settings`}
      component={props => (
        <Settings
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      )}
    />
    <Route
      path={`${match.path}/homework`}
      component={props => (
        <Homeworks
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      )}
    />
    {user && user.type === 'student' && (
      <Route
        path={`${match.path}/certificate`}
        component={props => (
          <Certificate
            {...props}
            setShowMenu={setShowMenu}
            showMenu={showMenu}
          />
        )}
      />
    )}
    {user && user.type === 'teacher' && (
      <Route
        path={`${match.path}/pending-certificates`}
        component={props => (
          <PendingCertificates
            {...props}
            setShowMenu={setShowMenu}
            showMenu={showMenu}
          />
        )}
      />
    )}

    <Route
      path={`${match.path}/schedule`}
      component={props => (
        <Schedule
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      )}
    />
    <Route
      path={`${match.path}/help`}
      component={props => (
        <Ticket
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      )}
    />
    <Route
      path={`${match.path}/method/mission`}
      component={props => (
        <Mission
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      )}
    />
    <Route
      path={`${match.path}/student/method/mission`}
      component={props => (
        <StudentMission
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      )}
    />
    <Route
      path={`${match.path}/student/classroom`}
      component={props => (
        <StudentClassroom
          {...props}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      )}
    />
    <Route
      path={`${match.path}/method/planning`}
      component={props => <Planning {...props} />}
    />
  </ContainerAside>
  };

  function renderStudentProfile() {
    return(
      <ContainerAside>
      {user.plan ?
          (
            <>
              <Route
                exact
                path={`${match.url}/`}
                render={() => <Redirect to={`${match.url}/method`} />}
              />
              <Route
                exact
                path={`${match.path}/method`}
                component={props => (
                  <DashboardStudent
                    match={match}
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}/>
                <Route
                  path={`${match.path}/profile`}
                  component={props => (
                    <Profile
                      match={match}
                      {...props}
                      setShowMenu={setShowMenu}
                      showMenu={showMenu}
                      typeLogin={typeLogin}
                    />
                  )}
                />
              <Route
                path={`${match.path}/tt/method`}
                component={props => (
                  <Methods
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/messages`}
                component={props => (
                  <Message
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                exact
                path={`${match.path}/guidelines`}
                component={props => (
                  <Guideline
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/guidelines/readbook`}
                component={props => (
                  <ReadBook
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/assignments`}
                component={props => (
                  <Assignments
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/settings`}
                component={props => (
                  <Settings
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/homework`}
                component={props => (
                  <Homeworks
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/certificate`}
                component={props => (
                  <Certificate
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/schedule`}
                component={props => (
                  <Schedule
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/help`}
                component={props => (
                  <Ticket
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/method/mission`}
                component={props => (
                  <Mission
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/student/method/mission`}
                component={props => (
                  <StudentMission
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/student/classroom`}
                component={props => (
                  <StudentClassroom
                    {...props}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                  />
                )}
              />
              <Route
                path={`${match.path}/method/planning`}
                component={props => <Planning {...props} />}
              />
            </>          
          ) : (
          <>
            <Route
              exact
              path={`${match.url}`}
              render={() => (
                <Redirect to={`${match.url}/student/purchase-period`} />
              )}
            />
            <Route
              exact
              path={`${match.url}/method`}
              render={() => (
                <Redirect to={`${match.url}/student/purchase-period`} />
              )}
            />
            <Route
              path={`${match.path}/student/purchase-period`}
              component={props => (
                <PurchasePlans
                  {...props}
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              )}
            />        
          </>
        )}
          
          
      </ContainerAside>
    );
  };

  return (
    <Container>
      <Menu
        ref={menuRef}
        match={match}
        setShowMenu={setShowMenu}
        showMenu={showMenu}
      />

      <MenuContext.Provider value={{ openMenu, showMenu, setShowMenu }}>
        {!loading && (isTeacher || isAfiliate ? renderTeacherRoutes() : renderStudentProfile())}
      </MenuContext.Provider>
    </Container>
  );
};

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) throw new Error('useMenu must be used within an MenuProvider.');
  return context;
};

export default Dashboard;
