import React, { Fragment } from 'react';

import { Container, Error } from './styles';

function PhoneNumberInput({ error, ...rest }) {
  return (
    <Fragment>
      <Container withError={error} country="br" {...rest} />
      {!!error && <Error>{error}</Error>}
    </Fragment>
  );
}

PhoneNumberInput.defaultProps = {
  error: false,
};

export default PhoneNumberInput;
