/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

import { checkIsVideo } from '../../../helpers/url';
import { api } from '../../../services/api';
import {
  ButtonCheckLesson,
  ButtonModalContinue,
  ShowHideButton,
  ContainerButtonCancel,
  ButtonModalCancel,
  ButtonsModal,
  ContainerButtonsModal,
  ContainerHeaderModal,
  ContainerModalContinue,
  ContainerModalWrapper,
  ContainerText,
  ContainerTextsUserModal,
  ContainerUserModal,
  ImageProfile,
  ImageExercise,
  ContainerImage,
  ContainerShowHide,
  Text,
  TextModal,
  TextModal2,
  WrapperModal,
  TextArea,
  Video,
} from './styles.ListCorrections';

interface IMission {
  title: string;
}

interface IStudent {
  avatarURL: string;
  fullName: string;
  name: string;
  studentLevel: string;
}

interface IQuestionsAnswers {
  _id: string;
  question: string;
  answer: string;
  isCorrected: boolean;
  isCorrectAnswer: number;
  obs: string;
}

interface ICorrections {
  _id: string;
  title: string;
  mission: IMission;
  student: IStudent;
  exam: any;
  quiz: any;
  exerciseDate: Date;
  totalCorrected: number;
  totalNotVerificated: number;
  answers: IQuestionsAnswers[];
}

interface ICorrectionsProps {
  correction: ICorrections | any | undefined;
  modalIsOpen: boolean;
  viewMode: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const ListCorrectionModal = ({
  viewMode,
  correction,
  modalIsOpen,
  setIsOpen,
}: ICorrectionsProps) => {
  const [currentPosition, setCurrentPosition] = useState(1);
  const [showImage, setShowImage] = useState(false);
  const [correctedAnswers, setCorrectedAnswers] = useState<any[]>();
  function closeModal() {
    setIsOpen(false);
  }
  function validateCurrentAnswer() {
    if (!correctedAnswers) return false;
    if (correctedAnswers[currentPosition - 1].isCorrectAnswer === 2) {
      toast('Check if the answer is right or wrong', { type: 'error' });
      return false;
    }
    if (
      correctedAnswers[currentPosition - 1].isCorrectAnswer === 0
      && !correctedAnswers[currentPosition - 1].obs
    ) {
      toast('Leave a note to your student.', { type: 'error' });
      return false;
    }
    return true;
  }
  async function finishAnswers() {
    if (!correction) {
      return false;
    }
    if (!viewMode) {
      if (!validateCurrentAnswer()) {
        return false;
      }
      await api.put(`/homework/${correction._id}/correct`, {
        answers: correctedAnswers,
      });
    }
    closeModal();
    return false;
  }
  function goBack() {
    setCurrentPosition(currentPosition - 1);
  }
  async function goNext() {
    if (!correction) {
      return false;
    }
    if (!viewMode) {
      if (!validateCurrentAnswer()) {
        return false;
      }
      await api.put(`/homework/${correction._id}/correct`, {
        answers: correctedAnswers,
      });
    }
    setCurrentPosition(currentPosition + 1);
    return false;
  }
  function handleAnswer(index: number, isCorrectAnswer: number) {
    if (viewMode) {
      return false;
    }
    const tempArray = JSON.parse(JSON.stringify(correctedAnswers));
    tempArray[index].isCorrectAnswer = isCorrectAnswer;
    setCorrectedAnswers(tempArray);
    return false;
  }
  function handleObsChange(index: number, text: string) {
    if (viewMode) {
      return false;
    }
    const tempArray = JSON.parse(JSON.stringify(correctedAnswers));
    tempArray[index].obs = text;
    setCorrectedAnswers(tempArray);
    return false;
  }
  function getFileUrl(index: number) {
    const exam1 = correction.exam && Array.isArray(correction.exam)
      ? correction.exam[0]
      : correction.exam;
    const quiz1 = correction.quiz && Array.isArray(correction.quiz)
      ? correction.quiz[0]
      : correction.quiz;
    if (
      exam1
      && exam1.writtenQuestions
      && exam1.writtenQuestions.length > 0
      && exam1?.writtenQuestions[index]?.fileURL
    ) {
      return exam1.writtenQuestions[index].fileURL;
    }
    if (
      exam1
      && exam1.multipleChoiceQuestions
      && exam1.multipleChoiceQuestions.length > 0
      && exam1.multipleChoiceQuestions[index]
    ) {
      return exam1.multipleChoiceQuestions[index].fileURL;
    }
    if (
      quiz1
      && quiz1.writtenQuestions
      && quiz1.writtenQuestions.length > 0
      && quiz1.writtenQuestions[index].fileURL
    ) {
      return quiz1.writtenQuestions[index].fileURL;
    }
    if (
      quiz1
      && quiz1.multipleChoiceQuestions
      && quiz1.multipleChoiceQuestions.length > 0
      && quiz1.multipleChoiceQuestions[index]
    ) {
      return quiz1.multipleChoiceQuestions[index].fileURL;
    }
    return null;
  }

  useEffect(() => {
    const tempArray = [];
    if (correction && correction.answers) {
      for (let i = 0; i < correction.answers.length; i += 1) {
        if (correction.answers[i].isCorrectAnswer === 2) {
          setCurrentPosition(i + 1);
          break;
        }
      }
      for (let i = 0; i < correction.answers.length; i += 1) {
        tempArray.push({
          isCorrectAnswer:
            correction.answers[i].isCorrectAnswer !== undefined
            && correction.answers[i].isCorrectAnswer !== null
              ? correction.answers[i].isCorrectAnswer
              : 2,
          obs: correction.answers[i].obs ? correction.answers[i].obs : '',
        });
      }
    }
    setCorrectedAnswers(tempArray);
  }, []);

  const { t } = useTranslation();

  return (
    <Modal
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
    >
      <ContainerModalContinue>
        <ContainerHeaderModal>
          <TextModal headerModal>{t('checkHomework')}</TextModal>

          {correction.student && correction.student.studentLevel && (
            <>
              <ContainerUserModal>
                <ImageProfile src={correction?.student.avatarURL} />
                <ContainerTextsUserModal>
                  <Text>
                    {correction?.student.fullName ?? correction?.student.name}
                  </Text>
                  <Text>{correction?.student.studentLevel}</Text>
                </ContainerTextsUserModal>
              </ContainerUserModal>
              <TextModal headerModal>
                {correction?.student.studentLevel}
              </TextModal>
            </>
          )}
          <ContainerButtonsModal>
            <ButtonsModal disabled={currentPosition === 1} onClick={goBack}>
              <span className="iconify" data-icon="dashicons:arrow-left-alt2" />
            </ButtonsModal>
            <TextModal>
              {currentPosition}
              /
              {correction?.answers?.length}
            </TextModal>
            <ButtonsModal
              onClick={goNext}
              disabled={
                correction && currentPosition === correction.answers.length
              }
              right
            >
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
              />
            </ButtonsModal>
          </ContainerButtonsModal>
        </ContainerHeaderModal>
        <WrapperModal>
          <ContainerModalWrapper>
            {correction
              && correction?.answers
              && correction?.answers.map(
                (item: any, index: number) => index + 1 === currentPosition && (
                <>
                  {getFileUrl(index) && (
                  <div>
                    {!showImage && (
                    <ContainerShowHide>
                      <ShowHideButton
                        onClick={() => {
                          setShowImage(true);
                        }}
                      >
                        Show Image / Video
                      </ShowHideButton>
                    </ContainerShowHide>
                    )}
                    {showImage && (
                    <>
                      <ContainerShowHide>
                        <ShowHideButton
                          onClick={() => {
                            setShowImage(false);
                          }}
                        >
                          Hide Image / Video
                        </ShowHideButton>
                      </ContainerShowHide>
                      {checkIsVideo(getFileUrl(index)) && (
                      <ContainerImage>
                        <Video src={getFileUrl(index)} controls />
                      </ContainerImage>
                      )}
                      {!checkIsVideo(getFileUrl(index)) && (
                      <ContainerImage>
                        <ImageExercise src={getFileUrl(index)} />
                      </ContainerImage>
                      )}
                    </>
                    )}
                  </div>
                  )}
                  <ContainerText>
                    <TextModal>{item.question}</TextModal>
                  </ContainerText>
                  <ContainerText>
                    <TextModal2>{item.answer}</TextModal2>
                    {correctedAnswers
                          && correctedAnswers.length - 1 >= index && (
                            <ContainerButtonsModal>
                              {correctedAnswers[index].isCorrectAnswer
                                === 1 && (
                                <ButtonCheckLesson
                                  check2
                                  viewMode={viewMode}
                                  onClick={() => {
                                    handleAnswer(index, 1);
                                  }}
                                >
                                  <span
                                    className="iconify"
                                    data-icon="akar-icons:check"
                                  />
                                </ButtonCheckLesson>
                              )}
                              {!viewMode
                                && correctedAnswers[index].isCorrectAnswer
                                  !== 1 && (
                                  <ButtonCheckLesson
                                    check1
                                    viewMode={viewMode}
                                    onClick={() => {
                                      handleAnswer(index, 1);
                                    }}
                                  >
                                    <span
                                      className="iconify"
                                      data-icon="akar-icons:check"
                                    />
                                  </ButtonCheckLesson>
                              )}
                              {correctedAnswers[index].isCorrectAnswer
                                === 0 && (
                                <ButtonCheckLesson
                                  close2
                                  viewMode={viewMode}
                                  onClick={() => {
                                    handleAnswer(index, 0);
                                  }}
                                >
                                  <span
                                    className="iconify"
                                    data-icon="eva:close-fill"
                                  />
                                </ButtonCheckLesson>
                              )}
                              {!viewMode
                                && correctedAnswers[index].isCorrectAnswer
                                  !== 0 && (
                                  <ButtonCheckLesson
                                    close1
                                    viewMode={viewMode}
                                    onClick={() => {
                                      handleAnswer(index, 0);
                                    }}
                                  >
                                    <span
                                      className="iconify"
                                      data-icon="eva:close-fill"
                                    />
                                  </ButtonCheckLesson>
                              )}
                              {viewMode
                                && correctedAnswers[index].isCorrectAnswer !== 0
                                && correctedAnswers[index].isCorrectAnswer
                                  !== 1 && (
                                  <ButtonCheckLesson warning viewMode>
                                    ?
                                  </ButtonCheckLesson>
                              )}
                            </ContainerButtonsModal>
                    )}
                  </ContainerText>
                  {!item.isCorrectAnswer && correction.type !== 'open' && (
                  <ContainerText>
                    <TextModal style={{ color: 'red' }}>
                      Correct Answer:
                      {' '}
                      {item.correction}
                    </TextModal>
                  </ContainerText>
                  )}
                  {correctedAnswers
                        && correctedAnswers.length - 1 >= index
                        && ((viewMode && correctedAnswers[index].obs)
                          || !viewMode) && (
                          <ContainerText>
                            <TextArea
                              placeholder="Add a comment"
                              // placeholder="Teacher observation"
                              value={correctedAnswers[index].obs}
                              disabled={viewMode}
                              onChange={(event) => {
                                handleObsChange(index, event.target.value);
                              }}
                            />
                          </ContainerText>
                  )}
                </>
                ),
              )}
          </ContainerModalWrapper>
          {correction
            && !viewMode
            && currentPosition < correction.answers.length && (
              <ContainerButtonCancel>
                <ButtonModalCancel onClick={closeModal}>
                  <span className="iconify" data-icon="gg:coffee" />
                  {t('finishLater')}
                </ButtonModalCancel>
              </ContainerButtonCancel>
          )}
          {correction
            && !viewMode
            && currentPosition === correction.answers.length && (
              <ButtonModalContinue onClick={finishAnswers}>
                <span className="iconify" data-icon="gg:check" />
                {t('finish')}
              </ButtonModalContinue>
          )}
          {viewMode && (
            <ButtonModalContinue onClick={closeModal}>
              {t('fechar')}
            </ButtonModalContinue>
          )}
        </WrapperModal>
      </ContainerModalContinue>
    </Modal>
  );
};
