import React from 'react';
import { Span } from '../../components/Span';
import { Button } from '../../components/Button';

import {
  Container,
  Column2,
  ScrollForm,
  BaseDiv,
  Column2Form,
  BasePlans,
  UniquePlans,
  UniquePlansCenter,
  TitleUniquePlans,
  ValueUniquePlans,
  TextUniquePlans,
  ButtonUniquePlans,
  TopUniquePlansCenter,
  TopYellowUniquePlansCenter,
  TitleUniquePlansCenter,
  ValueUniquePlansCenter,
  TextUniquePlansCenter,
  ButtonUniquePlansCenter,
  DescriptionsUniquePlans,
} from './styles';

export function Plans() {
  return (
    <Container>
      <Column2>
        <ScrollForm>
          <Column2Form>
            <BaseDiv>
              <Span>Escolha seu destino</Span>
            </BaseDiv>
            <BasePlans>
              <UniquePlans>
                <TitleUniquePlans>
                  <Span>Yes, A can!</Span>
                </TitleUniquePlans>
                <ValueUniquePlans>
                  <Span>R$ 1.917,60</Span>
                </ValueUniquePlans>
                <TextUniquePlans>
                  <Span>O preço por usuário.</Span>
                  <p>Altere seu plano a qualquer momento</p>
                </TextUniquePlans>
                <ButtonUniquePlans>
                  <Button color="#fff" title="Selecione o plano" />
                </ButtonUniquePlans>
                <DescriptionsUniquePlans>
                  <ul>
                    <li>96 aulas</li>
                    <li>
                      Recomendamos 2 aulas por semana para concluir em 6 meses
                    </li>
                    <li>Acesso ao método</li>
                    <li>Acesso ao conteúdo extra</li>
                    <li>Games Online</li>
                    <li>Chat com professor</li>
                    <li>Suporte por chat e ticket</li>
                  </ul>
                </DescriptionsUniquePlans>
              </UniquePlans>

              <UniquePlansCenter>
                <TopUniquePlansCenter>
                  <TopYellowUniquePlansCenter>
                    <Span>Recomendado</Span>
                  </TopYellowUniquePlansCenter>
                </TopUniquePlansCenter>
                <TitleUniquePlansCenter>
                  <Span>Yes, A can!</Span>
                </TitleUniquePlansCenter>
                <ValueUniquePlansCenter>
                  <Span>R$ 1.917,60</Span>
                </ValueUniquePlansCenter>
                <TextUniquePlansCenter>
                  <Span>O preço por usuário.</Span>
                  <p>Altere seu plano a qualquer momento</p>
                </TextUniquePlansCenter>
                <ButtonUniquePlansCenter>
                  <Button color="#197364" title="Selecione o plano" />
                </ButtonUniquePlansCenter>
                <DescriptionsUniquePlans>
                  <ul>
                    <li>96 aulas</li>
                    <li>
                      Recomendamos 2 aulas por semana para concluir em 6 meses
                    </li>
                    <li>Acesso ao método</li>
                    <li>Acesso ao conteúdo extra</li>
                    <li>Games Online</li>
                    <li>Chat com professor</li>
                    <li>Suporte por chat e ticket</li>
                  </ul>
                </DescriptionsUniquePlans>
              </UniquePlansCenter>
              <UniquePlans>
                <TitleUniquePlans>
                  <Span>Yes, A can!</Span>
                </TitleUniquePlans>
                <ValueUniquePlans>
                  <Span>R$ 1.917,60</Span>
                </ValueUniquePlans>
                <TextUniquePlans>
                  <Span>O preço por usuário.</Span>
                  <p>Altere seu plano a qualquer momento</p>
                </TextUniquePlans>
                <ButtonUniquePlans>
                  <Button color="#fff" title="Selecione o plano" />
                </ButtonUniquePlans>
                <DescriptionsUniquePlans>
                  <ul>
                    <li>96 aulas</li>
                    <li>
                      Recomendamos 2 aulas por semana para concluir em 6 meses
                    </li>
                    <li>Acesso ao método</li>
                    <li>Acesso ao conteúdo extra</li>
                    <li>Games Online</li>
                    <li>Chat com professor</li>
                    <li>Suporte por chat e ticket</li>
                  </ul>
                </DescriptionsUniquePlans>
              </UniquePlans>
            </BasePlans>
          </Column2Form>
        </ScrollForm>
      </Column2>
    </Container>
  );
}
