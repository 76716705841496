/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';

import {
  ContainerAddNotes,
  ContainerHeader,
  TextsHeaderContainer,
  TextModal,
  ButtonModal,
  ContainerModal,
  ButtonSave,
  Select,
} from './styles';
import Input from '../../Form/Input';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const AddNotes = ({ open, setOpen }) => {
  const closed = () => {
    setOpen(false);
  };
  const [methods, setMethods] = useState();
  const [missions, setMissions] = useState();
  const [selectionMission, setSelectionMission] = useState();
  const [selectionMethod, setSelectionMethod] = useState();
  const [type, setType] = useState();
  const [description, setDescription] = useState();
  const { t } = useTranslation();

  async function fetchMethods() {
    const { data } = await api.get('/methods');
    setMethods(data.docs);
  }

  async function fetchMissions() {
    const { data } = await api.get('/missions?type=class');
    setMissions(data.docs);
  }

  useEffect(() => {
    fetchMissions();
    fetchMethods();
  }, []);

  const handleSave = async () => {
    try {
      await api.post('/notes', {
        type,
        description,
        mission: selectionMission,
        method: selectionMethod,
      });

      toast(t('yourNotesHasBeenSuccessfully'), { type: 'success' });
      closed();
    } catch (err) {
      toast('Error', { type: 'error' });
    }
  };

  return (
    <ContainerAddNotes>
      <Modal
        isOpen={open}
        contentLabel="Modal correction method"
        onRequestClose={closed}
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <ContainerModal>
          <header>
            <ContainerHeader>
              <TextsHeaderContainer>
                <TextModal>{t('titulo')}</TextModal>
                <Input
                  name="type"
                  type="text"
                  placeholder={'Add title here'}
                  onChange={({ target }) => setType(target.value)}
                />
                <TextModal>{t('metodo')}</TextModal>
                <Select
                  onChange={({ target }) => setSelectionMethod(target.value)}
                >
                  <option> </option>
                  {methods?.map((method, index) =>
                    method.title != undefined ? (
                      <option value={method.id}>{method.title}</option>
                    ) : (
                      ''
                    ),
                  )}
                </Select>
                <TextModal>{t('mission')}:</TextModal>
                <Select
                  onChange={({ target }) => setSelectionMission(target.value)}
                >
                  <option> </option>
                  {missions?.map((mission, index) =>
                    mission.title != undefined &&
                    selectionMethod &&
                    mission.method &&
                    mission.method._id == selectionMethod ? (
                      <option value={mission.id}>{mission.title}</option>
                    ) : (
                      ''
                    ),
                  )}
                </Select>
                <TextModal>{t('notes')}:</TextModal>
                <Input
                  name="type"
                  type="text"
                  placeholder={'Add a note here'}
                  onChange={({ target }) => setDescription(target.value)}
                  isTextArea={true}
                />
              </TextsHeaderContainer>
            </ContainerHeader>
          </header>
          <ButtonSave onClick={() => handleSave()}>{t('salvar')}</ButtonSave>
          <ButtonModal onClick={closed}>{t('fechar')}</ButtonModal>
        </ContainerModal>
      </Modal>
    </ContainerAddNotes>
  );
};
