import styled, { css } from 'styled-components';
import Modal from 'react-modal';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  height: 80rem;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.3rem;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #083059;
    border-radius: 1rem;
    height: 0.6rem;
    max-height: 0.6rem;
  }
  ::selection {
    background-color: #083059;
  }
`;

export const ContainerList = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 1rem 0 1rem 0;
  border-bottom: 1px solid #ebe9f1;

  .container-profile {
    display: flex;
    justify-content: flex-start;
  }
`;

export const Image = styled.img`
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

export const ContainerDetails = styled.div`
  display: flex;
`;

export const Button = styled.button`
  border-radius: 0.5rem;
  border: 1px solid #ebe9f1;
  color: #083059;
  height: 3.5rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  .iconify {
    margin-right: 0.5rem;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32.51px;
  height: 32.51px;
  border-radius: 50%;
  background: #c6fae0;
  color: #083059;
  font-size: 1.6rem;
  margin-right: 0.5rem;

  @media (max-width: 468px) {
    display: none;
  }
`;

export const TextDetails = styled.span`
  font-size: 1.4rem;
  color: #b9b9c3;

  ${props =>
    props.title &&
    css`
      font-size: 0.8rem;
    `}

  ${props =>
    props.typeLogin &&
    css`
      color: #083059;
      font-size: 1.7rem;
      font-weight: bold;

      span {
        font-weight: 500;
      }
    `}
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span + span {
    font-weight: 500;
  }
`;

export const ModalClass = styled(Modal)`
  width: 80%;
`;

export const ContainerModal = styled.div`
  background: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  width: 100%;

  textarea {
    width: 60rem;
    height: 20rem;
    border: 1px solid var(--gray3);
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0.5rem 0;
    outline: none;
    resize: none;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  form {
    padding: 0 0 1rem 1rem;
    display: flex;
    flex-direction: column;
  }

  .container-img {
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .container-imgs-obs {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-gap: 1rem;
    padding-right: 1rem;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .container-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 6rem;
      border: 1px solid var(--gray3);
      border-radius: 0.5rem;
      padding: 0 6rem;
      font-size: 2rem;
      color: #243342;
      cursor: pointer;

      :hover {
        filter: brightness(0.8);
      }
    }
  }

  .button {
    width: 100%;
    height: 6rem;
    background: #243342;
    color: #fff;
    margin-top: 1rem;
    border-radius: 0.5rem;
    font-size: 2rem;
    cursor: pointer;

    :hover {
      filter: brightness(0.8);
    }
  }
`;

export const Text = styled.span`
  color: #5e5873;
  font-size: 2rem;

  ${props =>
    props.title &&
    css`
      width: 100%;
      border-bottom: 1px solid var(--gray3);
      text-align: center;
      padding-bottom: 2rem;
      margin-bottom: 1rem;
    `}

  ${props =>
    props.text &&
    css`
      /* width: 100%; */
    `}
`;
