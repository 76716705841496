import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../../hooks/AuthProvider';
import {
  Text, Wrapper, ContainerButton, ButtonPass,
} from './styles';

interface DoneProps {
  onRedo: () => void;
}

export const Done = ({ onRedo }: DoneProps) => {
  const history = useHistory();

  const { user } = useAuth();

  const { status } = user.teachingKnowledgeTest || {};

  return (
    <Wrapper>
      <Text warning>
        {status === 'approved'
          ? 'Congratulations you passed the test'
          : 'unfortunately you didn\'t pass the test'}
      </Text>

      <ContainerButton>
        {status === 'approved' ? (
          <ButtonPass
            next
            onClick={() => {
              history.push('/dashboard/method');
            }}
          >
            Proceed
          </ButtonPass>
        ) : (
          <ButtonPass next onClick={onRedo}>
            Retake test
          </ButtonPass>
        )}
      </ContainerButton>
    </Wrapper>
  );
};
