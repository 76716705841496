import styled from 'styled-components';
import BackgroundWhite from '../../assets/background_login_white.png';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-content: center;
  overflow-y: auto;
`;

export const Column2 = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundWhite});
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ScrollForm = styled.div`
  padding: 5rem 0;
  width: 60%;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Column2Form = styled.div`
  width: 75%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
`;

export const BaseDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #243342;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    color: #15635e;
  }
`;

export const BasePlans = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const UniquePlans = styled.div`
  width: 35rem;
  margin: 1rem;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  border: 1px solid grey;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
  border: 1px solid #085b5525;
`;

export const UniquePlansCenter = styled.div`
  width: 35rem;
  margin: 1rem;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  border: 1px solid grey;
  border-radius: 5px;
  background-color: #197364;
  border: 1px solid #085b5525;
`;

export const TitleUniquePlans = styled.div`
  margin-top: 4rem;
  height: 10%;
  Span {
    align-items: center;
    font-size: 20px;
    color: #15635e;
  }
`;

export const ValueUniquePlans = styled.div`
  height: 10%;

  Span {
    align-items: center;
    font-size: 26px;
    color: #15635e;
  }
`;

export const TextUniquePlans = styled.div`
  height: 10%;

  Span {
    align-items: center;
    font-size: 12px;
    color: #c5c5c5;
  }

  p {
    align-items: center;
    font-size: 12px;
    color: #c5c5c5;
  }
`;

export const ButtonUniquePlans = styled.div`
  margin-top: 1.5rem;

  Button {
    width: 75%;
    height: 3rem;
    border: 1px solid black;
    color: #15635e;
  }

  Button:hover {
    background-color: #15635e;
    color: white;
  }
`;

export const DescriptionsUniquePlans = styled.div`
  margin-top: 2rem;
  padding-top: 2rem;
  background-color: white;
  padding-bottom: 2rem;
  li {
    font-size: 14px;
    color: #c5c5c5;
    font-weight: 500;
    margin-top: 10px;
    list-style-type: none;
  }
`;

export const TitleUniquePlansCenter = styled.div`
  margin-top: 4rem;
  height: 10%;
  Span {
    align-items: center;
    font-size: 20px;
    color: white;
  }
`;

export const ValueUniquePlansCenter = styled.div`
  height: 10%;

  Span {
    align-items: center;
    font-size: 26px;
    color: white;
  }
`;

export const TextUniquePlansCenter = styled.div`
  height: 10%;

  Span {
    align-items: center;
    font-size: 12px;
    color: white;
  }

  p {
    align-items: center;
    font-size: 12px;
    color: white;
  }
`;

export const ButtonUniquePlansCenter = styled.div`
  margin-top: 1.5rem;

  Button {
    width: 75%;
    height: 3rem;
    border: 1px solid white;
    color: #15635e;
    color: white;
  }

  Button:hover {
    background-color: white;
    color: #15635e;
  }
`;

export const TopUniquePlansCenter = styled.div`
  height: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopYellowUniquePlansCenter = styled.div`
  width: 20rem;
  height: 3rem;
  background-color: #dbad00;
  border-radius: 2vh;
  position: absolute;

  Span {
    color: #15635e;
    font-size: 20px;
  }
`;
