import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 35rem;
  background: #fff;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  position: absolute;
  right: 0;
  top: 4rem;

  display: flex;
  flex-direction: column;
`;

export const ContainerUser = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2rem;
  width: 100%;
  height: 8rem;
  border-bottom: 1px solid var(--gray3);
  position: relative;
  cursor: pointer;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const Image = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
`;

export const Text = styled.span`
  font-size: 1.6rem;
  color: #6e6b7b;
  font-weight: 600;

  ${props =>
    props.subtitle &&
    css`
      font-size: 1.4rem;
      color: #b9b9c3;
      font-weight: 500;
    `}

  ${props =>
    props.mail &&
    css`
      font-size: 1.2rem;
      color: #b9b9c3;
      font-weight: 500;
    `}
`;

export const BadgeStatus = styled.span`
  height: 1.4rem;
  width: 1.4rem;
  background: #28c76f;
  border-radius: 50%;
  position: absolute;
  border: 2px solid #fff;
  left: 5.5rem;
  top: 5rem;
`;

export const ContainerLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const Links = styled.div`
  font-size: 1.5rem;
  color: #6e6b7b;
  font-weight: normal;
  text-decoration: none;
  padding: 1rem 2rem;
  border-bottom: 1px solid var(--gray3);
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  ${props =>
    props.bottom &&
    css`
      border: 0;
    `}
`;
