import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 768px) {
    display: block;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const Col25 = styled.div`
  width: 25%;
  padding: 1rem;
  text-align: left;

  @media (max-width: 900px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WrapperForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span {
    font-size: 1.1rem;
  }

  button {
    margin-top: 2rem;
  }
`;

export const ContainerTip = styled.div`
  width: 100%;

  span {
    display: block;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 500;
  }
`;
