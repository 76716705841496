/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import Schedule from '../../../assets/svgMethod/icon_schedule_green.svg';
import StudentImage from '../../../assets/svgMethod/icon_student_green.svg';
import { ListAnswer } from '../../../components/Modals/ListAnswer';
import { api } from '../../../services/api';
import { ListCorrectionModal } from '../Method/ListCorrectionModal';
import { Answer, Homework } from './ListAssigments.Item';
import {
  Button,
  Container,
  ContainerButtons,
  HeaderContainer,
  Icon,
  IconList,
  Image,
  ListItem,
  Text,
} from './pendants.styles';
import './styles.css';

Modal.setAppElement('#root');

interface SetProps {
  data: Homework[];
  fetchAssignments: () => void;
  setSelectItem: Dispatch<SetStateAction<string>>;
}

export const PendantsItems = ({
  data,
  fetchAssignments,
  setSelectItem,
}: SetProps) => {
  const { t } = useTranslation();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [isListAnswersOpen, setIsListAnswersOpen] = useState(false);

  const [homeworkToCorrect, setHomeworkToCorrect] = useState<any>();

  const handleCloseCorrectionModal = (openVar: boolean) => {
    setIsOpen(false);
    fetchAssignments();
  };
  /*
    <IconList>
      <Image src={Schedule} />
    </IconList>
    <Text right>11/03/2020</Text>
  */

  console.log('data', data);
  return (
    <>
      <Container>
        <HeaderContainer>
          <Text>
            {data.length > 0
              ? t('pendingHomework')
              : 'There is no pending homework.'}
          </Text>
          <Icon onClick={() => setSelectItem('0')}>
            <span className="iconify" data-icon="eva:close-fill" />
          </Icon>
        </HeaderContainer>

        {data.map((item) => (
          <ListItem>
            <IconList>
              <Image src={StudentImage} />
            </IconList>
            <Text>
              {item.method.title}
              :
              {item.mission.title}
            </Text>
            <ContainerButtons>
              <Button
                onClick={() => {
                  setHomeworkToCorrect(item);
                  setIsListAnswersOpen(true);
                }}
              >
                {t('view')}
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-right-alt2"
                />
              </Button>
              <Button
                onClick={() => {
                  setIsOpen(true);
                  setHomeworkToCorrect(item);
                }}
              >
                {t('toCorrect')}
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-right-alt2"
                />
              </Button>
              {/* <Button>
                Continuar
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-right-alt2"
                />
              </Button> */}
            </ContainerButtons>
          </ListItem>
        ))}
      </Container>
      {homeworkToCorrect && modalIsOpen && (
        <ListCorrectionModal
          viewMode={false}
          correction={homeworkToCorrect}
          modalIsOpen={modalIsOpen}
          setIsOpen={handleCloseCorrectionModal}
        />
      )}
      {homeworkToCorrect && isListAnswersOpen && (
        <ListAnswer
          isOpen={isListAnswersOpen}
          homework={homeworkToCorrect}
          onRequestClose={() => {
            setHomeworkToCorrect(undefined);
            setIsListAnswersOpen(false);
          }}
        />
      )}
    </>
  );
};
