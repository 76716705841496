import styled, { css } from 'styled-components';

interface ButtonPassProps {
  next?: boolean;
  disabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    font-size: 1.8rem;
    color: #5e5873;
    font-weight: 600;
    margin: 16px 0;
  }
`;

export const ContainerPDFControls = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
`;

export const ButtonPass = styled.button<ButtonPassProps>`
  height: 5rem;
  width: 15rem;
  border-radius: 0.4rem;
  background: #ebe9f1;
  color: #243342;
  margin-right: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) => props.disabled
    && css`
      background: ${props.disabled ? '#cccccc' : '#ebe9f1'};
      color: #fff;
    `}

  ${(props) => props.next
    && css`
      background: ${props.disabled ? '#cccccc' : '#243342'};
      color: #fff;
    `}
`;
