import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from '../../../services/api';
import { Question } from '../../../types/registerTest';
import { MultipleChoiceAnswer } from '../index';
import {
  ButtonQuestions,
  ContainerButtonsTop,
  ContainerQuestions,
  ContainerTop,
  Text,
  Wrapper,
} from './styles';

interface MultipleChoicesProps {
  answers: MultipleChoiceAnswer[];
  onSubmit: () => void;
  onMultipleChoiceAnswerChange: (answer: MultipleChoiceAnswer) => void;
}

const MultipleChoices = ({
  answers,
  onMultipleChoiceAnswerChange,
  onSubmit,
}: MultipleChoicesProps) => {
  const [activePage, setActivePage] = useState(0);
  const { t } = useTranslation();
  const [questions, setQuestions] = useState<Question[]>();

  useEffect(() => {
    async function fetchQuestions() {
      try {
        await api
          .get('/placement/questions', {
            params: { type: 'multiple_choice', limit: 22 },
          })
          .then((response) => {
            setQuestions(response.data);
          });
      } catch (err) {
        console.log(err);
      }
    }

    fetchQuestions();
  }, []);

  const activeQuestion = useMemo(() => {
    if (!questions) return {} as Question;
    return questions[activePage];
  }, [activePage, questions]);

  function handleActivePageChange() {
    setActivePage((prevState) => prevState + 1);
  }

  if (!questions) return null;

  return (
    <ContainerQuestions>
      <ContainerTop>
        <Text>{t('questoes')}</Text>
        <ContainerButtonsTop>
          <Text button>
            {activePage}
            /
            {questions.length}
          </Text>
        </ContainerButtonsTop>
      </ContainerTop>

      <Wrapper>
        <>
          <Text quetions>{activeQuestion?.text}</Text>
          {activeQuestion?.answers?.map((option, index) => (
            <ButtonQuestions
              checked={answers.some(
                (a) => a.questionId === activeQuestion.id && a.answer === index,
              )}
              onClick={() => {
                onMultipleChoiceAnswerChange({
                  questionId: activeQuestion.id,
                  question: activeQuestion.text,
                  answer: index,
                });
              }}
            >
              {option.text}
            </ButtonQuestions>
          ))}
        </>
        {activePage === questions.length - 1 ? (
          <ButtonQuestions
            disabled={!answers.some((a) => a.questionId === activeQuestion.id)}
            quetions
            onClick={onSubmit}
          >
            {t('enviar')}
          </ButtonQuestions>
        ) : (
          <ButtonQuestions
            disabled={!answers.some((a) => a.questionId === activeQuestion.id)}
            quetions
            onClick={handleActivePageChange}
          >
            {t('next')}
          </ButtonQuestions>
        )}
      </Wrapper>
    </ContainerQuestions>
  );
};

export { MultipleChoices };
