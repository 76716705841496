import React from 'react';

interface Props {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<Props> = ({
  children, value, index, ...rest
}) => (
  <div {...rest} hidden={value !== index}>
    {value === index && children}
  </div>
);

export default TabPanel;
