import styled, { css } from 'styled-components';
import Modal from 'react-modal';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f9f9f9;

  overflow: auto;
`;

export const ModalMission = styled(Modal)``;

export const Wrapper = styled.div`
  margin: 1.2rem;
`;

export const Content = styled.div`
  padding: 0 1.2rem 1.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const ColumnContent = styled.div`
  width: 40rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  height: 25rem;
  width: 100%;
  object-fit: cover;
`;

export const ContainerImage = styled.div`
  padding: 1rem;
  width: 40rem;
  background: #fff;
  border: 1px solid var(--gray3);

  ${(props) =>
    props.chat &&
    css`
      height: 39.2rem;
      margin-top: 1.2rem;
      padding: 0;
      border: 0;
    `}

  @media (max-width: 768px) {
    display: none;
    width: 100%;

    ${(props) =>
      props.chat &&
      css`
        display: block;
        height: 39.2rem;
        margin-top: 1.2rem;
        padding: 0;
        border: 0;
      `}
  }
`;

export const ContainerSelect = styled.div`
  width: 100%;
  height: 7rem;
  background: #fff;
  border-right: 1px solid var(--gray3);
  border-left: 1px solid var(--gray3);
  border-bottom: 1px solid var(--gray3);

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
    height: 11rem;
    padding: 1rem 0;
  }

  @media (max-width: 560px) {
    height: 30rem;
  }
`;

export const Column = styled.div`
  padding-left: 1.2rem;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;

    ${(props) =>
      props.secondary &&
      css`
        margin-top: 1rem;
        justify-content: flex-start;
      `}
  }

  @media (max-width: 560px) {
    padding-left: 0;
    width: 100%;
    padding: 0 1rem;
    display: block;

    ${(props) =>
      props.secondary &&
      css`
        margin-top: 0;
        display: flex;
        justify-content: flex-start;
      `}
  }
`;

export const Select = styled.select`
  height: 3.8rem;
  padding: 0 1rem;
  border: 1px solid var(--gray3);
  color: #243342;
  margin-right: 1.2rem;
  border-radius: 0.5rem;

  option {
    color: #243342;
    background: #fff;
  }

  @media (max-width: 560px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const Button = styled.button`
  height: 3.8rem;
  padding: 0 1rem;
  background: #243342;
  color: #fff;
  margin-right: 1.2rem;
  border-radius: 0.5rem;

  @media (max-width: 560px) {
    ${(props) =>
      props.middle &&
      css`
        align-self: center;
        width: 100%;
        margin-bottom: 1rem;
      `}
  }
`;

export const ContainerModal = styled.div`
  background: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;

  textarea {
    width: 60rem;
    height: 30rem;
    border: 1px solid var(--gray3);
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0.5rem 0;
    outline: none;
    resize: none;
  }

  form {
    padding: 0 0 1rem 1rem;
    display: flex;
    flex-direction: column;
  }

  .container-img {
    display: flex;
  }

  .container-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 6rem;
      border: 1px solid var(--gray3);
      border-radius: 0.5rem;
      padding: 0 6rem;
      font-size: 2rem;
      color: #243342;
      cursor: pointer;

      :hover {
        filter: brightness(0.8);
      }
    }
  }

  .button {
    width: 100%;
    height: 6rem;
    background: #243342;
    color: #fff;
    margin-top: 1rem;
    border-radius: 0.5rem;
    font-size: 2rem;
    cursor: pointer;

    :hover {
      filter: brightness(0.8);
    }
  }
`;

export const Text = styled.span`
  color: #5e5873;
  font-size: 2rem;

  ${(props) =>
    props.title &&
    css`
      width: 100%;
      border-bottom: 1px solid var(--gray3);
      text-align: center;
      padding-bottom: 2rem;
      margin-bottom: 1rem;
    `}

  ${(props) =>
    props.text &&
    css`
      /* width: 100%; */
    `}
`;
