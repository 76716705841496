import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Check from '../../../assets/svgMethod/icon_check_green.svg';
import CheckList from '../../../assets/svgMethod/icon_check_list.svg';
import Close from '../../../assets/svgMethod/icon_close_green.svg';
import Schedule from '../../../assets/svgMethod/icon_schedule_green.svg';
import StudentImage from '../../../assets/svgMethod/icon_student_green.svg';
import { ListAnswer } from '../../../components/Modals/ListAnswer';
import { ListCorrectionClosedModal } from '../Method/ListCorrectionClosedModal';
import {
  Container,
  HeaderContainer,
  Text,
  Icon,
  ListItem,
  IconList,
  Image,
  ContainerNotes,
  Button,
} from './corrected.styles';
import { Homework } from './ListAssigments.Item';

interface SetProps {
  data: Homework[];
  setSelectItem: Dispatch<SetStateAction<string>>;
}

export const CorrectedItem = ({ data, setSelectItem }: SetProps) => {
  const { t } = useTranslation();
  const [isListAnswersOpen, setIsListAnswersOpen] = useState(false);

  const [homeworkToCorrect, setHomeworkToCorrect] = useState<any>();
  /*
    <IconList>
      <Image src={Schedule} />
    </IconList>
    <Text right>11/03/2020</Text>
  */

  return (
    <Container>
      <HeaderContainer>
        <Text>{t('reviewed')}</Text>
        <Icon onClick={() => setSelectItem('0')}>
          <span className="iconify" data-icon="eva:close-fill" />
        </Icon>
      </HeaderContainer>

      {data.map((item) => (
        <ListItem>
          <IconList>
            <Image src={StudentImage} />
          </IconList>
          <Text>
            {item.method.title}
            :
            {item.mission.title}
          </Text>
          <Text>
            {item.type === 'open'
              ? 'Open-ended questions'
              : 'Closed-ended questions'}
          </Text>
          <br />

          <Button
            back="#fff"
            color="#083059"
            style={{ marginRight: '10px' }}
            onClick={async () => {
              await setHomeworkToCorrect(item);
              setIsListAnswersOpen(true);
            }}
          >
            <span
              className="iconify"
              data-icon="akar-icons:eye"
              data-inline="false"
            />
            {t('view')}
          </Button>
          <ContainerNotes>
            <IconList>
              <Image src={Check} />
            </IconList>
            <Text>
              {t('hits')}
              :
              {item.score.hits}
            </Text>
            <IconList>
              <Image src={Close} />
            </IconList>
            <Text>
              {t('mistakes')}
              :
              {item.score.misses}
            </Text>
            <IconList>
              <Image src={CheckList} />
            </IconList>
            <Text>
              {t('Grade')}
              :
              {item.score.grade.toFixed(2)}
            </Text>
          </ContainerNotes>
        </ListItem>
      ))}
      {homeworkToCorrect?.type === 'open' && isListAnswersOpen && (
        <ListAnswer
          homework={homeworkToCorrect}
          isOpen={isListAnswersOpen}
          onRequestClose={() => {
            setHomeworkToCorrect(undefined);
            setIsListAnswersOpen(false);
          }}
        />
      )}
      {homeworkToCorrect?.type === 'multiple_choice' && isListAnswersOpen && (
        <ListCorrectionClosedModal
          viewMode
          correction={homeworkToCorrect}
          modalIsOpen={isListAnswersOpen}
          setIsOpen={setIsListAnswersOpen}
        />
      )}
    </Container>
  );
};
