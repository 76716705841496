import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { customState } from '../../../utils/customState';
import Footer from '../../../components/Footer';
import { Header } from '../../../components/Header';
import { Multi } from '../../../components/Homework/Multi';
// import { Quiz } from '../../../components/Homework/Quiz';
import { Written } from '../../../components/Homework/Written';
import { ContainerInfo } from '../../../components/ProfileComponent/ContainerInfo';
import { DashboardStudentMultiple } from '../Profile/DashboardStudentMultiple';
import { DashboardStudentQuiz } from '../Profile/DashboardStudentQuiz';
import { DashboardStudentWritten } from '../Profile/DashboardStudentWritten';
import {
  Container,
  ContainerHeader,
  HeaderComponent,
  Nav,
  Select,
  Wrapper,
} from './styles';
import { api } from '../../../services/api';

const STATUS = [
  {
    value: 'pending',
    label: 'pending',
  },
  {
    value: 'corrected',
    label: 'corrected',
  },
  {
    value: 'failed',
    label: 'failed',
  },
  {
    value: 'passed',
    label: 'approved',
  },
];

export function Homeworks({ match }) {
  const { t } = useTranslation();
  const location0 = useLocation();
  const location = customState(location0);

  console.log('location', location);

  const [seletion] = useState('0');
  const [seletionMulti] = useState('2');

  const [status, setStatus] = useState(STATUS[0].value);
  const [allMethods, setAllMethods] = useState([
    {
      value: '',
      label: 'Method',
    },
  ]);
  const [method, setMethod] = useState('');
  const [invisibleHeader, setInvisibleHeader] = useState(false);

  const getAllMethods = async () => {
    const { data } = await api.get(`methods`);
    const tempMethods = [
      {
        value: '',
        label: 'Method',
      },
    ];
    for (let i = 0; i < data.docs.length; i += 1) {
      tempMethods.push({
        value: data.docs[i]._id,
        label: data.docs[i].title,
      });
    }
    setAllMethods(tempMethods);
  };

  const changeVisibleHeader = () => {
    console.log('location', location);
    console.log('state', location.state);
    console.log('headerInvisible', location.state?.headerInvisible);
    if (location.state?.headerInvisible) {
      setInvisibleHeader(true);
    }
  };

  useEffect(() => {
    changeVisibleHeader();
  }, [changeVisibleHeader]);

  useEffect(() => {
    getAllMethods();
  }, []);

  return (
    <Container>
      <Header />
      <Wrapper>
        <ContainerInfo
          url="/dashboard/profile/editprofile/profile"
          urlNote="/dashboard/profile/mynote"
          match={match}
        />
        {!invisibleHeader && (
          <HeaderComponent>
            <ContainerHeader>
              <Nav left to={`${match.path}/written`}>
                {t('openEndedQuestions')}
              </Nav>
              <Nav to={`${match.path}/multi`}>{t('closedEndedQuestions')}</Nav>
            </ContainerHeader>
            <ContainerHeader selects>
              <Select
                value={method}
                onChange={({ target }) => setMethod(target.value)}
              >
                {allMethods.map(e => (
                  <option key={e.value} name="" value={e.value}>
                    {t(e.label)}
                  </option>
                ))}
              </Select>
              <Select onChange={({ target }) => setStatus(target.value)}>
                {STATUS.map(e => (
                  <option key={e.value} name="" value={e.value}>
                    {t(e.label)}
                  </option>
                ))}
              </Select>
            </ContainerHeader>
          </HeaderComponent>
        )}

        <Switch>
          <Route
            exact
            path={`${match.url}/`}
            render={() => <Redirect to={`${match.url}/written`} />}
          />

          <Route
            path={`${match.path}/written`}
            component={props => (
              <Written
                status={status}
                method={method}
                type="open"
                seletion={seletion}
                {...props}
              />
            )}
          />
          <Route
            path={`${match.path}/multi`}
            component={props => (
              <Multi
                status={status}
                method={method}
                type="multiple_choice"
                seletion={seletionMulti}
                {...props}
              />
            )}
          />
          <Route
            path={`${match.path}/multiple`}
            component={props => (
              <DashboardStudentMultiple seletion={seletion} {...props} />
            )}
          />
          <Route
            path={`${match.path}/writing`}
            component={props => (
              <DashboardStudentWritten seletion={seletion} {...props} />
            )}
          />
          <Route
            path={`${match.path}/quiz`}
            match={match}
            component={props => <DashboardStudentQuiz {...props} />}
          />
        </Switch>
      </Wrapper>

      <Footer />
    </Container>
  );
}
