import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WrapperContainer = styled.div`
  padding: 2rem;
  @media (max-width: 771px) {
    padding: 8rem 2rem;
  }
  width: 100% header {
    display: flex;
    align-items: center;
  }

  .header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    font-weight: 500;
    padding: 0.8rem 0;
  }

  .buttons {
    display: flex;

    button {
      margin-left: 1.5rem;
      padding: 0.5rem;
      color: #083059;
      border-radius: 50%;
      border: 1px solid #083059;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      :hover {
        filter: brightness(0.8);
      }

      .iconify {
        font-size: 2rem;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .guidelineContent {
    /* height: calc(50vh - 100px); */
    /* height: 400px; */
    /* width: 20rem; */
  }

  .container-carrosel {
    width: 100%;
  }

  .list-videos {
    display: flex;
    flex-wrap: nowrap;
    gap: 1.5rem;
    align-items: flex-start;
    height: 100%;
    width: 100rem;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar {
      height: 6px;
    }
    ::-webkit-scrollbar-track {
      background: #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 10px;
    }
    ::selection {
      background-color: #fff;
    }

    @media (min-width: 2270px) {
      width: 210rem;
    }

    @media (max-width: 2269px) {
      width: 189rem;
    }

    @media (max-width: 2181px) {
      width: 180rem;
    }

    @media (max-width: 2171px) {
      width: 180rem;
    }

    @media (max-width: 2100px) {
      width: 153rem;
    }

    @media (max-width: 1840px) {
      width: 148rem;
    }

    @media (max-width: 1770px) {
      width: 140rem;
    }

    @media (max-width: 1692px) {
      width: 130rem;
    }

    @media (max-width: 1592px) {
      width: 120rem;
    }

    @media (max-width: 1492px) {
      width: 110rem;
    }

    @media (max-width: 1149px) {
      width: 100rem;
    }

    @media (max-width: 1062px) {
      width: 91rem;
    }

    @media (max-width: 987px) {
      width: 80rem;
    }

    @media (max-width: 892px) {
      width: 70rem;
    }

    @media (max-width: 808px) {
      width: 60rem;
    }

    @media (max-width: 750px) {
      width: 75rem;
    }

    @media (max-width: 680px) {
      width: 65rem;
    }

    @media (max-width: 550px) {
      width: 50rem;
    }

    @media (max-width: 450px) {
      width: 45rem;
      gap: 0.5rem;
    }

    @media (max-width: 400px) {
      width: 40rem;
      gap: 0.5rem;
    }

    @media (max-width: 370px) {
      width: 35rem;
      gap: 0.5rem;
    }
  }

  .container-video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30rem;
    margin: 0 1.3rem;

    scroll-snap-align: start;
  }

  .container-ebook {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 20rem;
    margin-right: 2rem;

    scroll-snap-align: start;

    img {
      height: 60%;
      width: 20rem;
      object-fit: cover;
      align-self: center;
      margin-bottom: 2rem;
    }

    button {
      /* position: absolute; */
      bottom: 0;
      width: 100%;
      height: 4rem;
      background: #083059;
      color: #fff;
      font-size: 1.5rem;
      border-radius: 0.5rem;
      margin-top: 0.5rem;
    }
  }

  /* @media (max-width: 768px) {
    margin-top: 6rem;
  } */
`;

export const GuideLineContent = styled.div`
  height: auto;
  width: 100%;

  ${props =>
    props.checkContent &&
    css`
      min-height: 350px;
    `}
`;

export const Text = styled.span`
  color: #5e5873;
  font-size: 1.3rem;
  text-align: center;
  /* min-height: 10rem; */
  margin-bottom: 1rem;
  height: 8rem;
  overflow-y: auto;

  ${props =>
    props.title &&
    css`
      font-size: 2rem;
      font-weight: 600;
      /* height: auto; */
      margin-bottom: 1rem;
      height: 5rem;
    `}
`;

export const Input = styled.input`
  height: 4rem;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  margin-left: 3rem;
  width: 20rem;
  padding: 0 2rem;
`;

export const AccessBook = styled(Link)`
  button {
    cursor: pointer;
  }
  @media (max-width: 1280px) {
    font-size: 1.4rem;
  }
`;
