import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiStopCircle } from 'react-icons/fi';

import Image from '../../../assets/svgTest/icon_record.svg';
import useRecorder from '../../../hooks/useRecorder';
import { api } from '../../../services/api';
import { UseRecorder } from '../../../types/recorder';
import { Question } from '../../../types/registerTest';
import { formatMinutes, formatSeconds } from '../../../utils/formatTime';
import { TextReadingAnswer } from '../index';
import {
  ButtonQuestions,
  ButtonRecord,
  ContainerQuestions,
  ContainerRecord,
  ContainerTop,
  ImageRecord,
  Text,
  Wrapper,
} from './styles';

interface TextReadingProps {
  answer?: TextReadingAnswer;
  isSubmitting: boolean;
  onSubmit: () => void;
  onTextReadingAnswerChange: (answer: TextReadingAnswer) => void;
}

const TextReading = ({
  onSubmit,
  onTextReadingAnswerChange,
  isSubmitting,
}: TextReadingProps) => {
  const {
    recorderState,
    startRecording,
    saveRecording,
  }: // cancelRecording,
  UseRecorder = useRecorder();
  const {
    audio, recordingMinutes, recordingSeconds, initRecording,
  } = recorderState;

  const [question, setQuestion] = useState<Question>();
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchQuestions() {
      try {
        await api
          .get('/placement/questions', {
            params: { type: 'text_reading', limit: 1 },
          })
          .then((response) => {
            if (Array.isArray(response.data) && response.data.length > 0) {
              setQuestion(response.data[0]);
            }
          });
      } catch (err) {
        console.log(err);
      }
    }

    fetchQuestions();
  }, []);

  useEffect(() => {
    if (audio && question) {
      onTextReadingAnswerChange({
        audioURL: audio,
        question: question.text,
        questionId: question.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, question]);

  if (!question) return null;

  return (
    <ContainerQuestions>
      <ContainerTop>
        <Text quetions>{t('questoes')}</Text>
      </ContainerTop>

      <Wrapper>
        <Text>{question.text}</Text>

        <Text sendRecord>{t('readingTheAboveText')}</Text>
        <Text sendRecord>{t('pressToStart')}</Text>

        <ButtonRecord
          onClick={() => {
            if (initRecording) {
              saveRecording();
            } else {
              startRecording();
            }
          }}
        >
          <ContainerRecord>
            {initRecording ? (
              <FiStopCircle size="4rem" color="#243342" />
            ) : (
              <ImageRecord src={Image} />
            )}
          </ContainerRecord>
        </ButtonRecord>

        <Text sendRecord>
          {formatMinutes(recordingMinutes)}
          :
          {formatSeconds(recordingSeconds)}
        </Text>

        {audio && <audio controls src={audio} />}

        <ButtonQuestions
          disabled={isSubmitting || !audio}
          quetions
          onClick={onSubmit}
        >
          {t('enviar')}
        </ButtonQuestions>
      </Wrapper>
    </ContainerQuestions>
  );
};

export { TextReading };
