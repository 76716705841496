import styled, { css } from 'styled-components';
import imgHeader from '../../../assets/back_banner_top.png';

export const Container = styled.div`
  header {
    background: #0c231f;
    background-image: url(${imgHeader});
    background-repeat: no-repeat;
    height: 24rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 560px) {
    header {
      height: 20rem;
    }

    .image-banner {
      margin-left: 1.5rem;
      width: 14.4rem;
      height: 11rem;
    }
  }
`;

export const Text = styled.span`
  font-size: 1.6rem;

  ${(props) =>
    props.titleHeader &&
    css`
      color: #fff;
      font-size: 2.4rem;
      width: 33rem;
      margin-left: 3rem;
    `}

  ${(props) =>
    props.left &&
    css`
      color: #243342;
      font-size: 1.9rem;
    `}

  ${(props) =>
    props.textTime &&
    css`
      font-size: 1.3rem;
    `}

  ${(props) =>
    props.name &&
    css`
      font-size: 1.6rem;
      color: #5e5873;
    `}

  ${(props) =>
    props.exam &&
    css`
      font-size: 1.4rem;
      color: #b9b9c3;
    `}

    @media (max-width: 560px) {
    ${(props) =>
      props.titleHeader &&
      css`
        margin-left: 1.5rem;
        font-size: 2rem;
      `}
  }
`;

export const ContainerInfo = styled.div`
  border: 1px solid var(--gray3);
  height: 7rem;
  background: #f9f9f9;
  display: flex;

  @media (max-width: 768px) {
    display: block;
    padding: 1rem 0;
  }

  @media (max-width: 560px) {

    ${(props) =>
      props.typeLogin === true &&
      css`
        height: 11rem;
      `}
  }
`;

export const ImageHeader = styled.img`
  padding: 2rem 2.5rem;
  border-radius: 50%;
  background: #fff;
  border: 1px solid var(--gray3);
  position: absolute;
  top: -5rem;
  left: 4rem;
`;

export const Column = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  position: relative;

  ${(props) =>
    props.columnleft &&
    css`
      padding-left: 15rem;
    `}

  ${(props) =>
    props.columnright &&
    css`
      justify-content: flex-end;
      padding: 0 2rem;
    `}

    @media (max-width: 768px) {
    justify-content: space-around;
    ${(props) =>
      props.columnleft &&
      css`
        display: none;
      `}
  }

  @media (max-width: 560px) {
    justify-content: center;
  }

  @media (max-width: 560px) {
    ${(props) =>
      props.columnright &&
      css`
        display: block;
        justify-content: center;
        padding: 0 2rem;
      `}
  }
`;

export const Button = styled.button`
  background: #243342;
  color: #fff;
  height: 3.5rem;
  padding: 0 2rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
  width: 18rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    filter: brightness(0.8);
  }

  @media (max-width: 560px) {
    width: 100%;
    margin-left: 0;
    margin-top: 0.5rem;
  }
`;

export const ContainerProfile = styled.div`
  display: flex;
  align-items: center;
  width: 20rem;
  margin-left: 1rem;

  @media (max-width: 560px) {
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-top: 0.5rem;
  }
`;

export const ImageProfile = styled.img`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
`;

export const ContainerText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${(props) =>
    props.exam &&
    css`
      flex-direction: row;
      align-items: center;
    `}
`;

export const ContainerCounter = styled.div`
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 25% 75%;
  position: relative;

  @media screen and (max-width: 560px) {
    flex-direction: column;
    grid-template-columns: 15% 85%;
  }
`;

export const Hour = styled.div`
  display: flex;
  align-items: center;
  background: #243342;
  border: 1px solid #243342;
  border-radius: 0.5rem;
  height: 3.5rem;
  padding: 0 1.5rem;
  color: #fff;

  .container-text {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;

    span {
      line-height: 1.3rem;
    }
  }

  img {
    height: 2rem;
    width: 2rem;
  }

  @media (max-width: 560px) {
    justify-content: center;
  }
`;
export const ContainerPauseButton = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 30px;
  line-height: 1rem;

  svg {
    cursor: pointer;
  }
`;