import styled, { css } from 'styled-components';

interface LessonProps {
  division?: boolean;
}

interface TextModalProps {
  mission?: boolean;
  headerModal?: boolean;
}
interface ModalButtonsProps {
  right?: boolean;
}

interface ButtonsCheckModalProps {
  close?: boolean;
}

interface ButtonsProps {
  schedule?: boolean;
  cancel?: boolean;
}

export const Container = styled.div`
  width: 100%;
  background: #fff;

  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  border-left: 1px solid var(--gray3);
  border-right: 1px solid var(--gray3);
  border-bottom: 1px solid var(--gray3);
  border-radius: 0.4rem;

  position: relative;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #707070;

  span {
    font-size: 1.6rem;
  }

  .iconify {
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const Text = styled.span``;

export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--gray3);

  padding: 1.5rem 0;

  /* display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--gray3);

  padding: 1.5rem 0; */
`;

export const ContainerIcon = styled.div`
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: #28c76f12;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;

export const Icon = styled.img``;

export const ContainerHour = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  span {
    font-size: 1.4rem;
    color: #707070;
  }
`;

export const ContainerUser = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  align-items: center;
  margin-right: 1rem;

  /* position: relative;
  display: flex;
  align-items: center;
  margin-right: 1rem; */
`;

export const Image = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

export const ContainerTextsUser = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.6rem;
    color: #707070;
  }

  span + span {
    color: #b9b9c3;
    font-size: 1.4rem;
  }
`;

export const ContainerInfoLesson = styled.div<LessonProps>`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  ${(props) => props.division
    && css`
      border-left: 1px solid var(--gray3);
      border-right: 1px solid var(--gray3);
      padding: 0 1rem;
    `}
`;

export const ContainerTextsInfoLesson = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.4rem;
    color: #707070;
  }
`;

export const ContainerButtons = styled.div`
  position: absolute;
  right: 1.2rem;

  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4rem;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin-left: 1rem;

  color: #243342;
  cursor: pointer;

  .iconify {
    font-size: 1.6rem;
    margin-left: 1rem;
  }
`;

export const ButtonNoBorder = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4rem;
  width: 100%;
  padding: 0 1rem;
  margin-left: 1rem;

  color: #243342;
  cursor: pointer;

  .iconify {
    font-size: 1.6rem;
    margin-left: 1rem;
  }
`;

// MODAL

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100rem;
  padding: 1rem;
  border-radius: 0.4rem;
  background: #fff;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--gray3);
    padding-bottom: 2rem;

    div + span {
      width: 20rem;
    }

    .iconify {
      font-size: 1.6rem;
      color: #5e5873;
      margin-left: 10rem;
    }
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
`;

export const TextsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
`;

export const ImageModal = styled.img`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const TextModal = styled.span<TextModalProps>`
  font-size: 1.6rem;
  color: #5e5873;

  ${(props) => props.mission
    && css`
      color: #b9b9c3;
      font-size: 1.4rem;
    `}
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerTextList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  span {
    border: 1px solid var(--gray3);
    padding: 1rem;
  }

  span + span {
    border-top: 0;
  }
`;

export const ButtonModal = styled.button`
  width: 100%;
  margin: 2rem 0;
  padding: 1rem;
  background: #243342;
  color: #fff;
  font-size: 1.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
`;

export const ContainerModalContinue = styled.div`
  background: #fff;
  height: 40rem;
  width: 100rem;
  border-radius: 0.4rem;

  display: flex;
  flex-direction: column;
`;

export const ContainerHeaderModal = styled.div`
  display: flex;
  align-items: center;
  height: 7rem;
  min-height: 7rem;
  padding: 1rem;
  border-bottom: 1px solid var(--gray3);
  position: relative;
`;

export const ContainerUserModal = styled.div`
  display: flex;
  align-items: center;
  margin: 0 3rem;
`;

export const ImageProfile = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

export const ContainerTextsUserModal = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.6rem;
    color: #707070;
  }

  span + span {
    color: #b9b9c3;
    font-size: 1.4rem;
  }
`;

export const ContainerButtonsModal = styled.div`
  position: absolute;
  right: 0;

  display: flex;
  align-items: center;
  height: 100%;

  span {
    font-size: 1.6rem;
    color: #243342;
    font-weight: 600;
    padding: 0 2rem;
  }
`;

export const ButtonsModal = styled.button<ModalButtonsProps>`
  height: 6.9rem;
  width: 6rem;
  border-left: 1px solid var(--gray3);
  border-right: 1px solid var(--gray3);

  ${(props) => props.right
    && css`
      border-right: 0;
    `}
`;

export const WrapperModal = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
`;

export const ContainerModalWrapper = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin-bottom: 2rem;

  div + div {
    border-bottom: 0;
  }
`;

export const ContainerText = styled.div`
  border-bottom: 1px solid var(--gray3);
  padding: 4rem 2rem;
  position: relative;

  display: flex;
  align-items: center;

  span {
    font-size: 1.4rem;
    font-weight: 600;
    color: #243342;
  }
`;

export const ButtonCheckLesson = styled.button<ButtonsCheckModalProps>`
  margin-right: 2rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;

  background: #083059;

  .iconify {
    color: #fff;
    font-size: 2rem;
  }

  ${(props) => props.close
    && css`
      background: #ff0000;
    `}
`;

export const ButtonModalContinue = styled.div`
  height: 4rem;
  width: 100%;
  background: #243342;
  color: #fff;
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;

  .iconify {
    font-size: 1.6rem;
    margin-right: 0.5rem;
    margin-bottom: 0.3rem;
  }
`;

export const ButtonMessage = styled.button<ButtonsProps>`
  height: 4rem;
  width: 20rem;
  color: #fff;
  background: #243342;
  padding: 0 4rem;
  /* margin: 0 1rem; */
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .iconify {
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }

  ${(props) => props.schedule
    && css`
      background: #fff;
      color: #243342;
      padding: 0 2rem;

      .iconify {
        margin-right: 0.5rem;
        margin-left: 0;
      }
    `}

  ${(props) => props.cancel
    && css`
      background: #9e0d0d;

      .iconify {
        margin-right: 0.5rem;
        margin-left: 0;
      }
    `}
`;

export const ContainerShowComponents = styled.div`
  height: 30rem;
  width: 100%;
`;

type IBadgeStatus = { online: boolean };

export const BadgeStatus = styled.span<IBadgeStatus>`
  height: 1.4rem;
  width: 1.4rem;
  background: ${({ online }) => (online ? '#28c76f' : '#ccc')};
  border-radius: 50%;
  position: absolute;
  border: 2px solid #fff;
  left: 2.8rem;
  top: 2.8rem;
`;
