import styled, { css } from 'styled-components';

interface TextsProps {
  titleAssigments?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f9f9f9;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.2rem;

  @media (max-width: 768px) {
    margin-top: 6rem;
  }
`;

export const ContainerHeader = styled.div`
  height: 8rem;
  width: 100%;

  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  margin: 1.2rem 0;
`;

export const Text = styled.span<TextsProps>`
  ${(props) => props.titleAssigments
    && css`
      font-size: 1.8rem;
      font-weight: 600;
      color: #5e5873;
    `}
`;

export const Select = styled.select`
  height: 3.5rem;
  width: 15rem;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin: 0 1.2rem;
  padding: 0 0.8rem;
`;
