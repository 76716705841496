import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
const api2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// For GET requests
api.interceptors.request.use(function (config) {

  // spinning start to show
  // UPDATE: Add this code to show global loading indicator
  document.body.classList.remove('loading-indicator');
  document.body.classList.add('loading-indicator');

  return config
}, function (error) {
  document.body.classList.remove('loading-indicator');
  return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
  if(response?.code == 'token.expired') {
    localStorage.clear();
    window.location.href = "/";
  }
  // spinning hide
  // UPDATE: Add this code to hide global loading indicator
  document.body.classList.remove('loading-indicator');

  return response;
}, function (error) {
  document.body.classList.remove('loading-indicator');
  return Promise.reject(error);
});

export { api, api2 };
