const MINIMUM_HOUR_INTERVAL = 12;
const MILISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 3600;
const HOUR_IN_DAY = 24;

export const getDay = () => {
  const date = new Date();
  date.setHours(date.getHours() + MINIMUM_HOUR_INTERVAL);

  return date.getDay();
};

export const getHours = () => {
  const date = new Date();
  date.setHours(date.getHours() + MINIMUM_HOUR_INTERVAL);

  return date.getHours();
};

export const getMinutes = () => {
  const date = new Date();
  date.setHours(date.getHours() + MINIMUM_HOUR_INTERVAL);

  return date.getMinutes();
};

export const roundTime = (
  hour: number,
  minute: number,
): { hour: number; minute: number } => {
  if (minute > 0) {
    return {
      hour: hour + 1,
      minute: 0,
    };
  }
  return { hour, minute: 0 };
};

export const getLastTimeSeen = (lastSeen: number) => {
  const date = new Date(lastSeen);
  const lastDaySeen = date.toLocaleDateString();
  const [hour, minute] = date.toLocaleTimeString().split(':').slice(0, -1);
  const lastTimeSeen = `${hour}:${minute}`;

  return { lastSeen, lastDaySeen, lastTimeSeen };
};

export const toWeekDayLong = (lastDaySeen: string, language: string) => new Date(lastDaySeen).toLocaleString(language, { weekday: 'long' });

export const stringToTime = (string: string) => string.replace('h', ':').slice(0, -1);

export const daysFrom = (timestamp: number) => (Date.now() - timestamp)
  / (MILISECONDS_IN_SECOND * SECONDS_IN_MINUTE * HOUR_IN_DAY);
