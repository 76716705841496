import styled, { css } from 'styled-components';

export const Container = styled.select`
  height: 4rem;
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 5px;
  font-size: 1.25rem;
  padding: 10px;
  margin-bottom: 20px;
 
  ${({ error }) => error && css`
  border-color: #f00 !important;
  `};

  option {
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;


