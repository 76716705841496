import jwtDecode from 'jwt-decode';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { getSessionUserID } from '../helpers/session';
import { api, api2 } from '../services/api';
import { PerfilType } from '../enums/PerfilType';

const EXPIRES_IN_DAYS = 60
const MAX_AGE_IN_SECONDS = EXPIRES_IN_DAYS * 86400

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [cookies, setCookie] = useCookies(['auth']);
  const [authenticated, setAuthenticated] = useState(Boolean(getSessionUserID()))
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@TableTalks:token');
    const refreshToken = localStorage.getItem('@TableTalks:refreshToken');
    const user = localStorage.getItem('@TableTalks:user');
    const verified = localStorage.getItem('@TableTalks:verified');
    const twoFactorAuthentication = false;

    if (token && user && verified) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      api2.defaults.headers.authorization = `Bearer ${token}`;

      return {
        user: JSON.parse(user),
        token,
        refreshToken,
        verified,
        twoFactorAuthentication,
      };
    }

    return {};
  });

  const [typeLogin, setTypeLogin] = useState(() => {
    const token = localStorage.getItem('@TableTalks:token');

    if (token) {
      const { userType } = jwtDecode(token);

      if (userType === 'student') {
        return true;
      }
      return false;
    }

    return true;
  });

  const [loading, setLoading] = useState();

  const authCookie = useCallback(() => Boolean(cookies?.auth), [cookies])

  const renewCookie = useCallback(() => {
    try {
      let date = new Date();
      let expires = new Date(date.setDate(date.getDate() + EXPIRES_IN_DAYS));

      setCookie('auth', JSON.stringify(true), {
        path: '/',
        expires,
        maxAge: MAX_AGE_IN_SECONDS,
        sameSite: true
      })
    } catch(err) {
      // console.log(err)
    }
  }, [setCookie])

  const signIn = useCallback(async ({ email, password }) => {
    const confirmAuth = !authCookie()
    const response = await api.post('sessions', {
      email,
      password,
      confirmAuth
    });

    const { token, user, refreshToken } = response.data;

    api.defaults.headers.authorization = `Bearer ${token}`;
    api2.defaults.headers.authorization = `Bearer ${token}`;

    const isStudent = Boolean(user.type === 'student');

    setTypeLogin(isStudent);

    localStorage.setItem('@TableTalks:token', token);
    localStorage.setItem('@TableTalks:refreshToken', refreshToken.token);

    if (user.twoFactorAuthentication.type !== null && confirmAuth) {
      setData({ verified: false, twoFactorAuthentication: true });

      return { redirect: true, to: '/confirmauth' };
    } else {
      localStorage.setItem('@TableTalks:verified', true);
      localStorage.setItem('@TableTalks:user', JSON.stringify(user));

      setData({ token, refreshToken, user, verified: true });
      setAuthenticated(true)

      return { redirect: true, to: '/dashboard' };
    }
  }, []);

  const validateCode = useCallback(async code => {
    const verified = await api
      .get('sessions/validate', {
        params: { code },
      })
      .then(response => response.data.verified);

    if (!verified) {
      throw new Error('Código inválido');
    }

    localStorage.setItem('@TableTalks:verified', true);

    setData(prevState => ({ ...prevState, verified: true }));
    fetchUserData();
    setTimeout(() => {
      location.reload();
    }, 1000);
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@TableTalks:token');
    localStorage.removeItem('@TableTalks:refreshToken');
    localStorage.removeItem('@TableTalks:user');
    localStorage.removeItem('@TableTalks:verified');
    setAuthenticated(false)

    setData({});
  }, []);

  const fetchUserData = useCallback(async (loading = true) => {
    setLoading(loading);
    const user = await api.get(`users/`).then(response => response.data);

    setData(prevState => ({ ...prevState, user }));

    localStorage.setItem('@TableTalks:user', JSON.stringify(user));

    setLoading(false);
  }, []);

  useEffect(() => {
    if (data.token && data.verified) {
      fetchUserData();
    }
  }, [data.token, fetchUserData]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        token: data.token,
        typeLogin,
        user: data.user,
        signIn,
        signOut,
        fetchUserData,
        validateCode,
        loading,
        twoFactor: data.twoFactorAuthentication,
        renewCookie,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider.');
  return context;
}

export function isTeacher() {
  const {user} = useAuth();
  return user.type === PerfilType.TEACHER;
}

export function isStudent() {
  const {user} = useAuth();
  return user.type === PerfilType.STUDENT;
}

export function isAfiliate() {
  const {user} = useAuth();
  return user.type === PerfilType.AFILIATE;
}

export default AuthProvider;
