/* eslint-disable @typescript-eslint/no-explicit-any */
// yup-extended.ts
import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

import { isValidCNPJ } from '../helpers/cnpj';
import { isValidCPF } from '../helpers/cpf';

// custom cpf validation method
function validCPFType(this: any, message: string) {
  return this.test('isValidCPFType', message, (value: any) => {
    if (!isValidCPF(value)) {
      return false;
    }

    return true;
  });
}

// custom cnpj validation method
function validCNPJType(this: any, message: string) {
  return this.test('isValidCNPJType', message, (value: any) => {
    if (!validCPFType(value) && !isValidCNPJ(value)) {
      return false;
    }

    return true;
  });
}

// apply new yup method
yup.addMethod<yup.StringSchema>(yup.string, 'validCNPJType', validCNPJType);
yup.addMethod<yup.StringSchema>(yup.string, 'validCPFType', validCPFType);

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    validCNPJType(message: string): StringSchema<TType, TContext>;
    validCPFType(message: string): StringSchema<TType, TContext>;
  }
}

export default yup;
