import React from 'react';
import { Route as ReactDOMRouter, Redirect } from 'react-router-dom';
import Preload from '../pages/Preload';
import { useAuth } from '../hooks/AuthProvider';

const Route = ({ isPrivate = false, component: Component, ...rest }) => {
  const { user } = useAuth();

  return (
    <ReactDOMRouter
      {...rest}
      render={props => {
        return isPrivate === !!user ? (
          <Preload>
            <Component {...props} />
          </Preload>
        ) : (
          <>
            <Redirect to={{ pathname: isPrivate ? '/' : '/dashboard' }} />
          </>
        );
      }}
    />
  );
};

export default Route;
