import SelectLib from 'react-select';
import styled, { css } from 'styled-components';

import BackgroundWhite from '../../assets/background_login_white.png';

interface PropsButton {
  register?: boolean;
  left?: boolean;
  right?: boolean;
}

interface PropsInputs {
  terms?: boolean;
}

export const Container = styled.div`
  display: flex;

  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem 15rem;
  background-image: url(${BackgroundWhite});
  background-repeat: repeat-y;
  background-size: cover;
  overflow-y: auto;

  @media (max-width: 700px) {
    padding: 3rem;
  }
`;

export const Forms = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
`;

export const Logo = styled.img`
  height: 154px;
  width: 154px;
  margin: 0 0 1.5rem 0;

  @media (max-width: 1280px) {
    width: 12.4rem;
    height: 9.4rem;
  }`;

export const ContainerInput = styled.div<PropsInputs>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0.7rem 0;

  ${(props) => props.terms
    && css`
      padding: 0 1.2rem;
    `}
`;

export const ContainerLabel = styled.div`
  margin: 0 1.2rem;
  width: 100%;
`;

export const Input = styled.input`
  height: 4rem;
  width: 100%;
  background: #fff;
  border: 1px solid #ebe9f1;
  border-radius: 0.4rem;
  color: #b9b9c3;
  padding: 0 1rem;
`;

export const Select = styled(SelectLib)`
  height: 4rem;
  width: 100%;
  background: #fff;
  border: 1px solid #ebe9f1;
  border-radius: 0.4rem;
  font-size: 1.4rem;
`;

export const Label = styled.label`
  color: #5e5873;
`;

export const ContainerTerms = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin: 2rem 1.2rem;
  flex-direction: column;
`;

export const CheckBox = styled.input`
  margin-right: 1rem;
`;

export const SpanTerms = styled.span`
  font-size: 1.4rem;
  font-family: 'Montserrat';

  b {
    color: #dbad00;
    margin-left: 0.5rem;
  }
`;

export const Button = styled.button<PropsButton>`
  background: #dbad00;
  width: 100%;
  height: 4rem;
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;

  cursor: pointer;

  ${(props) => props.register
    && css`
      background: #243342;
    `}

  ${(props) => props.disabled
    && css`
      background: #cccccc;
    `}
  ${(props) => props.left
    && css`
      margin-right: 1.2rem;
    `}
  ${(props) => props.right
    && css`
      margin-left: 1.2rem;
    `}
`;

export const InputsLine = styled.div`
  display: flex;

  .container-phone {
    width: 4rem;
  }
`;
export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ErrorLabel = styled.label`
  padding-left: 1rem;
  font-size: 1.3rem;
  color: #f00;
`;
