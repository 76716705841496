import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Plan } from '../../../../models/plan';
import { api } from '../../../../services/api';
import { API_ROUTES } from '../../../../types/api-routes';
import { MonetaryUtil } from '../../../../utils/monetary-util';
import { usePurchaseContext } from '../hooks/context-hook';

function PurchaseFormStepThree(): React.ReactElement {
  const [plan, setPlan] = useState<Plan>();
  const context = usePurchaseContext();
  const { formData, commission } = context;

  useEffect(() => {
    loadPlan();
  }, []);

  function loadPlan(): void {
    api.get(`${API_ROUTES.PLANS}/${formData?.plan?.id}`)
      .then((response) => {
        const { data } = response;
        setPlan(data as Plan);
      });
  }
  
  function getPlanValue(): string {
    if (plan?.value) {
      const discountAffiliate = commission?.comissions ? commission.comissions[`discont_plan_${plan?.id}`] as number : undefined;
      if (discountAffiliate) {
        const finalValue = plan.value - (discountAffiliate / 100);
        return MonetaryUtil.round(String(finalValue), 2);    
      }

      if (plan?.discont_default) {
        const finalValue = plan.value - plan.discont_default;
        return MonetaryUtil.round(String(finalValue), 2);      
      }
    }

    return MonetaryUtil.round(String(plan?.value), 2);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">
          <b>Plano Selecionado</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {plan?.title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography align="right">
          <b>
            R$
            {getPlanValue()}
          </b>
        </Typography>
      </Grid>

      <Grid item xs={12} paddingTop={4}>
        <Typography variant="h6">
          <b>Endereço</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {formData.address}
          {' '}
          -
          {' '}
          {formData.number}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {formData.state?.name}
          {' '}
          -
          {' '}
          {formData.city?.name}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography />
      </Grid>

    </Grid>
  );
}

export {
  PurchaseFormStepThree,
};
