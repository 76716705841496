import { format, parseISO } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../../services/api';

import { Container, Table } from './styles';

export function LoginHistory() {
  const { t } = useTranslation();

  const [loginHistory, setLoginHistory] = useState([]);

  async function fetchLoginHistory() {
    const response = await api.get('/login-history/me');

    setLoginHistory(response.data);
  }

  useEffect(() => {
    fetchLoginHistory();
  }, []);

  return (
    <Container>
      <Table>
        <tr>
          <th>{t('tipo')}</th>
          <th>{t('date')}</th>
          <th>IP</th>
        </tr>
        {loginHistory.map(login => (
          <tr>
            <td>Web</td>
            <td>{format(parseISO(login.createdAt), 'dd/MM/yyyy HH:mm:ss')}</td>
            <td>{login.ipAddress}</td>
          </tr>
        ))}
      </Table>
    </Container>
  );
}
