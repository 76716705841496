import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Span } from '../../Span';
import { toast } from 'react-toastify';

import {
  Container,
  TicketHeader,
  TicketTitle,
  TicketBody,
  TicketDepartament,
  TicketCalled,
  TicketButtonAttachment,
  InputHidden,
  ButtonSend,
} from './styles';
import './styles/styles.css';
import axios from 'axios';
import { useAuth } from '../../../hooks/AuthProvider';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';

export function ModalTicket({ open, closed, openModalConfirmation }) {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [category, setCategory] = useState('');
  const [infoAttachment, setInfoAttachment] = useState(
    t('ifYouWishAttachAFileToYourTicket'),
  );
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();
  const filesElement = useRef(null);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const {
      data: { data, erro },
    } = await axios.get(
      `https://api.tomticket.com/departamentos/b40b8bc6aa8c3891087231bd5b097c09`,
    );
    if (!erro) {
      const [department] = data;
      setCategories(department.categorias);
    }
  };

  const handleButtonAttachment = () => {
    document.querySelector('.input-hidden').click();
  };

  const handleFiles = e => {
    const files = [...e.target.files];

    if (files.length === 0) {
      return setInfoAttachment(t('ifYouWishAttachAFileToYourTicket'));
    }

    if (files.length === 1) {
      return setInfoAttachment(files[0].name);
    }

    const qttFiles = files.reduce(accum => accum + 1, 0);
    setInfoAttachment(`${qttFiles} ${t('files')}`);
  };

  const sendAndCheckExistUser = async e => {
    e.preventDefault();

    if (!(title.trim() && message.trim() && category)) {
      return toast.error(t('fillInTheFormCorrectly'));
    }

    try {
      setLoading(true);
      const {
        data: {
          mensagem: mensagemExisteCliente,
          existe: existeCliente,
          erro: erroExisteCliente,
        },
      } = await axios.get(
        `https://api.tomticket.com/cliente/b40b8bc6aa8c3891087231bd5b097c09/${user.email}`,
      );

      if (erroExisteCliente) {
        setLoading(false);
        return toast.error(mensagemExisteCliente);
      }

      if (existeCliente) {
        return createCall();
      }

      const formData = new FormData();
      formData.append('identificador', user.email);
      formData.append('nome', user.fullName ?? user.name);
      formData.append('email', user.email);

      const {
        data: { mensagem: mensagemCriarCliente, erro: erroCriarCliente },
      } = await axios.post(
        'https://api.tomticket.com/criar_cliente/b40b8bc6aa8c3891087231bd5b097c09',
        formData,
      );

      if (erroCriarCliente) {
        setLoading(false);
        return toast.error(mensagemCriarCliente);
      }

      return setTimeout(() => createCall(), 3000);
    } catch (e) {
      setLoading(false);
      toast.error(e);
    }
  };

  const createCall = async () => {
    const formData = new FormData();
    formData.append('id_departamento', '2df3a542a0e80ac7da8b3f70a10a996b');
    formData.append('titulo', title);
    formData.append('mensagem', message);
    formData.append('id_tipoassunto', category);

    [...filesElement.current.files].forEach((file, index) => {
      formData.append(`anexos[${index}]`, file);
    });

    try {
      const {
        data: { protocolo, mensagem, erro },
      } = await axios.post(
        `https://api.tomticket.com/criar_chamado/b40b8bc6aa8c3891087231bd5b097c09/${user.email}`,
        formData,
      );

      if (erro) {
        return toast.error(mensagem);
      }

      closed();
      openModalConfirmation(protocolo);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={open}
      contentLabel="Example Modal"
      onRequestClose={closed}
      overlayClassName="Overlay"
      className="Modal"
    >
      <Container onSubmit={sendAndCheckExistUser}>
        <TicketHeader>
          <Span onClick={closed}>
            <AiOutlineClose size={17} color={'#000'} title={t('fechar')} />
          </Span>
        </TicketHeader>
        <TicketTitle
          value={title}
          placeholder={t('titleYourTicket')}
          onChange={e => setTitle(e.target.value)}
        />
        <TicketBody
          value={message}
          placeholder={t('describeYourProblemAndHowWeCanHelp')}
          onChange={e => setMessage(e.target.value)}
        />
        <TicketDepartament onChange={e => setCategory(e.target.value)}>
          <option value="">{t('chooseACategory')}</option>
          {categories.map(c => (
            <option value={c.id} key={c.id}>
              {c.nome}
            </option>
          ))}
        </TicketDepartament>
        <TicketButtonAttachment type="button" onClick={handleButtonAttachment}>
          {infoAttachment}
        </TicketButtonAttachment>
        <InputHidden
          type="file"
          multiple
          className="input-hidden"
          ref={filesElement}
          onChange={e => handleFiles(e)}
        />
        <TicketCalled>
          <ButtonSend type="submit" disabled={loading}>
            {t('openNewTicket').toUpperCase()}
          </ButtonSend>
        </TicketCalled>
      </Container>
    </Modal>
  );
}
