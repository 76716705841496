import React, { useMemo, useState } from 'react';

import { MultipleChoiceAnswer } from '../index';
import questions from './data.json';
import { ButtonQuestions, Text, Wrapper } from './styles';

interface MultipleChoicesProps {
  answers: MultipleChoiceAnswer[];
  onSubmit: () => void;
  onMultipleChoiceAnswerChange: (answer: MultipleChoiceAnswer) => void;
}

const MultipleChoices = ({
  answers,
  onMultipleChoiceAnswerChange,
}: MultipleChoicesProps) => {
  const [activePage, setActivePage] = useState(0);

  const activeQuestion = useMemo(() => questions[activePage], [activePage]);

  function handleActivePageChange() {
    setActivePage((prevState) => prevState + 1);
  }

  return (
    <Wrapper>
      <>
        <Text quetions>{activeQuestion?.title}</Text>
        {activeQuestion.answers?.map((answer) => (
          <ButtonQuestions
            key={answer}
            checked={answers.some(
              (a) => a.question === activeQuestion.title && a.answer === answer,
            )}
            onClick={() => {
              onMultipleChoiceAnswerChange({
                question: activeQuestion.title,
                answer,
              });
            }}
          >
            {answer}
          </ButtonQuestions>
        ))}
      </>
      {activePage < questions.length - 1 && (
        <ButtonQuestions
          disabled={!answers.some((a) => a.question === activeQuestion.title)}
          quetions
          onClick={handleActivePageChange}
        >
          Next question
        </ButtonQuestions>
      )}
    </Wrapper>
  );
};

export { MultipleChoices };
