import formatDate from 'date-fns/format';
import React, {
  Fragment, useEffect, useState, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Assignment, Student, Method, Mission,
} from '.';
import Check from '../../../assets/svgMethod/icon_check_green.svg';
import Close from '../../../assets/svgMethod/icon_close_green.svg';
import { api } from '../../../services/api';
import { ASSIGNMENTS_STATUS } from './Assignments.enum';
import { CorrectedItem } from './Corrected.Item';
import {
  Container,
  ContainerBook,
  // ImageBook,
  ContainerTextsBook,
  Text,
  Arrow,
  ContainerUser,
  Image,
  ContainerTextsUser,
  ContainerButtons,
  ContainerInfoLesson,
  ContainerTextsInfoLesson,
  ContainerIcon,
  Icon,
  Select,
  ContainerHours,
} from './listItem.styles';
import { PendantsItems } from './Pendants.Item';

export interface Answer {
  questionId: string;
  question: string;
  answer: string;
  isCorrectAnswer: number;
  obs: string;
  isCorrected: boolean;
  _id: string;
}

export interface Homework {
  _id: string;
  title: string;
  method: Method;
  mission: Mission;
  teacher: string;
  student: Student;
  quiz: { method: Method; mission: Mission };
  status: string;
  exerciseDate: Date;
  answers: Answer[];
  type: string;
  createdAt: Date;
  updatedAt: Date;
  id: string;
  score: {
    hits: number;
    misses: number;
    grade: number;
  };
}

interface ListItemAssignmentsProps {
  data: Assignment;
}

export const ListItemAssigments = ({ data }: ListItemAssignmentsProps) => {
  const { t } = useTranslation();

  const {
    student,
    method,
    mission,
    pending,
    paused,
    completed,
    nextAppointment,
  } = data;

  const [, setSelectItem] = useState<string>('0');
  const [status, setStatus] = useState<string>();

  const [homework, setHomework] = useState<Homework[]>();

  const fetchAssignments = useCallback(async () => {
    try {
      await api
        .get(`/assignments/${student._id}/${method._id}`, {
          params: {
            status,
          },
        })
        .then((response) => {
          setHomework(response.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [method._id, status, student._id]);

  useEffect(() => {
    if (status) {
      fetchAssignments();
    }
  }, [fetchAssignments, status]);

  function handleChangeStatus(status: string): void {
    switch(status) {
      case ASSIGNMENTS_STATUS.AWAITING_CORRECTION: 
      case ASSIGNMENTS_STATUS.CORRECTION_COMPLETED:
        setStatus(status);
        break;
      default: {
        setStatus(undefined);
        setHomework([]);
      }
    }
  }

  return (
    <>
      <Container>
        <ContainerBook>
          {/* <ImageBook src={imageBook} /> */}
          <ContainerTextsBook>
            <Text>{method.title.split('-')[0]}</Text>
            <Text>{method.title.split('-')[1]}</Text>
          </ContainerTextsBook>
          <Arrow />
        </ContainerBook>

        <ContainerUser>
          <Image src={student.avatarURL} />
          <ContainerTextsUser>
            <Text>
              {student.name}
              {' '}
              {student.lastName}
            </Text>
            <Text>{mission.title}</Text>
          </ContainerTextsUser>
        </ContainerUser>

        {/* {nextAppointment && (
          <ContainerHours>
            <span className="iconify" data-icon="akar-icons:calendar" />
            <Text>
              {t('nextLesson')}:{' '}
              {formatDate(new Date(nextAppointment.startTime), 'dd/MM/yyyy')}{' '}
              {t('from')}{' '}
              {formatDate(new Date(nextAppointment.startTime), 'HH:mm')}{' '}
              {t('at')} {formatDate(new Date(nextAppointment.endTime), 'HH:mm')}{' '}
            </Text>
          </ContainerHours>
        )} */}

        <ContainerButtons>
          <ContainerInfoLesson>
            <ContainerIcon>
              <Icon src={Check} />
            </ContainerIcon>
            <ContainerTextsInfoLesson>
              <Text>
                {t('reviewed')}
                :
                {completed}
              </Text>
            </ContainerTextsInfoLesson>
          </ContainerInfoLesson>

          <ContainerInfoLesson>
            <ContainerIcon>
              <Icon src={Close} />
            </ContainerIcon>
            <ContainerTextsInfoLesson>
              <Text>
                {t('pendentes')}
                :
                {pending}
              </Text>
            </ContainerTextsInfoLesson>
          </ContainerInfoLesson>
          <ContainerInfoLesson>
            <ContainerIcon>
              <Icon src={Close} />
            </ContainerIcon>
            <ContainerTextsInfoLesson>
              <Text>
                {t('paused')}
                :
                {paused}
              </Text>
            </ContainerTextsInfoLesson>
          </ContainerInfoLesson>
          <Select value={status} onChange={(e) => handleChangeStatus(e.target.value)}>
            <option value={undefined}>{t('selecionar')}</option>
            <option value="awaiting_correction">{t('pendentes')}</option>
            <option value="correction_completed">{t('reviewed')}</option>
          </Select>
        </ContainerButtons>
      </Container>
      {status === ASSIGNMENTS_STATUS.AWAITING_CORRECTION && homework && (
        <PendantsItems
          data={homework}
          fetchAssignments={fetchAssignments}
          setSelectItem={setSelectItem}
        />
      )}
      {status === ASSIGNMENTS_STATUS.CORRECTION_COMPLETED && homework && (
        <CorrectedItem data={homework} setSelectItem={setSelectItem} />
      )}
    </>
  );
};
