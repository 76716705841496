import Footer from '../../../components/Footer';
import { Header } from '../../../components/Header';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import * as C from './styles';
import { Book } from './Ebook';
import { Mission } from './Mission';
import { Content } from './Content';
import { Exam } from './Exam';

export const Methods = () => {
  const { path } = useRouteMatch();

  return (
    <C.Container>
      <Header />
      <C.ContainerBody>
        <Switch>
          <Route path={`${path}/:idMethod/mission/:idMission/exam`}>
            <Exam />
          </Route>
          <Route path={`${path}/:idMethod/content`}>
            <C.WrapperContent>
              <Content />
            </C.WrapperContent>
          </Route>
          <Route>
            <C.WrapperBook>
              <Book />
            </C.WrapperBook>
          </Route>
        </Switch>
      </C.ContainerBody>
      <Footer />
    </C.Container>
  );
};
