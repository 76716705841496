import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: hidden;
  margin: 10px;
  column-gap: 1rem;
`;

export const Left = styled.div`
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

export const TextArea = styled.textarea`
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  height: 30rem;
  width: 50rem;
  padding: 1rem;

  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  height: 3.8rem;
  width: 50rem;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem;
  padding: 0 1rem;
  cursor: pointer;

  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
