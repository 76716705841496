import { ReactElement } from 'react';

import { PurchaseContextProvider } from './context/purchase-context';
import { Purchase } from './purchase/purchase';

function PurchasePlans(): ReactElement {
  return (
    <PurchaseContextProvider>
      <Purchase />
    </PurchaseContextProvider>
  );
}

export {
  PurchasePlans,
};
