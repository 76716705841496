/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Span } from '../../../../Span';

import {
  ContainerTitle,
  Title,
  ContainerTicketBodyPending,
  ContainerLessons,
} from './styles';

function Performance({ presence, desempenho, show, statistic }) {
  const { t } = useTranslation();

  return (
    <>
      {desempenho && !presence && show && statistic ? (
        <>
          <ContainerTitle>
            <Title>{t('performance')}</Title>
          </ContainerTitle>
          <ContainerTicketBodyPending>
            <ContainerLessons>
              <table>
                <thead>
                  <tr>
                    <th> </th>
                    <th>{t('completed')}</th>
                    <th>{t('canceled')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td>{t('aulas')}</td>
                    <td>
                      {statistic.studentClasses &&
                      statistic.studentClasses.length > 0
                        ? statistic.studentClasses
                            .filter(classe => classe._id == 'finished')
                            .map(filteredClass => <>{filteredClass.count}</>)
                        : 0}
                    </td>
                    <td>
                      {statistic.studentClasses &&
                      statistic.studentClasses.length > 0
                        ? statistic.studentClasses
                            .filter(classe => classe._id == 'cancelled')
                            .map(filteredClass => <>{filteredClass.count}</>)
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ContainerLessons>

            <ContainerLessons>
              <table>
                <thead>
                  <tr>
                    <th> </th>
                    <th>Approved</th>
                    <th>Pending</th>
                    <th>Failed</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Activities (Quiz + Exam)</td>
                    <td>
                      {statistic.activities.activitiesPassed &&
                      statistic.activities.activitiesPassed.length > 0
                        ? statistic.activities.activitiesPassed[0].count
                        : 0}
                    </td>
                    <td>
                      {statistic.activities.activitiesPending &&
                      statistic.activities.activitiesPending.length > 0
                        ? statistic.activities.activitiesPending[0].count
                        : 0}
                    </td>
                    <td>
                      {statistic.activities.activitiesFailed &&
                      statistic.activities.activitiesFailed.length > 0
                        ? statistic.activities.activitiesFailed[0].count
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td>Quiz</td>
                    <td>
                      {statistic.quizzes.quizzesPassed &&
                      statistic.quizzes.quizzesPassed.length > 0
                        ? statistic.quizzes.quizzesPassed[0].count
                        : 0}
                    </td>
                    <td>
                      {statistic.quizzes.quizzesPending &&
                      statistic.quizzes.quizzesPending.length > 0
                        ? statistic.quizzes.quizzesPending[0].count
                        : 0}
                    </td>
                    <td>
                      {statistic.quizzes.quizzesFailed &&
                      statistic.quizzes.quizzesFailed.length > 0
                        ? statistic.quizzes.quizzesFailed[0].count
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ContainerLessons>
            <ContainerLessons>
              <table>
                <thead>
                  <tr>
                    <th> </th>
                    <th>A1</th>
                    <th>A2</th>
                    <th>B1</th>
                    <th>B2</th>
                    <th>C1</th>
                    <th>C2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Oral Exam</td>
                    <td>
                      {statistic.thirdBlock.responsesThird3 &&
                      statistic.thirdBlock.responsesThird3[0].length > 0
                        ? statistic.thirdBlock.responsesThird3[0][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird3 &&
                      statistic.thirdBlock.responsesThird3[1].length > 0
                        ? statistic.thirdBlock.responsesThird3[1][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird3 &&
                      statistic.thirdBlock.responsesThird3[2].length > 0
                        ? statistic.thirdBlock.responsesThird3[2][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird3 &&
                      statistic.thirdBlock.responsesThird3[3].length > 0
                        ? statistic.thirdBlock.responsesThird3[3][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird3 &&
                      statistic.thirdBlock.responsesThird3[4].length > 0
                        ? statistic.thirdBlock.responsesThird3[4][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird3 &&
                      statistic.thirdBlock.responsesThird3[5].length > 0
                        ? statistic.thirdBlock.responsesThird3[5][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td>Written Exam - Open-ended questions</td>
                    <td>
                      {statistic.thirdBlock.responsesThird2 &&
                      statistic.thirdBlock.responsesThird2[0].length > 0
                        ? statistic.thirdBlock.responsesThird2[0][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird2 &&
                      statistic.thirdBlock.responsesThird2[1].length > 0
                        ? statistic.thirdBlock.responsesThird2[1][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird2 &&
                      statistic.thirdBlock.responsesThird2[2].length > 0
                        ? statistic.thirdBlock.responsesThird2[2][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird2 &&
                      statistic.thirdBlock.responsesThird2[3].length > 0
                        ? statistic.thirdBlock.responsesThird2[3][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird2 &&
                      statistic.thirdBlock.responsesThird2[4].length > 0
                        ? statistic.thirdBlock.responsesThird2[4][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird2 &&
                      statistic.thirdBlock.responsesThird2[5].length > 0
                        ? statistic.thirdBlock.responsesThird2[5][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td>Written Exam - Closed-ended questions</td>
                    <td>
                      {statistic.thirdBlock.responsesThird1 &&
                      statistic.thirdBlock.responsesThird1[0].length > 0
                        ? statistic.thirdBlock.responsesThird1[0][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird1 &&
                      statistic.thirdBlock.responsesThird1[1].length > 0
                        ? statistic.thirdBlock.responsesThird1[1][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird1 &&
                      statistic.thirdBlock.responsesThird1[2].length > 0
                        ? statistic.thirdBlock.responsesThird1[2][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird1 &&
                      statistic.thirdBlock.responsesThird1[3].length > 0
                        ? statistic.thirdBlock.responsesThird1[3][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird1 &&
                      statistic.thirdBlock.responsesThird1[4].length > 0
                        ? statistic.thirdBlock.responsesThird1[4][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                    <td>
                      {statistic.thirdBlock.responsesThird1 &&
                      statistic.thirdBlock.responsesThird1[5].length > 0
                        ? statistic.thirdBlock.responsesThird1[5][0].sum.toFixed(
                            2,
                          )
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ContainerLessons>
          </ContainerTicketBodyPending>
        </>
      ) : null}
    </>
  );
}

export default Performance;
