import React, { useState } from 'react';
import Modal from 'react-modal';
import { CollapseSchedule } from '../../Button/CollapseSchedule';
import { parseISO, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Wrapper,
  ContainerText,
  Text,
  Image,
  Button,
  ModalContainer,
} from './styles';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
export function ListLessons({ appointment, fetchAppointments }) {
  const { t } = useTranslation();
  const [showReschedule, setReschedule] = useState(false);

  function closeModal() {
    setReschedule(false);
  }
  async function cancelClass() {
    await api.patch(`/appointments/cancel/${appointment._id}`);

    toast(t('yourLessonHasBeenCanceled2'), { type: 'success' });
    fetchAppointments();
  }

  return (
    <Container>
      <Wrapper top>
        <Image src={appointment.teacher?.avatarURL} />
        <ContainerText>
          <Text name>
            {appointment.teacher?.fullName ?? appointment.teacher?.name}
          </Text>
          <Text>
            {format(parseISO(appointment.startTime), 'iiii')}{' '}
            {format(parseISO(appointment.startTime), 'dd/MM/yyyy')}
          </Text>
          <Text>
            <span
              className="iconify"
              data-icon="ic:outline-watch-later"
              data-inline="false"
            />
            {format(parseISO(appointment.startTime), 'hh:mm')}
            <span
              className="iconify"
              data-icon="dashicons:arrow-right-alt2"
              data-inline="false"
            />
            {format(parseISO(appointment.endTime), 'hh:mm')}
          </Text>
        </ContainerText>
      </Wrapper>
      <Wrapper bottom>
        <Button
          style={{ marginRight: '20px' }}
          onClick={() => {
            if (!showReschedule) {
              setReschedule(true);
            } else {
              setReschedule(false);
            }
          }}
        >
          <Text>
            <span
              className="iconify"
              data-icon="akar-icons:calendar"
              data-inline="false"
            />
            {t('reagendar')}
          </Text>
        </Button>
        <Button
          onClick={() => {
            cancelClass();
          }}
        >
          <Text>
            <span
              className="iconify"
              data-icon="akar-icons:cross"
              data-inline="false"
            />
            {t('cancelar')}
          </Text>
        </Button>
      </Wrapper>
      {showReschedule && (
        <Modal
          className="react-modal-content"
          overlayClassName="react-modal-overlay"
          isOpen={showReschedule}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          <ModalContainer>
            <CollapseSchedule
              appointment={appointment}
              fetchAppointments={fetchAppointments}
              setShowSchedule={setReschedule}
            />
          </ModalContainer>
        </Modal>
      )}
    </Container>
  );
}
