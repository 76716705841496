import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import IconFlagEngland from '../../../assets/icon_bandeira_inglaterra.png';
import IconFlagEUA from '../../../assets/icon_bandeira_usa.png';
import { api } from '../../../services/api';
import { Container, ContainerText, Text, IconFlag } from './styles';

export function StudentQuestions({ conteudo, methodMission }) {
  const { t } = useTranslation();
  const handleAudioGoogle = async (languageCode, name, text) => {
    try {
      const voice = { languageCode, name, text };

      const { data } = await api.post(`text/synthesize`, {
        voice,
        text,
      });

      var audio = new Audio(data.fileURL);
      audio.play();

      console.log(data);
    } catch (err) {
      console.log(err);
      toast('There is an audio recording issue. Please check your settings.', {
        type: 'error',
      });
    }
  };

  return (
    <Container>
      <header>
        <div className="column">
          <ContainerText>
            <Text>
              <strong>{methodMission?.method}</strong>
            </Text>
            <Text>{methodMission?.mission}</Text>
            <Text finaly>
              {conteudo.title === 'Review' ? t('review') : t('questoes')}
            </Text>
          </ContainerText>
        </div>
      </header>

      {conteudo?.questions?.map((question, index) => (
        <section>
          <Text name="text" list>
            {question.text}
          </Text>
          <div>
            <IconFlag
              src={IconFlagEUA}
              onClick={() =>
                handleAudioGoogle('en-US', 'en-US-Wavenet-A', question.text)
              }
            />
            <IconFlag
              src={IconFlagEngland}
              onClick={() =>
                handleAudioGoogle('en-GB', 'en-GB-Wavenet-A', question.text)
              }
            />
          </div>
        </section>
      ))}
    </Container>
  );
}
