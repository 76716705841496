import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0 0 0.8rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ebe9f1;

  @media (max-width: 468px) {
    display: block;
  }
`;

export const Input = styled.input`
  height: 3.5rem;
  border-radius: 5px;
  border: 1px solid #ebe9f1;
  width: 27.3rem;
  padding: 0 1rem;

  ${(props) =>
    props.date &&
    css`
      width: 16.4rem;
    `}

  @media(max-width: 1280px) {
    height: 3rem;

    ${(props) =>
      props.date &&
      css`
        width: 15.2rem;
      `}
  }

  @media (max-width: 768px) {
    width: 15rem;
  }

  @media (max-width: 468px) {
    width: 100%;
  }
`;

export const ContainerDate = styled.div`
  display: flex;
  align-items: center;

  .datepicker-toggle {
    position: relative;
    height: 3.5rem;
    width: 16rem;
    border-radius: 0.5rem;
    border: 1px solid var(--gray3);

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    color: #243342;
    font-size: 1.5rem;
  }

  .datepicker-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
  }
  .datepicker-input::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  @media (max-width: 468px) {
    margin-top: 0.5rem;
    width: 100%;
  }
`;

export const Text = styled.span`
  margin: 0 0.5rem;
  font-size: 1.5rem;
  color: #243342;

  @media (max-width: 1280px) {
    font-size: 1.2rem;
  }
`;
