import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import IconFlagEngland from '../../../../../assets/icon_bandeira_inglaterra.png';
import IconFlagEUA from '../../../../../assets/icon_bandeira_usa.png';
import { api } from '../../../../../services/api';
import * as C from './styles';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

let audio = null;

export const All = conteudo => {
  const [currentContent, setCurrentContent] = useState(0);
  const audioEl = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [backToTheBeginning, setBackToTheBeginning] = useState(false);
  const { t } = useTranslation();

  const handleAudioGoogle = async (
    languageCode,
    name,
    text,
    isReadingComprehension = null,
  ) => {
    try {
      const voice = { languageCode, name };
      const { data } = await api.post(`text/synthesize`, { voice, text });

      if (isReadingComprehension) {
        audioEl.current.src = data.fileURL;
        audioEl.current.play();
        audioEl.current.addEventListener('ended', e => (e.target.src = ''));
      } else {
        if (audio) audio.pause();
        audio = new Audio(data.fileURL);
        audio.play();
      }
    } catch (err) {
      console.log(err);
      toast('There is an audio recording issue. Please check your settings.', {
        type: 'error',
      });
    }
  };

  const handleArrow = ({ key }) => {
    if (key === 'ArrowLeft') {
      document.querySelector('.arrow-left')?.click();
    }

    if (key === 'ArrowRight') {
      document.querySelector('.arrow-right')?.click();
    }
  };

  const handleArrowClick = e => {
    if (e === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return;
        }

        return prevState - 1;
      });
    }

    if (e === 'ArrowRight') {
      setCurrentContent(prevState => {
        if (prevState === conteudo.conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });
    }
  };

  const handleShowDialog = value => {
    setIsOpen(value);
  };

  useEffect(() => {
    window.addEventListener('keyup', handleArrow);

    return () => {
      window.removeEventListener('keyup', handleArrow);
    };
  }, []);

  useEffect(() => {
    if (conteudo.conteudo[currentContent]?.vocabulary) {
      // O conteúdo é o primeiro, então pega o ponteiro
      if (currentContent === 0) {
        setBackToTheBeginning(currentContent);
      } else if (!conteudo.conteudo[currentContent - 1]?.vocabulary) {
        // O conteúdo atual é um vocabulário mas o anterior não, então pega o ponteiro
        setBackToTheBeginning(currentContent);
      }
    }

    if (isOpen && !conteudo.conteudo[currentContent]?.vocabulary) {
      setIsOpen(false);
    }
  }, [conteudo, currentContent]);

  return (
    <C.Container>
      <header>
        <div className="column">
          {!!conteudo.conteudo[currentContent]?.vocabulary &&
            !conteudo.conteudo[currentContent + 1]?.vocabulary && (
              <C.Button onClick={() => setCurrentContent(backToTheBeginning)}>
                {' '}
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-left-alt2"
                  data-inline="false"
                />{' '}
              </C.Button>
            )}

          {currentContent === conteudo.conteudo.length - 1 && (
            <C.Button onClick={() => setCurrentContent(0)}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </C.Button>
          )}

          <C.ContainerText>
            <C.Text>
              <strong>
                {conteudo.conteudo[currentContent]?.method?.title}
              </strong>
            </C.Text>
            <C.Text>{conteudo.conteudo[currentContent]?.mission?.title}</C.Text>
            <C.Text finaly>{t('all')}</C.Text>
          </C.ContainerText>
        </div>
        <div className="column">
          {currentContent !== 0 && (
            <C.Button
              left
              onClick={() => handleArrowClick('ArrowLeft')}
              className="arrow-left"
            >
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </C.Button>
          )}
          {currentContent !== conteudo.conteudo.length - 1 && (
            <C.Button
              finaly
              onClick={() => handleArrowClick('ArrowRight')}
              className="arrow-right"
            >
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
                data-inline="false"
              />{' '}
            </C.Button>
          )}
        </div>
      </header>

      {conteudo.conteudo[currentContent]?.vocabulary && (
        <>
          {isOpen &&
            conteudo.conteudo[currentContent]?.vocabulary?.fileType.indexOf(
              'image',
            ) > -1 && (
              <C.ContainerImageFullScreen>
                <C.WrapperHeaderImageFullScreen>
                  <C.TitleImageFullScreen>
                    {conteudo.conteudo[currentContent]?.title}
                  </C.TitleImageFullScreen>
                </C.WrapperHeaderImageFullScreen>
                <C.WrapperButtonClose
                  type="button"
                  onClick={() => handleShowDialog(false)}
                >
                  <AiOutlineClose size={30} />
                </C.WrapperButtonClose>

                <C.ImageFull
                  src={conteudo.conteudo[currentContent]?.vocabulary?.fileURL}
                  alt={conteudo.conteudo[currentContent]?.title}
                />
              </C.ContainerImageFullScreen>
            )}

          {conteudo.conteudo[currentContent]?.vocabulary?.fileType.indexOf(
            'image',
          ) > -1 && (
            <C.ContainerImage>
              <C.Image
                onClick={() => handleShowDialog(true)}
                src={conteudo.conteudo[currentContent]?.vocabulary?.fileURL}
                alt={conteudo.conteudo[currentContent]?.title}
              />
            </C.ContainerImage>
          )}

          {conteudo.conteudo[currentContent]?.vocabulary?.fileType.indexOf(
            'video',
          ) > -1 && (
            <center style={{ height: 'calc(100vh - 325px)' }}>
              <video
                style={{ maxWidth: '100%', height: '100%' }}
                src={conteudo.conteudo[currentContent]?.vocabulary?.fileURL}
                type="video/mp4"
                controls
              />
            </center>
          )}

          <footer>
            <C.IconFlag
              vocabulary
              src={IconFlagEUA}
              onClick={() =>
                handleAudioGoogle(
                  'en-US',
                  'en-US-Wavenet-A',
                  conteudo.conteudo[currentContent]?.title,
                )
              }
            />
            <C.Text name="text" footer>
              {conteudo.conteudo[currentContent]?.title}
            </C.Text>
            <C.IconFlag
              vocabulary
              src={IconFlagEngland}
              onClick={() =>
                handleAudioGoogle(
                  'en-GB',
                  'en-GB-Wavenet-A',
                  conteudo.conteudo[currentContent]?.title,
                )
              }
            />
          </footer>
        </>
      )}

      {conteudo.conteudo[currentContent]?.questions?.map(question => (
        <>
          {question.text && (
            <C.SectionQuestion>
              <C.Text name="text" list>
                {question.text}
              </C.Text>
              <div>
                <C.IconFlag
                  question
                  src={IconFlagEUA}
                  onClick={() =>
                    handleAudioGoogle('en-US', 'en-US-Wavenet-A', question.text)
                  }
                />
                <C.IconFlag
                  question
                  src={IconFlagEngland}
                  onClick={() =>
                    handleAudioGoogle('en-GB', 'en-GB-Wavenet-A', question.text)
                  }
                />
              </div>
            </C.SectionQuestion>
          )}
        </>
      ))}

      {conteudo.conteudo[currentContent]?.readingComprehension && (
        <>
          {conteudo.conteudo[currentContent]?.readingComprehension.text && (
            <>
              <C.SectionReadingComprehension>
                <article name="text">
                  {
                    conteudo.conteudo[currentContent]?.readingComprehension
                      ?.text
                  }
                </article>
              </C.SectionReadingComprehension>

              <footer>
                <C.IconFlag
                  readingComprehension
                  src={IconFlagEUA}
                  onClick={() =>
                    handleAudioGoogle(
                      'en-US',
                      'en-US-Wavenet-A',
                      conteudo.conteudo[currentContent]?.readingComprehension
                        ?.text,
                      true,
                    )
                  }
                />
                <audio ref={audioEl} type="audio/mp3" controls />
                <C.IconFlag
                  readingComprehension
                  src={IconFlagEngland}
                  onClick={() =>
                    handleAudioGoogle(
                      'en-GB',
                      'en-GB-Wavenet-A',
                      conteudo.conteudo[currentContent]?.readingComprehension
                        ?.text,
                      true,
                    )
                  }
                />
              </footer>
            </>
          )}
        </>
      )}
    </C.Container>
  );
};
