import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './i18n';

console.log = () => {}
console.error = () => {}
console.debug = () => {}

ReactDOM.render(<App />, document.getElementById('root'));