/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import { Container, Input, Left, Right, TextArea } from './styles';
import './styles/styles.css';

const ModalPresentation = ({ open, closed, urlVideo, bio, linked }) => (
  <Modal
    isOpen={open}
    contentLabel="Example Modal"
    onRequestClose={closed}
    overlayClassName="Overlay"
    className="Modal"
  >
    <Container>
      <Left>
        <ReactPlayer controls url={urlVideo} className="video" />
      </Left>
      <Right>
        <TextArea placeholder="Bio" value={bio} readOnly />
        <a href={linked} target="_blank" rel="noopener noreferrer">
          <Input type="text" placeholder="Linkedin" value={linked} readOnly />
        </a>
      </Right>
    </Container>
  </Modal>
);

export default ModalPresentation;
