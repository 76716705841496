import React from 'react';

import { Container, Label, Input, Span } from './styles';

function Radio({ checked, onChange }) {
  
  return (
    <Container>
      <Label>
        <Input type="checkbox" checked={checked} onChange={onChange} />
        <Span slider className="slider" />
      </Label>

      <Label>
        <Input type="checkbox" />
        <Span sliderRound className="slider-round" checked={checked} />
      </Label>
    </Container>
  );
}

export default Radio;
