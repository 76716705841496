import React, { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Container, ContainerNav, Nav, ContainerAbasNav } from './styles';
import { ClassReports } from '../ClassReports';
import { CouponReports } from '../CouponReports';
import { FinancialReports } from '../FinancialReports';
import { FinancialReportsStudents } from '../FinancialReportsStudents';
import { WithdrawalReports } from '../WithdrawalReports';
import { isAfiliate, isTeacher, useAuth } from '../../../hooks/AuthProvider';

function NavProfile({ match }) {
  const { typeLogin, user } = useAuth();
  const { t } = useTranslation();
  useEffect(() => {
    console.log(t('classesReport'));
  }, [t]);
  return (
    <Container typeLogin={typeLogin}>
      <ContainerNav>
        <ContainerAbasNav>
          {!isAfiliate() && <Nav to={`${match.path}/classreports`}>{t('classesReport')}</Nav>}
          {typeLogin && (
            <Nav to={`${match.path}/finalcialreportsstudents`}>
              {t('financialReport')}
            </Nav>
          )}
          {isTeacher() && (
            <Nav to={`${match.path}/finalcialreports`}>
              {t('financialReport')}
            </Nav>
          )}
          {!typeLogin && (
            <Nav to={`${match.path}/withdrawalreports`}>
              {t('withdrawalReport')}
            </Nav>
          )}
          {(isTeacher() || isAfiliate())  && (
            <Nav to={`${match.path}/couponreports`}>{'Sales'}</Nav>
          )}
        </ContainerAbasNav>
        <Switch>
          <Route
            exact
            path={`${match.url}/`}
            render={() => <Redirect to={`${match.url}/classreports`} />}
          />

          <Route
            path={`${match.path}/classreports`}
            component={props => <ClassReports {...props} />}
          />
          <Route
            path={`${match.path}/finalcialreports`}
            component={props => <FinancialReports {...props} />}
          />
          <Route
            path={`${match.path}/finalcialreportsstudents`}
            component={props => <FinancialReportsStudents {...props} />}
          />

          <Route
            path={`${match.path}/withdrawalreports`}
            component={props => <WithdrawalReports {...props} />}
          />
          <Route
            path={`${match.path}/couponreports`}
            component={props => <CouponReports {...props} />}
          />
        </Switch>
      </ContainerNav>
    </Container>
  );
}

export default NavProfile;
