import styled, { css } from 'styled-components';

import ImageBanner from '../../../assets/back_banner_top.png';

interface ButtonsProps {
  schedule?: boolean;
  cancel?: boolean;
}

export const Container = styled.div`
  background: #f9f9f9;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.2rem;
`;

export const ContainerBanner = styled.div`
  height: 24rem;
  min-height: 24rem;
  width: 100%;
  border-radius: 0.4rem;
  background-color: #0c231f;
  background-image: url(${ImageBanner});
  background-repeat: no-repeat;
  background-size: auto;
  object-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img``;

export const TitleBanner = styled.div`
  display: flex;
  margin: 1rem 0;
  color: #fff;
  font-weight: 500;
  font-size: 2.2rem;
  width: 40rem;
  margin-left: 3rem;
  line-height: 2.5rem;

  @media (max-width: 924px) {
    font-size: 1.8rem;
  }

  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`;

export const ContainerActions = styled.div`
  height: 7rem;
  width: 100%;
  background: #083059;
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  margin-top: 1.2rem;
  padding: 0 2rem;
  position: relative;

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    gap: 0.6rem;
    justify-items: center;
    padding: 1rem;
  }
`;

export const ContainerHour = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageStudent = styled.img`
  margin-right: 1rem;
`;

export const Text = styled.span`
  color: #fff;
  font-size: 1.8rem;
`;

export const ContainerUser = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
`;

export const ImageProfile = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

export const ContainerTextsProfile = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.6rem;
  }

  span + span {
    font-size: 1.4rem;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-self: flex-end;
  position: absolute;
  right: 0;

  @media (max-width: 970px) {
    position: revert;
    justify-self: center;
  }

  @media (max-width: 450px) {
    display: grid;
    width: 100%;
    gap: 1rem;
  }
`;

export const Button = styled.button<ButtonsProps>`
  height: 4rem;
  width: 100% auto;
  color: #fff;
  background: #243342;
  padding: 0 4rem;
  margin: 0 1rem;
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .iconify {
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }

  ${(props) => props.schedule
    && css`
      background: #fff;
      color: #243342;
      padding: 0 2rem;

      .iconify {
        margin-right: 0.5rem;
        margin-left: 0;
      }
    `}

  ${(props) => props.cancel
    && css`
      background: #9e0d0d;

      .iconify {
        margin-right: 0.5rem;
        margin-left: 0;
      }
    `}
`;

export const ContainerShowComponents = styled.div`
  height: 30rem;
  width: 100%;
`;

export const ContainerMessage = styled.div`
  height: 46rem;
  margin-top: 1.2rem;

  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;

  .header-message {
    height: 5rem;
    width: 100%;
    background: #f9f9f9;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin-bottom: 1rem;

    span {
      font-size: 1.2rem;
      font-family: 'Montserrat';
      color: #5e5873;
    }

    .iconify {
      font-size: 1.5rem;
      color: #5e5873;
      cursor: pointer;
    }
  }
`;
