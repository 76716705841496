import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import LogoImage from '../../assets/icon_logo_login.png';
import Footer from '../../components/Footer';
import { useQuery } from '../../hooks/useQuery';
import {
  Container,
  Logo,
  SpanTerms,
  Wrapper,
  Span,
  Topic,
  ContainerImage,
  ContainerTerms,
  Span2,
  BarLine,
} from './styles';

export const Terms = () => (
  <Container>
    <Wrapper>
      <ContainerImage>
        <Logo src={LogoImage} />
      </ContainerImage>
      <Span>
        Seja bem-vindo a
        {' '}
        <strong>Table Talks</strong>
        ! Leia com atenção os
        Termos e Condições de Uso (“Termos e Condições”, “Termos” ou
        simplesmente “Termo”) do Aplicativo antes de iniciar a sua
        experiência. A discordância em relação a qualquer termo e condição
        implica a não utilização da Plataforma. Ao clicar no botão “Li e
        Aceito os Termos e Condições”, o usuário declara expressamente que
        está de acordo com todos os termos e condições adiante estabelecidos,
        que são igualmente aplicáveis às ofertas gratuitas e pagas.
      </Span>
      <Span>
        {' '}
        A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        é uma plataforma web (“Aplicativo” ou
        simplesmente “Plataforma”) editado por:
        {' '}
        <strong>TABLE TALKS PUB ESCOLA LTDA</strong>
        , pessoa jurídica de
        direito privado, inscrita no Cadastro Nacional da Pessoa Jurídica do
        Ministério da Fazenda (CNPJ/MF) sob o n° 30.553.280/0001- 26, sediada
        na cidade de Campinas, São Paulo, na Rua Conceição, 233, Sala 916,
        Centro, CEP 13.010-050.
      </Span>
      <ContainerTerms>
        <SpanTerms>Termos e Condições</SpanTerms>
      </ContainerTerms>
      <Topic>1. Serviços</Topic>
      <Span>
        1.1. A utilização da plataforma da
        {' '}
        <strong>Table Talks</strong>
        {' '}
        está
        subordinada à aceitação pura e simples dos Termos e Condições de uso.
        O intuito da Plataforma é unir professores e alunos interessados no
        ensino e aprendizagem da Língua Inglesa. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        é apenas a mediadora da relação, não se
        responsabilizando por questões adjacentes à intermediação.
      </Span>
      <Span>
        1.2. Por meio do
        {' '}
        <strong>Table Talks</strong>
        , o aluno poderá
        contratar o professor particular de sua escolha e com localização mais
        próxima para aulas particulares presenciais. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        irá disponibilizar o conteúdo pedagógico
        gratuitamente aos usuários da Plataforma.
      </Span>
      <Span>
        1.3. A utilização do
        {' '}
        <strong>Table Talks</strong>
        {' '}
        é restrita às
        pessoas físicas, ressalvado se o instrutor de idiomas for enquadrado
        enquanto Microempreendedor Individual (MEI). Desde logo, fica
        estabelecido que o cadastro que não se enquadrar nestas condições será
        removido sem aviso prévio e sem a possibilidade de reembolso de
        qualquer valor.
      </Span>
      <Span>
        1.4. Fica esclarecido, desde já, que o usuário da plataforma se
        declara ciente de que as informações prestadas pelo professor
        cadastrado serão verificadas pelo aluno interessado, de maneira que a
        <strong>Table Talks</strong>
        {' '}
        não se responsabiliza pela verificação da
        descrição constante no perfil do professor.
      </Span>
      <Span>
        1.5. O usuário professor declara que agirá moral e eticamente quando
        da contratação e execução dos serviços, prezando sempre a aprendizagem
        e bem-estar do aluno. O usuário aluno, por sua vez, compromete-se a
        agir de maneira que possibilite o bom fluxo da aula contratada,
        prezando pelo bem-estar do professor. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não responsabiliza por qualquer situação de desconforto ou desafeto
        entre os usuários.
      </Span>
      <Span>
        1.6. Os serviços da
        {' '}
        <strong>Table Talks</strong>
        {' '}
        são limitados à
        intermediação da relação professor e aluno, bem como fornecimento de
        material pedagógico gratuito. A atuação da plataforma não se estende à
        verificação de pormenores, como método de ensino aplicado pelo
        professor, didática, formação e capacitação do profissional, bem como
        análise do comportamento do aluno, histórico de aprendizagem,
        capacidade de absorção de conteúdo e outros.
      </Span>
      <Topic>2. Cadastro</Topic>
      <Span>2.1. Regras Gerais</Span>
      <Span>
        2.1.1 Para se cadastrar na plataforma, o usuário deverá fornecer suas
        informações e preencher corretamente os campos obrigatórios, com o
        e-mail, nome, idade, número de celular, escolaridade, data de
        nascimento, sexo, disponibilidade para as aulas, palavra-passe,
        endereço, método de pagamento válido, e outros campos que porventura
        constarem quando do cadastramento.
      </Span>
      <Span>
        2.1.2. Preenchidos todos os campos solicitados, o usuário receberá um
        e-mail gerado automaticamente com um hiperlink, que concederá a
        autenticação da Conta. Depois de autenticada a Conta, o usuário terá
        permissão para acessar o conteúdo e serviços do aplicativo. A cada
        usuário poderá ser atribuída apenas uma Conta, sob pena de exclusão da
        plataforma e aplicação das demais penalidades previstas.
      </Span>
      <Span>
        2.1.3. A qualquer tempo e sem prévio aviso, a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        poderá verificar a veracidade das
        informações disponibilizadas pelo usuário e, inclusive, removê-lo da
        Plataforma caso haja alguma irregularidade.
      </Span>
      <Span>
        2.1.4. Em caso de informações incorretas, inverídicas, não confirmadas
        ou, na hipótese de o usuário se negar a corrigir os dados erroneamente
        apresentados ou a comprová-los por meio de documentação hábil, a Table
        Talks se reserva no direito de: (a) não concluir o cadastro em
        andamento; (b) bloquear o perfil do usuário temporariamente; (c)
        remover a Conta do usuário; (d) impedir que o usuário se cadastre
        novamente na Plataforma. A decisão da empresa será proporcional à
        gravidade da conduta do usuário.
      </Span>
      <Span>
        2.1.5. Concluído o cadastro, o usuário terá acesso aos serviços por
        meio de login e senha, sendo a Conta cadastrada
        {' '}
        <strong>
          <u>pessoal</u>
        </strong>
        {' '}
        e
        {' '}
        <strong>
          <u>intransferível</u>
        </strong>
        . O usuário se obriga a manter o cadastro atualizado e, sempre que
        necessário, atender às solicitações da plataforma, inclusive aqueles
        referentes à atualização do aplicativo.
      </Span>
      <Span>
        2.1.6. O usuário é responsável por todas as atividades desempenhadas
        na sua Conta e consente em sempre, além de manter os dados corretos e
        verídicos, prezar pela
        {' '}
        <strong>
          <u>confidencialidade</u>
        </strong>
        {' '}
        das informações, especialmente do nome de usuário e da senha de sua
        Conta.
      </Span>
      <Span>2.2. Cadastro de Professores</Span>
      <Span>
        2.2.1. A
        {' '}
        <strong>Table Talks</strong>
        , por meio de seu aplicativo,
        visa unir professor e alunos para o ensino e aprendizagem da Língua
        Inglesa. Assim sendo, é
        {' '}
        <strong>
          <u>imprescindível</u>
        </strong>
        {' '}
        que o usuário professor tenha know-how no referido idioma, especial e
        relativamente ao domínio gramatical, proficiência e prolação
        linguística.
      </Span>
      <Span>
        2.2.2. Para se cadastrar na plataforma como professor, além de possuir
        diploma que o habilite no ensino da Língua Inglesa, o usuário deve ter
        {' '}
        <strong>
          <u>capacidade civil plena</u>
        </strong>
        {' '}
        e prestar informações verídicas quando do cadastro na plataforma, sob
        pena de ser responsabilizado civil e penalmente.
      </Span>
      <Span>
        2.2.3. Nos termos da disposição anterior, é vedado o cadastro de
        professores com idade inferior a 18 (dezoito) anos. A qualquer tempo e
        sem prévio aviso, a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        poderá 1 3 verificar
        a veracidade das informações disponibilizadas pelo usuário e,
        inclusive, removê-lo da plataforma caso haja alguma irregularidade,
        hipótese em que não haverá o reembolso de qualquer valor.
      </Span>
      <Span>
        2.3.4. Para o registro da Conta na plataforma, além de observar as
        diretrizes aqui dispostas, o usuário professor deve se atentar ao
        disposto na seção intitulada
        {' '}
        <u>Regras Gerais</u>
        .
      </Span>
      <Span>2.3. Cadastro de Alunos</Span>
      <Span>
        2.3.1. Para utilizar grande parte dos Serviços, inclusive acesso ao
        conteúdo, o usuário aluno deve ter pelo menos 18 (dezoito) anos para
        registrar uma Conta.
      </Span>
      <Span>
        2.3.2. Usuários com idade igual ou superior a 12 (doze) anos poderão
        registrar-se e manter Conta no aplicativo, desde que possua
        consentimento ou representação do (s) responsável (eis) legal (ais),
        conforme procedimento aplicável em cada caso. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não se responsabiliza por cadastros
        efetuados de má-fé, com informações e dados falsos, prestados com o
        intuito de ludibriar os desenvolvedores do aplicativo, a fim de se
        obter uma Conta sem consentimento ou autorização do (s) responsável
        (eis) legal (ais).
      </Span>
      <Span>
        2.3.3. Para o registro da Conta na plataforma, além de observar as
        diretrizes aqui dispostas, o usuário aluno deve se atentar ao disposto
        na seção intitulada
        {' '}
        <u>Regras Gerais</u>
        .
      </Span>
      <Topic>3. Licença</Topic>
      <Span>
        3.1. Sujeito ao cumprimento destes Termos e Condições, a
        {' '}
        <strong>Table Talks</strong>
        outorga ao usuário uma licença limitada, intransferível, revogável e
        não sub-rogável, para que ele tenha acesso ao aplicativo em seu
        dispositivo pessoal, bem como acesso ao conteúdo, informação ou
        qualquer material correlato disponibilizado na plataforma, com
        finalidade única de uso do Serviço desejado e autorizado (ensino e
        aprendizagem). Em nenhuma hipótese será admitido o
        {' '}
        <strong>
          <u>uso comercial</u>
        </strong>
        {' '}
        dos símbolos que remetem à
        {' '}
        <strong>Table Talks</strong>
        , bem como de
        qualquer material cedido gratuita ou onerosamente,
      </Span>
      <Topic>4. Propriedade Intelectual e Restrições</Topic>
      <Span>
        4.1. Os conteúdos, informações e materiais correlatos disponibilizados
        na plataforma gozam de proteção legal, inclusive, mas não se limitando
        à proteção constitucional. Assim, o usuário que violar as diretrizes
        legais sobre a Propriedade Intelectual deverá suportar as
        consequências jurídicas decorrentes da violação.
      </Span>
      <Span>
        4.2. Todas as manifestações linguísticas, em formato de texto ou
        imagens, bem como todos os conteúdos com direito de autor e obra
        cedidos pela
        {' '}
        <strong>Table Talks</strong>
        {' '}
        são protegidas por direito
        de autor. Qualquer uso que ultrapasse o limite contratualmente
        estabelecido deverá ser permitido pela cedente, sob pena de se
        incorrer em ilícito contratual, sem prejuízo das demais sanções legais
        existentes.
      </Span>
      <Span>
        4.3.
        {' '}
        <strong>
          <u>O usuário não pode, além das demais vedações</u>
        </strong>
        : (a) remover qualquer aviso que informe propriedade intelectual dos
        Serviços; (b) reproduzir, remodelar, realizar obras derivadas,
        distribuir, licenciar, sublicenciar, locar, vender, revender,
        transferir, exibir, veicular, transmitir ou, de qualquer outra forma,
        beneficiar-se dos Serviços, exceto da forma expressamente admitida
        pela
        {' '}
        <strong>Table Talks</strong>
        ; (c) promover mudanças com intuito
        de acoplar, separar e/ou promover engenharia reversa em qualquer parte
        dos Serviços; (d) fazer ou 1 4 lançar quaisquer programas ou scripts,
        a fim de fazer indexação, scraping, pesquisa ou, de qualquer outra
        maneira, tentar obter dados de qualquer fração dos Serviços ou, ainda,
        prejudicar, de qualquer modo, a funcionalidade e/ou operação referente
        aos Serviços; (e) tentar acesso não autorizado ou, ainda, burlar o
        sistema ou rede correlata.
      </Span>
      <Span>
        4.4. Caso o conteúdo, informação e material correlato seja fornecido
        na plataforma através de uma licença
        {' '}
        <strong>Creative Commons</strong>
        1, o compartilhamento estará sujeito aos termos e condição da licença
        <strong>Creative Commons</strong>
        {' '}
        relativamente aplicável.
      </Span>
      <Span>
        4.5. O uso dos símbolos que remetam à
        {' '}
        <strong>Table Talks</strong>
        ,
        tais como, marca comercial, marca, desenhos, nomes comerciais, entre
        outros, somente poderá ser feito com expressa concordância escrita da
        empresa.
      </Span>
      <Topic>5. Titularidade</Topic>
      <Span>
        5.1. Todos os Serviços e direitos são de titularidade da
        {' '}
        <strong>Table Talks</strong>
        ou de suas respectivas licenciadoras, se for o caso, e assim
        permanecerão. Os Termos e Condições de uso dos Serviços não outorgam
        aos usuários nem conferem direitos excedentes aos estabelecidos, de
        modo que é
        {' '}
        <strong>
          <u>vedado</u>
        </strong>
        , especialmente ao usuário professor, usar ou, de qualquer forma,
        fazer referência a nomes societários, logotipos, nome de produtos ou
        Serviços, marcas comerciais, entre outros.
      </Span>
      <Topic>6. Obrigações do Usuário</Topic>
      <Span>6.1. Obrigações Comuns</Span>
      <Span>
        6.1.1. São obrigações comuns ao usuário professor e usuário aluno: não
        transferir a Conta a terceiros, nem ceder conteúdo, informações e
        materiais correlatos, prezando pela confidencialidade e respeito aos
        direitos autorais. A inobservância às determinações referentes ao
        resguardo de dados pode ensejar exclusão da Conta registrada, sem
        prejuízo das demais penalidades previstas.
      </Span>
      <Span>
        6.1.2. Os usuários devem manter os dados atualizados, agindo conforme
        dita a boa-fé, apresentando sempre informações e dados verídicos e,
        prontamente, corrigir ou comprovar dados dúbios.
      </Span>
      <Span>
        6.1.3. Os usuários, em suas interrelações, especialmente no que diz
        respeito à conexão professor e aluno, devem agir respeitosa e
        polidamente, apresentando dados e informações verídicas, prezando pelo
        ensino e aprendizagem da Língua Inglesa.
      </Span>
      <Span>
        6.1.4. É obrigação do professor e do aluno proverem os seus meios de
        deslocamento para as aulas, bem como estipular, conjuntamente, em qual
        local e horário elas serão realizadas. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não se responsabiliza por qualquer logística e custeio relacionados ao
        transporte e deslocamento dos usuários do aplicativo.
      </Span>
      <Span>
        6.1.5. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        somente fará devolução de
        valores com fundadas justificativas, de maneira que a empresa não se
        responsabiliza pelo estorno de valores de aula marcada e não realizada
        por culpa do aluno, ou, ainda, não haverá repasse do valor da aula em
        que o professor não compareceu injustificadamente, sem apresentar
        comunicação prévia ao aluno.
      </Span>
      <BarLine />
      <Span2>
        ¹As licenças
        {' '}
        <i>Creative Commons</i>
        {' '}
        são várias licenças públicas que
        permitem a distribuição gratuita de uma obra protegida por direitos
        autorais e são usadas quando um autor quer dar às pessoas o direito de
        compartilhar, usar e construir sobre um trabalho que ele criou.
      </Span2>
      <Span>
        Em ambos os casos, as Partes poderão estabelecer combinado de
        reposição da aula perdida, mantendo-se o fluxo normal do Aplicativo
        quanto ao pagamento e repasses, de maneira que a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não se responsabiliza nesse sentido,
        tampouco arcará com eventuais custos de viagens relacionadas a aula
        cancelada ou a aula remarcada.
      </Span>
      <Span>
        6.1.6. O usuário concorda que, ao assinar os Termos e Condições de Uso
        da Plataforma, terá como obrigação correlata, em relação as outras
        aqui estabelecidas, verificar periodicamente o e-mail cadastrado,
        inclusive a seção de “spam” e ‘lixo eletrônico”, a fim de averiguar o
        recebimento de comunicações da Plataforma. Em nenhuma hipótese, o
        usuário poderá se escusar de suas responsabilidades alegando que não
        houve a devida comunicação.
      </Span>
      <Span>6.2. Obrigações do Professor</Span>
      <Span>
        6.2.1. O professor (“
        <u>Instrutor de Idiomas</u>
        ”) deve agir conforme
        diretrizes éticas da profissão, prezando pela aprendizagem do aluno,
        envidando esforços didática metodicamente, a fim de que o aluno
        consiga absorver satisfatoriamente o conteúdo, alcançando a
        proficiência e prolação linguística, além de boa apreensão gramatical.
        O professor se compromete a comparecer às aulas no horário combinado,
        conduzi-las com respeito e dedicação, avisando com antecedência caso
        não seja possível cumprir com o combinado de classe. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não se responsabiliza pela relação além
        do aplicativo, sendo o seu papel limitado à intermediação. Entretanto,
        fica esclarecido que o professor que receber reclamações justificadas
        e baixa pontuação no aplicativo poderá ser privado de usar a
        plataforma, seja pode meio de bloqueio do perfil ou mediante não
        aprovação de novo cadastro, nos termos das Regras Gerais.
      </Span>
      <Span>
        6.2.2. É obrigação do professor utilizar o material pedagógico
        disponibilizado na plataforma. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não se
        responsabiliza por qualquer conteúdo, informação ou material correlato
        estranho ao aplicativo, que for utilizado pessoal e exclusivamente
        pelo professor durante a execução das aulas. O aluno pode aceitar ou
        recusar a oferta de materiais estranhos aos disponibilizados. A
        negativa de uso dos materiais disponíveis, se reclamada, constitui
        motivo para aplicação das sanções contratuais previstas nestes Termos
        e Condições de uso, inclusive remoção do perfil do instrutor de Língua
        Inglesa.
      </Span>
      <Span>
        6.2.3. O professor se obriga a negar aula ao menor de 18 (dezoito)
        anos se ele não estiver devidamente acompanhado do responsável legal.
        A inobservância desta obrigação pode ensejar consequências contratuais
        e legais, conforme disposições deste Termo, inclusive remoção
        permanente do instrutor da Plataforma, sem a possibilidade de
        reembolso dos valores dispendidos, ainda que a conduta infratora não
        tenha ocorrido reiteradamente.
      </Span>
      <Span>
        6.2.4. O professor poderá escolher o local das aulas e quantas aulas
        irá lecionar durante a semana, considerando que pode trabalhar no
        mínimo 01 (uma) hora semanal.
      </Span>
      <Span>
        6.2.5. É obrigação do professor ser devidamente
        {' '}
        <strong>
          <u>habilitado</u>
        </strong>
        {' '}
        para o ensino da Língua Inglesa, demonstrando possuir know-how
        suficiente, especial e relativamente a gramática, proficiência e
        prolação linguística.
      </Span>
      <Span>6.3. Obrigações do Aluno</Span>
      <Span>
        6.3.1. O aluno deve agir ética e respeitosamente, colaborando para que
        o instrutor de Língua Inglesa consiga dar sequência a aula, prezando
        por uma relação amigável. O aluno se compromete, ainda, a cumprir o
        cronograma estabelecido pelo professor, respeitar o dia e 1 6 horário
        da aula, avisando com antecedência caso não seja possível comparecer a
        classe no dia ou horário combinados.
      </Span>
      <Span>
        6.3.2. O aluno com idade inferior a 18 (dezoito) anos deverá, durante
        a execução de toda a aula particular, estar acompanhado do (s)
        responsável (eis) legal (ais), ou, na falta deste (s), de pessoa que o
        represente. A não observância desta condição pode ensejar o bloqueio
        do perfil do aluno ou outra medida mais gravosa, nos termos das Regras
        Gerais. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não se responsabiliza pela
        fiscalização sobre a presença do responsável, mas deixa claro, desde
        que já, que o professor poderá efetuar reclamações diretas junto a
        empresa, que atuará incisivamente para resolução do impasse.
      </Span>
      <Span>
        6.3.3. O aluno se compromete a usar o material pedagógico
        disponibilizado no aplicativo, podendo recusar e/ou denunciar caso o
        professor ofereça ou force o uso de material estranho ao
        disponibilizado na plataforma. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não se
        responsabiliza por materiais, informações ou conteúdos que não constem
        na plataforma de ensino.
      </Span>
      <Topic>
        7. Obrigações da
        {' '}
        <strong>Table Talks</strong>
      </Topic>
      <Span>
        7.1. Disponibilizar conteúdo atualizado e idôneo, criando espaço
        virtual que permita ao usuário devidamente cadastrado utilizar os
        serviços da plataforma satisfatoriamente, encontrando professores dos
        mais variados perfis para a contratação de aulas particulares
        presenciais.
      </Span>
      <Span>
        7.2. Atuar como mediadora da relação professor e aluno,
        disponibilizando, além dos materiais didáticos, os meios necessários à
        contratação de aulas e efetivação do pagamento, de modo que a
        prestação flua do aluno para o professor, mediante intermédio da Table
        Talks.
      </Span>
      <Span>
        7.3. Proteger, por meio de armazenamento em servidores ou quaisquer
        outros meios magnéticos, a confidencialidade de todas as informações e
        cadastros relativos aos usuários, assim como os valores atinentes às
        operações financeiras decorrentes da operacionalização dos Serviços. A
        <strong>Table Talks</strong>
        {' '}
        não se responderá pela reparação de
        prejuízos que possam decorrer de apreensão ou cooptação de dados por
        parte de terceiros que, rompendo os sistemas de segurança, consigam
        acessar esses dados.
      </Span>
      <Topic>8. Dinâmica de funcionamento da Plataforma</Topic>
      <Span>
        8.1. A dinâmica de funcionamento da Plataforma pauta-se na relação
        intermediadora que
        {' '}
        <strong>Table Talks</strong>
        {' '}
        tem com os usuários.
        Nesse sentido, os Serviços se dividem em dois vieses, a depender da
        posição do usuário no vínculo estabelecido.
      </Span>
      <Span>
        a)
        {' '}
        <strong>
          <u>A plataforma do professor possibilita</u>
        </strong>
        : cadastro de perfil, horas de trabalho (de acordo com a
        disponibilidade do professor), acesso ao conteúdo pedagógico, correção
        dos exercícios dos alunos, controle pedagógico e financeiro dos
        alunos, e emissão de certificados.
      </Span>
      <Span>
        b)
        {' '}
        <strong>
          <u>A plataforma do aluno possibilita</u>
        </strong>
        : cadastro de perfil, agendamento de aulas, acesso ao conteúdo
        pedagógico, correção dos exercícios, controle pedagógico e financeiro,
        e emissão de certificados.
      </Span>
      <Span>
        8.2.
        {' '}
        <u>Registro e Publicação de anúncios</u>
        . Os professores podem se
        registrar e postar suas informações gratuitamente através de texto
        escrito, inclusive no formato de currículo vitæ. Os anúncios não podem
        conter informações inverídicas, ilícitas, errôneas ou ignominiosas e,
        1 7 ademais, os anúncios não poderão conter dados de contato do
        professor, tais como, telefone pessoal ou profissional, e-mail,
        endereço pessoal ou profissional e quaisquer outros dados que visem a
        comunicação direta com aluno, dispensando-se o uso da Plataforma.
        Anúncios que não estiverem de acordo com as diretrizes da Plataforma
        serão excluídos sem prévio aviso, e o usuário infrator poderá sofrer
        as sanções dispostas nas Regras Gerais.
      </Span>
      <Span>
        8.3.
        {' '}
        <u>Ordem dos anúncios</u>
        . Os anúncios aparecerão aos alunos
        levando em consideração uma série de fatores, tais como, a título
        exemplificativo: (i) distância física entre o professor e o aluno;
        (ii) número de aulas lecionadas desde o cadastro na Plataforma; (iii)
        in(existência) de reclamações; (iv) pontuação no Aplicativo; e (v)
        contratação de Plano Premium que garanta maior visibilidade na
        Plataforma. Os anúncios dos professores não ocuparão posição fixa no
        Aplicativo, tendo em vista que o preenchimento dos fatores acima não é
        estático. Fica esclarecido, desde já, que a contratação das aulas é
        opção do aluno e, embora a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        disponibilize
        os anúncios dos professores, ele [aluno] escolherá segundo seus
        próprios critérios de preferência, como didaticidade, know-how do
        professor, localização, compromisso, entre outros. Assim sendo, é
        vedado ao professor atribuir à
        {' '}
        <strong>Table Talks</strong>
        {' '}
        a
        responsabilidade pela obtenção de aulas, pois a empresa age como
        mediadora de relações, e não como fomentadora de perfis individuais,
        cabendo ao professor impulsionar o seu perfil, a fim de se destacar e,
        por conseguinte, angariar mais aulas e recursos.
      </Span>
      <Span>
        8.4.
        {' '}
        <u>Tempo de resposta</u>
        . O aluno escolherá o perfil do professor
        segundo seus critérios de preferência e o professor terá o prazo de 24
        (vinte e quatro) horas, contadas do momento de solicitação, para
        responder o aluno e agendar o dia e local da aula. Se o professor
        solicitado não responder dentro do prazo estabelecido, o aluno
        solicitante receberá uma mensagem da Plataforma, podendo, a seu
        critério: (i) chamar a atenção do professor solicitado por meio de
        mensagem direta; ou (ii) cancelar a aula solicitada, sem a cobrança de
        qualquer valor, e agendar nova aula com professor distinto.
      </Span>
      <Span>
        8.5.
        {' '}
        <u>Cancelamento de Aulas</u>
        . A fim de proteger os usuários da
        Plataforma, não se admite o cancelamento de aulas. Contudo, o usuário
        não ficará sem respaldo no caso de imprevistos, de maneira que: (i) o
        aluno poderá reagendar a aula, dentro do mês ativo, com 12 (doze)
        horas de antecedência, no mínimo; e (ii) o professor poderá promover o
        reagendamento da aula, devendo o aluno ser avisado no prazo de 12
        (doze) horas que antecedem o horário combinado para a realização da
        classe. O professor é obrigado a repor a aula, dentro do mês ativo, e,
        caso não o faça, será obrigado a devolver o valor integral da aula não
        dada.
      </Span>
      <Span>
        8.6.
        {' '}
        <u>Remuneração do Professor</u>
        . O professor receberá o valor da
        hora/aula pago pelo aluno segundo a sua produtividade (número de aulas
        lecionadas), após dedução do valor de eventuais tributos incidentes na
        fonte e da tarifa de 44% (quarenta e quatro por cento), devida à Table
        Talks em razão da utilização da plataforma. O professor receberá pelo
        Serviço prestado após efetivação de aula concluída por transferência
        bancária de acordo com a Política de Transferência de cada banco.
      </Span>
      <Span>
        8.7.
        {' '}
        <u>Aula experimental</u>
        . Para possibilitar a demonstração da
        didática de ensino e a efetiva contratação do professor, a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        exigirá que o professor oferte 01 (uma)
        aula experimental gratuita de, no mínimo, 20 (vinte) minutos. O aluno,
        caso queira, poderá dispensar a realização da aula experimental,
        hipótese que caracterizará consentimento e aceitação tácita da
        metodologia de abordagem e didática de ensino do professor.
      </Span>
      <Topic>9. Informações Públicas e Privadas</Topic>
      <Span>
        9.1.
        {' '}
        <u>Informações do Usuário</u>
        . As informações do usuário
        correspondem a toda e qualquer informação cedida direta ou
        indiretamente pelo usuário à Plataforma, comentários, mensagens
        enviadas dentro do Aplicativo ou e-mail. O único responsável por estas
        informações é o usuário que as forneceu, sendo que a Plataforma age
        como canal passivo de distribuição e publicação das Informações
        Públicas (adiante definidas). A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        isenta-se
        da responsabilidade acerca do conteúdo postado e fornecido pelo
        usuário, especialmente, mas não se limitando, à responsabilidade
        decorrente de propriedade intelectual, trabalhista, privacidade e
        outros. Fica avisado, conforme outros itens deste Termo, que o usuário
        poderá ser acionado pela
        {' '}
        <strong>Table Talks</strong>
        {' '}
        em que caso de
        inveracidade das informações e outras infrações reclamadas, sendo,
        nesse caso, aplicáveis as sanções aqui previstas.
      </Span>
      <Span>
        9.2.
        {' '}
        <u>Informações Públicas</u>
        . São todas as informações
        disponibilizadas nas áreas públicas da Plataforma, tais como, a título
        exemplificativo, nome, idade, localização, mensagens, arquivos,
        fotografias e outros. Mesmo as mensagens, arquivos e fotografias
        enviados por meio de troca de mensagens enquadram-se nesta categoria,
        respeitado o direito à privacidade, nos termos legais.
      </Span>
      <Span>
        9.3. Informações Privadas. São todas as informações que não se
        enquadram na categoria de Informações Públicas acima definida.
      </Span>
      <Span>
        9.4.
        {' '}
        <u>Política de Privacidade</u>
        . Todas as informações relacionadas
        ao uso dos Serviços da
        {' '}
        <strong>Table Talks</strong>
        {' '}
        estão sujeitas à
        Política de Privacidade da Plataforma
        (https://tabletalks.com.br/policy).
      </Span>
      <Span>
        9.5.
        {' '}
        <u>Licenciamento</u>
        . A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não clama
        propriedade das Informações nem troca de mensagens do usuário. A
        Plataforma usará as Informações Pessoais Identificáveis somente nos
        limites estabelecidos nestes Termos e Condições e conforme Política de
        Privacidade.
      </Span>
      <Span>
        9.6.
        {' '}
        <u>Uso das Informações</u>
        . Salvo disposição em contrário na
        Política de Privacidade, sujeita a alterações periódicas, a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não venderá, alugará ou divulgará, no
        todo ou em partes, as informações fornecidas pelo usuário, inclusive o
        e-mail, para terceiros.
      </Span>
      <Span>
        9.7.
        {' '}
        <u>Consentimento da Divulgação</u>
        . O usuário concorda que, em
        certos contextos, a Plataforma poderá divulgar as Informações
        Pessoais, se requerida a fazê-lo em razão de lei, cooperação para
        processo ou ordem judicial que recaia sobre a Plataforma ou seus
        gestores, fundadores e demais colaboradores, replicar a respeito de
        infração do direito de terceiros pelas Informações do usuário,
        proteger direitos, ou em qualquer outro contexto que razoavelmente
        demande a divulgação de tais informações.
      </Span>
      <Topic>10. Planos, pagamento, cancelamento e reembolso</Topic>
      <Span>
        10.1.
        {' '}
        <u>Planos</u>
        . O aluno da Plataforma contratará os Serviços
        mediante pagamento de Plano Mensal, que tem valor variável conforme o
        número de horas/aulas contratadas. O conteúdo pedagógico será
        disponibilizado gratuitamente, de maneira que a cobrança de taxas se
        refere ao uso efetivo do Serviço, qual seja, contratação de aulas. A
        qualquer tempo, a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        se reserva no direito
        de estabelecer distinções de acesso ao conteúdo, mediante oferecimento
        de Planos diferenciados.
      </Span>
      <Span>
        10.2.
        {' '}
        <u>Início da assinatura</u>
        . Para todos os Planos, a assinatura
        é realizada pelo período integral do plano (seja ele mensal,
        trimestral, semestral, anual ou por múltiplos anos), ainda que o
        pagamento seja parcelado. O Serviço da
        {' '}
        <strong>Table Talks</strong>
        {' '}
        será considerado prestado integral e efetivamente a partir do momento
        em que, cumulativamente, o Plano for contratado validamente, mediante
        cadastro na Plataforma e respectiva confirmação de Pagamento. O início
        do prazo de assinatura independe do efetivo uso da Plataforma, pois o
        usuário teve acesso ao conteúdo disponibilizado na Plataforma, bem
        como teve a possibilidade de contratação de Serviços. Havendo a
        possibilidade de teste grátis por 30 (trinta) dias, o Serviço será
        considerado integral e efetivamente prestado a partir do primeiro
        débito automático no cartão de crédito cadastrado.
      </Span>
      <Span>
        10.3.
        {' '}
        <u>Formas de Pagamento</u>
        . A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        utiliza como método de pagamento prioritário o serviço de pagamento
        online por cartão de crédito e débito, transferência bancária, sendo
        também admitido o pagamento por boleto bancário. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        se reserva o direito de adotar quaisquer
        meios de pagamento disponibilizados. Como membro da
        <strong>Table Talks</strong>
        , o usuário concorda que sejam cobrados no
        seu cartão de crédito ou debitados na sua conta bancária os preços
        relativos aos Planos e todas as taxas e impostos sobre vendas e
        quaisquer outras despesas aplicáveis ao uso dos Serviços da
        Plataforma. Para algumas formas de pagamento, a instituição bancária
        indicada pelo usuário poderá cobrar taxas. A cobrança de impostos
        locais pode variar de acordo com a forma de pagamento usada. Na
        hipótese de contratação de Plano anual com recorrência mensal, o
        usuário se compromete a manter disponibilidade de limite no cartão de
        crédito até o final do pagamento do plano contratado ou, em caso de
        vencimento ou cartão ou indisponibilidade de limite, indicar os dados
        de um novo cartão de crédito.
      </Span>
      <Span>
        10.4.
        {' '}
        <u>Desistência e Política de Reembolso</u>
        . Em caso de
        desistência da compra dos Serviços oferecidos pela
        {' '}
        <strong>Table Talks</strong>
        , o usuário poderá optar pelo cancelamento
        do Plano em até 7 (sete) dias após a compra (ou seja, depois de
        efetivada a transação). Neste caso, a
        <strong>Table Talks</strong>
        reembolsará os gastos por meio de estorno no cartão de crédito no qual
        a compra do Plano foi efetuada,
        {' '}
        <strong>
          <u>no prazo de 35 (trinta e cinco) a 60 (sessenta) dias</u>
        </strong>
        {' '}
        após a solicitação de cancelamento. O efetivo recebimento do reembolso
        como crédito no cartão dependerá das políticas da sua administradora
        de cartão de crédito do usuário. Em se tratando de boleto bancário ou
        transferência, o reembolso será efetuado mediante depósito em conta
        corrente no prazo de até 45 (quarenta e cinco) dias após a
        solicitação. Caberá ao usuário indicar os dados da conta bancária a
        ser efetuado o depósito. Nas compras realizadas por meio do
        aplicativo, o período de 7 (sete) dias para cancelamento se iniciará a
        partir da contratação do Plano de Serviços.
      </Span>
      <Span>
        10.5.
        {' '}
        <u>Direito de alterar preços</u>
        . A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        se reserva no direito de alterar os preços publicados no Aplicativo ou
        no website a qualquer momento, sem prévio aviso. A alteração não se
        aplicará aos contratos assinados antes da modificação, de maneira que
        eles vigerão conforme preço estabelecido quando da contratação. A
        renovação do Contrato mensal, porém, sujeitar-se-á aos novos valores,
        mediante concordância do usuário quando da recontratação.
      </Span>
      <Span>
        10.6.
        {' '}
        <u>Serviços extras</u>
        . A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        poderá
        cobrar por serviços e atividades extras, assim consideradas aquelas
        não incluídas no Plano contratado, mediante aceite prévio pelo usuário
        da Plataforma.
      </Span>
      <Span>
        10.7.
        {' '}
        <u>Renovação automática</u>
        . Os Planos de Serviço mensais serão
        renovados automaticamente, mediante débito automático no cartão de
        crédito informado. Os demais planos – trimestrais, semestrais, anuais,
        dentre outros – poderão ser renovados automática e sucessivamente para
        o plano mensal correspondente, exceto se o usuário se manifestar de
        forma diversa. Os Planos com renovação automática poderão ser
        cancelados a qualquer tempo pelo usuário, observadas as disposições
        anteriores sobre Desistência e Política de Reembolso.
      </Span>
      <Span>
        10.8.
        {' '}
        <u>Procedimento de Cancelamento</u>
        . Para o cancelamento do
        plano contratado ou para o cancelamento da renovação automática, o
        usuário deverá entrar em contato com a Central de Atendimento
        (Assunto: Cancelamento de Plano). Para contatar a Central via e-mail,
        o usuário deve utilizar a mesma conta de e-mail cadastrada na
        Plataforma quando do Cadastramento. Se a conta de e-mail utilizada
        para o cancelamento for distinta daquela cadastrada na Plataforma, a
        mensagem deverá conter as seguintes informações: nome completo do
        usuário, e-mail cadastrado na Plataforma e forma de pagamento (para
        cartão de crédito: bandeira e 4 (quatro) últimos dígitos do cartão;
        para boleto bancário, o número que consta no documento). Fica
        esclarecido, desde já, que cancelamentos que não seguirem este
        procedimento não serão efetuados.
      </Span>
      <Span>
        10.9.
        {' '}
        <u>Impossibilidade de Reembolso</u>
        . Com exceção das disposições
        constantes nestes Termos e Condições gerais de uso, nenhuma taxa,
        mensalidade ou despesa será reembolsável. Destaque-se e que a Table
        Talks não reembolsará o usuário caso a justificativa se assente na
        insatisfação pelos Serviços ofertados, especial e relativamente ao
        aluno, uma vez que é conferido ao aluno possibilidade de agendar uma
        aula experimental antes da efetiva contratação dos Serviços prestados
        pelo professor de Língua Inglesa.
      </Span>
      <Topic>11. Rescisão</Topic>
      <Span>
        11.1. A
        {' '}
        <strong>Table Talks</strong>
        {' '}
        se reserva no direito de
        rescindir o presente contrato se houver qualquer violação aos Termos e
        Condições de Uso.
      </Span>
      <Span>
        11.2. Sem prejuízo das demais disposições aqui constantes, o usuário
        declara-se ciente de que a Plataforma se reserva do direito de
        suspender temporariamente, indefinidamente ou remover permanentemente
        qualquer conteúdo ou informação que viole os Termos e Condições. No
        mesmo sentido, o perfil do usuário poderá ser suspenso temporária ou
        permanentemente se houver qualquer violação às diretrizes da
        Plataforma. Constituem hipóteses para rescisão, a título
        exemplificativo: (a) impossibilidade de a Plataforma autenticar ou
        verificar as informações prestadas; (b) se a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        verificar que o usuário violou ou agiu de
        forma inconsistente com o espírito destes Termos e Condições de Uso;
        (c) se a
        <strong>Table Talks</strong>
        {' '}
        acreditar que as ações do
        usuário podem acarretar responsabilidade civil a ele, à Plataforma ou
        a terceiros.
      </Span>
      <Span>
        11.3. O encerramento da Conta do usuário ou do acesso total ou parcial
        à Plataforma por motivo esclarecido nesses Termos e Condições de Uso
        será precedido de aviso enviado ao e-mail cadastrado na Plataforma. O
        usuário concorda que a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não poderá ser
        responsabilizada por ele ou por terceiros pelo encerramento do seu uso
        de todas ou quaisquer partes da Plataforma.
      </Span>
      <Span>
        11.4. Reembolso. Havendo rescisão, o usuário será reembolsado dos
        valores dispendidos e pertinentes ao Plano de Serviços escolhido,
        considerando a proporção entre o tempo 1 11 contratado e o tempo
        efetivamente utilizado. Será considerado como tempo efetivamente
        utilizado todo o período entre o início do contrato e a data da
        rescisão. O reembolso será efetuado através de estorno do valor no
        cartão de crédito cadastro, em até 30 (trinta) dias após a
        solicitação, respeitadas as políticas da administradora do cartão. Se
        o pagamento for relativo à boleto bancário, o reembolso será realizado
        através de depósito em conta corrente do usuário dentro do prazo de 30
        (trinta) dias após a solicitação com indicação dos dados bancários da
        conta a ser efetuado o depósito.
      </Span>
      <Span>
        11.5. Além da possibilidade de a rescisão ser sucedida pela Table
        Talks, o usuário poderá optar pelo cancelamento dos Serviços
        contratados, nos termos dos itens 10.4. e 10.8. que constam acima.
      </Span>
      <Topic>12. Indenização</Topic>
      <Span>
        12.1 Em caso de mau uso da Plataforma que acarrete violação dos Termos
        e Condições aqui constantes, além de violação à lei, estatuto, ordem,
        regulamento, direito de terceiros e outros, o usuário se compromete a
        defender, proteger, indenizar e eximir a
        {' '}
        <strong>Table Talks</strong>
        ,
        bem como seus fundadores, agentes, empregados e consultores contra
        toda e qualquer reclamação, perda, dano, responsabilização,
        julgamento, multas, honorários advocatícios e outros. Ademais, o
        usuário se compromete a reembolsar a
        {' '}
        <strong>Table Talks</strong>
        , sem
        prejuízo de indenizar, se o preenchimento das situações acima
        acarretar perda, custo ou despesa para a empresa. Desde já, fica
        esclarecido que a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não se responsabiliza
        pela conduta de seus usuários dentro ou fora do Aplicativo, pois age
        apenas como empresa intermediadora da relação.
      </Span>
      <Topic>13. Modificações dos Termos e Condições</Topic>
      <Span>
        13.1. O presente Termo poderá, a qualquer tempo, ter seu conteúdo, ou
        parte dele, modificados para adequações e inserções, tudo com vistas
        ao aprimoramento dos serviços disponibilizados. Na hipótese de o
        usuário não concordar com os novos termos, poderá enviar oposição à
        <strong>Table Talks</strong>
        , desde que o faça por escrito e com
        fundadas justificativas, para além de mero dissabor. A reclamação
        deverá ser enviada ao e-mail da Central de Atendimento e será
        respondida em tempo hábil.
      </Span>
      <Topic>14. Aceitação dos Termos e Condições</Topic>
      <Span>
        14.1. O usuário declara que leu e compreendeu os Termos e Condições,
        aceitando a todas condições e obrigações aqui estabelecidas.
      </Span>
      <Topic>15. Disposições Gerais</Topic>
      <Span>
        15.1.
        {' '}
        <u>Acordo</u>
        . Estes Termos e Condições de Uso, juntamente com a
        Política de Privacidade, constituem o acordo integral entre as Partes
        com relação ao objeto em questão, e substituem todos os acordos
        anteriores, escritos ou verbais.
      </Span>
      <Span>
        15.2.
        {' '}
        <u>Interpretação restritiva</u>
        . As Partes contratantes são
        independentes, de maneira que este Instrumento não resulta na criação
        de nenhuma sociedade, franquia, representação de vendas ou outras
        relações que não estejam previstas aqui, devendo as cláusulas serem
        interpretadas ipsis litteris.
      </Span>
      <Span>
        15.3.
        {' '}
        <u>Vínculo Empregatício</u>
        . O serviço ofertado pelo instrutor
        de Língua Inglesa não é exercido de forma subordinada, pessoal,
        habitual e onerosa e, portanto, em nenhuma hipótese será estabelecido
        o vínculo empregatício entre a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        e o
        usuário da Plataforma. Assim 1 12 sendo, o professor concorda com o
        papel meramente intermediador da
        <strong>Table Talks</strong>
        , estando ciente de que não poderá
        reclamar nenhum direito ou vínculo trabalhista relativamente a
        empresa.
      </Span>
      <Span>
        15.4.
        {' '}
        <u>Nulidade de Cláusula</u>
        . Na hipótese de alguma cláusula ser
        declarada nula, no todo ou em parte, por motivo legal ou contratual,
        as demais cláusulas continuarão vigentes, produzindo seus devidos
        efeitos.
      </Span>
      <Span>
        15.5.
        {' '}
        <u>Novação e Renúncia</u>
        . A tolerância ou o não exercício, por
        qualquer das Partes, de quaisquer direitos a ela assegurados nestes
        Termos de Uso ou na lei em geral não importará em novação ou em
        renúncia a qualquer desses direitos, podendo a referida Parte
        exercê-los durante a vigência destes Termos de Uso.
      </Span>
      <Span>
        15.6. Nenhuma cláusula destes Termos e Condições de Uso deve afetar
        quaisquer direitos legalmente estabelecidos em favor dos consumidores
        em face da
        {' '}
        <strong>Table Talks</strong>
        .
      </Span>
      <Span>
        15.7.
        {' '}
        <u>Comunicação</u>
        . Notificações, avisos e comunicações
        relativos aos Termos e Condições de Uso serão enviados por e-mail e
        consideradas entregues, independente de aviso de recebimento, vez que,
        ao assinar o presente Termo, o usuário assume como sua a obrigação de
        verificar periodicamente o e-mail cadastrado. Em nenhuma hipótese, o
        usuário poderá se escusar de suas responsabilidades alegando que não
        houve a devida comunicação.
      </Span>
      <Span>
        15.8.
        {' '}
        <u>Outras disposições</u>
        . Para compras realizadas por
        aplicativos, serão aplicadas, em complemento às disposições do
        presente Termo, as condições específicas praticadas pelas lojas de
        aplicativos (Apple Store e Google Play), que devem ser consultadas nas
        respectivas plataformas.
      </Span>
      <Span>
        15.9.
        {' '}
        <u>Condições Técnicas</u>
        . Ao contratar os Serviços da
        {' '}
        <strong>Table Talks</strong>
        , o usuário concorda que deve possuir as
        condições técnicas mínimas de acesso à Plataforma, sendo de sua
        inteira e integral responsabilidade providenciar máquina capaz de
        garantir o funcionamento do Aplicativo. Ademais, é responsabilidade
        exclusiva do usuário garantir acesso à internet, ficando esclarecido,
        desde já, que a maior parte dos Serviços do Aplicativo não serão
        disponibilizados off-line. A Table Talks se exime de qualquer
        responsabilidade referente à obtenção das condições técnicas mínimas
        para o acesso ao Aplicativo, sendo vedado ao usuário arguir tal
        justificativa para se eximir de obrigações ou mesmo cancelar os
        Serviços contratados, hipótese em que não haverá reembolso.
      </Span>
      <Span>
        15.10.
        {' '}
        <u>Isenção de Garantias</u>
        . O usuário concorda que utiliza a
        Plataforma por sua conta e risco, consentindo que ela é oferecida
        “Como é” e “Como está disponível”, sem garantias e os Serviços podem
        não estar livres de interrupções e erros. Além disso, a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        se exime de garantias e condições de
        qualquer tipo, sejam expressas, implícitas ou instituídas, incluindo,
        mas não se limitando a: garantias relacionadas à segurança,
        confiabilidade, conveniência e performance de nossa plataforma.
        Isenta-se, ainda, de quaisquer garantias sobre informações ou
        conselhos obtidos através da Plataforma, de garantias de terceiros por
        serviços ou bens recebidos através ou anunciados na Plataforma ou
        recebidos mediante links exibidos, bem como por qualquer informação ou
        conselho recebido através de quaisquer links exibidos na Plataforma.
        Ademais, nenhum conselho ou informação, seja oral ou escrita, obtida
        do usuário para a
        <strong>Table Talks</strong>
        , deve criar nenhum tipo
        de garantia.
      </Span>
      <Span>
        15.11.
        {' '}
        <u>Suposição de Riscos</u>
        . Ao contratar os Serviços da
        {' '}
        <strong>Table Talks</strong>
        , o usuário concorda que, ao fazer
        download ou acessar o conteúdo disponibilizado na Plataforma, ele o
        fará por sua conta e risco, de maneira que a
        {' '}
        <strong>Table Talks</strong>
        {' '}
        não se responsabiliza por qualquer dano à
        máquina do usuário, como computador, smartphone, aparelho celular,
        tablet, ou novas 1 13 tecnologias que possam surgir, ou perda de dados
        que resulte do download deste material ou dados.
      </Span>
      <Span>
        15.12.
        {' '}
        <u>Definições e Regras de Interpretação</u>
        . Para interpretar
        este Termo, deve-se levar em consideração as seguintes diretrizes: (a)
        os termos “incluindo”, “inclui”, “inclusive” e suas derivações e
        termos análogos serão interpretados como se estivessem acompanhados
        pela expressão “entre outros” e/ou “mas não se limitando a”; (b) as
        referências à disposições de Lei serão sempre interpretadas como
        referências a tais disposições e suas respectivas emendas, ampliações,
        consolidações ou reedições, ou ainda, quanto às alterações a sua
        aplicabilidade por outras normas de tempos em tempos, incluindo as
        disposições das quais elas se originam (com ou sem modificações),
        regulamentos, instrumentos ou outras normas legais a elas
        subordinadas; e (c) sempre que exigido pelo contexto, as definições
        aqui contidas serão aplicadas tanto no singular quanto no plural e o
        gênero masculino incluirá o feminino e vice-versa, sem qualquer
        alteração de significado.
      </Span>
      <Topic>16. Foro de Eleição</Topic>
      <Span>
        16.1. Este Contrato será regido e interpretado de acordo com as leis
        brasileiras e as Partes elegem, renunciando a qualquer outro, por mais
        privilegiado que seja, o foro da Comarca de Campinas, São Paulo, para
        dirimirem eventuais controvérsias que venham a surgir da interpretação
        e do cumprimento do presente Termo.
      </Span>
      <Footer />
    </Wrapper>
  </Container>
);
