/* eslint-disable react/no-array-index-key */
/* eslint-disable spaced-comment */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
/* eslint-disable  */

import { useState, useRef, useCallback, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { ReadBook } from './ReadBook';
import {
  Container,
  WrapperContainer,
  Text,
  Input,
  GuideLineContent,
} from './styles';
import ModalVideo from '../../../components/Modals/ModalVideo';
import { Header } from '../../../components/Header';
import { api } from '../../../services/api';
import { useTranslation } from 'react-i18next';
import { Ebook } from './components/Ebook';
import Video from './components/Video';
import React from 'react';

export function Guideline({ setShowMenu, showMenu, match }) {
  const { t } = useTranslation();

  const [visibleSlide /*setVisibleSlide*/] = useState(0);
  const [showModal, setShowModal] = useState(null);
  const [ebooks, setEbooks] = useState([]);
  const [videos, setVideos] = useState([]);
  const [search, setSearch] = useState();

  const wrapperRef = useRef(null);
  const ebookRef = useRef(null);

  const loadGuidelines = useCallback(async () => {
    try {
      const { data } = await api.get('/guidelines', {
        params: {
          q: search,
        },
      });

      const ebooks = data.filter(item => item.type === 'ebook');
      const videos = data.filter(item => item.type === 'video');

      setEbooks(ebooks);
      setVideos(videos);
    } catch (error) {
      console.log(error);
    }
  }, [search]);

  useEffect(() => {
    loadGuidelines();
  }, [loadGuidelines]);

  function closeModalVideo() {
    setShowModal(null);
    // window.location.reload(true);
  }

  return (
    <Container>
      <Header />
      <WrapperContainer videos={videos} ebooks={ebooks}>
        <header>
          <Text title>{t('guideline')}</Text>
          <Input
            placeholder={t('search')}
            onChange={e => setSearch(e.target.value)}
          />
        </header>

        <GuideLineContent
          className="wrapper guidelineContent"
          checkContent={videos.length}
        >
          <div className="header-title">
            <Text style={{width: '150px', paddingTop: '20px'}} title>{t('videos')}</Text>
            {videos?.length > 0 && (
              <div className="buttons">
                <button
                  className="left"
                  title="preview"
                  onClick={() => {
                    wrapperRef.current.scrollTo({
                      top: 0,
                      left: wrapperRef.current.scrollLeft - 200,
                      behavior: 'smooth',
                    });
                  }}
                >
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-left-alt2"
                    data-inline="false"
                  />
                </button>
                <button
                  className="right"
                  id="right"
                  title="next"
                  onClick={() => {
                    wrapperRef.current.scrollTo({
                      top: 0,
                      left: 200 + wrapperRef.current.scrollLeft,
                      behavior: 'smooth',
                    });
                  }}
                >
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                    data-inline="false"
                  />
                </button>
              </div>
            )}
          </div>

          <div className="list-videos" id="list-videos" ref={wrapperRef}>
            {videos?.map((video, i) => (
              <React.Fragment key={i}>
                <Video
                  index={i}
                  title={video.title}
                  url={video.fileURL}
                  visibleSlide={visibleSlide}
                  viewModal={showModal}
                  onChangeModal={() => {
                    setShowModal(i+1);
                  }}
                  desc={video.description}
                  key={i}
                  img={video.thumbnailURL}
                />

                {showModal !== null && showModal === i+1 && (
                  <ModalVideo
                    open={showModal}
                    closed={closeModalVideo}
                    urlVideo={video.fileURL}
                  />
                )}
              </React.Fragment>
            ))}

            {(!videos || videos?.length === 0) && (
              <h3>
                {t('thereAreNo')} {t('videos')}!
              </h3>
            )}
          </div>
        </GuideLineContent>

        <GuideLineContent
          className="wrapper guidelineContent"
          checkContent={ebooks.length}
        >
          <div className="header-title">
            <Text style={{width: '150px', paddingTop: '20px'}} title>{t('ebook')}</Text>
            {ebooks?.length > 0 && (
              <div className="buttons">
                <button
                  className="left"
                  title="preview"
                  onClick={() => {
                    ebookRef.current.scrollTo({
                      top: 0,
                      left: ebookRef.current.scrollLeft - 200,
                      behavior: 'smooth',
                    });
                  }}
                >
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-left-alt2"
                    data-inline="false"
                  />
                </button>

                <button
                  className="right"
                  id="right"
                  title="next"
                  onClick={() => {
                    ebookRef.current.scrollTo({
                      top: 0,
                      left: 200 + ebookRef.current.scrollLeft,
                      behavior: 'smooth',
                    });
                  }}
                >
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                    data-inline="false"
                  />
                </button>
              </div>
            )}
          </div>

          <div className="container-carrosel">
            <div className="list-videos" id="list-videos" ref={ebookRef}>
              {ebooks?.map((ebook, key) => (
                <Ebook
                  key={key}
                  title={ebook.title}
                  prev={ebook.thumbnailURL}
                  desc={ebook.description}
                  id={ebook.id}
                  fileURL={ebook.fileURL}
                />
              ))}
              {(!ebooks || ebooks?.length === 0) && (
                <h3>
                  {t('thereAreNo')} {t('ebook')}!
                </h3>
              )}
            </div>
          </div>
        </GuideLineContent>
        <Route
          path={`${match.path}/readbook`}
          match={match}
          component={props => <ReadBook {...props} />}
        />
      </WrapperContainer>
    </Container>
  );
}
