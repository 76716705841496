import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { WEEKDAYS } from '../../../utils/constants';
import { getWeekDays } from '../../../utils/getWeekDays';
import {
  BoxSchedule,
  Button,
  Container,
  ContainerCalendar,
  ContainerModal,
  ContainerSelect,
  Content,
  Icon,
  Image,
  Text,
  TextCalendar,
  SpanIconClose,
} from './styles';

export function SelectLesson({ onCreateAppointments, selectedAppointments, handleRemove }) {
  const wrapperRef = useRef(null);

  const { t } = useTranslation();

  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Container>
      <Text title>{t('selectedClasses')}</Text>
      <ContainerCalendar>
        <Button
          onClick={() => {
            wrapperRef.current.scrollTo({
              top: 0,
              left: wrapperRef.current.scrollLeft - 120,
              behavior: 'smooth',
            });
          }}
        >
          <span
            className="iconify"
            data-icon="dashicons:arrow-left-alt2"
            data-inline="false"
          />
        </Button>
        <Content ref={wrapperRef}>
          {selectedAppointments.map((appointment, i) => (
            <ContainerSelect>
              <Image src={appointment.teacher?.avatarURL} />
              <Text name>
                {appointment.teacher?.fullName ?? appointment.teacher?.name}
              </Text>
              <BoxSchedule>
                <Icon>
                  <span
                    className="iconify"
                    data-icon="bi:check-lg"
                    data-inline="false"
                  />
                </Icon>
                <button
                  onClick={() => {
                    handleRemove(i);
                  }}
                >
                  <SpanIconClose
                    className="iconify"
                    data-icon="eva:close-outline"
                    data-inline="false"
                  />
                </button>
                <TextCalendar top>
                  {format(
                    new Date(appointment.startTime),
                    'dd/MM/yyyy',
                  )}{' '}
                  <br />
                  {t(WEEKDAYS[appointment.weekday].label)}
                </TextCalendar>
                <TextCalendar>
                  <span
                    className="iconify"
                    data-icon="ic:outline-watch-later"
                    data-inline="false"
                  />
                  {appointment.tempStartTime}
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                    data-inline="false"
                  />
                  {appointment.tempEndTime}
                </TextCalendar>
              </BoxSchedule>
            </ContainerSelect>
          ))}
        </Content>
        <Button
          onClick={() => {
            wrapperRef.current.scrollTo({
              top: 0,
              left: 120 + wrapperRef.current.scrollLeft,
              behavior: 'smooth',
            });
          }}
        >
          <span
            className="iconify"
            data-icon="dashicons:arrow-right-alt2"
            data-inline="false"
          />
        </Button>
      </ContainerCalendar>

      <Modal
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <ContainerModal>
          <div className="container-check">
            <span
              className="iconify"
              data-icon="bi:check-circle"
              data-inline="false"
            />
          </div>
          <div className="container-text">
            <Text modal>{t('yourClassesWere')}</Text>
            <Text modal>{t('successfullyScheduled')}</Text>
            <button type="button" onClick={() => setIsOpen(false)}>
              {t('voltar')}
            </button>
          </div>
        </ContainerModal>
      </Modal>

      <Button confirm onClick={onCreateAppointments}>
        {t('scheduleSelectedClasses')}
      </Button>
    </Container>
  );
}
