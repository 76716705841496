import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const TicketMessage = styled.div`
  margin-bottom: 1.5rem;
  Span {
    font-size: 24px;
    color: #79748b;
    font-weight: bold;
  }
`;

export const TicketCalled = styled.div`
  margin-bottom: 1.5rem;
  Button {
    padding: 2rem;
    line-height: 0;
    border: 1px solid #ebe9f1;
  }
`;

export const TicketAnotherMessage = styled.div`
  margin-bottom: 1.5rem;
  Span {
    font-size: 14px;
    color: #79748b;
    font-weight: bold;
  }
`;

export const TicketOut = styled.div`
  Button {
    padding: 2rem;
    line-height: 0;
    border: 1px solid #ebe9f1;
  }
`;
