import React, { useState } from 'react';
import TimePicker from 'react-time-picker';
import { Container } from './styles';
import { ContainerTitle } from '../CalendarProfile/styles';

export function CalendarListTeacher({ schedule, onEdit, onDelete }) {
  /**
   * Formatando a hora de exibição para padrão Americano (12 horas)
   * @param time Hora em string (hh:mm)
   * @returns
   */
  const formatTime = time => {
    let [_hours, _minutes] = time.split(':');
    let shiftHour = 'AM';

    // Mudando formato de 24 horas para 12 horas
    if (_hours == '00' && _minutes == '00') {
      _hours = '12';
      shiftHour = 'AM';
    } else if (_hours == '12' && _minutes == '00') {
      _hours = '12';
      shiftHour = 'PM';
    } else if (_hours?.length === 2 && (_hours > '12' || (_hours == '12' && _minutes > '00'))) {
      if (_hours == '24') _hours = '00';
      if (parseInt(_hours) >= 12) shiftHour = 'PM';
      _hours = ((parseInt(_hours) + 11) % 12) + 1;
    }

    // Colocar o zero a esquerda na hora de somente um caracter
    if (_hours?.toString().length === 1) {
      _hours = `0${_hours}`;
    }

    return `${_hours}:${_minutes}${shiftHour}`;
  };
  /* end */

  return (
    <Container>
      <div className="watch">
        <span
          className="iconify"
          data-icon="ic:outline-watch-later"
          data-inline="false"
        />
      </div>

      <div className="text-container">
        <span>{formatTime(schedule.startTime)}</span>
        <span
          className="iconify"
          data-icon="dashicons:arrow-right-alt2"
          data-inline="false"
        />
        <span>{formatTime(schedule.endTime)}</span>
      </div>

      <div className="container-buttons">
        <button
          type="button"
          onClick={() => {
            onEdit(schedule);
          }}
        >
          <span
            className="iconify"
            data-icon="clarity:pencil-solid"
            data-inline="false"
          />
        </button>
        <button
          type="button"
          onClick={() => {
            onDelete(schedule.id);
          }}
        >
          <span
            className="iconify"
            data-icon="feather:trash"
            data-inline="false"
          />
        </button>
      </div>
    </Container>
  );
}
