import { useState, useEffect } from 'react';

import {
  Recorder,
  Interval,
  AudioTrack,
  MediaRecorderEvent,
  SetRecorder,
} from '../types/recorder';

const initialState: Recorder = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,
};

export async function startRecording(setRecorderState: SetRecorder) {
  try {
    const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    setRecorderState((prevState) => ({
      ...prevState,
      initRecording: true,
      mediaStream: stream,
    }));
  } catch (err) {
    // tratar erro video aqui!
    console.log(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function saveRecording(recorder: any) {
  if (recorder.state !== 'inactive') recorder.stop();
}

export default function useRecorder() {
  const [recorderState, setRecorderState] = useState<Recorder>(initialState);

  useEffect(() => {
    const MAX_RECORDER_TIME = 5;
    let recordingInterval: Interval = null;

    if (recorderState.initRecording) {
      recordingInterval = setInterval(() => {
        setRecorderState((prevState: Recorder) => {
          if (
            prevState.recordingMinutes === MAX_RECORDER_TIME
            && prevState.recordingSeconds === 0
          ) {
          // eslint-disable-next-line no-unused-expressions
            typeof recordingInterval === 'number'
              && clearInterval(recordingInterval);
            return prevState;
          }

          if (
            prevState.recordingSeconds >= 0
            && prevState.recordingSeconds < 59
          ) {
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };
          }
          if (prevState.recordingSeconds === 59) {
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            };
          }
          return prevState;
        });
      }, 1000);
    } else
    // eslint-disable-next-line no-unused-expressions, brace-style
    { typeof recordingInterval === 'number' && clearInterval(recordingInterval); }

    return () => {
      // eslint-disable-next-line no-unused-expressions
      typeof recordingInterval === 'number' && clearInterval(recordingInterval);
    };
  });

  useEffect(() => {
    setRecorderState((prevState) => {
      if (prevState.mediaStream) {
        return {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream),
        };
      }
      return prevState;
    });
  }, [recorderState.mediaStream]);

  useEffect(() => {
    const recorder = recorderState.mediaRecorder;
    let chunks: Blob[] = [];

    if (recorder && recorder.state === 'inactive') {
      recorder.start();

      recorder.ondataavailable = (e: MediaRecorderEvent) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm;' });
        chunks = [];

        setRecorderState((prevState: Recorder) => {
          if (prevState.mediaRecorder) {
            return {
              ...initialState,
              audio: window.URL.createObjectURL(blob),
            };
          }
          return initialState;
        });
      };
    }

    return () => {
      if (recorder) {
        recorder.stream
          .getAudioTracks()
          .forEach((track: AudioTrack) => track.stop());
      }
    };
  }, [recorderState.mediaRecorder]);

  return {
    recorderState,
    startRecording: () => startRecording(setRecorderState),
    cancelRecording: () => setRecorderState(initialState),
    saveRecording: () => saveRecording(recorderState.mediaRecorder),
  };
}
