import styled, { css } from 'styled-components';

interface LessonProps {
  division?: boolean;
}

interface ButtonsProps {
  cancel?: boolean;
}

export const Container = styled.div`
  width: 100%;
  background: #fff;
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;

  position: relative;
`;

export const Text = styled.span``;

export const List = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  padding: 1.5rem 0;
  position: relative;

  @media (max-width: 768px) {
    display: grid;
    width: 100%;
    justify-items: center;
    align-content: space-evenly;
    gap: 0.5rem;
  }
`;

export const ContainerIcon = styled.div`
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: #28c76f12;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;

export const Icon = styled.img``;

export const ContainerHour = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  span {
    font-size: 1.4rem;
    color: #707070;
  }
`;

export const ContainerUser = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

export const Image = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

export const ContainerTextsUser = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.6rem;
    color: #707070;
  }

  span + span {
    color: #b9b9c3;
    font-size: 1.4rem;
  }
`;

export const ContainerInfoLesson = styled.div<LessonProps>`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  ${(props) => props.division
    && css`
      border-left: 1px solid var(--gray3);
      border-right: 1px solid var(--gray3);
      padding: 0 1rem;
    `}
`;

export const ContainerTextsInfoLesson = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.4rem;
    color: #707070;
  }
`;

export const ContainerButtons = styled.div`
  position: absolute;
  right: 1.2rem;

  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    position: revert;
  }
`;

export const Button = styled.button<ButtonsProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4rem;
  width: 100% auto;
  padding: 0 1rem;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  margin-left: 1rem;

  color: #243342;
  cursor: pointer;

  .iconify {
    font-size: 1.6rem;
    margin-right: 1rem;
  }

  ${(props) => props.cancel
    && css`
      background: #9e0d0d;
      color: #fff;
    `}
`;
