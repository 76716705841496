/* eslint-disable import-helpers/order-imports */
import styled, { css } from 'styled-components';
import NumberFormat from 'react-number-format';

interface ContainerProps {
  isLoading?: boolean;
}

interface SubmitButtonProps {
  isLoading?: boolean;
}

export const NumberFormatInput = styled(NumberFormat)<{ error?: boolean }>`
  height: 4rem;
  width: 100%;
  border: 1px solid var(--gray3);
  background: #fff;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 0.8rem;

  ${(props) => props.error
    && css`
      border: 1px solid #f00 !important;
      margin-bottom: 2px !important;
    `};

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Container = styled.div<ContainerProps>`
  ${(props) => props.isLoading
    && css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
      width: 100%;
    `};

  h2 {
    color: var(--title);
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  form {
    display: flex;
    flex-direction: column;

    .column {
      display: flex;
      flex-direction: column;
    }
    .row {
      display: flex;
      flex-direction: row;
    }
  }

  label {
    color: #5e5873;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }

  input {
    margin-bottom: 1rem;
  }

  select {
    height: 4rem;
    width: 100%;
    border: 1px solid var(--gray3);
    background: #fff;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 1rem;
  }
`;

export const CompleteWithdrawContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  h2 {
    color: #3e4c59;
    margin-bottom: 32px;
  }

  .iconify {
    color: #083059;
    font-size: 12rem;
    margin-bottom: 16px;
  }

  button {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    border-radius: 0.25rem;
    font-size: 1.2rem;
    margin-top: 1.5rem;
    font-weight: 600;
    transition: filter 0.2s ease;
    &:hover {
      filter: brightness(0.9);
    }
  }

  .pix-text {
    font-size: 15px;
    color: #3e4c59;
    line-height: 24px;
    text-align: left;
    word-break: break-all;
  }
`;

export const SubmitButton = styled.button<SubmitButtonProps>`
  width: 100%;
  padding: 0 1.5rem;
  height: 4rem;
  background: var(--main-color);
  color: #ffffff;
  border-radius: 0.25rem;
  border: 0;
  font-size: 1.2rem;
  margin-top: 1.5rem;
  font-weight: 600;
  transition: filter 0.2s ease;
  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    background: #ccc;
    border-color: #ccc;
    box-shadow: none;
  }

  ${(props) => props.isLoading
    && css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`;

export const ErrorMessage = styled.p`
  align-self: flex-start;
  padding-left: 1rem;
  font-size: 1.3rem;
  color: #f00;
`;
