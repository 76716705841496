import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-right: 1.2rem;
  border: 1px solid var(--gray3);
  width: 100%;

  header {
    height: 6rem;
    background: #f9f9f9;
    border-bottom: 1px solid var(--gray3);
    border-radius: 0.5rem 0.5rem 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  section {
    height: 39rem;
    overflow: auto;
    background: #fff;
    border-bottom: 1px solid var(--gray3);

    article {
      font-size: 1.9rem;
      padding: 2rem;
      color: #243342;
      text-align: justify;
      line-height: 5rem;
    }
  }

  footer {
    height: 6rem;
    background: #f9f9f9;
    width: 100%;
    border-radius: 0 0 0.5rem 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .column {
    display: flex;
    align-items: center;
  }
`;

export const Button = styled.button`
  height: 6rem;
  width: 8rem;
  border-right: 1px solid var(--gray3);
  color: #243342;
  cursor: pointer;
  display: ${(props) => props.disable && 'none'};

  .iconify {
    font-size: 2rem;
  }

  ${(props) =>
    props.finaly &&
    css`
      border-left: 1px solid var(--gray3);
    `}

  ${(props) =>
    props.left &&
    css`
      border-left: 1px solid var(--gray3);
    `}
`;

export const ContainerText = styled.div`
  display: flex;
  margin-left: 1.2rem;

  @media (max-width: 560px) {
    display: none;
  }
`;

export const Text = styled.span`
  font-size: 1.9rem;
  color: #243342;
  margin-right: 1.2rem;
  padding-right: 1.2rem;
  border-right: 1px solid var(--gray3);

  ${(props) =>
    props.finaly &&
    css`
      border-right: none;
    `}

  ${(props) =>
    props.footer &&
    css`
      border-right: none;
      margin: 0 6rem;
    `}
`;

export const IconFlag = styled.img`
  height: 6rem;
  width: 6rem;
  margin: 0 1.2rem;
  cursor: pointer;
`;
