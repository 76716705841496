import React from 'react';
import { CalendarProfile } from '../CalendarProfile';
import { ContainerClass } from '../ContainerClass';
import { isStudent, isTeacher } from '../../../hooks/AuthProvider';


import { Container } from './styles';

export function ContainerSchedule() {
  return (
    <Container>
    {(isTeacher() || isStudent()) && 
      <>
        <CalendarProfile />
        <ContainerClass />
      </>
      }
    </Container>
  );
}
