import Modal from 'react-modal';
import { Span } from '../../Span';
import { Button } from '../../Button';

import {
  Container,
  TicketMessage,
  TicketCalled,
  TicketAnotherMessage,
  TicketOut,
} from './styles';
import './styles/styles.css';
import { useTranslation } from 'react-i18next';

export function ModalTicketConfirmation({
  open,
  closedModalConfirmation,
  protocoloOfNewCall
}) {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={open}
      contentLabel="Example Modal"
      onRequestClose={closedModalConfirmation}
      overlayClassName="Overlay"
      className="Modal"
    >
      <Container>
        <TicketMessage>
          <Span>{t('yourTicketHasBeenCreatedSuccessfully')}</Span>
        </TicketMessage>
        <TicketCalled>
          <Button
            color="#243342"
            fontColor="#fff"
            title={`${t('ticketProtocolIs')}: #${protocoloOfNewCall}`}
          />
        </TicketCalled>
        <TicketAnotherMessage>
          <Span>{t('weWillContactYouWithin24Hours')}</Span>
        </TicketAnotherMessage>
        <TicketOut>
          <Button
            color="#243342"
            fontColor="#fff"
            title={t('backToHomePage')}
            onClick={closedModalConfirmation}
          />
        </TicketOut>
      </Container>
    </Modal>
  );
}
