function round(value: string | number, decimals: number): string {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals).toFixed(decimals);
}

const MonetaryUtil = {
  round,
}

export {
  MonetaryUtil
}