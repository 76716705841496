import styled from 'styled-components';
import BackgroundGreen from '../../../assets/background_login_gren.png';
import IconLeft from '../../../assets/logo_icon_login.png';

export const Container = styled.div`
  width: ${props => props.with};
  height: 100vh;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ColumnLeft = styled.div`
  height: 100%;
  background-image: url(${BackgroundGreen});
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconsLeft = styled.img.attrs({ src: IconLeft })`
  /* position: absolute; */
  background-color: transparent;
  width: auto;
  height: 90%;
  left: ${props => props.leftIcon};

  @media (max-width: 1860px) {
    height: 70%;
    left: ${props => props.leftIcon};
  }

  @media (max-width: 1450px) {
    height: 60%;
    left: ${props => props.leftIcon};
  }

  @media (max-width: 1240px) {
    height: 50%;
    left: ${props => props.leftIcon};
  }

  @media (max-width: 1030px) {
    height: 40%;
    left: ${props => props.leftIcon};
  }

  @media (max-width: 830px) {
    height: 35%;
    left: ${props => props.leftIcon};
  }
`;

export const ContainerSocial = styled.div`
  position: absolute;
  bottom: 4rem;

  @media (max-width: 1280px) {
    bottom: 2rem;
    margin-left: 6rem;
  }
`;

export const ImageSocial = styled.img`
  margin-right: 1rem;
`;
