import React, { useEffect, useState } from 'react';
import { Chat } from '../../../components/Base/Chat';
import Footer from '../../../components/Footer';
import { Vocabulary } from '../../../components/Mission/Vocabulary';
import { Questions } from '../../../components/Mission/Questions';
import { ReadingComprehension } from '../../../components/Mission/ReadingComprehension';
import { PlacementTest } from '../../../components/Mission/PlacementTest';
import { HeaderInfo } from '../../../components/Mission/HeaderInfo';
import ImageConference from '../../../assets/img-conference.png';
import { useAuth } from '../../../hooks/AuthProvider';
import {
  Container,
  Wrapper,
  Content,
  ColumnContent,
  ContainerImage,
  Image,
  ContainerSelect,
  Column,
  Select,
  Button,
  ModalMission,
  ContainerModal,
  Text,
} from './styles';
import { Header } from '../../../components/Header';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export function StudentMission() {
  const { typeLogin } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showPlacements, setShowPlacements] = useState(false);
  const [valueVerification /* setValueVerification */] = useState([
    { type: 'vocabulary', id: '0', title: 'Vocabulary' },
    { type: 'challenge', id: '1', title: 'Questions' },
    { type: 'reading_comprehension', id: '2', title: 'Reading Comprehesion' },
  ]);

  const [selectionMission, setSelectionMission] = useState();
  const [selectionMethod, setSelectionMethod] = useState();
  const [selectionType, setSelectionType] = useState();
  const [selection, setSelection] = useState();

  const [methods, setMethods] = useState([]);
  const [missions, setMission] = useState([]);
  const [conteudo, setConteudo] = useState([]);

  const { t } = useTranslation();

  async function fetchMethods() {
    const { data } = await api.get('/methods');
    setMethods(data.docs);
  }

  async function fetchMissions() {
    const { data } = await api.get('/missions?type=class');
    setMission(data.docs);
  }

  async function fetchConteudo() {
    const { data } = await api.get(`/contents/?type=vocabulary`);

    setSelectionMethod(data.docs[0].method.id);
    setSelectionMission(data.docs[0].mission.id);
    setSelection(data.docs[0].type);
    setSelectionType(data.docs[0].type);

    setConteudo(data.docs);
  }

  useEffect(() => {
    fetchMissions();
    fetchMethods();
    fetchConteudo();
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  const handleClickEditar = async e => {
    const { data } = await api.get(
      `/contents/?method=${selectionMethod}&mission=${selectionMission}&type=${selectionType}`,
    );

    if (data.docs[0] == undefined) {
      toast(t('noContentForFilter'), { type: 'error' });
    } else {
      setConteudo(data.docs);
      setSelection(data.docs[0].type);
    }
  };

  return (
    <Container>
      <Header />
      <Wrapper>
        {!typeLogin && !showPlacements && (
          <ContainerSelect>
            <Column>
              <Select
                onChange={({ target }) => setSelectionMethod(target.value)}
              >
                {methods?.map((method, index) =>
                  method.title != undefined ? (
                    <option value={method.id}>{method.title}</option>
                  ) : (
                    ''
                  ),
                )}
              </Select>
              <Select
                onChange={({ target }) => setSelectionMission(target.value)}
              >
                {missions?.map((mission, index) =>
                  mission.title != undefined ? (
                    <option value={mission.id}>{mission.title}</option>
                  ) : (
                    ''
                  ),
                )}
              </Select>
              <Select onChange={({ target }) => setSelectionType(target.value)}>
                {valueVerification.map((e, index) => (
                  <option value={e.type}>{e.title}</option>
                ))}
              </Select>
              <Button middle onClick={e => handleClickEditar(e)}>
                {t('edit')}
              </Button>
              <Button middle>{t('update')}</Button>
            </Column>

            <Column secondary>
              <Select>
                <option value="">{t('midtermExam')}</option>
              </Select>
              <Select>
                <option value="">{t('oral')}</option>
              </Select>
              <Button>{t('apply')}</Button>
            </Column>
          </ContainerSelect>
        )}
      </Wrapper>
      <Content>
        {!showPlacements && (
          <>
            {selection === 'vocabulary' && <Vocabulary conteudo={conteudo} />}
            {selection === 'challenge' && <Questions conteudo={conteudo} />}
            {selection === 'reading_comprehension' && (
              <ReadingComprehension conteudo={conteudo} />
            )}
          </>
        )}

        <ColumnContent>
          <ContainerImage>
            <Image src="https://media.bizj.us/view/img/11644841/womanbusinessowner*1200xx4597-2591-526-429.jpg" />
          </ContainerImage>

          <ContainerImage chat>
            <Chat notFocus="false" />
          </ContainerImage>
        </ColumnContent>
      </Content>

      <Footer />
      <ModalMission
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <ContainerModal>
          <Text title>{t('finishClassNow')}</Text>
          <div className="container-img">
            <img src={ImageConference} alt="" />
            <form>
              <Text>{t('observations')}:</Text>
              <textarea placeholder="..." />
              <div className="container-buttons">
                <Text text>{t('finish7thMission')}</Text>
                <button type="button">{t('sim')}</button>
                <button type="button">{t('nao')}</button>
              </div>
            </form>
          </div>
          <button className="button" type="button">
            {t('finalizar')}
          </button>
        </ContainerModal>
      </ModalMission>
    </Container>
  );
}
