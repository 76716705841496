import React, { useState, useEffect, useCallback } from 'react';
import TimePicker from 'react-time-picker';
import { useAuth } from '../../../hooks/AuthProvider';
import { CalendarListTeacher } from '../CalendarListTeacher';
import { ListLessons } from '../ListLessons';
import { Container, ContainerTitle, Title, Wrapper, Button } from './styles';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { WEEKDAYS } from '../../../utils/constants';
import { getWeekDays } from '../../../utils/getWeekDays';
import { zeroPad } from '../../../utils/zeroPad';

export function CalendarProfile() {
  const { t } = useTranslation();

  const { user, typeLogin } = useAuth();
  const [schedules, setSchedules] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [schedulesIsLoading, setSchedulesIsLoading] = useState([]);
  const [startTime, setStartTime] = useState('10:00');
  const [endTime, setEndTime] = useState('11:00');
  const [weekday, setWeekday] = useState(() => {
    const date = new Date();

    return WEEKDAYS[date.getDay()];
  });
  const [scheduleToEdit, setScheduleToEdit] = useState();
  const fetchSchedules = useCallback(async () => {
    try {
      await api
        .get('/schedules', { params: { weekday: weekday.value } })
        .then(resp => setSchedules(resp.data));
    } catch (e) {
      toast('Error', { type: 'error' });
    }
  }, [weekday]);

  const fetchSchedulesParam = useCallback(async weekday => {
    try {
      await api
        .get('/schedules', { params: { weekday: weekday } })
        .then(resp => setSchedules(resp.data));
    } catch (e) {
      toast('Error', { type: 'error' });
    }
  }, []);

  const fetchAppointments = useCallback(async () => {
    try {
      const date = getWeekDays().find(w => w.weekday === weekday.value).date;
      await api
        .get('/appointments/me', { params: { date, status: 'accepted' } })
        .then(resp =>
          setAppointments(
            resp.data.map(appointment => {
              return {
                ...appointment,
                teacher: appointment.attendees.find(t => t.type === 'teacher'),
              };
            }),
          ),
        );
    } catch (e) {
      console.log(e);
      toast('Error', { type: err.message || 'error' });
    }
  }, [weekday]);

  useEffect(() => {
    if (user.type === 'student') {
      fetchAppointments();
    }
    if (user.type === 'teacher') {
      fetchSchedules();
    }
  }, [user, fetchSchedules, fetchAppointments]);

  const handleSubmit = useCallback(async () => {
    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      await api.post('/schedules', {
        weekday: weekday.value,
        startTime: startTime,
        endTime: endTime,
        timeZone,
      });

      toast('Schedules have been saved!', { type: 'success' });
    } catch (e) {
      toast(t('thisLessonHasAlreadyBeenBooked'), { type: 'error' });
    } finally {
      fetchSchedules();
    }
  }, [weekday, startTime, endTime]);

  const handleEditSchedule = useCallback(async () => {
    try {
      await api.put(`/schedules/${scheduleToEdit.id}`, {
        weekday: scheduleToEdit.weekday,
        startTime: startTime,
        endTime: endTime,
      });

      setScheduleToEdit(undefined);

      toast('Schedules have been saved!', { type: 'success' });
    } catch (e) {
      console.log(e.message);
      toast('Error', { type: 'error' });
    } finally {
      fetchSchedules();
    }
  }, [scheduleToEdit, startTime, endTime]);

  const handleDeleteSchedule = useCallback(async id => {
    try {
      const { data } = await api.delete(`/schedules/${id}`);

      const index = data?.weekday ?? 0;

      setWeekday(WEEKDAYS[index]);

      fetchSchedulesParam(index);

      toast('Schedules have been saved!', { type: 'success' });
    } catch (e) {
      toast('Error', { type: 'error' });
    }
  }, []);

  const setTimeUp = () => {
    let [hour, minute] = startTime.split(':');
    minute = Number(minute) + 15;

    if (minute === Number('60')) {
      hour++;
      minute = '00';
    }
    if (hour === Number('24')) {
      hour = '00';
    }
    setStartTime(`${zeroPad(hour, 2)}:${zeroPad(minute, 2)}`);
    let newSet = Number(hour) + 1;
    if (newSet == Number('24')) newSet = '00';
    setEndTime(`${zeroPad(newSet, 2)}:${zeroPad(minute, 2)}`);
  };

  const setTimeDown = () => {
    let [hour, minute] = startTime.split(':');
    hour = Number(hour);
    minute = Number(minute);

    if (minute <= Number('00')) {
      hour--;
      minute = '45';
    } else {
      minute -= 15;
    }

    if (hour < Number('00')) {
      hour = '23';
    }
    setStartTime(`${zeroPad(hour, 2)}:${zeroPad(minute, 2)}`);
    let newSet = Number(hour) + 1;
    if (newSet == Number('24')) newSet = '00';
    setEndTime(`${zeroPad(newSet, 2)}:${zeroPad(minute, 2)}`);
  };

  const onEditSchedule = schedule => {
    setScheduleToEdit(schedule);

    let [hourStart, minuteStart] = schedule.startTime.split(':');
    if (hourStart == '24') hourStart = '00';
    setStartTime(`${hourStart}:${minuteStart}`);

    let [hourEnd, minuteEnd] = schedule.endTime.split(':');
    if (hourEnd == '24') hourEnd = '00';
    setEndTime(`${hourEnd}:${minuteEnd}`);
  };

  const handleWeekdayUp = () => {
    setWeekday(prevState => {
      if (prevState.value === 6) {
        return WEEKDAYS[0];
      }

      return WEEKDAYS[prevState.value + 1];
    });
  };

  const handleWeekdayDown = () => {
    setWeekday(prevState => {
      if (prevState.value === 0) {
        return WEEKDAYS[6];
      }

      return WEEKDAYS[prevState.value - 1];
    });
  };

  return (
    <Container>
      <ContainerTitle>
        <Title>{t('mySchedules')}</Title>
      </ContainerTitle>
      <ContainerTitle week>
        <Button type="button" onClick={handleWeekdayDown}>
          <span
            className="iconify"
            data-icon="dashicons:arrow-left-alt2"
            data-inline="false"
          />
        </Button>
        <Title day>{t(weekday.label)}</Title>
        <Button type="button" onClick={handleWeekdayUp}>
          <span
            className="iconify"
            data-icon="dashicons:arrow-right-alt2"
            data-inline="false"
          />
        </Button>
      </ContainerTitle>

      {!typeLogin && (
        <ContainerTitle hours>
          <div className="hours">
            <div className="icon-up">
              <button type="button" onClick={setTimeUp}>
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-up-alt2"
                  data-inline="false"
                />
              </button>
            </div>
            <TimePicker
              onChange={setStartTime}
              value={startTime}
              clearIcon={null}
              clockIcon={null}
              disableClock
              className="react-time-picker"
            />
            <div className="icon-down">
              <button type="button" onClick={setTimeDown}>
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-down-alt2"
                  data-inline="false"
                />
              </button>
            </div>
          </div>
          <span className="as">{t('to')}</span>
          <div className="hours">
            <div className="icon-up">
              {/* <span
                className="iconify"
                data-icon="dashicons:arrow-up-alt2"
                data-inline="false"
              /> */}
            </div>
            <TimePicker
              onChange={setEndTime}
              value={endTime}
              clearIcon={null}
              clockIcon={null}
              disableClock
              className="react-time-picker"
            />
            <div className="icon-down">
              {/* <span
                className="iconify"
                data-icon="dashicons:arrow-down-alt2"
                data-inline="false"
              /> */}
            </div>
          </div>
          {scheduleToEdit ? (
            <button
              style={{ backgroundColor: '#dbad00' }}
              type="button"
              onClick={handleEditSchedule}
            >
              {t('update')}
            </button>
          ) : (
            <button type="button" onClick={handleSubmit}>
              {t('add')}
            </button>
          )}
        </ContainerTitle>
      )}

      <Wrapper typeLogin={typeLogin}>
        {!!typeLogin && (
          <>
            {appointments.map((appointment, key) => (
              <ListLessons
                key={key}
                appointment={appointment}
                fetchAppointments={fetchAppointments}
              />
            ))}
          </>
        )}
        {!typeLogin && (
          <>
            {schedules.map((schedule, key) => (
              <CalendarListTeacher
                key={key}
                schedule={schedule}
                onEdit={schedule => {
                  onEditSchedule(schedule);
                }}
                onDelete={handleDeleteSchedule}
              />
            ))}
          </>
        )}
      </Wrapper>
    </Container>
  );
}
