import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-right: 1.2rem;
  border: 1px solid var(--gray3);
  width: 100%;

  header {
    height: 6rem;
    background: #f9f9f9;
    border-bottom: 1px solid var(--gray3);
    border-radius: 0.5rem 0.5rem 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  footer {
    height: 6rem;
    background: #f9f9f9;
    width: 100%;
    border-radius: 0 0 0.5rem 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .column {
    display: flex;
    align-items: center;
  }
`;

export const SectionQuestion = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem;
  border-bottom: 1px solid var(--gray3);
`;

export const SectionReadingComprehension = styled.section`
  height: 39rem;
  overflow: auto;
  background: #fff;
  border-bottom: 1px solid var(--gray3);

  article {
    font-size: 1.9rem;
    padding: 2rem;
    color: #243342;
    text-align: justify;
    line-height: 5rem;
  }
`;

export const SectionVocabulary = styled.div``;

export const Button = styled.button`
  height: 6rem;
  width: 8rem;
  border-right: 1px solid var(--gray3);
  color: #243342;
  cursor: pointer;
  display: ${props => props.disabled && 'none'};

  .iconify {
    font-size: 2rem;
  }

  ${props =>
    props.finaly &&
    css`
      border-right: none;
      border-left: 1px solid var(--gray3);
    `}

  ${props =>
    props.left &&
    css`
      border-left: 1px solid var(--gray3);
    `}
`;

export const ContainerText = styled.div`
  display: flex;
  margin-left: 1.2rem;

  @media (max-width: 560px) {
    display: none;
  }
`;

export const ContainerImage = styled.div`
  /* height: calc(100vh - 270px); */
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    height: auto;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`;

export const IconFlag = styled.img`
  cursor: pointer;
  ${props =>
    props.vocabulary &&
    css`
      height: 6rem;
      width: 6rem;
    `}

  ${props =>
    props.question &&
    css`
      height: 4rem;
      width: 4rem;
      margin: 0 1.5rem;
    `}

  ${props =>
    props.readingComprehension &&
    css`
      height: 4rem;
      width: 4rem;
      margin: 0 1.2rem;
    `}
`;

export const Text = styled.span`
  font-size: 1.9rem;
  color: #243342;
  margin-right: 1.2rem;
  padding-right: 1.2rem;
  border-right: 1px solid var(--gray3);

  ${props =>
    props.finaly &&
    css`
      border-right: none;
    `}

  ${props =>
    props.footer &&
    css`
      border-right: none;
      margin: 0 6rem;
    `}

  ${props =>
    props.list &&
    css`
      border-right: none;
    `}
`;

/* ESTILO DA IMAGEM FULL SCREEN */

export const ImageFull = styled.img`
  max-height: calc(100vh - 90px);
  max-width: 100%;
  border: 1px solid #fff;
`;

export const ContainerImageFullScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.9);
`;

export const WrapperHeaderImageFullScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 20px;
`;

export const TitleImageFullScreen = styled.p`
  font-size: 16px;
  color: #fff;
  background-color: #000;
  padding: 1rem 2rem;
  border: 1px solid #fff;
`;

export const WrapperButtonClose = styled.button`
  color: #fff;
  position: absolute;
  right: 0;
  margin-right: 2rem;
  margin-top: 2rem;

  &:hover {
    color: var(--primary-color-yellow);
  }
`;
