import styled, { css } from 'styled-components';

interface TextTopProps {
  button?: boolean;
  quetions?: boolean;
  warning?: boolean;
}

interface ButtonPassProps {
  next?: boolean;
}

export const Text = styled.span<TextTopProps>`
  font-size: 1.8rem;
  color: #5e5873;
  font-weight: 600;

  ${(props) => props.button
    && css`
      font-weight: 500;
      color: #243342;
      font-size: 1.5rem;
      padding: 0 2rem;
    `}

  ${(props) => props.quetions
    && css`
      padding: 2rem 0;
    `}

  ${(props) => props.warning
    && css`
      padding: 2rem 0;
      font-weight: 500;
      margin: 0 12rem;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerButton = styled.div`
  margin-top: 5rem;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 1rem;
`;

export const ButtonPass = styled.button<ButtonPassProps>`
  height: 5rem;
  width: 20rem;
  border-radius: 0.4rem;
  background: #ebe9f1;
  color: #243342;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.next
    && css`
      background: ${props.disabled ? '#cccccc' : '#243342'};
      color: #fff;
    `}
`;
