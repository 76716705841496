import styled, { css } from 'styled-components';
import BackgroundWhite from '../../../assets/background_message.svg'; // TODO Alterar imagem de fundo

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: inherit;
`;

export const Form = styled.form``;

export const HeaderMessage = styled.div`
  width: 100%;
  height: 6rem;
  padding: 1rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--gray3);
  border-radius: 0.5rem 0 0 0.5rem;
`;

export const ColumnHeaderMessage = styled.div`
  display: flex;
  width: auto;
  @media (max-width: 728px) {
    max-width: 20rem;
  }
`;

export const ContentAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Avatar = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const DetailsUser = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  width: auto;
  align-items: baseline;
  & i {
    color: #ccc;
  }
`;

export const TextContact = styled.h1`
  color: #5e5873;
  font-weight: 1.2rem;
  font-weight: 500;
  white-space: nowrap;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    color: #5e5873;
    font-size: 12px;
  }
`;

export const Status = styled.div`
  background-color: #ccc;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  ${(props) => props.online && css`
    background-color: #07bc0c;
    /* animation: status 0.7s infinite; */
  `};

  @keyframes status {
    from { box-shadow: 0px 0px 6px -1px #07bc0c; }
    to { box-shadow: 0px 0px 6px 3px #07bc0c; }
  }
`;

export const Icon = styled.img`
  width: 0.5rem;
  margin: 0 1rem;
  cursor: pointer;
`;

export const InputSearch = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 1rem;
  width: 100%;
  height: 4rem;
  font-size: 15px;
  padding: 0.7rem;

  @media (max-width: 728px) {
    max-width: 10rem;
  }
`;

export const BackgroundChat = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  background-image: url(${BackgroundWhite});
  border-right: 1px solid var(--gray3);
  border-left: 1px solid var(--gray3);

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--gray6);
    opacity: 0.6;
    border-radius: 20px;
  }
`;

export const ContainerSend = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  background-color: white;
  border: 1px solid var(--gray3);
  align-items: center;
`;

export const InputSend = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 1rem;
  height: 100%;
  padding: 0.7rem;
  width: calc(100% - 10rem);
  font-size: 15px;
`;

export const ButtonSend = styled.button`
  width: 10rem;
  height: 100%;
  background-color: #002e5c;
  border-radius: 5px;
  color: white;
  padding: 1rem;
  transition: 300ms;
  cursor: pointer;
  &:hover {
    background-color: #014440;
  }
`;

// COMPONET CHAT TAB

export const ChatTabContainer = styled.div`
  width: 100%;
  min-height: 6rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 1rem;
  cursor: pointer;

  ${(props) =>
    props.isOpen === 'false' &&
    css`
      background: white;
    `};

  ${(props) =>
    props.isOpen === 'true' &&
    css`
      background: #083059;

      h1 {
        color: #fff;
      }
    `};

  ${(props) =>
    props.isOpen === 'true' &&
    css`
      .arrow {
        display: fixed;
        background: #083059;
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        margin-left: 16px;
        position: absolute;
        left: -2.9rem;
        z-index: 10;
      }
    `};
`;

export const Indicator = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 10rem;
  transform: rotate(45deg);
  position: absolute;
  right: 32rem;
  display: none;
`;

export const InfosUser = styled.div`
  margin-left: 1rem;
  width: 100%;
`;
export const NameUser = styled.h1`
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.5rem;

  ${(props) =>
    props.black &&
    css`
      color: black;
    `};
  ${(props) =>
    props.white &&
    css`
      color: white;
    `};
`;
export const Text1 = styled.h1`
  font-size: 1.2rem;
  font-weight: 300;
  ${(props) =>
    props.gray &&
    css`
      color: var(--gray7);
    `};
  ${(props) =>
    props.white &&
    css`
      color: white;
    `};
`;

export const WrapperTime = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 4rem;
  width: 7rem;
`;

export const NewMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.8rem;
  min-height: 1.8rem;
  border-radius: 50%;
  margin-top: 0.5rem;
`;
export const Number = styled.h1`
  font-size: 1.2rem;
  font-weight: 500;

  ${(props) =>
    props.badge &&
    css`
      border-radius: 50%;
      color: #fff;
      background: #ea5455;
      height: 2rem;
      width: 2rem;

      display: flex;
      align-items: center;
      justify-content: center;
    `}

  ${(props) =>
    props.badgeCount === '' &&
    css`
      display: none;
    `}

  ${(props) =>
    props.isOpen === 'true' &&
    css`
      display: none;
    `}
`;

// COMPONENTS DE MENSSAGEM

export const SentMessagem = styled.div`
  align-self: flex-start;
  max-width: 30rem;
  background: white;
  padding: 1.2rem;
  border-radius: 6px;
  margin-bottom: 1.2rem;
  box-shadow: 0.3rem 0.3rem 0.3rem var(--gray3);
`;
export const DeliveredMessagem = styled.div`
  align-self: flex-end;
  background: #002e5c;
  width: auto;
  max-width: 30rem;
  padding: 1.2rem;
  border-radius: 6px;
  margin-bottom: 1.2rem;
  box-shadow: 0.3rem 0.3rem 0.3rem var(--gray3);
`;

export const TextMessage = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;

  ${(props) =>
    props.gray &&
    css`
      color: var(--gray7);
    `};
  ${(props) =>
    props.white &&
    css`
      color: white;
    `};
`;

export const ContainerMessage = styled.div`
  display: flex;

  img {
    border-radius: 50%;
    width: 3.3rem;
    height: 3.3rem;
    object-fit: cover;
    margin-right: 1rem;
  }

  ${(props) =>
    props.delivered &&
    css`
      align-self: flex-end;
      justify-content: flex-end;
      width: auto;

      img {
        margin-left: 1rem;
        margin-right: 0;
      }
    `}
`;

export const WrapperOptions = styled.div`
    position: relative;
`;

export const BadgeStatus = styled.span`
  height: 1.4rem;
  width: 1.4rem;
  background: ${({ online }) => (online ? '#28c76f' : '#ccc')};
  border-radius: 50%;
  position: absolute;
  border: 2px solid #fff;
  left: 3.7rem;
  top: 3.7rem;
`;