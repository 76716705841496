import React, { useEffect, useRef, useState } from 'react';

import IconFlagEngland from '../../../../../assets/icon_bandeira_inglaterra.png';
import IconFlagEUA from '../../../../../assets/icon_bandeira_usa.png';
import { Container, Button, ContainerText, Text, IconFlag } from './styles';
import { toast } from 'react-toastify';
import { api } from '../../../../../services/api';
import { useTranslation } from 'react-i18next';

export function ReadingComprehension(props) {
  const { conteudo, currentContent, setCurrentContent } = props;

  const audioEl = useRef();
  const { t } = useTranslation();
  const handleAudioGoogle = async (languageCode, name) => {
    try {
      const voice = { languageCode, name };
      const text = document.getElementsByName('text')[0].innerText;

      const { data } = await api.post(`text/synthesize`, {
        voice,
        text,
      });

      audioEl.current.src = data.fileURL;
      audioEl.current.play();

      audioEl.current.addEventListener('ended', e => (e.target.src = ''));
    } catch (err) {
      console.log(err);
      toast('There is an audio recording issue. Please check your settings.', {
        type: 'error',
      });
    }
  };

  const handleArrow = ({ key }) => {
    if (key === 'ArrowLeft') {
      document.querySelector('.arrow-left')?.click();
    }

    if (key === 'ArrowRight') {
      document.querySelector('.arrow-right')?.click();
    }
  };

  function handleArrowClick(e) {
    if (e === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return;
        }

        return prevState - 1;
      });
    }
    if (e === 'ArrowRight') {
      setCurrentContent(prevState => {
        if (prevState === conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleArrow);

    return () => {
      window.removeEventListener('keyup', handleArrow);
    };
  }, []);

  return (
    <Container>
      <header>
        <div className="column">
          {currentContent === conteudo.length - 1 && (
            <Button onClick={() => setCurrentContent(0)}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
          <ContainerText>
            <Text>
              <strong>{conteudo[currentContent]?.method?.title}</strong>
            </Text>
            <Text>{conteudo[currentContent]?.mission?.title}</Text>
            <Text finaly>
              {conteudo[currentContent]?.readingComprehension?.title}
            </Text>
          </ContainerText>
        </div>
        <div className="column">
          {currentContent !== 0 && (
            <Button
              left
              onClick={e => handleArrowClick('ArrowLeft')}
              className="arrow-left"
            >
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
          {currentContent !== conteudo.length - 1 && (
            <Button
              finaly
              onClick={e => handleArrowClick('ArrowRight')}
              className="arrow-right"
            >
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
        </div>
      </header>

      <section>
        <article name="text">
          {conteudo[currentContent]?.readingComprehension?.text
            ?.split('\n\n')
            ?.map((str, key) => (
              <p key={key}>
                {str?.split('\n')?.map((_str, index) => (
                  <React.Fragment key={index}>
                    <br></br> {_str}
                  </React.Fragment>
                ))}
              </p>
            ))}

          {conteudo[currentContent]?.notes?.text
            ?.split('\n\n')
            ?.map((str, key) => (
              <p key={key}>
                {str?.split('\n')?.map((_str, index) => (
                  <React.Fragment key={index}>
                    <br></br> {_str}
                  </React.Fragment>
                ))}
              </p>
            ))}
        </article>
      </section>

      <footer>
        <IconFlag
          src={IconFlagEUA}
          onClick={() => handleAudioGoogle('en-US', 'en-US-Wavenet-A')}
        />
        <audio ref={audioEl} type="audio/mp3" controls />
        <IconFlag
          src={IconFlagEngland}
          onClick={() => handleAudioGoogle('en-GB', 'en-GB-Wavenet-A')}
        />
      </footer>
    </Container>
  );
}
