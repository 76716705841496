import { useTranslation } from 'react-i18next';
import {
  Container,
  ContainerLinks,
  Links,
} from './styles.menu';

export const Option = ({ handleClearAndDeleteContact, showOptionDelete }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ContainerLinks>
        <Links onClick={() => handleClearAndDeleteContact('clear')}>{t('clearMessages')}</Links>
        {showOptionDelete && 
          <Links onClick={() => handleClearAndDeleteContact('delete')}>{t('deleteConversation')}</Links>
        }
      </ContainerLinks>
    </Container>
  );
};
