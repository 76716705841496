import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Edit from './Edit';
import Financial from './Financial';
import { useAuth } from '../../../hooks/AuthProvider';
import { Container, ContainerAbasNav, Nav } from './styles';
import { useTranslation } from 'react-i18next';

export function EditProfile({ match }) {
  const { typeLogin } = useAuth();
  const { t } = useTranslation();
  return (
    <Container>
      <ContainerAbasNav>
        <Nav to={`${match.path}/profile`}>{t('profile')}</Nav>
        {!typeLogin && <Nav to={`${match.path}/financial`}>{t('financial')}</Nav>}
      </ContainerAbasNav>
      <Route
        exact
        path={`${match.url}/`}
        render={() => <Redirect to={`${match.url}/profile`} />}
      />
      <Route
        exact
        path={`${match.url}/profile`}
        component={(props) => <Edit {...props} />}
      />
      <Route
        exact
        path={`${match.url}/financial`}
        component={(props) => <Financial {...props} />}
      />
    </Container>
  );
}
