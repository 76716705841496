import React, { useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/AuthProvider';
import { Container } from './styles';

interface PreloadProps {
  children: React.ReactElement;
}

function Preload({ children }: PreloadProps): React.ReactElement {
  const history = useHistory();

  const { loading, user, signOut } = useAuth();

  useEffect(() => {
    if (user && user.type === 'teacher') {
      if (
        user.placementTest.status !== 'approved'
        && user.placementTest.canRedo
      ) {
        document.location.href = '/placement-test';
        // history.push('/placement-test');
      }

      if (user.placementTest.status === 'awaiting_approval') {
        history.push('/placement-test-done');
      }
      if (
        user.placementTest.status === 'failed'
        && !user.placementTest.canRedo
      ) {
        signOut();
      }
      if (
        user.placementTest.status === 'approved'
        && user.teachingKnowledgeTest.status !== 'approved'
      ) {
        history.push('/dashboard/teaching-knowledge-test');
      }
    }
  }, [history, signOut, user, loading]);

  return loading ? (
    <Container>
        <Loader
          visible
          type="ThreeDots"
          color="#124f8c"
          secondaryColor="#191919"
        />
    </Container>
  ) : (
    children
  );
}

export default Preload;
