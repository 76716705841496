import {
  Button, Divider, Grid, Paper, Step, StepButton, Stepper, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { City } from '../../../../models/city';
import { Plan } from '../../../../models/plan';
import { State } from '../../../../models/state';
import { api } from '../../../../services/api';
import { API_ROUTES } from '../../../../types/api-routes';
import { usePurchaseContext } from '../hooks/context-hook';
import { PurchaseFormStepOne } from './purchase-form-step-one';
import { PurchaseFormStepThree } from './purchase-form-step-three';
import { PurchaseFormStepThreeCheckout } from './purchase-form-step-three-checkout';
import { PurchaseFormStepTwo } from './purchase-form-step-two';

const stepItems = [
  { name: 'Endereço' },
  { name: 'Pagamento' },
  { name: 'Revise sua compra' },
];

interface ICheckoutError {
  code: string;
  description: string;
}

interface PaymentPayload {
  billingType: string;
  city?: City;
  creditCardHolderInfo: { 
    postalCode?: string; 
    addressNumber?: string  
  };
  creditCard?: {
    ccv?: number;
    expiryMonth?: number;
    expiryYear?: number;
    holderName?: string;
    number?: string;
  }
  postalCode?: string;
  planId?: string;
  state?: string ;
  cupomcode?: string;
  cpfcnpj?: string;
  installmentCount?: number;
}

function FormPurchaseContainer(): React.ReactElement {
  const [activeStep, setActiveStep] = useState(0);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [isCheckout, setIsCheckout] = useState<boolean>(false);  
  const history = useHistory();

  const context = usePurchaseContext();
  const { formData } = context;

  useEffect(() => {
    loadStates();
  }, []);

  useEffect(() => {
    loadCities();
  }, [formData.state]);

  function loadCities(): void {
    context.setFormData({ ...formData, city: undefined });
    if (formData.state) {
      api.get(`${API_ROUTES.CITIES_STATE}/${formData.state?.code}`).then((response) => {
        const { data } = response;
        setCities(data as City[]);
      });
    }
  }

  function loadStates(): void {
    api.get(API_ROUTES.STATES).then((response) => {
      const { data: { docs } } = response;
      setStates(docs
        .map((item: State) => item as State));
    });
  }

  function validateStepOne(): void {
    const error = {
      address: formData.address ? '' : 'Campo endereço obrigatório',
      state: formData.state ? '' : 'Campo estado obrigatório',
      city: formData.city ? '' : 'Campo cidade obrigatório',
      cep: formData.cep ? '' : 'Campo cep obrigatório',
      number: formData.number ? '' : 'Campo número obrigatório',
    } as Record<string, string>;
    
    const hasError = Object.keys(error).find(key => error[key] !== '');

    context.setFormError({ ...context.formError, ...error });
    hasError == null && setActiveStep(activeStep + 1);
  }

  function validateStepTwo(): void {
    const error = {
      cpfcnpj: formData.cpfcnpj ? '' : 'Campo CPF / Cnpj é obrigatório',
    } as Record<string, string>;

    const hasError = Object.keys(error).find(key => error[key] !== '');
    context.setFormError({ ...context.formError, ...error });

    hasError == null && setActiveStep(activeStep + 1);
  }

  function handleNextStep(): void {
    switch (activeStep) {
      case 0: validateStepOne(); break;
      case 1: validateStepTwo(); break;
      default: break;
    }
  }

  function handleBackStep(): void {
    setActiveStep(activeStep - 1);
  }

  function handleFinishPayment(): void {
    const payload = buildPayload();
    api.post(API_ROUTES.PLANS_CHECKOUT, payload).then(response => {
      if (payload.billingType === "CREDIT_CARD") {        
        history.push("/dashboard/method");
        setTimeout(() => {
          history.go(0);          
        }, 500)
        
        return;
      }
      
      const {data} = response;
      context.setPurchaseResponse(data);
      setIsCheckout(true);
    }).catch((error)=> {
      context.setFormError({...context.formError, checkout: error.response?.data?.message});      
    })
  }
  
  function buildPayload():PaymentPayload {
    return {
      planId: formData.plan?.id,
      billingType: formData.billingType || 'PIX',
      cpfcnpj: formData.cpfcnpj,
      state: formData.state?.id,
      city: formData.city,
      cupomcode: formData.cupomcode,
      postalCode: formData.cep,
      creditCardHolderInfo:{
        addressNumber: formData.number,
        postalCode: formData.cep,
      },
      creditCard: formData.creditCard,
      installmentCount: formData.installmentCount,
    }
  }

  function renderCheckoutError(): React.ReactElement {
    const errors = context.formError?.checkout as ICheckoutError[];
    
    if (errors?.length > 0) {
      return (
        <>
          <Grid item xs={12} paddingTop={4}><Divider /></Grid>
          <Grid item xs={12} display="flex" justifyContent="center" paddingTop={4}>
            <Typography variant="h6">
              <b>Ocorreram erros durante o pagamento verifique os items abaixo:</b>
            </Typography>
          </Grid>        
            {errors.map(item => (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Typography variant="h6">
                  {item.description}
                </Typography>
              </Grid>
            ))}        
        </>
      )
    }

    return(
      <></>
    )
  }

  return (
    <Grid container>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Paper elevation={1}>
          <Grid container spacing={2} padding={3}>
            <Grid item xs={12}>
              <Stepper nonLinear activeStep={activeStep}>
                {stepItems.map((stepItem, index) => (
                  <Step id={`step-item-${index}`}>
                    <StepButton color="inherit">
                      {stepItem.name}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>

            {/* steps */}
            <Grid item xs={12}>              
              {activeStep === 0 && <PurchaseFormStepOne cities={cities} states={states} />}
              {activeStep === 1 && <PurchaseFormStepTwo />}
              {activeStep === 2 && !isCheckout && <PurchaseFormStepThree />}
              {activeStep === 2 && isCheckout && <PurchaseFormStepThreeCheckout />}
              {/* <PurchaseFormStepThreeCheckout /> */}
            </Grid>

            <Grid item xs={12} paddingTop={8}>
                {renderCheckoutError()}                
            </Grid>

            {/* buttons */}
            <Grid xs={10} />
            <Grid item xs={1}>
              {[1, 2].includes(activeStep)
                && <Button variant="outlined" fullWidth onClick={handleBackStep}>Anterior</Button>}
            </Grid>
            <Grid item xs={1}>
              {[0, 1].includes(activeStep)
              && <Button variant="contained" fullWidth onClick={handleNextStep}>Próximo</Button>}
              {activeStep === 2 && !isCheckout
                && <Button variant="contained" fullWidth onClick={handleFinishPayment}>Finalizar</Button>}
            </Grid>
          </Grid>
        </Paper>

      </Grid>
      <Grid item xs={1} />
    </Grid>

  );
}

export {
  FormPurchaseContainer,
};
