import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  margin-top: 1rem;

  @media (max-width: 1660px) {
    display: grid;
    grid-template-areas:
      't1 t2'
      'f f';
    width: 100%;
    justify-content: start;
    gap: 1rem;
  }

  @media (max-width: 900px) {
    grid-template-areas:
      't'
      'm'
      'f';
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
