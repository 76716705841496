import React from 'react';
import Logo from '../../../../assets/icon_logo_login.png';

import {
  Container,
  ContainerBody,
  Writtenexercises,
  WrittenexercisesHeader,
  WrittenexercisesBody,
  WrittenexercisesImage,
  WrittenexercisesQuestions,
  ImageLogo,
  WrittenexercisesQuestionsGroupButtons,
  WrittenexercisesQuestionsChoice,
  WrittenexercisesQuestionsButtons,
  WrittenexercisesQuestionsUniqueButton,
} from './styles';
import { Span } from '../../../../components/Span';

import { Button } from '../../../../components/Button';
import { useTranslation } from 'react-i18next';

export function DashboardStudentQuiz() {
  const { t } = useTranslation();

  return (
    <Container>
      <ContainerBody>
        <Writtenexercises>
          <WrittenexercisesHeader>
            <Span>{t('voltar')}</Span>
            <Span>{t('foods')}</Span>
          </WrittenexercisesHeader>
          <WrittenexercisesBody>
            <WrittenexercisesImage>
              <ImageLogo src={Logo} />
            </WrittenexercisesImage>
            <WrittenexercisesQuestions>
              <Span>{t('chooseTheCorrectAnswer')}</Span>
              <WrittenexercisesQuestionsChoice>
                <Button color="#fff" fontColor="#8a8895" title={t('finishLater')} />
                <Button color="#fff" fontColor="#8a8895" title={t('finishLater')} />
                <Button color="#fff" fontColor="#8a8895" title={t('finishLater')} />
                <Button color="#fff" fontColor="#8a8895" title={t('finishLater')} />
              </WrittenexercisesQuestionsChoice>

              <WrittenexercisesQuestionsGroupButtons>
                <WrittenexercisesQuestionsButtons>
                  <Button color="#083059" fontColor="#fff" title={t('voltar')} />
                  <Button color="#083059" fontColor="#fff" title={t('next')} />
                </WrittenexercisesQuestionsButtons>
                <WrittenexercisesQuestionsUniqueButton>
                  <Button
                    color="#083059"
                    fontColor="#fff"
                    title={t('finishLater')}
                  />
                </WrittenexercisesQuestionsUniqueButton>
              </WrittenexercisesQuestionsGroupButtons>
            </WrittenexercisesQuestions>
          </WrittenexercisesBody>
        </Writtenexercises>
      </ContainerBody>
    </Container>
  );
}
