import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import {
  ContainerColumn3,
  ContainerColumn3Header,
  ContainerColumn3HeaderText,
  ContainerBodyVideos,
  ContainerBodyVideosSize,
  ContainerBodyVideosBody,
  ContainerBodyVideosBodyItem,
  ContainerBodyVideosBodyItemText,
} from './styles';

import { Span } from '../../../../components/Span';

export function ListVideos({ data = [] }) {
  const { t } = useTranslation();

  return (
    <ContainerColumn3>
      <ContainerColumn3Header>
        <ContainerColumn3HeaderText>{t('videos')}</ContainerColumn3HeaderText>
      </ContainerColumn3Header>
      <ContainerBodyVideos>
        <ContainerBodyVideosSize>
          <ContainerBodyVideosBody>
            {data.map(video => (
              <ContainerBodyVideosBodyItem>
                <ReactPlayer controls
                  url={video.fileURL}
                  width="90%"
                  height="75%"
                  light={video.thumbnailURL}
                />
                <ContainerBodyVideosBodyItemText>
                  <Span>{video.title}</Span>
                </ContainerBodyVideosBodyItemText>
              </ContainerBodyVideosBodyItem>
            ))}
          </ContainerBodyVideosBody>
        </ContainerBodyVideosSize>
      </ContainerBodyVideos>
    </ContainerColumn3>
  );
}
