import React, { Fragment, useState, useEffect, useCallback } from 'react';
import ReactStars from 'react-stars';
import { Chat } from '../../Base/Chat';
import CaroselDates from '../CaroselDates';
import {
  Container,
  ListContainer,
  TextContainer,
  Image,
  Text,
  Button,
  ContainerMessage,
} from './styles';
import { parseISO, format } from 'date-fns';
// import ModalVideo from '../../Modals/ModalVideo';
import ModalPresentation from '../../Modals/ModalPresentation';
import { useTranslation } from 'react-i18next';

export function ListProffy({ teacher, isSelected, onSelectAppointment }) {
  console.log('teacher', teacher);
  const { t } = useTranslation();

  const [selectedSchedules, setSelectSchedules] = useState([]);

  const [showChat, setShowChat] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setSelectSchedules(teacher.schedules);
  }, [teacher.schedules]);

  // const handleSelectSchedule = useCallback(query => {
  //   setSelectSchedules(prevState => [...prevState, query]);
  // }, []);

  const handleRemoveSchedule = useCallback(index => {
    setSelectSchedules(prevState => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  }, []);

  function handleSelectAppointment() {
    onSelectAppointment(
      selectedSchedules.map(schedule => ({
        ...schedule,
        teacher: teacher,
      })),
    );
  }
  // console.log('teacher', teacher);
  return (
    <Fragment>
      <Container>
        <ListContainer>
          <Image src={teacher.avatarURL} />
          <TextContainer>
            <Text name>{teacher.fullName ?? teacher.name}</Text>
            <Text>{teacher.address && teacher.address.state}</Text>
            <Text date>
              {t('teacherSince')}:{' '}
              {format(parseISO(teacher.createdAt), 'dd/MM/yyyy')}
            </Text>
            {/* <Text star>
              4.9{' '}
              <ReactStars
                style={{ marginLeft: 20 }}
                count={5}
                size={15}
                value={4.5}
                color2="#DBAD00"
              />
            </Text> */}
          </TextContainer>
          {teacher.videoPresentationURL && (
            <Button
              curriculum
              onClick={() => {
                setShowVideo(true);
              }}
            >
              <span
                className="iconify"
                data-icon="icons8:video-file"
                data-inline="false"
              />
              {t('verPerfil')}
            </Button>
          )}
          <CaroselDates
            width="30rem"
            list="true"
            queries={selectedSchedules}
            onRemoveSchedule={handleRemoveSchedule}
          />
          <div className="container-buttons">
            <Button
              select
              isSelected={isSelected}
              onClick={handleSelectAppointment}
            >
              {isSelected ? t('selected') : t('choose')}
            </Button>
            <Button message onClick={() => setShowChat(true)}>
              <span
                className="iconify"
                data-icon="bi:chat-left-text"
                data-inline="false"
              />
              {t('enviarMensagem')}
              <span
                className="iconify"
                data-icon="dashicons:arrow-down-alt2"
                data-inline="false"
              />
            </Button>
          </div>
        </ListContainer>
      </Container>
      {showVideo && (
        <ModalPresentation
          open={showVideo}
          closed={() => {
            setShowVideo(false);
          }}
          urlVideo={teacher.videoPresentationURL}
          bio={teacher?.bio}
          linked={teacher?.linked}
        />
      )}
      {!!showChat && (
        <ContainerMessage>
          <div className="header-message">
            <span>{t('messages')}</span>
            <div className="buttons-right">
              {/* <span className="iconify" data-icon="eva:expand-fill" /> */}
              <button type="button" onClick={() => setShowChat(false)}>
                <span className="iconify" data-icon="eva:close-fill" />
              </button>
            </div>
          </div>
          <Chat
            sentId={teacher._id}
            imageURL={teacher.avatarURL}
            name={teacher.name}
          />
        </ContainerMessage>
      )}
    </Fragment>
  );
}
