import React, { useCallback, useRef, useState, useEffect } from 'react';

import BackgroundProfileImg from '../../../assets/background_profile.jpeg';
import ImgProfile from '../../../assets/image-profile-model.jpeg';
import IconProfile from '../../../assets/icon_profile_green.svg';
import { isAfiliate, isTeacher, useAuth } from '../../../hooks/AuthProvider';
import {
  ContainerProfile,
  BackgroundImageProfile,
  InfoProfile,
  ImageProfile,
  InfoUser,
  NameUser,
  UserTag,
  ContainerEdit,
  ContainerCount,
  Radar,
  IconRadar,
  ContainerIcon,
  ContainerRadarText,
  Switch,
  TextRadar,
  Span,
  SpanCount,
  EditProfile,
  ContainerSpace,
} from './styles';
import Radio from '../../Radio';
import { api } from '../../../services/api';
import { ContainerColumn2Header } from '../../../pages/Dashboard/Guideline/ReadBook/styles';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { PerfilType } from '../../../enums/PerfilType';

export function ContainerInfo({ match, url, urlNote }) {
  const { typeLogin, fetchUserData, user } = useAuth();
  const inputAvatarFile = useRef(null);
  const inputBackgroundFile = useRef(null);

  const [image, setImage] = useState('');
  const [imageBack, setImageBack] = useState('');
  const [checkedRadar, setCheckedRadar] = useState();
  const [numberStudents, setNumberStudents] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchNumberStudents() {
      await api
        .get(`/teachers/profile/${user.id}/number/students`)
        .then(response => {
          setNumberStudents(response.data['number']);
        });
    }

    fetchNumberStudents();
  }, []);

  const handleRadar = useCallback(
    async isChecked => {
      if (isChecked != checkedRadar) {
        try {
          await api.patch('/users/update-radar', {
            isChecked,
          });
        } catch (err) {
          console.log('Erro :', err);
        }

        setCheckedRadar(isChecked);

        fetchUserData(false);
      }
    },
    [fetchUserData],
  );

  const handleImgProfile = useCallback(async () => {
    const file = inputAvatarFile.current.files[0];
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await api.patch('/users/avatar', formData);

    setImage(data.fileAvatar);

    fetchUserData();
  }, [fetchUserData]);

  const handleImgBackground = useCallback(async () => {
    const file = inputBackgroundFile.current.files[0];
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await api.patch('/users/background', formData);

    setImageBack(data.fileBackground);

    fetchUserData();
  }, [fetchUserData]);

  function getPerfilTypeReference() {
    switch (user.type) {
      case PerfilType.AFILIATE: return (<UserTag>@Afiliate</UserTag>)
      case PerfilType.TEACHER: return (<UserTag>@Teacher</UserTag>);
      default: return (<UserTag>@Student</UserTag>);
    }
  }

  return (
    <ContainerProfile>
      <BackgroundImageProfile
        onClick={() => inputBackgroundFile.current.click()}
        src={imageBack || user.backgroundURL || BackgroundProfileImg}
      />
      <input
        type="file"
        ref={inputBackgroundFile}
        accept="image/png, image/jpeg"
        style={{ display: 'none' }}
        onChange={handleImgBackground}
      />
      <InfoProfile>
        <ContainerSpace>
          <ImageProfile
            onClick={() => inputAvatarFile.current.click()}
            src={image || user.avatarURL || ImgProfile}
          />
          <input
            type="file"
            ref={inputAvatarFile}
            accept="image/png, image/jpeg"
            style={{ display: 'none' }}
            onChange={handleImgProfile}
          />
          <InfoUser to={`${match.path}`}>
            <NameUser>
              {user.name} {user.lastName}
            </NameUser>
            {getPerfilTypeReference()}
          </InfoUser>
          <ContainerEdit>
            {!typeLogin && (
              <>
                {!isAfiliate() &&
                  <ContainerCount to={`${match.path}/classes`}>
                    <Span src={IconProfile} />
                    <SpanCount>
                      {' '}
                      {numberStudents} {t('alunos')}
                    </SpanCount>
                  </ContainerCount>
                }
                <EditProfile to={`${match.path}/editprofile`}>
                  <span
                    className="iconify"
                    data-icon="bx:bxs-pencil"
                    data-inline="false"
                  />
                  {t('editProfile')}
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                    data-inline="false"
                  />
                </EditProfile>
              </>
            )}

            {typeLogin && (
              <>
                <ContainerCount student to={url || `${match.path}/editprofile`}>
                  <span
                    className="iconify"
                    data-icon="bx:bxs-pencil"
                    data-inline="false"
                  />
                  <SpanCount> {t('editProfile')}</SpanCount>
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                    data-inline="false"
                  />
                </ContainerCount>
                <EditProfile student to={urlNote || `${match.path}/mynote`}>
                  <span
                    className="iconify"
                    data-icon="bx:bxs-pencil"
                    data-inline="false"
                  />
                  {t('myAnnotations')}
                  <span
                    className="iconify"
                    data-icon="dashicons:arrow-right-alt2"
                    data-inline="false"
                  />
                </EditProfile>
              </>
            )}
          </ContainerEdit>
        </ContainerSpace>
        {isTeacher() && (
          <ContainerSpace radar>
            <Radar onClick={e => handleRadar(e.target.checked)}>
              <ContainerIcon>
                <IconRadar
                  className="iconify"
                  data-icon="gg:media-podcast"
                  data-inline="false"
                />
              </ContainerIcon>
              <ContainerRadarText>
                <Switch>
                  <TextRadar>{t('radar')}</TextRadar>
                  {/* <TextRadar>Show me</TextRadar>
                  <TextRadar>on the students radar:</TextRadar> */}
                </Switch>
              </ContainerRadarText>
              <Radio checked={checkedRadar || user.radar} />
            </Radar>
          </ContainerSpace>
        )}
      </InfoProfile>
    </ContainerProfile>
  );
}
