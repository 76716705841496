import styled, { css } from 'styled-components';

interface ColumnProps {
  left?: boolean;
  right?: boolean;
}

interface TextProps {
  active?: boolean;
  bottom?: boolean;
  pointer?: boolean;
}

interface ButtonProps {
  schedule?: boolean;
}

export const Container = styled.div`
  width: 100rem;
  height: 40rem;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  position: absolute;
  z-index: 10;
  bottom: -39rem;
  right: 10rem;

  display: flex;
  flex-direction: column;
  padding: 1.2rem;

  @media (max-width: 1000px) {
    width: 90rem;
    right: 0rem;
  }

  @media (max-width: 950px) {
    width: 80rem;
    right: 0rem;
    height: auto;
  }

  @media (max-width: 860px) {
    width: 70rem;
    right: 0rem;
    height: auto;
  }

  @media (max-width: 768px) {
    position: revert;
  }

  @media (max-width: 650px) {
    width: 39rem;
  }
`;

/* export const Container2 = styled.div`
  width: 100rem;
  height: 40rem;
  background: #fff;
  border: 1px solid var(--gray3);
  border-radius: 0.4rem;
  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: column;
  padding: 1.2rem;
`; */

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1.2rem;
  justify-content: space-between;
  width: 100%;

  span {
    font-size: 1.8rem;
    color: #243342;
    font-weight: 900;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  @media (max-width: 650px) {
    display: grid;
    gap: 0.4rem;
    justify-items: center;
  }
`;

export const ContainerDate = styled.div`
  display: flex;
  flex-direction: column;
  width: 33rem;
`;

export const ContainerHour = styled.div`
  display: flex;
  flex-direction: column;
  width: 25rem;
  margin-left: 2rem;
  border: 1px solid #aaa;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const ContainerText = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #243342;
  color: #fff;

  span {
    font-size: 1.6rem;
  }
`;

export const WrapperHours = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.2rem;

  label {
    margin-bottom: 4px;
    font-size: 1.3rem;
  }

  input {
    margin-bottom: 16px;
  }

  .noIcon::-ms-clear {
    background-color: red;
    background: none;
    display: none;
  }

  .noIcon::-webkit-clear-button {
    background-color: red;
    background: none;
    display: none;
  }
`;

export const ColumnHours = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
    font-size: 1.4rem;
    color: #6e6b7b;
    padding: 1rem 0;
    border-bottom: 1px solid var(--gray3);
    width: 100%;
    text-align: center;
  }

  ${(props) => props.left
    && css`
      padding-left: 1rem;
      border-right: 1px solid var(--gray3);
    `}

  ${(props) => props.right
    && css`
      padding-right: 1rem;
    `}
`;

export const Text = styled.span<TextProps>`
  ${(props) => props.bottom
    && css`
      border-bottom: 0;
    `}

  ${(props) => props.pointer
    && css`
      cursor: pointer;
    `}
`;

export const ContainerActive = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--gray3);

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
    background: #243342;
    border-radius: 50%;
    border: 0;
    width: 4rem;
    font-weight: 600;
  }
`;

export const ContainerSelectedDate = styled.div`
  width: 35rem;
  margin-left: 1.2rem;

  @media (max-width: 650px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const HeaderSelectedDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #06231f;
  color: #fff;
  font-weight: 600;
  font-size: 1.4rem;
  height: 12rem;

  .iconify {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
`;

export const InfoSelected = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray3);

  font-size: 1.4rem;
  height: 4rem;
  margin-top: 1rem;
  position: relative;
`;

export const Button = styled.button<ButtonProps>`
  width: 100%;
  height: 4rem;
  background: #9e0d0d;
  color: #fff;
  margin-top: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .iconify {
    font-size: 1.8rem;
    margin-right: 0.5rem;
  }

  ${(props) => props.schedule
    && css`
      background: #243342;
    `}
`;

export const ContainerIcon = styled.div`
  position: absolute;
  height: 3rem;
  width: 3rem;
  background: #ecebee;
  color: #243342;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  bottom: -2rem;
`;
