import styled, { css } from 'styled-components';

interface ButtonTopProps {
  quetions?: boolean;
  right?: boolean;
}

interface TextTopProps {
  button?: boolean;
  quetions?: boolean;
  sendRecord?: boolean;
}

export const ContainerQuestions = styled.div`
  width: 100%;
  height: 50vh;
  background: #fff;
  margin: 3rem 0;
  border-radius: 0.4rem;
  overflow-y: auto;
  audio {
    margin: 16px 0;
  }
`;

export const ContainerTop = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid var(--gray3);

  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Text = styled.span<TextTopProps>`
  font-size: 1.8rem;
  color: #5e5873;
  margin-bottom: 3rem;

  ${(props) => props.button
    && css`
      font-weight: 500;
      color: #243342;
      font-size: 1.5rem;
      padding: 0 2rem;
      margin-bottom: 0;
    `}

  ${(props) => props.quetions
    && css`
      padding: 2rem 0;
      font-weight: 600;
      font-size: 1.8rem;
      margin-bottom: 0;
    `}
  ${(props) => props.sendRecord
    && css`
      font-weight: 600;
      font-size: 1.8rem;
      margin-bottom: 0;
    `}
`;

export const ContainerButtonsTop = styled.div`
  position: absolute;
  right: 0;
`;

export const ButtonTop = styled.button<ButtonTopProps>`
  padding: 0 1.5rem;
  border-right: 1px solid var(--gray3);
  border-left: 1px solid var(--gray3);
  height: 5rem;
  cursor: pointer;
  transition: 0.2s;

  :hover {
    background: var(--gray3);
  }

  .iconify {
    font-size: 2rem;
  }

  ${(props) => props.right
    && css`
      border-right: 0;

      :hover {
        border-radius: 0 0.4rem 0 0;
      }
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 8rem;

  span {
    text-align: center;
  }
`;

export const ButtonQuestions = styled.button<ButtonTopProps>`
  padding: 16px;
  width: 80%;
  background: #083059;
  border-radius: 0.4rem;
  margin-top: 1rem;
  color: #fff;
  font-size: 2rem;

  ${(props) => props.quetions
    && css`
      background: ${props.disabled ? '#cccccc' : '#243342'};
      width: 25rem;
      font-size: 1.6rem;
      font-weight: 500;
      margin: 2rem 0;
    `}
`;

export const ButtonRecord = styled.button`
  height: 7rem;
  width: 7rem;
  background: #f9f9f9;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
`;

export const ContainerRecord = styled.div`
  height: 5rem;
  width: 5rem;
  background: #ebe9f1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageRecord = styled.img``;
