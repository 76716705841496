/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import closeImg from '../../../assets/icon_close.svg';
import { Container } from './styles';
import './styles/styles.css';

Modal.setAppElement('#root');

export function ExamsAppliedModal({
  open,
  onRequestClose,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={open}
      contentLabel=""
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      style={{
        content: {
          minWidth: `100px`
        }
      }}
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" />
      </button>
      <Container >
        <h2>{t('appliedExamsMessage')}</h2>
        <button onClick={onRequestClose}>{t('fechar')}</button>
      </Container>
    </Modal>
  );
}
