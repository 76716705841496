import styled from 'styled-components';

export const Container = styled.span`
  color: #243342;
  font-size: 1.25rem;
  text-align: center;

  b {
    color: #d89b00;
  }
`;
