import React from 'react';
import { Button } from '../../components/Button';
import { Span } from '../../components/Span';
import Logo from '../../assets/icon_logo_login.png';
import IconFooterTalks from '../../assets/icon-green.svg';

import {
  Container,
  Column1,
  IconsLeft,
  Column2,
  ScrollForm,
  BaseDiv,
  Column2Form,
  ImageLogo,
  FooterCopy,
  FooterIconTalks,
  TextCopy,
  LinkConfirm,
} from './styles';

export function InsertEmail() {
  return (
    <Container>
      <Column1>
        <IconsLeft />
      </Column1>
      <Column2>
        <ScrollForm>
          <ImageLogo src={Logo} />
          <Column2Form>
            <BaseDiv>
              <Span>Informe o e-mail que deseja receber o código</Span>
            </BaseDiv>
            <BaseDiv>
              <input placeholder="E-mail" name="email" />
            </BaseDiv>
            <BaseDiv>
              <LinkConfirm to="/confirmemail">
                <Button
                  color="#243342"
                  fontColor="#fff"
                  title="Enviar Código"
                />
              </LinkConfirm>
            </BaseDiv>
          </Column2Form>
          <FooterCopy>
            <FooterIconTalks src={IconFooterTalks} />
            <TextCopy>Copyright © 2021 Table Talks</TextCopy>
          </FooterCopy>
        </ScrollForm>
      </Column2>
    </Container>
  );
}
