import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import IconFlagEngland from '../../../assets/icon_bandeira_inglaterra.png';
import IconFlagEUA from '../../../assets/icon_bandeira_usa.png';
import IconBackURL from '../../../assets/icon_back.png';
import { api } from '../../../services/api';
import {
  Container,
  Button,
  ContainerText,
  Text,
  IconFlag,
  IconBack,
  ButtonNextMission,
} from './styles';
import { socket } from '../../../services/contextSocket';
import { customState } from '../../../utils/customState';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export function Questions(props) {
  const { conteudo, currentContent, setCurrentContent, nextMission } = props;

  const location0 = useLocation();
  const location = customState(location0);
  const classRoom = location?.state?.appointmentId;
  const { t } = useTranslation();

  function createClassRoom() {
    socket.emit('classroom:create', classRoom);
  }

  function fetchClassRoom(content) {
    socket.emit('classroom:classcontent', { classRoom, content });

    const contentType = 'vocabulary';
    socket.emit('classroom:classcontenttype', { classRoom, contentType });

    const methodMission = {
      method: conteudo[currentContent]?.method?.title,
      mission: conteudo[currentContent]?.mission?.title,
    };

    socket.emit('classroom:classcontentmethodmission', {
      classRoom,
      methodMission,
    });

    socket.on('classroom:serverclassstudentjoin', () => {
      socket.emit('classroom:classcontent', { classRoom, content });
    });
  }

  useEffect(() => {
    createClassRoom();
    fetchClassRoom(conteudo[currentContent]);
  }, [conteudo]);

  const handleAudioGoogle = async (languageCode, name, text) => {
    try {
      const voice = { languageCode, name };

      const { data } = await api.post(`text/synthesize`, {
        voice,
        text,
      });

      var audio = new Audio(data.fileURL);
      audio.play();
    } catch (err) {
      console.error(err);
      toast('There is an audio recording issue. Please check your settings.', {
        type: 'error',
      });
    }
  };

  function handleArrow({ key }) {
    if (key === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return prevState;
        }

        return prevState - 1;
      });

      let current = currentContent;

      if (current === 0) {
        current = current;
      } else {
        current = current - 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }

    if (key === 'ArrowRight') {
      setCurrentContent(prevState => {
        if (prevState === conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });

      socket.emit('classroom:create', classRoom);

      let current = currentContent;

      if (current === 0) {
        current = current;
      } else {
        current = current + 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }
  }

  function handleArrowClick(e) {
    if (e === 'ArrowLeft') {
      setCurrentContent(prevState => {
        if (prevState === 0) {
          return;
        }

        return prevState - 1;
      });

      const classRoom = location.state?.appointmentId;

      let current = currentContent;

      if (current === 0) {
        current = current;
      } else {
        current = current - 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }

    if (e === 'ArrowRight') {
      setCurrentContent(prevState => {
        if (prevState === conteudo.length - 1) {
          return prevState;
        }

        return prevState + 1;
      });

      const classRoom = location.state?.appointmentId;

      socket.emit('classroom:create', classRoom);

      let current = currentContent;

      if (current === conteudo.length - 1) {
        current = current;
      } else {
        current = current + 1;
      }

      const content = conteudo[current];
      socket.emit('classroom:classcontent', { classRoom, content });

      const contentType = 'vocabulary';
      socket.emit('classroom:classcontenttype', { classRoom, contentType });

      const methodMission = {
        method: conteudo[currentContent]?.method?.title,
        mission: conteudo[currentContent]?.mission?.title,
      };

      socket.emit('classroom:classcontentmethodmission', {
        classRoom,
        methodMission,
      });
    }
  }

  function handleGoToBeginning() {
    setCurrentContent(0);

    const content = conteudo[0];
    socket.emit('classroom:classcontent', { classRoom, content });

    const contentType = 'vocabulary';
    socket.emit('classroom:classcontenttype', { classRoom, contentType });

    const methodMission = {
      method: conteudo[0]?.method?.title,
      mission: conteudo[0]?.mission?.title,
    };
    socket.emit('classroom:classcontentmethodmission', {
      classRoom,
      methodMission,
    });
  }

  useEffect(() => {
    window.addEventListener('keydown', handleArrow);

    return () => {
      window.removeEventListener('keydown', handleArrow);
    };
  });

  return (
    <Container>
      <header>
        <div className="column">
          <Button onClick={e => handleGoToBeginning()}>
            {' '}
            <span
              className="iconify"
              data-icon="dashicons:arrow-left-alt2"
              data-inline="false"
            />{' '}
          </Button>

          <ContainerText>
            <Text>
              <strong>{conteudo[currentContent]?.method?.title}</strong>
            </Text>
            <Text>{conteudo[currentContent]?.mission?.title}</Text>
            <Text finaly>
              {conteudo[currentContent]?.title === 'Review'
                ? t('review')
                : t('questoes')}{' '}
            </Text>
          </ContainerText>
        </div>
        <div className="column">
          {currentContent !== 0 && (
            <Button left onClick={e => handleArrowClick('ArrowLeft')}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-left-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}
          {currentContent !== conteudo.length - 1 && (
            <Button finaly onClick={e => handleArrowClick('ArrowRight')}>
              {' '}
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
                data-inline="false"
              />{' '}
            </Button>
          )}

          {/* Next Mission */}
          <ButtonNextMission onClick={e => nextMission()}>
            Next Mission{' '}
            <span
              className="iconify"
              data-icon="dashicons:arrow-right-alt2"
              data-inline="false"
            />
          </ButtonNextMission>
          {/* end */}
        </div>
      </header>

      {conteudo[currentContent]?.questions?.map((question, index) => (
        <section>
          <Text name="text" list>
            {question.text}
          </Text>
          <div>
            <IconFlag
              src={IconFlagEUA}
              onClick={() =>
                handleAudioGoogle('en-US', 'en-US-Wavenet-A', question.text)
              }
            />
            <IconFlag
              src={IconFlagEngland}
              onClick={() =>
                handleAudioGoogle('en-GB', 'en-GB-Wavenet-A', question.text)
              }
            />
          </div>
        </section>
      ))}

      {/* {currentContent === conteudo.length - 1 && (
        <footer>
          <IconBack src={IconBackURL} onClick={() => handleGoToBeginning()} />
        </footer>
      )} */}

      {/* <footer>
        <span
          className="iconify"
          data-icon="dashicons:arrow-left-alt2"
          data-inline="false"
        />{' '}

        <Text>1</Text>
        <Text>2</Text>
        <Text>3</Text>
        <Text medium>4</Text>
        <Text>5</Text>
        <Text>6</Text>
        <Text finaly>7</Text>
        <span
          className="iconify"
          data-icon="dashicons:arrow-right-alt2"
          data-inline="false"
        />
      </footer> */}
    </Container>
  );
}
