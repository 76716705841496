/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Modal from 'react-modal';

import {
  Container,
  ContainerHeader,
  TextsHeaderContainer,
  TextModal,
  ButtonModal,
  ContainerModal,
} from './styles.ListNote';
import Input from '../../Form/Input';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const ListNoteViewModal = ({ note, open, setOpen }) => {
  const closed = () => {
    setOpen(false);
  };

  const { t } = useTranslation();

  return (
    <Container>
      <Modal
        isOpen={open}
        contentLabel="Modal correction method"
        onRequestClose={closed}
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <ContainerModal>
          <header>
            <ContainerHeader>
              <TextsHeaderContainer>
                <TextModal>{t('titulo')}</TextModal>
                <TextModal mission>{note?.type}</TextModal>
                <TextModal>{t('notes')}</TextModal>
                <TextModal mission>{note?.description}</TextModal>
              </TextsHeaderContainer>
            </ContainerHeader>
          </header>
          <ButtonModal onClick={closed}>{t('fechar')}</ButtonModal>
        </ContainerModal>
      </Modal>
    </Container>
  );
};
