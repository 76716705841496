import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from '../../../services/api';
import { Question } from '../../../types/registerTest';
import { WrittenAnswer } from '../index';
import {
  ButtonQuestions,
  ContainerQuestions,
  ContainerTop,
  Text,
  TextArea,
  Wrapper,
} from './styles';

interface WrittenProps {
  answers: WrittenAnswer[];
  onSubmit: () => void;
  onWrittenAnswerChange: (answer: WrittenAnswer) => void;
}

export const Written = ({
  answers,
  onSubmit,
  onWrittenAnswerChange,
}: WrittenProps) => {
  const [activePage, setActivePage] = useState(0);
  const { t } = useTranslation();
  const [questions, setQuestions] = useState<Question[]>();

  useEffect(() => {
    async function fetchQuestions() {
      try {
        await api
          .get('/placement/questions', {
            params: { type: 'written', limit: 3 },
          })
          .then((response) => {
            setQuestions(response.data);
          });
      } catch (err) {
        console.log(err);
      }
    }

    fetchQuestions();
  }, []);

  const activeQuestion = useMemo(() => {
    if (!questions) return {} as Question;
    return questions[activePage];
  }, [activePage, questions]);

  function handleActivePageChange() {
    setActivePage((prevState) => prevState + 1);
  }

  if (!questions) return null;

  return (
    <ContainerQuestions>
      <ContainerTop>
        <Text>{t('questoes')}</Text>
        <Text button>
          {activePage + 1}
          /
          {questions.length}
        </Text>
      </ContainerTop>
      <Wrapper>
        <>
          <Text quetions>{activeQuestion?.text}</Text>
          <TextArea
            rows={15}
            value={
              answers.find((a) => a.questionId === activeQuestion?.id)?.answer
              || ''
            }
            onChange={(event) => {
              onWrittenAnswerChange({
                questionId: activeQuestion?.id,
                question: activeQuestion?.text,
                answer: event.target.value,
              });
            }}
          />
        </>
        {activePage === questions.length - 1 ? (
          <ButtonQuestions
            disabled={!answers.some((a) => a.questionId === activeQuestion?.id)}
            quetions
            onClick={onSubmit}
          >
            {t('enviar')}
          </ButtonQuestions>
        ) : (
          <ButtonQuestions
            disabled={!answers.some((a) => a.questionId === activeQuestion?.id)}
            quetions
            onClick={handleActivePageChange}
          >
            {t('next')}
          </ButtonQuestions>
        )}
      </Wrapper>
    </ContainerQuestions>
  );
};
