import { format } from 'date-fns';
import React, { useState } from 'react';

import Check from '../../../assets/svgMethod/icon_check_green.svg';
import Close from '../../../assets/svgMethod/icon_close_green.svg';
import Schedule from '../../../assets/svgMethod/icon_schedule_green.svg';
import Student from '../../../assets/svgMethod/icon_student_green.svg';
import Watch from '../../../assets/svgMethod/icon_watch_colapse.svg';
import { ListCorrectionModal } from './ListCorrectionModal';
import {
  Button,
  ButtonNoBorder,
  Container,
  ContainerButtons,
  ContainerHour,
  ContainerIcon,
  ContainerInfoLesson,
  ContainerTextsInfoLesson,
  ContainerTextsUser,
  ContainerTitle,
  ContainerUser,
  Icon,
  Image,
  List,
  Text,
} from './styles.ListCorrections';

/* eslint-disable react/destructuring-assignment */

interface IMission {
  title: string;
}

interface IStudent {
  avatarURL: string;
  fullName: string;
  name: string;
  studentLevel: string;
}
interface IQuestionsAnswers {
  _id: string;
  question: string;
  answer: string;
  isCorrected: boolean;
  isCorrectAnswer: number;
  obs: string;
}

interface ICorrections {
  _id: string;
  title: string;
  mission: IMission;
  student: IStudent;
  exam: any;
  quiz: any;
  exerciseDate: Date;
  totalCorrected: number;
  totalNotVerificated: number;
  answers: IQuestionsAnswers[];
}

interface ICorrectionsProps {
  corrections: ICorrections[];
  setCorrectionItem: (showCorrectionItem: boolean) => void;
  fetchMethods: any;
}

export const ListCorrectionItem = ({
  corrections,
  setCorrectionItem,
  fetchMethods,
}: ICorrectionsProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const [correction, setCorrection] = useState<ICorrections>();

  const [open, setOpen] = useState(false);

  const handleOpenModal = (event: ICorrections) => {
    setCorrection(event);
    setOpen(true);
  };

  const handleOpenCorrectionModal = (event: ICorrections) => {
    setCorrection(event);
    setIsOpen(true);
  };

  const handleCloseCorrectionModal = (openVar: boolean) => {
    setIsOpen(false);
    fetchMethods();
  };

  return (
    <Container>
      <ContainerTitle>
        <Text>Pending Corrections</Text>
        <ButtonNoBorder
          onClick={() => {
            setCorrectionItem(false);
          }}
        >
          <span className="iconify" data-icon="eva:close-outline" />
        </ButtonNoBorder>
      </ContainerTitle>

      {corrections?.map((item) => (
        <List>
          <ContainerHour>
            <ContainerIcon>
              <Icon src={Watch} />
            </ContainerIcon>
            {item?.exerciseDate && (
              <Text>{format(new Date(item?.exerciseDate), 'hh:ss aa')}</Text>
            )}
          </ContainerHour>

          <ContainerUser>
            {item.student.avatarURL && <Image src={item.student.avatarURL} />}
            <ContainerTextsUser>
              <Text>
                {item.student.fullName
                  ? item.student.fullName
                  : item.student.name}
              </Text>
              <Text>{item.student.studentLevel}</Text>
            </ContainerTextsUser>
          </ContainerUser>

          <ContainerInfoLesson division>
            <ContainerIcon>
              <Icon src={Student} />
            </ContainerIcon>
            <ContainerTextsInfoLesson>
              <Text>{item.mission.title}</Text>
            </ContainerTextsInfoLesson>
          </ContainerInfoLesson>

          {item?.exerciseDate && (
            <ContainerInfoLesson>
              <ContainerIcon>
                <Icon src={Schedule} />
              </ContainerIcon>
              <ContainerTextsInfoLesson>
                <Text>
                  {format(new Date(item?.exerciseDate), 'dd/MM/yyyy')}
                </Text>
              </ContainerTextsInfoLesson>
            </ContainerInfoLesson>
          )}

          <ContainerInfoLesson division={!!item?.exerciseDate}>
            <ContainerIcon>
              <Icon src={Check} />
            </ContainerIcon>
            <ContainerTextsInfoLesson>
              <Text>
                Corrected
                {item.totalCorrected}
              </Text>
            </ContainerTextsInfoLesson>
          </ContainerInfoLesson>
          <ContainerInfoLesson>
            <ContainerIcon>
              <Icon src={Close} />
            </ContainerIcon>
            <ContainerTextsInfoLesson>
              <Text>
                Missing
                {item.totalNotVerificated}
              </Text>
            </ContainerTextsInfoLesson>
          </ContainerInfoLesson>

          <ContainerButtons>
            <Button onClick={() => handleOpenModal(item)}>
              View
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
              />
            </Button>

            <Button onClick={() => handleOpenCorrectionModal(item)}>
              View and Correct
              <span
                className="iconify"
                data-icon="dashicons:arrow-right-alt2"
              />
            </Button>
          </ContainerButtons>
        </List>
      ))}

      {open && (
        <ListCorrectionModal
          viewMode
          correction={correction}
          modalIsOpen={open}
          setIsOpen={setOpen}
        />
      )}

      {modalIsOpen && (
        <ListCorrectionModal
          viewMode={false}
          correction={correction}
          modalIsOpen={modalIsOpen}
          setIsOpen={handleCloseCorrectionModal}
        />
      )}
    </Container>
  );
};
