import React, { useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import { Button } from '../../components/Button';
import { Span } from '../../components/Span';
import Logo from '../../assets/icon_logo_login.png';
import IconFooterTalks from '../../assets/icon-green.svg';

import {
  Container,
  Column1,
  IconsLeft,
  Column2,
  ScrollForm,
  BaseDiv,
  Column2Form,
  ImageLogo,
  FooterCopy,
  FooterIconTalks,
  TextCopy,
  BaseDivCode,
  LinkDashboard,
} from './styles';
import { useAuth } from '../../hooks/AuthProvider';
import { useTranslation } from 'react-i18next';

export function ConfirmEmail() {
  const { setAuthUser, setTypeLogin } = useAuth();
  const [login] = useState(false);
  const { t } = useTranslation();

  return (
    <Container>
      <Column1>
        <IconsLeft />
      </Column1>
      <Column2>
        <ScrollForm>
          <ImageLogo src={Logo} />
          <Column2Form>
            <BaseDiv>
              <Span>{t('youAreAlmostThere')}</Span>
            </BaseDiv>
            <BaseDiv>
              <Span>{t('weSendYouAnEmailToSet')}</Span>
            </BaseDiv>
            <BaseDiv>
              <p>{t('changeRegistrationEmail')}</p>
            </BaseDiv>
            <BaseDivCode>
              <ReactCodeInput autoFocus fieldWidth={45} fieldHeight={40} />
            </BaseDivCode>
            <BaseDiv>
              <LinkDashboard to="/dashboard">
                <Button
                  color="#243342"
                  fontColor="#fff"
                  title="Confirmar"
                  onClick={() => {
                    setAuthUser({ authenticated: true });
                    localStorage.setItem('Type', login);
                    setTypeLogin(true);
                  }}
                />
              </LinkDashboard>
            </BaseDiv>
            <BaseDiv>
              <Button
                color="none"
                fontColor="#243342"
                title={t('resendCode')}
              />
            </BaseDiv>
          </Column2Form>
          <FooterCopy>
            <FooterIconTalks src={IconFooterTalks} />
            <TextCopy>Copyright © 2021 Table Talks</TextCopy>
          </FooterCopy>
        </ScrollForm>
      </Column2>
    </Container>
  );
}
