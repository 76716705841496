import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AccessBook, Text } from '../../styles';
import { useTranslation } from 'react-i18next';

import ThumbnailPdf from '../../../../../assets/thumbnail-pdf.png';
import ThumbnailImage from '../../../../../assets/thumbnail-image.png';

function EbookComponent({ prev, title, desc, params, id, fileURL }) {
  const { t } = useTranslation();

  localStorage.setItem('idEbook', id);

  const history = useHistory();
  const [thumbnailNotImg, setThumbnailNotImg] = useState();

  function handleOnclick(e) {
    localStorage.setItem('state', JSON.stringify({ id }));
    history.push('/dashboard/guidelines/readbook', { id: id });
  }

  useEffect(() => {
    if (!prev && fileURL) {
      if (fileURL.includes('.pdf')) {
        setThumbnailNotImg(ThumbnailPdf);
      } else {
        setThumbnailNotImg(ThumbnailImage);
      }
    }

    if (prev?.includes('.pdf')) {
      prev = null;
      setThumbnailNotImg(ThumbnailPdf);
    }
  }, [prev, fileURL]);

  return (
    <div className="container-ebook">
      <img
        src={
          (prev?.includes('.pdf') ? thumbnailNotImg : prev) || thumbnailNotImg
        }
        alt="ebook"
      />
      <Text title>
        {title?.toString()?.substring(0, 50)}{' '}
        {title?.toString()?.length > 50 ? '...' : ''}
      </Text>
      <Text>
        {desc?.toString()?.substring(0, 150)} {desc?.toString() || ''}
      </Text>
      <button onClick={e => handleOnclick(e)}>{t('read')}</button>
    </div>
  );
}

export const Ebook = memo(EbookComponent);
