import styled from 'styled-components';
import Select from 'react-select';

export const WrapperSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .selectlangage {
    border: 0;
  }
`;

export const Flags = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
`;

export const SelectLanguage = styled(Select)`
  width: 12rem;
  font-size: 1.3rem;
  color: #243342;
  display: flex;
  align-items: center;
  height: 4rem;
`;
