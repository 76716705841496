import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid var(--green3);
  border-radius: 0 0 0.5rem 0.5rem;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  background: var(--secondary-color-blue);
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  height: 5.8rem;
  font-size: 2rem;
  color: #fff;

  span {
    margin-right: 3rem;
  }
`;

export const Text = styled.span`
  color: #b9b9c3;
  font-size: 1.4rem;
  ${props =>
    props.title &&
    css`
      font-size: 2rem;
      color: #083059;
      font-weight: 650;
    `}

  ${props =>
    props.header &&
    css`
      font-size: 2rem;
      color: #fff;
      font-weight: 650;
    `}

  ${props =>
    props.proffy &&
    css`
      font-size: 1.4rem;
      color: var(--secondary-color-blue);
    `}

  ${props =>
    props.date &&
    css`
      font-size: 0.9rem;
      color: var(--secondary-color-blue);
    `}

  ${props =>
    props.name &&
    css`
      font-size: 1.7rem;
      font-weight: 650;
      color: #b9b9c3;
    `}

  ${props =>
    props.hours &&
    css`
      font-size: 1.2rem;
      font-weight: 650;
      color: #b9b9c3;
    `}

  ${props =>
    props.note &&
    css`
      font-size: 1.4rem;
      font-weight: 750;
      color: #083059;
    `}

    ${props =>
    props.titleModal &&
    css`
      font-size: 2rem;
      color: #5e5873;
      font-weight: 650;
    `}

    @media (max-width: 450px) {
    display: grid;
    justify-items: center;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;

  ${props =>
    props.selects &&
    css`
      justify-content: flex-end;
      width: 32rem;

      select {
        color: var(--secondary-color-blue);
        background: #fff;
        height: 3.8rem;
        margin: 0;
        width: 14rem;
        font-size: 1.7rem;
        margin-left: 1rem;
        padding: 0 2rem;
        border-radius: 0;
      }
    `}
`;

export const Select = styled.select`
  color: var(--secondary-color-blue);
  background: #fff;
  height: 3.8rem;
  margin-left: 1rem;
  padding: 0 2rem;
`;

export const ContainerExercise = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  height: 5.8rem;
  border-bottom: 1px solid var(--gray3);
  margin-top: 2rem;

  @media (max-width: 900px) {
    display: grid;
    height: auto;
    gap: 0.4rem;
    padding-bottom: 0.4rem;
  }

  @media (max-width: 450px) {
    display: grid;
    justify-content: center;
  }
`;

export const ContainerExerciseList = styled.div`
  display: flex;
  align-items: center;

  .Icon {
    margin-right: 0.5rem;
  }

  .container-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32.51px;
    height: 32.51px;
    border-radius: 50%;
    background: #28c76f20;
    color: #083059;
    font-size: 1.6rem;
    margin-right: 0.5rem;
  }

  .iconify {
    font-size: 1.6rem;
  }

  @media (max-width: 450px) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    width: 100%;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.2rem;
  margin-right: 5rem;
  width: auto;

  ${props =>
    props.sizeFixed &&
    css`
      width: 25rem;
    `}

  @media (max-width: 450px) {
    text-align: center;
    width: auto;
  }
`;

export const Image = styled.img`
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
`;

export const Button = styled.button`
  border: 1px solid #ebe9f1;
  color: ${props => props.color};
  background: ${props => props.back};
  height: 3.8rem;
  width: 18rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;

  .iconify {
    margin-right: 0.5rem;
  }

  ${props =>
    props.inital &&
    css`
      justify-content: space-between;
    `}

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const ContainerModal = styled.div`
  width: 60rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.4rem;

  span {
    color: #5e5873;
    font-weight: 600;
    font-size: 1.6rem;
  }
`;

export const ContainerHeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--gray3);
`;

export const ContainerListModal = styled.div`
  display: flex;
  overflow-y: auto;
  max-height: 450px;
  flex-direction: column;
  padding: 1rem 2rem 0;
  overflow-y: auto;
`;

export const ListModal = styled.div`
  width: 100%;
  border: 1px solid var(--gray3);
  margin-bottom: 1rem;
`;

export const ContainerTextModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;

  ${props =>
    props.bottom &&
    css`
      border-top: 1px solid var(--gray3);
    `}
`;

export const BottonModal = styled.div`
  height: 4rem;
  width: 100%;
  background: #083059;
  border-radius: 0.4rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #fff;
  font-size: 1.6rem;
`;
