/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from 'date-fns';
import React, {
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';

import { useAuth } from '../../../hooks/AuthProvider';
import { api } from '../../../services/api';
import { Appointment } from '../Schedule';
import { ListScheduleDayItemLine } from './ListScheduleDay.Item.Line';
import {
  Container, ContainerTitle, Text, Button,
} from './styles.listSchedule';

interface StateProps {
  setShowScheduleItem: Dispatch<SetStateAction<boolean>>;
  method: any;
}

export const ListScheduleDayItem = ({
  setShowScheduleItem,
  method,
}: StateProps) => {
  console.log('METHOD ID ', method);

  const { user } = useAuth();
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const fetchAppointments = useCallback(async () => {
    try {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

      await api
        .get<Appointment[]>(`/appointments/day-class/${user.id}/`, {
          params: { timeZone, status: 'accepted', method: method?.method?._id },
        })
        .then((response) => {
          setAppointments(response.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [method?.method?._id, user.id]);

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  return (
    <Container>
      <ContainerTitle>
        <Text>
          Today Schedule:
          {format(Date.now(), 'dd/MM/yyyy')}
        </Text>
        <Button onClick={() => setShowScheduleItem(false)} close>
          <span className="iconify" data-icon="eva:close-outline" />
        </Button>
      </ContainerTitle>

      <ListScheduleDayItemLine
        appointments={appointments}
        fetchAppointments={fetchAppointments}
      />
    </Container>
  );
};
