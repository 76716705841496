import React, { useState } from 'react';

import IconFlagEngland from '../../../assets/icon_bandeira_inglaterra.png';
import IconFlagEUA from '../../../assets/icon_bandeira_usa.png';
import { Container, ContainerText, Text, IconFlag } from './styles';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export function StudentReadingComprehension({ conteudo, methodMission }) {
  const [audioText, setAudio] = useState();
  const { t } = useTranslation();
  const handleAudioGoogle = async (languageCode, name) => {
    try {
      const voice = { languageCode, name };
      const text = document.getElementsByName('text')[0].innerText;

      console.log(voice, text);
      const { data } = await api.post(`text/synthesize`, {
        voice,
        text,
      });

      setAudio(data.fileURL);
    } catch (err) {
      console.log(err);
      toast('There is an audio recording issue. Please check your settings.', {
        type: 'error',
      });
    }
  };

  return (
    <Container>
      <header>
        <div className="column">
          <ContainerText>
            <Text>
              <strong>{methodMission?.method}</strong>
            </Text>
            <Text>{methodMission?.mission}</Text>
            <Text finaly>
              {conteudo?.readingComprehension
                ? conteudo?.readingComprehension?.title
                : t('readingComprehesion')}
            </Text>
          </ContainerText>
        </div>
      </header>

      <section>
        <article name="text" style={{ lineHeight: '1.2' }}>
          {conteudo?.readingComprehension?.text
            ?.split('\n\n')
            ?.map((str, key) => (
              <p key={key}>
                {str?.split('\n')?.map((_str, index) => (
                  <React.Fragment key={index}>
                    <br></br> {_str}
                  </React.Fragment>
                ))}
              </p>
            ))}

          {conteudo?.notes?.text?.split('\n\n')?.map((str, key) => (
            <p key={key}>
              {str?.split('\n')?.map((_str, index) => (
                <React.Fragment key={index}>
                  <br></br> {_str}
                </React.Fragment>
              ))}
            </p>
          ))}
        </article>
      </section>

      <footer>
        <IconFlag
          src={IconFlagEUA}
          onClick={() => handleAudioGoogle('en-US', 'en-US-Wavenet-A')}
        />
        <audio src={audioText} type="audio/mp3" controls />
        <IconFlag
          src={IconFlagEngland}
          onClick={() => handleAudioGoogle('en-GB', 'en-GB-Wavenet-A')}
        />
      </footer>
    </Container>
  );
}
