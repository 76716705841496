import React, { useEffect, useState } from 'react';

// import 'react-chat-elements/dist/main.css';
import { Chat } from '../../../components/Base/Chat';
import { ChatTab } from '../../../components/Base/Chat/ChatTab';
// import ProfilePicDefault from '../../../assets/no_profile.png';
import Footer from '../../../components/Footer';
import {
  Container,
  ContainerBody,
  // ChatListContainer,
  ContainerChat,
  Column1,
  Column2,
  UsersHeader,
  ContentAvatar,
  Avatar,
  ColumnHeaderMessage,
  InputSearch,
  ContentUsers,
  TitleHeaderUsers,
} from './styles';
import { Header } from '../../../components/Header';
import { BackgroundChat } from '../../../components/Base/Chat/styles';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';

let searchTimer = null;
let auxSentIdOfScopedMessages = null;
let auxContacts = null;
let auxChatActive = null;

const socket = io(process.env.REACT_APP_WS_URL);
socket.on('connect', () => {
  console.log('[IO] A new connection has been established 🚨');
});

export function Message() {
  const [user, setUser] = useState();
  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem('@TableTalks:user'));
    if (_user?.id) _user._id = _user.id;
    if (_user?._id) _user.id = _user._id;
    setUser(_user);
  }, []);

  const { t } = useTranslation();
  const [searchContact, setSearchContact] = useState('');
  const [contacts, setContacts] = useState([]);
  const [sent, setSent] = useState({});
  const [showChat, setShowChat] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [chatActive, setChatActive] = useState('');
  const [contactFiltered, setContactFiltered] = useState([]);

  // Pegar todos contatos
  // Remover a última conversa se caso o remetente tenha limpado
  // Atualizar lista de contato, quando receber e enviar uma nova mensagem
  useEffect(() => {
    if (!user?.id) return false;
    socket.on('getContacts', contacts => {
      if (contacts.includes(user.id)) {
        contacts = contacts.map(contact => {
          if (
            typeof contact.clear_message != 'undefined' &&
            contact.clear_message.includes(user.id)
          ) {
            contact.content = '';
            return contact;
          }

          return contact;
        });

        setContacts(contacts);

        auxContacts = contacts;
        auxChatActive = null;
      }
    });
    socket.on('updateContactsAndMessages', contacts => {
      if (contacts[user.id]) {
        contacts = contacts[user.id].map(contact => {
          if (
            typeof contact.clear_message != 'undefined' &&
            contact.clear_message.includes(user.id)
          ) {
            contact.content = '';
            return contact;
          }

          return contact;
        });

        setContacts(contacts);
        auxContacts = contacts;
      }
    });
    socket.on('receivedTheMessages', sentId => {
      setTimeout(() => {
        if (
          sentId == auxSentIdOfScopedMessages &&
          auxChatActive == auxSentIdOfScopedMessages
        ) {
          const contactsUpdated = auxContacts.map(contact => {
            if (contact.id == sentId) {
              contact.qttMessagesNotViewed = 0;
              return contact;
            }

            return contact;
          });

          setContacts(contactsUpdated);
        }
      }, 1000);
    });

    socket.emit('getContacts', { delivered: user.id });

    return () => socket.off();
  }, [user]);

  // Filtrar contatos
  useEffect(() => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      if (searchContact.trim()) {
        const contactsFilteredLocal = contacts.filter(({ fullName, name }) => {
          if (!fullName && name) fullName = name;

          if (!fullName) return false;

          fullName = fullName.toLocaleLowerCase();

          if (fullName.indexOf(searchContact.toLocaleLowerCase()) > -1)
            return true;

          return false;
        });

        setContactFiltered(contactsFilteredLocal);
        setActiveSearch(true);
      } else {
        setActiveSearch(false);
      }
    }, 500);
  }, [searchContact]);

  // Exibir o chat de conversa selecionada
  // Atribuir o id do destinatário, para destacar que está com conversando com ele na lista de contatos
  // Zerar o badgeCount que mostra a quantidade de mensagens não visualizadas, pois as mensagens foram visualizadas naquele instante
  const handleMessageActive = data => {
    const contactsUpdated = contacts.map(contact => {
      if (contact.id == data.id) {
        contact.qttMessagesNotViewed = 0;
        return contact;
      }

      return contact;
    });

    setContacts(contactsUpdated);

    setSent({
      id: data.id,
      imageURL: data.avatarURL,
      name: data.fullName ?? data.name,
    });
    setChatActive(data.id);
    setShowChat(true);

    auxSentIdOfScopedMessages = data.id;
    auxContacts = contactsUpdated;
    auxChatActive = data.id;
  };

  // Fechar o chat quando houver uma exclusão e atualizará a lista de contatos
  const handleShowChatAndUpdateList = sentDeleted => {
    const newsContacts = auxContacts.filter(({ id }) => id != sentDeleted);
    setContacts(newsContacts);
    setShowChat(false);
    setChatActive('');

    auxContacts = newsContacts;
    auxChatActive = null;
  };

  // Remover última mensagem que é mostrado na lista de contato, quando houver a limpeza de mensagem
  const handleRemoveLastMessageWhenClear = sentClear => {
    const contactsUpdated = auxContacts.map(contact => {
      if (contact.id == sentClear) {
        contact.content = '';
        return contact;
      }

      return contact;
    });

    setContacts(contactsUpdated);

    auxContacts = contactsUpdated;
  };

  return (
    <Container>
      <Header />
      <ContainerBody>
        <ContainerChat>
          <Column1>
            {showChat ? (
              <Chat
                sentId={sent.id}
                imageURL={sent.imageURL}
                name={sent.name}
                handleShowChatAndUpdateList={handleShowChatAndUpdateList}
                handleRemoveLastMessageWhenClear={
                  handleRemoveLastMessageWhenClear
                }
                showOptionDelete={true}
              />
            ) : (
              <BackgroundChat />
            )}
          </Column1>
          <Column2>
            <UsersHeader>
              <ContentAvatar>
                <Avatar src={user?.avatarURL} />
              </ContentAvatar>
              <ColumnHeaderMessage>
                <InputSearch
                  placeholder={t('searchConversation')}
                  value={searchContact}
                  onChange={e => setSearchContact(e.target.value)}
                />
              </ColumnHeaderMessage>
            </UsersHeader>
            <ContentUsers>
              <TitleHeaderUsers>{t('mensagens')}</TitleHeaderUsers>
              {activeSearch
                ? contactFiltered.map((c, i) => (
                    <ChatTab
                      key={i}
                      isOpen={chatActive == c.id ? 'true' : 'false'}
                      badgeCount={c.qttMessagesNotViewed}
                      data={c}
                      handleMessageActive={handleMessageActive}
                    />
                  ))
                : contacts.map((c, i) => (
                    <ChatTab
                      key={i}
                      isOpen={chatActive == c.id ? 'true' : 'false'}
                      badgeCount={c.qttMessagesNotViewed}
                      data={c}
                      handleMessageActive={handleMessageActive}
                    />
                  ))}
            </ContentUsers>
          </Column2>
        </ContainerChat>
      </ContainerBody>
      <Footer />
    </Container>
  );
}
